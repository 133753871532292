import React, { ReactElement, useState, useRef } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './ResizeableColumn.module.less'

type ResizeableColumnProps = {
  width?: number | string
  children?: ReactElement[] | ReactElement
}

export default function ResizeableColumn({ children, width: defaultWidth }: ResizeableColumnProps): ReactElement {
  const [width, setWidth] = useState(defaultWidth ?? `100%`)
  const [isResizing, setIsResizing] = useState(false)
  const container = useRef(null)

  function mouseMove(ev: MouseEvent): void {
    if (!container?.current || !isResizing) {
      return
    }

    const x = ev.clientX
    const xc = container.current.getBoundingClientRect()

    setWidth(x - xc.x + 5)
  }

  return (
    <div
      className={dynamicClassName({ [styles.ResizeableColumn]: true, [styles.ResizeableColumn__active]: isResizing })}
      style={{ width }}
      ref={container}
    >
      <div className={styles.ResizeableColumn_Body}>{children}</div>

      <div
        className={dynamicClassName({
          [styles.ResizeableColumn_DragBorder]: true,
          [styles.ResizeableColumn_DragBorder__active]: isResizing,
        })}
        onMouseMove={mouseMove}
        onMouseDown={() => setIsResizing(true)}
        onMouseLeave={() => setIsResizing(false)}
        onMouseUp={() => setIsResizing(false)}
      />
    </div>
  )
}
