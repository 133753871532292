import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { useDigitalTwin, useDigitalTwinAckumulatorInfo } from 'api/digitalTwin/digitalTwin.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { ToggleButton } from 'ui/molecules/ToggleButton/ToggleButton'

import { useTranslation } from 'react-i18next'

import Tooltip from '../Tooltip/Tooltip'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './DigitalTwinAccumulatorLevels.module.less'
import { LevelsForOneDigitalTwinAccumulator } from './LevelsForOneDigitalTwinAccumulator'
import { useAggregatedAccumulatorData } from './UseAggregatedAccumulatorData'

type DigitalTwinAccumulatorLevelsProps = {
  digitalTwinUid: number
  optStartTime?: ISODateTime
  optEndTime?: ISODateTime
  sandboxProjectId?: number
}

export function DigitalTwinAccumulatorLevels({
  digitalTwinUid,
  optStartTime,
  optEndTime,
  sandboxProjectId,
}: DigitalTwinAccumulatorLevelsProps): ReactElement {
  const { t } = useTranslation()

  const { data: accumulators, isLoading: loadingAccumulators } = useDigitalTwinAckumulatorInfo(
    digitalTwinUid,
    optStartTime,
    optEndTime
  )

  const [showAccumulatorLevels, setShowAccumulatorLevels] = useState<boolean>(false)

  useEffect(() => {
    if (accumulators !== undefined && accumulators.length < 2) {
      setShowAccumulatorLevels(true)
    }
  }, [accumulators])

  const { data: digitalTwin, isLoading: loadingDigitalTwin } = useDigitalTwin(digitalTwinUid, true)

  const aggregatedDataPerAccumulator = useAggregatedAccumulatorData({
    accumulators,
    sandboxProjectId,
  })

  const hasWarning = useMemo(
    () =>
      Object.values(aggregatedDataPerAccumulator ?? {}).some(
        (accumulator) => accumulator.startLevelWarnings.length > 0 || accumulator.endLevelWarnings.length > 0
      ),
    [aggregatedDataPerAccumulator]
  )

  if (loadingAccumulators || loadingDigitalTwin || aggregatedDataPerAccumulator === undefined) {
    return (
      <div className={styles.DigitalTwinAccumulatorLevels_LoadingPlaceholder}>
        <LoadingPlaceholderContainer height={24} width={56} />
      </div>
    )
  }

  if (!accumulators || accumulators.length === 0) {
    return <></>
  }

  return (
    <div className={styles.DigitalTwinAccumulatorLevels_Root}>
      {(accumulators?.length ?? 0) > 1 && (
        <div className={styles.DigitalTwinAccumulatorLevels_CollapseButtonContainer}>
          {hasWarning && (
            <Tooltip title={t('Unexpected value found for accumulators')} arrow>
              <div className={styles.DigitalTwinAccumulatorLevels_WarningIcon}>
                <Icon icon="fas fa-exclamation-circle" size="medium" color="yellow" />
              </div>
            </Tooltip>
          )}
          <ToggleButton
            isCollapsed={!showAccumulatorLevels}
            onClick={() => {
              setShowAccumulatorLevels(!showAccumulatorLevels)
            }}
          >
            {t('Accumulator levels')}
          </ToggleButton>
        </div>
      )}
      {showAccumulatorLevels &&
        (accumulators?.length === 1 ? (
          <LevelsForOneDigitalTwinAccumulator
            digitalTwinTranslations={digitalTwin?.translations}
            accumulatorData={aggregatedDataPerAccumulator[accumulators[0].unit_name]}
          />
        ) : (
          <div className={styles.DigitalTwinAccumulatorLevels_Collapse}>
            <div className={styles.DigitalTwinAccumulatorLevels_AccumulatorLevelsContainer}>
              {accumulators?.map((accumulator, i) => (
                <div key={`${accumulator.unit_name}-${i}`}>
                  <div className={styles.DigitalTwinAccumulatorLevels_AccumulatorName}>
                    {getPrettyName(accumulator.unit_name, digitalTwin?.translations)}
                  </div>
                  <LevelsForOneDigitalTwinAccumulator
                    digitalTwinTranslations={digitalTwin?.translations}
                    accumulatorData={aggregatedDataPerAccumulator[accumulator.unit_name]}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  )
}
