import React, { ReactElement, useState } from 'react'

import { Inline } from 'ui/atoms'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import { FormDatePickerPeriod } from 'ui/molecules/pickers/DatePicker/FormDatePickerPeriod'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

export default function ReviewForecasts(): ReactElement {
  const { t } = useTranslation()
  const [period, setPeriod] = useState({ 
    startTime: Datetime.getISONow(-168),
    endTime: Datetime.getISONow(0),
  })

  return (
    <View
      header={
        <ModuleHeader infoHeader={t('Review forecasts')}>
          <Inline flexEnd>
            <FormDatePickerPeriod
              granularity='minute'
              defaultPeriod={period}
              onSubmit={(period) => setPeriod(period)}
            />
          </Inline>
        </ModuleHeader>
      }
    >
      <UiConfig
        type="review_forecasts"
        datasetStartTime={period.startTime}
        datasetEndTime={period.endTime}
        fallback={<UiConfig type={`followup_forecast`} datasetStartTime={period.startTime} datasetEndTime={period.endTime} />}
      />

      <QueueProgressBar />
    </View>
  )
}
