import React, { ReactElement, useState } from 'react'

import Icon from 'ui/atoms/Icon/Icon'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './DeviationSettingsMenuTab.module.less'

type DeviationSettingsMenuTabProps = {
  numberOfSettings: number;
  menuWidth: number;
  onToggle: (isOpen: boolean) => void;
};

export default function DeviationSettingsMenuTab({ numberOfSettings, menuWidth, onToggle }: DeviationSettingsMenuTabProps): ReactElement {
  const { t } = useTranslation()
  const isScreenSmall = useMediaQuery('(max-width:1024px)')
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (): void => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    onToggle(newIsOpen)
  }

  return (
    <div className={styles.DeviationSettingsMenuTab} style={{right: isOpen ? isScreenSmall ? '200px' : `${menuWidth}%` : '10px'}} onClick={handleToggle}>
      <div className={styles.DeviationSettingsMenuTab_CenteredContent}>
        <Icon 
          icon={isOpen ? 'fal fa-chevron-right' : 'fal fa-chevron-left'} 
          size="medium" 
          tooltip={{ 
            title: isOpen? t('Close') : t('Open'),
            placement: 'left', 
          }} 
        />
      </div>
      <div className={styles.DeviationSettingsMenuTab_Content}>
        <div><Icon icon="fal fa-wrench" size='medium' /></div>
        <Tooltip 
          title={t(`{{number}} active deviation settings for current optimization period`, { number: numberOfSettings })}
          placement="left"
        >
          <div className={styles.DeviationSettingsMenuTab_YellowCircle}>{numberOfSettings}</div>
        </Tooltip>
      </div>
    </div>
  )
}
