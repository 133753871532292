import React, { ReactElement } from 'react'

import { Button } from 'ui/atoms'

import { useTranslation } from 'react-i18next'

import styles from './ResetFilters.module.less'

type ResetFiltersProps = {
  onClick: () => void
}

export default function ResetFilters({ onClick }: ResetFiltersProps): ReactElement {
  const { t } = useTranslation()
  return (
    <div
      className={styles.ResetFilters}
    >
      <div>
        <p className={styles.ResetFilters__Margin}>
          {t(`There are no properties that match your criteria.`)}
        </p>
        <p className={styles.ResetFilters__Margin__Bold}>
          {t(`Adjust selected filters above`)}
        </p>
        <p className={styles.ResetFilters__Margin}>{t(`or`)}</p>
        <Button primary onClick={onClick} style={{ margin: '10px' }}>
          {t(`Reset filters`)}
        </Button>
      </div>
    </div>
  )
}
