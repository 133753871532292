import React, { ReactElement, ReactNode } from 'react'

import { dynamicClassName } from 'styles/helper'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps } from 'ui/uiConfig/factory'

import { PieChart, Pie, Cell, Label } from 'recharts'

import { setPieChartColour } from 'helpers/chart.helper/chart.helper'
import {
  getReturnIdsFromDatasetInstruction,
  ApplyAggregateOptions,
  applyAggregateFromUiConfig,
} from 'helpers/dataset.helper/dataset.helper'
import { getTypeObject } from 'helpers/global.helper/global.helper'
import DatasetZoomRange from 'views/OptimizeView/components/DatasetZoomRange/DatasetZoomRange'

import styles from './KpiValue.module.less'

export type KpiValues = {
  show_only_in_export?: boolean
  title: string
  data_id: string
  color?: string
  no_data_icon?: string
  bold_title?: boolean
  unit?: string
  decimals?: number
  tooltip?: string
  data_type?: string
  ignore_zoom?: boolean
  do_not_show_item?: boolean
  style?: `bar` | `value` | 'pie' | 'pie_percentage'
}

type KpiValueProps = {
  uid: number
  items: KpiValues[]
  has_background_color: boolean
  style: `bar` | `value` | 'pie'
  title: string
  datasets: Dataset[]
  children: ReactNode
  ignoreZoom: boolean
  position?: `center` | `left` | `evenly`
  paddingLeft?: boolean
  show_date_range?: boolean
  applyAggregateOptions?: ApplyAggregateOptions
}

const defaultProps: Omit<KpiValueProps, `datasets` | `children` | `uid` | `ignoreZoom`> = {
  items: [{ title: ``, data_id: ``, color: `$color-1`, decimals: 2, tooltip: ``, unit: ``, data_type: `` }],
  has_background_color: false,
  show_date_range: false,
  paddingLeft: true,
  position: `left`,
  style: `value`,
  title: ``,
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: KpiValueProps
): KpiValueProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId.split(`.`).pop(),
    data_id: returnId,
    color: `$color-1`,
    decimals: 2,
    tooltip: ``,
    unit: ``,
    data_type: ``,
  }))

  return {
    ...previousProps,
    has_background_color: false,
    style: `value`,
    title: ``,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent(`kpi_value`, KpiValue)
registerAppendPropsWithDatasets(`kpi_value`, appendPropsWithDataset)
registerDefaultProps(`kpi_value`, defaultProps)

export default function KpiValue({
  uid,
  items,
  datasets,
  has_background_color,
  style,
  title,
  position,
  show_date_range,
  paddingLeft = true,
  applyAggregateOptions,
}: KpiValueProps): ReactElement {
  const itemsToShowInComponent = items.filter((item) => !(item.show_only_in_export || item.do_not_show_item))

  const isBar = style === `bar`
  const isPie = style === 'pie'
  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, applyAggregateOptions)
  const values = aggregatedDatasets.map(
    (dataset) => dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0
  )
  const maxValue = Math.max(...values)
  const minValue = Math.min(...values)

  const highestValue = maxValue < 0 ? minValue : maxValue
  const idToValueMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: dataset.values.filter((v) => v !== null && v !== undefined)?.[0],
    }),
    {}
  )
  const idToPercentageMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: ((dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0) / highestValue) * 100,
    }),
    {}
  )

  const renderKpiValue = (item: KpiValues): ReactElement => {
    const typeObj = getTypeObject(idToValueMap[item.data_id], item.data_type, item.unit, item.decimals)
    const value = typeObj.value
    const unit = typeObj.unit || ''
    const icon = item.no_data_icon || ''

    return (
      <div className={styles.KpiValue_ValueWrapper}>
        <div className={dynamicClassName({
          [styles.KpiValue_ValueWrapper_Value]:true,
          [styles.KpiValue_ValueWrapper_Value__blue]: (icon && value === '-'),
        })}>
          {(icon && value === '-') ? (<i className={icon} />) : value}
        </div>
        <div className={styles.KpiValue_ValueWrapper_Unit}>{unit}</div>
      </div>
    )
  }

  const renderPieValue = (item: KpiValues, percentage?: boolean): ReactElement => {
    const typeObj = getTypeObject(idToValueMap[item.data_id], item.data_type, item.unit, item.decimals)
    const value = typeObj.value
    const unit = typeObj.unit || ''

    const pieData = [
      {
        value:  Number(value) ?? 0,
        name: 'main',
        color: percentage ? setPieChartColour(Number(value)) : item.color,
      },
      {
        name: `rest`,
        value:  Number(value) ? 100 - Number(value) : 100,
        color: `transparent`,
      },
    ]

    return (
      <PieChart width={100} height={100}>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={50}
          fill={item.color}
          isAnimationActive={false}
          stroke="none"
        >
          {pieData.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
          <Label value={value + unit} position="center" />
        </Pie>
      </PieChart>
    )
  }

  return (
    <>
      {title && (
        <div
          className={dynamicClassName({
            [styles.KpiTitle]: true,
            [styles.KpiTitle__center]: position === `center`,
          })}
        >
          <TitleWithIcon title={title} size={`small`} padding={false} />
        </div>
      )}
      <div className={styles.KpiContainer}>
        <div
          className={dynamicClassName({
            [styles.KpiContainer_Background]: true,
            [styles.KpiContainer_Background__color]: has_background_color,
            [styles.KpiContainer_Background__center]: position === `center`,
            [styles.KpiContainer_Background__evenly]: position === `evenly`,
          })}
        >
          {show_date_range && <DatasetZoomRange />}
          {(itemsToShowInComponent || []).map((item, index) => (
            <div
              className={dynamicClassName({
                [styles.KpiValue]: true,
                [styles.KpiValue__paddingLeft]: paddingLeft,
              })}
              key={index}
            >
              {(isBar || item.style === 'bar') && (
                <div className={styles.KpiValue_BarWrapper}>
                  <div
                    className={styles.KpiValue_BarWrapper_Bar}
                    style={{ height: `${idToPercentageMap[item.data_id] ?? 0}%`, backgroundColor: item.color }}
                  />
                </div>
              )}
              {(isPie || item?.style?.includes('pie')) ? renderPieValue(item, item?.style?.includes('percentage')) : renderKpiValue(item)}
              {item.tooltip ? (
                <Tooltip title={item.tooltip} arrow>
                  <div className={dynamicClassName({
                    [styles.KpiValue_Title]: true,
                    [styles.KpiValue_Title__bold]: !!item.bold_title,
                  })} >{item.title}</div>
                </Tooltip>
              ) : (
                <div
                  className={dynamicClassName({
                    [styles.KpiValue_Title]: true,
                    [styles.KpiValue_Title__bold]: !!item.bold_title,
                  })} > { item.title }</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
