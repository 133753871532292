import React, { useState, ReactElement, useCallback } from 'react'

import {
  DigitalTwinSettingCreationResult,
  TimeSeriesSettingsCreationResult,
  TimeSeriesSettingCreationRequest,
  useCreateDigitalTwinSetting,
  useCreateTimeSeriesSetting,
  useDeleteDeviationSetting,
  useDeleteTimeSeriesSetting,
  useUpdateDigitalTwinSetting,
  useUpdateTimeSeriesSetting,
  DigitalTwin,
  checkSettingModificationSuccessful,
  DigitalTwinSettingCreationRequest,
  ConfigurableProperty,
} from 'api/digitalTwin/digitalTwin.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'

import { DialogActions } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getValueHelperTextForSetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'

import { CreateSettingData } from './DigitalTwinSettingDialogContent'

type DigitalTwinSettingDialogActionsProps = {
  digitalTwin: DigitalTwin
  initData: null | CreateSettingData
  newSetting: DigitalTwinSettingCreationRequest
  activeConfigurableProperty?: ConfigurableProperty
  onClose: () => void
  onSettingModified: () => void
  onInvalidIndexChange: (invalidValuesIndexes: number[], valueHelperText: string) => void
}

export default function DigitalTwinSettingDialogActions({
  digitalTwin,
  newSetting,
  activeConfigurableProperty,
  initData,
  onClose,
  onSettingModified,
  onInvalidIndexChange,
}: DigitalTwinSettingDialogActionsProps): ReactElement {
  const { t } = useTranslation()

  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const { mutateAsync: createSetting, isLoading: isCreatingSettingLoading } = useCreateDigitalTwinSetting()
  const { mutateAsync: createTimeSeriesSetting, isLoading: isCreatingTimeSeriesSettingLoading } =
    useCreateTimeSeriesSetting()
  const { mutateAsync: updateDeviationSetting, isLoading: isUpdatingDeviationSettingLoading } =
    useUpdateDigitalTwinSetting()
  const { mutateAsync: updateTimeSeriesSetting, isLoading: isUpdatingTimeSeriesSettingLoading } =
    useUpdateTimeSeriesSetting()
  const { mutateAsync: deleteDeviationSetting } = useDeleteDeviationSetting()
  const { mutateAsync: deleteTimeSeriesSetting } = useDeleteTimeSeriesSetting()

  const maxValue = activeConfigurableProperty?.max_value
  const minValue = activeConfigurableProperty?.min_value

  const settingStartTime = newSetting.start_time
  const settingEndTime = newSetting.end_time

  const onSubmit = useCallback(() => {
    if (settingStartTime && settingEndTime && settingStartTime > settingEndTime) {
      return // Early return because of bad setting time period
    }

    const { errorMessage: valueHelperText, invalidValuesIndexes: newInvalidValuesIndexes } =
      getValueHelperTextForSetting(newSetting, minValue, maxValue, t)
    if (valueHelperText) {
      onInvalidIndexChange(newInvalidValuesIndexes, valueHelperText)
      return // Early return because of bad setting value
    }

    // Setting is valid. Save as time series or regular setting, then show a success message and close the dialog.
    let modificationPromise: Promise<
      DigitalTwinSettingCreationResult | TimeSeriesSettingsCreationResult | TimeSeriesSettingsCreationResult[]
    >
    if (newSetting.isTimeSeries) {
      modificationPromise =
        initData?.id !== undefined
          ? updateTimeSeriesSetting({
            overwrite: true,
            settingId: initData.id,
            data: newSetting,
            digitalTwinUid: digitalTwin.uid,
          })
          : createTimeSeriesSetting({
            overwrite: true,
            digitalTwinUid: digitalTwin.uid,
            data: newSetting as TimeSeriesSettingCreationRequest,
            splitOnNullValues: true,
          })
    } else {
      modificationPromise =
        initData?.id !== undefined
          ? updateDeviationSetting({
            overwrite: true,
            settingId: initData.id,
            data: newSetting,
          })
          : createSetting({
            overwrite: true,
            digitalTwinUid: digitalTwin.uid,
            data: newSetting,
          })
    }

    modificationPromise.then((data) => {
      const success = Array.isArray(data)
        ? data.length > 0 && data.every((d) => checkSettingModificationSuccessful(d))
        : data && checkSettingModificationSuccessful(data)
      if (success) {
        onSettingModified()
        onClose()
      }
    })
  }, [
    settingStartTime, 
    settingEndTime, 
    newSetting, 
    minValue, 
    maxValue, 
    t, 
    onInvalidIndexChange, 
    initData?.id, 
    updateTimeSeriesSetting, 
    digitalTwin.uid, 
    createTimeSeriesSetting, 
    updateDeviationSetting, 
    createSetting, 
    onSettingModified, 
    onClose,
  ])

  if (!digitalTwin || !initData) {
    return <></>
  }

  const isSubmitting =
    isCreatingSettingLoading ||
    isCreatingTimeSeriesSettingLoading ||
    isUpdatingDeviationSettingLoading ||
    isUpdatingTimeSeriesSettingLoading

  const startAfterEnd = !!(settingStartTime && settingEndTime && settingStartTime > settingEndTime)
  const disableSubmit =
    !settingStartTime || !newSetting.comment || newSetting.value === undefined || startAfterEnd

  const onDeleteFunction = initData?.id !== undefined ? () => setDeleteConfirmOpen(true) : undefined

  return (
    <>
      <DialogActions>
        <ModalButtons
          onDelete={onDeleteFunction}
          disableSubmit={disableSubmit}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          onCancel={() => {
            setCloseConfirmOpen(true)
          }}
        />
      </DialogActions>
      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          setCloseConfirmOpen(false)
          onClose()
        }}
        textObject={{
          title: t(`Do you really want to close without saving?`),
          text: t(
            `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
          ),
        }}
      />

      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={() => {
          setDeleteConfirmOpen(false)
        }}
        onConfirm={() => {
          if (initData?.id !== undefined) {
            const deletePromise = newSetting.isTimeSeries
              ? deleteTimeSeriesSetting({ settingId: initData.id, digitalTwinUid: digitalTwin.uid })
              : deleteDeviationSetting({ settingId: initData.id })
            deletePromise.then(() => {
              onSettingModified()
              onClose()
            })
          }
          setDeleteConfirmOpen(false)
        }}
        textObject={{
          title: t(`Do you really want to delete this setting?`),
          text: t(
            `If you remove this setting it will be deleted from history and not included in the follow-up. Instead, you could choose to cancel and then edit the end time.`
          ),
        }}
      />
    </>
  )
}
