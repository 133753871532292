import React, { ReactElement, useMemo, useState } from 'react'

import { useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import TimeField from 'ui/components/CustomTable/components/TimeField/TimeField'
import Tour from 'ui/components/Tour/Tour'

import { Grid, Typography, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import { getDisplayName, prettyPrintAnySettingValue } from 'helpers/global.helper/global.helper'
import { getTranslatedCategoryDisplayName } from 'helpers/optimize.helper/optimize.helper'

import styles from './HandoverSettingsTable.module.less'

type HandoverSettingsTableProps = {
  settings: Setting[]
}

const handoverTable = `handoverTable`
const step: Array<Step> = [
  {
    target: handoverTable,
    content: `Here are the production plan settings which is within the period of what is displayed in the production plan overview. The settings are displayed in both calender form as well as in table form.`,
    title: `Active settings`,
  },
]

export default function HandoverSettingsTable({ settings }: HandoverSettingsTableProps): ReactElement {
  const [runTour, setRunTour] = useState(false)
  const { data: objectProperties } = useObjectProperties({ classification: `deviation` })
  const { t } = useTranslation()

  const columns: { Header: string, accessor: string, Cell?: any }[] = useMemo(
    () => [
      {
        Header: t(`Group`),
        accessor: `group`,
      },
      { Header: t(`Property`), accessor: `property` },
      { Header: t(`Current value`), accessor: `currentValue` },

      {
        Header: t(`Unit `),
        accessor: `unit`,
      },
      { Header: t(`Cause `), accessor: `reason` },
      { Header: t(`End time`), accessor: `endTime`, Cell: TimeField },
      {
        Header: t(`Last updated`),
        accessor: `updatedAt`,
        Cell: TimeField,
      },
    ],
    [t]
  )

  const data = useMemo(() => {
    if (!objectProperties) return []
    return settings.reduce<Record<string, unknown>[]>((prev, setting) => {
      const property = objectProperties.find((prop) => prop.id === setting.object_property)

      if (!property) return prev

      return [
        ...prev,
        {
          category: getTranslatedCategoryDisplayName(property.category),
          group: property.parent ? getDisplayName(property.parent) : `-`,
          property: getDisplayName(property),
          currentValue: prettyPrintAnySettingValue(property, setting),
          unit: property.measurement_unit !== null ? property.measurement_unit : `-`,
          reason: setting.comment !== `` ? setting.comment : `-`,
          endTime: setting.end_time,
          updatedAt: setting.updated_at,
        },
      ]
    }, [])
  }, [objectProperties, settings])

  return (
    <>
      <Tour isSingle={true} run={runTour} setRunState={setRunTour} steps={step} />
      <Grid container className={classNames(handoverTable, styles.HandoverSettingsTable_Container)} direction="column">
        <Grid item container direction="row">
          <Grid item>
            <Icon
              className={classNames(styles.HandoverSettingsTable_Icon, `fas fa-info-circle`)}
              onClick={() => setRunTour(true)}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={styles.HandoverSettingsTable_Title} display="inline">
              {t(`Deviation settings`)}
            </Typography>
          </Grid>
        </Grid>
        <div className={styles.HandoverSettingsTable_Table}>
          <DefaultTable
            columns={columns}
            data={data}
            tableOptions={{
              initialState: { hiddenColumns: [`category`] },
            }}
          />
        </div>
      </Grid>
    </>
  )
}
