import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import {
  ConfigurableProperty,
  DigitalTwinSetting,
  DigitalTwin,
  useDigitalTwinSettings,
  DigitalTwinSettingMetadata,
} from 'api/digitalTwin/digitalTwin.api'
import { DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { Inline, LoadingPlaceholderContainer, Switch } from 'ui/atoms'
import BarCalendar from 'ui/components/BarCalendar/BarCalendar'
import TimePeriodPicker from 'ui/molecules/TimePeriodPicker/TimePeriodPicker'
import Datetime, { Month } from 'utils/datetime/datetime'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { generateDTSCCategoriesAndEvents } from './DigitalTwinSettingsCalendar.helper'
import styles from './DigitalTwinSettingsCalendar.module.less'

type DigitalTwinSettingsCalendarProps = {
  digitalTwin: DigitalTwin
  onEditDeviationSetting: (setting: DigitalTwinSetting | undefined) => void
  configurableProperties: ConfigurableProperty[]
  filteredProperties: ConfigurableProperty[]
  settingPriortyLevels: SystemSettingPriorityLevel[] | null | undefined
  activeBaseSettings: DigitalTwinSettingMetadata[]
  settingPropertyFilterEnabled: boolean
  refetchTriggerToggle: boolean
}

export default function DigitalTwinSettingsCalendar({
  digitalTwin,
  onEditDeviationSetting,
  configurableProperties,
  filteredProperties,
  settingPriortyLevels,
  activeBaseSettings,
  settingPropertyFilterEnabled,
  refetchTriggerToggle,
}: DigitalTwinSettingsCalendarProps): ReactElement {
  const { t } = useTranslation()

  const startTimeProductionPlan = Datetime.getStartOfDay(Datetime.getISONow(0))
  const endTimeProductionPlan = Datetime.getEndOfDay(Datetime.getISONow(168))

  const [calendarZoom, setCalendarZoom] = useState<`productionPlan` | `week` | `month`>('productionPlan')
  const [startTime, setStartTime] = useState<ISODateTime>(startTimeProductionPlan)
  const [endTime, setEndTime] = useState<ISODateTime>(endTimeProductionPlan)

  const [filteredSettings, setFilteredSettings] = useState<DigitalTwinSetting[] | null>(null)
  const { mutateAsync: fetchSettings, isLoading: isLoadingSettings} = useDigitalTwinSettings()

  const refetchSettings = useCallback(() => {
    if (filteredProperties && filteredProperties.length > 0) {
      fetchSettings({
        digitalTwinUid: digitalTwin?.uid,
        properties: filteredProperties,
        priorities: [...DEVIATION_SETTING_PRIORITIES, ...(settingPriortyLevels ?? [])],
        activeFrom: startTime,
        activeTo: endTime,
        usePropertyFiltersList: settingPropertyFilterEnabled,
      }).then(setFilteredSettings)
    }
  }, [settingPriortyLevels, digitalTwin?.uid, fetchSettings, filteredProperties, startTime, endTime, settingPropertyFilterEnabled])
  useEffect(refetchSettings, [refetchSettings, refetchTriggerToggle])

  if (!filteredSettings) {
    return <LoadingPlaceholderContainer height={200} />
  }
  
  const { categories, events } = generateDTSCCategoriesAndEvents(digitalTwin, configurableProperties, filteredSettings, activeBaseSettings, t)
  return (
    <div className={styles.DigitalTwinSettingsCalendar}>
      <><Inline spaceBetween>
        <div>
          {calendarZoom === 'month' && (
            <TimePeriodPicker
              periodType='month'
              startTime={moment(startTime)}
              setTimePeriod={(date, periodType, timePeriodLength) => {
                if (date && periodType && timePeriodLength) {
                  const ISODate = Datetime.toISOString(date)
                  const { startTime, endTime } = Datetime.getStartAndEndOfTimePeriod(ISODate, timePeriodLength, periodType)
                  setStartTime(startTime)
                  setEndTime(endTime)
                }
              } } />
          )}
          {calendarZoom === 'week' && (
            <TimePeriodPicker
              periodType='week'
              startTime={moment(startTime)}
              setTimePeriod={(date, periodType, timePeriodLength) => {
                if (date && periodType && timePeriodLength) {
                  const ISODate = Datetime.toISOString(date)
                  const { startTime, endTime } = Datetime.getStartAndEndOfTimePeriod(ISODate, timePeriodLength, periodType)
                  setStartTime(startTime)
                  setEndTime(endTime)
                }
              } } />
          )}
        </div>
        <Switch
          value={calendarZoom}
          onClick={(value) => {
            if (value === 'productionPlan') {
              setStartTime(startTimeProductionPlan)
              setEndTime(endTimeProductionPlan)
            } else if (value === 'month') {
              const timeToStartFrom = Datetime.getStartTimeForLatestMonth(startTime, endTime)

              const currentYear = new Date(Datetime.toLocalTime(timeToStartFrom)).getFullYear()
              const currentMonth = new Date(Datetime.toLocalTime(timeToStartFrom)).getMonth() + 1
              const { startTime: newStartTime, endTime: newEndTime } = Datetime.getStartTimeEndTimeOfMonth(
                currentYear,
                  currentMonth as Month
              )
              setStartTime(newStartTime)
              setEndTime(newEndTime)
            } else if (value === 'week') {
              const timeToStartFrom = Datetime.getStartTimeForLatestMonth(startTime, endTime)
              const { startTime: newStartTime, endTime: newEndTime } = Datetime.getStartAndEndOfTimePeriod(timeToStartFrom, 0, 'week')

              setStartTime(newStartTime)
              setEndTime(newEndTime)
            }

            setCalendarZoom(value)
          } }
          items={[
            {
              value: `productionPlan`,
              label: t(`Production plan period`),
            },
            {
              value: `week`,
              label: t(`Week`),
            },
            {
              value: `month`,
              label: t(`Month`),
            },
          ]} />
      </Inline>

      {isLoadingSettings ? (
        <LoadingPlaceholderContainer height={200} />
      ) : (
        <BarCalendar
          startTime={startTime}
          endTime={endTime}
          events={events}
          categories={categories}
          onClick={(event) => {
            const settingId = typeof event.id === 'string' ? parseInt(event.id) : event.id
            const isTimeSeries = event.meta?.isTimeSeries
            const setting = filteredSettings.find(
              (setting) => setting.id === settingId && setting.isTimeSeries === isTimeSeries
            )
            onEditDeviationSetting(setting)
          } }
          header={t('Asset')} />
      )}
      </>
    </div>
  )
}
