import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react'

import { postStat } from 'api/stats/stats.api'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import sentry from 'utils/sentry/sentry'

import { Avatar, Card, CardActions, CardContent, CardHeader, Hidden } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useTranslation } from 'react-i18next'


import styles from './CardWidget.module.less'

type CardWidgetContextType = ReturnType<typeof useFullScreenHandle>

const CardWidgetContext = createContext<CardWidgetContextType | undefined>(undefined)

type CardWidgetProps = PropsWithChildren<{
  id: string
  icon?: React.ReactNode
  title: React.ReactNode
  width?: string
  handleInfoIconClick?: () => void
}>

export default function CardWidget({
  id,
  children,
  icon,
  title,
  width,
  handleInfoIconClick,
}: CardWidgetProps): ReactElement {
  const { t } = useTranslation()

  const fullScreenHandle = useFullScreenHandle()
  const { active: isFullScreen, enter, exit } = fullScreenHandle
  const style: Record<string, string> = {}

  if (width) {
    style.width = width
  }

  return (
    <div style={style}>
      <CardWidgetContext.Provider value={{ ...fullScreenHandle }}>
        <FullScreen handle={fullScreenHandle}>
          <Card className={id}>
            <CardHeader
              avatar={icon ? <Avatar className={styles.CardWidget_Avatar}>{icon}</Avatar> : null}
              action={
                <CardActions>
                  <Hidden mdDown>
                    <TooltipIconButton
                      tooltip={isFullScreen ? t(`Minimize fullscreen`) : t(`Fullscreen`)}
                      onClick={() => {
                        if (isFullScreen) {
                          exit()
                        } else {
                          enter()
                          postStat(`card-widget`, `open-fullscreen`)
                        }
                      }}
                      icon={isFullScreen ? `fal fa-compress-wide` : `fal fa-expand`}
                    />
                  </Hidden>

                  {handleInfoIconClick !== undefined && !isFullScreen ? (
                    <TooltipIconButton
                      tooltip={t(`Show more information`)}
                      onClick={handleInfoIconClick}
                      icon={`fas fa-info-circle`}
                      className={styles.CardWidget_Info}
                    />
                  ) : null}
                </CardActions>
              }
              title={t(`${title}`)}
              titleTypographyProps={{ variant: `h1` }}
            />
            <CardContent>
              <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
                {children}
              </ErrorBoundary>
            </CardContent>
          </Card>
        </FullScreen>
      </CardWidgetContext.Provider>
    </div>
  )
}

export function useCardWidget(): CardWidgetContextType | { active: boolean; node: undefined } {
  const context = useContext(CardWidgetContext)

  return context ?? { active: false, node: undefined }
}
