import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useEventDeleteMutation, useGetEventCategories } from 'api/events/events.api'
import alertStore from 'store/alert/alert'
import theme from 'styles/theme/theme'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import Datetime from 'utils/datetime/datetime'

import { Box, createTheme, StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'

import EventPostModal from '../EventPostModal/EventPostModal'
import EventPostViewModal from '../EventPostViewModal/EventPostViewModal'
import { EventPostListItem, EventStatus, getLabelForEventStatus, useOperationalEventsPermissions } from '../events.helper'

type EventsTableProps = {
  events: EventPostListItem[]
}

enum ROW_CLICK_ACTION_ENUM {
  VIEW = 'view',
  EDIT = 'edit'
}

export default function EventsTable({ events }: EventsTableProps): ReactElement {
  const { t } = useTranslation()
  const { systemId, hasAccess } = useAuth()

  const { data: eventCategories } = useGetEventCategories(systemId)
  const { mutateAsync: deleteEventPost } = useEventDeleteMutation()

  const [columns, setColumns] = useState<MRT_ColumnDef<EventPostListItem>[]>([])
  const [selectedEventPost, setSelectedEventPost] = useState<EventPostListItem | undefined>(undefined)
  const [selectedViewEventPost, setSelectedViewEventPost] = useState<EventPostListItem | undefined>(undefined)

  const {
    hasDeleteOperationalEventsAccess,
    hasDeleteActivatedOperationalEventsAccess,
  } = useOperationalEventsPermissions()

  const deleteEvent = useCallback(async (id: number) => {
    try {
      await deleteEventPost(id)
      alertStore.success(t(`Event deleted`))
    } catch (e) {
      alertStore.error(t(`Failed to delete event`))
    }
  }, [deleteEventPost, t])

  const handleRowClick = useCallback((action: ROW_CLICK_ACTION_ENUM, row: { original: EventPostListItem }): void => {
    if (action === ROW_CLICK_ACTION_ENUM.VIEW) {
      setSelectedViewEventPost(row.original)
    } else if (action === ROW_CLICK_ACTION_ENUM.EDIT) {
      setSelectedEventPost(row.original)
    }
  }, [setSelectedEventPost])

  const hasAccessToUMMCheckbox = hasAccess({ submodule: 'operational_event_umm', module: 'events' })

  useEffect(() => {
    const newColumns: MRT_ColumnDef<EventPostListItem>[] = [
      {
        accessorKey: 'name',
        header: t('Event'),
        Cell: ({ cell }) => {
          return <Box sx={{ display: 'inline' }}>{cell.getValue<string>()}</Box>
        },
      },
      {
        accessorKey: 'start_time',
        header: t('Start time'),
        Cell: ({ row }) => {
          const start_time = Datetime.toLocalTime(Datetime.toISOString(row?.original?.start_time), 'longDayText')
          return <Box sx={{ display: 'inline' }}>{start_time}</Box>
        },
        size: 250,
      },
      {
        accessorKey: 'end_time',
        header: t('End time'),
        Cell: ({ row }) => {
          const end_time = Datetime.toLocalTime(Datetime.toISOString(row?.original?.end_time), 'longDayText')
          return <Box sx={{ display: 'inline' }}>{end_time}</Box>
        },
      },
      {
        accessorKey: 'category_id',
        header: t('Cause category'),
        Cell: ({ row }) => {
          const categoryDisplayName = eventCategories?.find((category) => category.id === row.original.category_id)?.display_name
          return <Box sx={{ display: 'inline' }}>{t(categoryDisplayName as string)}</Box>
        },
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        Cell: ({ cell }) => {
          const cellValue = cell.getValue<EventStatus>()
          const displayName = t(getLabelForEventStatus(cellValue))
          let color
          if (cellValue === 'Draft') {
            color = '#7a625a'
          } else if (cellValue === 'Sent') {
            color = '#082B45'
          } else if (cellValue === 'Active') {
            color = '#43a047'
          } else if (cellValue === 'Inactive') {
            color = '#757575'
          }
          return <Box sx={{ display: 'inline', color: color }}>{displayName}</Box>
        },
        filterFn: (row, columnId, filterValue) => {
          const cellValue = row.getValue<EventStatus>(columnId)
          const displayName = t(getLabelForEventStatus(cellValue))
          return displayName.toLowerCase().includes(filterValue.toLowerCase())
        },
        size: 150,
      },
      {
        accessorKey: 'overview',
        header: t('Overview'),
        Cell: ({ cell }) => <Box>
          <Button
            onClick={() => handleRowClick(ROW_CLICK_ACTION_ENUM.VIEW, cell.row)}
            primary
          >
            {t('View event')}
          </Button>
        </Box>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'manage',
        header: t('Manage'),
        Cell: ({ cell }) => <Box>
          <Button
            onClick={() => handleRowClick(ROW_CLICK_ACTION_ENUM.EDIT, cell.row)}
            primary
          >
            {t('Show/edit')}
          </Button>
        </Box>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'delete',
        header: t('Delete'),
        Cell: ({ row }) => {
          const disableDelete = !hasDeleteOperationalEventsAccess || (!hasDeleteActivatedOperationalEventsAccess && (row?.original?.status === 'Active' || row?.original?.status === 'Inactive'))
          return <Box>
            <TooltipIconButton
              icon={'fal fa-trash-alt'}
              iconColor={disableDelete ? '#f35e5e81' : '#f35e5e'}
              iconSize='small'
              tooltip={disableDelete ? t('You do not have permission to delete this event') : t('Delete event')}
              disabled={disableDelete}
              onClick={() => deleteEvent(row?.original?.id as number)} />
          </Box>},
        size: 10,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ]

    if (hasAccessToUMMCheckbox) {
      newColumns.splice(5, 0, {
        accessorKey: 'require_umm',
        header: t('Requires UMM'),
        Cell: ({ cell }) => {
          return <Box sx={{ display: 'inline' }}>{cell.getValue() ? t('Yes') : '-'}</Box>
        },
        size: 0,
      })
    }

    setColumns(newColumns)
  }, [eventCategories, hasDeleteOperationalEventsAccess, t, deleteEvent, hasDeleteActivatedOperationalEventsAccess, handleRowClick, hasAccessToUMMCheckbox])

  const globalTheme = useTheme()
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: theme.palette.primary,
          secondary: theme.palette.secondary,
          info: theme.palette.info,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalTheme]
  )

  const statusOrder = ['Sent', 'Draft', 'Active', 'Inactive']

  const customSorting = (
    rowA: { getValue: (arg0: any) => any; original: { updated_at: string } },
    rowB: { getValue: (arg0: any) => any; original: { updated_at: string } },
    columnId: string): number => {
    const valueA = rowA.getValue(columnId)
    const valueB = rowB.getValue(columnId)
    const indexA = statusOrder.indexOf(valueA)
    const indexB = statusOrder.indexOf(valueB)

    if (indexA === indexB) {
      const updatedAtA = Datetime.toISOString(rowA.original.updated_at)
      const updatedAtB = Datetime.toISOString(rowB.original.updated_at)
      return Datetime.compare(updatedAtB, updatedAtA)
    }

    return indexA - indexB
  }

  return (
    <>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, overflowX: `scroll` }}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={events}
                  enableGlobalFilterModes
                  enableColumnActions={false}
                  enableStickyHeader
                  enableColumnDragging={false}
                  paginateExpandedRows={false}
                  positionGlobalFilter='left'
                  sortingFns={{ customSorting }}
                  defaultColumn={{ sortingFn: 'customSorting' }}
                  initialState={{
                    columnOrder: ['name', 'start_time', 'end_time', 'category_id', 'status', 'require_umm', 'overview', 'manage', 'delete'],
                    sorting: [
                      { id: 'status', desc: false },
                    ],
                    showGlobalFilter: true,
                    pagination: {
                      pageIndex: 0,
                      pageSize: 50,
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    sx: {
                      backgroundColor: row.original.status === 'Sent' ? '#6baedf75' : 'inherit',
                    },
                  })}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
        )}
      </AutoSizer>

      {selectedEventPost && (
        <EventPostModal
          closeModal={() => setSelectedEventPost(undefined)}
          selectedEventPostId={selectedEventPost?.id}
        />
      )}

      {selectedViewEventPost && (
        <EventPostViewModal
          closeModal={() => setSelectedViewEventPost(undefined)}
          selectedEventPostId={selectedViewEventPost?.id}
        />
      )}
    </>
  )
}
