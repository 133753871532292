import React, { ReactElement, ReactNode } from 'react'

import { dynamicClassName } from 'styles/helper'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps } from 'ui/uiConfig/factory'

import {
  getReturnIdsFromDatasetInstruction,
  applyAggregateFromUiConfig,
  ApplyAggregateOptions,
} from 'helpers/dataset.helper/dataset.helper'
import { getTypeObject } from 'helpers/global.helper/global.helper'
import DatasetZoomRange from 'views/OptimizeView/components/DatasetZoomRange/DatasetZoomRange'

import styles from './DoubleKpiValue.module.less'

export type DoubleKpiValue = {
  values: {
    type?: 'main' | 'sub'
    text?: string,
    data_id: string,
    decimals?: number,
    data_type?: string,
    unit?: string,
  }[],
  title?: string,
  color?: string
  tooltip?: string
  ignore_zoom?: boolean
}

type DoubleKpiValueProps = {
  uid: number
  items: DoubleKpiValue[]
  has_background_color: boolean
  title: string
  datasets: Dataset[]
  children: ReactNode
  ignoreZoom: boolean
  position?: `center` | `left` | `evenly`
  paddingLeft?: boolean
  show_date_range?: boolean
  applyAggregateOptions?: ApplyAggregateOptions
}

const defaultProps: Omit<DoubleKpiValueProps, `datasets` | `children` | `uid` | `ignoreZoom`> = {
  items: [{
    values: [{
      type: 'main',
      text: '',
      data_id: '',
      decimals: 2,
      data_type: '',
      unit: '',
    }],
    color: `$color-1`,
    tooltip: ``,
    title: '',
    ignore_zoom: false,
  }],
  has_background_color: false,
  show_date_range: false,
  paddingLeft: true,
  position: `left`,
  title: ``,
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: DoubleKpiValueProps
): DoubleKpiValueProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems: DoubleKpiValue[] = returnIds.map((returnId) => ({
    values: [{
      type: 'main',
      text: returnId.split(`.`).pop(),
      data_id: returnId,
      decimals: 2,
    }],
    color: `$color-1`,
    ignore_zoom: false,
  }))

  return {
    ...previousProps,
    has_background_color: false,
    title: ``,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent(`double_kpi_value`, DoubleKpiValue)
registerAppendPropsWithDatasets(`double_kpi_value`, appendPropsWithDataset)
registerDefaultProps(`double_kpi_value`, defaultProps)

export default function DoubleKpiValue({
  uid,
  items,
  datasets,
  has_background_color,
  title,
  position,
  show_date_range,
  paddingLeft = true,
  applyAggregateOptions,
}: DoubleKpiValueProps): ReactElement {
  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, applyAggregateOptions)

  const idToValueMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: dataset.values.filter((v) => v !== null && v !== undefined)?.[0],
    }),
    {}
  )

  const renderDoubleKpiValue = (item: DoubleKpiValue): ReactElement => {

    return (
      <div className={styles.KpiValue_ValueWrapper}>
        {item.values.map((value, index) => {
          const typeObj = getTypeObject(idToValueMap[value.data_id], value.data_type, value.unit, value.decimals)
          const objValue = value.data_id ? typeObj.value : ''

          const objUnit = typeObj.unit || ''
          if (value.type === 'main') {
            return (<div className={styles.KpiValue_ValueWrapper_Value} key={index}>{`${value.text} ${objValue} ${objUnit}`}</div>)
          } else if (value.type === 'sub') {
            return <div className={styles.KpiValue_ValueWrapper_Unit} key={index}>{`${value.text} ${objValue} ${objUnit}`}</div>
          }
        })}
      </div>)
  }

  return (
    <>
      {title && (
        <div
          className={dynamicClassName({
            [styles.KpiTitle]: true,
            [styles.KpiTitle__center]: position === `center`,
          })}
        >
          {title}
        </div>
      )}
      <div className={styles.KpiContainer}>
        <div
          className={dynamicClassName({
            [styles.KpiContainer_Background]: true,
            [styles.KpiContainer_Background__color]: has_background_color,
            [styles.KpiContainer_Background__center]: position === `center`,
            [styles.KpiContainer_Background__evenly]: position === `evenly`,
          })}
        >
          {show_date_range && <DatasetZoomRange />}
          {(items || []).map((item, index) => (
            <div
              className={dynamicClassName({
                [styles.KpiValue]: true,
                [styles.KpiValue__paddingLeft]: paddingLeft,
              })}
              key={index}
            >
              {renderDoubleKpiValue(item)}
              {item.tooltip ? (
                <Tooltip title={item.tooltip} arrow>
                  <div className={styles.KpiValue_Title}>{item.title}</div>
                </Tooltip>
              ) : (
                <div className={styles.KpiValue_Title}>{item.title}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
