import { ReactElement, useCallback, useMemo, useState } from 'react'

import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import {
  BidToCreate,
  BidTypeName,
  ElectricityPriceScenario,
  ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
  PlanValues,
  useCreateElplanBidsMutation,
  useDeleteElplanBidsForDateMutation,
  useElplanBidCreateMutation,
  useElplanBidDeleteMutation,
  useElplanBids,
  useElplanBidUpdateMutation,
  useUpdateElplanBidsMutation,
} from 'api/elplan/elplan.api'
import { OptJobInputData, useOptJobMutation } from 'api/optJobs/optJobs.api'
import { postStat } from 'api/stats/stats.api'
import { useUiConfigById } from 'api/uiConfig/uiConfig.api'
import alertStore from 'store/alert/alert'
import elplanStore from 'store/elplan/elplan'
import { Button } from 'ui/atoms'
import LineDivider from 'ui/atoms/Line/LineDivider'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { OptimizeButton } from 'ui/components/OptimizeFormProvider/components/OptimizeButton/OptimizeButton'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfig from 'ui/uiConfig/UiConfig'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'
import { translateKeyWithSpecificTranslations } from 'utils/stringUtils'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import BlockBidTable from '../BlockBidTable/BlockBidTable'
import {
  formatBid,
  FormattedBid,
  FormattedBidWithIdAndTitle,
  generatePlanValues,
  getDefaultBlockBid,
  getSpotBidsFromOptElplan,
} from '../DayAheadBidManagement.helper'
import SpotBidTable from '../SpotBidTable/SpotBidTable'
import { convertNumberDecimalSign, isArray } from 'helpers/global.helper/global.helper'

import styles from './ElectricityPriceScenarioVisualization.module.less'

const ELECTRICITY_PRICE_SCENARIO_JOB_TYPE = 'electricity_price_scenario'

const MAX_NUMBER_OF_BLOCK_BIDS = 100

type ElectricityPriceScenarioVisualizationProps = {
  scenario: ElectricityPriceScenario
  datasetStartTime: ISODateTime
  datasetEndTime: ISODateTime
  setCurrentBlockBid: (bid: FormattedBidWithIdAndTitle) => void
  setCurrentProfileBid: (bid: FormattedBidWithIdAndTitle) => void
  setTypeOfBidToCopy: (bidType: BidTypeName) => void
  currentView: 'tomorrow' | 'week' | ''
}

export default function ElectricityPriceScenarioVisualization({
  scenario,
  datasetStartTime,
  datasetEndTime,
  setCurrentBlockBid,
  setCurrentProfileBid,
  setTypeOfBidToCopy,
  currentView,
}: ElectricityPriceScenarioVisualizationProps): ReactElement {
  const {
    all_bids: showAllScenarioBids,
    bid_types: scenarioBidTypes,
    hide_optimize_button: hideOptimizeButton,
    id: scenarioId,
    optjob_subtypes: scenarioSubtypes,
    scenario_name: scenarioName,
    ui_config_id: uiConfigId,
    uiconfig_override_alias: uiConfigOverrideAlias,
  } = scenario
  const { systemId, activeSystem } = useAuth()
  const { data: uiConfigs } = useUiConfigById(uiConfigId)
  const { mutate: optJobCreate, status: jobCreationStatus } = useOptJobMutation()
  const { t } = useTranslation()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true, true)
  const elplanSnap = useSnapshot(elplanStore)
  const [blockBids, setBlockBids] = useState<FormattedBidWithIdAndTitle[]>([])
  const [profileBids, setProfileBids] = useState<FormattedBidWithIdAndTitle[]>([])

  const { mutate: createBid } = useElplanBidCreateMutation()
  const { mutate: updateBid } = useElplanBidUpdateMutation()
  const { mutate: deleteBid } = useElplanBidDeleteMutation()
  const { mutate: createSpotBids } = useCreateElplanBidsMutation()
  const { mutate: updateSpotBids } = useUpdateElplanBidsMutation()
  const { mutate: deleteSpotBidsFromDate } = useDeleteElplanBidsForDateMutation()

  const { scenarioHasSpotBids, scenarioHasBlockBids, scenarioHasProfileBids } = useMemo(() => {
    const systemHasSpotBids = elplanSnap.bid_types.some(
      (bid) => bid.type === 'spotbid_without_price' || bid.type === 'spotbid_with_price'
    )
    const scenarioHasSpotBids =
      (scenarioBidTypes ?? []).length === 0 ||
      (scenarioBidTypes ?? []).some((type) => type === 'spotbid_without_price' || type === 'spotbid_with_price')

    const systemHasBlockBids = elplanSnap.bid_types.some((bid) => bid.type === 'blockbid')
    const scenarioHasBlockBids =
      (scenarioBidTypes ?? []).length === 0 || (scenarioBidTypes ?? []).some((type) => type === 'blockbid')

    const systemHasProfileBids = elplanSnap.bid_types.some((bid) => bid.type === 'profilebid')
    const scenarioHasProfileBids =
      (scenarioBidTypes ?? []).length === 0 || (scenarioBidTypes ?? []).some((type) => type === 'profilebid')

    return {
      scenarioHasSpotBids: systemHasSpotBids && scenarioHasSpotBids,
      scenarioHasBlockBids: systemHasBlockBids && scenarioHasBlockBids,
      scenarioHasProfileBids: systemHasProfileBids && scenarioHasProfileBids,
    }
  }, [elplanSnap.bid_types, scenarioBidTypes])

  const onOptimize = useCallback(() => {
    const inputData: OptJobInputData = {
      opt_start_time: moment().startOf('hour').toISOString(),
      opt_end_time: undefined,
    }

    if (scenarioSubtypes !== undefined && scenarioSubtypes !== null) {
      inputData.include_subtypes = scenarioSubtypes
    }

    optJobCreate({
      opt_job_type: ELECTRICITY_PRICE_SCENARIO_JOB_TYPE,
      input_data: inputData,
    })
  }, [optJobCreate, scenarioSubtypes])

  const translatedScenarioName = translateKeyWithSpecificTranslations(scenarioName, digitalTwin?.translations)

  const { data: topicalBids } = useElplanBids(
    elplanSnap.elplan_info_id,
    datasetStartTime,
    datasetEndTime,
    showAllScenarioBids ? undefined : scenarioId
  )
  const allFormattedBids = useMemo(() => {
    if (!topicalBids)
      return {
        spotBids: [],
        blockBids: [],
        profileBids: [],
      }

    const formatted = topicalBids?.map((bid) => formatBid(bid))
    const spotBids = formatted?.filter((bid) => bid.type === 'spotbid_without_price')
    const blockBids = formatted?.filter((bid) => bid.type === 'blockbid')
    const profileBids = formatted?.filter((bid) => bid.type === 'profilebid')

    blockBids?.sort((a, b) => (a.block_order && b.block_order ? a.block_order - b.block_order : 0))
    setBlockBids(blockBids)
    profileBids?.sort((a, b) => (a.block_order && b.block_order ? a.block_order - b.block_order : 0))
    profileBids?.forEach((bid, index) => {
      bid.title = t('Profile bid') + (bid.block_order ? ` ${bid.block_order}` : ` ${index + 1}`)
    })
    setProfileBids(profileBids)

    return {
      spotBids: spotBids,
      blockBids: blockBids,
      profileBids: profileBids,
    }
  }, [t, topicalBids])

  const openBlockBidModal = useCallback(() => {
    const emptyBid = getDefaultBlockBid({
      startTime: datasetStartTime,
      endTime: datasetEndTime,
    })
    setCurrentBlockBid({ ...emptyBid, scenarioId })
  }, [datasetEndTime, datasetStartTime, scenarioId, setCurrentBlockBid])

  const openProfileBidModal = useCallback(() => {
    const emptyBid = getDefaultBlockBid({
      startTime: datasetStartTime,
      endTime: datasetEndTime,
    })

    setCurrentProfileBid({
      ...emptyBid,
      type: 'profilebid',
      title: t('Profile bid'),
      scenarioId,
      planValues: generatePlanValues(0, datasetStartTime, datasetEndTime),
    })
  }, [scenarioId, datasetEndTime, datasetStartTime, setCurrentProfileBid, t])

  const createSpotbidsFromElplan = useCallback(async () => {
    let bids: BidToCreate[] = []

    try {
      bids = await getSpotBidsFromOptElplan(datasetStartTime, datasetEndTime).then((spotBidsFromOptElplan) => {
        return spotBidsFromOptElplan.map((bid) => ({
          ...bid,
          time: bid.time as ISODateTime,
          volume: bid.volume !== undefined && !isNaN(bid.volume as number) ? bid.volume : 0,
        }))
      })
    } catch (err: any) {
      sentry.captureException(err)

      alertStore.error(t('Could not copy spot bids from production plan (data missing)'))
      return
    }
    createSpotBids({
      elplan_info: elplanSnap.elplan_info_id,
      type: 'spotbid_without_price',
      electricity_price_scenario: scenarioId,
      bids,
    })
  }, [createSpotBids, datasetEndTime, datasetStartTime, elplanSnap.elplan_info_id, scenarioId, t])

  const addNewSpotBidsForDate = useCallback(
    (spotBids: PlanValues[]) => {
      const bids = spotBids.map((bid) => {
        return { time: bid.start_time, volume: convertNumberDecimalSign(bid.volume) as number }
      })
      createSpotBids({
        elplan_info: elplanSnap.elplan_info_id,
        type: 'spotbid_without_price',
        electricity_price_scenario: scenarioId,
        bids,
      })
    },
    [createSpotBids, elplanSnap.elplan_info_id, scenarioId]
  )

  const editSpotBidsForDate = useCallback(
    (spotBids: PlanValues[]) => {
      const bidsToBeUpdated = spotBids
        .map((bid) => {
          return { id: bid.id, volume: convertNumberDecimalSign(bid.volume) }
        })
        .filter((bid) => bid.id)

      updateSpotBids({
        elplan_info: elplanSnap.elplan_info_id,
        bids: bidsToBeUpdated,
      })
    },
    [elplanSnap.elplan_info_id, updateSpotBids]
  )

  const removeBlockBid = useCallback(
    (bid: FormattedBidWithIdAndTitle) => {
      if (bid.id && !bid.fake) {
        deleteBid({ id: bid.id })
      }
    },
    [deleteBid]
  )

  const editBid = useCallback(
    (bid: FormattedBidWithIdAndTitle) => {
      updateBid({
        id: bid.id,
        start_time: Datetime.toISOString(bid.startTime),
        end_time: Datetime.toISOString(bid.endTime),
        volume: isArray(bid.volume)
          ? bid.volume.map((vol) => convertNumberDecimalSign(vol) as number)
          : convertNumberDecimalSign(bid.volume),
        price: convertNumberDecimalSign(bid.price),
        name: bid.name,
      })
    },
    [updateBid]
  )

  const addNewBid = useCallback(
    (bid: FormattedBid) => {
      createBid({
        elplan_info: elplanSnap.elplan_info_id,
        start_time: Datetime.toISOString(bid.startTime),
        end_time: Datetime.toISOString(bid.endTime),
        type: bid.type,
        block_order: bid.block_order,
        volume: isArray(bid.volume)
          ? bid.volume.map((vol) => convertNumberDecimalSign(vol) as number)
          : convertNumberDecimalSign(bid.volume),
        price: convertNumberDecimalSign(bid.price),
        name: bid.name || '-',
        electricity_price_scenario: scenarioId,
      })
    },
    [createBid, elplanSnap.elplan_info_id, scenarioId]
  )

  const removeSpotBidsForDate = useCallback(
    (date: ISODateTime) => {
      deleteSpotBidsFromDate({
        elplan_info: elplanSnap.elplan_info_id,
        date: Datetime.toLocalTime(date, 'onlyDate') as ISODateTime,
        type: 'spotbid_without_price',
      })
    },
    [deleteSpotBidsFromDate, elplanSnap.elplan_info_id]
  )

  const optimizeButtonLabel = useMemo(() => {
    if (scenarioSubtypes === undefined || scenarioSubtypes === null) {
      return t('Optimize all scenarios')
    }

    return `${t('Optimize')} ${translatedScenarioName}`
  }, [scenarioSubtypes, t, translatedScenarioName])

  return (
    <>
      {!hideOptimizeButton && (
        <div className={styles.ElectricityPriceScenarioVisualization_OptimizaButtonContainer}>
          <OptimizeButton
            type={ELECTRICITY_PRICE_SCENARIO_JOB_TYPE}
            optimizeLabel={optimizeButtonLabel}
            onClick={onOptimize}
            isValid={true}
            subtypes={scenarioSubtypes ?? []}
            creatingOptJob={jobCreationStatus === 'loading'}
          />
        </div>
      )}

      {uiConfigId === null ? (
        <UiConfig
          type="day_ahead_bid_management"
          datasetStartTime={datasetStartTime}
          datasetEndTime={datasetEndTime}
          datasetRefreshToken={ELPLAN_BID_REFRESH_EVENT_QUERY_KEY}
        />
      ) : (
        <UiConfigComponent
          systemId={systemId}
          id={uiConfigId}
          uid={uiConfigs?.at(0)?.uid}
          datasetStartTime={datasetStartTime}
          datasetEndTime={datasetEndTime}
          overrideAlias={uiConfigOverrideAlias}
          datasetRefreshToken={ELPLAN_BID_REFRESH_EVENT_QUERY_KEY}
        />
      )}

      <LineDivider />

      {(scenarioHasBlockBids || scenarioHasProfileBids) && (
        <div className={styles.ElectricityPriceScenarioVisualization_Tables}>
          <TitleWithIcon size="medium" title={t('Block bid editor')} />
          <div className={styles.ElectricityPriceScenarioVisualization_Tables__right}>
            {scenarioHasBlockBids && (
              <Button
                primary
                icon="fal fa-plus"
                marginRight
                disabled={blockBids?.length >= MAX_NUMBER_OF_BLOCK_BIDS}
                onClick={openBlockBidModal}
              >
                {t('Create block bid')}
              </Button>
            )}
            {scenarioHasProfileBids && (
              <Button
                primary
                icon="fal fa-plus"
                marginRight
                disabled={profileBids?.length >= MAX_NUMBER_OF_BLOCK_BIDS}
                onClick={openProfileBidModal}
              >
                {t('Create profile bid')}
              </Button>
            )}

            {scenarioHasBlockBids && (
              <Button
                onClick={() => {
                  setTypeOfBidToCopy('blockbid')
                }}
                secondary
                marginRight
                disabled={blockBids?.length ? true : false}
                icon={'fas fa-copy'}
              >
                {t('Copy block bids from a chosen date')}
              </Button>
            )}
            {scenarioHasProfileBids && (
              <Button
                onClick={() => {
                  setTypeOfBidToCopy('profilebid')
                }}
                secondary
                disabled={profileBids?.length ? true : false}
                icon={'fas fa-copy'}
              >
                {t('Copy profile bids from a chosen date')}
              </Button>
            )}
          </div>

          <BlockBidTable
            blockBids={allFormattedBids.blockBids.concat(allFormattedBids.profileBids)}
            createBid={addNewBid}
            updateBid={editBid}
            deleteBid={removeBlockBid}
            view={currentView}
          />
        </div>
      )}

      {scenarioHasSpotBids && (
        <div className={styles.ElectricityPriceScenarioVisualization_Tables}>
          <TitleWithIcon size="medium" title={t('Spot bid editor')} />
          <div className={styles.ElectricityPriceScenarioVisualization_Tables__right}>
            <Button
              onClick={() => {
                postStat('create-spotbids-from-plan', `click`)
                createSpotbidsFromElplan()
              }}
              secondary
              marginRight
              icon={'fas fa-copy'}
            >
              {t('Calculate spot bid')}
            </Button>
            <Button
              onClick={() => {
                setTypeOfBidToCopy('spotbid_without_price')
              }}
              secondary
              icon={'fas fa-copy'}
            >
              {t('Copy spot bids from a chosen date')}
            </Button>
          </div>
          <SpotBidTable
            currentDate={{ startTime: datasetStartTime, endTime: datasetEndTime }}
            spotBids={allFormattedBids.spotBids}
            createBid={(bid: PlanValues[]) => addNewSpotBidsForDate(bid)}
            updateBid={(bid: PlanValues[]) => editSpotBidsForDate(bid)}
            deleteBidForDate={(date: ISODateTime) => removeSpotBidsForDate(date)}
          />
        </div>
      )}
    </>
  )
}
