import { ReactElement, useEffect, useMemo, useState } from 'react'

import { Button, Inline, TextField , Select} from 'ui/atoms'
import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'

import { FormControl, Grid , Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { MODIFY_SETTING_COLUMN_RETURN_ID } from './constants'

export type ParseResult = {
  percentageInput: number | null
  mwInput: number | null
  selectedDataset: Dataset
}

type CalculateTimeSeriesSettingsFormProps = {
  disableCalculateButton?: boolean
  onCalculateClick: (parseResult: ParseResult) => void
  datasets: Dataset[],
  chartItems: ChartItem[]
}

export function CalculateTimeSeriesSettingsForm({ 
  datasets,
  chartItems,
  disableCalculateButton,
  onCalculateClick,
}: CalculateTimeSeriesSettingsFormProps) : ReactElement {
  const { t } = useTranslation()
  
  const [computableSettingPercentInputValueError, setComputableSettingPercentInputValueError] = useState(false)
  const [computableSettingMWInputValueError, setComputableSettingMWInputValueError] = useState(false)
  const [computableSettingPercentInputValue, setComputableSettingPercentInputValue] = useState('')
  const [computableSettingMWInputValue, setComputableSettingMWInputValue] = useState('')
  const [computableSettingErrorHelperText, setComputableSettingErrorHelperText] = useState('')
  
  const filteredDatasets = useMemo(
    () => datasets.filter(dataset => !!dataset.return_id 
      && dataset.return_id !== MODIFY_SETTING_COLUMN_RETURN_ID
      && dataset.values.filter(value => value !== null).length > 0
    ),
    [datasets]
  )
  const [selectedDataset, setSelectedDataset] = useState<Dataset>(filteredDatasets[0])
  const showDatasetSelect = useMemo(
    () => selectedDataset && filteredDatasets.length > 1,
    [filteredDatasets, selectedDataset]
  )

  useEffect(() => {
    if (typeof selectedDataset === 'undefined') {
      setSelectedDataset(filteredDatasets[0])
    }
  }, [filteredDatasets, selectedDataset])

  const onButtonClick = (): void => {
    if (!computableSettingPercentInputValue && !computableSettingMWInputValue) {
      setComputableSettingPercentInputValueError(true)
      setComputableSettingMWInputValueError(true)
      setComputableSettingErrorHelperText(t('You must enter a value to calculate'))
      return
    }

    let parsedPercentValue = computableSettingPercentInputValue?.length > 0
      ? parseFloat(computableSettingPercentInputValue)
      : null
    const parsedMWValue = computableSettingMWInputValue?.length > 0
      ? parseFloat(computableSettingMWInputValue)
      : null

    if (parsedPercentValue !== null && isNaN(parsedPercentValue)) {
      setComputableSettingPercentInputValueError(true)
      return
    }

    if (parsedMWValue !== null && isNaN(parsedMWValue)) {
      setComputableSettingMWInputValueError(true)
      return
    }

    if (parsedMWValue && parsedPercentValue) {
      setComputableSettingPercentInputValueError(true)
      setComputableSettingMWInputValueError(true)
      setComputableSettingErrorHelperText(t('Cannot calculate both at the same time, please clear one of the fields'))
      return
    }

    if (parsedPercentValue) {
      // Input is offset in percentage points, e.g. input "5" means 1.05 and "-5" means 0.95
      parsedPercentValue = 1 + (parsedPercentValue / 100)
    }

    setComputableSettingPercentInputValue('')
    setComputableSettingMWInputValue('')

    onCalculateClick({ percentageInput: parsedPercentValue, mwInput: parsedMWValue, selectedDataset })
  }

  const items = useMemo(() => {
    return chartItems
      .filter(chartItem => filteredDatasets.some(dataset => dataset.return_id === chartItem.data_id))
      .map(chartItem => ({ value: chartItem.data_id, label: chartItem.title }))
  }, [filteredDatasets, chartItems])
  
  return (
    <Grid container justifyContent="flex-end" marginBottom={'30px'}>
      <FormControl variant="outlined">
        {showDatasetSelect && (
          <Select
            value={selectedDataset.return_id}
            items={items} 
            onChange={(value: string | number | string[]) => {
              const dataset = datasets.find(dataset => dataset.return_id === value) || null
              if (dataset) {
                setSelectedDataset(dataset)
              }
            }}
          />
        )}
      </FormControl>
      <FormControl component="fieldset" variant="outlined">
        <Inline>
          <TextField 
            marginLeft={showDatasetSelect}
            label={t('Calculate percentage')}
            type="number"
            error={computableSettingPercentInputValueError}
            inputProps={{
              max: 100,
              min: 0,
            }}
            value={computableSettingPercentInputValue} 
            onChange={(value) => {
              setComputableSettingPercentInputValue(value as string)
              setComputableSettingMWInputValueError(false)
              setComputableSettingPercentInputValueError(false)
            }} 
          />
          <TextField 
            marginLeft
            label={t('Calculate MW')}
            type="number"
            error={computableSettingMWInputValueError}
            value={computableSettingMWInputValue} 
            onChange={(value) => {
              setComputableSettingMWInputValue(value as string)
              setComputableSettingMWInputValueError(false)
              setComputableSettingPercentInputValueError(false)
            }} 
          />
          <Button 
            marginLeft
            primary
            disabled={disableCalculateButton}
            disabledTooltip={t('You must select a field below to calculate')}
            onClick={onButtonClick}>
            {t('Calculate')}
          </Button>
        </Inline>
        {computableSettingMWInputValueError && (
          <Typography color="error" variant="caption">
            {t(computableSettingErrorHelperText)}
          </Typography>
        )}
      </FormControl>
    </Grid>
  )
}