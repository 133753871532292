import { apiClient } from 'api/apiClient/apiClient'

import { useMutation, useQuery, useQueryClient } from 'react-query'

export type EventType = `planned_load_reduction` | `planned_unavailability`

export type UnitAvailabilityPlannedStopItem = {
  event_type: EventType
  comment: string
  period: Period
}

export type UnitAvailabilityPlannedStopFormData = {
  id: number
  system_id: number
  event_type: EventType
  comment: string
  start_time: string
  end_time: string
  unit_name: string
}

export const AVAILABILITY_EVENT_QUERY_KEY = `unit_nameAvailabilityPlannedStop`

export function useUnitAvailabilityPlannedStop(systemId: number | undefined, unitName: string, period?: Period) {
  return useQuery(
    [
      AVAILABILITY_EVENT_QUERY_KEY,
      {
        system_id: systemId,
        unit_name: unitName,
        end_time: period?.endTime.endOf(`d`).format(),
        start_time: period?.startTime.startOf(`d`).format(),
      },
    ],
    () =>
      apiClient<UnitAvailabilityPlannedStopFormData[]>(`unit_availability_planned_stops`, {
        params: {
          system_id: systemId,
          unit_name: unitName,
          end_time: period?.endTime.endOf(`d`).format(),
          start_time: period?.startTime.startOf(`d`).format(),
        },
      }),
    {
      enabled: !!unitName,
    }
  )
}

export function useUnitAvailabilityPlannedStopMutation() {
  const postUnitAvailabilityPlannedStop = (data: UnitAvailabilityPlannedStopFormData) =>
    apiClient(`unit_availability_planned_stop`, { data })

  const patchUnitAvailabilityPlannedStop = (id: number, data: UnitAvailabilityPlannedStopFormData) =>
    apiClient(`unit_availability_planned_stop/${id}/update`, { data, method: `PATCH` })

  const postOrPatchUnitAvailabilityPlannedStop = ({
    data,
    id,
  }: {
    data: UnitAvailabilityPlannedStopFormData
    id?: number
  }) => {
    if (id !== undefined) {
      return patchUnitAvailabilityPlannedStop(id, data)
    }
    return postUnitAvailabilityPlannedStop(data)
  }

  const queryClient = useQueryClient()

  return useMutation(postOrPatchUnitAvailabilityPlannedStop, {
    onSuccess: () => {
      queryClient.invalidateQueries(AVAILABILITY_EVENT_QUERY_KEY)
    },
  })
}

export function useUnitAvailabilityPlannedStopDeleteMutation() {
  const deleteUnitAvailabilityPlannedStop = (id: number) =>
    apiClient<{ id: number }>(`unit_availability_planned_stop/${id}/delete`, { method: `DELETE` })

  const queryClient = useQueryClient()

  return useMutation(deleteUnitAvailabilityPlannedStop, {
    onSuccess: () => {
      queryClient.invalidateQueries(AVAILABILITY_EVENT_QUERY_KEY)
    },
  })
}
