import React, { ReactElement, useState } from 'react'


import { GetSystemsFromOrganization, useCreateNewUser, useRolesFromOrganization } from 'api/admin/admin.api'
import { Button, Card } from 'ui/atoms'
import AboutPermissions from 'ui/components/AboutPermissions/AboutPermissions'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import Dropdown from 'ui/components/Dropdown/Dropdown'
import FormRow from 'ui/components/FormRow/FormRow'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import View from 'ui/components/View/View'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'

import { DialogContent, Grid, Icon, IconButton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { clone } from 'helpers/global.helper/global.helper'

import styles from './AdminSettingsView.module.less'
import UsersList from './components/UsersList/UsersList'

export type NewUserData = {
  first_name: string,
  last_name: string,
  email: string,
  primary_system: number | undefined,
  primary_role: number | undefined,
  system_roles: {system: number | undefined, role: number | undefined}[]
}

export default function AdminSettingsView(): ReactElement {
  const { t } = useTranslation()
  const { mutateAsync: createUser } = useCreateNewUser()
  const { data: systemsFromOrg = [] } = GetSystemsFromOrganization()
  const { data: rolesFromOrg = [] } = useRolesFromOrganization()

  const [isOpen, setIsOpen] = useState(false)
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
  const [newSystem, setNewSystem] = useState<{ system: number | undefined, role: number | undefined}[]>([])
  const [newUser, setNewUser] = useState<NewUserData>({
    first_name: '',
    last_name: '',
    email: '',
    primary_system: undefined,
    primary_role: undefined,
    system_roles: [],
  })

  function addSelectField(): void {
    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    const values = newUser.system_roles
    values.push({ system: undefined, role: undefined })
    const user = {...newUser, system_roles: values }
    setNewUser(user)
  }

  function insertAllSystemsToRoleList(): void {
    const systemRoles = systemsFromOrg
      .filter(({ id }) => id !== newUser.primary_system)
      .map((s) => {
        return { system: s.id, role: undefined }
      })

    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    setNewUser({
      ...newUser,
      system_roles: systemRoles,
    })
  }

  function assignRoleToAllSystems(): void {
    const systemRoles = systemsFromOrg
      .filter(({ id }) => id !== newUser.primary_system)
      .map((s) => {
        return { system: s.id, role: newUser.primary_role }
      })

    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    setNewUser({
      ...newUser,
      system_roles: systemRoles,
    })
  }

  function removeSelectField(index: number): void {
    const systemRoles = newUser.system_roles
    systemRoles.splice(index, 1)
    setNewUser({
      ...newUser,
      system_roles: systemRoles,
    })
  }

  const handleOpenDialog = (): void => {
    setNewUser({
      first_name: '',
      last_name: '',
      email: '',
      primary_system: undefined,
      primary_role: undefined,
      system_roles: [],
    })
    setIsOpen(true)
  }

  return (
    <View
      header={
        <ModuleHeader infoHeader={t(`Admin settings`)} />
      }
    >

      <Card
        width='100%'
        collapsible
        margin
        title={<TitleWithIcon size='medium' title={t(`Users at this organization:`)} />} >
        <Button
          primary
          icon='fal fa-plus'
          onClick={() => {
            handleOpenDialog()
          } }>
          {t(`Add user`)}
        </Button>
        <UsersList/>
      </Card>

      <Card
        width='100%'
        collapsible
        defaultCollapsed
        margin
        title={<TitleWithIcon size='medium' title={t(`About permissions`)} />}
      >
        <AboutPermissions />
      </Card>

      <Dialog
        fullWidth
        maxWidth='lg'
        open={isOpen}
        aria-labelledby="Add user"
      >
        <>
          <CustomDialogTitle title={t(`Add user`)} handleClose={() => setCloseConfirmOpen(true)} />

          <DialogContent className={styles.AdminSettingsView_DialogContent} dividers>
            <div className={styles.AdminSettingsView_Container}>
              <FormRow>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`First name`)}*`}
                    type='text'
                    value={newUser.first_name}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setNewUser({
                        ...newUser,
                        first_name: target.value,
                      })
                    }}
                  />
                </Grid>

                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`Last name`)}*`}
                    type='text'
                    value={newUser.last_name}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setNewUser({
                        ...newUser,
                        last_name: target.value,
                      })
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`Email`)}*`}
                    type='text'
                    value={newUser.email}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setNewUser({
                        ...newUser,
                        email: target.value,
                      })
                    }}
                  />
                </Grid>
                <p className={styles.AdminSettingsView_RequiredFields}>{t(`* Required fields`)}</p>

              </FormRow>
              <FormRow>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <Dropdown
                    fullWidth
                    items={systemsFromOrg.map((s) => ({ label: s.display_name, value: s.id }))}
                    label={`${t(`Primary system`)}*`}
                    value={newUser?.primary_system ?? 0}
                    handleChange={(e) => setNewUser({
                      ...newUser,
                      primary_system: e.target.value as number,
                    })}

                  />
                </Grid>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <Dropdown
                    fullWidth
                    items={rolesFromOrg.map((s) => ({ label: t(`${s.display_name}`) ?? s.display_name, value: s.id }))}
                    label={`${t(`Role for primary system`)}*`}
                    value={newUser?.primary_role ?? 0}
                    handleChange={(e) => setNewUser({
                      ...newUser,
                      primary_role: e.target.value as number,
                    })}
                  />
                </Grid>

                {newUser?.system_roles.map((userSystemRole, index) => (
                  <Grid item md={12} sm={12} style={{ margin: 5 }}>
                    <div>
                      <Dropdown
                        style={{ width: '43.5%', marginRight: 5 }}
                        items={systemsFromOrg.map((s) => ({ label: s.display_name, value: s.id }))}
                        label={t(`System`)}
                        value={userSystemRole.system ?? 0}
                        handleChange={(e) => {
                          const systemRoles = clone(newUser.system_roles)
                          systemRoles[index] = {
                            ...userSystemRole,
                            system: e.target.value as number,
                          }
                          setNewUser({ ...newUser, system_roles: systemRoles })
                        }
                        }
                      />
                      <Dropdown
                        style={{ width: '43.5%' }}
                        items={rolesFromOrg?.map((s) => ({ label: t(`${s.display_name}`) ?? s.display_name, value: s.id }))}
                        label={t(`Role`)}
                        value={userSystemRole.role ?? 0}
                        handleChange={(e) =>{
                          const systemRoles = clone(newUser.system_roles)
                          systemRoles[index] = {
                            ...userSystemRole,
                            role: e.target.value as number,
                          }
                          setNewUser({ ...newUser, system_roles: systemRoles })
                        }}
                      />

                      <IconButton
                        onClick={() => removeSelectField(index)}
                        aria-label={t('Delete user')}
                        size="large">
                        <Icon style={{color: 'red'}} fontSize='small' className="fal fa-trash-alt" />
                      </IconButton>
                    </div>
                  </Grid>
                ))}                               
                <Button icon={'fal fa-plus'} marginTop onClick={addSelectField}>{t('Add another system role')}</Button>
                {systemsFromOrg.length > 1 ? 
                  <>
                    <Button icon={'fal fa-plus'} marginTop onClick={insertAllSystemsToRoleList}>{t('Assign role to all systems...')}</Button>
                    {newUser.primary_role && <Button icon={'fal fa-plus'} marginTop onClick={assignRoleToAllSystems}>{t('Assign primary role to all systems')}</Button>}
                  </> : null}                
              </FormRow>
            </div>

            <ModalButtons
              icon='fal fa-plus'
              submitLabel={t('Create user')}
              disableSubmit={!newUser.first_name || !newUser.last_name || !newUser.email || !newUser.primary_system || !newUser.primary_role}
              onCancel={() => setCloseConfirmOpen(true)}
              onSubmit={async () => {
                await createUser({
                  data: {
                    ...newUser,
                    system_roles: [
                      // Primary system role is first element
                      {
                        system: newUser.primary_system,
                        role: newUser.primary_role,
                      },

                      // Then any additional system roles
                      ...newUser.system_roles,
                    ],
                  },
                })
                setIsOpen(false)
              } }
            />
          </DialogContent>
        </>
      </Dialog>

      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          setCloseConfirmOpen(false)
          setIsOpen(false)
        }}
        textObject={{
          title: t(`Do you really want to close without saving?`),
          text: t(
            `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
          ),
        }} />

    </View>
  )
}
