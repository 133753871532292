import React, { ReactElement } from 'react'

import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'

import { DialogTitle, Typography, Grid } from '@mui/material'

type CustomDialogTitleProps = {
  title: string | ReactElement
  info_text?: string | React.ReactNode
  style?: React.CSSProperties
  handleClose: () => void
}

export default function CustomDialogTitle({ 
  title,
  handleClose, 
  info_text, 
  style,
}: CustomDialogTitleProps): ReactElement {
  return (
    <DialogTitle id="form-dialog-title">
      <Grid container alignItems="flex-start" justifyContent="space-between" direction="row" style={style}>
        <Typography variant="h2" style={{ fontWeight: 'bold' }} display="inline" alignSelf='center'>
          {title}
        </Typography>
        <Typography variant="h3" alignSelf='center'>
          {info_text}
        </Typography>
        <PopoverCloseButton handleClose={handleClose} />
      </Grid>
    </DialogTitle>
  )
}
