import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { useGetEventCategories } from 'api/events/events.api'
import { Switch } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import BarCalendar from 'ui/components/BarCalendar/BarCalendar'
import { DatePickerPeriod } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import EventPostModal from '../EventPostModal/EventPostModal'
import { 
  compareEvents, 
  EVENT_TYPES, 
  EventPostListItem, 
  EventStatus, 
  getColorForEventStatus, 
  getLabelForEventStatus,
} from '../events.helper'

import styles from './EventsCalendar.module.less'

export type CalendarEvent = {
  id: string;
  attribute: string;
  type: string;
  startTime: ISODateTime;
  endTime: ISODateTime;
  categoryId: string;
  name: string;
  meta: {
    tooltipElement: string;
    clickable: boolean;
    value: string;
  };
  tooltipHeader: string;
  color: string;
};

type EventsCalendarProps = {
  events: EventPostListItem[]
  initDatePeriod: 'productionPlanPeriod' | 'week' | 'month' | 'custom'
  datePeriod: { startTime: ISODateTime, endTime: ISODateTime }
  setDatePeriod: (startTime: ISODateTime, endTime: ISODateTime) => void
}

export const transformEventToCalendarEvent = (events: EventPostListItem[], t: (key: string) => string): CalendarEvent[] => {
  return events.map(event => {
    const eventType = EVENT_TYPES.find((e) => e.value === event.operational_event_type)
    return {
      id: event?.id ? event.id.toString() : '',
      attribute: event?.linked_objects?.[0]?.attribute || '',
      type: event.name || '',
      startTime: event.start_time,
      endTime: event.end_time,
      categoryId: event?.category_id ? event.category_id.toString() : '',
      name: event.name || '',
      require_umm: event.require_umm,
      meta: {
        tooltipElement: eventType ? t(eventType.label) : '',
        clickable: true,
        value: getLabelForEventStatus(event.status as EventStatus),
      },
      tooltipHeader: event.name || '',
      color: getColorForEventStatus(event.status as EventStatus),
    }
  })
}

export default function EventsCalendar({ events, initDatePeriod, datePeriod, setDatePeriod }: EventsCalendarProps): ReactElement {
  const { t } = useTranslation()
  const { systemId } = useAuth()

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedEventPost, setSelectedEventPost] = useState<EventPostListItem | undefined>(undefined)

  const handleRowClick = (row: { selectedEvent: EventPostListItem }): void => {
    setSelectedEventPost(undefined)
    setSelectedEventPost(row.selectedEvent)
    setModalOpen(true)
  }

  const eventCategories = useGetEventCategories(systemId).data || []

  const calendarCategories = eventCategories.map(category => ({
    'id': category.id?.toString() || '',
    'name': t(category.display_name as string),
  }))

  const sortedEvents = useMemo(() => [...events].sort(compareEvents).reverse(), [events])

  const calendarEvents = transformEventToCalendarEvent(sortedEvents, t)
  const [switchValue, setSwitchValue] = useState(initDatePeriod)

  const switchItems = [
    {
      value: `productionPlanPeriod`,
      label: t(`Production plan period`),
    },
    {
      value: `week`,
      label: t(`Week`),
    },
    {
      value: `month`,
      label: t(`Month`),
    },
    {
      value: `custom`,
      label: t(`Custom`),
    },
  ]

  useEffect(() => {
    if (switchValue === 'month') {
      const time = Datetime.getStartTimeEndTimeOfMonthByDate(Datetime.getISONow())
      setDatePeriod(time.startTime, time.endTime)
    } else if (switchValue === 'week') {
      const time = Datetime.getStartTimeEndTimeOfWeek()
      setDatePeriod(time.startTime, time.endTime)
    } else if (switchValue === 'productionPlanPeriod') {
      const startTimeProductionPlan = Datetime.getStartOfDay(Datetime.getISONow(0))
      const endTimeProductionPlan = Datetime.getEndOfDay(Datetime.getISONow(168))
      setDatePeriod(startTimeProductionPlan, endTimeProductionPlan)
    }
  }, [setDatePeriod, switchValue])

  return (
    <>
      <div>
        <div className={styles.EventsCalendar_Switch}>
          <Switch key={switchValue} value={switchValue} onClick={(value) => setSwitchValue(value)} items={switchItems} />
        </div>
        <div className={`${styles.EventsCalendar_CustomDatePicker} ${switchValue === 'custom' ? styles.EventsCalendar_CustomDatePickerVisible : ''}`}>
          {switchValue === 'custom' && (
            <DatePickerPeriod
              startDate={datePeriod.startTime}
              endDate={datePeriod.endTime}
              onSelect={(startTime, endTime) => {
                setDatePeriod(startTime || datePeriod.startTime, endTime || datePeriod.endTime)
              }}
              minDate={Datetime.toISOString('2000-01-01T00:00:00+00:00')}
            />
          )}
        </div>
      </div>
      <BarCalendar
        startTime={datePeriod.startTime}
        endTime={datePeriod.endTime}
        events={calendarEvents}
        categories={calendarCategories}
        onClick={(event) => {
          handleRowClick({ selectedEvent: events.find(e => e.id === parseInt(event.id.toString())) as EventPostListItem })
        } }
        header={t('Events')}
        isOperationalEventCalendar
        disableDefaultSorting={true}
      />
      {modalOpen && (
        <EventPostModal
          closeModal={() => setModalOpen(false)}
          selectedEventPostId={selectedEventPost?.id}
        />
      )}
    </>
  )
}
