import React, { ReactElement } from 'react'

import { Setting } from 'api/settings/settings.api'
import ActiveSettingText from 'ui/components/ActiveSettingText/ActiveSettingText'

import { Icon , Tooltip as MuiTooltip } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'


import styles from './SettingsStatusField.module.less'

type SettingsStatusFieldProps = {
  value: Setting | null
}

export default function SettingsStatusField({ value }: SettingsStatusFieldProps): ReactElement {
  const { t } = useTranslation()

  return value ? (
    <MuiTooltip
      arrow
      placement="top"
      title={<ActiveSettingText startTime={value.start_time} endTime={value.end_time} />}
    >
      <Icon className={classNames(styles.SettingsStatusField_Exclamation, `fas fa-exclamation-circle`)} />
    </MuiTooltip>
  ) : (
    <MuiTooltip arrow placement="top" title={t(`No deviation`)}>
      <Icon className={classNames(styles.SettingsStatusField_Check, `fas fa-check-circle`)} />
    </MuiTooltip>
  )
}
