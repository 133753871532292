import React, { ReactElement } from 'react'

import Badge from 'ui/atoms/Badge/Badge'

type UiConfigBadgeProps = {
  uiConfig: Pick<UiConfig, 'is_published' | 'mode'>
  absolute?: boolean
}

export default function UiConfigBadge({ uiConfig, absolute = true }: UiConfigBadgeProps): ReactElement {
  if (uiConfig.is_published) {
    return <Badge success absolute={absolute} right title="Pub" />
  }
  if (uiConfig.mode === `dev`) {
    return <Badge warning absolute={absolute} right title="Dev" />
  }
  if (uiConfig.mode === `beta`) {
    return <Badge info absolute={absolute} right title="Beta" />
  }
  if (uiConfig.mode === `deleted`) {
    return <Badge danger absolute={absolute} right title="Del" />
  }

  return <></>
}
