import { apiClient } from 'api/apiClient/apiClient'
import { BulletinObjectProperty } from 'api/bulletinBoard/bulletinBoard.api'
import { DefaultPageNumberPagination } from 'api/types'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'

export type EventCategory =
  | `optimization_regular`
  | `optimization_followup_with_deviations`
  | `optimization_followup_without_deviations`
  | `optimization_measvalues_calculations`
  | `optimize_setting`
  | `optimization_sandbox`
  | `forecast_load`
  | `forecast_elprice`

export type EventType =
  | `setting_added`
  | `setting_edited`
  | `setting_comment_added`
  | `automatic_optimization_enabled`
  | `automatic_optimization_disabled`
  | `optimization_finished`
  | `forecast_elprice_updated`
  | `forecast_load_updated`
  | `measdata_updated`
  | `optimization_failed`

export type OptimizationReferenceField = { start_time: string; end_time?: string }
export type SettingReferenceField = {
  object_property: BulletinObjectProperty
  start_time: string
  end_time: string
  value: number
}

type ReferenceFields = SettingReferenceField | OptimizationReferenceField

export function isSettingReferenceFields(referenceFields: ReferenceFields): referenceFields is SettingReferenceField {
  return (referenceFields as SettingReferenceField).object_property !== undefined
}

export type Event = {
  id: number
  event_category: EventCategory
  event_type: EventType
  reference_fields: ReferenceFields
  updated_at: string
  triggered_by: number | null
}

type EventResponse = DefaultPageNumberPagination<Event[]>

export const SYSTEM_EVENTS_QUERY_KEY = `systemEvents`

export function useSystemEvents({
  startDate,
  endDate,
  queryConfig,
}: {
  startDate: string
  endDate: string
  queryConfig?: UseInfiniteQueryOptions<EventResponse, unknown>
}) {
  const { activeSystem } = useAuth()
  return useInfiniteQuery<EventResponse>(
    [SYSTEM_EVENTS_QUERY_KEY, { system: activeSystem?.id, startDate, endDate }],
    ({ pageParam = 1 }) =>
      apiClient<EventResponse>(`system_events`, {
        params: {
          system: activeSystem?.id,
          updated_at__gte: startDate,
          updated_at__lte: endDate,
          page: pageParam,
        },
      }),
    {
      ...queryConfig,
      getNextPageParam: (lastPage) => lastPage.next,
      getPreviousPageParam: (lastPage) => lastPage.previous,
      enabled: !!activeSystem && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
    }
  )
}
