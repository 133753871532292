import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery } from 'react-query'

export const NODES_QUERY_KEY = `nodes`

export type Node = DisplayNameObject & {
  id: number
  weather_locations: {
    weather_location: number
    weather_source: number
  }[]
  demand_location: number
  demand_weather_source: number
  opt_model: number
  units: number[]
  props: string[]
}

export function useNodes() {
  const { activeSystem } = useAuth()
  return useQuery(
    [NODES_QUERY_KEY, { system: Number(activeSystem?.id) }],
    () =>
      apiClient<Node[]>(`nodes`, {
        params: {
          opt_model: activeSystem?.primary_opt_model?.id,
        },
      }),
    { enabled: !!activeSystem?.primary_opt_model }
  )
}
