import React, { ReactElement, useState } from 'react'

import { useCreateUiConfigMutation } from 'api/uiConfig/uiConfig.api'
import authStore from 'store/auth/auth'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { Button } from 'ui/atoms'
import { Dialog } from 'ui/components/Dialog/Dialog'
import { getAllComponentNames, getAllSingletonComponentNames, getComponentTitle } from 'ui/uiConfig/factory'
import templateUiConfigs, { modules } from 'ui/uiConfig/template/templateUiConfigs'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './CreateNewUiConfig.module.less'

export default function CreateNewUiConfig(): ReactElement {
  const { t } = useTranslation()
  const { mutate: createUiConfig } = useCreateUiConfigMutation()
  const [isOpen, setIsOpen] = useState(false)
  const singletonComponentNames = new Set(getAllSingletonComponentNames())
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const authSnap = useSnapshot(authStore)
  const existingComponentNames = new Set(
    Object.values(uiConfigSnap.uiConfigs)
      .filter((uiConfig) => uiConfig.system === authSnap.systemId)
      .map((uiConfig) => uiConfig.component)
  )
  const componentNames = getAllComponentNames().filter(
    (componentName) => !existingComponentNames.has(componentName) || !singletonComponentNames.has(componentName)
  )

  function createNewUiConfig(component: string): void {
    createUiConfig({ component })
    setIsOpen(false)
  }

  function generateUiConfigWithDefaultData(defaultData: UiConfig): void {
    const component = defaultData?.component || `chart`
    createUiConfig({ component, defaultData })
    setIsOpen(false)
  }

  const templates: (UiConfig | null)[] = templateUiConfigs
    .map((t) => {
      if (typeof t === 'function') {	
        return t()
      }

      return t
    })
    .filter((t) => t !== null && t.component)
    .filter((t) => !singletonComponentNames.has(t?.component as string) || !existingComponentNames.has(t?.component as string))

  return (
    <div className={styles.CreateNewUiConfig}>
      <Button primary onClick={() => setIsOpen(true)}>
        {t(`Create`)}
      </Button>

      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={styles.CreateNewUiConfigDialog}>
          <h2>{t(`Create a new Ui Config`)}</h2>
          <div className={styles.CreateNewUiConfigDialog_Buttons}>
            {componentNames
              .filter((componentName) => !singletonComponentNames.has(componentName))
              .map((componentName) => (
                <Button key={componentName} secondary onClick={() => createNewUiConfig(componentName)}>
                  {getComponentTitle(componentName)}
                </Button>
              ))}
          </div>

          <h3>{t(`Anchor components`)}</h3>

          <div className={styles.CreateNewUiConfigDialog_Buttons}>
            {componentNames
              .filter((componentName) => singletonComponentNames.has(componentName))
              .map((componentName) => (
                <Button
                  key={componentName}
                  secondary
                  danger={singletonComponentNames.has(componentName)}
                  onClick={() => createNewUiConfig(componentName)}
                >
                  {getComponentTitle(componentName)}
                </Button>
              ))}
          </div>

          <h3>Generate modules with use_default</h3>
          {modules.filter(({ module }) => !singletonComponentNames.has(module) || !existingComponentNames.has(module))
            .map(({ module, generator, altTitle }) => (
              <Button marginRight marginTop danger onClick={generator} key={`module_generator_${altTitle || module}`}>
                {altTitle || module}
              </Button>
            ))}

          <h3>Create from template</h3>
          {templates.map((templateUiConfig, index) => (
            templateUiConfig && <Button
              marginRight
              marginTop
              danger link
              onClick={() => generateUiConfigWithDefaultData(templateUiConfig)}
              key={`${index}_${templateUiConfig.display_name}`}
            >
              {templateUiConfig.display_name || templateUiConfig.component}
            </Button>
          ))}
        </div>
      </Dialog>
    </div>
  )
}
