/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './Checkbox.module.less'

export type CheckboxProps = {
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  background?: boolean
  label: string
  isChecked?: boolean
  largeText?: boolean
}

export default function Checkbox({ onClick, background, label, isChecked, largeText }: CheckboxProps): ReactElement {
  return (
    <div
      onClick={(ev) => {

        onClick && onClick(ev)
      }}
      className={dynamicClassName({
        [styles.Checkbox_Background]: true,
        [styles.Checkbox_Background__color]: !!background,
      })}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <input type="checkbox" id="checkbox" name={label} value={label} checked={isChecked} onChange={() => {}} />
      <label htmlFor={label} className={dynamicClassName({
        [styles.Checkbox_Label]: true,
        [styles.Checkbox_Label_largeText]: !!largeText,
      })}>{label}</label>
    </div>
  )
}
