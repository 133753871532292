import React, { ReactElement, useState } from 'react'

import { PlanValues } from 'api/elplan/elplan.api'
import Datetime from 'utils/datetime/datetime'
import { formatNumberString } from 'utils/stringUtils'

import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'


import styles from './TimeEffectTable.module.less'

export interface TimeEffectTableProps {
  currentPlanValues?: PlanValues[]
  onCellChange: (value: number, index: number) => void
}

export default function TimeEffectTable({ currentPlanValues = [], onCellChange }: TimeEffectTableProps): ReactElement {
  const { t } = useTranslation()

  const [planValues, setPlanValues] = useState(currentPlanValues.map((value) => `${value.volume}`))

  const halfOfHourValues = Math.ceil(planValues.length / 2)

  const timeEffectValues = currentPlanValues.map((value, index) => {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <tr key={index} className={styles.ElectricityPlanModal_Table_Container}>
        <td className={styles.ElectricityPlanModal_Table_Cell}>
          <span className={styles.ElectricityPlanModal_Table_Cell_Time}>
            {`${Datetime.toLocalTime(value.start_time, 'hour')}-${Datetime.toLocalTime(Datetime.ISODatetimeTo59Minutes(value.start_time), 'hour')}`}
          </span>
          <TextField
            className={styles.ElectricityPlanModal_Table_Cell_EditableContent}
            id="value.volume"
            variant="outlined"
            value={planValues[index]}
            type="text"
            onBlur={(e) => {
              const valueString = e.target.value

              // Don't propagate the event if the value is not a number. Just a '-' is not added to the bid until a number is entered after it.
              const newValue = valueString === '' ? 0 : parseFloat(valueString)

              if (!isNaN(newValue)) {
                onCellChange(newValue, index)
              }
            }}
            onChange={(e) => {
              const newValue = formatNumberString(e.target.value)

              if (newValue === undefined) {
                return // Latest character is invalid, no need to update the value
              }

              const updatedPlanValues = [...planValues]
              updatedPlanValues[index] = newValue
              setPlanValues(updatedPlanValues)
            }}
            size="small"
          />
        </td>
      </tr>
    )
  }, [currentPlanValues])

  return (
    <div className={styles.ElectricityPlanModal}>
      <div className={styles.ElectricityPlanModal_Header}>{t('Volume') + ` (MW)`}</div>
      <table className={styles.ElectricityPlanModal_Table}>
        <tbody className={styles.ElectricityPlanModal_Table_Body}>
          {timeEffectValues.slice(0, halfOfHourValues)}
        </tbody>
        <tbody className={styles.ElectricityPlanModal_Table_Body}>
          {timeEffectValues.slice(halfOfHourValues)}
        </tbody>
      </table>
    </div>
  )
}