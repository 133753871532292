import { useCallback, useState } from 'react'

import { Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TimeSeriesDataTableColumnDef } from '../TimeSeriesDataTable/types'
import getRowIndex from '../TimeSeriesDataTable/utils/getRowIndex'

import styles from './TimeSeriesSettingTable.module.less'
import { useShiftKeySelected } from './useShiftKeySelected'

export function useCustomCheckboxColumn({ 
  datasets, 
  enableComputableSettings,
}: { 
  datasets: Dataset[]
  enableComputableSettings?: boolean
}): [TimeSeriesDataTableColumnDef[], number[], () => void] {
  const { t } = useTranslation()
  const [indicesToCompute, setIndicesToCompute] = useState<number[]>([])
  const shiftKeyPressed = useShiftKeySelected()
  
  const columns: TimeSeriesDataTableColumnDef[] = []
  if (enableComputableSettings) {
    columns.push({
      accessorKey: `computed`,
      enableColumnActions: false,
      enableEditing: true,
      muiTableHeadCellProps: {
        align: 'center',
      },
      align: 'center',
      maxSize: 85,
      minSize: 85,
      header: '',
      Header: ({ table }) => {
        const { pageSize, pageIndex } = table.getState().pagination
        const currentPageStartIndex = pageIndex * pageSize
        const currentPageEndIndex = Math.min((pageIndex + 1) * pageSize, datasets[0].values.length) - 1
        const range = Array.from(
          { length: currentPageEndIndex - currentPageStartIndex + 1 },
          (_, i) => i + currentPageStartIndex
        )
        const allOfCurrentPageAreSelected = indicesToCompute.length === Math.min(datasets[0].values.length, pageSize)
        return (
          <>
            <div className={styles.DatasetTableHeader}>
              <span>{t(`Computed setting`)}</span>
            </div>
            <div>
              <FormControlLabel 
                label={t('Select all')} 
                labelPlacement="top" 
                componentsProps={{ typography: { fontSize: '12px', fontWeight: 200 }}}
                control={
                  <Checkbox 
                    checked={allOfCurrentPageAreSelected}
                    onChange={(_event, checked) => {
                      const newSettingsDataIndicesToComputed = checked
                        ? range
                        : []
                      setIndicesToCompute(newSettingsDataIndicesToComputed)
                    }} 
                  />
                }
              />
            </div>
          </>
        )
      },
      Cell: ({ renderedCellValue }) => <span style={{ whiteSpace: `nowrap`}}>{renderedCellValue}</span>,
      Edit: ({ row, table }) => {
        const datasetIndex = getRowIndex(row, table.getState().pagination)
        return (
          <Checkbox 
            checked={indicesToCompute.includes(datasetIndex)}
            onChange={(_event, checked) => {
              if (!checked) {
                setIndicesToCompute(indicesToCompute.filter(index => index !== datasetIndex))
                return
              }

              let indicesToAdd = []
              if (shiftKeyPressed && indicesToCompute.length > 0) {
                const firstIndex = indicesToCompute[indicesToCompute.length - 1]
                const lastIndex = datasetIndex
                const newSettingsDataIndicesToComputed = Array.from(
                  { length: Math.abs(lastIndex - firstIndex) + 1 },
                  (_, i) => Math.min(firstIndex, lastIndex) + i
                )
                indicesToAdd = newSettingsDataIndicesToComputed
              } else {
                indicesToAdd.push(datasetIndex)
              }

              setIndicesToCompute(Array.from(new Set([...indicesToCompute, ...indicesToAdd])))
            }} 
          />
        )
      },
    })
  }

  const reset = useCallback(() => {setIndicesToCompute([])}, [])
  return [columns, indicesToCompute, reset]
}