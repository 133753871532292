import React, { ReactElement } from 'react'

import { Switch as MuiSwitch, FormControlLabel } from '@mui/material'

type ToggleProps = {
  label?: string
  value: boolean
  size?: 'small' | 'medium'
  color?: 'default' | 'primary' | 'secondary'
  toggleStyling?: CSSModuleClasses
  onClick: (value: boolean) => void
}

export default function Toggle({ label = ``, value, size, color, toggleStyling, onClick }: ToggleProps): ReactElement {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiSwitch
          color={color}
          aria-label={label}
          value={value}
          checked={!!value}
          size={size}
          classes={toggleStyling}
          onChange={(e) => onClick(e.target.checked)}
        />
      }
    />
  )
}
