import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { DigitalTwinSetting } from 'api/digitalTwin/digitalTwin.api'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import CustomTable from 'ui/components/CustomTable/CustomTable'
import TimeField from 'ui/components/CustomTable/components/TimeField/TimeField'
import CommentField from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/PreviousSettingsTables/components/CommentField/CommentField'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Datetime from 'utils/datetime/datetime'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { isActiveSetting, isHistorySetting } from 'helpers/optimize.helper/optimize.helper'
import { getLabelForAttributeValue } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import EventPostViewModal from 'views/EventsView/components/EventPostViewModal/EventPostViewModal'
import { EventPost, EventProperties } from 'views/EventsView/components/events.helper'

import styles from './PreviousSettingsTable.module.less'

type DigitalTwinPreviousSettingsTableProps = {
  allSettingsForChosenProperty: DigitalTwinSetting[]
  onEditSetting: (setting: DigitalTwinSetting) => void
  permissions: SettingsPermissions
  onEventPostModalClose?: () => void,
  operatinalEvents?: Map<number, EventPost>,
  eventPost?: {
    isOperationalEvent: boolean,
    eventId: number | null,
    linked_objects?: EventProperties | null
    index?: number | null
  }
}

export default function PreviousSettingsTable({
  allSettingsForChosenProperty,
  onEditSetting,
  permissions,
  operatinalEvents,
  onEventPostModalClose,
  eventPost = {
    isOperationalEvent: false,
    eventId: null,
    linked_objects: null,
    index: null,
  },
}: DigitalTwinPreviousSettingsTableProps): ReactElement {
  const { t } = useTranslation()

  const [openEventPostModal, setOpenEventPostModal] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState<number | null>()

  const handleEventClick = useCallback((eventId: number | null): void => {
    if (eventId === null) {
      return
    }
    setSelectedEventId(eventId)
    setOpenEventPostModal(true)
  }, [])


  const handleModalClose = useCallback(() => {
    setOpenEventPostModal(false)
    if (onEventPostModalClose !== undefined) {
      onEventPostModalClose()
    }
  }, [onEventPostModalClose])


  const generateColumns = useCallback(
    () => {
      const columns = [
        {
          Header: t('Start time'),
          accessor: 'startTime',
          Cell: TimeField,
        },
        {
          Header: t('End time'),
          accessor: 'endTime',
          Cell: TimeField,
        },
        { Header: t('Value '), accessor: 'value' },
        { Header: t('Unit '), accessor: 'unit' },
        { Header: t('Cause '), accessor: 'comment', Cell: CommentField },
        {
          Header: t('Last updated'),
          accessor: 'updatedAt',
          Cell: TimeField,
          sortType: (rowA: { original: { updatedAt: string | number | Date } }, rowB: { original: { updatedAt: string | number | Date } }) => {
            const dateA = moment(rowA.original.updatedAt)
            const dateB = moment(rowB.original.updatedAt)
            return dateA.isAfter(dateB) ? 1 : -1
          },
        },
        { Header: t('Manage'), accessor: 'manage', disableSortBy: true },
      ]

      if (allSettingsForChosenProperty.some(setting => setting.creator_name)) {
        columns.splice(5, 0, { Header: t('Last modified by '), accessor: 'creatorName' })
      }

      if (allSettingsForChosenProperty?.some(setting => setting.operational_event && !eventPost.isOperationalEvent)) {
        columns.splice(6, 0, { Header: t('Event'), accessor: 'event' })
      }

      return columns
    },
    [allSettingsForChosenProperty, eventPost.isOperationalEvent, t]
  )

  const activeSettingsColumns = useMemo(() => generateColumns(), [generateColumns])
  const historySettingsColumns = useMemo(() => generateColumns(), [generateColumns])
  const operationalEventSettingsColumns = useMemo(() => generateColumns(), [generateColumns])

  const generateRows = useCallback(
    (settings: DigitalTwinSetting[], canEdit?: boolean): Record<string, string | number | ReactElement | null>[] => {
      return settings.map((setting) => {
        return {
          id: setting.id,
          startTime: setting.start_time,
          endTime: setting.end_time === null ? null : Datetime.ISODatetimeTo59Minutes(setting.end_time),
          value: getLabelForAttributeValue(setting.attribute, setting.value) ?? '',
          unit: setting.unit ?? '-',
          comment: setting.comment,
          updatedAt: setting.updated_at,
          creatorName: setting.creator_name ?? '',
          event: setting.operational_event ?
            <Icon
              icon="fal fa-calendar-week"
              large
              tooltip={{title: t('View event') + ': ' + (operatinalEvents?.get(setting.operational_event)?.name ?? '-'), arrow: true, placement: 'right'}}
              onClick={() => handleEventClick(setting.operational_event ?? null)}
            />
            : '-',
          manage: (
            <Button
              key={setting.id}
              link
              onClick={() => onEditSetting(setting)}
              disabled={!canEdit}
              disabledTooltip={t('Unfortunately, you do not have permission to change settings on this user/facility')}
              icon={'fal fa-edit'}
            >
              {''}
            </Button>
          ),
        }
      })
    },
    [handleEventClick, onEditSetting, operatinalEvents, t]
  )

  const activeSettingsData = useMemo(() => {
    const activeSettings = allSettingsForChosenProperty?.filter((setting) => isActiveSetting(setting)) ?? []
    return generateRows(activeSettings, permissions.canEdit)
  }, [allSettingsForChosenProperty, generateRows, permissions.canEdit])

  const historySettingsData = useMemo(() => {
    const historySettings = allSettingsForChosenProperty?.filter((setting) => isHistorySetting(setting)) ?? []
    return generateRows(historySettings, permissions.canEditHistoric)
  }, [allSettingsForChosenProperty, generateRows, permissions.canEditHistoric])

  const operationalEventSettingsData = useMemo(() => {
    const eventSettings = allSettingsForChosenProperty.filter((setting) =>
      setting.operational_event === eventPost.eventId &&
      eventPost.linked_objects &&
      eventPost.index !== null &&
      eventPost.index !== undefined &&
      eventPost.linked_objects[eventPost.index]?.attribute === setting.attribute &&
      setting.operational_event !== null
    ) ?? []
    return generateRows(eventSettings, permissions.canEditHistoric)
  }, [allSettingsForChosenProperty, generateRows, permissions.canEditHistoric, eventPost])

  const sortByUpdatedAt = useMemo(() => [{ id: 'updatedAt', desc: true }], [])
  const sortByUpcomingSettings = useMemo(() => [{ id: 'startTime', desc: true }], [])

  if (eventPost.isOperationalEvent) {
    return (
      <>
        <div className={styles.DigitalTwinPreviousSettingsTable_Container}>
          <CustomTable columns={operationalEventSettingsColumns} data={operationalEventSettingsData} initialSortBy={sortByUpcomingSettings}/>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.DigitalTwinPreviousSettingsTable_Container}>
          <div className={styles.DigitalTwinPreviousSettingsTable_TableTitle}>{t('Active settings')}</div>
          <CustomTable columns={activeSettingsColumns} data={activeSettingsData} initialSortBy={sortByUpdatedAt}/>

          <div className={styles.DigitalTwinPreviousSettingsTable_TableTitle}>{t('Previous settings')}</div>
          <CustomTable columns={historySettingsColumns} data={historySettingsData} initialSortBy={sortByUpdatedAt} />
        </div>
        {openEventPostModal && (
          <EventPostViewModal
            closeModal={handleModalClose}
            selectedEventPostId={operatinalEvents && selectedEventId !== null ? selectedEventId : undefined}
          />
        )}
      </>
    )
  }
}