import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { TextField, MenuItem, Icon } from '@mui/material'
import classNames from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './PlannedUnplannedSelect.module.less'

type PlannedUnplannedSelectProps = {
  defaultValue?: `planned` | `unplanned`
  disabled: boolean
}

function PlannedUnplannedSelect({ defaultValue = `unplanned`, disabled }: PlannedUnplannedSelectProps): ReactElement {
  const { t } = useTranslation()
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name="classification"
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          disabled={disabled}
          id="classification"
          helperText={t(`* This field is required`)}
          InputLabelProps={{ style: { pointerEvents: `auto` } }}
          label={
            <>
              {t(`Planned/Unplanned`)}
              <Tooltip
                title={t(
                  `Planned hours refers to distruptions announced to supplier at least 14 days before they take place. Unplanned hours referes to distruptions abbounced to supplier closer than 14 days before they take place.`
                )}
                placement="top"
                arrow
              >
                <Icon
                  fontSize="inherit"
                  className={classNames(`fas fa-info-circle`, styles.PlannedUnplannedSelect_InfoIcon)}
                />
              </Tooltip>
            </>
          }
          variant="outlined"
          margin="normal"
          select
          fullWidth
          required
          {...field}
        >
          <MenuItem key={1} value="planned">
            {t(`Planned`)}
          </MenuItem>
          <MenuItem key={2} value="unplanned">
            {t(`Unplanned`)}
          </MenuItem>
        </TextField>
      )}
    />
  )
}

export default PlannedUnplannedSelect
