import React, { ReactElement, PropsWithChildren } from 'react'

import { useCardWidget } from 'ui/components/CardWidget/CardWidget'

import { FormControl, InputLabel, Select, FormControlProps } from '@mui/material'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

type ReactHookFormSelectProps = FormControlProps & {
  name: string
  label: string
  defaultValue?: string | number
  rules?: RegisterOptions
}

export default function ReactHookFormSelect({
  name,
  label,
  defaultValue,
  children,
  rules,
  ...props
}: PropsWithChildren<ReactHookFormSelectProps>): ReactElement {
  const labelId = `${name}-label`
  const { control } = useFormContext()
  const { node } = useCardWidget()
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select {...field} labelId={labelId} label={label} MenuProps={{ container: node?.current }}>
            {children}
          </Select>
        )}
      />
    </FormControl>
  )
}
