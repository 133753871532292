import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { DigitalTwin, DigitalTwinLayout } from 'api/digitalTwin/digitalTwin.api'

import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  MiniMap,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
} from 'reactflow'

import { clone } from 'helpers/global.helper/global.helper'

import CustomNode from './CustomNode'
import { getLayoutKey, getNodesAndEdgesFromCurrentDigitalTwin } from './DigitalTwinFlow.helper'
import styles from './DigitalTwinFlow.module.less'

import 'reactflow/dist/style.css'
const nodeTypes = {
  custom: CustomNode,
}

type DigitalTwinFlowProps = {
  currentDigitalTwin?: DigitalTwin
}

export default function DigitalTwinFlow({ currentDigitalTwin }: DigitalTwinFlowProps): ReactElement {
  const { nodeArray, edgeArray } = getNodesAndEdgesFromCurrentDigitalTwin(clone(currentDigitalTwin))
  const [nodes, setNodes, onNodesChange] = useNodesState(nodeArray)
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgeArray)
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null)

  const onNodeDragStop = useCallback(() => {
    if (rfInstance && currentDigitalTwin) {
      const flow = rfInstance.toObject()

      const layout: DigitalTwinLayout = {}
      if (currentDigitalTwin?.layout) {
        for (const existingLayoutKey in currentDigitalTwin.layout) {
          layout[existingLayoutKey] = currentDigitalTwin?.layout[existingLayoutKey]
        }
      }

      flow.nodes.forEach((node) => {
        layout[getLayoutKey(node.data.type, node.data.name)] = { x: node.position.x, y: node.position.y }
      })
      currentDigitalTwin.layout = layout
    }
  }, [rfInstance, currentDigitalTwin])

  useEffect(() => {
    const { nodeArray, edgeArray } = getNodesAndEdgesFromCurrentDigitalTwin(currentDigitalTwin)
    setNodes(nodeArray)
    setEdges(edgeArray)
  }, [currentDigitalTwin, setNodes, setEdges])

  const onConnect = useCallback((params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)), [setEdges])

  const onInit = (reactFlowInstance: ReactFlowInstance): void => {
    setRfInstance(reactFlowInstance)
  }

  return (
    <div>
      <div className={styles.FlowView}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={onInit}
          fitView
          attributionPosition="top-center"
          minZoom={0.5}
          maxZoom={2}
          nodeTypes={nodeTypes}
          onNodeDragStop={onNodeDragStop}
        >
          <MiniMap zoomable pannable />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
    </div>
  )
}
