import React, { ReactElement } from 'react'

import { ShiftTeam } from 'api/shiftTeams/shiftTeams.api'
import { WorkShift, ShiftSchedule } from 'api/workShifts/workShifts.api'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { TableRow, TableCell, Grid } from '@mui/material'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './ShiftScheduleContent.module.less'

type ShiftScheduleContentProps = {
  shiftSchedule: ShiftSchedule[]
  workShifts: WorkShift[]
  shiftTeams: ShiftTeam[]
}

export default function ShiftScheduleContent({
  shiftSchedule,
  workShifts,
  shiftTeams,
}: ShiftScheduleContentProps): ReactElement {
  const orderedWorkShifts = [...workShifts].sort((a, b) => a.schedule_order - b.schedule_order)

  const twoTeamsPerShift = (shiftScheduleItem: ShiftSchedule, workShift: WorkShift): ReactElement => {
    let tooltipName = ``
    const colors: string[] = []

    for (let index = 0; index < shiftScheduleItem[workShift.name].length; index++) {
      const teamSequence = shiftScheduleItem[workShift.name][index]
      const shiftTeamItem = shiftTeams.find((item) => item.name === teamSequence)
      const color: string = shiftTeamItem?.color ?? `#fff`
      colors.push(color)

      if (index !== shiftScheduleItem[workShift.name].length && index !== 0) {
        tooltipName = shiftTeamItem ? tooltipName + `, ` + shiftTeamItem.display_name : tooltipName
      } else {
        tooltipName = shiftTeamItem ? tooltipName + ` ` + shiftTeamItem.display_name : tooltipName
      }
    }

    return (
      <Tooltip
        arrow
        key={shiftScheduleItem.time + workShift.start_time}
        style={{ whiteSpace: `pre-line` }}
        title={tooltipName + `: ` + workShift.start_time.substring(0, 5) + ` - ` + workShift.end_time.substring(0, 5)}
      >
        <Grid
          className={styles.ShiftScheduleContent_WorkShift}
          style={{ background: `linear-gradient(to right bottom, ${colors[0]} 50%, ${colors[1]} 50%)` }}
          key={shiftScheduleItem.time + workShift.name}
          item
          xs={12}
        >
          {getDisplayName(workShift)}
        </Grid>
      </Tooltip>
    )
  }

  const oneTeamPerShift = (shiftScheduleItem: ShiftSchedule, workShift: WorkShift): ReactElement => {
    const shiftTeamName = shiftScheduleItem[workShift.name][0]
    const shiftTeam = shiftTeams.find((item) => item.name === shiftTeamName)
    return (
      <Tooltip
        arrow
        key={shiftScheduleItem.time + workShift.start_time}
        style={{ whiteSpace: `pre-line` }}
        title={
          shiftTeam
            ? shiftTeam.display_name +
              `: ` +
              workShift.start_time.substring(0, 5) +
              ` - ` +
              workShift.end_time.substring(0, 5)
            : ``
        }
      >
        <Grid
          className={styles.ShiftScheduleContent_WorkShift}
          style={{
            backgroundColor: shiftTeam ? shiftTeam.color : undefined,
          }}
          key={shiftScheduleItem.time + workShift.name}
          item
          xs={12}
        >
          {getDisplayName(workShift)}
        </Grid>
      </Tooltip>
    )
  }
  return (
    <>
      <TableRow>
        {shiftSchedule.map((item) => {
          const timeSlotFilled: number[] = []
          const activeShiftNums: number[] = workShifts
            .filter((shift) => item[shift.name] !== undefined)
            .map((shift) => {
              return shift.schedule_order
            })

          return (
            <TableCell className={styles.ShiftScheduleContent_Cell} key={item.time} valign="top">
              <Grid container className={styles.ShiftScheduleContent_DayColumn} direction="column" alignItems="center">
                {orderedWorkShifts.map((workShift) => {
                  if (item[workShift.name]) {
                    timeSlotFilled.push(workShift.schedule_order)

                    if (item[workShift.name].length > 1) {
                      return twoTeamsPerShift(item, workShift)
                    } else {
                      return oneTeamPerShift(item, workShift)
                    }
                  } else if (
                    !timeSlotFilled.includes(workShift.schedule_order) &&
                    !activeShiftNums.includes(workShift.schedule_order)
                  ) {
                    timeSlotFilled.push(workShift.schedule_order)
                    return (
                      <Grid
                        className={styles.ShiftScheduleContent_EmptyCell}
                        item
                        xs={12}
                        key={item.time + workShift.name}
                      />
                    )
                  } else return null
                })}
              </Grid>
            </TableCell>
          )
        })}
      </TableRow>
    </>
  )
}
