import { useEffect, ReactElement, useState } from 'react'

import { ELPLAN_REFRESH_EVENT_QUERY_KEY } from 'api/elplan/elplan.api'
import elplanStore, { setCurrentDate } from 'store/elplan/elplan'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import { DatePickerInForm } from 'ui/molecules/pickers/DatePicker/DatePicker'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { queryClient } from 'helpers/queryClient'

import styles from './IntradayOverviewView.module.less'



export default function IntradayOverviewView(): ReactElement {
  const { t } = useTranslation()
  const elplanSnap = useSnapshot(elplanStore)

  const [chosenDate, setChosenDate] = useState<{ startTime: ISODateTime, endTime: ISODateTime }>({
    startTime: Datetime.getTodayDate().startTime, endTime: Datetime.getTodayDate().endTime,
  })
  function onDateTimePickerChange(value: ISODateTime): void{
    setChosenDate({ startTime: Datetime.getStartOfDay(value), endTime: Datetime.getEndOfDay(value) })
    setCurrentDate(Datetime.getStartOfDay(value), Datetime.getEndOfDay(value))
  }

  useEffect(() => {
    queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
  }, [elplanSnap.date.start_time, elplanSnap.date.end_time])

  return (
    <div > <CardWidget
      title={t(`ID - Overview`)} id={''}        >

      <DatePickerInForm
        value={chosenDate.startTime}
        onSubmit={(date) => {
          onDateTimePickerChange(date)
        }}
        label={t('Chosen date')}
        showWeekNumbers
        granularity={'day'}
        isRequired
      />
      <div className={styles.IntradayOverviewView_Tables}>
        <UiConfig type="intraday_overview"
          datasetStartTime={chosenDate.startTime ?? Datetime.getTodayDate().startTime}
          datasetEndTime={chosenDate.endTime ?? Datetime.getTodayDate().endTime}
          datasetRefreshToken={ELPLAN_REFRESH_EVENT_QUERY_KEY}/>
      </div>

    </CardWidget> </div>
  )
}