type GetLoggerOptions = {
  isVerboseDebug?: boolean
}

type Logger = {
  debugging: typeof console.log
  info: typeof console.log
  warn: typeof console.warn
  error: typeof console.error
}

type LogHistory = {
  scopeName: string
  level: 'debug' | 'info' | 'warn' | 'error'
  timestamp: string
  message: string
  args: unknown[]
}

const logHistory: LogHistory[] = []
const LOG_HISTORY_MAX_SIZE = 1000
function logToHistory(
  args: Omit<LogHistory, 'timestamp'>): void {
  logHistory.push({
    ...args,
    timestamp: getTimestamp(),
  })

  // Remove oldest log if history is too long
  if (logHistory.length > LOG_HISTORY_MAX_SIZE) {
    logHistory.shift()
  }
}

export function getLogHistory(): LogHistory[] {
  return logHistory
}

export function printLogHistory(): void {
  // eslint-disable-next-line no-console
  console.table(logHistory)
}

function getTimestamp(): string {
  return new Date().toISOString()
}

let isVerboseDebug = true
export function setVerboseDebug(enabled: boolean): void {
  isVerboseDebug = enabled
}

export default function getLogger(
  scopeName: string,
  options: GetLoggerOptions = {}
): Logger {
  return {
    debugging: (message: string, ...args: unknown[]) => {
      logToHistory({
        scopeName,
        level: 'debug',
        message,
        args,
      })

      if (!isVerboseDebug) {
        return
      }

      if (options.isVerboseDebug === false) {
        return
      }

      // eslint-disable-next-line no-console
      console.log(`[${scopeName}] ${getTimestamp()} DEBUG: ${message}`, ...args)
    },
    info: (message: string, ...args: unknown[]) => {
      logToHistory({
        scopeName,
        level: 'info',
        message,
        args,
      })

      // eslint-disable-next-line no-console
      console.log(`[${scopeName}] ${getTimestamp()} ${message}`, ...args)
    },
    warn: (message: string, ...args: unknown[]) => {
      logToHistory({
        scopeName,
        level: 'warn',
        message,
        args,
      })

      console.warn(`[${scopeName}] ${getTimestamp()} ${message}`, ...args)
    },
    error: (message: string, ...args: unknown[]) => {
      logToHistory({
        scopeName,
        level: 'error',
        message,
        args,
      })

      console.error(`[${scopeName}] ${getTimestamp()} ${message}`, ...args)
    },
  }
}
