import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import { useQuery, useQueryClient } from 'react-query'


export const SYSTEM_MESSAGES_QUERY_KEY = `system-messages`

export type SystemMessage = {
  id: number
  title: string
  text: string
  severity: `info` | `warning` | `meetup`
  start_time: ISODateTime
  end_time: ISODateTime
  }



export function useSystemMessages() {
  const { systemId } = useAuth()
  const queryClient = useQueryClient()

  const channel = useChannel(`system-messages`)
  useEvent(channel, `new-system-message`, () => {
    queryClient.invalidateQueries(SYSTEM_MESSAGES_QUERY_KEY)
  })


  return useQuery(SYSTEM_MESSAGES_QUERY_KEY, () =>
    apiClient<SystemMessage[]>(`system_messages`, { params: { system_id: systemId } }),
  {
    enabled: !!systemId,
  }
  )
}

