import React, { ReactElement, useMemo } from 'react'

import { DigitalTwinSettingTarget } from 'api/digitalTwin/digitalTwin.api'
import { useUser } from 'api/users/users.api'
import theme from 'styles/theme/theme'
import Button from 'ui/atoms/Button/Button'
import Icon from 'ui/atoms/Icon/Icon'
import { EventId } from 'ui/components/BarCalendar/BarCalendar.types'
import Datetime from 'utils/datetime/datetime'

import { Box, ThemeProvider, StyledEngineProvider, createTheme, useTheme, CircularProgress } from '@mui/material'
import MaterialReactTable, { MRT_ColumnDef, MRT_Localization } from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useAuth } from '../AuthContext/AuthContext'
import { SettingsPermissions } from '../SettingsTable/SettingsTable'

import styles from './PropertiesTable.module.less'
import { PropertiesTableItemStatus, PropertiesTableItemWithTooltip } from './PropertiesTable.types'

function useUserLocalization(): MRT_Localization {
  const { data: user } = useUser()
  const localization = user?.settings.language
  switch (localization) {
    case 'sv':
      return MRT_Localization_SV
    default:
      return MRT_Localization_EN
  }
}

export type PropertiesTableProps = {
  onEditDeviationSetting?: (event: EventId) => void
  dataIn: PropertiesTableItemWithTooltip[]
  translations?: { [key: string]: string }
  priorities?: SystemSettingPriorityLevel[]
  permissions?: SettingsPermissions
  editGrouping?: (meta: DigitalTwinSettingTarget) => void
  isBaseSettings?: boolean
  expanded?: true | Record<string, boolean>
}

export default function PropertiesTable({
  dataIn,
  priorities,
  permissions,
  editGrouping,
  expanded,
  isBaseSettings = false,
}: PropertiesTableProps): ReactElement {
  // NOTE: Might be nicer to pass these from the parent component instead or some middle abstraction layer component
  const { t } = useTranslation()
  const { hasAccess } = useAuth()
  const hasAccessToPropertyGroupFeature = useMemo(() => {
    return hasAccess({ module: 'optimizesettings', submodule: 'optimizesettings_group_property'})
  }, [hasAccess])

  const columns = useMemo(() => {
    let cols: MRT_ColumnDef<Record<string, unknown>>[]  = [
      {
        accessorKey: 'group',
        header: t('Group'),
        enableColumnActions: true,
        Header: () => <Box>{t('Grouping')}</Box>,
        GroupedCell: ({ cell }) => <Box sx={{ display: 'inline', fontWeight: 'bold' }}>{cell.getValue<string>()}</Box>,
        PlaceholderCell: ({ cell }) => <Box sx={{ display: 'inline' }}>{cell.getValue<string>()}</Box>,
        size: 15,
      },
      {
        accessorKey: 'status',
        header: t(`Status`),
        aggregationFn: 'max',
        size: 5,

        AggregatedCell: ({ cell }) => {
          // sortOrder === 0 means no active setting. If all subRows have sortOrder === 0, the group has no active setting.
          const groupHasActiveSetting = !cell.row.subRows?.every(
            (subRow) => (subRow.original.status as PropertiesTableItemStatus).sortOrder === 0
          )

          const groupIsLoading = cell.row.subRows?.some(
            (subRow) => (subRow.original.status as PropertiesTableItemStatus).loading
          )

          if (groupIsLoading) {
            return <CircularProgress size={24} />
          }

          if (groupHasActiveSetting) {
            return <Icon icon="fas fa-exclamation-circle" size="large" color="yellow" />
          }

          return <Icon icon="fas fa-check-circle" size="large" color="dark_green" />
        },
        Cell: ({ cell }) => {
          return (cell.getValue() as PropertiesTableItemStatus).icon
        },

        // Custom sort function necessary since 'status' contains both a sortOrder and an icon
        sortingFn: (rowA, rowB, columnId) => {
          const statusA = rowA.original[columnId] as PropertiesTableItemStatus
          const statusB = rowB.original[columnId] as PropertiesTableItemStatus

          if (statusA.sortOrder < statusB.sortOrder) {
            return -1
          }
          if (statusA.sortOrder > statusB.sortOrder) {
            return 1
          }
          return 0
        },
      },
      {
        accessorKey: 'property',
        header: t('Property'),
        size: 170,
        AggregatedCell: ({ row }) => (
          <Box sx={{ display: 'inline', fontWeight: 'bold' }}>
            {(row.original?.propertyGroupName ? row.original.propertyGroupName : t('Grouping')) as string}
          </Box>
        ),
        Cell: ({ cell }) => <Box>{cell.getValue<string>() !== '-' ? cell.getValue<string>() : '-'}</Box>,
      },
      {
        accessorKey: 'currentValue',
        header: t('Current value'),
        size: 15,
        Cell: ({ cell }) => <Box>{cell.getValue<string>() !== '-' ? cell.getValue<string>() : '-'}</Box>,
      },
      {
        accessorKey: 'unit',
        header: t('Unit'),
        size: 5,
        Cell: ({ cell }) => <Box>{cell.getValue<string>() !== '-' ? cell.getValue<string>() : '-'}</Box>,
      },
      {
        accessorKey: 'changedAt',
        header: t('Changed at'),
        aggregationFn: 'max',
        size: 175,
        Cell: ({ cell }) => {
          if (cell.getValue() === '-') {
            return <Box>-</Box>
          }
          const changedAt = Datetime.toLocalTime(
            cell.getValue<`${number}-${number}-${number}T${number}:${number}:${number}+00:00`>(),
            'longDayText'
          )
          return <Box>{changedAt}</Box>
        },
        AggregatedCell: ({ cell }) => {
          if (cell.getValue() === '-') {
            return <Box sx={{ display: 'inline' }}>-</Box>
          }
          const changedAt = Datetime.toLocalTime(
            cell.getValue<`${number}-${number}-${number}T${number}:${number}:${number}+00:00`>(),
            'longDayText'
          )
          return <Box sx={{ display: 'inline' }}>{changedAt}</Box>
        },
      },
      {
        accessorKey: 'edit',
        header: t('Edit'),
        enableSorting: false,
        size: 160,
        AggregatedCell: ({ row }) => {
          if (editGrouping && row.original && row.original.meta) {
            return (
              <Box>
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    editGrouping(row.original.meta as DigitalTwinSettingTarget)
                  }}
                  primary
                  icon={permissions?.canEdit ? 'fas fa-edit' : undefined}
                >
                  {permissions?.canEdit ? t('Show/edit') : t('Show')}
                </Button>
              </Box>
            )
          }
        },
      },
    ]

    if (hasAccessToPropertyGroupFeature) {
      cols.splice(1, 0, {
        accessorKey: 'expandButtons',
        header: t('Expand'),
        size: 5,
        enableSorting: false,
        enableHiding: true,
        Header: ({ table }) => (
          <Box>
            {table.getIsAllRowsExpanded() ? (
              <Button onClick={() => table.toggleAllRowsExpanded()} style={{ backgroundColor: 'transparent' }}>
                <Icon icon="fa fa-grip-lines-vertical" size="medium" />
              </Button>
            ) : (
              <Button onClick={() => table.toggleAllRowsExpanded()} style={{ backgroundColor: 'transparent' }}>
                <Icon icon="fa fa-grip-lines" size="medium" />
              </Button>
            )}
          </Box>
        ),
        AggregatedCell: ({ row }) => (
          <Box sx={{ display: 'inline', fontWeight: 'bold' }}>
            {row.getIsGrouped() ? (
              row.getIsExpanded() ? (
                <div className={styles.iconPosition}>
                  <Icon icon="fa fa-chevron-up" size="medium" />
                </div>
              ) : (
                <div className={styles.iconPosition}>
                  <Icon icon="fa fa-chevron-down" size="medium" />
                </div>
              )
            ) : null}
          </Box>
        ),
      })
    }

    if (isBaseSettings) {
      cols = cols.filter((column) => column.accessorKey !== 'status')
    }
    return cols
  }, [editGrouping, hasAccessToPropertyGroupFeature, isBaseSettings, permissions?.canEdit, t])

  const globalTheme = useTheme()
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: theme.palette.primary,
          secondary: theme.palette.secondary,
          info: theme.palette.info,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalTheme]
  )
  const userLocalization = useUserLocalization()

  let visibleColumns = columns
  if (priorities?.some((priority) => priority.priority_level === 10)) {
    visibleColumns = visibleColumns.filter((column) => column.accessorKey !== 'status')
  }

  const baseSorting = [
    { id: 'changedAt', desc: true },
    { id: 'group', desc: false },
    { id: 'property', desc: true },
  ]
  const sorting = !isBaseSettings ? [{ id: 'status', desc: true }, ...baseSorting] : baseSorting

  // This weird construct is a workaround of the fact that the expanded prop in MaterialReactTable is not typed correctly
  // A state object with an 'expanded' key throws an error even if the value is 'undefined'
  const state: {expanded?: true | Record<string, boolean> } = {}
  if (expanded) {
    state['expanded'] = expanded
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <div style={{ width, overflowX: `auto` }}>
          <div className={styles.TimeSeriesDataTable}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  state={state}
                  autoResetExpanded={true}
                  columns={visibleColumns}
                  data={dataIn}
                  enableGlobalFilterModes
                  enableColumnActions={false}
                  localization={userLocalization}
                  enableStickyHeader
                  enableGrouping={hasAccessToPropertyGroupFeature}
                  enableColumnDragging={false}
                  enablePinning
                  paginateExpandedRows={false}
                  positionToolbarAlertBanner="none"
                  initialState={{
                    showGlobalFilter: true,
                    grouping: hasAccessToPropertyGroupFeature ? ['group'] : undefined,
                    columnVisibility: {
                      'mrt-row-expand': false,
                    },
                    sorting,
                    columnPinning: {
                      left: ['expandButtons'],
                    },
                    columnOrder: ['expandButtons', 'group'],
                  }}
                  positionGlobalFilter="left"
                  muiSearchTextFieldProps={{
                    placeholder: t(`Search`),
                    sx: { minWidth: '300px' },
                    variant: 'outlined',
                  }}
                  muiTablePaperProps={{
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
                      wordWrap: 'break-word',
                    },
                  }}
                  muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
                      backgroundColor:
                        cell.getIsAggregated() || cell.getIsGrouped() ? 'rgb(242, 242, 242);' : 'inherit',
                    },
                  })}
                  muiTableFooterCellProps={{
                    sx: {
                      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      {
                        row.toggleExpanded()
                      }
                    },
                    sx: {
                      cursor: row.getIsGrouped() ? 'pointer' : 'default',
                    },
                  })}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
        </div>
      )}
    </AutoSizer>
  )
}
