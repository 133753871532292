import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { BinarySettingAlternative } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/BinarySettingSelect/BinarySettingSelect'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'
import { Moment } from 'moment'

import { getTextFromValue } from 'helpers/global.helper/global.helper'

export type ConfirmTextObject = {
  title: string
  text: string
  confirm?: string
}

export function formatClassificationString(classification: string): string | undefined {
  switch (classification) {
    case `planned`:
      return i18n.t(`Planned`)
    case `unplanned`:
      return i18n.t(`Unplanned`)
    default:
      return undefined
  }
}

export function getAlternativeValue(property: ObjectProperty): {
  value: string
  alternatives: BinarySettingAlternative[]
} {
  let alternatives: BinarySettingAlternative[] = []
  let value = ``
  if (property.name.includes(`availability`)) {
    alternatives = [
      {
        name: getTextFromValue(0, property.value_mappings) ?? i18n.t(`Unavailable`),
        value: 0,
      },
      {
        name: getTextFromValue(1, property.value_mappings) ?? i18n.t(`Available`), //TODO: Is this value even needed anywhere? If so, we could remove it and remove the ugly check solution of index 0 in SettingValueInput.tsx at line 46
        value: 1,
      },
    ]
    value = `0`
  }
  if (property.name.includes(`forced`)) {
    alternatives = [
      {
        name: i18n.t(`Forced active`),
        value: 1,
      },
    ]
    value = `1`
  }
  return { value, alternatives }
}

export type DateTimePickerRange = {
  startTime : {
    min?: Moment | ISODateTime
    max?: Moment | ISODateTime
  },
  endTime: {
    min?: Moment | ISODateTime
    max?: Moment | ISODateTime
  }
}

export function getDateTimePickerRangesFromPermissions(hasHistoricPerm: boolean): DateTimePickerRange{

  if(hasHistoricPerm){
    return {
      startTime: {
        min: undefined,
        max: undefined,
      },
      endTime: {
        min: undefined,
        max: undefined,
      },
    }
  }else{
    return {
      startTime: {
        min: undefined,
        max: undefined,
      },
      endTime: {
        min: new Datetime(Datetime.getISONow(0)).ISOString,
        max: undefined,
      },
    }
  }

}
