import React, { ReactElement } from 'react'

import { default as AbsButton , ButtonProps } from 'ui/atoms/Button/Button'

import { MenuItem, Popper, Grow, ClickAwayListener, MenuList, Paper } from '@mui/material'

type SelectButtonProps = ButtonProps & {
  loading?: boolean
  menuItems?: {label: string, onClick: () => void}[]
  onButtonClick?: () => void
  noRadiusRight?: boolean
  children?: ReactElement | string
  icon?: string
  disabledTooltip?: string
}

export default function SelectButton({
  menuItems,
  onButtonClick,
  disabled,
  noRadiusRight = false,
  children,
  icon,
  disabledTooltip,
  ...props
}: SelectButtonProps): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (onButtonClick) {
      onButtonClick()
    }
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <AbsButton
        onClick={handleButtonClick}
        primary
        noRadiusRight={noRadiusRight}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? `true` : undefined}
        icon={icon}
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        {...props}
      >
        {children}
      </AbsButton>
      <Popper open={open} anchorEl={anchorEl} transition style={{zIndex: '10000'}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems?.map((item, i) => (
                    <MenuItem
                      key={`${i}-${item.label}`}
                      onClick={() => {
                        item.onClick()
                        handleClose()
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
