import React, { ReactElement } from 'react'

import {
  ShiftTeam,
} from 'api/shiftTeams/shiftTeams.api'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Grid, Typography } from '@mui/material'


import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './ShiftInfoTeamList.module.less'

function TeamListWrapper({
  shiftTeams,
}: {
  shiftTeams: ShiftTeam[]
}): ReactElement {
  return (
    <>
      {shiftTeams.map((shiftTeam) => (
        <Grid
          item
          key={shiftTeam.name}
          className={styles.ShiftInfoTeamList_TeamIcon}
          style={{ background: shiftTeam.color }}
        >
          <Typography className={styles.ShiftInfoTeamList_TeamIconText} variant="body1" display="inline">
            {getDisplayName(shiftTeam)}
          </Typography>
        </Grid>
      ))}
    </>
  )
}

type ShiftInfoTeamListProps = {
  shiftTeams: ShiftTeam[]
}
export default function ShiftInfoTeamList({ shiftTeams }: ShiftInfoTeamListProps): ReactElement {

  let status: 'loading' | 'success' | 'error'

  if (shiftTeams.length > 0) {
    status = 'success'
  } else {
    status = 'error'
  }
  return (
    <Grid container direction="column" justifyContent="space-between">
      <StatusComponent
        data={{ shiftTeams }}
        status={status}
        Component={TeamListWrapper}
        ComponentProps={{ shiftTeams }}
      />
    </Grid>
  )
}

