import React, { ReactElement, useMemo, useRef, useState } from 'react'

import { PlanValues } from 'api/elplan/elplan.api'
import elplanStore from 'store/elplan/elplan'
import { Dialog } from 'ui/components/Dialog/Dialog'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import Datetime from 'utils/datetime/datetime'

import { DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './SpotBidModal.module.less'

type SpotBidModalProps = {
  onClose: () => void
  onSubmit: (planValues: PlanValues[]) => void
  elplanExistsWarningText?: string
  title?: string,
  elplanFromBids: PlanValues[],
}

export default function SpotBidModal({ onClose, onSubmit, title, elplanExistsWarningText, elplanFromBids }: SpotBidModalProps): ReactElement {
  const ref = useRef(null)
  const elplanSnap = useSnapshot(elplanStore)

  const [currentPlanValues, setCurrentPlanValues] = useState<PlanValues[]>(elplanFromBids as unknown as PlanValues[])

  const { t } = useTranslation()
  const timeAndValues = useMemo(() => {
    function onCellChange(e: any, index: number): void {
      const copy = currentPlanValues
      copy[index].volume = e.target?.innerText
      setCurrentPlanValues(copy)
    }

    setCurrentPlanValues(elplanFromBids)

    return currentPlanValues
      ?.sort((a, b) => a.start_time.localeCompare(b.start_time))
      .map((obj, index) => {
        return (
          <div key={obj.start_time}>
            <td
              className={styles.SpotBidModal_Table_Cell}
            >{`${Datetime.toLocalTime(obj.start_time, 'hour')}-${Datetime.toLocalTime(obj.end_time, 'hour')}`}</td>
            <td
              className={styles.SpotBidModal_EditableContent}
              contentEditable="true"
              suppressContentEditableWarning
              onBlur={(e) => onCellChange(e, index)}
            >
              {`${obj.volume}`}
            </td>
          </div>
        )
      })
  }, [currentPlanValues, elplanFromBids])

  const halfOfHourValues = Math.ceil(timeAndValues.length / 2)

  return (
    <div className={styles.SpotBidModal}>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={title ?? t('Edit electricity plan')} handleClose={() => onClose()} />
        <DialogContent >
          <table className={styles.SpotBidModal_Table}>
            <tbody className={styles.SpotBidModal_Body}>
              <tr className={styles.SpotBidModal_Table_Cell}>
                <th className={styles.SpotBidModal_Table_Cell__header_time}> <span className={`${'fal fa-clock'} ${styles.SpotBidModal_Table_Icon}`}/>{t('Time')}</th>
                <th className={styles.SpotBidModal_Table_Cell__header}> <span className={`${'fal fa-bolt'} ${styles.SpotBidModal_Table_Icon}`}/>{`${t('Volume')} (MW)`}</th>
              </tr>
              <div className={styles.SpotBidModal_Table_Cell}>
                {timeAndValues.slice(0, halfOfHourValues)}
              </div>
            </tbody>

            <tbody className={styles.SpotBidModal_Body}>
              <tr className={styles.SpotBidModal_Table_Cell}>
                <th className={styles.SpotBidModal_Table_Cell__header_time}> <span className={`${'fal fa-clock'} ${styles.SpotBidModal_Table_Icon}`}/>{t('Time')}</th>
                <th className={styles.SpotBidModal_Table_Cell__header}> <span className={`${'fal fa-bolt'} ${styles.SpotBidModal_Table_Icon}`}/>{`${t('Volume')} (MW)`}</th>
              </tr>
              <div className={styles.SpotBidModal_Table_Cell}>
                {timeAndValues.slice(halfOfHourValues)}
              </div>
            </tbody>
          </table>

          <ModalButtons onSubmit={() => {
            onSubmit(currentPlanValues)
            onClose()
          }}
          onCancel={() => onClose()}
          infoText={elplanSnap.elplan.has_plan ? elplanExistsWarningText : undefined}
          />

        </DialogContent>
      </Dialog>
    </div>
  )
}
