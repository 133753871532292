import { proxy } from 'valtio'

export type RemainingHours = {
  plannedRemaining: number,
  unplannedWinterRemaining: number,
  unplannedSummerRemaining: number,
}

export const samenergiStore = proxy<RemainingHours>
(
  {
    plannedRemaining: 0,
    unplannedSummerRemaining: 0,
    unplannedWinterRemaining: 0,
  }
)
