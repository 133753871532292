import authStore from 'store/auth/auth'
import { systemFeatureFlagActive } from 'utils/systemUtils'
import { DeepReadonly } from 'utils/types'

import { useSnapshot } from 'valtio'


const shouldEnableComputableTimeSeriesSettings = (system: DeepReadonly<System>): boolean => {
  return systemFeatureFlagActive(system, 'deviation_settings_computable_timeseriessettings')
}

export const useShouldEnableComputableTimeSeriesSettings = (): boolean => {
  const { activeSystem } = useSnapshot(authStore) as typeof authStore
  return activeSystem ? shouldEnableComputableTimeSeriesSettings(activeSystem) : false
}