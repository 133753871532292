import React, { ReactElement, useState } from 'react'

import { useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { Setting, useSettings } from 'api/settings/settings.api'
import {
  ShiftHandoverCommentGroup,
  useShiftHandoverCommentGroups,
} from 'api/shiftHandoverCommentGroups/shiftHandoverCommentGroups.api'
import { Comment, Shift, ShiftHandoverCreateInfo } from 'api/shiftHandovers/shiftHandovers.api'
import { useUser } from 'api/users/users.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { DialogTitle, Grid, Typography, Icon } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Form from '../Form/Form'
import { getDisplayUserName } from 'helpers/global.helper/global.helper'

type FormWrapperProps = {
  commentGroups: ShiftHandoverCommentGroup[]
  settings: Setting[]
  pinnedComments: Comment[]
  startingShift: Shift
  endingShift: Shift
  handleClose: () => void
}

function FormWrapper({
  commentGroups,
  settings,
  pinnedComments,
  startingShift,
  endingShift,
  handleClose,
}: FormWrapperProps): ReactElement {
  const commentGroupsWithComments = commentGroups.map((item) => ({
    ...item,
    comments: [
      ...pinnedComments.filter((comment) => item.id === comment.group),
      { group: item.id, content: ``, is_reported: false, is_pinned: false },
    ],
  }))
  return (
    <Form
      defaultValues={{
        starting_shift_team: Number(startingShift?.team),
        ending_shift_team: Number(endingShift?.team),
        start_time: moment(endingShift.start_time).format(),
        end_time: moment(startingShift.end_time).format(),
        handover_time: moment(endingShift.end_time).format(),
        comment_groups: commentGroupsWithComments ?? [],
        representatives_present: false,
      }}
      settings={settings}
      handleModalClose={handleClose}
    />
  )
}

type CreateModalProps = {
  open: boolean
  handleClose: () => void
  createInfo: Omit<ShiftHandoverCreateInfo, `handover_shifts`> & {
    handover_shifts: NonNullable<ShiftHandoverCreateInfo[`handover_shifts`]>
  }
}

function CreateModal({ open, handleClose, createInfo }: CreateModalProps): ReactElement {
  const { t } = useTranslation()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const { handover_shifts, pinned_comments } = createInfo
  const ending_shift = handover_shifts?.ending_shift
  const starting_shift = handover_shifts?.starting_shift
  const { data: commentGroups, status } = useShiftHandoverCommentGroups({
    enabled: open,
  })

  const { data: user } = useUser()
  const { data: settings, status: settingsStatus } = useSettings({
    params: {
      active_from: ending_shift.start_time,
      active_to: starting_shift.end_time,
    },
    queryConfig: { enabled: open },
  })

  const { data: objectProperties, status: objectPropertyStatus } = useObjectProperties({
    queryConfig: { enabled: open },
  })

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth scroll="paper">
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container alignItems="center" justifyContent="flex-start" spacing={1} xs={11}>
              <Grid item>
                <Typography variant="h1" display="inline">
                  {t(`Shift handover report`)}
                </Typography>
              </Grid>
              <Grid item>
                <Icon className="fal fa-pen" style={{ fontSize: 15, marginRight: 5 }} />
                <Typography variant="h2" display="inline" style={{ marginRight: 10 }}>
                  {t('Created by') + ': '}
                  {getDisplayUserName(user, true)}
                </Typography>
                <InfoBanner
                  text={t(
                    `A draft will be automatically submitted if not submitted before the end of the scheduled shift.`
                  )}
                />
              </Grid>
            </Grid>

            <PopoverCloseButton handleClose={() => setConfirmDialogOpen(true)} />
          </Grid>
        </DialogTitle>
        <StatusComponent
          status={[status, settingsStatus, objectPropertyStatus]}
          data={{ commentGroups, settings, objectProperties }}
          Component={FormWrapper}
          ComponentProps={{
            startingShift: starting_shift,
            endingShift: ending_shift,
            pinnedComments: pinned_comments,
            handleClose,
          }}
        />
      </Dialog>

      <ConfirmDialog
        open={confirmDialogOpen}
        textObject={{
          title: t(`Are you sure you want to cancel your shift handover?`),
          text: t(
            `All your progress will be lost and you have to redo the shift handover again if you close this window.`
          ),
        }}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          handleClose()
          setConfirmDialogOpen(false)
        }}
      />
    </>
  )
}

export default CreateModal
