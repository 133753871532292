import React, { ReactElement } from 'react'

import { DeclinedHours, HeatSupplier, useHeatSuppliers } from 'api/heatSuppliers/heatSuppliers.api'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'

import AvailableHoursForm, { AvailableHoursFormProps } from './components/AvailableHoursForm/AvailableHoursForm'
import ObjectPropertySettingForm, {
  ObjectPropertySettingFormProps,
} from './components/ObjectPropertySettingForm/ObjectPropertySettingForm'

type ObjectPropertyFormSelectorProps = {
  objectProperty?: ObjectProperty
  ObjectPropertySettingFormProps: Omit<ObjectPropertySettingFormProps, `objectProperty`>
  AvailableHoursFormProps?: Omit<AvailableHoursFormProps, `supplier` | `objectProperty` | `declinedHours`> & {
    supplier?: HeatSupplier
    declinedHours?: DeclinedHours
  }
}

export default function ObjectPropertyFormSelector({
  objectProperty,
  ObjectPropertySettingFormProps,
  AvailableHoursFormProps,
}: ObjectPropertyFormSelectorProps): ReactElement | null {
  const { data: suppliers } = useHeatSuppliers({ enabled: objectProperty?.name === `available_hours` })

  if (objectProperty) {
    let supplier = AvailableHoursFormProps?.supplier
    if (!supplier && suppliers) {
      supplier = suppliers.find((item) => item.props.map((item) => item.id).includes(objectProperty.id))
    }
    if (objectProperty?.name === `available_hours`) {
      return supplier ? (
        <AvailableHoursForm supplier={supplier} objectProperty={objectProperty} {...AvailableHoursFormProps} />
      ) : null
    }

    return (
      <ObjectPropertySettingForm
        objectProperty={objectProperty}
        showPlannedUnplanned={(objectProperty) =>
          supplier && objectProperty?.name.includes(`availability`) ? true : false
        }
        {...ObjectPropertySettingFormProps}
      />
    )
  }
  return null
}
