import React, { ReactElement } from 'react'

import classNames from 'classnames'

import styles from './HorizontalBar.module.less'

type HorizontalBarProps = {
  color: string
  value: number | string
  width: string
  height?: number
  transparent?: boolean
  paddingRight?: number
  danger?: boolean
}

export default function HorizontalBar({ color, value, width, height, transparent, paddingRight = 50, danger }: HorizontalBarProps): ReactElement {
  const style: Record<string, string> = {}

  if (height) {
    style.height = `${height}px`
  }
  if (paddingRight) {
    style.paddingRight = `${paddingRight}px`
  }

  return (
    <div className={classNames([styles.HorizontalBar])} style={style}>
      <div
        className={classNames([styles.HorizontalBar_Bar, 'animation'])}
        style={{ width, borderColor: color }}
      >
        <div
          className={classNames(styles.HorizontalBar_Bar_Background, {
            [styles.HorizontalBar_Bar_Background__transparent]: transparent,
          })}
          style={{ backgroundColor: color }}
        />

        <div className={classNames(styles.HorizontalBar_Bar_Label, {
          [styles.HorizontalBar_Bar_Label__danger]: !!danger,
        })}>
          {value}
        </div>
      </div>
    </div>
  )
}
