import React, { ReactElement, useState } from 'react'

import { Unit } from 'api/units/units.api'
import { useSettingsModalTitle } from 'hooks/utilsHooks/utilsHooks'
import { Switch } from 'ui/atoms'
import BarCalendar from 'ui/components/BarCalendar/BarCalendar'
import { Event } from 'ui/components/BarCalendar/BarCalendar.types'
import SettingsModal from 'ui/components/SettingsModal/SettingsModal'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import UnitSettingForm, { UnitSettingFormData } from 'ui/components/UnitSettingForm/UnitSettingForm'
import MonthPicker from 'ui/molecules/MonthPicker/MonthPicker'

import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { isNumber } from 'helpers/global.helper/global.helper'
import { DateTimePickerRange } from 'helpers/settingsModal.helper/settingsModal.helper'

import { SettingsCalendarItem, generateCategoriesAndEvents } from './SettingsCalendarHelper'
import styles from './SettingsCalendarView.module.less'

type SettingsCalendarViewProps = {
  items: SettingsCalendarItem[],
  currentCalendarView?: `productionPlan` | `month` | `week`,
  currentPeriod: { startTime: ISODateTime; endTime: ISODateTime; },
  setCurrentCalendarView: React.Dispatch<React.SetStateAction<`productionPlan` | `month` | `week`>>
  permissions: SettingsPermissions
  startEndTimeRange: DateTimePickerRange
  updateCurrentPeriod: (date: Moment) => void
}

export default function SettingsCalendarView({
  items,
  currentCalendarView = `productionPlan`,
  currentPeriod,
  setCurrentCalendarView,
  permissions,
  startEndTimeRange,
  updateCurrentPeriod,

}: SettingsCalendarViewProps): ReactElement {
  const { t } = useTranslation()
  const { categories, events } = generateCategoriesAndEvents(items)

  const [openCreateNewSettingModal, setOpenCreateNewSettingModal] = useState(false)
  const [newSettingItem, setNewSettingItem] = useState<SettingsCalendarItem | undefined>()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<({ isEditable: boolean } & Partial<UnitSettingFormData>) | undefined>()

  const closeModal = (): void => { 
    setOpenCreateNewSettingModal(false),
    setIsEditing(false), 
    setNewSettingItem(undefined) 
  }

  const editSetting = (setting: Event): void => {
    const unit = items.find((unit) => unit.name === setting.categoryId)
    if (unit) {
      setNewSettingItem(unit)
      setIsEditing(true)
      setDefaultValues({
        start_time: moment(setting.startTime),
        end_time: setting.endTime ? moment(setting.endTime) : null,
        id: isNumber(setting.id) ? setting.id : undefined,
        objectProperty: setting.meta?.object_property,
        comment: setting.meta?.comment,
        value: setting.meta?.value.toString(),
        isEditable: setting.meta?.clickable,
      })
      setOpenCreateNewSettingModal(true)
    }
  }

  const modalTitle = useSettingsModalTitle({
    unit: newSettingItem,
    editing: defaultValues ? defaultValues.isEditable : false,
  })

  return (
    <div className={styles.SettingsCalendarView}>
      <SettingsModal
        closeModal={closeModal}
        open={openCreateNewSettingModal}
        title={modalTitle}
        form={
          openCreateNewSettingModal ? (
            <UnitSettingForm
              permissions={permissions}
              startEndTimeRange={startEndTimeRange}
              isEditing={isEditing}
              period={{
                startTime: currentPeriod.startTime,
                endTime: currentPeriod.endTime,
              }}
              unit={newSettingItem as Unit}
              onFormFinished={closeModal}
              defaultValues={defaultValues}
            />
          ) : null
        }
      />
      <div className={styles.SettingsCalendarView_ButtonsContainer}>
        <div className={styles.SettingsCalendarView_ButtonsContainer_MonthPicker}>
          {currentCalendarView === 'month' && <MonthPicker currentDate={moment(currentPeriod.startTime)} setCurrentDate={updateCurrentPeriod} />}
        </div>
        <div className={styles.SettingsCalendarView_ButtonsContainer_Padding}>
          <Switch
            value={currentCalendarView}
            onClick={(value) => {
              setCurrentCalendarView(value as `productionPlan` | `month` | `week`)
            }}
            items={[
              {
                value: `productionPlan`,
                label: t(`Production plan period`),
              },
              {
                value: `week`,
                label: t(`Week`),
              },
              {
                value: `month`,
                label: t(`Month`),
              },
            ]}
          />
        </div>
      </div>

      <BarCalendar
        startTime={currentPeriod.startTime}
        endTime={currentPeriod.endTime}
        categories={categories}
        events={events}
        onClick={(event) => editSetting(event)}
      />
    </div>
  )
}
