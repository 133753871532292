import React, { ReactElement, useState, useEffect } from 'react'

import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import { SettingAlertTexts, useSettingChange } from 'hooks/apiHooks/apiHooks'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import FormRow from 'ui/components/FormRow/FormRow'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'

import { Grid, Button } from '@mui/material'
import moment, { Moment } from 'moment'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { isBinarySetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { DateTimePickerRange, getAlternativeValue } from 'helpers/settingsModal.helper/settingsModal.helper'

import styles from './ObjectPropertySettingForm.module.less'
import AddDialogActions from './components/AddDialogActions/AddDialogActions'
import NoPermissionErrorMessage from './components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import PlannedUnplannedSelect from './components/PlannedUnplannedSelect/PlannedUnplannedSelect'
import PreviousSettingsTables from './components/PreviousSettingsTables/PreviousSettingsTables'
import { SettingDateTimePicker } from './components/SettingDateTimePicker/SettingDateTimePicker'
import SettingValueInput from './components/SettingValueInput/SettingValueInput'

type ActiveRowStatus = `editing` | `added` | `deleted`
export type ActiveRow = { id: number; status: ActiveRowStatus }

export type SettingFormData = {
  id: number
  start_time: string
  end_time: string | null
  value: string
  comment: string | null
  classification?: string
  userHasConfirmedNewHistoricalSettings?: boolean
}

export type DefaultValues = {
  id: number
  start_time: Moment
  end_time: Moment | null
  value: string
  comment: string | null
  classification?: string
}

export type ObjectPropertySettingFormProps = {
  objectProperty: ObjectProperty
  alertTexts?: SettingAlertTexts
  startEndTimeRange?: DateTimePickerRange
  showCreateButton?: boolean
  showPlannedUnplanned?: (prop?: ObjectProperty) => boolean
  showHistory?: boolean
  setIsDirty?: (arg: boolean) => void
  onFormFinished?: () => void
  defaultValues?: Partial<DefaultValues>
  isBaseValueSetting?: boolean
  permissions: SettingsPermissions
}

function getDefaultDateTime({ min, max, defaultTime }: { min?: Moment | ISODateTime; max?: Moment | ISODateTime; defaultTime: Moment }): string {
  return min && defaultTime.isBefore(min)
    ? moment(min).format()
    : max && defaultTime.isAfter(max)
      ? moment(max).format()
      : defaultTime.format()
}

const defaultStartTime = moment().startOf(`hour`)

export default function ObjectPropertySettingForm({
  objectProperty,
  alertTexts,
  startEndTimeRange,
  isBaseValueSetting = false,
  showCreateButton,
  showPlannedUnplanned,
  showHistory,
  setIsDirty,
  onFormFinished,
  defaultValues,
  permissions,
}: ObjectPropertySettingFormProps): ReactElement {

  const isBinary = objectProperty ? isBinarySetting(objectProperty.name) : false
  const [showForm, setShowForm] = useState(showCreateButton ? false : true)
  const { edit, add, remove } = useSettingChange({ alertTexts })
  const [activeRow, setActiveRow] = useState<ActiveRow | undefined>(undefined)
  const isEditing =
    activeRow?.status === `editing` || (defaultValues?.id !== undefined && activeRow?.status !== `deleted`)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [editConfirmOpen, setEditConfirmOpen] = useState(false)
  const [historicalEditConfirmOpen, setHistoricalEditConfirmOpen] = useState(false)
  const [cachedHistoricalSettingsData, setCachedHistoricalSettingsData] = useState<SettingFormData | undefined>()

  const { t } = useTranslation()
  const location = useLocation()
  const locationSandbox = location.pathname.includes(`/sandbox`)
  const { value } = isBinary
    ? getAlternativeValue(objectProperty)
    : { value: defaultValues?.value ?? `` }

  const mergedDefaultValues = {
    value: value,
    comment: ``,
    ...defaultValues,
    start_time: defaultValues?.start_time
      ? defaultValues.start_time.startOf(`hour`).format()
      : getDefaultDateTime({ ...startEndTimeRange?.startTime, defaultTime: defaultStartTime }),
    end_time: defaultValues?.end_time ? defaultValues.end_time.minutes(0).seconds(0).format() : null,
  }

  // Form related stuff
  const methods = useForm<SettingFormData>({
    mode: `onChange`,
    shouldUnregister: false,
    defaultValues: mergedDefaultValues,
  })

  const { handleSubmit, formState, reset } = methods
  const { isValid, isSubmitting, isDirty, errors } = formState

  useEffect(() => {
    setIsDirty?.(isDirty)
  }, [isDirty, setIsDirty])

  function resetForm(): void {
    reset(mergedDefaultValues)
    setActiveRow(undefined)
    showCreateButton && setShowForm(false)
    onFormFinished?.()
  }

  async function onSubmit(data: SettingFormData): Promise<void> {
    const isHistoricalSetting = moment(data?.end_time).isBefore()

    /*
    2023-05-15 Mattias Naarttijärvi
    We temporarilly attach "userHasConfirmedNewHistoricalSettings" to the form data in order to pass it into onSubmit in this render time.
    A useState would not come into affect until next render, and a secondary optional parameter is not possible since react-hook-form uses remainding param spaces for events.
    We delete it from form data (if present) before saving to db.
    */
    const userHasConfirmedNewHistoricalSettings = !!data?.userHasConfirmedNewHistoricalSettings
    if (userHasConfirmedNewHistoricalSettings) {
      delete data.userHasConfirmedNewHistoricalSettings
    }

    if (permissions.canEditHistoric
        && isHistoricalSetting
        && !userHasConfirmedNewHistoricalSettings
        && !locationSandbox) {
      setCachedHistoricalSettingsData(data)
      setHistoricalEditConfirmOpen(true)
      return
    }

    if (isEditing) {
      await edit(
        defaultValues?.id ?? data.id,
        objectProperty.id,
        data.start_time,
        data.end_time,
        Number(data.value),
        isBaseValueSetting,
        data.comment,
        data.classification
      ).then(() => {
        resetForm()
      })
    } else {
      await add(
        objectProperty.id,
        data.start_time,
        data.end_time,
        Number(data.value),
        isBaseValueSetting,
        data.comment,
        data.classification
      ).then((result) => {
        resetForm()
        if (!onFormFinished) {
          setActiveRow({ id: (result as Setting).id, status: `added` })
          setTimeout(() => setActiveRow(undefined), 3000)
        }
      })
    }
  }

  function onEditClick(
    comment: string | null,
    end_time: string | null,
    start_time: string,
    id: number,
    value: string,
    classification?: string
  ): void {
    setActiveRow({ id, status: `editing` })
    setShowForm(true)
    reset({
      comment,
      classification,
      end_time: end_time === null ? null : moment(end_time).minutes(0).seconds(0).format(),
      start_time: moment(start_time).minutes(0).seconds(0).format(),
      id,
      value,
    })
  }

  return (
    <>
      {!showForm ? (
        <div className={styles.ObjectPropertySettingForm_ButtonWrapper}>
          { !permissions.canEdit ? <NoPermissionErrorMessage title={t(`Unfortunately, you do not have permission to make changes here`)} message={t('Unfortunately, you do not have permission to change settings on this user/facility')} /> : <Button color="primary" variant="contained" onClick={() => setShowForm(true)} disabled={isSubmitting}>
            {t(`Create`)}
          </Button>}
        </div>
      ) : (
        <FormProvider {...methods}>
          <form aria-label="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column">
              <FormRow icon="fal fa-alarm-clock" style={{paddingTop: '20px'}}>
                <Grid item xs={12} md={6} lg={7}>
                  <SettingDateTimePicker allowEmpty minValue={startEndTimeRange?.startTime?.min} maxValue={startEndTimeRange?.endTime?.max} />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <SettingValueInput
                    disabled={!permissions.canEdit || isSubmitting}
                    error={errors.value ? true : false}
                    objectProperty={objectProperty}
                  />
                </Grid>
              </FormRow>
              <FormRow icon="fal fa-comment-alt-lines">
                <div className={styles.ObjectPropertySettingForm_CommentInputContainer}>
                  <ReactHookFormTextField name="comment" defaultValue="" label={t(`Reason`)} type="text" disabled={!permissions.canEdit || isSubmitting} />
                </div>
              </FormRow>
              {showPlannedUnplanned?.(objectProperty) && (
                <div className={styles.ObjectPropertySettingForm_CommentInputContainer}>
                  <FormRow icon="fal fa-calendar-alt">
                    <PlannedUnplannedSelect disabled={!permissions.canEdit || isSubmitting}/>
                  </FormRow>
                </div>
              )}
              <AddDialogActions
                onDelete={
                  defaultValues?.id
                    ? () => {
                      setActiveRow({ status: `editing`, id: defaultValues?.id ?? -1 })
                      setDeleteConfirmOpen(true)
                    }
                    : undefined
                }
                onCancel={() => {
                  isDirty ? setEditConfirmOpen(true) : resetForm()
                }}
                hasEditPermission={permissions.canEdit}
                editing={isEditing}
                isSubmitting={isSubmitting}
                saveButtonDisabled={!isValid || (isEditing && !isDirty) || isSubmitting}
              />
            </Grid>
          </form>
        </FormProvider>
      )}
      {showHistory && (
        <PreviousSettingsTables
          objectProperty={objectProperty}
          unit={objectProperty?.measurement_unit !== null ? objectProperty?.measurement_unit : `-`}
          isBinaryProperty={isBinary}
          isBaseValue={isBaseValueSetting}
          activeRow={activeRow}
          permissions={permissions}
          onEditClick={onEditClick}
          onDeleteClick={(id: number) => {
            setActiveRow({ status: `deleted`, id: id })
            setDeleteConfirmOpen(true)
          }}
        />
      )}
      <ConfirmDialog
        open={deleteConfirmOpen}
        onClose={() => {
          setActiveRow(undefined)
          setDeleteConfirmOpen(false)
        }}
        onConfirm={() => {
          if (activeRow?.id) remove(activeRow.id, objectProperty.id)
          resetForm()
          setDeleteConfirmOpen(false)
        }}
        textObject={{
          title: t(`Do you really want to delete this setting?`),
          text: t(
            `If you remove this setting it will be deleted from history and not included in the follow-up. Instead, you could choose to cancel and then edit the end time.`
          ),
          confirm: t(`Remove setting`),
        }}
      />
      <ConfirmDialog
        open={editConfirmOpen}
        onClose={() => {
          setEditConfirmOpen(false)
        }}
        onConfirm={() => {
          resetForm()
          setEditConfirmOpen(false)
        }}
        textObject={
          activeRow?.status === `editing`
            ? {
              title: t(`Do you really want to close without saving your modified setting?`),
              text: t(
                `Currently you have unsaved changes, are you completely sure you would like to exit without saving?`
              ),
            }
            : {
              title: t(`Do you really want to close without saving?`),
              text: t(
                `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
              ),
            }
        }
      />

      <ConfirmDialog
        open={historicalEditConfirmOpen}
        onClose={() => {
          setHistoricalEditConfirmOpen(false)
        }}
        onConfirm={() => {
          if (typeof cachedHistoricalSettingsData === 'undefined') {
            return
          }

          onSubmit({
            ...cachedHistoricalSettingsData,
            userHasConfirmedNewHistoricalSettings: true,
          })

          setHistoricalEditConfirmOpen(false)
        }}
        textObject={{
          title: t(`Do you really want to create a historical setting?`),
          text: t(
            `Note that the setting you're creating applies to a period back in time where follow-up has already been run. To be included in the follow-up, the follow-up for the setting's period needs to be rerun. Do you still want to create the setting?`
          ),
        }}
      />
    </>
  )
}

