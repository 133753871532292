import Datetime from 'utils/datetime/datetime'

import ChartJS from 'chart.js/auto'
import i18n from 'i18next'

export type TodayMarkerOptions = {
  enabled?: boolean,
  color?: string
  fillBeforeNow?: boolean,
}
export default {
  id: `todayMarker`,
  enabled: true,
  color: `#1b72b8`,
  fillBeforeNow: false,
  afterDraw: function (chart: ChartJS, _args: any, options: TodayMarkerOptions) {
    if (options.enabled === false) {
      return
    }
    
    const now = Datetime.getISONow()
    const labels = chart?.data?.labels ?? []

    if (labels.length <= 2) {
      return
    }

    const startTime: ISODateTime = labels[0] as ISODateTime
    const endTime: ISODateTime = labels[labels.length - 1] as ISODateTime

    if (!startTime || !endTime) {
      return
    }

    if (Datetime.isBeforeOrEqual(now, startTime) || Datetime.isAfter(now, endTime)) {
      return
    }


    let nowIndex = 0
    let labelTime = startTime
    while (nowIndex < labels.length - 1) {
      labelTime = labels[nowIndex] as ISODateTime
      if (Datetime.isBeforeOrEqual(now, labelTime)) {
        break
      }

      nowIndex++
    }

    if (nowIndex <= 0 || nowIndex >= labels.length - 1) {
      return
    }

    const {
      ctx,
      chartArea: { top, bottom },
      scales: { x },
    } = chart
    ctx.save()

    // Fill the background color before "now"

    if (options.fillBeforeNow) {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.07)'
      const startPixel = x.getPixelForValue(0)
      const nowPixel = x.getPixelForValue(nowIndex)
      ctx.fillRect(startPixel, top, nowPixel - startPixel, bottom - top)
    }

    ctx.strokeStyle = options.color ?? `#1b72b8`
    ctx.lineWidth = 2
    ctx.strokeRect(x.getPixelForValue(nowIndex), top, 0, bottom - 10)

    ctx.fillStyle = options.color ?? `#1b72b8`
    ctx.textAlign = `center`
    ctx.font = `14px sans-serif`
    ctx.fillText(i18n.t(`Now`), x.getPixelForValue(nowIndex), bottom + 9)
    ctx.restore()
  },
}
