import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './Button.module.less'

export type ButtonProps = {
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void)
  title?: string
  disabled?: boolean
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  warning?: boolean
  children?: ReactElement | string
  icon?: string
  loading?: boolean
  large?: boolean
  tight?: boolean
  link?: boolean
  marginRight?: boolean
  marginLeft?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  noRadiusLeft?: boolean
  noRadiusRight?: boolean
  noPadding?: boolean
  tooltip?: string
  disabledTooltip?: string
  largeIcon?: boolean
  alignCenter?: boolean
  justifyCenter?: boolean
  transparent?: boolean
  hideBorder?: boolean
  style?: React.CSSProperties
  className?: string
  'data-testid'?: string
}

export default function Button({
  disabled,
  primary,
  secondary,
  danger,
  warning,
  children,
  icon,
  link,
  large,
  tight,
  noRadiusLeft,
  noRadiusRight,
  noPadding,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  transparent,
  tooltip,
  disabledTooltip,
  largeIcon,
  onClick,
  alignCenter,
  justifyCenter,
  hideBorder,
  style = {},
  className,
  ...props
}: ButtonProps): ReactElement {
  return (
    <button
      data-testid={props[`data-testid`]}
      className={`${dynamicClassName({
        [styles.Button]: true,
        [styles.Button__disabled]: !!disabled && !link,
        [styles.Button__primary]: !disabled && !!primary,
        [styles.Button__secondary]: !!secondary && !(!!link || !!danger),
        [styles.Button__danger]: !!danger && !secondary && !link,
        [styles.Button__danger__secondary]: !!danger && !!secondary && !link,
        [styles.Button__danger__link]: !!danger && !!link,
        [styles.Button__danger__disabled]: !!danger && !!disabled,
        [styles.Button__warning]: !!warning,
        [styles.Button__warning__secondary]: !!warning && !!secondary && !link,
        [styles.Button__warning__link]: !!warning && !!link,
        [styles.Button__large]: !!large,
        [styles.Button__tight]: !!tight,
        [styles.Button__link]: !!link,
        [styles.Button__link__secondary]: !!link && !!secondary,
        [styles.Button__link__disabled]: !!link && !!disabled,
        [styles.Button__marginRight]: !!marginRight,
        [styles.Button__marginLeft]: !!marginLeft,
        [styles.Button__marginTop]: !!marginTop,
        [styles.Button__marginBottom]: !!marginBottom,
        [styles.Button__noRadiusLeft]: !!noRadiusLeft,
        [styles.Button__noRadiusRight]: !!noRadiusRight,
        [styles.Button__noPadding]: !!noPadding,
        [styles.Button__alignCenter]: !!alignCenter,
        [styles.Button__justifyCenter]: !!justifyCenter,
        [styles.Button__transparent]: !!transparent,
        [styles.Button__hideBorder]: !!hideBorder,
      })} ${className}`}
      style={style}
      onClick={(ev) => {
        if (disabled || !onClick) {
          return
        }

        onClick(ev)
      }}
      title={disabled ? disabledTooltip : tooltip}
    >
      {icon &&
        <i
          className={dynamicClassName({
            [icon + ` ` + styles.Button__icon]: true,
            [styles.Button__icon__large]: !!largeIcon,
          })}
          style={{ margin: icon === `fas fa-sort-down` ? `0px` : undefined}}
        />
      }
      {children}
    </button>
  )
}
