import { BulletinObjectProperty } from 'api/bulletinBoard/bulletinBoard.api'
import { Event, SettingReferenceField } from 'api/systemEvents/systemEvents.api'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'

import { formatPossibleNullTimestamp } from 'helpers/dateTime.helper/dateTime.helper'
import { getSettingValue, getDisplayName } from 'helpers/global.helper/global.helper'

export function formatEventText(event: Event, objectProperty?: BulletinObjectProperty): Record<string, string> | null {

  const returnObj = {
    categoryText: '',
    eventText: '',
  }
  const category = event.event_category
  const type = event.event_type
  const data = objectProperty
    ? {
      category: objectProperty.category,
      unit: getDisplayName(objectProperty.parent),
      objectProperty: getDisplayName(objectProperty),
      settingsValue: event.reference_fields
        ? getSettingValue((event.reference_fields as SettingReferenceField)?.value ?? ``)
        : null,
      startTime: event.reference_fields ? Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`) : null,
      endTime: event.reference_fields
        ? formatPossibleNullTimestamp(
          (event.reference_fields as SettingReferenceField)?.end_time ?? ``,
          `ddd D MMMM HH:mm`,
          i18n.t(`Until further notice`)
        )
        : null,
    }
    : undefined

  if (data && typeof data.settingsValue === `number`) data.settingsValue = parseFloat(data.settingsValue.toFixed(2))

  if (category === `optimize_setting` && type === `setting_edited`) {
    if(data){
      returnObj.categoryText = i18n.t(`{{objectProperty}} {{unit}}`, { ...data })
      returnObj.eventText = i18n.t(`was changed to {{settingsValue}} between {{startTime}} - {{endTime}}`, { ...data })
    }
  } else if (category === `optimize_setting` && type === `setting_added`) {
    if(data){
      returnObj.categoryText = i18n.t(`{{objectProperty}} {{unit}}`, { ...data })
      returnObj.eventText = i18n.t(`was set to {{settingsValue}} between {{startTime}} - {{endTime}}`, { ...data })
    }
  } else if (category === `optimize_setting` && type === `setting_comment_added`) {
    if(data){
      returnObj.categoryText = i18n.t(`Comment regarding {{objectProperty}} {{unit}},`, { ...data })
      returnObj.eventText = i18n.t(`{{settingsValue}} between {{startTime}} - {{endTime}}`, { ...data })
    }
  } else if (category === `optimization_regular` && type === `optimization_finished`) {
    if (event.triggered_by !== null) {
      returnObj.categoryText = i18n.t(`Manual optimization`)
      returnObj.eventText = event.reference_fields
        ? i18n.t(`has finished between {{startTime}} - {{endTime}}`, {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.addWeeks(Datetime.toISOString(event.reference_fields.start_time), 1), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        })
        : i18n.t(`has finished`)
    }
    else{
      returnObj.categoryText = i18n.t(`An automatic optimization`)
      returnObj.eventText = event.reference_fields
        ? i18n.t(`has finished between {{startTime}} - {{endTime}}`, {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.addWeeks(Datetime.toISOString(event.reference_fields.start_time), 1), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        })
        : ``
    }
  } else if (category === `optimization_sandbox` && type === `optimization_finished`) {
    returnObj.categoryText = i18n.t(`A sandbox optimization`)
    returnObj.eventText = i18n.t(`has finished`)
  } else if (category === `optimization_followup_with_deviations` && type === `optimization_finished`) {
    returnObj.categoryText = i18n.t(`Follow-up optimization (incl. production deviations)`)
    if (event.reference_fields.end_time) {
      returnObj.eventText = i18n.t(
        `has finished between {{startTime}} - {{endTime}}`,
        {
          startTime:Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.end_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        }
      )
    } else {
      returnObj.eventText = i18n.t(`has finished with start time {{startTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    }
  } else if (category === `optimization_followup_without_deviations` && type === `optimization_finished`) {
    returnObj.categoryText = i18n.t(`Follow-up optimization (optimal production)`)
    if (event.reference_fields.end_time) {
      returnObj.eventText = i18n.t(
        `has finished between {{startTime}} - {{endTime}}`,
        {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.end_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        }
      )
    } else {
      returnObj.eventText = i18n.t(`has finished with start time {{startTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    }
  } else if (category === `optimization_measvalues_calculations`) {
    returnObj.categoryText = i18n.t(`Outcome values`)
    if (event.reference_fields.end_time) {
      returnObj.eventText = i18n.t(`have been updated between {{startTime}} - {{endTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        endTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.end_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    } else {
      returnObj.eventText = i18n.t(`have been updated with start time {{startTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    }
  } else if (category === `forecast_load`) {
    returnObj.categoryText = i18n.t(`Heat demand forecast`)
    returnObj.eventText = i18n.t(`has been updated`)
  } else if (category === `forecast_elprice`) {
    returnObj.categoryText = i18n.t(`Electricity price forecast`)
    returnObj.eventText = i18n.t(`has been updated`)
  } else if (type === `automatic_optimization_enabled`) {
    returnObj.categoryText = i18n.t(`Automatic optimization`)
    returnObj.eventText = i18n.t(`has been enabled`)
  } else if (type === `automatic_optimization_disabled`) {
    returnObj.categoryText = i18n.t(`Automatic optimization`)
    returnObj.eventText = i18n.t(`has been disabled`)
  } else if (category === `optimization_regular` && type === `optimization_failed`) {
    if (event.triggered_by !== null) {
      returnObj.categoryText = i18n.t(`Manual optimization`)
      returnObj.eventText = event.reference_fields
        ? i18n.t(`has failed between {{startTime}} - {{endTime}}`, {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.addWeeks(Datetime.toISOString(event.reference_fields.start_time), 1), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        })
        : i18n.t(`has failed`)
    }
    else{
      returnObj.categoryText = i18n.t(`An automatic optimization`)
      returnObj.eventText = event.reference_fields
        ? i18n.t(`has failed between {{startTime}} - {{endTime}}`, {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.addWeeks(Datetime.toISOString(event.reference_fields.start_time), 1), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        })
        : i18n.t(`has failed`)
    }
  } else if (category === `optimization_sandbox` && type === `optimization_failed`) {
    returnObj.categoryText = i18n.t(`A sandbox optimization`)
    returnObj.eventText = event.reference_fields
      ? i18n.t(`has failed between {{startTime}} - {{endTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        endTime: Datetime.toLocalTime(Datetime.addWeeks(Datetime.toISOString(event.reference_fields.start_time), 1), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
      : i18n.t(`has failed`)
  } else if (category === `optimization_followup_with_deviations` && type === `optimization_failed`) {
    returnObj.categoryText = i18n.t(`Follow-up optimization (incl. production deviations)`)
    if (event.reference_fields.end_time) {
      returnObj.eventText = i18n.t(
        `has failed between {{startTime}} - {{endTime}}`,
        {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.end_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        }
      )
    } else {
      returnObj.eventText = i18n.t(`has failed with start time {{startTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    }
  } else if (category === `optimization_followup_without_deviations` && type === `optimization_failed`) {
    returnObj.categoryText = i18n.t(`Follow-up optimization (optimal production)`)
    if (event.reference_fields.end_time) {
      returnObj.eventText = i18n.t(
        `has failed between {{startTime}} - {{endTime}}`,
        {
          startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
          endTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.end_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
        }
      )
    } else {
      returnObj.eventText = i18n.t(`has failed with start time {{startTime}}`, {
        startTime: Datetime.toLocalTime(Datetime.toISOString(event.reference_fields.start_time), `longDayAndMonthTextWithoutYearIfCurrentYear`),
      })
    }
  }

  return returnObj
}
