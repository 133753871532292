import React, { ReactElement, useState } from 'react'

import { dynamicClassName } from 'styles/helper'

import { Tooltip } from '@mui/material'

import styles from './Switch.module.less'

type TabItem = {
  value: string
  label: string
  tooltip?: string
}

type TabsProps<T> = {
  items: TabItem[]
  value?: T
  setNoTabActive?: boolean
  onClick: (value: T) => void
}

export default function Switch<T>({ 
  items = [], 
  value, 
  setNoTabActive = false, 
  onClick,
}: TabsProps<T>): ReactElement {
  const itemIsClickedIndex = items.findIndex((item) => item.value === value)
  const [activeTab, setActiveTab] = useState<number>(itemIsClickedIndex ?? 0)

  if (!items?.length) {
    return <></>
  }

  return (
    <div
      className={dynamicClassName({
        [styles.SwitchGroup]: true,
      })}
    >
      {items.map((item, index) => (
        <div
          key={`tab_${index}`}
          className={dynamicClassName({
            [styles.SwitchGroup_Switch]: true,
            [styles.SwitchGroup_Switch__firstElement]: index === 0,
            [styles.SwitchGroup_Switch__lastElement]: index === items.length - 1,
            [styles.SwitchGroup_Switch__active]: !setNoTabActive && index === activeTab,
          })}
          onClick={() => {
            if (onClick) {
              onClick(item.value as T)
            }

            setActiveTab(index)
          }}
        >
          <div
            className={dynamicClassName({
              [styles.SwitchGroup_Switch_Title]: true,
              [styles.SwitchGroup_Switch_Title__active]: !setNoTabActive && index === activeTab,
            })}
          >
            {item.tooltip ? (
              <Tooltip title={item.tooltip} arrow>
                {<span>{item.label}</span>}
              </Tooltip>
            ) : (
              item.label
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
