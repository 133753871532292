import React, { ReactElement, ReactNode, useState } from 'react'

import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps, registerComponentHeight } from 'ui/uiConfig/factory'

import { Pie } from 'react-chartjs-2'

import { sortChartItems } from '../Chart/Tooltip/tooltip.helper'
import {
  getReturnIdsFromDatasetInstruction,
  applyAggregateFromUiConfig,
} from 'helpers/dataset.helper/dataset.helper'
import {getTypeObject } from 'helpers/global.helper/global.helper'

import styles from './PieChart.module.less'

export type PieChartItem = {
  title: string
  data_id: string
  color?: string
  unit?: string
  decimals?: number
  tooltip?: string
  data_type?: string
  order?: number | string | undefined
  stack_group: string | number
  [key: string]: any
}

type PieChartStackGroup = {
  title: string
  id: string | number
  include_only_base_items_with_attr?: Record<string, boolean | number | string | null>
}

type PieChartProps = {
  uid: number
  items: PieChartItem[]
  stack_groups: PieChartStackGroup[]
  title: string
  data_type: string
  datasets: Dataset[]
  children: ReactNode
  ignoreZoom: boolean
}

const defaultProps: Omit<PieChartProps, `datasets` | `children` | `uid` | `ignoreZoom`> = {
  items: [
    {
      title: ``,
      data_id: ``,
      color: `$color-1`,
      unit: ``,
      order: 0,
      stack_group: 0,
      decimals: 1,
      data_type: ``,
    },
  ],
  stack_groups: [
    {
      title: ``,
      id: 0,
    },
  ],
  title: ``,
  data_type: '',
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: PieChartProps
): PieChartProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId,
    data_id: returnId,
    color: `$color-1`,
    unit: ``,
    order: 0,
    stack_group: 0,
    decimals: 1,
    data_type: ``,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent('pie_chart', PieChart)
registerAppendPropsWithDatasets('pie_chart', appendPropsWithDataset)
registerDefaultProps('pie_chart', defaultProps)
registerComponentHeight('pie_chart', 112)

export default function PieChart({
  uid,
  items,
  datasets,
  title,
  stack_groups,
  data_type,
}: PieChartProps): ReactElement {
  const [showTable, setShowState] = useState(false)

  if (!datasets.length && !items.length) {
    return <></>
  }
  if (!stack_groups?.length) {
    return <></>
  }

  sortChartItems(items)

  //add the data_ids that should only be included  if "include_only_base_items_with_attr" is set on stack_group items
  const onlyIncludedDataIdsFromAttr: string[][] = [[], []]

  stack_groups.forEach(stackGroup => {
    const stackGroupId = Number(stackGroup.id)
    Object.entries(stack_groups[stackGroupId]?.include_only_base_items_with_attr || {})
      .forEach(([attr, value]) => {
        items?.forEach((item) => {
          if (item[attr] === value && item.stack_group === stackGroupId) {
            onlyIncludedDataIdsFromAttr[stackGroupId].push(item.data_id)
          }
        })
      })
  })

  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, { invalidIndexCheckIds: items.map(({ data_id }) => data_id)})

  const stackGroupTotal: Record<string | number, number> = {}
  const returnIdToStackGroupId: Record<string, string | number> = {}
  stack_groups.forEach((stackGroup) => {
    stackGroupTotal[stackGroup.id] = 0

    items
      .filter((item) => (item.stack_group === stackGroup.id)).forEach((item) => {
        returnIdToStackGroupId[item.data_id] = stackGroup.id
      })

  })
  aggregatedDatasets
    .filter(({ return_id }) => returnIdToStackGroupId[return_id] !== undefined)
    .forEach((dataset) => {
      let value = 0
      const stackGroupId = Number(returnIdToStackGroupId[dataset.return_id])

      if (stack_groups[stackGroupId]?.include_only_base_items_with_attr) {
        if (onlyIncludedDataIdsFromAttr[stackGroupId].find((item) => item === dataset.return_id)) {
          value = dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0
        }
      } else {
        value = dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0
      }

      if (!stackGroupTotal[stackGroupId]) {
        stackGroupTotal[stackGroupId] = 0
      }
      stackGroupTotal[stackGroupId] += value
    })
  let maxValue = Math.max(...Object.values(stackGroupTotal))
  if (maxValue < 0) {
    maxValue = 0
  }

  const idToValueMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: dataset.values.filter((v) => v !== null && v !== undefined)?.[0],
    }),
    {}
  )

  const titleToStackGroupToValueMap: Record<string, Record<string | number, number>> = {}
  const titleToStyleMap: Record<string, { color: string; dataType: string; unit: string }> = {}
  const itemTitles = Array.from(new Set(items.map((item) => item.title)))
  items.forEach((item) => {
    if (!titleToStackGroupToValueMap[item.title]) {
      titleToStackGroupToValueMap[item.title] = {}
    }
    if (!titleToStyleMap[item.title]) {
      titleToStyleMap[item.title] = {
        color: item.color ?? ``,
        dataType: data_type ?? item.data_type ?? ``,
        unit: item.unit ?? ``,
      }
    }

    titleToStackGroupToValueMap[item.title][item.stack_group] = idToValueMap[item.data_id] ?? 0
  })

  return (
    <>
      <div
        onMouseEnter={() => setShowState(true)}
        onMouseLeave={() => {
          setShowState(false)
        } }
      >
        {title && <TitleWithIcon title={title} size={`small`} padding={false} />}
        {stack_groups.map((stackGroup) => {
          const data = {
            labels: items
              .filter(item => item.stack_group === stackGroup.id)
              .map(item => item.title),
            datasets: [
              {
                data: items
                  .filter(item => item.stack_group === stackGroup.id)
                  .map(item => idToValueMap[item.data_id] ?? 0),
                backgroundColor: items
                  .filter(item => item.stack_group === stackGroup.id)
                  .map(item => item.color || '#000'),
              },
            ],
          }

          return (
            <div key={`barWrapper_${stackGroup.id}`}>
              <div className={styles.PieChart_Pie}>
                <Pie data={data}
                  options = {{
                    plugins:{
                      legend: {display: false},
                    }}} />
              </div>
              <div className={styles.PieChart_Pie_Title}>{stackGroup.title}</div>
            </div>
          )
        })}
        {showTable && (
          <div className={styles.PieChart_Table}>
            <table>
              <thead>
                <tr>
                  <th>{``}</th>
                  {stack_groups.map((stackGroup) => (
                    <th key={`header_${stackGroup.id}`}>{stackGroup.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {itemTitles
                  .filter((title) => {
                    for (let i = 0; i < stack_groups.length; i++) {
                      const stackGroup = stack_groups[i]
                      const value = titleToStackGroupToValueMap[title][stackGroup.id] || 0

                      if (value) {
                        return true
                      }
                    }
                    return false
                  })
                  .map((title) => {
                    const styling = titleToStyleMap[title]
                    const backgroundColor = styling.color
                    const dataType = styling.dataType
                    const unit = styling.unit
                    const decimals = 2

                    return (
                      <tr key={title}>
                        <td>
                          <div className={styles.PieChart_Table_Dot} style={{ backgroundColor }} />
                          {title}
                        </td>
                        {stack_groups.map((stackGroup) => {
                          let value: string | number | null = titleToStackGroupToValueMap[title][stackGroup.id] ?? 0
                          if (typeof stackGroup?.id === 'number' && stackGroup?.include_only_base_items_with_attr) {
                            const theItem = items.find((item) => item.title === title && item.stack_group === stackGroup.id)
                            const dataIdsFromAttrForStackGroup = onlyIncludedDataIdsFromAttr[stackGroup.id]
                            const dataIdFromAttr = dataIdsFromAttrForStackGroup.find((data_id) => 
                              data_id === theItem?.data_id && theItem?.stack_group === stackGroup.id
                            )
                            if (theItem && !dataIdFromAttr) {
                              value = null
                            }
                          }
                          const typeObj = getTypeObject(
                            value,
                            dataType,
                            unit,
                            decimals
                          )
                          return (
                            <td className={styles.PieChart_Table_Cell} key={`${title}_${stackGroup.id}`}>
                              <div className={styles.PieChart_Pie_Value}>{typeObj.value}</div>
                              <div className={styles.PieChart_Pie_Unit}>{typeObj.unit}</div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div></>
  )
}
