import React, { ReactElement, useState, useRef } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './ResizeableRow.module.less'

type ResizeableRowProps = {
  height?: number | string
  children?: ReactElement[] | ReactElement
}

export default function ResizeableRow({ children, height: defaultHeight }: ResizeableRowProps): ReactElement {
  const [height, setHeight] = useState(defaultHeight ?? `100%`)
  const [isResizing, setIsResizing] = useState(false)
  const container = useRef(null)
  
  function mouseMove(ev: MouseEvent): void {
    if (!container?.current || !isResizing) {
      return
    }
    
    const y = ev.clientY
    const yc = container.current.getBoundingClientRect()
    
    setHeight(y - yc.y + 5)
  }

  return (
    <div
      className={dynamicClassName({ [styles.ResizeableRow]: true, [styles.ResizeableRow__active]: isResizing })}
      style={{ height }}
      ref={container}
    >
      <div className={styles.ResizeableRow_Body}>{children}</div>

      <div
        className={dynamicClassName({
          [styles.ResizeableRow_DragBorder]: true,
          [styles.ResizeableRow_DragBorder__active]: isResizing,
        })}
        onMouseMove={mouseMove}
        onMouseDown={() => setIsResizing(true)}
        onMouseLeave={() => setIsResizing(false)}
        onMouseUp={() => setIsResizing(false)}
      />
    </div>
  )
}
