import React, { ReactElement, useMemo, useRef, useState } from 'react'

import { PlanValues, useElplanInfo } from 'api/elplan/elplan.api'
import elplanStore from 'store/elplan/elplan'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import Datetime from 'utils/datetime/datetime'

import { DialogContent , Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'

import styles from './DAOverviewModal.module.less'

type DAOverviewModalProps = {
  onClose: () => void
  onSubmit: (planValues: PlanValues[]) => void
  elplanExistsWarningText?: string
  elplanFromSpotBid: PlanValues[],
}

export default function DAOverviewModal({ onClose, onSubmit, elplanExistsWarningText, elplanFromSpotBid }: DAOverviewModalProps): ReactElement {
  const ref = useRef(null)
  const { t } = useTranslation()
  const elplanSnap = useSnapshot(elplanStore)

  const elplanExistsForCurrentDate = elplanSnap.elplan.has_plan
  const dayAheadSupplierResultsExists = Array.isArray(elplanSnap?.elplan?.day_ahead_supplier_results) && elplanSnap.elplan.day_ahead_supplier_results.length > 0

  const [currentPlanValues, setCurrentPlanValues] = useState<PlanValues[]>(clone(elplanSnap?.elplan.values).length > 0 ? clone(elplanSnap?.elplan.values) : elplanFromSpotBid)
  const [activeButton, setActiveButton] = useState(elplanExistsForCurrentDate ? 'elplan_current' : 'elplan_bids')
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  const timeAndValues = useMemo(() => {
    function onCellChange(e: any, index: number): void {
      const copy = currentPlanValues
      copy[index].volume = e.target?.innerText
      setCurrentPlanValues(copy)
    }

    return currentPlanValues?.sort((a, b) => a.start_time.localeCompare(b.start_time)).map((obj, index) => {
      // TODO: Add label?
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <tr key={obj.start_time}>
        <div>
          <td className={styles.DAOverviewModal_Table_Cell}>{`${Datetime.toLocalTime(obj.start_time, 'hour')}-${Datetime.toLocalTime(Datetime.addHours(obj.start_time, 1), 'hour')}`}</td>
          <td className={styles.DAOverviewModal_EditableContent} contentEditable='true' suppressContentEditableWarning onBlur={(e) => onCellChange(e, index)}>
            {`${obj.volume}`}</td>
        </div>
      </tr>
    })
  }, [currentPlanValues])

  const halfOfHourValues = Math.ceil(timeAndValues.length / 2)

  const auth = useAuth()
  const elplanInfo = useElplanInfo(auth.systemId)
  const integrationExists = elplanInfo?.data?.integration ?? false

  return (
    <div className={styles.DAOverviewModal}>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={elplanExistsForCurrentDate ? t('Edit electricity plan') : t('Create electricity plan')} handleClose={() => onClose()} />
        <DialogContent >
          <div className={styles.DAOverviewModal_Buttons}>
            {elplanExistsForCurrentDate && (
              <Button
                primary={activeButton === 'elplan_current'}
                secondary={activeButton !== 'elplan_current'}
                disabled={!elplanExistsForCurrentDate}
                onClick={() => {
                  setActiveButton('elplan_current')
                  setCurrentPlanValues(clone(elplanSnap?.elplan.values) as unknown as PlanValues[])
                }}
              >{t('Lagd elplan')}</Button>
            )}
            {integrationExists && (
              <Tooltip title={!dayAheadSupplierResultsExists ? t(`Fetch results from supplier with the button 'Fetch results'`) : ''} placement='top' arrow>
                <div>
                  <Button
                    primary={activeButton === 'elplan_supplier'}
                    secondary={activeButton !== 'elplan_supplier'}
                    disabled={!dayAheadSupplierResultsExists}
                    onClick={() => {
                      setActiveButton('elplan_supplier')
                      setCurrentPlanValues(clone(elplanSnap?.elplan?.day_ahead_supplier_results) as unknown as PlanValues[])
                    }}
                  >{t('Electricity plan from supplier')}</Button>
                </div>
              </Tooltip>
            )}
            <Button
              primary={activeButton === 'elplan_bids'}
              secondary={activeButton !== 'elplan_bids'}
              onClick={() => {
                setActiveButton('elplan_bids')
                setCurrentPlanValues(elplanFromSpotBid as unknown as PlanValues[])
              }}

            >{t('Electricity plan from bids')}</Button>
          </div>

          <table className={styles.DAOverviewModal_Table}>
            <tbody className={styles.DAOverviewModal_Body}>
              <tr className={styles.DAOverviewModal_Table_Cell}>
                <th className={styles.DAOverviewModal_Table_Cell__header_time}> <span className={`${'fal fa-clock'} ${styles.DAOverviewModal_Table_Icon}`}/>{t('Time')}</th>
                <th className={styles.DAOverviewModal_Table_Cell__header}> <span className={`${'fal fa-bolt'} ${styles.DAOverviewModal_Table_Icon}`}/>{`${t('Volume')} (MW)`}</th>
              </tr>
              <div className={styles.DAOverviewModal_Table_Cell}>
                {timeAndValues.slice(0, halfOfHourValues)}
              </div>
            </tbody>

            <tbody className={styles.DAOverviewModal_Body}>
              <tr className={styles.DAOverviewModal_Table_Cell}>
                <th className={styles.DAOverviewModal_Table_Cell__header_time}> <span className={`${'fal fa-clock'} ${styles.DAOverviewModal_Table_Icon}`}/>{t('Time')}</th>
                <th className={styles.DAOverviewModal_Table_Cell__header}> <span className={`${'fal fa-bolt'} ${styles.DAOverviewModal_Table_Icon}`}/>{`${t('Volume')} (MW)`}</th>
              </tr>
              <div className={styles.DAOverviewModal_Table_Cell}>
                {timeAndValues.slice(halfOfHourValues)}
              </div>
            </tbody>
          </table>

          <ModalButtons onSubmit={() => {
            if (elplanSnap.elplan.has_plan) {
              setConfirmDialog(true)
            } else {
              onSubmit(currentPlanValues)
              onClose()
            }
          }}
          onCancel={() => onClose()}
          infoText={elplanSnap.elplan.has_plan ? elplanExistsWarningText : undefined}
          />

        </DialogContent>
      </Dialog>
      {confirmDialog &&
       <ConfirmDialog
         open={confirmDialog}
         onConfirm={() => {
           setConfirmDialog(false)
           onClose()
           onSubmit(currentPlanValues)
         }}
         onClose={() => setConfirmDialog(false)}
         textObject={{
           title: t(`Are you sure you want to save this manual electric plan?`),
           text: t(`You are currently editing an already created electricity plan. Any changes you make to this plan may differ from the plan you have sent into trading. Do you want to continue to save the electricity plan?`),
         }}
       />}
    </div>
  )
}
