import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Typography, Icon } from '@mui/material'
import classNames from 'classnames'

import styles from './MetricField.module.less'

type MetricFieldProps = {
  iconCode?: string
  value?: number | string
  name?: string
  unit?: string
  tooltip?: string
  collectionName?: string
}

function MetricField({ iconCode, value, name, unit, tooltip, collectionName }: MetricFieldProps): ReactElement {
  const NameContent = React.forwardRef<HTMLSpanElement, { name?: string }>(
    ({ name }, ref): ReactElement => (
      <Typography ref={ref} variant="body2" className={classNames(styles.MetricField_NameText, styles.MetricField_Name)}>
        {name}
      </Typography>
    )
  )

  return (
    <Grid container className={styles.MetricField_Container} direction="row" justifyContent={`flex-start`}>
      {iconCode && (
        <Grid item container xs={2} justifyContent="flex-end">
          <Icon className={classNames(styles.MetricField_Icon, iconCode)} />
        </Grid>
      )}
      <Grid item xs={iconCode ? 10 : 12}>
        {value && unit ? (
          <Tooltip title={value + unit} arrow placement="top-start">
            <span>
              <Typography variant="body1" className={classNames(styles.MetricField_Value)}>
                {value}
                {unit ? <span style={{ fontSize: `0.5em` }}>{unit}</span> : null}
              </Typography>
            </span>
          </Tooltip>
        ) : (
          <Typography variant="body1" className={classNames(styles.MetricField_Value)}>
            {value}
            {unit ? <span style={{ fontSize: `0.5em` }}>{unit}</span> : null}
          </Typography>
        )}
        {collectionName && <NameContent name={collectionName} />}
        {tooltip ? (
          <Tooltip title={tooltip} arrow placement="bottom-start">
            <span>
              <NameContent name={name} />
            </span>
          </Tooltip>
        ) : (
          <NameContent name={name} />
        )}
      </Grid>
    </Grid>
  )
}

export default MetricField
