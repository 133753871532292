import React, { ReactElement } from 'react'

import { Typography, Icon } from '@mui/material'
import classNames from 'classnames'
import { t } from 'i18next'
import { Button } from 'react-aria-components'

import styles from './InfoBox.module.less'

type InfoBoxProps = {
  infoHeader: string | JSX.Element
  onInfoButtonClick?: () => void
  beta?: boolean
}

export default function InfoBox ({infoHeader, onInfoButtonClick, beta}: InfoBoxProps): ReactElement {

  return (
    <div className={styles.InfoBox_TitleAndInfo}>
      <Typography variant="h1" className={styles.InfoBox_InfoHeader}>
        {beta ? <span className={styles.InfoBox_Beta}>Beta</span> : <></>}
        {infoHeader}
      </Typography>
      {onInfoButtonClick && (
        <Button
          color="inherit"
          variant="outlined"
          className={styles.InfoBox_InfoButton}
          onClick={onInfoButtonClick}
        >
          <Icon fontSize="small" className={classNames(`fas fa-info-circle`, styles.InfoBox_InfoButtonIcon)} />
          <Typography variant="subtitle2" className={styles.InfoBox_InfoButtonText}>
            {t(`I want to learn more`)}
          </Typography>
        </Button>
      )}
    </div>
  )
}
