import React, { ReactElement, useState } from 'react'

import { LogBookPost, useGetPinnedLogBookPosts } from 'api/logBook/logBook.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import CreateLogBookPostModal from 'views/LogBookView/LogBookPostModal/LogBookPostModal'

import PinnedItem from './PinnedItem/PinnedItem'
import styles from './PinnedMessages.module.less'

export default function PinnedMessages(): ReactElement {
  const { systemId } = useAuth()
  const logBookPostData = useGetPinnedLogBookPosts(systemId).data || []

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState<LogBookPost>()

  function openEditModal(item: LogBookPost): void {
    setCurrentItem(item)
    setIsModalOpen(true)
  }

  function closeEditModal(): void {
    setIsModalOpen(false)
    setCurrentItem(undefined)
  }

  return (
    <>
      <div className={styles.PinnedMessages_Items}>
        {logBookPostData.map((item, index) => (
          <PinnedItem key={index} onEditClick={() => openEditModal(item)} data={item} />
        ))}
      </div>

      <CreateLogBookPostModal
        open={isModalOpen}
        handleClose={() => closeEditModal()}
        edit={true}
        log_post={currentItem}
      />
    </>
  )
}
