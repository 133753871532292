import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { IconButton, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './ManageField.module.less'

type ManageFieldProps = {
  onEditClick?: () => void
  onDeleteClick?: () => void
  isEditing?: boolean
  isDisabled?: boolean
}

export default function ManageField({
  onEditClick,
  onDeleteClick,
  isEditing,
  isDisabled,
}: ManageFieldProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div>
      <Tooltip title={t(`Edit setting`)} placement="top" arrow>
        <>
          <IconButton
            disabled={isEditing || isDisabled}
            onClick={onEditClick}
            aria-label={t(`Edit setting`)}
            className={styles.ManageField_Edit}
            size="large"
          >
            <Icon className={classNames(styles.ManageField_Edit__icon, `fal fa-pen`)} />
          </IconButton>
        </>
      </Tooltip>
      {onDeleteClick && (
        <Tooltip title={t(`Delete setting`)} placement="top" arrow>
          <IconButton
            onClick={onDeleteClick}
            disabled={isDisabled}
            aria-label={t(`Delete setting`)}
            className={styles.ManageField_Delete}
            size="large"
          >
            <Icon className={classNames(styles.ManageField_Delete__icon, `fal fa-trash-alt`)} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
