import React, { ReactElement, useState } from 'react'

import { useHeatSuppliers } from 'api/heatSuppliers/heatSuppliers.api'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import Overview from './components/Overview/Overview'
import SamEnergiHeader, { CounterContentClass } from './components/SamEnergiHeader/SamEnergiHeader'
import SupplierView from './components/SupplierView/SupplierView'

const widgetConstants = {
  SAMENERGI_GRID: `samEnergiGrid`,
  OVERVIEW_WIDGET: `overview_widget`,
  SUPPLIER_WIDGET: `supplier_widget`,
}

const overview: Array<Step> = [
  {
    target: widgetConstants.OVERVIEW_WIDGET,
    content: `In the EnergyCollab module prices that are offered excess heat suppliers are calculated and visualized. The price is calculated automatically for the next day and is updated every hour by optimizations that are run in the background and are using the heat load and given conditions in the settings`,
    title: `Overview`,
  },
]

const supplier: Array<Step> = [
  {
    target: widgetConstants.SUPPLIER_WIDGET,
    content: `In this view, more details about each excess hest supplier can be seen in one tab per supplier. In the table, hourly values are shown for the EnergyCollab price, demand on delivery temperature and if delivery is desired or not.With the three buttons to the right of the table, you can edit if delivery is desired or not for specific hours, change the demad on delivery temperature and edit the agreement of how many hours you are able to decline delivery during summer and winter.`,
    title: `Suppliers`,
  },
]

const steps: Array<Step> = [
  ...overview,
  {
    target: CounterContentClass,
    content: `The price for the next day is locked for changes at 13.20 every day when it is also sent automatically to the excess heat suppliers. The countdown shows how much time there is left to make any changes before the price is locked and sent.`,
    title: `Countdown`,
  },
  ...supplier,
]

function SamEnergiView(): ReactElement {
  const [runTour, setRunTour] = useState<null | number>(null)
  const { data: heatSuppliers } = useHeatSuppliers()
  const atleastOneSupplierIsReal = heatSuppliers?.find((supplier) => !supplier.is_fictive) ?? false
  const { t } = useTranslation()

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={t(`SamEnergi`)}
          onInfoButtonClick={() => {
            setRunTour(0)
          }}
        >
          {atleastOneSupplierIsReal ? (
            <Grid container justifyContent="flex-end">
              <SamEnergiHeader />
            </Grid>
          ) : (
            <></>
          )}
        </ModuleHeader>
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? overview : runTour === 2 ? supplier : steps}
      />
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.OVERVIEW_WIDGET}
          title={t(`Overview`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <Overview />
        </CardWidget>
      </Grid>
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.SUPPLIER_WIDGET}
          title={t(`Detailed supplier view`)}
          handleInfoIconClick={() => {
            setRunTour(2)
          }}
        >
          <SupplierView />
        </CardWidget>
      </Grid>
    </View>
  )
}

export default SamEnergiView
