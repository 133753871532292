import { useCallback, useMemo } from 'react'

import { AccumulatorInfo } from 'api/digitalTwin/digitalTwin.api'
import { useModules } from 'api/modules/modules.api'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../AuthContext/AuthContext'
import { useHasPermission } from 'helpers/global.helper/global.helper'

import { AggregatedAccumulatorData, OnEditAccumulatorLevel } from './LevelsForOneDigitalTwinAccumulator'
import { AccumulatorLevelAttribute } from './SingleDigitalTwinAccumulatorLevel'

function getEditAccumulatorSettingRoute(
  unitName: string,
  attribute: string,
  isBaseSetting: boolean,
  sandboxProjectId?: number
): string {
  // Add the right query parameters so that a modal is opened immediately when we navigate to the settings view
  const queryParams: Record<string, string> = {
    level: 'unit',
    name: unitName,
    attribute,
  }

  // If we are editing settings of a sandbox project, we should navigate to the sandbox settings
  // If not, we should go to either the base settings view or the devation settings view
  let route: string
  if (sandboxProjectId !== undefined) {
    route = `/sandbox/${sandboxProjectId}`
    queryParams['base_setting'] = isBaseSetting ? '1' : '0'
  } else {
    route = isBaseSetting ? '/digitalTwinBaseSettings' : '/digitalTwinDeviationSettings'
  }

  const queryString = new URLSearchParams(queryParams).toString()
  return `${route}?${queryString}`
}

type UseAggregatedAccumulatorDataProps = {
  accumulators?: AccumulatorInfo[]
  sandboxProjectId?: number
}

export function useAggregatedAccumulatorData({ accumulators, sandboxProjectId }: UseAggregatedAccumulatorDataProps):
  | {
      [accumulatorName: string]: AggregatedAccumulatorData
    }
  | undefined {
  const { t } = useTranslation()

  const auth = useAuth()
  const { data: modules = [] } = useModules(auth.systemId)
  const moduleNames = useMemo(() => modules.map((item) => item.name), [modules])

  const history = useHistory()

  const canEditBaseSettings = useHasPermission(`change_model_settings`)
  const canEditDeviationSettings = useHasPermission(`change_plan_settings`)

  // Check if we should navigate to sandbox settings, base settings, or deviation settings
  // depending on the accumulator level.
  // Also check if the user has permission to edit the specific accumulator level setting.
  const getEditAccumulatorFunction = useCallback(
    (unitName: string, attribute: AccumulatorLevelAttribute, isBaseSetting: boolean) => {
      const path = getEditAccumulatorSettingRoute(unitName, attribute, isBaseSetting, sandboxProjectId)
      const onEdit = (): void => history.push(path)

      let hasPermission = false
      if (sandboxProjectId !== undefined) {
        hasPermission = moduleNames.includes('sandbox')
      } else if (isBaseSetting) {
        hasPermission = moduleNames.includes('optimizemodelsettings') && canEditBaseSettings
      } else {
        hasPermission = moduleNames.includes('optimizesettings') && canEditDeviationSettings
      }

      return { onEdit, hasPermission }
    },
    [canEditBaseSettings, canEditDeviationSettings, history, moduleNames, sandboxProjectId]
  )

  const aggregatedDataPerAccumulator = useMemo(
    () =>
      accumulators?.reduce((acc: { [accumulatorName: string]: AggregatedAccumulatorData }, accumulator) => {
        const unitName = accumulator.unit_name

        // Start level
        let startLevelValue: number
        const startLevelWarnings: string[] = []
        let onEditStartLevelClicked: undefined | OnEditAccumulatorLevel
        if (
          accumulator.start_level_deviation_setting_value !== null &&
          accumulator.start_level_deviation_setting_value !== undefined
        ) {
          // Devation setting is set
          startLevelValue = accumulator.start_level_deviation_setting_value
          startLevelWarnings.push(t('Using value from deviation setting.'))

          onEditStartLevelClicked = getEditAccumulatorFunction(unitName, 'start_level', false)
        } else if (accumulator.start_level_meas_value !== null && accumulator.start_level_meas_value !== undefined) {
          // No devation setting is set, but measvalue is found
          startLevelValue = accumulator.start_level_meas_value
          // No start level warning

          onEditStartLevelClicked = getEditAccumulatorFunction(unitName, 'start_level', false)
        } else {
          // No measvalue found, use base setting
          startLevelValue = accumulator.start_level_base_setting_value
          startLevelWarnings.push(t('No start level measurement value found, using base setting value as fallback.'))

          onEditStartLevelClicked = getEditAccumulatorFunction(unitName, 'start_level', true)
        }

        // Settings can make changes to min and max level during the optimization period,
        // so we need to compare start level to min and max for the start of the optimization (start_min_level & start_max_level).
        if (
          startLevelValue !== undefined &&
          accumulator.start_min_level !== undefined &&
          startLevelValue < accumulator.start_min_level
        ) {
          startLevelWarnings.push(t('Start level lower than min level. Optimization may be affected.'))
        }
        if (
          startLevelValue !== undefined &&
          accumulator.start_max_level !== undefined &&
          startLevelValue > accumulator.start_max_level
        ) {
          startLevelWarnings.push(t('Start level greater than max level. Optimization may be affected.'))
        }

        // End level
        let endLevelValue: number
        const endLevelWarnings: string[] = []
        let onEditEndLevelClicked: undefined | OnEditAccumulatorLevel
        if (
          accumulator.end_level_deviation_setting_value !== null &&
          accumulator.end_level_deviation_setting_value !== undefined
        ) {
          // Devation setting is set
          endLevelValue = accumulator.end_level_deviation_setting_value
          endLevelWarnings.push(t('Using value from deviation setting.'))
          onEditEndLevelClicked = getEditAccumulatorFunction(unitName, 'end_level', false)
        } else {
          // No deviation found, use base setting
          endLevelValue = accumulator.end_level_base_setting_value
          // No end level warning

          onEditEndLevelClicked = getEditAccumulatorFunction(unitName, 'end_level', true)
        }

        // Settings can make changes to min and max level during the optimization period,
        // so we need to compare end level to min and max for the end of the optimization (end_min_level & end_max_level).
        if (
          endLevelValue !== undefined &&
          accumulator.end_min_level !== undefined &&
          endLevelValue < accumulator.end_min_level
        ) {
          endLevelWarnings.push(t('End level lower than min level. Optimization may be affected.'))
        }
        if (
          endLevelValue !== undefined &&
          accumulator.end_max_level !== undefined &&
          endLevelValue > accumulator.end_max_level
        ) {
          endLevelWarnings.push(t('End level greater than max level. Optimization may be affected.'))
        }

        acc[accumulator.unit_name] = {
          unitName,
          startLevelValue,
          startLevelWarnings,
          onEditStartLevelClicked,
          endLevelValue,
          endLevelWarnings,
          onEditEndLevelClicked,
        }

        return acc
      }, {}),
    [accumulators, getEditAccumulatorFunction, t]
  )

  return aggregatedDataPerAccumulator
}
