import React, { ReactElement, useMemo, useState } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import { Button, Inline } from 'ui/atoms'
import Chart from 'ui/uiConfig/components/Chart/Chart'
import { DEFAULT_CONFIG } from 'ui/uiConfig/components/Chart/chart.constants'
import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { applyAggregateFromUiConfig } from 'helpers/dataset.helper/dataset.helper'
import { clone } from 'helpers/global.helper/global.helper'

import styles from './UiConfigDebugMenu.module.less'

type DatasetChartProps = {
  dataset: Dataset
  uiConfig: UiConfig
  uid: UiConfigUid
}

function datasetsAreEqual(datasetA: Dataset, datasetB: Dataset): boolean {

  if (datasetA.values.length !== datasetB.values.length) {
    return false
  }

  const tolerance = 0.0001

  for (let i = 0; i < datasetA.values.length; i++) {
    const valueA = datasetA.values[i]
    const valueB = datasetB.values[i]

    if (valueA === valueB) {
      continue
    }

    if (valueA === null || valueB === null) {
      return false
    }

    if (Math.abs(valueA - valueB) > tolerance) {
      return false
    }
  }

  return true
}

function DatasetChart({ uid, dataset, uiConfig }: DatasetChartProps): ReactElement {
  const item = uiConfig.props?.items?.find((item) => item.data_id === dataset.return_id)
  const title = item?.title ? `${item.title} (${dataset.return_id})` : dataset.return_id
  const [aggregatedDataset] = applyAggregateFromUiConfig([dataset], uid)
  const hasAggregate = aggregatedDataset === undefined ? false : !datasetsAreEqual(dataset, aggregatedDataset)


  const items: ChartItem[] = [{
    ...(item || {}),
    color: item?.color || '#000000',
    title,
    data_id: dataset.return_id,
  }]
  const datasets = [dataset]

  if (hasAggregate) {
    const aggregatedReturnId = `aggregated_${dataset.return_id}`
    items.push({
      ...items[0],
      title: `${title} [Aggregated]`,
      data_id: aggregatedReturnId,
    })

    if (aggregatedDataset.values.length === 1) {
      items[1].y_axis_id = 'yAggregated'
    }

    items[0].dashed = !items[0].dashed

    datasets.push({
      ...clone(aggregatedDataset),
      return_id: aggregatedReturnId,
    })
  }

  return <>
    <b>{dataset.return_id}</b>
    <Chart uid={uid} items={items} datasets={datasets} config={DEFAULT_CONFIG} height={100} ignoreAggregate={true} />
  </>
}

type UiConfigDebugMenuProps = {
  uid: UiConfigUid
  id: UiConfigId
  datasets: Dataset[]
  datasetStartTime?: ISODateTime
  datasetEndTime?: ISODateTime
  overrideAlias?: UiConfigAliases
  datasetCreatedAt?: string
  datasetCreatedAtOperator?: string
}

export default function UiConfigDebugMenu({ uid, id, datasets, overrideAlias, datasetEndTime, datasetStartTime, datasetCreatedAt, datasetCreatedAtOperator }: UiConfigDebugMenuProps): ReactElement {
  const [activeUid, setActiveUid] = useState<UiConfigUid | null>(null)
  const [pageNr, setPageNr] = useState(0)
  const pageSize = 5
  const { t } = useTranslation()
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const expandDebugMenu = activeUid === uid
  const uiConfig = uiConfigSnap.getParsedUiConfig(uid, overrideAlias)

  const datasetsOnPage = useMemo(() => {
    const start = pageNr * pageSize
    const end = start + pageSize

    return datasets.slice(start, end)
  }, [datasets, pageNr, pageSize])

  if (!uiConfigSnap.showDebugMenu) {
    return <></>
  }

  return (
    <>
      <div className={classNames([styles.UiConfigDebugMenuToggle, 'animation'])} onClick={() => setActiveUid(uid)}>
        <div>{id}</div>
      </div>

      {expandDebugMenu && uiConfig && (
        <div className={styles.UiConfigDebugMenu}>
          <Inline flexEnd>
            <Button danger onClick={() => {
              setActiveUid(null)
              setPageNr(0)
            }}>{t('Close')}</Button>

            {/* eslint-disable-next-line no-console */}
            <Button onClick={() => console.log({ uiConfig, overrideAlias, datasetStartTime, datasetEndTime, datasetCreatedAt, datasetCreatedAtOperator, datasets })}>{t('Log to console')}</Button>
          </Inline>

          <p>
            {`UID: ${uid}, ID: ${id}`}
            <br/>
            {`Datasets: ${datasets.length}`}
          </p>

          {datasetsOnPage.map((dataset, index) => (
            <DatasetChart uid={uid} dataset={dataset} key={`dataset_chart_${index}`} uiConfig={uiConfig} />))}

          <Inline spaceBetween marginBottom marginTop>
            {pageNr > 0 && <Button primary onClick={() => setPageNr(pageNr - 1)}>{t('Previous')}</Button>}
            <div />
            {datasets.length > (pageNr + 1) * pageSize && <Button primary onClick={() => setPageNr(pageNr + 1)}>{t('Next')}</Button>}
          </Inline>
        </div>)}
    </>
  )
}
