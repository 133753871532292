import React, { ReactElement, useRef } from 'react'

import {
  UnitAvailabilityPlannedStopFormData,
  useUnitAvailabilityPlannedStopMutation,
} from 'api/unitAvailabilityPlannedStop/unitAvailabilityPlannedStop.api'
import alertStore from 'store/alert/alert'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import { Dialog } from 'ui/components/Dialog/Dialog'
import NoPermissionErrorMessage from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import { SettingDateTimePicker } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/SettingDateTimePicker/SettingDateTimePicker'
import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'
import ReactHookFormSelect from 'ui/components/ReactHookFormSelect/ReactHookFormSelect'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'

import { Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, MenuItem } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


type UnitAvailabilityPlannedStopModalProps = {
  hasChangePermission: boolean
  edit?: boolean
  open: boolean
  onClose: (args: boolean) => void
  onRefetch?: () => void
  unitName: string
  event?: UnitAvailabilityPlannedStopFormData
  period?: Period
}

function PlannedStopModal({
  hasChangePermission,
  edit,
  open,
  onClose,
  onRefetch,
  unitName,
  event,
}: UnitAvailabilityPlannedStopModalProps): ReactElement {
  const { t } = useTranslation()
  const { mutateAsync } = useUnitAvailabilityPlannedStopMutation()
  const { activeSystem } = useAuth()

  const defaultValues: UnitAvailabilityPlannedStopFormData = {
    id: event ? event.id : 0, // Ensure id is always a number
    comment: event ? event.comment : '',
    event_type: event ? event.event_type : 'planned_unavailability', // Ensure event_type matches EventType
    start_time: event ? moment(event.start_time).format('YYYY-MM-DD HH:00') : moment().format('YYYY-MM-DD HH:00'),
    end_time: event
      ? moment(event.end_time).format('YYYY-MM-DD HH:00')
      : moment().add(7, 'd').format('YYYY-MM-DD HH:00'),
    unit_name: unitName,
    system_id: activeSystem?.id ?? 0, // Ensure system_id is always a number
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const { handleSubmit, formState, reset } = methods
  const { isSubmitting } = formState

  const onCloseModal = (): void => {
    reset(defaultValues)
    onClose(false)
  }

  const onSave = handleSubmit((input: UnitAvailabilityPlannedStopFormData) => {
    const plannedStopData = {
      data: { ...input, unit_name: unitName, system_id: activeSystem?.id ?? 0 },
      id: event ? event.id : undefined,
    }
    mutateAsync(plannedStopData).then(
      () => {
        onCloseModal()
        if (onRefetch) {
          onRefetch()
        }
        alertStore.success(t('Saved event success'))
      },
      (errorMessage) => {
        alertStore.error(`${errorMessage}`)
      }
    )
  })

  const ref = useRef(null)

  return (
    <div ref={ref}>
      <Dialog container={ref.current} open={open} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>{edit ? t('Change existing event') : t('Add new event')}</Grid>
            <Grid item>
              <PopoverCloseButton handleClose={() => onCloseModal()} />
            </Grid>
          </Grid>
        </DialogTitle>
        {!hasChangePermission && (
          <NoPermissionErrorMessage
            title={t('Unfortunately, you do not have permission to add a new event')}
            message={t('Unfortunately, you do not have permission to add a new event on this user/facility')}
          />
        )}
        <FormProvider {...methods}>
          <form onSubmit={onSave}>
            <DialogContent>
              <Grid container direction="column">
                <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  <Grid item xs={1}>
                    <Icon className="fal fa-info-circle" />
                  </Grid>
                  <Grid item xs={10}>
                    <ReactHookFormSelect
                      disabled={!hasChangePermission || isSubmitting}
                      fullWidth
                      name="event_type"
                      label={t('Pick event type')}
                      margin="normal"
                      variant="outlined"
                      rules={{ required: true }}
                      style={{ borderRadius: 5 }}
                    >
                      <MenuItem key={'planned_unavailability'} value={'planned_unavailability'}>
                        {t('Planned unavailability')}
                      </MenuItem>
                      <MenuItem key={'back'} value={'planned_load_reduction'}>
                        {t('Planned load reduction')}
                      </MenuItem>
                    </ReactHookFormSelect>
                  </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  <Grid item xs={1}>
                    <Icon className="fal fa-comment-alt-lines" />
                  </Grid>
                  <Grid item xs={10}>
                    <ReactHookFormTextField
                      disabled={!hasChangePermission || isSubmitting}
                      name="comment"
                      label={t('Description of event')}
                      type="text"
                      rules={{ required: true }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center" spacing={1}>
                  <Grid item xs={1}>
                    <Icon className="fal fa-alarm-clock" />
                  </Grid>
                  <Grid item container xs={10} spacing={1} direction="row" justifyContent="space-between">
                    <Grid item xs={12}>
                      <SettingDateTimePicker
                        isDisabled={!hasChangePermission || isSubmitting}
                        startLabel={t('From')}
                        endLabel={t('To')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                onClick={onCloseModal}
                disableRipple={true}
              >
                {t('Cancel')}
              </Button>
              <ContainedIconButton
                color="primary"
                icon={edit ? 'fal fa-save' : 'fal fa-plus'}
                label={edit ? t('Save') : t('Add new event')}
                type="submit"
                style={{ marginTop: 20, marginBottom: 20 }}
                disabled={!formState.isValid || !hasChangePermission || isSubmitting}
              />
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  )
}

export default PlannedStopModal
