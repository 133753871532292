import React, { ReactElement } from 'react'

import { useSystemMessages } from 'api/systemMessage/systemMessage.api'
import RedirectSnackbar from 'ui/molecules/RedirectSnackbar/RedirectSnackbar'

import moment from 'moment'


export default function SystemMessageBanner(): ReactElement {

  const systemMessages = useSystemMessages()

  return (
    <>
      {systemMessages.data?.filter(
        (message) => {
          const now = moment()
          const hasStarted = !message.start_time || moment(message.start_time).isBefore(now)
          const hasEnded = message.end_time && moment(message.end_time).isSameOrBefore(now)
          return hasStarted && !hasEnded
        }
      ).map((message) => (
        <RedirectSnackbar
          key={message.id}
          text={message.text}
          style={message.severity}
          systemId={message.id}
          name={`{message.title}-${message.id}`}
        />
      ))}
    </>
  )
}
