import React, { ReactElement, ReactNode } from 'react'

import { DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP } from 'api/dataset/dataset.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { Card, Tabs } from 'ui/atoms'
import { TabItem } from 'ui/atoms/Tabs/Tabs'
import PrintButton from 'ui/molecules/PrintButton/PrintButton'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import ExcelButton from 'ui/uiConfig/components/ExcelButton/ExcelButton'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './FollowupProduction.module.less'

type FollowupProductionCell = {
  title?: string,
  ui_config_id: number
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

type FollowupProductionTab = {
  title: string
  kpi: FollowupProductionCell
  total_cost: FollowupProductionCell
  total_production: FollowupProductionCell
  optimal: FollowupProductionCell
  outcome: FollowupProductionCell
  extra?: FollowupProductionCell
}

type FollowupProductionHeaderTab = {
  title?: string
  headerTabKpi?: FollowupProductionCell
  tabs: FollowupProductionTab[]
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

type FollowupProductionProps = {
  headerTabs: FollowupProductionHeaderTab[]
  systemId: number
  children: ReactNode[]
  datasetEndTime?: ISODateTime
  datasetStartTime?: ISODateTime
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

const defaultProps: Omit<FollowupProductionProps, `datasets` | `children` | `systemId`> = {
  headerTabs: [
    {
      title: ``,
      headerTabKpi: {
        ui_config_id: NaN,
        override_alias: {},
      },
      tabs: [
        {
          title: `__Optimal production`,
          kpi: {
            ui_config_id: NaN,
            override_alias: {},
          },
          total_cost: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 4,
              outcome_opt_job_type_id: 5,
            },
          },
          total_production: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 4,
              outcome_opt_job_type_id: 5,
            },
          },
          optimal: {
            ui_config_id: NaN,
            override_alias: { opt_job_type_id: 4 },
          },
          outcome: {
            ui_config_id: NaN,
            override_alias: {},
          },
        },
        {
          title: `__Include deviations`,
          kpi: {
            ui_config_id: NaN,
            override_alias: {},
          },
          total_cost: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 3,
              outcome_opt_job_type_id: 5,
            },
          },
          total_production: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 3,
              outcome_opt_job_type_id: 5,
            },
          },
          optimal: {
            ui_config_id: NaN,
            override_alias: { opt_job_type_id: 3 },
          },
          outcome: {
            ui_config_id: NaN,
            override_alias: {},
          },
        },
        {
          title: `__Production plan`,
          kpi: {
            ui_config_id: NaN,
            override_alias: {},
          },
          total_cost: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 2,
              outcome_opt_job_type_id: 5,
            },
          },
          total_production: {
            ui_config_id: NaN,
            override_alias: {
              optimal_opt_job_type_id: 2,
              outcome_opt_job_type_id: 5,
            },
          },
          optimal: {
            ui_config_id: NaN,
            override_alias: { opt_job_type_id: 2 },
          },
          outcome: {
            ui_config_id: NaN,
            override_alias: {},
          },
        },
      ],
    },
  ],
}
registerUiConfigComponent(`followup_production`, FollowupProduction, {
  isSingletonComponent: true,
  title: i18n.t(`Follow-up - Production`),
})
registerDefaultProps(`followup_production`, defaultProps)

function getSystemtabs(
  route: string,
  handleTabChange: (index: number) => void,
  headerTabs: FollowupProductionHeaderTab[]
): JSX.Element {
  const systemTabItems: TabItem[] = []
  const localStorageKey =  `FollowupProductionHeaderTabs_${route}`

  headerTabs.forEach((tab) => {
    systemTabItems.push({
      title: tab.title,
      children: [],
    })
  })

  if (route === `uiconfig`) {
    return <Tabs items={systemTabItems} onChange={handleTabChange} uniqueStorageKey={localStorageKey} />
  }

  if (route === `followupproduction`) {
    const element = document.getElementById(`portal_for_tabs`)
    if (element) {
      return ReactDOM.createPortal(
        <Tabs items={systemTabItems} onChange={handleTabChange} innerLeftPadding marginBottom={false} uniqueStorageKey={localStorageKey} />,
        element
      )
    }
  }
  return <></>
}

function getFollowupProdKpi(route: string, tabIndex: number, headerTabs: FollowupProductionHeaderTab[], systemId: number, datasetStartTime: ISODateTime, datasetEndTime: ISODateTime, uid: number): JSX.Element {

  if (route === `uiconfig`) {
    return <UiConfigComponent
      systemId={systemId}
      id={headerTabs[tabIndex].headerTabKpi?.ui_config_id ?? NaN}
      datasetStartTime={datasetStartTime}
      datasetEndTime={datasetEndTime}
      uid={uid}
      overrideAlias={headerTabs[tabIndex]?.override_alias}
      key_prefix={tabIndex}
      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
    />
  }

  if (route === `followupproduction`) {
    const element = document.getElementById(`portal_for_followupprodkpi`)
    if (element) {
      return ReactDOM.createPortal(
        <UiConfigComponent
          systemId={systemId}
          id={headerTabs[tabIndex].headerTabKpi?.ui_config_id ?? NaN}
          datasetStartTime={datasetStartTime}
          datasetEndTime={datasetEndTime}
          uid={uid}
          overrideAlias={headerTabs[tabIndex]?.override_alias}
          key_prefix={tabIndex}
          datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
        />,
        element)
    }
  }
  return <></>
}


export default function FollowupProduction({
  headerTabs,
  systemId,
  datasetStartTime,
  datasetEndTime,
}: FollowupProductionProps): ReactElement {
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const [tabIndex, setTabIndex] = React.useState(0)
  const route = getRoute()
  const { t } = useTranslation()
  const localStorageKeyForTabs = `FollowupProductionTabs_${route}_${tabIndex}`


  function handleTabChange(index: number): void {
    setTabIndex(index)
  }

  const metricsId = headerTabs[tabIndex].headerTabKpi?.ui_config_id ?? Object.values(uiConfigSnap.uiConfigs).find(
    (u) => u.component === `follow_up_prod_kpi`
  )?.id

  const period = {startTime: Datetime.toLocalTime(datasetStartTime), endTime: Datetime.toLocalTime(datasetEndTime)}


  const systemTabs = getSystemtabs(route, handleTabChange, headerTabs)
  const followupProdKpi = getFollowupProdKpi(route, tabIndex, headerTabs, systemId, datasetStartTime, datasetEndTime, uiConfigSnap.idToUiConfig[headerTabs[tabIndex].kpiId]?.uid )
  return (
    <Card margin>
      {headerTabs.length > 1 && systemTabs}
      {headerTabs[tabIndex].headerTabKpi?.ui_config_id &&
        followupProdKpi}
      <Tabs
        items={headerTabs[tabIndex].tabs.map((tab) => ({
          title: tab.title,
          children: (
            <div className={styles.FollowupProduction}>

              <div className={styles.FollowupProduction_HorizontalBars}>
                {tab.kpi?.ui_config_id && <div className={styles.FollowupProduction_HorizontalBars_Kpi}>

                  <UiConfigComponent
                    systemId={systemId}
                    id={tab.kpi?.ui_config_id ?? NaN}
                    datasetStartTime={datasetStartTime}
                    datasetEndTime={datasetEndTime}
                    uid={uiConfigSnap.idToUiConfig[tab.kpi?.ui_config_id]?.uid}
                    overrideAlias={tab.kpi?.override_alias || headerTabs[tabIndex]?.override_alias}
                    overrideProps={tab.kpi?.override_props || headerTabs[tabIndex]?.override_props}
                    key_prefix={tabIndex}
                    datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                  />
                </div>}
                <div className={styles.FollowupProduction_HorizontalBars_TotalCost}>
                  {tab.total_cost?.ui_config_id && (
                    <UiConfigComponent
                      systemId={systemId}
                      id={tab.total_cost?.ui_config_id}
                      datasetStartTime={datasetStartTime}
                      datasetEndTime={datasetEndTime}
                      uid={uiConfigSnap.idToUiConfig[tab.total_cost?.ui_config_id]?.uid}
                      overrideAlias={tab.total_cost?.override_alias || headerTabs[tabIndex]?.override_alias}
                      overrideProps={tab.total_cost?.override_props || headerTabs[tabIndex]?.override_props}
                      key_prefix={tabIndex}
                      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                    />
                  )}
                </div>
                <div className={styles.FollowupProduction_HorizontalBars_TotalProduction}>
                  {tab.total_production?.ui_config_id && (
                    <UiConfigComponent
                      systemId={systemId}
                      id={tab.total_production?.ui_config_id}
                      datasetStartTime={datasetStartTime}
                      datasetEndTime={datasetEndTime}
                      uid={uiConfigSnap.idToUiConfig[tab.total_production?.ui_config_id]?.uid}
                      overrideAlias={tab.total_production?.override_alias || headerTabs[tabIndex]?.override_alias}
                      overrideProps={tab.total_production?.override_props || headerTabs[tabIndex]?.override_props}
                      key_prefix={tabIndex}
                      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                    />
                  )}
                </div>
              </div>
              <div className="FollowupProduction_Optimal">
                <TitleWithIcon title={tab.optimal.title ?? t(`Optimal`)} size={`small`} marginTop padding={false} />
                {tab.optimal?.ui_config_id && (
                  <UiConfigComponent
                    systemId={systemId}
                    id={tab.optimal?.ui_config_id}
                    datasetStartTime={datasetStartTime}
                    datasetEndTime={datasetEndTime}
                    uid={uiConfigSnap.idToUiConfig[tab.optimal?.ui_config_id]?.uid}
                    overrideAlias={tab.optimal?.override_alias || headerTabs[tabIndex]?.override_alias}
                    overrideProps={tab.optimal?.override_props || headerTabs[tabIndex]?.override_props}
                    key_prefix={tabIndex}
                    datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                  />
                )}
              </div>
              <div className="FollowupProduction_Outcome">
                <TitleWithIcon title={tab.outcome.title ?? t(`Outcome`)} size={`small`} marginTop padding={false} />
                {tab.outcome?.ui_config_id && (
                  <UiConfigComponent
                    systemId={systemId}
                    id={tab.outcome?.ui_config_id}
                    datasetStartTime={datasetStartTime}
                    datasetEndTime={datasetEndTime}
                    uid={uiConfigSnap.idToUiConfig[tab.outcome?.ui_config_id]?.uid}
                    overrideAlias={tab.outcome?.override_alias || headerTabs[tabIndex]?.override_alias}
                    overrideProps={tab.outcome?.override_props || headerTabs[tabIndex]?.override_props}
                    key_prefix={tabIndex}
                    datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                  />
                )}
              </div>
              <div className={styles.FollowupProduction_Buttons}>
                <ExcelButton
                  title={t(`Export to Excel`)}
                  style={'button'}
                  tabs={[
                    {
                      title: tab.optimal?.title ?? `__Optimal`,
                      ui_config_id: tab.optimal?.ui_config_id,
                      defaultTimeFormat: `YYYY-MM-DD HH:mm`,
                      show_times_in_utc: false,
                      overrideAlias: tab.optimal?.override_alias || headerTabs[tabIndex]?.override_alias,
                      overrideProps: tab.optimal?.override_props || headerTabs[tabIndex]?.override_props,
                      dataset_instructions: [],
                    },
                    {
                      title: tab.outcome?.title ?? `__Outcome`,
                      ui_config_id: tab.outcome?.ui_config_id,
                      defaultTimeFormat: `YYYY-MM-DD HH:mm`,
                      show_times_in_utc: false,
                      overrideAlias: tab.outcome?.override_alias || headerTabs[tabIndex]?.override_alias,
                      overrideProps: tab.outcome?.override_props || headerTabs[tabIndex]?.override_props,
                      dataset_instructions: [],
                    },
                    {
                      title: `__Total cost`,
                      ui_config_id: tab.total_cost?.ui_config_id,
                      defaultTimeFormat: `YYYY-MM-DD HH:mm`,
                      overrideAlias: tab.total_cost?.override_alias || headerTabs[tabIndex]?.override_alias,
                      overrideProps: tab.total_cost?.override_props || headerTabs[tabIndex]?.override_props,
                      show_times_in_utc: false,
                      dataset_instructions: [],
                    },
                    {
                      title: `__Metrics`,
                      ui_config_id: metricsId,
                      defaultTimeFormat: `YYYY-MM-DD HH:mm`,
                      show_times_in_utc: false,
                      overrideAlias: headerTabs[tabIndex].headerTabKpi?.ui_config_id ? headerTabs[tabIndex].headerTabKpi?.override_alias : null,
                      overrideProps: headerTabs[tabIndex].headerTabKpi?.ui_config_id ? headerTabs[tabIndex].headerTabKpi?.override_props : null,
                      dataset_instructions: [],
                    },
                  ].filter(({ ui_config_id }) => !!ui_config_id)}
                  datasetStartTime={datasetStartTime}
                  datasetEndTime={datasetEndTime}
                />

                <PrintButton fileName={`abs-followup-${period.startTime}-${period.endTime}.png`} title={t('Print tab')} />
              </div>
              {tab.extra?.ui_config_id &&
                              <div className={styles.FollowupProduction__padding}>
                                <TitleWithIcon title={tab.extra?.title ?? ''} />
                                <UiConfigComponent
                                  systemId={systemId}
                                  id={tab.extra?.ui_config_id}
                                  datasetStartTime={datasetStartTime}
                                  datasetEndTime={datasetEndTime}
                                  uid={uiConfigSnap.idToUiConfig[tab.extra?.ui_config_id]?.uid}
                                  overrideAlias={tab.extra?.override_alias || headerTabs[tabIndex]?.override_alias}
                                  overrideProps={tab.extra?.override_props || headerTabs[tabIndex]?.override_props}
                                  key_prefix={tabIndex}
                                  datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                                />
                              </div>}
            </div>

          ),
        }))}
        uniqueStorageKey={localStorageKeyForTabs}/>
    </Card>
  )
}
