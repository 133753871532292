import React, { ReactElement, useRef, useState } from 'react'

import elplanBidStore from 'store/elplan/elplan'
import { Dialog } from 'ui/components/Dialog/Dialog'
import FormRow from 'ui/components/FormRow/FormRow'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import { TimePicker } from 'ui/molecules/pickers/TimePicker/TimePicker'
import Datetime from 'utils/datetime/datetime'

import { DialogContent, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { FormattedBid, FormattedBidWithIdAndTitle } from '../bidManagement.helper'
import { useCurrency } from 'helpers/global.helper/global.helper'

import styles from './BidModal.module.less'

type BidModalProps = {
  editBid: (obj: FormattedBidWithIdAndTitle) => void
  createBid?: (obj: FormattedBid) => void
  deleteBid?: (obj: FormattedBidWithIdAndTitle) => void
  bid?: FormattedBidWithIdAndTitle
  closeModal: () => void
  type?: 'spot' | 'block'
  isEditing?: boolean

}

export default function BidModal({editBid, closeModal, bid, createBid, type, deleteBid, isEditing}: BidModalProps): ReactElement {
  const tomorrow = Datetime.getTomorrowDate()
  const elplanSnap = useSnapshot(elplanBidStore)
  const currency = useCurrency()
  const { t } = useTranslation()
  
  let startHour = ''
  if (bid?.startTime) {
    startHour = bid.startTime
  } else if (elplanSnap.date.start_time) {
    startHour = Datetime.getStartOfDay(elplanSnap.date.start_time)
  } else {
    startHour = tomorrow.startTime
  }

  let endHour = ''
  if (bid?.endTime) {
    endHour = Datetime.ISODatetimeTo59Minutes(bid.endTime as ISODateTime)
  } else if (elplanSnap.date.start_time) {
    endHour = Datetime.ISODatetimeTo59Minutes(Datetime.getEndOfDay(elplanSnap.date.start_time))
  } else {
    endHour = Datetime.ISODatetimeTo59Minutes(tomorrow.endTime)
  }
  const [hoursPeriod, setHoursPeriod] = useState<{ startHour: ISODateTime, endHour: ISODateTime }>(
    {
      startHour: startHour as ISODateTime,
      endHour: endHour as ISODateTime,
    })

  const [price, setPrice] = useState<number>(bid?.price ?? 0)
  const [volume, setVolume] = useState<number>(bid?.volume ?? 0)
  const ref = useRef(null)

  const bidType = type === 'block' ? t('Block bid') : t('Spot bid')
  const modalTitle = isEditing ? `${t('Edit')} ${bid?.title ?? bidType}` : `${t('Create')} ${bidType}`

  const hourLimit = type === 'block' ? elplanSnap.blockBidInfo.minimum_hours_for_bid : elplanSnap.spotBidWithoutPriceInfo.minimum_hours_for_bid
  const hourPeriodIsLessThanMinimum = !hourLimit || Datetime.getHoursBetween(hoursPeriod.startHour, hoursPeriod.endHour).length <= hourLimit - 1
  const hourLimitValidationErrorText = hourLimit === 1 ? hourLimit + ` ` + t('hour') + `.` : hourLimit + ` ` + t('hours') + `.`

  return (
    <div className={styles.BidModal} >
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={modalTitle} handleClose={() => closeModal()} />
        <DialogContent><div className={styles.BidModal_TimePickers}>
          <FormRow icon="fal fa-alarm-clock">
            <Grid item xs={12} md={12} lg={6}>
              <TimePicker
                error={hourPeriodIsLessThanMinimum}
                value={hoursPeriod.startHour}
                label={t('From')}
                onChange={(newStartHour) => {
                  const newPeriod = { startHour: newStartHour, endHour: hoursPeriod.endHour }
                  setHoursPeriod(newPeriod)
                }}
                dateTransform={Datetime.ISODatetimeTo00Minutes}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TimePicker
                error={hourPeriodIsLessThanMinimum}
                label={t('To')}
                value={hoursPeriod.endHour}
                onChange={(newEndHour) => {
                  const newPeriod = { startHour: hoursPeriod.startHour, endHour: newEndHour }
                  setHoursPeriod(newPeriod)
                }}
                dateTransform={Datetime.ISODatetimeTo59Minutes}
              />
            </Grid>
            {hourPeriodIsLessThanMinimum && (
              <Grid item xs={12}>
                <Typography color="error">
                  {`${t('Minimum hour length of bid has to be at least')} ${hourLimitValidationErrorText}`}
                </Typography>
              </Grid>
            )}
          </FormRow>
        </div>
        <div className={styles.BidModal_TextFields}>
          <FormRow icon="fal fa-cogs">
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id={'volume'}
                label={t('Volume') + ' (MW)'}
                aria-label={'volume'}
                disabled={false}
                variant="outlined"
                fullWidth
                defaultValue={volume}
                error={volume === null}
                onInput={e => {
                  const target = e.target as HTMLInputElement
                  if (target.value) {
                    setVolume(parseFloat(target.value))
                  }
                }}
              />
            </Grid>
          </FormRow>
        </div>
        {type === 'block' && <div className={styles.BidModal_TextFields}>
          <FormRow icon="fal fa-bolt">
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id={'electricityPrice'}
                label={t('Avg price') + ` (${currency}/MWh)`}
                aria-label={'electricityPrice'}
                disabled={false}
                variant="outlined"
                fullWidth
                defaultValue={price}
                error={price === null}
                onInput={e => {
                  const target = e.target as HTMLInputElement
                  if (target.value) {
                    setPrice(parseFloat(target.value))
                  }
                }}
              />
            </Grid>
          </FormRow>
        </div>}
        <ModalButtons
          onSubmit={() => {
            if (type === 'block') {
              const obj = {
                type: bid?.type ?? 'blockbid',
                fake: bid?.fake,
                block_order: bid?.block_order,
                displayStartTime: hoursPeriod.startHour,
                displayEndTime: hoursPeriod.endHour,
                startTime: Datetime.toISOString(hoursPeriod.startHour),
                endTime: Datetime.ISODatetimeTo00Minutes(hoursPeriod.endHour),
                volume: volume,
                price: price,
                editable: true,
                clear: true,
              }
              if (bid) {
                editBid({...obj, id: bid.id, title: bid.title})
              } else if (createBid) {
                createBid(obj)
              }
            }

            if (type === 'spot') {
              const obj = {
                type: bid?.type ?? 'spotbid_without_price',
                displayStartTime: hoursPeriod.startHour,
                displayEndTime: hoursPeriod.endHour,
                startTime: Datetime.toISOString(hoursPeriod.startHour),
                endTime: Datetime.ISODatetimeTo00Minutes(hoursPeriod.endHour),
                volume: volume,
                editable: true,
                clear: true,
              }
              if (bid) {
                editBid({...obj, id: bid.id, title: bid.title})
              } else if (createBid) {
                createBid(obj)
              }
            }
            closeModal()
          }}
          onCancel={() => closeModal()}
          onDelete={(isEditing && deleteBid) ? () => {
            closeModal()
          } : void 0}
          disableSubmit={hourPeriodIsLessThanMinimum}
          infoText={elplanSnap.elplan.has_plan ? t('There is already an electrity plan created for this period. Your changes will not affect the current electricity plan. Would you still like to make changes?') : undefined}
        />
        </DialogContent>
      </Dialog>
    </div>
  )
}
