import React, { ReactElement } from 'react'

import filterStore from 'store/filter/filter'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerAppendPropsWithDatasets, registerDefaultProps, registerUiConfigComponent } from 'ui/uiConfig/factory'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { applyAggregateFromUiConfig, getReturnIdsFromDatasetInstruction } from 'helpers/dataset.helper/dataset.helper'
import { getRoute } from 'helpers/route.helper/route.helper'


import styles from './GroupingTable.module.less'
type GroupingTableProps = {
  base_items: {
    all: boolean
  }
  uid: number
  items: UiConfigPropsItem[]
  datasets: Dataset[]
  title: string
  grouping_tag?: string
  columns: { title: string; map_id: string; unit?: string, include_item_unit?: boolean }[]
}

const defaultProps: Omit<GroupingTableProps, 'uid' | `datasets` | `children`> = {
  base_items: {
    all: false,
  },
  grouping_tag: '',
  items: [
    {
      title: ``,
      data_id: ``,
      unit: ``,
      decimals: 1,
      data_type: ``,
      grouping_type: 'fuel',
    },
  ],
  columns: [
    { title: 'Fuel', map_id: 'fuel', unit: '' },
    { title: 'Fuel Consumption', map_id: 'fuel_consumption', unit: '' },
    { title: 'Stored Unit', map_id: 'stored_unit', unit: '' },
    { title: 'Produced Energy', map_id: 'produced_energy', unit: '' },
  ],
  title: ``,
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: GroupingTableProps
): GroupingTableProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId.split(`.`).pop() || '',
    data_id: returnId,
    unit: ``,
    order: 0,
    decimals: 1,
    grouping_type: 'fuel_consumption',
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent('grouping_table', GroupingTable)
registerAppendPropsWithDatasets('grouping_table', appendPropsWithDataset)
registerDefaultProps('grouping_table', defaultProps)

export default function GroupingTable({
  uid,
  items,
  datasets,
  title,
  columns,
  grouping_tag,
}: GroupingTableProps): ReactElement {
  datasets = datasets || []
  const filterSnap = useSnapshot(filterStore)
  const route = getRoute()
  const groupingTag = grouping_tag ?? filterSnap.groupingPerRoute[route]
  const { t } = useTranslation()

  const data: { [key: string]: { [key: string]: number | string } } = {}

  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, {
    invalidIndexCheckIds: items.map(({ data_id }) => data_id),
  })

  items.forEach((item) => {
    const groupingType = item.grouping_type as string
    const titleWithoutType = groupingType ? item.title.split('&&')[0] : item.title
    if (!data[titleWithoutType]) {
      data[titleWithoutType] = {}
    }

    if (groupingType) {
      data[titleWithoutType][groupingType] =
        aggregatedDatasets.find((dataset) => dataset.return_id === item.data_id)?.values[0] ?? 0
    }

    if (item.fuel_type && columns.find((column) => column.map_id === 'fuel')) {
      //TODO: Make more dynamic (only add fuel type if we want to have fuel as a column in table)
      data[titleWithoutType]['fuel'] = (item.fuel_type as string) ?? ''
    } else if (item.h_energy_bar && columns.find((column) => column.map_id === 'produced_energy')) {
      //TODO: Make more dynamic (only add produced energy type if we want to have produced energy as a column in table)
      data[titleWithoutType]['produced_energy'] =
        aggregatedDatasets.find((dataset) => dataset.return_id === item.data_id)?.values[0] ?? 0
    }

    data[titleWithoutType]['unit'] = item.unit ?? ''
  })
  return (
    <div className={styles.GroupingTable}>
      {title && <TitleWithIcon title={title} size={`small`} padding={false} />}
      <div className={styles.GroupingTable__scrollable}>
        <table>
          <thead>
            <tr>
              <th>{t(groupingTag.charAt(0).toUpperCase() + groupingTag.slice(1)) ?? groupingTag}</th>
              {columns.map((column, columnIndex) => {
                return <th key={columnIndex}>{`${t(column.title) ?? column.title} ${column.unit ?? ''}`}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(data)
              .sort((a, b) => a.localeCompare(b))
              .map((tag, tagIndex) => {
                return (
                  <tr key={`tagKey_${tagIndex}`}>
                    <td>
                      <b>{tag}</b>
                    </td>
                    {columns.map((column, columnIndex) => {
                      const indexString: string = column.map_id
                      let value = '-'

                      if (typeof data[tag][indexString] === 'number') {
                        const valueAsNumber: number = data[tag][indexString] as number
                        if (column.include_item_unit) {
                          value = valueAsNumber.toFixed(1).toString() + ' ' + data[tag]['unit']
                        } else {
                          value = valueAsNumber.toFixed(1).toString()
                        }
                      } else if (data[tag][indexString]) {
                        const valueAsString: string = data[tag][indexString] as string
                        const camelCase = valueAsString.charAt(0).toUpperCase() + valueAsString.slice(1)
                        value = t(camelCase) ?? camelCase
                      }

                      return <td key={`columnKey_${columnIndex}`}>{value ?? data[tag][indexString]}</td>
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
