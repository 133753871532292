import React, { ReactElement } from 'react'

import { generallyApplyAliases } from 'helpers/uiConfig.helper/uiConfig.helper'

import styles from './UiConfigColorInput.module.less'

type UiConfigColorInputProps = {
  color?: string
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export default function UiConfigColorInput({ color, onBlur, disabled }: UiConfigColorInputProps): ReactElement {
  return (
    <div className={styles.UiConfigColorInput}>
      <div className={styles.UiConfigColorInput_Dot} style={{ backgroundColor: generallyApplyAliases(color) }} />
      <input className={styles.UiConfigColorInput_Input} disabled={disabled} aria-label="color" defaultValue={color} onBlur={onBlur} />
    </div>
  )
}
