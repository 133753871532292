import React, { ReactElement, useState } from 'react'

import { OptProject, OptProjectCreateData, useOptProjectsMutation } from 'api/optProjects/optProjects.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'
import ReactHookFormSelect from 'ui/components/ReactHookFormSelect/ReactHookFormSelect'
import ReactHookFormSwitch from 'ui/components/ReactHookFormSwitch/ReactHookFormSwitch'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'
import { DatePicker } from 'ui/molecules/pickers/DatePicker/DatePicker'
import Datetime from 'utils/datetime/datetime'

import {
  DialogTitle,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { history } from 'helpers/history'

import styles from './CreateSandboxPopover.module.less'

type ProjectValues = {
  id?: number
  display_name: string
  project_type: string
  start_time: string
  include_deviations: boolean
  end_time: string
  system?: number
}

type CreateSandboxPopoverProps = {
  open: boolean
  closePopover(): void
  defaultValues?: ProjectValues
}

export default function CreateSandboxPopover({
  open,
  closePopover,
  defaultValues = {
    id: undefined,
    display_name: ``,
    project_type: `Single`,
    start_time: moment().startOf(`hour`).format(`YYYY-MM-DD HH:00`),
    end_time: moment().startOf(`hour`).add(1, `week`).format(`YYYY-MM-DD HH:00`),
    include_deviations: false,
  },
}: CreateSandboxPopoverProps): ReactElement {
  const { t } = useTranslation()

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { systemId } = useAuth()

  const methods = useForm({
    mode: `onChange`,
    defaultValues,
  })
  const { mutateAsync } = useOptProjectsMutation()
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = methods

  async function onSubmit(data: ProjectValues): Promise<void> {
    if (systemId !== undefined) {
      const optProjectCreateData: OptProjectCreateData = {
        ...data,
        system: systemId,
        end_time: moment(data.start_time).add(1, `week`).subtract(1, 'hour').format(`YYYY-MM-DD HH:00`),
      }
      await mutateAsync({ data: optProjectCreateData }).then((response) => {
        closePopover()
        history.push(`/sandbox/` + (response as OptProject).id + `/`)
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          setConfirmModalOpen(true)
        }}
        maxWidth={`md`}
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item className={styles.CreateSandboxPopover_Title}>
                <Typography variant="h2">
                  {defaultValues.id ? t(`Create copy of project`) : t(`Create new project`)}
                </Typography>
              </Grid>
              <Grid item>
                <PopoverCloseButton
                  handleClose={() => {
                    setConfirmModalOpen(true)
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={styles.CreateSandboxPopover_Content}>
            <Grid container direction="column" spacing={2}>
              <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <ReactHookFormTextField
                    name="display_name"
                    className={styles.CreateSandboxPopover_TextField}
                    variant="outlined"
                    label={t(`Choose a project name`)}
                    aria-label={t(`Choose a project name`)}
                    type="text"
                    disabled={isSubmitting}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ReactHookFormSelect
                    name="project_type"
                    margin="normal"
                    variant="outlined"
                    className={styles.CreateSandboxPopover_TextField}
                    label={t(`Type of sandbox project`)}
                    rules={{ required: true }}
                    disabled={true} //since we only have one type of sandbox available, it is unnecessary to have this enabled
                  >
                    <MenuItem key={1} value={`Single`}>
                      {t(`Single optimization`)}
                    </MenuItem>
                  </ReactHookFormSelect>
                </Grid>
              </Grid>
              <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="start_time"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        value={field.value}
                        isRequired
                        minValue={moment().year(2020).startOf(`year`)}
                        maxValue={moment().endOf(`day`)}
                        label={t(`Choose start time for project`)}
                        onChange={(date) => {
                          if (!date) {
                            return
                          }
                          
                          field.onChange(Datetime.ISODatetimeTo00Minutes(date))
                        }}
                        isDisabled={defaultValues.id || isSubmitting ? true : false}
                      />
                    )}
                  />
                </Grid>
                <Grid item container direction="row" alignItems="center" justifyContent="center" xs={6}>
                  <Typography display="inline">{t('Include deviation settings')}</Typography>
                  <ReactHookFormSwitch name="include_deviations" disabled={isSubmitting} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  className={styles.CreateSandboxPopover_CancelButton}
                  onClick={() => {
                    setConfirmModalOpen(true)
                  }}
                >
                  {t(`Cancel`)}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit" disabled={!isValid || isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <CircularProgress size={16} color="inherit" style={{ marginRight: 10 }} />
                      {defaultValues.id ? t(`Copying`) : t(`Creating`)}
                    </>
                  ) : (
                    <>
                      <Icon className={classNames(styles.CreateSandboxPopover_CreateIcon, `fal fa-folder-plus`)} />
                      {defaultValues.id ? t(`Copy`) : t(`Create`)}
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog
        open={confirmModalOpen}
        onConfirm={() => {
          closePopover()
          setConfirmModalOpen(false)
        }}
        onClose={() => setConfirmModalOpen(false)}
        textObject={{
          title: t(`Are you sure you want to cancel?`),
          text: t(`If you cancel, your progress will be lost.`),
        }}
      />
    </FormProvider>
  )
}
