import { getDatasets } from 'api/dataset/dataset.api'
import { BidTypeName, Bid } from 'api/elplan/elplan.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'
import { snapshot } from 'valtio'

import { convertNumberDecimalSign } from 'helpers/global.helper/global.helper'

export type FormattedBid = {
  fake?: boolean
  type: BidTypeName
  date?: ISODateTime | string
  startTime: ISODateTime | string
  endTime: ISODateTime | string
  displayStartTime?: string
  displayEndTime?: string
  volume?: number
  price?: number
  block_order?: number
  editable?: boolean
  clear?: boolean
}

export type FormattedBidWithIdAndTitle = {
  id: number
  title: string
} & FormattedBid

export type BidValues = { 
  time: ISODateTime | number
  volume?: string | number
}[]

export function formatBid(bid: Bid): FormattedBidWithIdAndTitle {
  return {
    id: bid.id,
    fake: false,
    title: bid.type === 'blockbid' ?
      `${i18n.t('Block bid')} ${bid.props.block_order}` :
      `${i18n.t('Spot bid')}`,
    type: bid.type,
    date: Datetime.toLocalTime(bid.start_time, 'onlyDate'),
    startTime: bid.start_time,
    endTime: bid.end_time,
    displayStartTime: Datetime.toLocalTime(bid.start_time, 'hour'),
    displayEndTime: Datetime.toLocalTime(Datetime.ISODatetimeTo59Minutes(bid.end_time), 'hour'),
    volume: bid.volume,
    price: bid.props.price,
    block_order: bid.type === 'blockbid' ? bid.props.block_order : undefined,
    editable: !bid.is_locked,
    clear: true,
  }
}

export function getElplanAsBids(dataset: Dataset, onlyPositiveBids: boolean): BidValues {
  let volume = ''
  const bids = dataset.times.map((time, index) => {
    if (dataset.values[index] === null || dataset.values[index] === undefined) {
      volume = '-'
    } else if (dataset.values[index] < 0 && onlyPositiveBids) {
      volume = '0'
    } else {
      const convertedDecimalSignNumber = convertNumberDecimalSign(dataset.values[index] ?? 0)
      volume = typeof convertedDecimalSignNumber !== 'undefined' 
        ? convertedDecimalSignNumber.toFixed(1) 
        : ''
    }
    return { time: time, volume: volume }
  })

  return bids
}

export function getDefaultBlockBids(
  topicalBlockBids: FormattedBidWithIdAndTitle[] | undefined,
  amountOfBids: number,
  topicalPeriod: {startTime: ISODateTime, endTime: ISODateTime},
  previousBlockBids?: FormattedBidWithIdAndTitle[]
): FormattedBidWithIdAndTitle[] {
  const defaultBlockBids: FormattedBidWithIdAndTitle[] = []

  for (let i = 0; i < amountOfBids; i++) {
    const topicalBlockBid = topicalBlockBids?.find((topicalBid) => topicalBid.block_order === i + 1)

    if (topicalBlockBid) {
      defaultBlockBids.push(topicalBlockBid)
    } else {

      const previousBlockBid = previousBlockBids?.find((previousBlockBid) => previousBlockBid.block_order === i + 1)

      defaultBlockBids.push({
        id: i + 1,
        block_order: i + 1,
        type: 'blockbid',
        fake: true,
        title: `${i18n.t('Block bid')} ${i + 1}`,
        date: Datetime.toLocalTime(topicalPeriod?.startTime, 'onlyDate'),
        startTime: topicalPeriod?.startTime,
        endTime: topicalPeriod?.endTime,
        displayStartTime: Datetime.toLocalTime(topicalPeriod?.startTime, 'hour'),
        displayEndTime: Datetime.toLocalTime(Datetime.ISODatetimeTo59Minutes(topicalPeriod?.endTime), 'hour'),
        volume: previousBlockBid?.volume ?? 0,
        price: previousBlockBid?.price ?? 0,
        editable: true,
        clear: true,
      })
    }
  }

  return defaultBlockBids
}

export async function getSpotBidsFromOptElplan(startTime: ISODateTime, endTime: ISODateTime, onlyPositiveBids: boolean): Promise<BidValues> {
  const EL_PLAN_SOURCE = 'net_exports.el.el_balance'
  const uiConfigSnap = snapshot(uiConfigStore)
  const uiConfig = uiConfigSnap.getAnchorUiConfigByType('base_config')

  if (!uiConfig) {
    throw new Error(`No UiConfig for "base_config" found to retreive electricity plan.`)
  }
  
  if (!uiConfig.id) {
    throw new Error(`No id found in UiConfig for "base_config" to retreive electricity plan.`)
  }

  const datasets = await getDatasets(uiConfig.id, uiConfig.version, uiConfig?.dataset_instructions, startTime, endTime, {
    onlyReturnIds: [EL_PLAN_SOURCE],
    overrideAlias: {
      opt_job_type_id: 2,
    },
  })
  const optPlanDataset = datasets.find(({ return_id }) => return_id === EL_PLAN_SOURCE)

  if (!optPlanDataset) {
    throw new Error(`No Dataset "${EL_PLAN_SOURCE}" found in UiConfig for "base_config"`)
  }

  const bids = getElplanAsBids(optPlanDataset, onlyPositiveBids)
  return bids
}

export function getDatasetInstructionsForSumVolumeCalc(amountOfBlockBids: number): DatasetInstruction {
  const volumeVariablesBids: string[] = []

  for (let i = 0; i < amountOfBlockBids; i++){
    volumeVariablesBids.push(`blockbid.${i+1}.volume`)
  }
  
  return {
    type: 'calc',
    filter: {
      end_time: '$end_time',
      aggregate: '',
      start_time: '$start_time',
      zero_is_nan: true,
    },
    contract: {
      operator: '+',
      variables: [
        ...volumeVariablesBids,
        'spotbid_without_price.volume',
      ],
      element_wise: true,
      accumulate: '',
    },
    return_id: 'sum_volume',
  }
}

export function getItemsForExcel(amountOfBlockBids: number): {data_id: string, title: string}[] {
  const volumeBids: { data_id: string, title: string }[] = []
  const priceBids: { data_id: string, title: string }[] = []

  for (let i = 0; i < amountOfBlockBids; i++){
    volumeBids.push({data_id: `blockbid.${i+1}.volume`, title: `${i18n.t('Block bid')} ${i+1}`})
    priceBids.push({data_id: `blockbid.${i+1}.price`, title: `${i18n.t('Block bid')} ${i+1} ${i18n.t('')}`})
  }
  return ([
    { data_id: 'spotbid_without_price.volume', title: 'Spotbud' },
    ...volumeBids,
    ...priceBids,
  ])
}
