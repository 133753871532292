import React, { ReactElement } from 'react'

import { Grid } from '@mui/material'

import styles from './AccountSetting.module.less'
import ChangePasswordFields from './components/ChangePasswordBox/ChangePasswordBox'
import UserDetails from './components/UserDetails/UserDetails'

export default function AccountSettings(): ReactElement {
  return (
    <Grid container direction="row" justifyContent="space-between" className={styles.AccountSetting_Container}>
      <Grid item xs={12} lg={8} className={styles.AccountSetting_InnerContainer}>
        <UserDetails />
      </Grid>

      <Grid item xs={12} lg={4}>
        <ChangePasswordFields />
      </Grid>
    </Grid>
  )
}
