import React, { ReactElement, ReactNode } from 'react'

import { Card, Tabs } from 'ui/atoms'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps, getComponentTitle } from 'ui/uiConfig/factory'

import { useTranslation } from 'react-i18next'

import { getRoute } from 'helpers/route.helper/route.helper'

export type CardWidgetTabItem = {
  id: UiConfigId
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

export type CardWidgetTab = {
  title: string
  children: UiConfigId | UiConfigId[] | CardWidgetTabItem[]
}

type CardWidgetProps = {
  id?: UiConfigId
  uid?: UiConfigUid
  key?: string
  component: string
  children: ReactNode[]
  use_default?: boolean
  tabs?: CardWidgetTab[]
  card?: boolean
  overrideAlias?: UiConfigAliases
}

const defaultProps: Pick<CardWidgetProps, 'use_default' | 'card' | 'tabs'> = {
  use_default: false,
  card: true,
  tabs: [{
    title: 'Tab 1',
    children: [{
      id: 0,
      override_alias: {},
      override_props: {},
    }],
  }],
}
registerUiConfigComponent('card_widget', CardWidget)
registerDefaultProps('card_widget', defaultProps)

function generateChildren(
  children: UiConfigId | UiConfigId[] | CardWidgetTabItem[],
  props: Partial<CardWidgetProps>
): ReactElement | ReactElement[] {
  delete props.id
  delete props.uid
  delete props.key

  if (typeof children === 'number') {
    return <UiConfigComponent
      {...props}
      id={children}
    />
  }

  return children.map((child, index) => {
    if (typeof child === 'number') {
      return <UiConfigComponent {...props} key={index} id={child} />
    }

    const overrideAlias = {
      ...(props.overrideAlias || {}),
      ...(child.override_alias || {}),
    }

    return <UiConfigComponent {...props} key={index} id={child.id} overrideAlias={overrideAlias} overrideProps={child.override_props} />
  })
}

export default function CardWidget({ component, children, tabs = [], card = true, ...props }: CardWidgetProps): ReactElement {
  const { t } = useTranslation()
  const route = getRoute()

  const title = t(`${getComponentTitle(component, true)}`)
  const tabItems = tabs.map(tab => ({
    title: tab.title,
    children: generateChildren(tab.children, props),
  }))
  const body = (<>
    {title && <TitleWithIcon title={title} />}
    {tabItems.length ? <Tabs
      uniqueStorageKey={`Cardwidget_${route}_${title}_Tabs`}
      items={tabItems} /> : <>{ children }</>}
  </>)

  if (!card) {
    return <>{body}</>
  }

  return (
    <Card margin>{body}</Card>
  )
}
