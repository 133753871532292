import { ConfigurableProperty } from 'api/digitalTwin/digitalTwin.api'

export type MultilevelDropdownOptions = {
  [key: string]: string[]
}

// Parses tags from properties based on current priorities.
export function parseTagsFromProperties(
  properties: ConfigurableProperty[] | UiConfigPropsItem[],
  currentPriorities: SystemSettingPriorityLevel[]
): MultilevelDropdownOptions {
  const tags: Set<string> = new Set<string>()

  properties.filter((property) =>
    !Array.isArray(property.available_priorities) || property.available_priorities?.some((priority) =>
      currentPriorities.some((currentPriority) =>
        currentPriority.priority_level === priority
      )
    )
  )
    .forEach((property) => (property.tags ?? []).forEach((tag) => tags.add(tag)))

  return parseTags(Array.from(tags))
}

export function parseTags(tags: string[]): MultilevelDropdownOptions {
  if (!Array.isArray(tags)) {
    // Can happen if someone writes the digital twin json on an incorrect format.
    return {}
  }

  let out = {}
  tags.forEach((tag) => {
    out = parseTag(tag, out)
  })

  return out
}

export function parseTag(tag: string, current: MultilevelDropdownOptions = {}): MultilevelDropdownOptions {
  tag = tag.replace(/^\/+/g, '') // Remove leading slashes

  if (tag === '') {
    return current
  }

  const [topLevelTag, subLevelTag] = tag.split(/\/(.*)/)

  const existingTagsForTopLevel = topLevelTag in current ? current[topLevelTag] : []

  if (subLevelTag === undefined || subLevelTag === '') {
    return { ...current, [topLevelTag]: existingTagsForTopLevel }
  } else {
    return { ...current, [topLevelTag]: [subLevelTag, ...existingTagsForTopLevel] }
  }
}

export function matchesSomeTagFromEveryTopLevel(tagsToCheck: string[], filterTags: string[]): boolean {
  if (tagsToCheck.every((tag) => filterTags.includes(tag))) {
    return true
  }

  return false
}

export function getTopLevelOptions(
  availableFilters: Set<string>,
  options: MultilevelDropdownOptions,
  hideFilters?: string[]
): { value: string; label: string }[] {
  const availableTopLevelTags = new Set(Array.from(availableFilters).map((f) => f.split('/')[0]))

  return Object.keys(options) // Don't show top levels that don't have any sub levels.
    .filter((option) => {
      return !hideFilters?.includes(option) && options[option].length > 0 && availableTopLevelTags.has(option)
    })
    .map((option) => {
      const optionValue = options[option]
      const value = typeof optionValue === 'string' ? optionValue : option
      return {
        value,
        label: option,
      }
    })
}
