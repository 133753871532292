import React, { ReactElement, useMemo } from 'react'

import { DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN } from 'api/dataset/dataset.api'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import UiConfig from 'ui/uiConfig/UiConfig'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import OptimizeHeaderContent from 'views/OptimizeView/components/OptimizeHeaderContent/OptimizeHeaderContent'

import { fuelPlanViewRange } from './store/FuelPlanStore'

export default function FuelPlanView(): ReactElement {
  const { t } = useTranslation()

  const [startTime, endTime] = fuelPlanViewRange('month')

  const optStartTime = useMemo(() => moment().startOf('day'), [])
  const optEndTime = useMemo(() => moment(optStartTime).add(29, 'day').subtract(1, 'hour'), [optStartTime])

  return (
    <View
      header={
        <ModuleHeader infoHeader={t('Fuel plan')} >
          <OptimizeHeaderContent
            type='fuel_plan'
            permission='edit_fuelplan_module'
            enableAutomaticOptimization={false}
            optStartTime={optStartTime}
            optEndTime={optEndTime}
          />
        </ModuleHeader>
      }
    >
      <UiConfig
        type="fuel_plan"
        datasetStartTime={startTime}
        datasetEndTime={endTime}
        datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_FUEL_PLAN}
      />
      <QueueProgressBar />
    </View>
  )
}