import React, { ReactElement } from 'react'

import { Button, Icon, Typography } from '@mui/material'

import styles from './FilterButton.module.less'

type FilterButtonProps = {
  onClick: ((e: React.MouseEvent<HTMLButtonElement>) => void)
  text: string
  style?: React.CSSProperties
}

export default function FilterButton({ onClick, text, style }: FilterButtonProps): ReactElement {
  return (
    <>
      <Button className={styles.FilterButton_Button} onClick={onClick} style={style}>
        <Icon className={`fal fa-filter`} />
        <Typography variant="body2" display="inline">
          {text}
        </Typography>
      </Button>
    </>
  )
}
