import React, { useState, ReactElement, useEffect, useMemo, useRef } from 'react'

import { DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE, DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE } from 'api/dataset/dataset.api'
import { useElplanInfo } from 'api/elplan/elplan.api'
import { getOptjobAsObject, OptJobStatusObject, useLatestOptJobStatus } from 'api/optJobs/optJobs.api'
import globalState from 'store/global/global'
import { resetZoom, resetZoomOnAllRoutes } from 'store/zoom/zoom'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import UiConfig from 'ui/uiConfig/UiConfig'
import { systemFeatureFlagActive } from 'utils/systemUtils'

import { Box, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { Step } from 'react-joyride'
import Snowfall from 'react-snowfall'
import { useSnapshot } from 'valtio'

import { optJobIsDone } from 'helpers/optimize.helper/optimize.helper'

import styles from './OptimizeView.module.less'
import DeviationSettingsMenu from './components/DeviationSettingsMenu/DeviationSettingsMenu'
import { OptimizeHeaderContentClass } from './components/OptimizeHeaderContent/OptimizeHeaderContent'
import OptimizeModuleHeader from './components/OptimizeModuleHeader/OptimizeModuleHeader'
import optimizeViewStore, { resetOptimizeViewStore } from './store/optimizeViewStore'
import { useShouldShowCustomDatepicker } from './utils/useShouldShowCustomDatepicker'

const widgetConstants = {
  OPTIMIZE_GRID: `optimize`,
  OPTIMIZE_WIDGET_1: `item-1`,
  OPTIMIZE_WIDGET_2: `item-2`,
  OPTIMIZE_WIDGET_3: `item-3`,
}

const resultInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_WIDGET_1,
    content: `The current production plan is displayed here, with an hourly resolution. The production values are given as an hourly mean, for example a MW value with timestamp 08:00 signifies the mean production in the interval 08:00-08:59. If the “show outcome” box is checked, the graph will display one week of historical measured production, followed by the forecasted optimized production plan for one week ahead. Unchecking the “show outcome” box will hide the historical measured production to only show the forecasted production plan. By hovering the chart, hourly values will be displayed for each production unit. It is also possible to zoom in the chart using click-and-drag. If you would like to print or save the chart as an Excel file, PDF or image, it is possible to use the export button at the right hand corner where you also find the chart settings.`,
    title: `Optimization result`,
  },
]
const forecastInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_WIDGET_2,
    content: `The forecast chart gives you the possibility to pick which forcast you would like to inspect during the current production plan period. The forecasts are parts of the production plan calculations.`,
    title: `Optimization forecasts`,
  },
]
const tableInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_WIDGET_3,
    content: `This table shows the data from the chart 'Optimization result' - If no data is displayed here, you need to start a new optimization`,
    title: `Optimization result - table`,
  },
]

const steps: Array<Step> = [
  {
    target: OptimizeHeaderContentClass,
    content: `The prerequisites allow you to adjust start and end levels of the plants' accumulators (if applicable). Here you will also see any active deviation settings that currently affects your production plan if you choose to start a new optimization. You also have the choice to activate automatic optimizations, which puts optimizations to execute automatically once every hour.`,
    title: `Production plan prerequisites`,
  },
  ...resultInfo,
  ...forecastInfo,
  ...tableInfo,
]

function getPreviousLatestFinishedRegularOptJob(comparePlansFeatureFlag: boolean, latestFinishedRegularOptJobsData: OptJobStatusObject | null | undefined ): ISODateTime | string {
  if (comparePlansFeatureFlag && latestFinishedRegularOptJobsData) {
    if (latestFinishedRegularOptJobsData) { //second to first opt job always
      return latestFinishedRegularOptJobsData.created_at
    }
  }

  return ''
}

const MENU_WIDTH_IN_PERCENT = 15
const CONTENT_WIDTH_IN_PERCENT = 100 - MENU_WIDTH_IN_PERCENT
const CONTENT_WIDTH_MENU_OPEN_IN_PERCENT = CONTENT_WIDTH_IN_PERCENT - 2

export default function OptimizeView(): ReactElement {
  const optimizeViewSnap = useSnapshot(optimizeViewStore)
  const { activeSystem } = useAuth()
  const globalSnap = useSnapshot(globalState)


  if (activeSystem?.id !== optimizeViewSnap.activeSystemId) {
    // Make sure that the store has valid values for the system
    resetOptimizeViewStore(activeSystem)
    resetZoom()
  }

  const [runTour, setRunTour] = useState<null | number>(null)
  const { data: latestOptJob } = useLatestOptJobStatus({ type: `regular` })
  const firstLatestOptJob = getOptjobAsObject(latestOptJob)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuToggle = (isOpen: boolean): void => {
    setIsMenuOpen(isOpen)
  }

  const shouldShowSnow = moment().month() + 1 === 12 && !optJobIsDone(firstLatestOptJob)
  const isRegular = activeSystem?.tier === `regular`

  const shouldShowCustomDatepicker = useShouldShowCustomDatepicker()
  const fetchElplanValuesForOptimize = systemFeatureFlagActive(activeSystem, 'electricity_plan_in_optimize_view')

  const showDeviationSettingsMenu = activeSystem?.primary_digital_twin?.uid ?? false
  const isScreenSmall = useMediaQuery('(max-width:1024px)')

  // Get the previous plan by getting latest finished optimization job and subtracting one minute
  const { data: latestFinishedRegularOptJobData} = useLatestOptJobStatus({ type: 'regular', enabled: shouldShowCustomDatepicker, limit: 2 }) //fetch the two latest opt jobs (regardless of status)
  const secondToFirstFinishedOptJob = getOptjobAsObject(latestFinishedRegularOptJobData, 1)
  const previousLatestFinishedRegularOptJobDateTime = getPreviousLatestFinishedRegularOptJob(shouldShowCustomDatepicker, secondToFirstFinishedOptJob)

  const [ datasetStartTime, datasetEndTime, productionPlanStartTime, measEndTime ] = useMemo(() => {
    const [datasetStartTime, datasetEndTime] = optimizeViewSnap.optimizedViewRange
    return [datasetStartTime, datasetEndTime, optimizeViewSnap.productionPlanStartTime, optimizeViewSnap.measEndTime]
  }, [optimizeViewSnap.optimizedViewRange, optimizeViewSnap.productionPlanStartTime, optimizeViewSnap.measEndTime])
  
  useElplanInfo(activeSystem?.id, fetchElplanValuesForOptimize) //to create data for compare bidplan

  const baseAlias = {
    'previous_regular_opt_job_time': previousLatestFinishedRegularOptJobDateTime,
  }
  const overrides = shouldShowCustomDatepicker ? {
    overrideAlias: {
      ...baseAlias,
      'start_time': datasetStartTime,
      'end_time': datasetEndTime,
      offset_start_time: 0,
      offset_end_time: 0,
      'plan_start_time': productionPlanStartTime,
      'plan_end_time': datasetEndTime,
      'meas_start_time': datasetStartTime,
      'meas_end_time': measEndTime,
      'plan_offset_end_time': 0,
      'meas_offset_start_time': 0,
    },
  } : {
    overrideAlias: baseAlias,
  }

  useEffect(() => {
    if (!isRegular) {
      // Light-systems should always have default zoom
      resetZoomOnAllRoutes()
    }
  }, [isRegular])

  const headerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    if (headerRef.current) {
      //+60 to account for the padding of the header, otherwise the settings list will be cut off
      setHeaderHeight(headerRef.current.offsetHeight + 60)
    }   
  }, [])



  return (
    <View
      header={
        <div ref={headerRef}>
          <OptimizeModuleHeader onInfoButtonClick={() => setRunTour(0)}/>
        </div>
      }
      stickyHeader
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? resultInfo : runTour === 2 ? forecastInfo : runTour === 3 ? tableInfo : steps}
      />
      {shouldShowSnow && <Snowfall style={{ zIndex: 1000 }} color="#bed0ed" snowflakeCount={100} />}
      <div className={styles.OptimizeView__Content}>
        <Box sx={{ width: (!showDeviationSettingsMenu || !isMenuOpen || isScreenSmall)  ? '100%' : globalSnap.navigationIsOpen ? `${CONTENT_WIDTH_MENU_OPEN_IN_PERCENT}%` : `${CONTENT_WIDTH_IN_PERCENT}%` }}>
          <UiConfig
            type="real_time_optimization_bars"
            datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_REAL_TIME_OPTIMIZE}
          />
          <UiConfig
            type="optimize_view"
            datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE}
            {...overrides}
            fallback={
              <>
                <UiConfig
                  type="optimize_production_plan"
                  tourId={widgetConstants.OPTIMIZE_WIDGET_1}
                  datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE}
                  {...overrides}
                />

                {isRegular ? (
                  <>
                    <UiConfig
                      type="production_plan_forecast"
                      tourId={widgetConstants.OPTIMIZE_WIDGET_2}
                      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE}
                      {...overrides}
                    />
                    <UiConfig
                      type="production_plan_table"
                      tourId={widgetConstants.OPTIMIZE_WIDGET_3}
                      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_ON_OPTIMIZE}
                      {...overrides}
                    />
                  </>
                ) : null}
              </>
            }
          />
        </Box>
        {showDeviationSettingsMenu && 
        <DeviationSettingsMenu menuWidth={MENU_WIDTH_IN_PERCENT} headerHeight={headerHeight} onToggle={handleMenuToggle} />
        }
      </div>
      <QueueProgressBar />
    </View>
  )
}
