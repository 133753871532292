import { apiClient } from 'api/apiClient/apiClient'
import { Setting } from 'api/settings/settings.api'
import { UNITS_QUERY_KEY } from 'api/units/units.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query'

export type ValueMapping = {
  value: number
  text_sv: string
  text_en: string
}

export type ValidRange = {
  lower: number
  upper?: number
  bounds: string
}

export type Classification = `deviation` | `base` | `price`

type ObjectPropertyParent = {
  id: number
  type: `unit` | `node`
} & DisplayNameObject

export type ObjectProperty = DisplayNameObject & {
  id: number
  parent: ObjectPropertyParent | null
  valid_range: ValidRange
  default_value: number
  classifications: Classification[]
  category: string | null
  active_settings: Setting[] | null
  current_active_value: number | null
  current_base_value: number | null
  latest_updated: string
  latest_updated_base: string
  latest_update_type: `changed`
  measurement_unit: string | null
  belongs_to_opt_model: number
  value_mappings: ValueMapping[]
}

type ObjectPropertyQueryParams = {
  optModel?: number
  start_time?: string
  end_time?: string
  category?: string
  classification?: Classification
  queryConfig?: UseQueryOptions<ObjectProperty[], Error>
  name?: 'endlevel_fictive_income' | 'endcost_active'
}

export const OBJECT_PROPERTIES_QUERY_KEY = `objectProperties`

export function useObjectProperties({
  optModel,
  classification,
  queryConfig,
  ...queryParams
}: ObjectPropertyQueryParams = {}): UseQueryResult<ObjectProperty[]> {
  const { activeSystem } = useAuth()
  const optModelId = optModel ?? activeSystem?.primary_opt_model?.id

  return useQuery<ObjectProperty[], Error, ObjectProperty[]>(
    [OBJECT_PROPERTIES_QUERY_KEY, { optModel: optModelId }, { classification, ...queryParams }],
    () => {
      return apiClient<ObjectProperty[]>(`object_properties`, {
        params: {
          belongs_to_opt_model: optModelId,
          classifications: classification,
          ...queryParams,
        },
      })
    },
    {
      ...queryConfig,
      enabled: !!optModelId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
    }
  )
}

export function useObjectProperty({ id, ...queryParams }: { id?: number; start_time?: string; end_time?: string }) {
  const queryClient = useQueryClient()
  return useQuery(
    [OBJECT_PROPERTIES_QUERY_KEY, id, { ...queryParams }],
    () =>
      apiClient<ObjectProperty>(`object_properties/${id}`, { params: { ...queryParams } }).then((data) => {
        // Can't do this in onSuccess since it won't be called if the query is not mounted anymore
        queryClient.setQueriesData<ObjectProperty | ObjectProperty[] | undefined>(
          [OBJECT_PROPERTIES_QUERY_KEY],
          (previousData) => {
            return Array.isArray(previousData)
              ? previousData?.map((item) => (item.id === data.id ? data : item))
              : previousData
          }
        )

        return data
      }),
    {
      enabled: !!id,
      initialData: () => {
        return queryClient
          .getQueryData<ObjectProperty[] | undefined>([OBJECT_PROPERTIES_QUERY_KEY])
          ?.find((prop: ObjectProperty) => prop.id === id)
      },
      onSuccess: (data) => {
        if (data && [`min_level`, `max_level`].includes(data.name)) {
          queryClient.invalidateQueries([
            UNITS_QUERY_KEY,
            { optModel: data.belongs_to_opt_model, unit_type: `Storage` },
          ])
        }
      },
    }
  )
}
