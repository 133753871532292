import React, { ReactElement } from 'react'

import FormRow from 'ui/components/FormRow/FormRow'

import { TextField , Autocomplete } from '@mui/material'

import styles from './DigitalTwinSettingsAutocompleteRow.module.less'

type DeviationPropertyChoice = {
  value: number | string
  name: string
  unitId?: string
}

type DeviationSettingsAutocompleteRowProps = {
  label: string
  id: string
  value: DeviationPropertyChoice | null
  defaultValue?: string | number
  onChange: (value?: string) => void
  choices: DeviationPropertyChoice[]
  disabled?: boolean
  icon?: string
  required?: boolean
}

export function DigitalTwinSettingsAutocompleteRow({
  label,
  id,
  onChange,
  choices,
  disabled = false,
  icon = 'fal fa-cog',
  value,
  required,
}: DeviationSettingsAutocompleteRowProps): ReactElement {
  return (
    <FormRow icon={icon}>
      <div className={styles.DigitalTwinSettingsAutocompleteRow_Container}>
        <Autocomplete
          isOptionEqualToValue={(option: DeviationPropertyChoice, values: DeviationPropertyChoice) =>
            option.value === values?.value
          }
          // Setting inputValue without disabled means you cannot type in the textfield,
          // so the autocomplete feature won't work. So we only set value when disabled = true.
          inputValue={disabled && value?.name ? value.name : undefined}
          // We use value=null to clear the component, but Autocomplete doesn't like that.
          // If we switch to using undefined, we get an error saying
          // "A component is changing an uncontrolled Autocomplete to be controlled".
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={value}
          disableClearable={true}
          id={id}
          options={choices}
          getOptionLabel={(option) => option.name || ''}
          onChange={(e, v) => onChange(v?.value ? `${v?.value}` : undefined)}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              margin="normal"
              name={id}
              SelectProps={{
                MenuProps: {
                  className: styles.PropertyMenu_Menu,
                },
              }}
              required={required}
            />
          )}
          fullWidth
        />
      </div>
    </FormRow>
  )
}
