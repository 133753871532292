import React, { ReactElement, useState } from 'react'

import { useAuth } from 'ui/components/AuthContext/AuthContext'
import StartPageFilter from 'ui/components/BulletinBoard/components/StartPageFilter/StartPageFilter'
import { useCardWidget } from 'ui/components/CardWidget/CardWidget'

import { Grid, Typography, Chip, Icon, Button, ClickAwayListener, MenuItem, Popper } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './SystemsPicker.module.less'

type SystemsPickerProps = {
  currentSystems: System[]
  sendList: number[]
  setSendList: (arg0: number[]) => void
  onFilterClick?: () => void
  filterOnUserPosts: boolean
}

export default function SystemsPicker({
  currentSystems,
  sendList,
  setSendList,
  onFilterClick,
  filterOnUserPosts,
}: SystemsPickerProps): ReactElement {
  const { t } = useTranslation()

  const { activeSystem } = useAuth()

  const [open, setOpen] = useState(false)

  const [anchorSystems, setAnchorSystems] = useState<(EventTarget & Element) | null>(null)

  const toggleSystemsBox = (event: React.MouseEvent): void => {
    setAnchorSystems((prev) => (prev ? null : event.currentTarget))
  }

  function handleToggle(): void {
    setOpen(!open)
  }

  function wrapper(e: React.MouseEvent): void {
    toggleSystemsBox(e)
    handleToggle()
  }

  function removeFromSendList(systemToRemove: number): void {
    setAnchorSystems(null)
    const array = [...sendList]
    const index = array.indexOf(systemToRemove)
    if (index !== undefined && index > -1) {
      if (sendList.length < 2) {
        setSendList([])
      } else {
        array.splice(index, 1)
        setSendList(array)
      }
    }
  }

  function addToSendList(systemToAdd: number): void {
    setAnchorSystems(null)
    setSendList([...sendList, systemToAdd])
  }

  function addAllToSendList(): void {
    setAnchorSystems(null)
    const allSystemsList: Array<number> = []
    currentSystems.forEach((system: System) => {
      if (system.id !== activeSystem?.id) {
        allSystemsList.push(system.id)
      }
    })
    setSendList(allSystemsList)
  }

  const systemsNotInSendList = currentSystems.filter((system: System) => {
    return !sendList.includes(system.id) && system.id !== activeSystem?.id
  })

  const { node } = useCardWidget()

  return (
    <Grid container direction="row" className={styles.SystemsPicker_Header} alignItems="center">
      <Typography variant={`h3`} className={styles.SystemsPicker_HeaderText}>
        {t(`Create a new post to :`)}
      </Typography>
      <Chip
        size="medium"
        icon={<Icon className={classNames(`fal fa-industry-alt`, styles.SystemsPicker_IconSendSystem)} />}
        label={activeSystem ? getDisplayName(activeSystem) : null}
        className={styles.SystemsPicker_SystemThreadChip}
      />
      {currentSystems
        ? currentSystems.map((system: System) => {
          if (sendList.includes(system.id)) {
            return (
              <Chip
                size="medium"
                icon={<Icon className={classNames(`fal fa-industry-alt`, styles.SystemsPicker_IconSendSystem)} />}
                label={getDisplayName(system)}
                onDelete={() => removeFromSendList(system.id)}
                className={styles.SystemsPicker_SystemThreadChip}
                key={system.id}
              />
            )
          }
        })
        : null}

      {systemsNotInSendList.length > 0 && (
        <>
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
              setAnchorSystems(null)
            }}
          >
            <Button onClick={(e) => wrapper(e)} className={styles.SystemsPicker_AdditionalButton}>
              <Icon
                fontSize="small"
                className={classNames(styles.SystemsPicker_Expand, `fas fa-caret-down`, {
                  [styles.SystemsPicker_ExpandOpen]: open,
                })}
              />
              &nbsp;
              <Typography className={styles.SystemsPicker_AdditionalButtonText}>
                {t(`Choose additional systems`)}
              </Typography>
            </Button>
          </ClickAwayListener>
          <Popper
            placement="bottom-start"
            open={Boolean(anchorSystems)}
            anchorEl={anchorSystems}
            className={styles.SystemsPicker_SystemMenu}
            container={node?.current}
          >
            <MenuItem
              onClick={() => {
                if (currentSystems) {
                  addAllToSendList()
                  setOpen(false)
                }
              }}
              className={classNames(styles.SystemsPicker_SystemMenuList, styles.SystemsPicker_SystemMenuText)}
            >
              <Icon className={`fal fa-industry-alt`} />
              <Typography display="inline" className={styles.SystemsPicker_SystemMenuText}>
                {t(`All systems`)}
              </Typography>
            </MenuItem>
            {systemsNotInSendList.map((system) => {
              return (
                <MenuItem
                  className={classNames(styles.SystemsPicker_SystemMenuListItem, styles.SystemsPicker_SystemMenuText)}
                  key={system.id}
                  onClick={() => {
                    addToSendList(system.id)
                    setOpen(false)
                  }}
                >
                  <Icon className={`fal fa-industry-alt`} />
                  <Typography display="inline" className={styles.SystemsPicker_SystemMenuText}>
                    {getDisplayName(system)}
                  </Typography>
                </MenuItem>
              )
            })}
          </Popper>
        </>
      )}
      <StartPageFilter filterOnUserPosts={filterOnUserPosts} onFilterClick={onFilterClick} />
    </Grid>
  )
}
