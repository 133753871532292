import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import { useTranslation } from 'react-i18next'

import styles from './TitleWithIcon.module.less'

type TitleWithIconProps = {
  title: string
  icon?: string
  size?: `large` | `medium` | `small`
  padding?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  marginLeft?: boolean
}

export default function TitleWithIcon({
  title,
  icon,
  size,
  padding = true,
  marginTop,
  marginBottom,
  marginLeft,
}: TitleWithIconProps): ReactElement {
  const { t } = useTranslation()
  return (
    <div className={styles.TitleWithIcon_Header}>
      {icon && (
        <div className={styles.TitleWithIcon_Icon}>
          <span className={styles.TitleWithIcon_Icon_fal + ` ` + icon} />
        </div>
      )}
      <div
        className={dynamicClassName({
          [styles.TitleWithIcon_Title]: true,
          [styles.TitleWithIcon_Title__medium]: size === `medium`,
          [styles.TitleWithIcon_Title__small]: size === `small`,
          [styles.TitleWithIcon_Title__padding]: padding,
          [styles.TitleWithIcon_Title__marginTop]: !!marginTop,
          [styles.TitleWithIcon_Title__marginBottom]: !!marginBottom,
          [styles.TitleWithIcon_Title__marginLeft]: !!marginLeft,
        })}
      >
        {t(`${title}`)}
      </div>
    </div>
  )
}
