import { ConfigurableProperty, DeviationSettingValue, DigitalTwin, DigitalTwinSetting, DigitalTwinSettingMetadata, TranslatedDeviationSetting } from 'api/digitalTwin/digitalTwin.api'
import { Setting } from 'api/settings/settings.api'
import Datetime from 'utils/datetime/datetime'
import { getPropertiesFromDigitalTwinModel } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'
import { translateKeyWithSpecificTranslations } from 'utils/stringUtils'

import i18n from 'i18next'

import { keyToSourceFromCommonDicts } from 'localization/commonTranslations'

export function getActiveSettingByProperty(settings: TranslatedDeviationSetting[]): { [key: string]: TranslatedDeviationSetting } {
  const activeSettingByProperty: { [key: string]: TranslatedDeviationSetting } = {}

  const now = Datetime.getISONow(0, false)

  settings.forEach((setting) => {
    const settingProperty = { level: setting.level, name: setting.name, attribute: setting.attribute }
    const key = JSON.stringify(settingProperty)
    const currentSetting = activeSettingByProperty[key]

    const ISOFormatStartTime = setting.start_time ? Datetime.toISOString(setting.start_time as ISODateTime) : null
    const ISOFormatEndTime = setting.end_time ? Datetime.toISOString(setting.end_time as ISODateTime) : null

    if (
      (ISOFormatStartTime === null || Datetime.isBeforeOrEqual(ISOFormatStartTime, now)) &&
      (ISOFormatEndTime === null || Datetime.isAfterOrEqual(ISOFormatEndTime, now)) &&
      (!currentSetting || setting.priority > currentSetting.priority)
    ) {
      activeSettingByProperty[key] = setting
    }
  })
  return activeSettingByProperty
}

export function getDictFromSettingMetadata(settingMetadata: DigitalTwinSettingMetadata[]): { [key: string]: DigitalTwinSettingMetadata } {
  const activeSettingByProperty: { [key: string]: DigitalTwinSettingMetadata } = {}
  settingMetadata.forEach((setting) => {
    const settingProperty = { level: setting.level, name: setting.name, attribute: setting.attribute }
    const key = JSON.stringify(settingProperty)
    activeSettingByProperty[key] = setting
  })
  return activeSettingByProperty
}

export function getActiveSettingMetadataByProperty(
  settingMetadata: DigitalTwinSettingMetadata[],
  priorities: SystemSettingPriorityLevel[]
): { [key: string]: DigitalTwinSettingMetadata } {
  const activeSettingByProperty: { [key: string]: DigitalTwinSettingMetadata } = {}

  settingMetadata.forEach((setting) => {
    const settingProperty = { level: setting.level, name: setting.name, attribute: setting.attribute }
    const key = JSON.stringify(settingProperty)
    const activeSetting = activeSettingByProperty[key]

    if (isSettingMetadataPriorityIncluded(setting, priorities)) {
      if (activeSetting) {
        const hasHigherPriorityThanActiveSetting = setting.priority != undefined
          && activeSetting.priority != undefined
          && setting.priority > activeSetting.priority
        if (hasHigherPriorityThanActiveSetting) {
          activeSettingByProperty[key] = setting
        }
      } else {
        activeSettingByProperty[key] = setting
      }
    }
  })
  return activeSettingByProperty
}

export function isSettingMetadataPriorityIncluded(settingMetadata: DigitalTwinSettingMetadata, priorities: SystemSettingPriorityLevel[]): boolean {
  return priorities.some((priority) => priority.priority_level === settingMetadata.priority)
}

export function getHistorySettingByProperty(timestamp: ISODateTime, settings: TranslatedDeviationSetting[]): { [key: string]: TranslatedDeviationSetting } {
  const historySettingByProperty: { [key: string]: TranslatedDeviationSetting } = {}

  settings.forEach((setting) => {
    const settingProperty = { level: setting.level, name: setting.name, attribute: setting.attribute }
    const key = JSON.stringify(settingProperty)
    const currentSetting = historySettingByProperty[key]

    if (
      (setting.end_time === null || Datetime.isBeforeOrEqual(setting.end_time, timestamp)) &&
      (!currentSetting || setting.priority > currentSetting.priority)
    ) {
      historySettingByProperty[key] = setting
    }
  })
  return historySettingByProperty
}

export function getUpcomingSettingByProperty(timestamp: ISODateTime, settings: TranslatedDeviationSetting[]): { [key: string]: TranslatedDeviationSetting } {
  const upcomingSettingByProperty: { [key: string]: TranslatedDeviationSetting } = {}

  settings.forEach((setting) => {
    const settingProperty = { level: setting.level, name: setting.name, attribute: setting.attribute }
    const key = JSON.stringify(settingProperty)
    const currentSetting = upcomingSettingByProperty[key]

    if (
      (setting.start_time === null || Datetime.isBeforeOrEqual(timestamp, setting.start_time)) &&
      (setting.end_time === null || Datetime.isAfterOrEqual(setting.end_time, timestamp)) &&
      (!currentSetting || setting.priority > currentSetting.priority)
    ) {
      upcomingSettingByProperty[key] = setting
    }
  })
  return upcomingSettingByProperty
}

export function getLabelForAttributeValue(
  attribute: string,
  value: number | undefined | (number | null)[] | DeviationSettingValue
): number | undefined | null | string {

  if (value && Array.isArray(value)) {
    return i18n.t('Time series')
  }
  if (attribute.includes('availability')) {
    if (value === 0) {
      return i18n.t('Unavailable')
    } else if (value === 1) {
      return i18n.t('Available')
    }
  } else if (attribute.includes('forced')) {
    if (value === 1) {
      return i18n.t('Forced active')
    } else if (value === 0) {
      return i18n.t('Forced driving inactive')
    }
  }

  return value
}

export function getSettingsWithUnitsFromMatchingConfigurableProperty(digitalTwin: DigitalTwin | undefined ,settings: DigitalTwinSetting[]): DigitalTwinSetting[] {
    
  const configurableProperties: ConfigurableProperty[] = digitalTwin?.model
    ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model) : []

  const settingsWithUnit = ((settings ?? [])
    .map(setting => {
      const matchingProperty = configurableProperties.find(
        property => property.name === setting.name && property.attribute === setting.attribute && property.level === setting.level
      )
      return {
        ...setting,
        unit: matchingProperty?.measurement_unit || '',
      }
    })
  )

  return settingsWithUnit
}


export function getPrettyName(translationKey: string, translations?: { [key: string]: string }, toUpperCase?: boolean): string {
  const specificTranslation = translateKeyWithSpecificTranslations(translationKey, translations, toUpperCase)
  // If specific translation for raw property name exists, use it.
  if (specificTranslation !== translationKey) {
    return specificTranslation
  }

  // If property is a nested property, get the pretty name for the nested property.
  if (translationKey.includes('.')) {
    return getPrettyName(translationKey.split('.').slice(1).join('.'), translations, toUpperCase)
  }

  const commonTranslation = keyToSourceFromCommonDicts(translationKey)
  if (translationKey !== commonTranslation) {
    // If specific translation for pretty name exists, use it.
    return translateKeyWithSpecificTranslations(commonTranslation, translations, toUpperCase)
  }

  return translationKey
}

export function getPropertyPrettyName(
  name: string,
  attribute: string,
  translations?: { [key: string]: string }
): string {
  const translationKey = `${name}.${attribute}`
  return getPrettyName(translationKey, translations)
}

export function getSettingAffectsProductionPlan(
  value: Setting | TranslatedDeviationSetting | null | Array<Setting | TranslatedDeviationSetting | null>
): boolean {
  if (Array.isArray(value)) {
    if (value.every((v) => v === undefined || v === null)) {
      return false
    } else {
      return true
    }
  } else {
    if (value?.id) {
      return true
    }
    return false
  }
}

export function getAvailableFilters(items: string[][], activeFilters: string[], hiddenFilters: Set<string>): Set<string> {

  // A filter is visibile, if and only if changing it will show/hide more items.
  // If other filters prevent an item from being shown/hidden when a filter is changed, that filter is irrelevant for that item.
  // If a filter is not relevant for any item, there is no need to show it.
  const output = new Set<string>()
  items.forEach((item) => {

    const inactiveFiltersForItem = new Set(item)
    activeFilters.forEach(filter => inactiveFiltersForItem.delete(filter))

    if (inactiveFiltersForItem.size === 0) {
      // All filters are ACTIVE for this combination, so all filters are relevant (since changing one of them will hide the item).
      item.forEach(filter => output.add(filter))
    } else if (inactiveFiltersForItem.size === 1) {
      // Only one filter is INACTIVE for this combination, so that filter is relevant (since changing it will show the item).
      inactiveFiltersForItem.forEach(filter => output.add(filter))
    }
  })

  // Hidden filters should not be included when we decide which top level filters to show.
  // If all items in a list are in the 'hidden_filters' list, the top level filter should not be shown.
  hiddenFilters.forEach(filter => output.delete(filter))

  return output
}

export function allFiltersSelected(selectedFilters: string[], allFilters: string[]): boolean {
  return selectedFilters.length === allFilters.length
}