import React, { ReactElement, useState } from 'react'

import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import ShiftInfoWidget from 'views/ShiftHandoverView/components/ShiftInfoWidget/ShiftInfoWidget'

const widgetConstants = {
  HANDOVER_GRID: `shiftSchedule`,
  HANDOVER_WIDGET_1: `item-1`,
  HANDOVER_WIDGET_2: `item-2`,
}

const calendar: Array<Step> = [
  {
    target: widgetConstants.HANDOVER_WIDGET_1,
    content: `Here you can see the current shift schedule on a weekly basis. Each shift team has its own colour in the schedule. It is also possible to view the shift schedule several weeks ahead or back by pressing the arrows above the schedule.`,
    title: `Shift information`,
  },
]

export default function ShiftScheduleView(): ReactElement {
  const { t } = useTranslation()
  const [runTour, setRunTour] = useState<null | number>(null)

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={t(`Shift Schedule`)}
          onInfoButtonClick={() => {
            setRunTour(0)
          }}
        />
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={calendar}
      />

      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.HANDOVER_WIDGET_1}
          title={t(`Schedule`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <ShiftInfoWidget />
        </CardWidget>
      </Grid>


    </View>
  )
}