import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { DigitalTwinSetting, useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { useGetEvent, useGetEventCategories } from 'api/events/events.api'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Dialog } from 'ui/components/Dialog/Dialog'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import PrintButton from 'ui/molecules/PrintButton/PrintButton'
import Datetime from 'utils/datetime/datetime'
import { getPropertiesFromDigitalTwinModel } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { DialogContent, Divider } from '@mui/material'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import EventPostModal from '../EventPostModal/EventPostModal'
import { EventProperties, EVENT_TYPES, SCHEDULABILITIES, getLabelForEventStatus, useFetchOperationalEventSettings } from '../events.helper'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './EventPostViewModal.module.less'

type EventPostViewModalProps = {
  closeModal: () => void
  selectedEventPostId?: number
}

const getSettingValue = (setting: DigitalTwinSetting): string => {
  if (typeof setting.value === 'number') {
    return setting.value.toString()
  }

  if (Array.isArray(setting.value)) {
    const values = setting.value.map(item => item.value).filter(value => value !== null && value !== undefined)
    const minValue = Math.min(...values)
    const maxValue = Math.max(...values)
    return `${minValue}-${maxValue}`
  }

  return 'N/A'
}

export default function EventPostViewModal({ closeModal, selectedEventPostId }: EventPostViewModalProps): ReactElement {
  const { t } = useTranslation()
  const history = useHistory()

  const { activeSystem, systemId } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true)
  const { data: selectedEventPost } = useGetEvent(selectedEventPostId)

  const eventCategories = useGetEventCategories(systemId).data || []
  const { settings } = useFetchOperationalEventSettings(selectedEventPostId)

  const allProperties = useMemo(() => {
    return digitalTwin?.model
      ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model)
      : []
  }, [digitalTwin])

  const [openEventPostModal, setOpenEventPostModal] = useState(false)

  const handleOnClose = useCallback((): void => {
    closeModal()
  }, [closeModal])

  if (!selectedEventPost) {
    return <></>
  }

  const handleEditClick = (): void => {
    const url = new URL(location.origin)
    url.pathname = `/events/${selectedEventPostId}`
    const newWindow = window.open(url, '_blank')

    if (newWindow) {
      newWindow.onload = () => {
        ReactDOM.createPortal(
          <EventPostModal
            selectedEventPostId={selectedEventPostId}
            closeModal={() => setOpenEventPostModal(false)}
          />,
          newWindow.document.body
        )
      }
    }
  }


  const renderInformation = (): ReactElement => {
    return (
      <>
        <div className={styles.EventPostViewModal_Information}>
          <div className={styles.EventPostViewModal_Information_Section}>
            <div className={styles.EventPostViewModal_Information_Section_Column}>
              <div><strong>{t('Name')}:</strong> {selectedEventPost.name}</div>
              <div><strong>{t('Event type')}:</strong> {EVENT_TYPES.find(eventType => eventType.value === selectedEventPost.operational_event_type)?.label || '-'}</div>
              <div><strong>{t('Cause category')}:</strong> {selectedEventPost.category_id ? eventCategories.find(eventType => eventType.id === selectedEventPost.category_id)?.display_name : ''}</div>
              <div><strong>{t('Requires UMM')}:</strong> {selectedEventPost.require_umm ? t('Yes') : t('No')}</div>
              <div><strong>{t('Cancelable after start')}:</strong> {selectedEventPost.cancelable_after_start ? t('Yes') : t('No')}</div>
            </div>

            <div className={styles.EventPostViewModal_Information_Section_Column}>
              <div><strong>{t('Start time')}:</strong> {Datetime.toLocalTime(selectedEventPost.start_time, 'longDay')}</div>
              <div><strong>{t('End time')}:</strong> {Datetime.toLocalTime(selectedEventPost.end_time, 'longDay')}</div>
              <div> <strong>{t('Earliest start time')}:</strong> {selectedEventPost.earliest_start_time ? Datetime.toLocalTime(selectedEventPost.earliest_start_time, 'longDay') : '-'}</div>
              <div><strong>{t('Latest end time')}:</strong> {selectedEventPost.latest_end_time ? Datetime.toLocalTime(selectedEventPost.latest_end_time, 'longDay') : '-'}</div>
            </div>
          </div>

          <div className={styles.EventPostViewModal_Information_Section}>
            <div className={styles.EventPostViewModal_Information_Section_Column}>
              <div><strong>{t('Schedulability')}:</strong> {SCHEDULABILITIES.find(schedulability => schedulability.value === selectedEventPost.schedulability)?.label || '-'} </div>
              <div> <strong>{t('Consequence of time adjustment')}:</strong> {selectedEventPost.schedulability_effect}</div>
            </div>

            <div className={styles.EventPostViewModal_Information_Section_Column}>
              <div><strong>{t('Created at')}:</strong> {selectedEventPost.created_at ? Datetime.toLocalTime(selectedEventPost.created_at, 'longDay') : '-'} </div>
              <div><strong>{t('Updated at')}:</strong> {selectedEventPost.updated_at ? Datetime.toLocalTime(selectedEventPost.updated_at, 'longDay') : '-'}</div>
              <div><strong>{t('Created by')}:</strong> {selectedEventPost.created_by ? selectedEventPost.created_by : t('Unknown')}</div>
              <div><strong>{t('Updated by')}:</strong> {selectedEventPost.updated_by ? selectedEventPost.updated_by : t('Unknown')}</div>
              <div><strong>{t('Status')}:</strong> {getLabelForEventStatus(selectedEventPost.status)}</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderInputField = (): ReactElement | null => {
    const inputFieldKey = 'Input field'
    const inputFieldValue = selectedEventPost.input_field

    if (inputFieldValue && selectedEventPost.operational_event_type) {
      const htmlContent = (inputFieldValue as { [key: string]: string })[selectedEventPost.operational_event_type]
      return (
        <div key={inputFieldKey}>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} className={styles.EventPostViewModal_QuillEditor} />
        </div>
      )
    } else {
      return null
    }
  }

  const renderSettings = (settings: DigitalTwinSetting[], linked_objects?: EventProperties | [] | undefined): ReactElement => {
    const settingsMap: {[key: string]: DigitalTwinSetting[]} = {}
    settings.forEach(setting => {
      const key = `${setting.level}-${setting.name}-${setting.attribute}`
      if (!settingsMap[key]) {
        settingsMap[key] = []
      }
      settingsMap[key].push(setting)
    })

    const leftPanel: ReactElement[] = []
    const rightPanel: ReactElement[] = []

    linked_objects?.map((linkedObject, index) => {
      const key = `${linkedObject.object_category}-${linkedObject.object_name}-${linkedObject.attribute}`
      const settings = settingsMap[key]

      const prettyPriorityName = (priority: number): string => {
        let prettyName = ''
        if (priority === 20) {
          prettyName = t('Planned')
        } else if (priority === 30) {
          prettyName = t('Unplanned')
        }
        return prettyName
      }

      const linkedObjectItem = (
        <div key={key}>
          <div className={styles.EventPostViewModal_AffectedComponent}>
            <h3>
              {
                getPrettyName(linkedObject.object_category, digitalTwin?.translations) + ' - '
                + getPrettyName(linkedObject.object_name, digitalTwin?.translations) + (linkedObject.attribute ? ' - ' : '')
                + getPrettyName(linkedObject.attribute, digitalTwin?.translations)
              }
            </h3>
            {settings?.length
              ? <p>{` ${settings?.length} ${settings?.length > 1
                ? t('Settings')
                : t('Setting')}`}</p>
              : <p>{t('Affected component. No added settings')}</p>
            }
          </div>
          {settings?.map((setting, index) => {
            const property = allProperties.find(property => property.name === setting.name
                && property.attribute === setting.attribute
                && property.level === setting.level)

            return (
              <>
                <div key={setting.id} className={styles.EventPostViewModal_Settings}>
                  {setting.isTimeSeries && <p><strong>{t('Time series setting')}</strong></p>}
                  <p><strong>{t('Start time')}:</strong> {setting.start_time ? Datetime.toLocalTime(setting.start_time, 'longDay') : '-'}</p>
                  <p><strong>{t('End time')}:</strong> {setting.end_time ? Datetime.toLocalTime(setting.end_time, 'longDay') : '-'}</p>
                  <p><strong>{t('Deviation type')}:</strong> {prettyPriorityName(setting.priority)}</p>
                  <p><strong>{t('Cause')}:</strong> {setting.comment}</p>
                  <p><strong>{t('Value')}:</strong> {getSettingValue(setting)} {property?.measurement_unit}</p>
                </div>
                {settings.length > 1 && index !== settings.length - 1 && <Divider />}
              </>
            )}
          )}
        </div>
      )

      if (index % 2 === 0) {
        leftPanel.push(linkedObjectItem)
      } else {
        rightPanel.push(linkedObjectItem)
      }
    })

    return (
      <>
        <div className={styles.EventPostViewModal_Container}>
          <div className={styles.EventPostViewModal_LeftPanel}>
            {leftPanel}
          </div>
          <div className={styles.EventPostViewModal_RightPanel}>
            {rightPanel}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="lg" onClose={(_, reason) => {
        if (reason === 'escapeKeyDown') {
          handleOnClose()
        }
      }}>
        <div data-printable className={styles.EventPostViewModal}>
          <CustomDialogTitle
            title={selectedEventPost.name ?? ''}
            handleClose={handleOnClose}
          />
          <DialogContent className={styles.EventPostViewModal_Body}>
            <h2>{t('Information')}:</h2>
            {renderInformation()}
            <h2>{t('Description')}:</h2>
            {renderInputField()}

            <h2>{t('Affected components & settings')}:</h2>
            {renderSettings(settings, selectedEventPost.linked_objects)}
            
            <div className={styles.EventPostViewModal_PrintButton}>
              <Button
                primary
                icon='fal fa-edit'
                onClick={() => {
                  history.location.pathname !== '/events' ? handleEditClick() : (
                    setOpenEventPostModal(true)
                  )
                }}
              >{t('Edit')}</Button>
              <PrintButton
                fileName={selectedEventPost.name}
                title={t('Print')}
                fitOverflowY={{ overflowInParentElement: true }}
              />
            </div>
          </DialogContent>
        </div>

      </Dialog>
      {openEventPostModal && (
        <EventPostModal
          selectedEventPostId={selectedEventPostId}
          closeModal={() => setOpenEventPostModal(false)}
        />
      )}
    </>
  )
}
