import React, { ReactElement, useEffect, useState } from 'react'

import { chartStore, hideReturnIdInGroup, initFilterItemStatus, toggleFilterItemStatus } from 'store/chart/chart'
import { Button } from 'ui/atoms'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getInitialHiddenAndShownReturnIds } from '../chart.helper'
import { FilterItems } from '../chartTypes'

type FilterButtonsType = {
  filterItems: FilterItems[],
  groupId: string,
 }


export default function FilterButtons({ filterItems, groupId }: FilterButtonsType): ReactElement {
  const { t } = useTranslation()

  const chartSnap = useSnapshot(chartStore)
  const [hasInitGroupId, setHasInitGroupId] = useState<string | undefined>(undefined)

  const ordinaryFilterItems = (filterItems.filter((filterItem) => !filterItem.is_mutually_exclusive))
  const mutuallyExclusiveFilterItems = (filterItems.filter((filterItem) => filterItem.is_mutually_exclusive))

  useEffect(() => {
    // If the chart state is cleared, we want to reinitalize the filter items even if hasInitGroupId is set
    const groupIdInFilterIsVisible = Object.prototype.hasOwnProperty.call(chartSnap.groupIdFilterItemIsVisible, groupId)

    if (hasInitGroupId === groupId && groupIdInFilterIsVisible) {
      // hasInitGroupId is set, and current groupId is in groupIdFilterItemIsVisible. No need to reinitialize.
      return
    }

    const hiddenAndShownReturnIds = getInitialHiddenAndShownReturnIds(filterItems ?? [])
    hideReturnIdInGroup({ groupId, returnId: hiddenAndShownReturnIds.hidden })

    filterItems.forEach((filterItem) => {
      initFilterItemStatus(filterItem, groupId)
    })

    setHasInitGroupId(groupId)
  }, [chartSnap.groupIdFilterItemIsVisible, filterItems, groupId, hasInitGroupId])

  function toggleFilterItemVisibilityHandler(filterItem: FilterItems, setToHidden: boolean, exclusive?: boolean): void {
    toggleFilterItemStatus({
      groupId,
      filterItem,
      exclusive,
    })
  }

  if (ordinaryFilterItems.length === 0 && mutuallyExclusiveFilterItems.length !== 0) {
    return (
      <div>
        {mutuallyExclusiveFilterItems.map((filterItem, key) => {
          const filterItemIsShown = !!(chartSnap.groupIdFilterItemIsVisible[groupId]?.[filterItem.title]
            ?? filterItem.default_hidden
            ?? false)

          return (
            <Button
              secondary
              marginRight
              marginBottom
              key={key}
              onClick={() => {
                toggleFilterItemVisibilityHandler(filterItem, !filterItemIsShown, true)
              }}
              icon={!filterItemIsShown ? 'fal fa-filter' : 'fa fa-filter'}
              tooltip={t(`Filter on {{title}}`, { title: filterItem.title })}
            >
              {filterItem.title}
            </Button>)
        })}
      </div>
    )
  }

  return (
    <div>
      {ordinaryFilterItems.map((filterItem, key) => {
        const isVisible = chartSnap.groupIdFilterItemIsVisible[groupId]?.[filterItem.title]
          ?? filterItem.default_hidden
          ?? false

        return (
          <Button
            secondary
            marginRight
            marginBottom
            key={key}
            onClick={() => toggleFilterItemVisibilityHandler(filterItem, !isVisible)}
            icon={isVisible ? 'fa fa-filter' : 'fal fa-filter'}
            tooltip={t(`Filter on {{title}}`, { title: filterItem.title })}
          >
            {filterItem.title}
          </Button>)
      })}
    </div>
  )
}

