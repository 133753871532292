import React, { ReactElement, Suspense, useMemo } from 'react'

import { useModules } from 'api/modules/modules.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import alertStore from 'store/alert/alert'
import authStore from 'store/auth/auth'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import Alert from 'ui/components/Alert/Alert'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import Navigation from 'ui/components/Navigation/Navigation'
import PrivateSwitch from 'ui/components/PrivateSwitch/PrivateSwitch'
import StageBanner from 'ui/components/StageBanner/StageBanner'
import sentry from 'utils/sentry/sentry'

import { ErrorBoundary } from 'react-error-boundary'
import { useSnapshot } from 'valtio'

import { useMainRoutes } from 'routes/main.routes'

import Initialization from './Initialization'
import styles from './MainLayout.module.less'
import { filterRoutes } from './MainLayout.utils'

export default function MainLayout(): ReactElement {
  const auth = useAuth()
  const { data: modules = [] } = useModules(auth.systemId)
  const mainRoutes = useMainRoutes()
  const alertStoreSnap = useSnapshot(alertStore)

  const routes = useMemo(() => filterRoutes(mainRoutes, modules), [mainRoutes, modules])

  const authSnap = useSnapshot(authStore)
  useUiConfig(authSnap.systemId, `base_config`)

  return (
    <div className={styles.MainLayout_Root}>
      <StageBanner />
      <Navigation routes={routes}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
          <Suspense fallback={<LoadingPlaceholderContainer />}>
            <PrivateSwitch routes={routes} />
          </Suspense>
        </ErrorBoundary>
        <Alert
          variant={alertStoreSnap.alert?.variant ? alertStoreSnap.alert.variant : `success`}
          message={alertStoreSnap.alert?.message ? alertStoreSnap.alert.message : ``}
          open={!!alert}
          onClose={alertStoreSnap.alertClear}
          SnackbarProps={alertStoreSnap.alert?.props}
        />
        <Initialization />
      </Navigation>
    </div>
  )
}
