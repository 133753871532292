import React, { ReactElement, useState, useEffect } from 'react'


import { postStat } from 'api/stats/stats.api'
import { Button } from 'ui/atoms'
import { toDateValue, toISODateTime } from 'ui/molecules/pickers/DatePicker/utils/dateAdapter'
import DatePicker from 'ui/molecules/pickers/internal/DatePicker/DatePicker'
import {
  defaultTransformStartDate,
  defaultTransformEndDate,
} from 'ui/molecules/pickers/internal/DatePicker/utils/datePicker.helper'
import { useFormatErrorMessageOptimizeView } from 'ui/molecules/pickers/internal/DatePicker/utils/useFormatErrorMessage'
import { validateRange } from 'ui/molecules/pickers/internal/DatePicker/utils/validation'
import Datetime from 'utils/datetime/datetime'

import moment from 'moment'
import { DateValue } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import { optimizeViewRange } from 'views/OptimizeView/store/optimizeViewStore'

import styles from './OptimizeViewDateTimePeriodPicker.module.less'

type DatepickerFormProps = {
  handlePeriodChange: (period: { startTime: ISODateTime; endTime: ISODateTime }) => void
}
export default function OptimizeViewDateTimePeriodPicker({ handlePeriodChange }: DatepickerFormProps): ReactElement {
  const { t } = useTranslation()

  // Use the current optimized view range as the default period (should
  // correspond to whatever the user last selected e.g. week or day)
  const [rangeStartDateTime, rangeEndDateTime] = optimizeViewRange()

  const transformedStartDateTime: DateValue = toDateValue(moment(rangeStartDateTime)) as DateValue
  const transformedEndDateTime: DateValue = toDateValue(moment(rangeEndDateTime)) as DateValue

  const [startDateTime, setStartDateTime] = useState<DateValue>(
    defaultTransformStartDate(transformedStartDateTime) ?? transformedStartDateTime
  )
  const [endDateTime, setEndDateTime] = useState<DateValue>(
    defaultTransformEndDate(transformedEndDateTime) ?? transformedEndDateTime
  )

  const [isError, setIsError] = useState(false)

  const handleDateTimePickerError = (error: boolean): void => {
    setIsError(error)
  }
  const { format } = useFormatErrorMessageOptimizeView()
  // +169 hours correspond to the max optimization range (1 week + 1 hour)
  // Datetime floors the result to the nearest hour, e.g. 12:00
  const maxOptimizationISODate = Datetime.getISONow(169)
  // However, in the datepicker the end time isn't floored, but instead changed to the last
  // minute and second of the hour, to help the users understand that the end
  // time is inclusive. So our max date needs to be stretched a bit.
  const maximumDateTime = toDateValue(moment(maxOptimizationISODate).minutes(59) ?? moment().add(1, 'years'))
  const minimumDateTime = toDateValue(moment().subtract(1, 'week').startOf('day'))

  const [startDateTimeError, setStartDateError] = useState(false)
  const [endDateTimeError, setEndDateError] = useState(false)

  const validationResult = validateRange({
    startDate: startDateTime,
    endDate: endDateTime,
    minValue: minimumDateTime,
    maxValue: maximumDateTime,
  })

  // Return undefined when our validation is valid, to not override pickers internal validation
  const isInvalid = !validationResult.isValid || undefined

  function propagateError(): void {
    if (!handleDateTimePickerError) return

    const hasError = startDateTimeError || endDateTimeError || !validationResult.isValid
    handleDateTimePickerError(hasError)
  }
  useEffect(propagateError, [startDateTimeError, endDateTimeError, validationResult.isValid])

  return (
    <div className={styles.OptimizeViewDateTimePeriodPicker}>
      <div className={styles.OptimizeViewDateTimePeriodPicker_DateTimePeriodPickerWithInfoBannerContainer}>
        <div className={styles.OptimizeViewDateTimePeriodPicker_DateTimePeriodPickerWithInfoBannerContainer_DateTimePeriodPicker}>
          <DatePicker
            data-testid="start-date-time-picker"
            name={'start-date-time-picker'}
            isRequired
            value={startDateTime}
            isInvalid={isInvalid}
            minValue={minimumDateTime ?? undefined}
            maxValue={maximumDateTime ?? undefined}
            label={t('Start date')}
            onChange={(dateTime: DateValue) => {
              const transformedDateTime = defaultTransformStartDate(dateTime)
              setStartDateTime(transformedDateTime ?? dateTime)
            }}
            onError={setStartDateError}
            showWeekNumbers
          />
          <DatePicker
            data-testid="end-date-time-picker"
            name={'end-date-time-picker'}
            isRequired
            value={endDateTime}
            isInvalid={isInvalid}
            minValue={minimumDateTime ?? undefined}
            maxValue={maximumDateTime ?? undefined}
            label={t('End date')}
            onChange={(dateTime: DateValue) => {
              const transformedDateTime = defaultTransformEndDate(dateTime)
              setEndDateTime(transformedDateTime ?? dateTime)
            }}
            onError={setEndDateError}
            isEndPeriod
            showWeekNumbers
          />
        </div>
        {!validationResult.isValid && (
          <div className={styles.OptimizeViewDateTimePeriodPicker_DateTimePeriodPickerWithInfoBannerContainer_ErrorText}>
            <text>{validationResult?.error ? format(validationResult.error) : ''}</text>
          </div>
        )}
      </div>

      <div className={styles.OptimizeViewDateTimePeriodPicker_LoadPeriodButton}>
        <Button
          primary
          disabled={isError}
          data-testid="apply-period-change"
          icon="fal fa-redo"
          onClick={() => {
            handlePeriodChange({
              startTime: toISODateTime(startDateTime) as ISODateTime,
              endTime: toISODateTime(endDateTime) as ISODateTime,
            })
            postStat(`period-picker`, `apply-period-change`)
          } }
        >
          {t(`Load period`)}
        </Button>
      </div>

    </div>
  )
}
