import React, { ReactElement } from 'react'

import { postStat } from 'api/stats/stats.api'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'

import { useTranslation } from 'react-i18next'

import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './DeviationSettingsListItem.module.less'

type DeviationSettingsListItemProps = {
  name: string
  prettyName: string
  settings: {
    id: number;
    name: string;
    value: string;
    startDate: string;
    endDate: string;
    comment: string;
    operationalEvent?: EventPost;
  }[] 
  showButton: boolean;
  onEventClick: (eventPost: EventPost | undefined) => void;
}

export default function DeviationSettingsListItem({name, prettyName, settings, showButton, onEventClick}: DeviationSettingsListItemProps): ReactElement {
  const { t } = useTranslation()
  const { hasAccess }  = useAuth()

  const handleClick = (): void => {
    postStat('deviation_settings_menu', `navigate-to-filtered-property-${name}`) //DATA LOG FOR PILOT 1,5

    const url = new URL(location.origin)
    url.pathname = '/digitalTwinDeviationSettings'
    url.search = location.search
    url.searchParams.set('filter', name)
    window.open(url, '_blank')
  }

  const hasAccessToUMMCheckbox = hasAccess({ submodule: 'operational_event_umm', module: 'events' })
  
  return (
    <div className={styles.DeviationSettingsListItem}>
      <div className={styles.DeviationSettingsListItem_Title}>
        <span>{prettyName}</span>
        {showButton && <TooltipIconButton icon={'fas fa-external-link'} 
          iconColor="#539ed4"
          tooltip={t('Open in new tab')} 
          iconSize="small" 
          onClick={handleClick} />}
      </div>
      {settings.map((setting) => {
        let eventName
        if(setting.operationalEvent) {
          eventName = setting.operationalEvent.name
        }

        const handleEventClick = (): void => {
          onEventClick(setting.operationalEvent)
        }

        return (
          <div key={setting.id} className={styles.DeviationSettingsListItem_Body}>
            <div className={styles.DeviationSettingsListItem_SettingValue}>
              <span className={styles.DeviationSettingsListItem__large}>{setting.name}</span>: {setting.value}
            </div>
            <div>
              <span className={styles.DeviationSettingsListItem__large}>{t('Period')}</span>: {`${setting.startDate} - ${setting.endDate}`}
            </div>
            <div>
              <span className={styles.DeviationSettingsListItem__large}>{t('Cause')}</span>: {setting.comment}
            </div>
            {setting.operationalEvent && (
              <>
                <div>
                  <span className={styles.DeviationSettingsListItem__large}>{t('Event')}</span>:
                  <span className={styles.DeviationSettingsListItem_OperationalEvent} onClick={handleEventClick}> {eventName}</span>
                  <Icon icon="fal fa-calendar-week" size='small' tooltip={{ title: t('View event'), arrow: true, placement: 'top' }} onClick={handleEventClick} />
                </div>
                {setting.operationalEvent.require_umm && hasAccessToUMMCheckbox && (
                  <div>
                    <span className={styles.DeviationSettingsListItem__large}>{t('Requires UMM')}</span>: {t('Yes')}
                  </div>
                )}
              </>
            )}
          </div>     
        )
      })}
      {settings.length === 0 && (<span className={styles.DeviationSettingsListItem__small}>{t('No active settings found')}</span>)}
    </div>
  )
}