import React, { ReactElement, useState } from 'react'

import { PlanValues } from 'api/elplan/elplan.api'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import { FormattedBidWithIdAndTitle } from '../DayAheadBidManagement.helper'

import SpotBidModal from './SpotBidModal/SpotBidModal'
import styles from './SpotBidTable.module.less'

export type SpotBidTableProps = {
  spotBids: FormattedBidWithIdAndTitle[],
  currentDate: { startTime: ISODateTime, endTime: ISODateTime },
  createBid: (bid: PlanValues[]) => void
  updateBid: (bid: PlanValues[]) => void
  deleteBidForDate: (date: ISODateTime) => void
}

export function getDefaultTimeAndVolumeObjects(date: ISODateTime): Record<string, PlanValues> {
  let currentHour = Datetime.getStartOfDay(date)
  const endHour = Datetime.getEndOfDay(date)

  const planValuesRecord: Record<string, PlanValues> = {}

  while (Datetime.isBeforeOrEqual(currentHour, endHour)) {
    const nextHour = new Datetime(currentHour).addHours(1)
    planValuesRecord[currentHour] = ({ time: currentHour, start_time: currentHour, end_time: nextHour.ISOString, volume: 0, id: null })

    currentHour = nextHour.ISOString
  }

  return planValuesRecord
}

export function spotBidsToPlanValues(databaseSpotBids: FormattedBidWithIdAndTitle[], date: ISODateTime): PlanValues[] {
  const array: PlanValues[] = []
  const recordWithAllHours = getDefaultTimeAndVolumeObjects(date)

  databaseSpotBids.forEach((spotBid) => {

    const startTimeISOString = Datetime.toISOString(spotBid.startTime)
    if (recordWithAllHours[startTimeISOString]) {
      recordWithAllHours[startTimeISOString].id = spotBid.id ?? null

      if (typeof spotBid.volume === 'number') {
        recordWithAllHours[startTimeISOString].volume = spotBid.volume
      } else if (Array.isArray(spotBid.volume)) {
        recordWithAllHours[startTimeISOString].volume = spotBid.volume[0]
      } else {
        recordWithAllHours[startTimeISOString].volume = 0
      }
    }
  })


  for (const key in recordWithAllHours) {
    array.push(recordWithAllHours[key])
  }

  return array

}
export default function SpotBidTable({spotBids, createBid, updateBid, deleteBidForDate, currentDate}: SpotBidTableProps): ReactElement {
  const { t } = useTranslation()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [confirmBidDeleteDialogOpen, setConfirmBidDeleteDialogOpen] = useState(false)

  const noRealBids = spotBids ? spotBids.length === 0 : true

  const defaultSpotBid = [
    {
      id: 0,
      fake: true,
      type: 'spotbid_without_price',
      title: t('Spot bid'),
      date: Datetime.toLocalTime(currentDate.startTime, 'onlyDate'),
      startTime: currentDate.startTime,
      endTime: currentDate.endTime,
      clear: true,
      editable: true,
    },
  ]

  function deleteSpotBidsForDate(): void {
    deleteBidForDate(currentDate.startTime)
  }


  const columns = [
    {
      accessor: 'color',
      header: '',
      Cell: () => {
        return <Icon color={'grey'} icon={'fa fa-circle'} />
      },
    },
    {
      accessor: 'title',
      Header: t('Spot bid'),
    },
    {
      accessor: `date`,
      Header: t(`Date`),
    },
    {
      accessor: `edit`,
      Header: t(`Edit`),
      Cell: () =>
        <Button
          link
          icon={'fal fa-pen'}
          onClick={() => setModalIsOpen(true)}
        >{''}</Button>,
    },
    {
      accessor: `clear`,
      Header: t(`Remove`),
      Cell: () =>
        <Button
          link
          icon={'fal fa-trash'}
          onClick={() => setConfirmBidDeleteDialogOpen(true)} 
        >{''}</Button>,
    },
  ]


  return (
    <div className={styles.ElectricityBidTable} >
      <ConfirmDialog
        open={confirmBidDeleteDialogOpen}
        textObject={{
          title: t(`Are you sure you want to remove all spotbids for {{date}}?`, { date: Datetime.toLocalTime(currentDate.startTime, 'onlyDate') }),
          text: t(
            `All values for this day will be removed.`
          ),
        }}
        onClose={() => {
          setConfirmBidDeleteDialogOpen(false)
        }}
        onConfirm={() => {
          deleteSpotBidsForDate()
          setConfirmBidDeleteDialogOpen(false)
        }}
      />

      <DefaultTable columns={columns} data={defaultSpotBid ?? []} />
      {modalIsOpen &&
        <SpotBidModal
          onClose={() => setModalIsOpen(false)}
          elplanExistsWarningText={t('There is already an electrity plan created for this period. Your changes will not affect the current electricity plan. Would you still like to make changes?')}
          title={noRealBids ? t('Create spot bid for 24h') : t('Edit spot bid for 24h')}
          elplanFromBids={spotBidsToPlanValues(spotBids, currentDate.startTime)}
          onSubmit={(planValues) => {
            if (noRealBids) {
              createBid(planValues)
            } else {
              updateBid(planValues)
            }
          }
          }
        />}
    </div>
  )
}
