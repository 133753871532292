import React, { ReactElement } from 'react'

import { useUiConfigVersions } from 'api/uiConfig/uiConfig.api'
import uiConfigStore, { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import { dynamicClassName } from 'styles/helper'

import { useSnapshot } from 'valtio'

import UiConfigBadge from 'views/UiConfigView/components/UiConfigBadge/UiConfigBadge'

import styles from './UiConfigVersions.module.less'

export default function UiConfigVersions(): ReactElement {
  const snap = useSnapshot(uiConfigStore)
  const editingUiConfigSnap = useSnapshot(editingUiConfigStore)
  const uid = snap.editingUid
  const id = editingUiConfigSnap.editingId

  useUiConfigVersions(uid)

  if (!uid || !id) {
    return <></>
  }

  const versionUids = snap.versions[id] || []
  const uiConfigs = versionUids.map((versionUid) => snap.uiConfigs[versionUid])

  function selectUiConfigVersion(uiConfig: UiConfig): void {
    uiConfigStore.editingUid = uiConfig.uid
  }

  return (
    <div className={styles.UiConfigVersions}>
      {uiConfigs.map((uiConfig) => (
        <div
          key={uiConfig.uid}
          className={dynamicClassName({
            [styles.UiConfigVersions_Item]: true,
            [styles.UiConfigVersions_Item__active]: uiConfig.uid === snap.editingUid,
          })}
          onClick={() => selectUiConfigVersion(uiConfig)}
        >
          {`(${uiConfig.id}.${uiConfig.version}) ${uiConfig.component}`}

          <UiConfigBadge uiConfig={uiConfig} />
        </div>
      ))}
    </div>
  )
}
