import React, { ReactElement } from 'react'

import { DigitalTwinTranslations } from 'api/digitalTwin/digitalTwin.api'

import styles from './DigitalTwinAccumulatorLevels.module.less'
import { SingleDigitalTwinAccumulatorLevel } from './SingleDigitalTwinAccumulatorLevel'

export type OnEditAccumulatorLevel = { onEdit: () => void; hasPermission: boolean }

export type AggregatedAccumulatorData = {
  unitName: string
  startLevelValue: number
  startLevelWarnings: string[]
  onEditStartLevelClicked?: OnEditAccumulatorLevel
  endLevelValue: number
  endLevelWarnings: string[]
  onEditEndLevelClicked?: OnEditAccumulatorLevel
}

type LevelsForOneDigitalTwinAccumulatorProps = {
  digitalTwinTranslations?: DigitalTwinTranslations
  accumulatorData: AggregatedAccumulatorData
}

export function LevelsForOneDigitalTwinAccumulator({
  digitalTwinTranslations,
  accumulatorData: aggregatedData,
}: LevelsForOneDigitalTwinAccumulatorProps): ReactElement {
  return (
    <div className={styles.DigitalTwinAccumulatorLevels_AccumulatorLevels}>
      <SingleDigitalTwinAccumulatorLevel
        value={aggregatedData.startLevelValue}
        accumulatorName={aggregatedData.unitName}
        attribute="start_level"
        onEditClicked={aggregatedData.onEditStartLevelClicked?.onEdit}
        hasEditPermission={aggregatedData.onEditStartLevelClicked?.hasPermission ?? false}
        digitalTwinTranslations={digitalTwinTranslations}
        warnings={aggregatedData.startLevelWarnings}
      />
      <SingleDigitalTwinAccumulatorLevel
        value={aggregatedData.endLevelValue}
        accumulatorName={aggregatedData.unitName}
        attribute="end_level"
        onEditClicked={aggregatedData.onEditEndLevelClicked?.onEdit}
        hasEditPermission={aggregatedData.onEditEndLevelClicked?.hasPermission ?? false}
        digitalTwinTranslations={digitalTwinTranslations}
        warnings={aggregatedData.endLevelWarnings}
      />
    </div>
  )
}
