import React, { ReactElement, useRef, useState } from 'react'

import classNames from 'classnames'

import styles from './HeightAdjuster.module.less'

type HeightAdjusterProp = {
  height: number
  minHeight: number
  maxHeight: number
  onChange: (height: number) => void
}

export default function HeightAdjuster({ height, minHeight, maxHeight, onChange }: HeightAdjusterProp): ReactElement {
  const [isResizing, setIsResizing] = useState(false)
  const container = useRef(null)
  const [diff, setDiff] = useState(0)

  function mouseMove(ev: MouseEvent): void {
    if (!container?.current || !isResizing) {
      return
    }

    const y = ev.clientY
    const yc = container.current.getBoundingClientRect().y

    setDiff(Math.round(y - yc))
  }

  function finishResize(): void {
    let newHeight = height + diff

    if (newHeight < minHeight) {
      newHeight = minHeight
    } else if (newHeight > maxHeight) {
      newHeight = maxHeight
    }

    onChange(newHeight)
    setIsResizing(false)
    setDiff(0)
  }

  return (
    <div
      className={styles.HeightAdjuster}
      ref={container}
    >
      <div
        className={classNames([styles.HeightAdjuster__DragElement], {
          [styles.HeightAdjuster__DragElement__active]: isResizing,
        })}
        style={{
          top: isResizing ? diff - 50 : -10,
        }}
        onMouseMove={mouseMove}
        onMouseDown={() => setIsResizing(true)}
        onMouseLeave={finishResize}
        onMouseUp={finishResize}
      >
        <div
          className={classNames({
            [styles.HeightAdjuster__DragElement__Line]: isResizing,
          })}
        />
      </div>
    </div>
  )
}