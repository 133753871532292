import React, { ReactElement, ReactNode, useState } from 'react'

import { Unit, useUnitByName } from 'api/units/units.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import i18n from 'i18next'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSnapshot } from 'valtio'

import DeviationSettingsDropdown from 'views/AvailabilityView/components/AvailabilityWidget/components/DeviationSettingsDropdown/DeviationSettingsDropdown'
import PeriodPicker from 'views/AvailabilityView/components/AvailabilityWidget/components/PeriodPicker/PeriodPicker'
import PlannedStopSettings from 'views/AvailabilityView/components/AvailabilityWidget/components/PlannedStopModal/PlannedStopSettings/PlannedStopSettings'

import styles from './Availability.module.less'

type AvailabilityUnitWidget = {
  unit_name: string
  unit_display_name: string
  kpi: { ui_config_id: number }
  chart: { ui_config_id: number }
}

type AvailabilityUnitsProps = {
  availabilityUnits: AvailabilityUnitWidget[]
  systemId: number
  children: ReactNode[]
  datasetEndTime?: ISODateTime
  datasetStartTime?: ISODateTime
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

export const AVAILABILITY_QUERY_KEY = 'availabilityQueryKey'

const defaultProps: Omit<AvailabilityUnitsProps, `datasets` | `children` | `systemId`> = {
  availabilityUnits: [
    {
      unit_name: '',
      unit_display_name: '',
      kpi: {
        ui_config_id: NaN,
      },
      chart: {
        ui_config_id: NaN,
      },
    },
  ],
}
registerUiConfigComponent(`availability`, Availability, {
  isSingletonComponent: true,
  title: i18n.t(`Availability for Units`),
})
registerDefaultProps(`availability`, defaultProps)

type AvailabilityWidgetProps = {
  systemId: number
  unitWidget: AvailabilityUnitWidget
}
function AvailabilityWidget({ systemId, unitWidget }: AvailabilityWidgetProps): ReactElement {
  const { data: units } = useUnitByName({ name: unitWidget.unit_name })
  const [period, setPeriod] = useState({ startTime: moment().startOf(`month`), endTime: moment().endOf(`month`) })
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const unitName = unitWidget.unit_display_name ?? unitWidget.unit_name
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  let myUnit: Unit

  if (units && units[0]) {
    myUnit = units[0]
  } else {
    return <></>
  }

  function handleOnChange(): void {
    queryClient.invalidateQueries(AVAILABILITY_QUERY_KEY)
  }

  return (
    <div className={styles.Availability_Widget}>
      <Grid item xs={12}>
        <CardWidget id={'AvailabilityWidget'} title={`${t('Availability')} - ${unitName}`}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            style={{ overflow: `hidden` }}
          >
            <Grid
              item
              container
              direction="column"
              xs={4}
              spacing={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <PeriodPicker setPeriod={setPeriod} />
              <UiConfigComponent
                datasetRefreshToken={AVAILABILITY_QUERY_KEY}
                systemId={systemId}
                id={unitWidget.kpi.ui_config_id}
                datasetStartTime={Datetime.toISOString(period.startTime)}
                datasetEndTime={Datetime.toISOString(period.endTime)}
                uid={uiConfigSnap.idToUiConfig[unitWidget.kpi.ui_config_id]?.uid}
              />
              <Grid item>
                <DeviationSettingsDropdown period={period} unit={myUnit?.id ?? 0} />
              </Grid>
              <PlannedStopSettings period={period} unit={myUnit?.id ?? 0} onRefetch={() => handleOnChange()} />
            </Grid>
            <Grid item xs={8}>
              <UiConfigComponent
                datasetRefreshToken={AVAILABILITY_QUERY_KEY}
                systemId={systemId}
                id={unitWidget.chart.ui_config_id}
                datasetStartTime={Datetime.toISOString(period.startTime)}
                datasetEndTime={Datetime.toISOString(period.endTime)}
                uid={uiConfigSnap.idToUiConfig[unitWidget.chart.ui_config_id]?.uid}
              />
            </Grid>
          </Grid>
        </CardWidget>
      </Grid>
    </div>
  )
}

export default function Availability({ availabilityUnits, systemId }: AvailabilityUnitsProps): ReactElement {
  return (
    <>
      {availabilityUnits.map((unitWidget, index) => {
        return <AvailabilityWidget key={index} systemId={systemId} unitWidget={unitWidget} />
      })}
    </>
  )
}
