import React, { ReactElement, useEffect, useState } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import zoomStore from 'store/zoom/zoom'
import { Card, Tabs } from 'ui/atoms'
import { TabItem } from 'ui/atoms/Tabs/Tabs'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import { capitalize } from 'utils/stringUtils'

import i18n from 'i18next'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

// TODO: Maybe change this type to include more info (colors, etc).
type ReviewForecastsTab = {
  title: string
  cards: {
    title: string,
    default_collapsed?: boolean,
    children_list: {
      ui_config_id: number,
      override_alias?: UiConfigAliases
    }[]
  }[]
}

type ReviewForecastsProps = {
  systemId: number
  tabs: ReviewForecastsTab[]
  datasetEndTime: ISODateTime
  datasetStartTime: ISODateTime
}

const defaultProps: Omit<ReviewForecastsProps, 'datasets' | 'children' | 'systemId' | 'datasetStartTime' | 'datasetEndTime'> = {
  tabs: [
    {
      title: 'City 1',
      cards: [{
        title: 'Heat',
        children_list: [{
          ui_config_id: -31,
          override_alias: {
            unit: 'heat',
            key: 'se_city',
          },
        },
        {
          ui_config_id: -30,
          override_alias: {
            unit: 'heat',
            key: 'se_city',
          },
        }],
      },
      {
        title: 'Cool',
        children_list: [{
          ui_config_id: -31,
          override_alias: {
            unit: 'cool',
            key: 'se_city',
          },
        },
        {
          ui_config_id: -30,
          override_alias: {
            unit: 'cool',
            key: 'se_city',
          },
        }],
      },
      {
        title: 'Temperature',
        children_list: [
          {
            ui_config_id: -22,
            override_alias: {
              forecast_locality: 'forecast_weather_se_city',
              outcome_tag: 'calc_temperature_observatorielund',
            },
          }],
      },
      {
        title: 'Electricity price',
        children_list: [
          {
            ui_config_id: -23,
          }],
      }],
    },
  ],
}

registerUiConfigComponent('review_forecasts', ReviewForecasts, {
  isSingletonComponent: true,
  title: i18n.t('Review forecasts'),
})
registerDefaultProps('review_forecasts', defaultProps)

function getSystemtabs(
  route: string,
  tabs: ReviewForecastsTab[],
  systemTabItems: TabItem[] = []
): JSX.Element {

  const localStorageKey = `ReviewForecastHeaderTabs_${route}`
  if (tabs.length == 1) {
    return <></>
  }

  if (systemTabItems.length == 0) {
    const systemTabItems: TabItem[] = []
    tabs.forEach((tab) => {
      systemTabItems.push({
        title: tab.title,
        children: [],
      })
    })
  }

  if (route === 'uiconfig') {
    return <Tabs items={systemTabItems} uniqueStorageKey={localStorageKey} />
  }

  if (route === 'reviewforecasts') {
    const element = document.getElementById('headerPortalTabs')
    if (element) {
      return ReactDOM.createPortal(
        <Tabs items={systemTabItems} innerLeftPadding marginBottom={false} uniqueStorageKey={localStorageKey} />,
        element
      )
    }
  }

  return <></>
}

export default function ReviewForecasts({
  systemId,
  datasetStartTime,
  datasetEndTime,
  tabs,
}: ReviewForecastsProps): ReactElement {
  const snap = useSnapshot(zoomStore)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const { t } = useTranslation()
  const [domReady, setDomReady] = useState(false)

  useEffect(() => {
    setDomReady(true)
    return () => {
      setDomReady(false)
    }
  }, [])

  if (!tabs?.length) {
    return <></>
  }

  if (tabs.length === 1) {
    return (
      <>
        {tabs[0].cards.map((card) =>
          <Card
            margin
            key={`card_${card.title}`}
            title={<TitleWithIcon title={t(capitalize(card.title)) ?? capitalize(card.title)} />}
            defaultCollapsed={card.default_collapsed}
            collapsible
          >
            {card.children_list.map((child, index) =>
              <UiConfigComponent
                key={`${child.ui_config_id}_${index}`}
                systemId={systemId}
                id={child.ui_config_id}
                uid={uiConfigSnap.idToUiConfig[child.ui_config_id]?.uid}
                datasetStartTime={datasetStartTime}
                datasetEndTime={datasetEndTime}
                overrideAlias={child.override_alias}
              />
            )}
          </Card>

        )}
      </>
    )
  } else {
    const systemTabItems: TabItem[] = tabs.map((tab) => ({
      title: tab.title,
      children: [<>{(tab.cards || []).map((card) => {
        return (
          <Card
            margin
            key={`card_${card.title}`}
            title={<TitleWithIcon title={t(capitalize(card.title)) ?? capitalize(card.title)} />}
            defaultCollapsed={card.default_collapsed}
            collapsible
          >
            {card.children_list.map((child, index) =>
              <UiConfigComponent
                key={`${child.ui_config_id}_${index}`}
                systemId={systemId}
                id={child.ui_config_id}
                uid={uiConfigSnap.idToUiConfig[child.ui_config_id]?.uid}
                datasetStartTime={datasetStartTime}
                datasetEndTime={datasetEndTime}
                overrideAlias={child.override_alias}
              />
            )}
          </Card>
        )
      })} </>],
    }))

    return (
      <>
        {domReady ? getSystemtabs(snap.route, tabs, systemTabItems) : null}
      </>
    )
  }
}
