import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import { dynamicClassName } from 'styles/helper'
import {
  Table as CustomTable,
  TableBody,
  TableFooter,
  TableHead,
  TableProvider,
} from 'ui/components/CustomTable/CustomTable'
import {
  registerUiConfigComponent,
  registerAppendPropsWithDatasets,
  registerDefaultProps,
  registerComponentHeight,
} from 'ui/uiConfig/factory'

import { Grid } from '@mui/material'

import { clone, getTypeObject } from 'helpers/global.helper/global.helper'

import styles from './Table.module.less'

type ColumnType = {
  Header: string | number | undefined
  accessor: string
  is_dataset: boolean
}

type RowType = {
  unit?: string
  data_type?: string
  decimals?: number
} & {
  [key: string]: string
}

type TableProps = {
  uid: number
  columns: ColumnType[]
  rows:  RowType[]
  title?: string
  datasets: Dataset[]
  children: ReactNode
  has_background_color: boolean
  no_padding: boolean
  ignoreZoom: boolean
  no_header?: boolean
}

function appendPropsWithDataset(datasetInstruction: DatasetInstruction, previousProps: TableProps): TableProps {
  return {
    ...previousProps,
    columns: [...(previousProps?.columns || [])],
    rows: [...(previousProps?.rows || [])],
  }
}

const defaultColumns = [{ Header: `Column`, accessor: `AccessorID`, is_dataset: false }]
const defaultRows = [{ unit: ``, data_type: ``, AccessorID: `Row` }]

const defaultProps: Omit<TableProps, `datasets` | `children` | `uid` | `ignoreZoom`> = {
  columns: defaultColumns,
  rows: defaultRows,
  has_background_color: false,
  no_padding: false,
  no_header: false,
  title: ``,
}

function getCalculatedRowsForFirstIndexInDataset(
  _rows: RowType[],
  columns: ColumnType[],
  datasets: Dataset[]
): Record<string, unknown>[] {
  const rows: RowType[] = clone(_rows)

  rows.forEach((row) => {
    for (const key in row) {
      const column = columns.find((column) => column.accessor === key)
      const returnId = row[key]

      if (!column || !returnId) {
        continue
      }

      const datasetItem = datasets.find((dataset) => dataset.return_id === returnId)

      if (datasetItem && column.is_dataset) {
        const value = datasetItem.values[0] ?? 0
        const typeObj = getTypeObject(value, row.data_type, row.unit, row.decimals)
        row[key] = typeObj.value + ` ` + typeObj.unit
      } else if (row[key] === datasetItem?.return_id) {
        row[key] = `-`
      } else if (column.is_dataset) {
        row[key] = `-`
      }
    }
  })

  return rows
}
registerUiConfigComponent('table', Table)
registerAppendPropsWithDatasets('table', appendPropsWithDataset)
registerDefaultProps('table', defaultProps)
registerComponentHeight('table', 300)

export default function Table({
  columns,
  rows,
  title,
  datasets,
  has_background_color,
  no_padding,
  no_header,
}: TableProps): ReactElement {
  const [datasetToUse, setDatasetToUse] = useState<Record<string, unknown>[]>(defaultRows)
  const firstColumns: ColumnType[] = columns

  useEffect(() => {
    setDatasetToUse(getCalculatedRowsForFirstIndexInDataset(rows, columns, datasets))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets])

  if (!datasets.length && !datasetToUse) {
    return <></>
  }

  return (
    <div className={styles.Table}>
      {title && <div className={styles.Table_Title}>{title}</div>}
      <div
        className={dynamicClassName({
          [styles.Table__backgroundColor]: has_background_color,
          [styles.Table_no_padding]: no_padding,
        })}
      >
        <TableProvider columns={firstColumns} data={datasetToUse}>
          <Grid container direction="column">
            <Grid container direction="row" />
            <CustomTable>
              {!no_header && <TableHead />}
              <TableBody />
              <TableFooter />
            </CustomTable>
          </Grid>
        </TableProvider>
      </div>
    </div>
  )
}
