import React, { ReactNode } from 'react'

import SelectButton from 'api/SelectButton/SelectButton'
import { TimeSeriesSettingValue } from 'api/digitalTwin/digitalTwin.api'
import { TablePropsFactoryFnProps } from 'ui/components/TimeSeriesDataTable/types'
import DatasetTableHeader from 'ui/uiConfig/components/DatasetTable/DatasetTableHeader'

import { TableCellProps, TextFieldProps } from '@mui/material'

import getRowIndex from '../TimeSeriesDataTable/utils/getRowIndex'

import { getSettingData } from './TimeSeriesSettingTable'
import styles from './TimeSeriesSettingTable.module.less'
import { 
  CreateHeaderOptions, 
  CreateMUITableBodyCellEditTextFieldPropsOptions, 
  CreateMUITableBodyCellPropsOptions, 
  EditableColumnFactory, 
  FactoryDependencies,
} from './types'

const ARROW_KEY_CODES = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

export default function createEditableColumnFactory({
  datasets,
  chartItems,
  invalidValuesIndex,
  mouseDownValue,
  mouseOverIndex,
  focusedCell,
  times,
  settingsData,
  t,
  copyDatasetToSettingValues,
  handleMouseEvent,
  setFocusedCell,
  setMouseOverIndex,
  setMouseDownValue,
  updateTimeSeriesValues,
  setSettingData,
  cellRefs,
}: FactoryDependencies): EditableColumnFactory {
  return {
    Header: ({title, returnId, unit, index = 0 }: CreateHeaderOptions) => {
      return (): ReactNode => (
        <>
          <DatasetTableHeader title={title} unit={unit} />
          {datasets.filter((dataset) => dataset.return_id !== returnId).length > 0 && (
            <div className={styles.TimeSeriesSettingTable_CopyFromButtonContainer}>
              <SelectButton
                icon={'fal fa-copy'}
                link
                noPadding
                menuItems={
                  datasets
                    .filter((dataset) => dataset.return_id !== returnId)
                    .map((dataset) => ({
                      label: chartItems.find((item) => item.data_id === dataset.return_id)?.title,
                      onClick: () => copyDatasetToSettingValues(dataset, index),
                    }))
                    .filter((item) => item.label !== undefined) as { label: string; onClick: () => void }[]
                }
              >
                {t('Copy from')}
              </SelectButton>
            </div>
          )}
        </>
      )
    },
    muiTableBodyCellProps: ({ index }: CreateMUITableBodyCellPropsOptions) => {
      return ({ row, table, column }: TablePropsFactoryFnProps): TableCellProps => ({
        className: invalidValuesIndex?.includes(getRowIndex(row, table.getState().pagination))
          ? styles.TimeSeriesSettingTable_InvalidInput
          : mouseDownValue !== null &&
            mouseOverIndex !== null &&
            row.index >= Math.min(mouseDownValue.rowIndex, mouseOverIndex) &&
            row.index <= Math.max(mouseDownValue.rowIndex, mouseOverIndex)
            ? styles.TimeSeriesSettingTable_HighlighedInput
            : undefined,
        onKeyDown: (event: React.KeyboardEvent<HTMLTableCellElement>) => {
          let dataSetIndex = 0
          const columnIndex = datasets.findIndex((dataset) => dataset.return_id === column.id)
          const newFocusedCell = { ...focusedCell }

          const { pageIndex, pageSize } = table.getState().pagination
          const totalNumberOfRows = times.length
          const currentPagePageSize = Math.min(pageSize, totalNumberOfRows - pageIndex * pageSize)

          if (ARROW_KEY_CODES.includes(event.key)) {
            event.preventDefault()
            setFocusedCell(newFocusedCell)

            if (event.key === 'ArrowUp') {
              newFocusedCell.row = Math.max(0, row.index - 1)
              newFocusedCell.column = column.id
            }
            if (event.key === 'ArrowDown') {
              newFocusedCell.row = Math.min(currentPagePageSize - 1, row.index + 1)
              newFocusedCell.column = column.id
            }
            if (event.key === 'ArrowLeft') {
              dataSetIndex = Math.max(0, columnIndex - 1)
              newFocusedCell.column = datasets[dataSetIndex].return_id
              newFocusedCell.row = row.index
            }
            if (event.key === 'ArrowRight') {
              dataSetIndex = Math.min(datasets.length - 1, columnIndex + 1)
              newFocusedCell.column = datasets[dataSetIndex].return_id
              newFocusedCell.row = row.index
            }
          }
        },
        onMouseDown: (event: React.MouseEvent<HTMLTableCellElement>) => {
          const settingData = getSettingData(settingsData, index)
          if (settingData === undefined) {
            return
          }

          const datasetIndex = getRowIndex(row, table.getState().pagination)
          const settingValue = settingData.value as TimeSeriesSettingValue
          const times: ISODateTime[] = Object.keys(settingValue) as ISODateTime[]
          const value = settingValue[times[datasetIndex]].value

          if (value !== null) {
            handleMouseEvent(event, { rowIndex: row.index, value })
          }
        },
        onMouseOver: () => {
          if (mouseDownValue !== null) {
            setMouseOverIndex(row.index)
          }
        },
        onDrop: (event: React.MouseEvent<HTMLTableCellElement>) => {
          handleMouseEvent(event, null)
          setMouseOverIndex(null)
        },
        onMouseUp: () => {
          const settingData = getSettingData(settingsData, index)
          if (settingData === undefined) {
            return
          }

          if (mouseDownValue === null || mouseDownValue.rowIndex === row.index) {
            setMouseDownValue(null)
            return
          }

          const newSettingValues = { ...settingData.value as TimeSeriesSettingValue }
          const times: ISODateTime[] = Object.keys(newSettingValues) as ISODateTime[]
          for (
            let i = Math.min(mouseDownValue.rowIndex, row.index);
            i < Math.max(mouseDownValue.rowIndex, row.index) + 1;
            i++
          ) {
            const datasetIndex = getRowIndex(i, table.getState().pagination)
            newSettingValues[times[datasetIndex]] = { id: newSettingValues[times[i]].id, value: mouseDownValue.value }
          }

          updateTimeSeriesValues(newSettingValues, index)
        },
      })
    },
    muiTableBodyCellEditTextFieldProps: ({ index, returnId }: CreateMUITableBodyCellEditTextFieldPropsOptions) => {
      return ({ column, row, table }: TablePropsFactoryFnProps): TextFieldProps => ({
        variant: 'standard',
        type: 'number',
        inputRef: cellRefs.current[`${column.id}-${row.index}`] || (cellRefs.current[`${column.id}-${row.index}`] = React.createRef()),
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          const datasetIndex = getRowIndex(row, table.getState().pagination)
          const settingData = getSettingData(settingsData, index)

          if (typeof settingData?.value === 'object' && column.id === returnId) {
            const id = settingData.value ? settingData.value[times[datasetIndex]].id : undefined

            const valueAsNumber = event.target.value === '' ? null : Number(event.target.value)

            if (Number.isNaN(valueAsNumber)) {
              return
            }

            const newSettingData = {
              ...settingData,
              value: {
                ...settingData.value,
                [times[datasetIndex]]: { id, value: valueAsNumber },
              },
            }
            setSettingData(newSettingData)
          }
        },
        onBlur: () => {
          setMouseDownValue(null)
        },
        onPaste: (event: React.ClipboardEvent) => {
          const settingData = getSettingData(settingsData, index)
          if (settingData === undefined) {
            return
          }

          if ((event.clipboardData.getData('text').match(/\n/g) || []).length < 2) {
            return
          }

          const newSettingValues = { ...settingData.value as TimeSeriesSettingValue }
          const times: ISODateTime[] = Object.keys(newSettingValues) as ISODateTime[]

          const datasetIndex = getRowIndex(row, table.getState().pagination)

          const newData = event.clipboardData.getData('text')
          const cleanedData = newData.split(/(\r\n)+$/)[0]
          const rows = cleanedData.split('\n')
          rows.forEach((r, rowIndex) => {
            const cells = r.split('\t')
            const parsedValue = parseFloat(cells[0].replace(',', '.'))
            const value = isNaN(parsedValue) ? 0 : parsedValue
            const i = datasetIndex + rowIndex

            if (i < times.length) {
              newSettingValues[times[i]] = { id: newSettingValues[times[i]].id, value }
            }
          })
          updateTimeSeriesValues(newSettingValues, index)
        },
      })
    },
  }
}