import React, { ReactElement, useMemo } from 'react'

import { getOptjobAsObject, OptJobStatus, OptJobType, useLatestOptJobStatus } from 'api/optJobs/optJobs.api'
import ModuleHeaderButton from 'ui/components/ModuleHeaderButton/ModuleHeaderButton'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import { optJobIsDone } from 'helpers/optimize.helper/optimize.helper'

import styles from './OptimizeButton.module.less'

type OptimizeButtonProps = {
  creatingOptJob?: boolean
  disabled?: boolean
  isValid?: boolean
  onClick?: () => void
  sandboxProjectId?: number
  style?: React.CSSProperties
  type: OptJobType
  optimizeLabel?: string
  subtypes?: string[]
}

export function buttonTranslationMapping(optimizeLabel?: string, status?: OptJobStatus): string | undefined {
  if (status === null || status === undefined || status === 'Finished') {
    return optimizeLabel ?? i18n.t('Optimize')
  }
  const map: { [key: string]: string } = {
    Created: i18n.t('Fetching optimization data'),
    Queued: i18n.t('Preparing optimization'),
    Running: i18n.t('Optimizing'),
    Failed: i18n.t('Failed'),
    Unsolvable: i18n.t('Unsolvable'),
  }
  return map[status]
}

export function OptimizeButton({
  creatingOptJob,
  disabled,
  isValid,
  onClick,
  sandboxProjectId,
  style,
  type,
  optimizeLabel,
  subtypes,
}: OptimizeButtonProps): ReactElement {
  const { t } = useTranslation()
  const { data: latestOptJob, status: fetchingLatestJobStatus } = useLatestOptJobStatus({
    sandboxProjectId,
    type,
    subtypes,
  })
  const firstLatestOptJob = getOptjobAsObject(latestOptJob)

  const labelOverride = useMemo(
    () => (fetchingLatestJobStatus === 'loading' ? t('Fetching optimization status') : undefined),
    [fetchingLatestJobStatus, t]
  )

  return (
    <ModuleHeaderButton
      icon="fal fa-redo-alt"
      loading={creatingOptJob || fetchingLatestJobStatus === 'loading' || !optJobIsDone(firstLatestOptJob)}
      disabled={!optJobIsDone(firstLatestOptJob) || !isValid || disabled ? true : false}
      type="submit"
      style={style}
      onClick={onClick}
    >
      <div className={styles.OptimizeButton_UpdateButtonTextContainer}>
        <div className={styles.OptimizeButton_UpdateButtonText}>
          {labelOverride ?? buttonTranslationMapping(optimizeLabel, firstLatestOptJob?.status)}
        </div>
      </div>
    </ModuleHeaderButton>
  )
}
