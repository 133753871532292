import React, { ReactElement, ReactNode } from 'react'

import { postStat } from 'api/stats/stats.api'
import { dynamicClassName } from 'styles/helper'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps } from 'ui/uiConfig/factory'

import { Tooltip } from '@mui/material'

import styles from './Tabs.module.less'

type TabItem = {
  title: string
  children_ids: number[]
  tooltip?: string
}

type TabStyles = `tab` | `toggle`
type TabsProps = {
  sticky?: boolean
  style?: TabStyles
  items: TabItem[]
  datasets: Dataset[]
  children: ReactNode[]
  paddingTop?: boolean
}

const defaultProps: Omit<TabsProps, `datasets` | `children`> = {
  style: `tab`,
  items: [
    {
      title: `Tab 1`,
      children_ids: [],
      tooltip: ``,
    },
    {
      title: `Tab 2`,
      children_ids: [],
      tooltip: ``,
    },
  ],
}

function appendPropsWithDataset(datasetInstruction: DatasetInstruction, previousProps: TabsProps): TabsProps {
  return {
    ...previousProps,
    style: `tab`,
    items: [
      ...(previousProps?.items || []),
      {
        title: datasetInstruction.return_id ?? ``,
        children_ids: [],
        tooltip: ``,
      },
    ],
  }
}

registerUiConfigComponent(`tabs`, Tabs)
registerAppendPropsWithDatasets(`tabs`, appendPropsWithDataset)
registerDefaultProps(`tabs`, defaultProps)

export default function Tabs({ items, children, sticky, style, paddingTop }: TabsProps): ReactElement {
  const [activeTab, setActiveTab] = React.useState(0)
  const childrenIds = new Set(items?.[activeTab]?.children_ids ?? [])
  const visibleChildren = children.filter((child) => 
    React.isValidElement(child) && childrenIds.has(child?.props?.id))
  const visibleChildrenWithLineBreaks = [<br key="lineBreak_-1" />].concat(
    ...visibleChildren.map((child, index) => [child, <br key={`lineBreak_${index}`} />] as ReactElement[])
  )

  const isToggle = style === `toggle`

  if (!items?.length) {
    return <></>
  }

  return (
    <>
      <div
        className={dynamicClassName({
          [styles.Tabs]: true,
          [styles.Tabs__sticky]: !!sticky,
          [styles.Tabs__padding]: !!paddingTop,
        })}
      >
        {!isToggle && <div className={styles.Tabs_BorderBottom} />}

        {items.map((item, index) => (
          <div
            key={`tab_${index}`}
            className={dynamicClassName({
              [styles.Tabs_Tab]: true,
              [styles.Tabs_Tab_Toggle]: isToggle,
              [styles.Tabs_Tab_Toggle__firstElement]: index === 0 && isToggle,
              [styles.Tabs_Tab_Toggle__lastElement]: index === items.length - 1 && isToggle,
              [styles.Tabs_Tab_Toggle__active]: isToggle && index === activeTab,
            })}
            onClick={() => {
              postStat('tab', `navigate-to-tab-${item.title ?? index}`)
              setActiveTab(index)
            }}
          >
            <div
              className={dynamicClassName({
                [styles.Tabs_Tab_Title]: true,
                [styles.Tabs_Tab_Title__active]: index === activeTab,
              })}
            >
              {item.tooltip ? (
                <Tooltip title={item.tooltip} arrow>
                  {<span>{item.title}</span>}
                </Tooltip>
              ) : (
                item.title
              )}

              {index === activeTab && !isToggle && <div className={styles.Tabs_Tab_BorderBottom} />}
            </div>
          </div>
        ))}
      </div>
      <div>{visibleChildrenWithLineBreaks}</div>
    </>
  )
}
