import React, { ReactElement, useState } from 'react'

import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import elplanStore from 'store/elplan/elplan'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import { getBidColorBasedOnOrderAndType } from 'ui/uiConfig/components/wildcardItems.helper'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import BidModal from '../BidModal/BidModal'
import { FormattedBidWithIdAndTitle, getScenarioName } from '../DayAheadBidManagement.helper'
import { isArray } from 'helpers/global.helper/global.helper'

type BlockBidProps = {
  blockBids: FormattedBidWithIdAndTitle[]
  createBid: (bid: FormattedBidWithIdAndTitle) => void
  updateBid: (bid: FormattedBidWithIdAndTitle) => void
  deleteBid: (bid: FormattedBidWithIdAndTitle) => void
  view?: string
}
export default function BlockBidTable({
  blockBids,
  createBid,
  updateBid,
  deleteBid,
  view,
}: BlockBidProps): ReactElement {
  const { t } = useTranslation()

  const { activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentBlockBid, setCurrentBlockBid] = useState<FormattedBidWithIdAndTitle>()
  const elplanSnap = useSnapshot(elplanStore)

  function editBlockBid(currentBlockBid: FormattedBidWithIdAndTitle): void {
    setCurrentBlockBid(currentBlockBid)
    setModalIsOpen(true)
  }

  function closeModal(): void {
    setCurrentBlockBid(undefined)
    setModalIsOpen(false)
  }

  function getType(type: string): 'block' | 'spot' | 'profile' | undefined {
    switch (type) {
      case 'blockbid':
        return 'block'
      case 'spotbid':
        return 'spot'
      case 'profilebid':
        return 'profile'
    }
  }

  const weekView = view === 'week'

  const columns = [
    {
      accessor: 'color',
      header: '',
      Cell: (tableContent: any) => {
        const order = tableContent.row.original.block_order
        const type = tableContent.row.original.type

        const color = getBidColorBasedOnOrderAndType(type, order)
        return <Icon color={color} icon={'fa fa-circle'} />
      },
    },
    {
      accessor: 'title',
      Header: t('Block bid'),
    },
    {
      accessor: 'name',
      Header: t('Name'),
    },
    {
      accessor: 'date',
      Header: t('Date'),
    },
    {
      accessor: 'displayStartTime',
      Header: t('Start time'),
    },
    {
      accessor: 'displayEndTime',
      Header: t('End time'),
    },
    {
      accessor: 'volume',
      Header: `${t('Volume')} (MW)`,
      Cell: (tableContent: any) => {
        if (isArray(tableContent.row.original.volume) && tableContent.row.original.volume.length > 1) {
          return '-'
        }
        return (
          tableContent.row.original.volume.reduce((acc: number, value: number) => acc + value, 0) /
          tableContent.row.original.volume.length
        )
      },
    },
    {
      accessor: 'price',
      Header: `${t('Avg price')} (${elplanSnap.currency.toLocaleUpperCase()}/MWh)`,
    },
    {
      accessor: 'editable',
      Header: t('Edit'),
      Cell: (tableContent: any) => (
        <Button link icon={'fal fa-pen'} disabled={weekView} onClick={() => editBlockBid(tableContent.row.original)}>
          {''}
        </Button>
      ),
    },
    {
      accessor: 'clear',
      Header: t('Remove'),
      Cell: (tableContent: any) => (
        <Button link icon={'fal fa-trash'} onClick={() => deleteBid(tableContent.row.original)}>
          {''}
        </Button>
      ),
    },
  ]

  if (elplanSnap.scenarios.length > 1) {
    const scenarioColumn = {
      accessor: 'scenarioId',
      Header: t('Scenario'),
      Cell: (tableContent: any) => (
        <span>{getScenarioName(tableContent.row.original.scenarioId, digitalTwin?.translations)}</span>
      ),
    }

    // Insert scenario column between name and date
    columns.splice(3, 0, scenarioColumn)
  }

  return (
    <div>
      {weekView && (
        <InfoBanner
          text={t('You cannot edit blockbids when in week view, please change to day view to edit')}
          style={'warning'}
          inlineBlock
        />
      )}
      <DefaultTable columns={columns} data={blockBids} initialPageSize={20} />
      {modalIsOpen && (
        <BidModal
          closeModal={closeModal}
          bid={currentBlockBid}
          editBid={(blockBid: FormattedBidWithIdAndTitle) => {
            if (currentBlockBid?.fake) {
              createBid(blockBid)
            } else {
              updateBid(blockBid)
            }
          }}
          type={getType(currentBlockBid?.type ?? 'blockbid')}
          isEditing
        />
      )}
    </div>
  )
}
