import React, { memo, ReactElement, SyntheticEvent } from 'react'

import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow'

type SelectProps = {
  value: string
  handleId: string
  nodeId: string
}

type CustomNodeProps = {
  id: string
  data: {
    selects: Record<string, string>
  }
}

const options = [
  {
    value: 'smoothstep',
    label: 'Smoothstep',
  },
  {
    value: 'step',
    label: 'Step',
  },
  {
    value: 'default',
    label: 'Bezier (default)',
  },
  {
    value: 'straight',
    label: 'Straight',
  },
]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Select({ value, handleId, nodeId }: SelectProps): ReactElement {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const onChange = (evt: SyntheticEvent): void => {
    const { nodeInternals } = store.getState()
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          const target = evt.target as HTMLSelectElement
          node.data = {
            ...node.data,
            selects: {
              ...node.data.selects,
              [handleId]: target.value,
            },
          }
        }

        return node
      })
    )
  }

  return (
    <div className="custom-node__select">
      <div>Edge Type</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <Handle type="source" position={Position.Bottom} id={handleId} />
    </div>
  )
}

function CustomNode({ id, data }: CustomNodeProps): ReactElement {
  return (
    <>
      <div className="custom-node__header">
        This is a <strong>custom node</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.selects).map((handleId) => (
          <Select 
            key={handleId} 
            nodeId={id} 
            value={data.selects[handleId]} 
            handleId={handleId} />
        ))}
      </div>
    </>
  )
}

export default memo(CustomNode)
