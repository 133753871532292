import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Unit } from 'api/units/units.api'
import { useSettingChange } from 'hooks/apiHooks/apiHooks'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import FormRow from 'ui/components/FormRow/FormRow'
import { SettingFormData } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/ObjectPropertySettingForm'
import { SettingDateTimePicker } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/SettingDateTimePicker/SettingDateTimePicker'
import SettingValueInput from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/SettingValueInput/SettingValueInput'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Datetime from 'utils/datetime/datetime'

import { TextField, MenuItem, Grid, DialogActions, Button, Icon } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { getDisplayName } from 'helpers/global.helper/global.helper'
import { isBinarySetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { DateTimePickerRange, getAlternativeValue } from 'helpers/settingsModal.helper/settingsModal.helper'

import styles from './DeviationSettingsModal.module.less'

type DeviationSettingsSelectRowProps = {
  label: string
  id: string
  value: unknown
  defaultValue?: string | number
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  choices: DeviationPropertyChoice[]
  disabled?: boolean
  icon?: string
}

export function DeviationSettingsSelectRow({
  label,
  id,
  value,
  defaultValue = '',
  onChange,
  choices,
  disabled = false,
  icon = 'fal fa-cog',
}: DeviationSettingsSelectRowProps): ReactElement {
  return (
    <FormRow icon={icon}>
      <TextField
        margin="normal"
        id={id}
        name={id}
        select
        label={label}
        variant="outlined"
        fullWidth
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        SelectProps={{
          MenuProps: {
            className: styles.PropertyMenu_Menu,
          },
        }}
        disabled={disabled}
      >
        {choices.map((choice) => (
          <MenuItem key={choice.value} value={choice.value}>
            {choice.name}
          </MenuItem>
        ))}
      </TextField>
    </FormRow>
  )
}
type DeviationPropertyChoice = {
  value: number | string
  name: string
  unitId?: string
}

type CreateNewDeviationDialogProps = {
  units: Unit[]
  objectProperties: ObjectProperty[]
  handleClose: () => void
  permissions: SettingsPermissions
  startTimeAndEndTimeRange?: DateTimePickerRange
  isBaseValueSetting: boolean
}

export function CreateNewDeviationDialog({
  units,
  objectProperties,
  handleClose,
  permissions,
  startTimeAndEndTimeRange,
  isBaseValueSetting,
}: CreateNewDeviationDialogProps): ReactElement {
  const { t } = useTranslation()
  const location = useLocation()
  const locationSandbox = location.pathname.includes(`/sandbox`)

  // Form related stuff
  const methods = useForm<{
    start_time: moment.Moment
    end_time: moment.Moment
    value: string | undefined | number
  }>({
    mode: `onChange`,
    shouldUnregister: false,
    defaultValues: {
      start_time: moment().startOf('day'),
      end_time: moment().startOf('day').hours(23),
      value: undefined,
    },
  })
  const { handleSubmit, setValue } = methods

  const now = useMemo(() => moment(), [])
  const [unit, setUnit] = useState<unknown>('')
  const [property, setProperty] = useState<unknown>('')
  const [cause, setCause] = useState<string | null>('')
  const [historicalEditConfirmOpen, setHistoricalEditConfirmOpen] = useState(false)
  const [cachedHistoricalSettingsData, setCachedHistoricalSettingsData] = useState<SettingFormData>({})

  const emptyObjectProperty: ObjectProperty = useMemo(() => ({
    id: 0,
    name: ``,
    parent: null,
    valid_range: {
      lower: 0,
      bounds: `[]`,
    },
    default_value: 0,
    classifications: ['base', 'deviation'],
    category: null,
    active_settings: null,
    current_active_value: null,
    current_base_value: null,
    latest_updated: Datetime.getISONow(),
    latest_updated_base: Datetime.getISONow(),
    latest_update_type: `changed`,
    measurement_unit: null,
    belongs_to_opt_model: 0,
    value_mappings: [{
      value: 0,
      text_sv: ``,
      text_en: ``,
    }],
  }), [])
  const [ objProp, setObjProp ] = useState<ObjectProperty>(emptyObjectProperty)

  const unitChoices: DeviationPropertyChoice[] = useMemo(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    units = units?.sort((a, b) => getDisplayName(a).localeCompare(getDisplayName(b)))
    return units?.map(unit => {
      return {
        name: getDisplayName(unit),
        value: unit.id,
        unitId: unit.name,
      }
    })
    ?? []
  }, [units])

  const proptertyChoices = useMemo(() => {
    if (unit != '') {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      objectProperties = objectProperties?.sort((a, b) => getDisplayName(a).localeCompare(getDisplayName(b)))
      return objectProperties?.filter(property => property.parent?.id == unit)?.map(property => {
        return {
          name: getDisplayName(property),
          value: property.id,
        }
      })
    }
    return objectProperties?.map(property => {
      return {
        name: getDisplayName(property),
        value: property.id,
        unitId: property.unit_id,
      }
    })
  }, [objectProperties, unit])

  useEffect(() => {
    if (property != '' && unit != '') {
      const objectProperty = objectProperties?.find(prop => prop.id == property)
      if (objectProperty) {
        setObjProp(objectProperty)
        const newValue = (
          isBinarySetting(objectProperty.name)
            ? getAlternativeValue(objectProperty).value
            : undefined
        )
        setValue(
          'value',
          newValue
        )
      }
    } else {
      setObjProp(emptyObjectProperty)
      setValue('value', undefined)
    }
  }, [emptyObjectProperty, objectProperties, property, unit, setValue])

  const { add } = useSettingChange()

  async function onSubmit(data: SettingFormData): Promise<void> {

    const isHistoricalSetting = moment(data?.end_time).isBefore(now)

    const userHasConfirmedNewHistoricalSettings = !!data?.userHasConfirmedNewHistoricalSettings
    if (userHasConfirmedNewHistoricalSettings) {
      delete data.userHasConfirmedNewHistoricalSettings
    }

    if (permissions.canEditHistoric
        && isHistoricalSetting
        && !userHasConfirmedNewHistoricalSettings
        && !locationSandbox) {
      setCachedHistoricalSettingsData(data)
      setHistoricalEditConfirmOpen(true)
      return
    }

    await add(
      objProp.id,
      data.start_time,
      data.end_time,
      Number(data.value),
      isBaseValueSetting,
      cause,
      data.classification
    ).then(() => {
      handleClose()
    })
  }

  return (
    <>
      <FormProvider {...methods}>
        <form aria-label="form" onSubmit={handleSubmit(onSubmit)}>
          <DeviationSettingsSelectRow
            label={t(`Unit`)}
            id="unit"
            value={unit}
            onChange={(value) => {
              setUnit(value?.target?.value ?? '')
              setProperty('')
            }}
            choices={unitChoices}
          />
          {
            unit != '' &&
          <DeviationSettingsSelectRow
            label={t(`Property`)}
            id="objectProperty"
            value={property}
            onChange={(value) => {setProperty(value?.target?.value ?? '')}}
            choices={proptertyChoices}
          />
          }
          { objProp && objProp.id != 0 && (
            <>
              <FormRow icon="fal fa-alarm-clock">
                <Grid item xs={12} md={6} lg={8} style={{paddingLeft: 0}}>
                  <SettingDateTimePicker
                    minValue={startTimeAndEndTimeRange?.startTime.min}
                    maxValue={startTimeAndEndTimeRange?.endTime.max}
                    startLabel={t(`From`)}
                    endLabel={t(`To`)}
                    allowEmpty
                  />
                </Grid>
                {/* NOTE: Grundvärde */}
                <Grid item xs={12} md={12} lg={4}>
                  <SettingValueInput objectProperty={objProp} />
                </Grid>
              </FormRow>
              {/* NOTE: Cause */}
              <FormRow icon="fal fa-cog">
                <TextField
                  margin="normal"
                  id={'cause'}
                  name={'cause'}
                  label={t(`Cause`)}
                  variant="outlined"
                  fullWidth
                  value={cause}
                  onChange={(value) => {setCause(value?.target?.value ?? '')}}
                />
              </FormRow>
            </>
          )}
          {/* NOTE: Cancel/Save buttons */}
          <DialogActions>
            <Grid item container justifyContent="flex-end" spacing={1} xs={8}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  {t(`Cancel`)}
                </Button>
              </Grid>
              {
                objProp.id != 0 &&
              <Grid item>
                <Button
                  type='submit'
                  variant="contained"
                  color="primary"
                >
                  <Icon
                    className={'fal fa-plus'}
                    fontSize="small"
                    style={{ marginRight: 8 }}
                  />
                  {t(`Save`)}
                </Button>
              </Grid>
              }
            </Grid>
          </DialogActions>
        </form>
      </FormProvider>
      <ConfirmDialog
        open={historicalEditConfirmOpen}
        onClose={() => {
          setHistoricalEditConfirmOpen(false)
        }}
        onConfirm={() => {
          onSubmit({
            ...cachedHistoricalSettingsData,
            userHasConfirmedNewHistoricalSettings: true,
          })

          setHistoricalEditConfirmOpen(false)
        }}
        textObject={{
          title: t(`Do you really want to create a historical setting?`),
          text: t(
            `Note that the setting you're creating applies to a period back in time where follow-up has already been run. To be included in the follow-up, the follow-up for the setting's period needs to be rerun. Do you still want to create the setting?`
          ),
        }}
      />
    </>
  )
}
