import React, { ReactElement, useState } from 'react'

import { postStat } from 'api/stats/stats.api'
import { dynamicClassName } from 'styles/helper'
import DeviationSettings from 'ui/uiConfig/anchorComponents/FollowupProduction/DeviationSettings/DeviationSettings'

import { t } from 'i18next'
import { Popover } from 'react-aria-components'

import { useHasPermission } from 'helpers/global.helper/global.helper'

import styles from './OptimizationFailedGuide.module.less'
type OptimizationFailedGuideProps = {
  startTime?: ISODateTime
  endTime?: ISODateTime
  popup?: boolean //open content of the guide in a popup
}

//For further development of this guide
//TODO: Add links to module redirections
export default function OptimizationFailedGuide({
  startTime,
  endTime,
  popup,
}: OptimizationFailedGuideProps): ReactElement {
  const [learnMoreIsOpen, setLearnMoreIsOpen] = useState<boolean>(false)

  const learnMoreRef = React.useRef<HTMLAnchorElement>(null)

  return (
    <div className={styles.OptimizationFailedGuide}>
      <i
        className={dynamicClassName({
          ['fa fa-question']: true,
          [styles.OptimizationFailedGuide_Icon]: true,
        })}
      />
      <a
        ref={learnMoreRef}
        data-trigger="learn-more"
        className={styles.OptimizationFailedGuide_Link}
        onClick={() => {
          postStat('optimization_failed_guide', 'click')
          setLearnMoreIsOpen(!learnMoreIsOpen)
        }}
      >
        {t('Why did the optimization fail?')}
      </a>
      {popup ? (
        <Popover
          className={styles.OptimizationFailedGuide_Popup}
          trigger={'learn-more'}
          triggerRef={learnMoreRef}
          isOpen={learnMoreIsOpen}
          style={{ zIndex: 100 }} //Need to be here to allow DeviationSettings popup to pop up
        >
          <GuideContent startTime={startTime} endTime={endTime} />
        </Popover>
      ) : (
        learnMoreIsOpen && <GuideContent startTime={startTime} endTime={endTime} />
      )}
    </div>
  )
}

type GuideContent = {
  startTime: ISODateTime | undefined
  endTime: ISODateTime | undefined
}

function GuideContent({ startTime, endTime }: GuideContent): ReactElement {
  const [settingExamplesIsOpen, setSettingExamplesIsOpen] = useState<boolean>(false)
  const [dataExamplesIsOpen, setDataExamplesIsOpen] = useState<boolean>(false)

  const hasDbSDataExplorerPermission = useHasPermission('view_dbs_data_explorer')

  return (
    <div className={styles.GuideContent}>
      <b>{`${t('An optimization failure can be caused by multiple factors')}:`}</b>
      <br />
      <br />
      {`1. ${t('Check that all deviation settings during the period are correct and ensure that there are no conflicting settings (you can see the current deviation settings by clicking the button below or in the Deviation Settings module')}`}
      <br />
      <a onClick={() => setSettingExamplesIsOpen(!settingExamplesIsOpen)} className={styles.GuideContent_Link}>
        {t('Common cases of conflicting settings')}
        <i
          className={dynamicClassName({
            ['fal fa-chevron-up']: settingExamplesIsOpen,
            ['fal fa-chevron-down']: !settingExamplesIsOpen,
            [styles.GuideContent_Icon]: true,
          })
          }
        />
      </a>

      {settingExamplesIsOpen && (
        <ul>
          <li>{t('There is a forced activity while the unit is set as unavailable.')}</li>
          <li>{t('Min- and max-values overlap and cannot meet the requirements for an optimization.')}</li>
          <li>{t('There is a forced production that exceeds the demand.')}</li>
          <li>{t('Start and end levels for the accumulator must be within the unit`s maximum and minimum range.')}</li>
          <li>
            {t(
              'There is too little production (too many unavailabilities) that prevents the optimization from meeting the demand.'
            )}
          </li>
          <li>{t('The start and end level of an accumulator also affect the total demand.')}</li>
        </ul>
      )}
      {startTime && endTime && (
        <span className={styles.GuideContent_DeviationSettingsSpan}>
          <DeviationSettings startTime={startTime} endTime={endTime} />
        </span>
      )}
      <br />
      {`2. `}
      {hasDbSDataExplorerPermission && (
        <>
          {t(
            'You can review the incoming measurement data of the plant by clicking on Review data at the top of the page. There you can analyze and ensure that all input needed for the optimization is available in Aurora by Sigholm.'
          )}
          <br />
          <br />
        </>
      )}
      {t(
        'You can also investigate the outcome data in the Follow-up - production module to see if the current outcome looks reasonable or not.'
      )}
      <br />
      <a onClick={() => setDataExamplesIsOpen(!dataExamplesIsOpen)} className={styles.GuideContent_Link}>
        {t('Common measurement issues that affect the optimization')}
        <i
          className={dynamicClassName({
            ['fal fa-chevron-up']: dataExamplesIsOpen,
            ['fal fa-chevron-down']: !dataExamplesIsOpen,
            [styles.GuideContent_Icon]: true,
          })
          }
        />
      </a>
      {dataExamplesIsOpen ? (
        <ul>
          <li>
            {t('For an optimization that applies in the future (E.g. Production plan)')}
            <ul>
              <li>{t('Check the start level of the accumulator and make sure it looks reasonable.')}</li>
              <li>{t('Recent measured demands might affect the forecasts.')}</li>
            </ul>
          </li>
          <li>
            {t('For an optimization that applies in the past (E.g. Follow-up)')}
            <ul>
              <li>{t('Check both the start and end level of the accumulator and make sure it looks reasonable.')}</li>
              <li>{t('Check for any missing measured data, specifcally demand or waste heat.')}</li>
            </ul>
          </li>
        </ul>
      ) : (
        <>
          <br /> <br />
        </>
      )}
      {t(
        'If everything looks good, there may be an error on our side - please create a bug report by clicking on Contact Us.'
      )}
    </div>
  )
}
