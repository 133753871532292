import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import EventStatus from 'views/EventsView/components/EventStatus/EventStatus'
import { EventStatus as EventStatusType } from 'views/EventsView/components/events.helper'

import styles from './StatusFlow.module.less'

export default function StatusFlow(): ReactElement {

  const { t } = useTranslation()

  const statusFlowContent = [
    {
      status: 'Draft',
      description: 'The event is inactive and is still in an edit mode. Settings are not active and will not affect the production plan.',
    },
    {
      status: 'Sent',
      description: 'The event is inactive but is submitted to be activated. Settings are not active and will not affect the production plan.',
    },
    {
      status: 'Active',
      description: 'The event is active and settings will apply and affect the production plan.',
    },
    {
      status: 'Inactive',
      description: 'The event is inactive and settings will not affect the production plan.',
    },
  ]

  return (
    <div className={styles.StatusFlow_Container}>
      <h3 className={styles.StatusFlow_Header}>{t('Status flow')}</h3>
      <p>{`${t('Every event has a status that will affect the production plan in different ways')}:`}</p>
      <div className={styles.StatusFlow_Statuses}>
        {statusFlowContent.map((status) => {
          return (
            <>
              <div className={styles.StatusFlow_Status}>
                <EventStatus key={status.status} status={status.status as EventStatusType} />
                <p>{t(status.description)}</p>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}
