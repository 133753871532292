import React, { ReactElement } from 'react'

import { Select as MuiSelect, InputLabel, MenuItem, FormHelperText } from '@mui/material'

type SelectItem = {
  value: string | number
  label: string
}

type SelectProps = {
  value?: string | number | string[]
  items: SelectItem[]
  label?: string
  error?: boolean
  fullWidth?: boolean
  variant?: `outlined` | `standard` | `filled`
  onChange: (value: string | number | string[]) => void
  multiple?: boolean
}

export default function Select({
  value = ``,
  onChange,
  label = ``,
  items = [],
  error = false,
  fullWidth = true,
  variant = `outlined`,
  multiple = false,
}: SelectProps): ReactElement {
  return (
    <>
      <MuiSelect
        fullWidth={fullWidth}
        error={!!error}
        aria-label={label}
        variant={variant}
        value={value}
        multiple={multiple}
        onChange={(e) => onChange(e.target.value)}
      >
        {label && <InputLabel>{label}</InputLabel>}

        {items.map(({ value, label }, index) => (
          <MenuItem value={value} key={`${index}_${value}`}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>

      {label && <FormHelperText>{label}</FormHelperText>}
    </>
  )
}
