import React, { ReactElement, useMemo, useState } from 'react'

import { useShiftHandovers, ShiftHandover } from 'api/shiftHandovers/shiftHandovers.api'
import { ShiftTeam, useShiftTeams } from 'api/shiftTeams/shiftTeams.api'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import { EmojiIcon } from 'ui/components/EvaluationEmojiIcon/EvaluationEmojiIcon'

import { Grid, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'

import EditModal from '../EditModal/EditModal'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'
import { getDisplayName } from 'helpers/global.helper/global.helper'

import ReportsDatePicker from './components/ReportsDatePicker'

export const shiftHandoverFilter = `shiftHandoverFilter`

function findShiftTeam(shiftTeams: ShiftTeam[], id: number): string {
  const foundShiftTeam = shiftTeams.find((item) => item.id === id)
  return foundShiftTeam ? getDisplayName(foundShiftTeam) : `Skiftlag`
}

export default function ShiftReportsWidget(): ReactElement {
  const { t } = useTranslation()
  const theme = useTheme()
  const [openHandover, setOpenHandover] = useState<ShiftHandover | undefined>()

  const [period, setPeriod] = useState({
    startTime: moment().subtract(1, `week`),
    endTime: moment(),
  })

  const [shiftTeamsFilter, setShiftTeamsFilter] = useState<number[]>([])
  const { data: shiftTeams } = useShiftTeams()
  const { data: shiftHandovers, status } = useShiftHandovers(period)

  const columns: Column<Record<string, unknown>>[] = [
    {
      accessor: `reportFiledAt`,
      Header: t(`Report submitted at`),
    },
    {
      accessor: `reportCreatedAt`,
      Header: t(`Report created at`),
    },
    {
      accessor: `scheduledShift`,
      Header: t(`Scheduled shift`),
    },
    {
      accessor: `endingShift`,
      Header: t(`Scheduled shift team`),
    },
    {
      accessor: `startingShift`,
      Header: t(`Next shift team`),
    },
    {
      accessor: `shiftAssessment`,
      Header: t(`Shift review`),
    },
  ]

  if (window.innerWidth > theme.breakpoints.values.sm) {
    columns.push({
      accessor: `manage`,
      Header: t(`Manage`),
    })
  }

  const tableData = useMemo(
    () =>
      (shiftHandovers || [])
        .filter(
          (item) =>
            !shiftTeamsFilter.includes(item.ending_shift_team) || !shiftTeamsFilter.includes(item.starting_shift_team)
        )
        .sort((a, b) => b.handover_time.localeCompare(a.handover_time))
        .map((item) => {
          const reportFiledAt = item.submitted_at
            ? formatDateTimeToLocalizedString(item.submitted_at, true)
            : t(`Not submitted`)

          const reportCreatedAt = item.created_at && formatDateTimeToLocalizedString(item.created_at, true)

          const scheduledShift = `${formatDateTimeToLocalizedString(item.start_time, true)} - ${moment(
            item.handover_time
          ).format(`HH:mm`)}`
          return {
            reportFiledAt,
            reportCreatedAt,
            scheduledShift,
            endingShift: findShiftTeam(shiftTeams || [], item.ending_shift_team),
            startingShift: findShiftTeam(shiftTeams || [], item.starting_shift_team),
            shiftAssessment: (
              <EmojiIcon key={`evaluation_status_` + item.id} evaluationStatus={item.evaluation_status} />
            ),
            manage: (
              <>
                {item.submitted_at ? (
                  <ContainedIconButton
                    color="primary"
                    icon={`fal fa-file-chart-line`}
                    onClick={(): void => {
                      setOpenHandover(item)
                    }}
                    key={`change_button_` + item.id}
                    label={t(`Show report`)}
                  />
                ) : (
                  <ContainedIconButton
                    color="primary"
                    icon={`fal fa-pen`}
                    label={t(`Edit report`)}
                    onClick={(): void => {
                      setOpenHandover(item)
                    }}
                    key={`change_button_` + item.id}
                  />
                )}
              </>
            ),
          }
        }),
    [shiftHandovers, shiftTeams, shiftTeamsFilter, t]
  )

  function updateShiftTeamsFilter(id: number): void {
    const shiftTeamIndex = shiftTeamsFilter.indexOf(id)
    const newShiftTeamsFilter = [...shiftTeamsFilter]
    shiftTeamIndex === -1 ? newShiftTeamsFilter.push(id) : newShiftTeamsFilter.splice(shiftTeamIndex, 1)

    setShiftTeamsFilter(newShiftTeamsFilter)
  }

  return (
    <>
      <Grid container justifyContent="space-between" className={shiftHandoverFilter}>
        <Grid item container xs={12}>
          <ReportsDatePicker
            handoverLoading={status === `loading`}
            selectedShifts={shiftTeamsFilter}
            updateShiftTeamsFilter={updateShiftTeamsFilter}
            shiftTeams={shiftTeams}
            handleDateChange={(period: Period) => {
              setPeriod(period)
            }}
            period={period}
          />
        </Grid>
      </Grid>
      <DefaultTable columns={columns} data={tableData} emptyTableText={t(`No shift reports found`)} />
      <EditModal
        open={openHandover !== undefined}
        handleClose={() => {
          setOpenHandover(undefined)
        }}
        handover={openHandover}
      />
    </>
  )
}
