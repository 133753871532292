import React, { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react'

import { OptJobType } from 'api/optJobs/optJobs.api'
import { OptProject } from 'api/optProjects/optProjects.api'
import { useSystem } from 'api/systems/systems.api'
import { Unit, useUnits } from 'api/units/units.api'
import { LoadingPlaceholderContainer, TextField } from 'ui/atoms'
import DeviationSettings from 'ui/uiConfig/anchorComponents/FollowupProduction/DeviationSettings/DeviationSettings'
import Datetime from 'utils/datetime/datetime'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../AuthContext/AuthContext'
import { DigitalTwinAccumulatorLevels } from '../DigitalTwinAccumulatorLevels/DigitalTwinAccumulatorLevels'
import { OptModelsAccumulatorLevels } from '../OptModelAccumulatorLevels/OptModelsAccumulatorLevels'
import { getSandboxOptType } from 'helpers/sandbox.helper/sandbox.helper'
import OptimizeUpdateContent from 'views/OptimizeView/components/OptimizeHeaderContent/components/OptimizeUpdateContent/OptimizeUpdateContent'
import { optimizeViewRange } from 'views/OptimizeView/store/optimizeViewStore'

import styles from './OptimizeForm.module.less'
import { OptimizeButton } from './components/OptimizeButton/OptimizeButton'

function getModelIds(
  activeSystem?: System,
  sandboxProject?: OptProject
): { digitalTwinUid?: number; optModelId?: number } {
  // If we are viewing a sandbox project, we never want to show info for the primary model of the system.
  // If sandbox project has a digital twin, and the system has an optmodel, we don't want to show optmodel accumulator levels for example.
  if (sandboxProject) {
    return {
      digitalTwinUid: sandboxProject.digital_twin,
      optModelId: sandboxProject.opt_model,
    }
  }

  // If activeSystem is undefined, we don't want to show any accumulator levels (optModelId and digitalTwinUid will be undefined).
  // This happens while the system is being loaded.
  return {
    digitalTwinUid: activeSystem?.primary_digital_twin?.uid,
    optModelId: activeSystem?.primary_opt_model?.id,
  }
}

export type UnitSettings = Record<string, { start_level: number; end_level: number }>

export type LevelValues = {
  unit: Unit
  startLevel: string
  endLevel: string
  minLevel: number
  maxLevel: number
  dynamicEndLevelAvailable: boolean
  dynamicEndLevelActive: boolean
  dynamicEndLevelValue: number
  dynamicEndLevelActiveId: number
  dynamicEndLevelValueId: number
}

type OptimizeFormProviderProps = PropsWithChildren<{
  optimize: (ackLevels: UnitSettings) => void
  digitalTwinUid?: number
  enableAutomaticOptimization?: boolean
  sandboxProject?: OptProject
  type: OptJobType
  optStartTime?: moment.Moment
  optEndTime?: moment.Moment
  creatingOptJob?: boolean
}>

export default function OptimizeFormProvider({
  enableAutomaticOptimization,
  optimize,
  optStartTime,
  optEndTime,
  type,
  creatingOptJob,
  sandboxProject,
}: OptimizeFormProviderProps): ReactElement {
  const { t } = useTranslation()
  const { systemId } = useAuth()
  const { data: activeSystem, status: loadingSystemStatus } = useSystem({ id: systemId })

  const [startTime, endTime] = useMemo(() => optimizeViewRange(false, { type: 'week' }), [])
  const { digitalTwinUid, optModelId } = useMemo(
    () => getModelIds(activeSystem, sandboxProject),
    [activeSystem, sandboxProject]
  )

  const { accLevelStartTime, accLevelEndTime } = useMemo(() => {
    const optOrSandboxStartTime = optStartTime ?? sandboxProject?.start_time
    const optOrSandboxEndTime = optEndTime ?? sandboxProject?.end_time
    return {
      accLevelStartTime: optOrSandboxStartTime ? Datetime.toISOString(optOrSandboxStartTime) : undefined,
      accLevelEndTime: optOrSandboxEndTime ? Datetime.toISOString(optOrSandboxEndTime) : undefined,
    }
  }, [optEndTime, optStartTime, sandboxProject?.end_time, sandboxProject?.start_time])

  // Fetch data from backend for current acc-leveles and alike
  const { data: units = [] } = useUnits({
    optModel: optModelId,
    unit_type: `Storage`,
    enabled: !digitalTwinUid,
  })

  const [isValid, setIsValid] = useState(true)
  const [unitSettings, setUnitSettings] = useState<UnitSettings>({})

  const triggerOptimize = useCallback(() => {
    optimize(unitSettings)
  }, [unitSettings, optimize])

  if (loadingSystemStatus === 'loading' || loadingSystemStatus === 'idle') {
    return <LoadingPlaceholderContainer />
  }

  const accumulatorLevels: ReactElement =
  (units.length > 0 || digitalTwinUid !== undefined) ? (
    <>
      {optModelId !== undefined && optModelId !== null && (
        <OptModelsAccumulatorLevels
          setIsValid={setIsValid}
          setUnitSettings={setUnitSettings}
          optModelId={optModelId}
        />
      )}
      {digitalTwinUid !== undefined && digitalTwinUid !== null && (
        <DigitalTwinAccumulatorLevels
          digitalTwinUid={digitalTwinUid}
          optStartTime={accLevelStartTime}
          optEndTime={accLevelEndTime}
          sandboxProjectId={sandboxProject?.id}
        />
      )}
    </>
  ) : <></>

  if(sandboxProject){
    return (
      <>
        {accumulatorLevels}
        <div className={styles.OptimizeForm_SandboxTextfieldsContainer}>
          <div className={styles.OptimizeForm_SandboxTextfield}>
            <TextField
              variant="outlined"
              disabled
              value={moment(sandboxProject.start_time).format(`YYYY-MM-DD HH:mm`)}
              label={t(`Start time`)}
              aria-label={t(`Start time`)}
              margin="normal"
            />
          </div>
          <div className={styles.OptimizeForm_SandboxTextfield}>
            <TextField
              variant="outlined"
              disabled
              value={moment(sandboxProject.end_time).format(`YYYY-MM-DD HH:mm`)}
              label={t(`End time`)}
              aria-label={t(`End time`)}
              margin="normal"
            />
          </div>
          <div className={styles.OptimizeForm_SandboxTextfield}>
            <TextField
              variant="outlined"
              label={t(`Sandbox type`)}
              disabled
              value={getSandboxOptType(sandboxProject.project_type)}
              aria-label={t(`Sandbox type`)}
              margin="normal"
            />
          </div>
        </div>
        <div className={styles.OptimizeForm_SandboxOptimizeButtonContainer}>
          <OptimizeButton
            type={'sandbox'}
            onClick={triggerOptimize}
            isValid={isValid}
            sandboxProjectId={sandboxProject?.id}
            creatingOptJob={creatingOptJob}
          />
        </div></>)
  }

  return (
    <div className={styles.OptimizeForm_OptimizeFormContainer}>
      {accumulatorLevels}
      {!digitalTwinUid && <DeviationSettings startTime={startTime} endTime={endTime} shrink/>}
      <div className={styles.OptimizeForm_OptimizeFormContainer_OptimizeUpdateContentContainer}>
        <OptimizeUpdateContent
          type={type || 'regular'}
          permission="run_regular_opt"
          enableAutomaticOptimization={enableAutomaticOptimization ?? true}
          isValid={isValid}
          onOptimize={triggerOptimize}
          creatingOptJob={creatingOptJob}
        />
      </div>

    </div>
  )
}
