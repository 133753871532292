import { apiClient } from 'api/apiClient/apiClient'
import alertStore from 'store/alert/alert'

import { useTranslation } from 'react-i18next'
import { UseMutationResult, useMutation, useQuery, useQueryClient } from 'react-query'

import { OrganizationRoleOptions } from 'helpers/customerAdmin.helper/customerAdmin.helper'
import { clone } from 'helpers/global.helper/global.helper'

export const USER_ADMIN_QUERY_KEY = `user_admin`
export const ORGANIZATION_ROLES = `organization_roles`
export const USER_CURRENT_ORGANIZATION_QUERY_KEY = `users_in_current_organization`
export const SYSTEMS_IN_CURRENT_ORGANIZATION = `user_admin/systems_in_current_organization`

export function useAllUsersFromOrganization() {
  return useQuery<User[]>(
    [USER_ADMIN_QUERY_KEY, USER_CURRENT_ORGANIZATION_QUERY_KEY],
    async () => {
      const users = await apiClient<User[]>(`user_admin/users_in_current_organization`)
      return users
    },
    {
      enabled: true,
    }
  )
}

export function useRolesFromOrganization() {
  return useQuery<OrganizationRoleOptions[]>(
    [USER_ADMIN_QUERY_KEY, ORGANIZATION_ROLES],
    async () => {
      const roles = await apiClient<OrganizationRoleOptions[]>(`user_admin/organization_roles`)
      return roles
    },
    {
      enabled: true,
    }
  )
}

export function GetSystemsFromOrganization() {
  return useQuery<System[]>(
    [USER_ADMIN_QUERY_KEY, SYSTEMS_IN_CURRENT_ORGANIZATION],
    async () => {
      const systems = await apiClient<System[]>(`user_admin/systems_in_current_organization`)
      return systems
    },
    {
      enabled: true,
    }
  )
}

// Create user
export type NewUserCreationRequest = {
  first_name: string
  last_name?: string
  email: string
  primary_system: number | undefined
  primary_role: number | undefined
  system_roles: { system: number | undefined; role: number | undefined }[]
}

type NewUserCreateRequestPayload = Omit<NewUserCreationRequest, 'primary_role'>

export type CreateNewUserParams = {
  data: NewUserCreationRequest
}

export type NewUserCreationResult = {
  success?: boolean
  created?: User | null
}

export function splitPrimaryAndSystemRole(
  user: User
): Pick<NewUserCreationRequest, 'primary_system' | 'primary_role' | 'system_roles'> {
  const primaryRoleId = user.system_roles.find(({ system }) => system === user.primary_system?.id)?.role_id
  const nonPrimarySystemRoles = user.system_roles.filter(
    ({ system, role_id }) => !(system === user.primary_system?.id && role_id === primaryRoleId)
  )

  return {
    primary_system: user.primary_system?.id,
    primary_role: primaryRoleId,
    system_roles: nonPrimarySystemRoles.map(({ system, role_id }) => ({ system, role: role_id })),
  }
}

function packPrimaryRoleToUserReqData(data: NewUserCreationRequest): NewUserCreateRequestPayload {
  const reqData = clone(data)

  if (data.primary_role) {
    reqData.system_roles.unshift({
      system: data.primary_system,
      role: data.primary_role,
    })
  }

  reqData.system_roles = reqData.system_roles.filter(({ system, role }) => system && role)

  delete reqData.primary_role
  return reqData
}

export function unpackPrimaryRoleFromUserResData(user: User): NewUserCreationRequest {
  const { primary_system, primary_role, system_roles } = splitPrimaryAndSystemRole(user)
  const reqData: NewUserCreationRequest = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    primary_role,
    primary_system,
    system_roles,
  }

  return reqData
}

export function useCreateNewUser(): UseMutationResult<NewUserCreationResult, unknown, CreateNewUserParams, unknown> {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation(({ data }: CreateNewUserParams) => {
    const reqData = packPrimaryRoleToUserReqData(data)
    const res = apiClient<NewUserCreationResult>(`user_admin/create_user`, { method: 'POST', data: reqData })
    return res
  },
  {
    onSuccess: () => {
      alertStore.success(t('User created successfully'))
      queryClient.invalidateQueries(USER_ADMIN_QUERY_KEY)
    },
    onError: () => {
      alertStore.error(t(`Failed to create user`))
    },
  }
  )
}

// Delete user
export type DeleteUserParams = {
  id: number
}

export function useDeleteUser(): UseMutationResult<undefined, unknown, DeleteUserParams, unknown> {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation(({id}: DeleteUserParams) => (
    apiClient<undefined>(`user_admin/remove_user/${id}`, { method:'DELETE' })
  ),
  {
    onSuccess: () => {
      alertStore.success(t('User deleted successfully'))
      queryClient.invalidateQueries(USER_ADMIN_QUERY_KEY)
    },
    onError: () => {
      alertStore.error(t(`Failed to delete user`))
    },
  }
  )
}

// Update user
export type UpdateUserParams = {
  data: NewUserCreationRequest
  id: number
}

export function useUpdateUser(): UseMutationResult<NewUserCreationResult, unknown, UpdateUserParams, unknown> {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation(({ data, id }: UpdateUserParams) => {
    const reqData = packPrimaryRoleToUserReqData(data)
    const res = apiClient<NewUserCreationResult>(`user_admin/update_user/${id}`, { method: 'PUT', data: reqData })
    return res
  },
  {
    onSuccess: () => {
      alertStore.success(t(`User saved successfully`))
      queryClient.invalidateQueries(USER_ADMIN_QUERY_KEY)
    },
    onError: () => {
      alertStore.error(t(`Failed to update user`))
    },
  }
  )
}
