import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import {
  DigitalTwinSetting,
  useDigitalTwinSettings,
  useDigitalTwin,
  DigitalTwin,
} from 'api/digitalTwin/digitalTwin.api'
import { DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { useGetAllEventMutation } from 'api/events/events.api'
import { ObjectProperty, useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { Setting, useSettings } from 'api/settings/settings.api'
import { useFollowupPeriod } from 'hooks/utilsHooks/utilsHooks'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { SettingsListItem } from 'ui/components/SettingListItem/SettingListItem'
import SettingsDropdown from 'ui/components/SettingsDropdown/SettingsDropdown'
import Datetime from 'utils/datetime/datetime'

import { Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { getFirstOccuringSetting, getDisplayName } from 'helpers/global.helper/global.helper'
import {
  settingInPeriod,
  prettyPrintCurrentSettingValue,
  getUnit,
  getDigitalTwinSettingValueString,
} from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getPrettyName, getPropertyPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './DeviationSettings.module.less'

const deviationCategory = `deviation`

type DeviationSettingsProps = {
  startTime: ISODateTime
  endTime: ISODateTime
  activeSettingId?: number
  specificUnit?: string
  specificDigitalTwin?: DigitalTwin // Used instead of the primary digital twin (in sandbox module for example).
  followUp?: boolean
  forceListView?: boolean
  shrink?: boolean
}

const generateSettingsListItems = (
  settings: Setting[], 
  objectProperties: ObjectProperty[], 
  followUpEndTime: ISODateTime, 
  startTime: ISODateTime, 
  endTime: ISODateTime
): (JSX.Element | null)[] | undefined => {
  return objectProperties
    .map((prop) => ({ prop, setting: getFirstOccuringSetting(settings, prop, followUpEndTime) }))
    .filter(({ setting }) => !!setting)
    .map(({ prop, setting }) => {
      // This should never happen because of the filter above,
      // but typescript doesn't care about the filter...
      if (!setting) {
        return null
      }
      const propertyName = getDisplayName(prop)
      return (
        <Grid key={setting.id} item>
          <SettingsListItem
            name={[`${propertyName}`, `${prop.parent ? getDisplayName(prop.parent) : '-'}`]}
            startTime={setting.start_time}
            endTime={setting.end_time}
            value={prettyPrintCurrentSettingValue({
              startTime: Datetime.toISOString(moment(setting.start_time)),
              endTime: setting.end_time ? Datetime.toISOString(moment(setting.end_time)) : null,
              activeValue: prop.current_active_value,
              defaultValue: prop.default_value,
              periodStartTime: startTime,
              periodEndTime: endTime,
              unit: getUnit(prop.measurement_unit),
              attribute: prop.name,
              baseValue: prop.current_base_value,
            })}
            comment={setting.comment}
            settings_value={setting.value?.toString() ?? ''}
            unit={getUnit(prop.measurement_unit)}
          />
        </Grid>
      )
    })
}

const generateDigitalTwinSettingsListItems = (
  settings: DigitalTwinSetting[], 
  startTime: ISODateTime, 
  endTime: ISODateTime, 
  language: string | null, 
  operationalEvents: Map<number, EventPost>,
  specificUnit?: string, 
  activeSettingId?: number, 
  digitalTwin?: DigitalTwin 
): (JSX.Element | null)[] | undefined => {
  return settings
    .filter((setting) => setting.id != activeSettingId)
    .filter((setting) => {
      if (specificUnit) {
        return setting.name === specificUnit && setting.level === 'unit'
      }
      return true
    })
    .sort((a, b) => {
      if (a.name && b.name) {
        const nameA = getPrettyName(a.name, digitalTwin?.translations)
        const nameB = getPrettyName(b.name, digitalTwin?.translations)
        return nameA.localeCompare(nameB, language ?? 'sv')
      }
      return 0
    })
    .map((setting) => {
      const targetObjectName = getPrettyName(setting.name, digitalTwin?.translations)
      const propertyName = getPropertyPrettyName(setting.name, setting.attribute, digitalTwin?.translations)
      return (
        <Grid key={setting.id} item>
          <SettingsListItem
            name={[`${propertyName}`, `${targetObjectName}`]}
            startTime={setting.start_time?.toString() ?? ''}
            endTime={setting.end_time}
            value={getDigitalTwinSettingValueString({
              settingStartTime: setting.start_time ?? Datetime.toISOString(moment().startOf('day')),
              settingEndTime: setting.end_time ? Datetime.toISOString(moment(setting.end_time)) : null,
              activeValue: setting.value,
              periodStartTime: startTime,
              periodEndTime: endTime,
              attribute: setting.attribute,
              baseValue: null,
              isTimeSeries: setting.isTimeSeries,
            })}
            comment={setting.comment}
            operationalEvent={operationalEvents.get(setting.operational_event ?? -1)}
          />
        </Grid>
      )
    })
}

export default function DeviationSettings({
  startTime,
  endTime,
  activeSettingId,
  specificUnit,
  specificDigitalTwin,
  followUp,
  forceListView,
  shrink,
}: DeviationSettingsProps): ReactElement {
  const [digitalTwinSettings, setDigitalTwinSettings] = useState<DigitalTwinSetting[]>([])
  const [operationalEvents, setOperationalEvents] = useState<Map<number, EventPost>>(new Map<number, EventPost>())

  const [followupPeriod] = useFollowupPeriod()
  const followUpEndTime = Datetime.toISOString(followupPeriod.endTime)

  const { t } = useTranslation()
  const language = localStorage.getItem('language')
  const { activeSystem } = useAuth()
  const systemPrimaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: primaryDigitalTwin } = useDigitalTwin(systemPrimaryDigitalTwin?.uid, true)

  const digitalTwin = specificDigitalTwin ?? primaryDigitalTwin

  const { data: objectProperties = [], isLoading } = useObjectProperties({
    start_time: startTime.toString(),
    end_time: endTime.toString(),
  })
  const { data: optModelSettings, status } = useSettings({
    params: {
      active_from: startTime.toString(),
      active_to: endTime.toString(),
    },
  })

  const { mutateAsync: fetchDigitalTwinSettings, isLoading: isLoadingSettings } = useDigitalTwinSettings()
  useEffect(() => {
    if (digitalTwin?.uid !== undefined) {
      fetchDigitalTwinSettings({
        digitalTwinUid: digitalTwin?.uid,
        activeFrom: startTime,
        activeTo: endTime,
        priorities: [...DEVIATION_SETTING_PRIORITIES, ...(activeSystem?.setting_priority_levels ?? [])],
      }).then((settings) => {
        setDigitalTwinSettings(settings)
      })
    }
  }, [activeSystem, digitalTwin?.uid, endTime, fetchDigitalTwinSettings, startTime])

  const { mutateAsync: fetchOperationalEvents } = useGetAllEventMutation()
  const fetchEvents = useCallback(() => {
    const ids = Array.from(new Set(digitalTwinSettings
      .map((setting) => setting.operational_event)
      .filter((id) => id !== undefined && id !== null)))
    if (ids.length === 0) {
      return
    }

    fetchOperationalEvents({
      ids: ids,
    }).then((data) => {
      const dict = new Map<number, EventPost>()
      data.forEach((event) => {
        if (event.id !== undefined) {
          dict.set(event.id, event)
        }
      })
      setOperationalEvents(dict)
    })
  }, [fetchOperationalEvents, digitalTwinSettings])

  const onOpenSettingsDropdown = useCallback((open: boolean) => {
    if (open) {
      fetchEvents()
    }
  }, [fetchEvents])


  const filteredOptModelSettings = useMemo(() => {
    return optModelSettings?.filter((setting) => {
      const property = objectProperties.find((prop) => setting.object_property === prop.id)
      if (property && !setting.is_base_value) {
        if (followUp) {
          return property.classifications.includes(deviationCategory)
        } else {
          return (
            property.classifications.includes(deviationCategory) &&
          settingInPeriod(moment(setting.start_time), setting.end_time !== null ? moment(setting.end_time) : null)
          )
        }
      }
      return false
    })
  }, [optModelSettings, objectProperties, followUp])

  const settingsListItemPerProperty: (JSX.Element | null)[] | undefined = useMemo(() => {
    if (!digitalTwin && filteredOptModelSettings?.length) {
      return generateSettingsListItems(
        filteredOptModelSettings, 
        objectProperties, 
        followUpEndTime, 
        startTime, 
        endTime
      )
    } else if (digitalTwinSettings?.length) {
      return generateDigitalTwinSettingsListItems(
        digitalTwinSettings, 
        startTime, 
        endTime, 
        language, 
        operationalEvents, 
        specificUnit, 
        activeSettingId, 
        digitalTwin
      )
    }
  }, [
    activeSettingId, digitalTwin, digitalTwinSettings, endTime, filteredOptModelSettings, 
    followUpEndTime, language, objectProperties, operationalEvents, specificUnit, startTime,
  ])

  return (
    <div className={classNames(styles.DeviationSettings_Container, shrink ? styles.DeviationSettings_Shrink : '')}>
      {status === `loading` || isLoading || isLoadingSettings ? (
        <div style={{ maxHeight: 30 }}>
          <LoadingPlaceholderContainer />
        </div>
      ) : settingsListItemPerProperty?.length ? (
        settingsListItemPerProperty.length === 1 && !forceListView ? (
          <>{settingsListItemPerProperty}</>
        ) : (
          <SettingsDropdown 
            noOfSettings={settingsListItemPerProperty.length}
            handleToggle={onOpenSettingsDropdown}
          >
            {settingsListItemPerProperty}
          </SettingsDropdown>
        )
      ) : (
        <div className={styles.DeviationSettings_TypographyContainer}>
          <Typography variant="body1">{t(`No active deviation settings found for this period.`)}</Typography>
        </div>
      )}
    </div>
  )
}
