import React, { ReactElement, useEffect, useState } from 'react'

import { useElplanInfo } from 'api/elplan/elplan.api'
import authStore from 'store/auth/auth'
import { setCurrentDate } from 'store/elplan/elplan'
import { Tabs } from 'ui/atoms'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './ElectricityPlanningView.module.less'
import BidManagementView from './components/BidManagementView/BidManagementView'
import ElectricityPlanView from './components/ElectricityPlanView/ElectricityPlanView'


export default function ElectricityPlanningView(): ReactElement {
  const route = getRoute()
  const localStorageKeyForTabs = `ElectricityPlanningTabs_${route}`
  const savedTab = parseInt(localStorage.getItem(localStorageKeyForTabs) ?? '0', 10) ?? 0

  const [tabIndex, setTabIndex] = useState(savedTab)
  const authSnap = useSnapshot(authStore)
  const { t } = useTranslation()
  useElplanInfo(authSnap.systemId)
  setCurrentDate(Datetime.getTomorrowDate().startTime, Datetime.getTomorrowDate().endTime)

  const tabs = [
    { title: t('Bid management'), children: [] },
    { title: t('Current electricity plan'), children: [] },
  ]

  useEffect(() => {
    localStorage.setItem(localStorageKeyForTabs, tabIndex.toString())
  }, [tabIndex, localStorageKeyForTabs])

  return (
    <div className={styles.ElectricityPlanningView} >
      <View
        header={
          <>
            <ModuleHeader infoHeader={t(`Electricity Planning`)} />
            <div className={styles.ElectricityPlanningView_Tabs}>
              <Tabs
                items={tabs}
                onChange={(index) => setTabIndex(index)}
                innerLeftPadding
                marginBottom={false}
                uniqueStorageKey={localStorageKeyForTabs} />
            </div>
          </>
        }
      >
        {tabIndex === 0 && 
          <Grid item xs={12}>
            <BidManagementView />
          </Grid>}

        {tabIndex === 1 && 
          <Grid item xs={12}>
            <ElectricityPlanView />          
          </Grid>}

        <QueueProgressBar />
      </View></div>
  )
}

