import React, { ReactElement } from 'react'

import { Grid, Button, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './SandboxTitleContent.module.less'

export default function SandboxTitleContent({
  setCreateDialogOpen,
}: {
  setCreateDialogOpen: (value: React.SetStateAction<boolean>) => void
}): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <Grid
        container
        direction="row"
        className={styles.SandboxTitleContent_Container}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Grid item>
          <Link className={styles.SandboxTitleContent_ReturnButton} to="/sandbox">
            <Button className={styles.SandboxTitleContent_BackButton}>{t(`< Back to start page`)}</Button>
          </Link>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={styles.SandboxTitleContent_Button}
            onClick={() => setCreateDialogOpen(true)}
          >
            <Icon className={classNames(styles.SandboxTitleContent_Icon, `fal fa-plus`)} />
            {t(`Create new`)}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
