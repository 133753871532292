import React, { ReactElement, useEffect } from 'react'

import { DigitalTwin, useDigitalTwinVersions } from 'api/digitalTwin/digitalTwin.api'
import Badge from 'ui/atoms/Badge/Badge'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, ListItemButton, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './DigitalTwinList.module.less'

type DigitalTwinListProps = {
  digitalTwin: DigitalTwin
  primaryDigitalTwinUid: number | undefined
  currentDigitalTwin?: DigitalTwin
  setCurrentDigitalTwin: (digitalTwin?: DigitalTwin) => void
}

export default function DigitalTwinList({
  digitalTwin,
  primaryDigitalTwinUid,
  setCurrentDigitalTwin,
  currentDigitalTwin,
}: DigitalTwinListProps): ReactElement {
  const { t } = useTranslation()
  const { data: versions, refetch: fetchVersions } = useDigitalTwinVersions(digitalTwin.uid, 30)

  const open = currentDigitalTwin?.id === digitalTwin.id
  const latestModalTitle = `${digitalTwin.id} ${digitalTwin.display_name}`

  useEffect(() => {
    fetchVersions()
  }, [fetchVersions, digitalTwin.layout]) // Multiple versions can share the same layout. So if one changes, we need to reload all.

  const collapsedListItems = (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {versions?.map((version, i) => {
        const versionModelTitle = `${digitalTwin.id}.${version.version} ${version.display_name}`
        const selected = currentDigitalTwin?.uid === version.uid

        let className: string | undefined
        if (!selected) {
          className = i % 2 === 0 ? styles.DigitalTwinList_WhiteBackground : styles.DigitalTwinList_GreyBackground
        } else {
          className = styles.DigitalTwinList_Selected
        }

        return (
          <ListItemButton
            className={className}
            selected={selected}
            key={`digital_twin_model_version_${version.uid}`}
            onClick={() => {
              setCurrentDigitalTwin(version)
            }}
            divider
            dense
          >
            <ListItemText primary={versionModelTitle} />
            {primaryDigitalTwinUid === version.uid ? <Badge success title={t('Primary')} /> : <></>}
          </ListItemButton>
        )
      })}
    </Collapse>
  )

  return (
    <>
      <ListItemButton
        key={`digital_twin_model_${digitalTwin.uid}`}
        onClick={() => {
          setCurrentDigitalTwin(open ? undefined : digitalTwin)
        }}
        divider
      >
        <ListItemText primary={latestModalTitle} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {collapsedListItems}
    </>
  )
}
