import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import zoomStore from 'store/zoom/zoom'
import { Card } from 'ui/atoms'
import { TabList } from 'ui/atoms/Tabs/Tabs'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import { capitalize } from 'utils/stringUtils'

import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

type DefaultComponentTab = {
  title: string
  cards: {
    tourId?: string,
    title: string,
    default_collapsed?: boolean,
    remember_collapsed?: boolean,
    children_list: {
      ui_config_id: number,
      override_alias?: UiConfigAliases
      override_props?: UiConfigProps
    }[]
  }[]
}

type DefaultComponentProps = {
  uid: number
  children: ReactNode
  systemId: number
  tabs: DefaultComponentTab[]
  datasetEndTime: ISODateTime
  datasetStartTime: ISODateTime
  datasetRefreshToken?: string
  overrideAlias?: UiConfigAliases
}

const defaultProps: Omit<DefaultComponentProps, 'children' | 'systemId' | 'datasetStartTime' | 'datasetEndTime' | 'uid'> = {
  tabs: [
    {
      title: 'Stad 1',
      cards: [{
        title: 'Körplan',
        children_list: [{
          ui_config_id: -9,
          override_alias: {
          },
          override_props:{},
        },
        {
          ui_config_id: -10,
          override_alias: {
          },
          override_props:{},
        }],
      },
      {
        title: 'Prognoser för körplan',
        children_list: [{
          ui_config_id: -15,
          override_alias: {
          },
          override_props:{},
        }],
      },
      {
        title: 'Körplanstabell',
        children_list: [
          {
            ui_config_id: -11,
            override_alias: {
            },override_props:{},
          }],
      }],
    },
  ],
}

registerUiConfigComponent('default_component', DefaultComponent)
registerDefaultProps('default_component', defaultProps)

type SystemTabProps = {
  route: string
  activeTabIndex: number
  handleTabChange: (index: number) => void
  tabs: DefaultComponentTab[]
}

function SystemTabs(
  props: SystemTabProps
): JSX.Element {
  const {
    route,
    activeTabIndex,
    handleTabChange,
    tabs,
  } = props

  if (tabs.length == 1){
    return <></>
  }

  const tabTitles = tabs.map((tab) => tab.title)

  if (route === 'uiconfig') {
    return <TabList items={tabTitles} selectedTab={activeTabIndex} onClick={handleTabChange} />
  }

  if (route !== 'uiConfig') {
    const element = document.getElementById('headerPortalTabs')
    if (element) {
      return ReactDOM.createPortal(
        <TabList items={tabTitles} onClick={handleTabChange} selectedTab={activeTabIndex} innerLeftPadding marginBottom={false} />,
        element
      )
    }
  }

  return <></>
}

export default function DefaultComponent ({
  systemId,
  datasetStartTime,
  datasetEndTime,
  tabs,
  datasetRefreshToken,
  overrideAlias,
}: DefaultComponentProps): ReactElement {

  const [tabIndex, setTabIndex] = React.useState(0)
  const { t } = useTranslation()
  const snap = useSnapshot(zoomStore)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const [domReady, setDomReady] = useState(false)

  useEffect(() => {
    setDomReady(true)
    return () => {
      setDomReady(false)
    }
  }, [])

  if (!tabs?.length && !domReady) {
    return <></>
  }

  const activeTab = tabs[tabIndex]

  return (
    <>
      {domReady ? <SystemTabs route={snap.route} activeTabIndex={tabIndex} handleTabChange={setTabIndex} tabs={tabs} /> : null}

      {(activeTab.cards || []).map((card) => {

        return (
          <Card
            margin
            key={`card_${card.title}`}
            title={<TitleWithIcon title={t(capitalize(card.title)) ?? capitalize(card.title)} />}
            defaultCollapsed={card.default_collapsed}
            rememberCollapsed={card.remember_collapsed}
            collapsible
            tourId={card.tourId}
          >
            {card.children_list.map((child, index) =>
              <UiConfigComponent
                key={`${child.ui_config_id}_${index}`}
                systemId={systemId}
                id={child.ui_config_id}
                uid={uiConfigSnap.idToUiConfig[child.ui_config_id]?.uid}
                datasetStartTime={datasetStartTime}
                datasetEndTime={datasetEndTime}
                overrideAlias={{...overrideAlias, ...child.override_alias}}
                overrideProps={child.override_props}
                datasetRefreshToken={datasetRefreshToken}
              />
            )}
          </Card>
        )})}
    </>
  )
}
