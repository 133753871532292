import React, { useState, ReactElement } from 'react'

import { ShiftTeam } from 'api/shiftTeams/shiftTeams.api'
import { useCardWidget } from 'ui/components/CardWidget/CardWidget'
import HeaderPeriodPicker from 'ui/components/HeaderPeriodPicker/HeaderPeriodPicker'

import { Grid, Button, Icon, Popper, ClickAwayListener, MenuList, MenuItem, Checkbox, Typography } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './ReportsDatePicker.module.less'

type ReportsDatePickerProps = {
  handleDateChange: (period: Period) => void
  shiftTeams?: ShiftTeam[]
  updateShiftTeamsFilter: (id: number) => void
  selectedShifts: number[]
  period: Period
  handoverLoading: boolean
}

export default function ReportsDatePicker({
  handleDateChange,
  selectedShifts,
  updateShiftTeamsFilter,
  shiftTeams,
  period,
  handoverLoading,
}: ReportsDatePickerProps): ReactElement {
  const { t } = useTranslation()

  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  function handleToggle(): void {
    setOpen(!open)
  }
  const toggleFilterBox = (event?: React.MouseEvent<HTMLElement>): void => {
    setAnchorFilter(anchorFilter || !event?.currentTarget ? null : event?.currentTarget)
  }

  const handleClick = (event?: React.MouseEvent<HTMLElement>): void => {
    toggleFilterBox(event)
    handleToggle()
  }
  const { node } = useCardWidget()
  return (
    <Grid item container direction={`row`} justifyContent={`space-between`}>
      <Grid item xs={12} md={3}>
        <Button className={styles.ReportsDatePicker_FilterArea} onClick={(e) => handleClick(e)}>
          <Icon className={`fal fa-users`} style={{ marginRight: 15 }} />
          <Typography variant="body2" display="inline" className={styles.ReportsDatePicker_FilterText}>
            {t(`Filter on shift teams`)}
          </Typography>
          <Icon
            fontSize="small"
            className={classNames(styles.ReportsDatePicker_Expand, `fas fa-caret-down`, {
              [styles.ReportsDatePicker_ExpandOpen]: open,
            })}
          />
        </Button>
        <Popper
          open={Boolean(anchorFilter)}
          anchorEl={anchorFilter}
          placement="bottom-start"
          className={styles.ReportsDatePicker_FilterDropdownMenu}
          container={node?.current}
        >
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
              toggleFilterBox()
            }}
          >
            <MenuList>
              {shiftTeams?.map((shiftTeam) => {
                return (
                  <MenuItem
                    key={shiftTeam.name}
                    value={shiftTeam.id}
                    onClick={() => {
                      updateShiftTeamsFilter(shiftTeam.id)
                    }}
                  >
                    <Checkbox checked={!selectedShifts.includes(shiftTeam.id)} />
                    {getDisplayName(shiftTeam)}
                  </MenuItem>
                )
              })}
            </MenuList>
          </ClickAwayListener>
        </Popper>
      </Grid>
      <Grid item xs={10} md={8} lg={6}>
        <HeaderPeriodPicker
          period={period}
          handlePeriodChange={handleDateChange}
          loading={handoverLoading}
          maxDate={moment().endOf('day')}
        />
      </Grid>
    </Grid>
  )
}
