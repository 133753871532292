import { useCallback, useEffect, useMemo, useState } from 'react'

import { ApiRequestStatus } from 'api/api.utils'
import { DigitalTwinSetting, useDigitalTwinSettings } from 'api/digitalTwin/digitalTwin.api'
import { BASE_SETTING_PRIORITIES, DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { DigitalTwinHeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

type UseDigitalTwinHeatSupplierSettingsProps = {
  heatSupplier?: DigitalTwinHeatSupplier
  activeFrom?: ISODateTime
  activeTo?: ISODateTime
  attributes: string[]
  priorities?: SystemSettingPriorityLevel[]
}

type UseDigitalTwinHeatSupplierSettingsReturn = {
  settings: DigitalTwinSetting[]
  fetchSettingsStatus: ApiRequestStatus
  fetchSettings: () => void
}

export default function useDigitalTwinHeatSupplierSettings({
  heatSupplier,
  activeFrom,
  activeTo,
  attributes,
  priorities,
}: UseDigitalTwinHeatSupplierSettingsProps): UseDigitalTwinHeatSupplierSettingsReturn {
  const { activeSystem } = useAuth()
  const primaryDigitalTwinUid = activeSystem?.primary_digital_twin?.uid

  // Moving the default priorities to prop defaults results in an infinite loop of re-renders.
  // We need to memoize the value to avoid an infinite list of network requests.
  const memoizedPriorities = useMemo(
    () => priorities ?? [...BASE_SETTING_PRIORITIES, ...DEVIATION_SETTING_PRIORITIES],
    [priorities]
  )

  const [settings, setSettings] = useState<DigitalTwinSetting[]>([])

  const { mutateAsync: fetch, status: fetchSettingsStatus } = useDigitalTwinSettings()

  const fetchSettings = useCallback(() => {
    if (heatSupplier?.unit_name === undefined) {
      return
    }

    fetch({
      properties: attributes.map((attribute) => ({
        level: 'unit',
        attribute,
        name: heatSupplier.unit_name,
      })),
      digitalTwinUid: primaryDigitalTwinUid,
      priorities: memoizedPriorities,
      activeFrom,
      activeTo,
    }).then(setSettings)
  }, [activeFrom, activeTo, attributes, fetch, heatSupplier?.unit_name, primaryDigitalTwinUid, memoizedPriorities])

  useEffect(() => {
    if (!primaryDigitalTwinUid || attributes.length === 0) {
      // No digital twin or no attributes given, so we shouldn't return any setting from this hook.
      // This prevents old settings from being returned by the hook if the attribute list is cleared.
      setSettings([])
      return
    }

    fetchSettings()
  }, [attributes.length, fetchSettings, primaryDigitalTwinUid])

  return { settings, fetchSettingsStatus, fetchSettings }
}
