import React, { ReactElement, useState } from 'react'

import { ShiftHandoverCreateInfo } from 'api/shiftHandovers/shiftHandovers.api'
import ModuleHeaderButton from 'ui/components/ModuleHeaderButton/ModuleHeaderButton'
import NoPermissionErrorMessage from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'

import { Grid, Hidden } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import CreateModal from 'views/ShiftHandoverView/components/CreateModal/CreateModal'

type ShiftModuleButtonProps = {
  disabled?: boolean
  createInfo?: ShiftHandoverCreateInfo
}

export default function ShiftModuleButton({ createInfo }: ShiftModuleButtonProps): ReactElement {
  const [modalOpen, setModalOpen] = useState(false)
  const hasCreateShiftHandoverAccess = useHasPermission('create_shift_handover')
  const { t } = useTranslation()

  return (
    <Hidden mdDown>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item xs={12}>
          <ModuleHeaderButton
            disabled={!hasCreateShiftHandoverAccess ? true : false}
            icon="fal fa-plus"
            onClick={() => setModalOpen(true)}
          >
            {t(`Create handover`)}
          </ModuleHeaderButton>
          {!hasCreateShiftHandoverAccess && (
            <NoPermissionErrorMessage
              title={t(`Unfortunately, you do not have permission to create or modify shift reports`)}
              message={t(
                `Unfortunately, you do not have permission to create or modify shift reports on this user/facility`
              )}
            />
          )}
          {createInfo && createInfo?.handover_shifts ? (
            <CreateModal
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
              createInfo={
                createInfo as Omit<ShiftHandoverCreateInfo, `handover_shifts`> & {
                  handover_shifts: NonNullable<ShiftHandoverCreateInfo[`handover_shifts`]>
                }
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </Hidden>
  )
}
