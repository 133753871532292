import React, { ReactElement, useMemo } from 'react'

import { DeviationSettingLevel, DigitalTwinPropertyObject, useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import {
  Choices,
  getAutocompleteValueForModelEntityName,
  getAutocompleteValueForProperty,
} from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialogContent'

import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import {EventPost} from 'views/EventsView/components/events.helper'

type EventPropertiesDropdownsProps = {
  eventPost: EventPost
  property: { object_category: DeviationSettingLevel | string, object_name: string, attribute: string }
  index: number
  error?: boolean
  disabled?: boolean
  setEventPost: (event: EventPost) => void
  checkIfEditing: (event: EventPost) => boolean
  setIsEditing: (isEditing: boolean) => void
}
export default function EventPropertiesDropdowns({ eventPost, error, property, index, disabled, setEventPost, checkIfEditing, setIsEditing }: EventPropertiesDropdownsProps): ReactElement {
  const { t } = useTranslation()

  const { activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin, isLoading: isLoadingDigitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true)

  function setPropertyObject(
    list: DigitalTwinPropertyObject[],
    object_category: DeviationSettingLevel,
    properties?: DigitalTwinPropertyObject[]
  ): void {
    properties?.forEach((p) => {
      p.level = object_category
      list.push(p)
    })
  }

  const propertyItems = useMemo(() => {
    // Sets objectCategories
    if (!digitalTwin?.model || isLoadingDigitalTwin || !digitalTwin) {
      return []
    }
    const propertyObjects: DigitalTwinPropertyObject[] = []
    digitalTwin.model.name = digitalTwin.name
    digitalTwin.model.level = 'model' as DeviationSettingLevel
    propertyObjects.push(digitalTwin.model)
    digitalTwin.model.nodes.forEach((n) => {
      setPropertyObject(propertyObjects, 'unit', n.units)
      n.level = 'node' as DeviationSettingLevel
      propertyObjects.push(n)
    })
    digitalTwin.model.commodities.forEach((c) => {
      setPropertyObject(propertyObjects, 'byproduct', c.byproducts)
      c.level = 'commodity' as DeviationSettingLevel
      propertyObjects.push(c)
    })
    setPropertyObject(propertyObjects, 'exchange', digitalTwin.model.exchanges)
    setPropertyObject(propertyObjects, 'balance', digitalTwin.model.balances)

    return propertyObjects
  }, [digitalTwin, isLoadingDigitalTwin])

  const objectCategoryChoiceItems = useMemo((): { value: string; label: string}[] => {
    if (!Array.isArray(propertyItems)) {
      return []
    }

    const objectCategoryChoices: Choices[] = []
    for (const property of propertyItems) {
      if (property.level && !objectCategoryChoices.some(c => c.value === property.level) && property.configurable_properties) {
        objectCategoryChoices.push({
          name: getPrettyName(property.level, digitalTwin?.translations, true),
          value: property.level,
        })
      }
    }

    return objectCategoryChoices.sort((a, b) =>
      a.name.localeCompare(b.name)
    ).map((type) => ({ value: type.value, label: type.name }))
  }, [digitalTwin?.translations, propertyItems])

  const getObjectNameChoiceItems = useMemo(() => (index: number): { value: string; label: string}[] => {
    const currentObjectCategory = eventPost.linked_objects?.[index]?.object_category ?? ''
    return propertyItems
      .filter(unit => unit.level === currentObjectCategory && !unit.tags?.includes('exclude') && unit.configurable_properties)
      .map(unit => getAutocompleteValueForModelEntityName(unit.name, digitalTwin?.translations))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((type) => ({ value: type.value, label: type.name }))
      ?? []
  }, [eventPost.linked_objects, propertyItems, digitalTwin?.translations])

  const getAttributeChoiceItems = useMemo(() => (index: number): { value: string; label: string}[] => {
    const currentObjectCategory = eventPost?.linked_objects?.[index]?.object_category
    const currentObjectName = eventPost?.linked_objects?.[index].object_name

    return propertyItems.find((p) => p.name === currentObjectName && p.level === currentObjectCategory)?.configurable_properties
      ?.filter((p) => !p.tags?.includes('exclude'))
      ?.map((p) => getAutocompleteValueForProperty(p.attribute, digitalTwin?.translations))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((type) => ({ value: type.value, label: type.name }))
      ?? []
  }, [eventPost.linked_objects, propertyItems, digitalTwin?.translations])

  if (eventPost.linked_objects) {
    return (
      <>
        <Autocomplete
          disablePortal
          disabled={disabled}
          fullWidth
          options={objectCategoryChoiceItems}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={objectCategoryChoiceItems.find(item => item.value === property.object_category) || null}
          onChange={(e, newValue) => {
            if (eventPost.linked_objects) {
              const updatedProperties = [...eventPost.linked_objects]
              updatedProperties[index] = {
                ...updatedProperties[index],
                object_category: newValue?.value || '',
                object_name: '',
                attribute: '',
              }
              const newEventPost = {
                ...eventPost,
                linked_objects: updatedProperties,
              }
              setEventPost(newEventPost)
              setIsEditing(checkIfEditing(newEventPost))
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Category') + ' *'}
              error={error}
              margin="dense"
            />
          )}
        />
        <Autocomplete
          disablePortal
          disabled={(eventPost.linked_objects[index].object_category === '') || disabled}
          fullWidth
          options={getObjectNameChoiceItems(index)}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={getObjectNameChoiceItems(index).find(item => item.value === property.object_name) || null}
          onChange={(e, newValue) => {
            if (eventPost.linked_objects) {
              const updatedProperties = [...eventPost.linked_objects]
              updatedProperties[index] = {
                ...updatedProperties[index],
                object_name: newValue?.value || '',
                attribute: '',
              }
              const newEventPost = {
                ...eventPost,
                linked_objects: updatedProperties,
              }
              setEventPost(newEventPost)
              setIsEditing(checkIfEditing(newEventPost))
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Object') + ' *'}
              error={error}
              margin="dense"
            />
          )}
        />
        <Autocomplete
          disablePortal
          disabled={(eventPost.linked_objects[index].object_name === '') || disabled}
          fullWidth
          options={getAttributeChoiceItems(index)}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={getAttributeChoiceItems(index).find(item => item.value === property.attribute) || null}
          onChange={(e, newValue) => {
            if (eventPost.linked_objects) {
              const updatedProperties = [...eventPost.linked_objects]
              updatedProperties[index] = {
                ...updatedProperties[index],
                attribute: newValue?.value || '',
              }
              const newEventPost = {
                ...eventPost,
                linked_objects: updatedProperties,
              }
              setEventPost(newEventPost)
              setIsEditing(checkIfEditing(newEventPost))
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Property') + ' *'}
              error={error}
              margin="dense"
            />
          )}
        />
      </>
    )
  } else {
    return <></>
  }
}
