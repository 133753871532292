import React, { ReactElement, useState } from 'react'

import { Comment, ShiftHandoverFormDefaultValues } from 'api/shiftHandovers/shiftHandovers.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import ReactHookFormCheckbox from 'ui/components/ReactHookFormCheckbox/ReactHookFormCheckbox'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'

import { Grid, Typography, Chip, Icon, Button, IconButton } from '@mui/material'
import classNames from 'classnames'
import { useFieldArray, useWatch, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


import styles from './CommentField.module.less'

type CommentFieldProps = {
  disabled?: boolean
  groupIndex: number
  groupId?: string
  name: string
  settingComments?: Array<string>
}

export default function CommentField({
  settingComments,
  groupIndex,
  groupId,
  name,
  disabled,
}: CommentFieldProps): ReactElement {
  const { t } = useTranslation()

  const { control, register, setValue } = useFormContext<ShiftHandoverFormDefaultValues>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `comment_groups.${groupIndex}.comments` as `comment_groups.0.comments`,
    keyName: `comment_id`,
  })

  const comments = useWatch({
    control,
    name: `comment_groups.${groupIndex}.comments` as `comment_groups.0.comments`,
    defaultValue: fields,
  })

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    if (e.target.value === ``) {
      setValue(
        `comment_groups.${groupIndex}.comments.${index}.is_reported` as `comment_groups.0.comments.0.is_reported`,
        false
      )
      setValue(
        `comment_groups.${groupIndex}.comments.${index}.is_pinned` as `comment_groups.0.comments.0.is_pinned`,
        false
      )
    }
    setValue(
      `comment_groups.${groupIndex}.comments.${index}.content` as `comment_groups.0.comments.0.content`,
      e.target.value
    )
  }

  const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(-1)

  const allItemsHaveContent = comments.every((comment: Comment) => comment.content !== ``)

  return (
    <>
      <Grid
        item
        container
        className={styles.CommentField_Container}
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item container justifyContent="flex-end" alignItems="flex-start" xs={2}>
          <Typography variant="h1" style={{ textOverflow: `ellipsis`, whiteSpace: `nowrap` }}>
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={10}
          direction="column"
          className={settingComments && styles.CommentField_Border}
          spacing={1}
        >
          {settingComments && (
            <Grid item container direction="row" spacing={1}>
              {settingComments.map((label) => (
                <Chip
                  key={`setting` + label}
                  label={label}
                  icon={<Icon className={`fal fa-cog`} />}
                  className={styles.CommentField_Chip}
                />
              ))}
            </Grid>
          )}
          <Grid item container spacing={1}>
            {fields.map((comment, index) => (
              <Grid
                key={comment.comment_id}
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <Grid item container direction="row" justifyContent="flex-start" xs={8}>
                  <input
                    key={comment.id}
                    aria-label="comment_id"
                    type="hidden"
                    defaultValue={comment.id}
                    {...register(
                      `comment_groups.${groupIndex}.comments.${index}.id` as `comment_groups.0.comments.0.id`
                    )}
                  />
                  <input
                    aria-label="comment_group"
                    type="hidden"
                    defaultValue={groupId}
                    {...register(
                      `comment_groups.${groupIndex}.comments.${index}.group` as `comment_groups.0.comments.0.group`
                    )}
                  />
                  <Grid item xs={11}>
                    <ReactHookFormTextField
                      name={`comment_groups.${groupIndex}.comments.${index}.content`}
                      label={disabled ? `` : t(`Comment`)}
                      disabled={disabled}
                      disabledTextAsCustomStyle={disabled}
                      defaultValue={comment.content}
                      multiline={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleContentChange(e, index)}
                    />
                  </Grid>
                  {!comment.id && !comment.is_pinned && fields.length > 1 && (
                    <IconButton
                      className={`fal fa-trash-alt`}
                      style={{
                        color: `red`,
                      }}
                      aria-label={t(`Remove comment`)}
                      onClick={() => setConfirmDeleteIndex(index)}
                      size="large"
                    />
                  )}
                </Grid>

                <Grid item container alignItems="flex-start" justifyContent="flex-start" xs={4}>
                  <Grid item xs={4}>
                    <ReactHookFormCheckbox
                      label={t(`Problem reported`)}
                      name={`comment_groups.${groupIndex}.comments.${index}.is_reported`}
                      defaultValue={comment.is_reported}
                      disabled={disabled || comments[index]?.content === ``}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ReactHookFormCheckbox
                      label={t(`Pin comment`)}
                      checkedLabel={t(`Unpin comment`)}
                      name={`comment_groups.${groupIndex}.comments.${index}.is_pinned`}
                      defaultValue={comment.is_pinned}
                      CheckboxProps={{
                        disableRipple: true,
                        icon: <Icon className={classNames(`fal fa-thumbtack`, styles.CommentField_IconButton)} />,
                        checkedIcon: (
                          <Icon className={classNames(`fas fa-thumbtack`, styles.CommentField_PinnedIconButton)} />
                        ),
                      }}
                      disabled={disabled || comments[index]?.content === ``}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {allItemsHaveContent && !disabled && (
              <Button
                color="secondary"
                className={styles.CommentField_}
                onClick={() => {
                  append({ content: ``, is_reported: false, is_pinned: false })
                }}
              >
                <Icon className="fal fa-plus" fontSize="small" />
                {t(`Add new comment field`)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmDeleteIndex >= 0}
        onConfirm={() => {
          remove(confirmDeleteIndex)
          setConfirmDeleteIndex(-1)
        }}
        onClose={() => setConfirmDeleteIndex(-1)}
        textObject={{
          title: t(`Remove comment`),
          text: t(`Your comment cannot be restored if you remove it`),
        }}
      />
    </>
  )
}
