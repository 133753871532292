import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'

type TimeFieldProps = {
  value: string | null
}

function TimeField({ value }: TimeFieldProps): ReactElement {
  const { t } = useTranslation()

  return <>{value ? formatDateTimeToLocalizedString(value, true) : t(`Until further notice`)}</>
}

export default TimeField
