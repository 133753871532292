import React, { ReactElement } from 'react'

import { Button, Icon, Tooltip, ButtonProps } from '@mui/material'
import classNames from 'classnames'

import styles from './ContainedIconButton.module.less'

interface ContainedIconButtonProps extends Partial<ButtonProps> {
  icon: string
  label: string
  tooltip?: string
  rightIcon?: boolean
}

export default function ContainedIconButton({
  rightIcon,
  icon,
  label,
  tooltip,
  onClick,
  disabled,
  color,
  type,
  ...props
}: ContainedIconButtonProps): ReactElement {

  return (
    <Tooltip title={tooltip ?? ``} placement="top" arrow>
      <>
        <Button
          color={color}
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          type={type}
          aria-label={label}
          {...props}
        >
          {!rightIcon && <Icon className={classNames(styles.ContainedIconButton_Icon, icon)} />} {label}
          {rightIcon && <Icon className={classNames(styles.ContainedIconButton_RightIcon, icon)} />}
        </Button>
      </>
    </Tooltip>
  )
}