import { DigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { DefaultPageNumberPagination } from 'api/types'

import { clone } from 'helpers/global.helper/global.helper'

//------ Filtering of dataset instuctions --------
function filterDatasetInstructionWithSources(
  datasetInstruction: any,
  items: UiConfigPropsItem[],
  currentActiveTagsPerRoute: Set<string>,
  allTagsPerRoute: Set<string>,
  allTagsDict: { [unitName: string] : string[] }
): any {
  // Filters out sources in a DI with sources.
  const newSources: { source?: string, return_id: string }[] = []

  datasetInstruction.contract?.sources?.forEach((source: any) => {
    const sourceItem = items.find((item) => item.data_id === source.return_id)

    if (sourceItem && !sourceItem.fake) {
      // sources which have an item in the uiconfig will be included in filtering iftagsAreIncludedInFiltering(...) returns true

      if (tagsAreIncludedInFiltering(sourceItem?.tags ?? [], currentActiveTagsPerRoute, allTagsPerRoute)) {
        newSources.push(source)
      }

    } else if (!sourceItem) {
      // sources without an item in the uiconfig will be included in filtering if the source has tags from digital twin model json, or if it has no tags at all
      const dataIdComponents = source.return_id.split('.')
      const sourceUnitName = dataIdComponents[dataIdComponents.length - 1]
      const sourceTags = getDigitalTwinTagsFromDataId(sourceUnitName, allTagsDict)
      if (sourceTags && tagsAreIncludedInFiltering(sourceTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
        newSources.push(source)
      }

    } else if (sourceItem.fake) {
      // is the found sourceItem from a calc variable, then it will be included in filtering if it has tags from the digital twin model json or its parent item

      const sourceTags = getTagsAccordingToTagsLogicForItem(sourceItem, allTagsDict)

      const sourceItemTags = sourceItem.tags ?? []
      const soughtTags = sourceItemTags.concat(sourceTags ?? [])

      if (tagsAreIncludedInFiltering(soughtTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
        newSources.push(source)
      }
    }
  })

  return {
    ...datasetInstruction,
    contract: {
      ...datasetInstruction.contract,
      sources: newSources,
    },
  } as DatasetInstruction
}

function filterDatasetInstructionWithoutSources(
  datasetInstruction: DatasetInstruction, 
  items: UiConfigPropsItem[],
  currentActiveTagsPerRoute: Set<string>,
  allTagsPerRoute: Set<string>,
  allTagsDict: { [unitName: string] : string[] }
): DatasetInstruction | undefined {

  const instructionItem = items.find((item) => item.data_id === datasetInstruction.return_id)

  if (instructionItem && !instructionItem.fake) {
    if (tagsAreIncludedInFiltering(instructionItem?.tags ?? [], currentActiveTagsPerRoute, allTagsPerRoute)) {
      return datasetInstruction
    }
  } else if (!instructionItem && datasetInstruction.return_id) { //only include this item if it has sourcetags from model
    const sourceDataId = datasetInstruction.return_id.split('.')
    const unitName = sourceDataId[sourceDataId.length - 1]

    const sourceTags = getDigitalTwinTagsFromDataId(unitName, allTagsDict)

    if (sourceTags && tagsAreIncludedInFiltering(sourceTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
      return datasetInstruction
    }
  } else if (instructionItem?.fake) { //is the found sourceItem from a calc variable, then it will be included in filtering if it has tags from the digital twin model json or its parent item


    const sourceTags = getTagsAccordingToTagsLogicForItem(instructionItem, allTagsDict)
    if (tagsAreIncludedInFiltering(sourceTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
      return datasetInstruction
    }
  }

  return undefined
}

function filterDbSDatasetInstruction(
  datasetInstruction: DatasetInstruction,
  items: UiConfigPropsItem[],
  currentActiveTagsPerRoute: Set<string>,
  allTagsPerRoute: Set<string>,
  allTagsDict: { [unitName: string]: string[] }
): DatasetInstruction | undefined {
  // Filters out sources in a DI with sources.
  const newInternalIds: string[] = []
  const contract = datasetInstruction.contract as DatasetContractDbS

  if (!contract?.metadata_filter?.include?.internal_id?.length) {
    return undefined
  }

  const idToItem: Record<string, UiConfigPropsItem> = {}
  items.forEach((item) => {
    idToItem[item.data_id] = item
  })

  contract.metadata_filter.include.internal_id?.forEach((internalId) => {
    const returnId = contract?.id_renaming?.[internalId] || internalId
    const returnIds = typeof returnId === 'string' ? [returnId] : returnId

    returnIds.forEach((returnId) => {
      const sourceItem = idToItem[returnId]
      if (sourceItem && !sourceItem.fake) {
      // sources which have an item in the uiconfig will be included in filtering iftagsAreIncludedInFiltering(...) returns true

        if (tagsAreIncludedInFiltering(sourceItem?.tags ?? [], currentActiveTagsPerRoute, allTagsPerRoute)) {
          newInternalIds.push(internalId)
        }

      } else if (!sourceItem) {
      // sources without an item in the uiconfig will be included in filtering if the source has tags from digital twin model json, or if it has no tags at all
        const sourceDataId = internalId.split('.')
        const sourceTags = getDigitalTwinTagsFromDataId(sourceDataId, allTagsDict) // returns undefined if the source could not be found in the digital twin json model

        if (sourceTags && tagsAreIncludedInFiltering(sourceTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
          newInternalIds.push(internalId)
        }

      } else if (sourceItem.fake) {
      // is the found sourceItem from a calc variable, then it will be included in filtering if it has tags from the digital twin model json or its parent item
        const sourceDataId = internalId.split('.')
        const sourceTags = getDigitalTwinTagsFromDataId(sourceDataId, allTagsDict) // returns undefined if the source could not be found in the digital twin json model
        const sourceItemTags = sourceItem.tags ?? []
        const soughtTags = sourceItemTags.concat(sourceTags ?? [])

        if (tagsAreIncludedInFiltering(soughtTags, currentActiveTagsPerRoute, allTagsPerRoute)) {
          newInternalIds.push(internalId)
        }
      }
    })
  })

  if (!newInternalIds.length) {
    return undefined
  }

  const newDatasetInstruction = clone(datasetInstruction)
  ;(newDatasetInstruction.contract as DatasetContractDbS).metadata_filter.include.internal_id = newInternalIds

  return newDatasetInstruction
}

function tagsAreIncludedInFiltering(tags: string[], currentActiveTagsPerRoute: Set<string>, allTagsPerRoute: Set<string>): boolean {

  const filteredTags = tags.filter((tag) => allTagsPerRoute.has(tag)) //filter out the tags that may have been misspelled or plainly does not exist in the digital twin model json

  if (filteredTags.length === 0) { //if there are no tags, return true.
    return true
  }

  else if (filteredTags.every((tag) => currentActiveTagsPerRoute.has(tag))) { // if all of the tags are currently filtered in, return true
    return true
  }

  return false
}


export function filterDatasetInstructionsNotShown(datasetInstructions: any[], items: UiConfigPropsItem[], currentActiveTagsPerRoute: Set<string>, allTagsPerRoute: Set<string>, allTagsDict: { [unitName: string] : string[] }): DatasetInstruction[]{
  const newDatasetInstructions: any[] = []

  datasetInstructions.forEach((instruction) => {
    if (instruction.type === 'calc') {
      for (let i = 0; i < items?.length; i++) {
        const item = items[i]

        if (tagsAreIncludedInFiltering(item?.tags ?? [], currentActiveTagsPerRoute, allTagsPerRoute)) {
          newDatasetInstructions.push(instruction)
          break
        }
      }

    } else if (instruction.contract?.sources && instruction.contract.sources.length > 0) {
      const datasetInstructionWithSources = filterDatasetInstructionWithSources(instruction, items, currentActiveTagsPerRoute, allTagsPerRoute, allTagsDict)

      const instructionAlreadyExistsInDatasetInstructions = newDatasetInstructions
        .find((datasetInstruction) => {
          datasetInstruction.contract
          && datasetInstructionWithSources.contract
          && datasetInstruction.filter
          && datasetInstructionWithSources.filter
          && datasetInstruction.return_id
          && datasetInstructionWithSources.return_id
          && datasetInstruction.type
          && datasetInstructionWithSources.type
        })

      if (instructionAlreadyExistsInDatasetInstructions) {
        const merge = <T>(a: T[], b: T[], predicate: (a: T, b: T) => boolean = (a, b) => a === b): T[] => {
          const c = clone(a) // copy to avoid side effects
          // add all items from B to copy C if they're not already present
          b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
          return c
        }

        const newSources = merge(instructionAlreadyExistsInDatasetInstructions.contract.sources, datasetInstructionWithSources.contract.sources, (a: any, b: any) => a.return_id === b.return_id)
        newDatasetInstructions.push({ ...instructionAlreadyExistsInDatasetInstructions, contract: { ...instructionAlreadyExistsInDatasetInstructions.contract, sources: newSources } })
      } else {
        newDatasetInstructions.push(datasetInstructionWithSources)
      }

    } else if (instruction.type === 'dbs') {
      const filteredDatasetInstruction = filterDbSDatasetInstruction(instruction, items, currentActiveTagsPerRoute, allTagsPerRoute, allTagsDict)
      if (filteredDatasetInstruction) {
        newDatasetInstructions.push(filteredDatasetInstruction)
      }
    } else {
      const datasetInstructionWithoutSources = filterDatasetInstructionWithoutSources(instruction, items, currentActiveTagsPerRoute, allTagsPerRoute, allTagsDict)

      if (datasetInstructionWithoutSources) {
        newDatasetInstructions.push(datasetInstructionWithoutSources)
      }
    }
  })
  return newDatasetInstructions
}

//------ Fetching tags --------

export function getDigitalTwinTagsFromDataId(unitName: string | string[], allTagsDict: { [unitName: string] : string[] }): string[] | undefined { //getting tags from digital twin based on data_id


  const tryTagsForEachPrefix = (prefixes: string[]): string[] | undefined => {
    //TODO: We might need to add distinguishment between different outputs, e.g unit.var_panna.el and unit.var_panna.fjv
    for (const prefix of prefixes) {

      const tagsFromDTModel = allTagsDict[prefix + '.' + unitName]

      if (tagsFromDTModel) {
        return tagsFromDTModel
      }
    }
  }

  const tags = tryTagsForEachPrefix(['unit', 'exchange', 'balance', 'commodity'])
  return tags ?? []

}

export function getItemsFromVariableCalcs(datasetInstructions: any[], items: UiConfigPropsItem[], allTagsDict: { [unitName: string] : string[] }): UiConfigPropsItem[] {
  const variableItems: UiConfigPropsItem[] = []
  datasetInstructions.forEach((instruction) => {
    if (instruction.type === 'calc') {
      const calcItem = items.find((item) => item.data_id === instruction.return_id)
      const tags = calcItem ? getTagsAccordingToTagsLogicForItem(calcItem, allTagsDict) : []

      instruction.contract.variables?.forEach((variable: any) => {
        const variableExistsAsItem = items.find((item) => item.data_id === variable)
        if (typeof variable === 'string' && !variableExistsAsItem) {
          variableItems.push({ data_id: variable, tags: tags, fake: true, title: variable })
        }
      })

    }
  })

  const uniqueVariableItems = Array.from(new Set(variableItems))

  return uniqueVariableItems
}

export function getTagsAccordingToTagsLogicForItem(item: UiConfigPropsItem, allTagsDict: { [unitName: string] : string[] }): string[] {
  let itemTags: string[] = []

  if (item.tags && item.tags.length !== 0) { //if item has tags, the tags will be used
    itemTags = Array.from(new Set(item.tags))
  }
  else if (item.get_tags_from) { //if item has get_tags_from, the tags will be fetched from the get_tags_from (could be several in the dict)
    itemTags = []
    item.get_tags_from.forEach((name) => { //unit.p7.input.fjv
      const dictTags = allTagsDict[name]
      if (!dictTags) {
        itemTags.push(...getDigitalTwinTagsFromDataId(name, allTagsDict ?? {}) ?? [])
      } else {
        itemTags.push(...dictTags ?? [])
      }
    })
    itemTags = Array.from(new Set(itemTags))
  }
  else { //else the tags will be fetched from the data_id
    const dataIdComponents = item?.data_id?.split('.')

    if (dataIdComponents?.length === 0 || !dataIdComponents) {
      itemTags = []
    } else {
      const unitName = dataIdComponents[dataIdComponents.length - 1]  //should be t.ex var_panna
      itemTags = getDigitalTwinTagsFromDataId(unitName, allTagsDict ?? {}) ?? []
    }
  }

  return itemTags
}

export function getItemsWithTags(uiConfigPropsItems: UiConfigPropsItem[], allTagsDict: { [unitName: string] : string[]; } ): UiConfigPropsItem[] { //TODO: This mapping can only function if the data_ids are in the format of "production.heat.osv"
  const itemsWithTags = uiConfigPropsItems.map((item) => {
    const itemTags: string[] = getTagsAccordingToTagsLogicForItem(item, allTagsDict)

    return {
      ...item,
      tags: itemTags,
    }

  })

  return itemsWithTags

}

export function getDigitalTwinByReturnId(uiConfig: UiConfig | null, systems: System[] | undefined, digitalTwins: DefaultPageNumberPagination<DigitalTwin[]> | undefined): { [returnId: string]: DigitalTwin | undefined }  {
  const digitalTwinByReturnId: { [returnId: string]: DigitalTwin | undefined } = {}
  uiConfig?.dataset_instructions?.forEach((datasetInstruction) => {
    if (!datasetInstruction.contract) {
      return
    }

    let digitalTwin: DigitalTwin | undefined = undefined

    if ('system_id' in datasetInstruction.contract) {
      const instructionSystemId = datasetInstruction.contract.system_id
      digitalTwin = systems?.find((s) => s.id === instructionSystemId)?.primary_digital_twin as DigitalTwin | undefined
    } else if ('opt_model_id' in datasetInstruction.contract) {
      digitalTwin = undefined
    } else if ('digital_twin_id' in datasetInstruction.contract) {
      const instructionDigitalTwinId = datasetInstruction.contract.digital_twin_id
      const instructionDigitalTwins = digitalTwins?.results.filter((dt) => dt.id === instructionDigitalTwinId)
      if (instructionDigitalTwins !== undefined) {
        const instructionDigitalTwinVersions = instructionDigitalTwins?.map((dt) => dt.version)
        digitalTwin = instructionDigitalTwins.find((dt) => (dt.version = Math.max(...instructionDigitalTwinVersions)))
      }
    } else if ('digital_twin_uid' in datasetInstruction.contract) {
      datasetInstruction.contract.digital_twin_uid
    }

    if ('sources' in datasetInstruction.contract) {
      const contract = datasetInstruction.contract as DatasetContractWithSources
      contract.sources.forEach((source) => {
        digitalTwinByReturnId[source.return_id] = digitalTwin
      })
    } else {
      digitalTwinByReturnId[datasetInstruction.return_id as string] = digitalTwin
    }
  })

  return digitalTwinByReturnId
}

export function getFuelFromTags(tags: string[] | undefined): string { //always returns the fuel from tag
  const stringArrayWithFuel = tags?.find((tag) => tag.split('/')[0].includes('fuel'))

  if (stringArrayWithFuel) {
    return stringArrayWithFuel.split('/')[1]
  }
  return ''
}
