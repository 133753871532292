import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import SelectButton from 'api/SelectButton/SelectButton'
import { ConfigurableProperty, DeviationSettingLevel, DigitalTwin, DigitalTwinSetting, useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Dialog } from 'ui/components/Dialog/Dialog'
import PreviousSettingsTable from 'ui/components/DigitalTwinEditSettingsModal/PreviousSettingsTable/PreviousSettingsTable'
import { DigitalTwinSettingDialog } from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialog'
import { CreateSettingData } from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialogContent'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import { getPropertiesFromDigitalTwinModel } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getPrettyName, getSettingsWithUnitsFromMatchingConfigurableProperty } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './EventSettingsOverviewTable.module.less'

type EventSettingsOverviewTableProps = {
  level?: string | undefined
  attribute?: string
  name?: string
  index: number
  eventPost?: EventPost
  settings?: DigitalTwinSetting[]
  refetchSettings: () => void
}

export default function EventSettingsOverviewTable({
  level, 
  attribute, 
  name, 
  index, 
  eventPost, 
  settings, 
  refetchSettings,
}: EventSettingsOverviewTableProps): ReactElement {
  const { t } = useTranslation()
  const { activeSystem, hasAccess } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true)

  const [openCreateSettingModal, setOpenCreateSettingModal] = useState(false)
  const [openEditSettingModal, setOpenEditSettingModal] = useState(false)

  const hasUpdateActiveOperationalEventsAccess = useHasPermission('update_active_operational_events')
  const hasUpdateOperationalEventsAccess = useHasPermission('update_operational_events')

  const [initData, setInitData] = useState<null | CreateSettingData>(null)
  const priorities = useMemo(() => [
    ...DEVIATION_SETTING_PRIORITIES,
    ...(activeSystem?.setting_priority_levels ?? []),
  ], [activeSystem])

  const onClickSelectButtonForSetting = useCallback(() => {
    setInitData({
      level: level as DeviationSettingLevel,
      name: name,
      attribute: attribute,
      start_time: eventPost?.start_time,
      end_time: eventPost?.end_time,
      priority: priorities[0].priority_level,
      isTimeSeries: false,
      operational_event: eventPost?.id ?? null,
    })
    setOpenCreateSettingModal(true)
  }, [attribute, eventPost?.end_time, eventPost?.id, eventPost?.start_time, level, name, priorities])

  const onClickSelectButtonForTimeSeriesSetting = useCallback(() => {
    setInitData({
      level: level as DeviationSettingLevel,
      name: name,
      attribute: attribute,
      start_time: eventPost?.start_time,
      end_time: eventPost?.end_time,
      value: [],
      priority: priorities[0].priority_level,
      isTimeSeries: true,
      operational_event: eventPost?.id ?? null,
    })
    setOpenCreateSettingModal(true)
  }, [attribute, eventPost?.end_time, eventPost?.id, eventPost?.start_time, level, name, priorities])

  const settingsForChosenPropertyWithUnits = getSettingsWithUnitsFromMatchingConfigurableProperty(digitalTwin, settings ?? [])
  const hasAccessToTimeseries = hasAccess({ submodule: 'optimizesettings_time_series', module: 'events' })

  const onEditSetting = useCallback((setting: DigitalTwinSetting): void => {
    const value = typeof setting.value === 'string' ? Number(setting.value) : setting.value
    setInitData({
      ...setting,
      value: value,
      operational_event: setting.operational_event ?? null,
    })
    setOpenEditSettingModal(true)
  }, [])

  const allConfigurableProperties: ConfigurableProperty[] = useMemo(
    () => digitalTwin?.model ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model) : [],
    [digitalTwin]
  )
  
  return (
    <>
      <div className={styles.EventSettingsOverviewTable_CreateSettingsButton}>
        {eventPost?.status !== 'Active' && settingsForChosenPropertyWithUnits.length > 0 && (
          <InfoBanner text={t('Settings are currently inactive. Activate event to activate settings')} style='warning' />
        )}
        {(!hasUpdateOperationalEventsAccess || !hasUpdateActiveOperationalEventsAccess && (eventPost?.status === 'Active' || eventPost?.status === 'Inactive')) 
          ? <></> 
          : hasAccess({ submodule: 'optimizesettings_time_series', module: 'events' }) 
            ? (
              <SelectButton
                icon="fal fa-plus"
                primary
                menuItems={[
                  {
                    label: t('Setting'),
                    onClick: onClickSelectButtonForSetting,
                  },
                  {
                    label: t('Time series setting'),
                    onClick: onClickSelectButtonForTimeSeriesSetting,
                  },
                ]}
              >
                {t(`Create new setting`)}
              </SelectButton>
            ) : (
              <Button
                primary
                onClick={onClickSelectButtonForSetting}>
                {t(`Create new setting`)}
              </Button>
            )
        }
      </div>

      <PreviousSettingsTable
        allSettingsForChosenProperty={settingsForChosenPropertyWithUnits}
        onEditSetting={onEditSetting}
        permissions={{
          canEdit: (eventPost?.status === 'Active' || eventPost?.status === 'Inactive')
            ? hasUpdateActiveOperationalEventsAccess
            : hasUpdateOperationalEventsAccess,
          canEditHistoric: (eventPost?.status === 'Active' || eventPost?.status === 'Inactive')
            ? hasUpdateActiveOperationalEventsAccess
            : hasUpdateOperationalEventsAccess,
        }}
        eventPost={{
          isOperationalEvent: true,
          eventId: eventPost?.id ?? null,
          linked_objects: eventPost?.linked_objects ?? null,
          index: index,
        }}
      />

      <Dialog open={openCreateSettingModal || openEditSettingModal} fullWidth maxWidth="md">
        <CustomDialogTitle title={`${getPrettyName(`${level}.${name}`, digitalTwin?.translations)}: ${getPrettyName(`${level}.${name}.${attribute}`, digitalTwin?.translations)}`} handleClose={() => {openCreateSettingModal ? setOpenCreateSettingModal(false) : setOpenEditSettingModal(false)}} />
        <DialogContent>
          <div>
            {initData?.isTimeSeries && !hasAccessToTimeseries && (
              <InfoBanner text={t('Unfortunately, you do not have permission to make changes here')} style='warning' />
            )}
            <DigitalTwinSettingDialog
              propertyItems={settings ?? []}
              digitalTwin={digitalTwin ?? {} as DigitalTwin}
              allowEmpty={false}
              priorities={priorities}
              operationalEvent={eventPost}
              initData={initData}
              configurableProperties={allConfigurableProperties}
              isBaseSettings={false}
              hasUnitAndProperty={true}
              onSettingModified={refetchSettings}
              onClose={() => {
                setInitData(null)
                openCreateSettingModal ? setOpenCreateSettingModal(false) : setOpenEditSettingModal(false)
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}