import { useTranslation } from 'react-i18next'

import { ValidationError } from './validation'

type FormatErrorMessage = {
  format: (validationError: ValidationError) => string
}

export function useFormatErrorMessage(): FormatErrorMessage {
  const { t } = useTranslation()

  const format = (validationError: ValidationError): string => {
    switch (validationError) {
      case 'REVERSED_RANGE':
        return t('End date must be after start date')
      case 'MISSING_END_DATE':
        return t('End date is required')
      case 'MISSING_START_DATE':
        return t('Start date is required')
      case 'RANGE_OVERFLOW':
        return t('Date is too far in the future')
      case 'RANGE_UNDERFLOW':
        return t('Date is too far in the past')
      default: {
        const _exhaustiveCheck: never = validationError
        return _exhaustiveCheck
      }
    }
  }

  return { format }
}

export function useFormatErrorMessageOptimizeView(): FormatErrorMessage {
  const { t } = useTranslation()

  const format = (validationError: ValidationError): string => {
    switch (validationError) {
      case 'REVERSED_RANGE':
        return t('End date must be after start date')
      case 'MISSING_END_DATE':
        return t('End date is required')
      case 'MISSING_START_DATE':
        return t('Start date is required')
      case 'RANGE_OVERFLOW':
        return t('Production plan results are only available for the next week. Please select a different date.')
      case 'RANGE_UNDERFLOW':
        return t('You can only select a date within the past week. To view older data, please go to the follow-up view.')
      default: {
        const _exhaustiveCheck: never = validationError
        return _exhaustiveCheck
      }
    }
  }

  return { format }
}