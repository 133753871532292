
import { DigitalTwin, DigitalTwinLayout } from 'api/digitalTwin/digitalTwin.api'

import i18n from 'i18next'
import {
  Node,
  Edge,
  MarkerType,
} from 'reactflow'

type NodeBalance = {
  commodity: string,
  balance: string,
  outputUnitIds: string[],
  inputUnitIds: string[],
  node: Node,
}

type NodeCommodity= {
  name: string,
  outputUnitIds: string[],
  inputUnitIds: string[],
  node: Node,
}

const MODEL_INIT_POSITION = 0
const BALANCE_INIT_POSITION = 200
const COMMODITY_INIT_POSITION = 0

function getBalancesAsNodeObjects(currentDigitalTwin: DigitalTwin): NodeBalance[] {
  
  const balanceNodes: NodeBalance[] = currentDigitalTwin?.model?.balances?.map((balance, balanceIndex: number) => {

    const node = getNode(
      `b${(balanceIndex + 1).toString()}`,
      balance.name,
      balance.name,
      { x: (balanceIndex + 1) * 200, y: BALANCE_INIT_POSITION },
      'balance',
      currentDigitalTwin.layout
    )
    return {
      commodity: balance.commodity,
      balance: balance.name,
      outputUnitIds: [],
      inputUnitIds: [],
      node: node,
    }
  })

  return balanceNodes
}

function getCommoditiesAsNodeObjects(currentDigitalTwin: DigitalTwin): NodeCommodity[] {
  const commodityNodes: NodeCommodity[] = currentDigitalTwin?.model?.commodities?.map((commodity, commodityIndex: number) => {

    const node = getNode(
      `c${(commodityIndex + 1).toString()}`,
      commodity.name,
      commodity.name,
      { x: (commodityIndex + 1) * 200, y: COMMODITY_INIT_POSITION },
      'commodity',
      currentDigitalTwin.layout
    )
    return {
      name: commodity.name,
      outputUnitIds: [],
      inputUnitIds: [],
      node: node,
    }
  })

  return commodityNodes
}

export function getNodesAndEdgesFromJson(currentDigitalTwin: DigitalTwin): { nodeArray: Node[], edgeArray: Edge[] } {
  const nodeArray: Node[] = []
  const edgeArray: Edge[] = []

  const balanceNodeObjects: NodeBalance[] = getBalancesAsNodeObjects(currentDigitalTwin)
  const commodityNodeObjects: NodeCommodity[] = getCommoditiesAsNodeObjects(currentDigitalTwin) 

  currentDigitalTwin?.model?.nodes?.map((node, nodeIndex: number) => { 
    node.units?.forEach((unit, unitIndex: number) => {
      const unitId = `n${(nodeIndex + 1).toString()}_u${(unitIndex + 1).toString()}`

      //Add unit ids to output or input for balances and commodities to be able to create edges from them later
      Object.keys(unit.outputs ?? {}).forEach((outputCommodity) => {
        const balanceNode = balanceNodeObjects.find((balance) => (
          unit.outputs && balance.balance === unit.outputs[outputCommodity].balance
        ))
        const commodityNode = commodityNodeObjects.find((commodity) => (
          unit.outputs && commodity.name === outputCommodity && !unit.outputs[outputCommodity].balance
        ))

        if (balanceNode) {
          balanceNode.outputUnitIds.push(unitId)
        }
        if (commodityNode) {
          commodityNode.outputUnitIds.push(unitId)
        }
      })

      Object.keys(unit.inputs ?? {}).forEach((inputCommodity) => {
        const balanceNode = balanceNodeObjects.find((balance) => (
          unit.inputs && balance.balance === unit.inputs[inputCommodity].balance
        ))
        const commodityNode = commodityNodeObjects.find((commodity) => (
          unit.inputs && commodity.name === inputCommodity && !unit.inputs[inputCommodity].balance
        ))      

        if (balanceNode) {
          balanceNode.inputUnitIds.push(unitId)
        }

        if (commodityNode) {
          commodityNode.inputUnitIds.push(unitId)
        }
      })

      //Create all edges to and from balance nodes
      balanceNodeObjects.forEach((balance) => {
        balance.outputUnitIds.map((id) => {
          const newEdge = getEdge(id, balance.node.id, i18n.t(balance.commodity))
          if (newEdge && !edgeArray.some((edge) => edge.id === newEdge.id)) {
            edgeArray.push(newEdge)
          }
        })

        balance.inputUnitIds.forEach((id) => {
          const newEdge = getEdge(balance.node.id, id, i18n.t(balance.commodity))

          if (newEdge && !edgeArray.some((edge) => edge.id === newEdge.id)) {
            edgeArray.push(newEdge)
          }
        })
      })

      //Remove commodities without node connections
      const filteredCommodityNodeObjects = commodityNodeObjects.filter(commodity => 
        commodity.inputUnitIds.length > 0 || commodity.outputUnitIds.length > 0
      )

      //Create all edges to and from commodity nodes
      filteredCommodityNodeObjects.forEach((commodity) => {
        commodity.outputUnitIds.map((id) => {    
          const newEdge = getEdge(id, commodity.node.id, i18n.t(commodity.name))
          if (newEdge && !edgeArray.some((edge) => edge.id === newEdge.id)) {
            edgeArray.push(newEdge)
          }
        })

        commodity.inputUnitIds.forEach((id) => {
          const newEdge = getEdge(commodity.node.id, id, i18n.t(commodity.name))

          if (newEdge && !edgeArray.some((edge) => edge.id === newEdge.id)) {
            edgeArray.push(newEdge)
          }
        })
      })

      nodeArray.push(getNode(
        unitId,
        unit.display_name ?? unit.name,
        unit.name,
        { x: (unitIndex + 1) * 200, y: 100 },
        'unit',
        currentDigitalTwin.layout
      ))

      //Add model node and edge to unit to arrays if show_model_node is true
      if(currentDigitalTwin?.model?.show_model_node){
        nodeArray.unshift(getNode(
          `m${nodeIndex}`,
          node.display_name ?? node.name,
          node.name,
          { x: MODEL_INIT_POSITION + 250, y: MODEL_INIT_POSITION },
          'model',
          currentDigitalTwin.layout
        ))}
      const edge = getEdge(`m${nodeIndex}`, unitId, `${unit.type}`)
      if (edge) {
        edgeArray.push(edge)
      }

      balanceNodeObjects.map((balance) => {
        nodeArray.push(balance.node)
      })

      filteredCommodityNodeObjects.map((commodity) => {
        nodeArray.push(commodity.node)
      })

    })
  })

  currentDigitalTwin.model?.exchanges?.map(exchange => {
    const from_balance = balanceNodeObjects.find(node => node.balance === exchange.balance_from)
    const to_balance = balanceNodeObjects.find(node => node.balance === exchange.balance_to)
    if (from_balance && to_balance) {
      const newEdge = getEdge(from_balance.node.id, to_balance.node.id, i18n.t(from_balance.commodity), false)
      if (newEdge && !edgeArray.some((edge) => edge.id === newEdge.id)) {
        edgeArray.push(newEdge)
      }
    }
  })

  return { nodeArray, edgeArray }
}

export function getEdge(sourceId: string, targetId: string, label: string, _animated?: boolean): Edge | undefined {

  // Node -> Balance
  if (sourceId.includes('n') && targetId.includes('b')) {
    return {
      id: `e${sourceId}-${targetId}`, source: sourceId, target: targetId, label: label, type: 'smoothstep',
      markerStart: 'default',
      markerEnd: { type: MarkerType.Arrow, color: '#000', width: 20, height: 20},
      animated: false,
      style: {
        strokeWidth: 2,
        stroke: '#ff0072',
      },
      labelBgPadding: [4, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: '#FFCC00', fillOpacity: 0.7 },
    }
  }

  // Exchange (Balance -> Balance)
  if (sourceId.includes('b') && targetId.includes('b')) {
    return {
      id: `e${sourceId}-${targetId}`, source: sourceId, target: targetId, label: label, type: 'smoothstep',
      markerStart: 'default',
      markerEnd: { type: MarkerType.Arrow, color: '#000', width: 20, height: 20},
      animated: false,
      style: {
        strokeWidth: 2,
        stroke: '#ad24bd',
      },
      labelBgPadding: [4, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: '#FFCC00', fillOpacity: 0.7 },
    }
  }

  //Commodity edges
  if (sourceId.includes('c') || targetId.includes('c')) {
    return {
      id: `e${sourceId}-${targetId}`, source: sourceId, target: targetId, label: label, type: 'smoothstep',
      markerStart: 'default',
      markerEnd:  { type: MarkerType.Arrow, color: '#000', width: 20, height: 20},
      animated: false,
      style: {
        strokeWidth: 2,
        stroke: '#EE7538',
      },
      labelBgPadding: [4, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: '#EF824C', fillOpacity: 0.7 },
    }
  }

  if (sourceId.includes('m')) {
    return {
      id: `e${sourceId}-${targetId}`, source: sourceId, target: targetId, label: label, type: 'smoothstep',
      markerStart: 'default',
      markerEnd: 'default',
      animated: false,
      style: {
        strokeWidth: 2,
        stroke: '#787878',
      },
      labelBgPadding: [4, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: '#c9c8c3', fillOpacity: 0.7 },
    }
  }

  if (targetId.includes('n')) {
    return {
      id: `e${sourceId}-${targetId}`, source: sourceId, target: targetId, label: label, type: 'smoothstep',
      markerStart: 'default',
      markerEnd: { type: MarkerType.Arrow, color: '#000', width: 20, height: 20},
      animated: false,
      style: {
        strokeWidth: 2,
        stroke: '#ebc334',
      },
      labelBgPadding: [4, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: { fill: '#FFCC00', fillOpacity: 0.7 },
    }
  }
}

export type NodeType = 'balance' | 'unit' | 'model' | 'commodity'

function getNodePosition(
  name: string,
  type: NodeType,
  defaultPos: {x: number, y: number},
  layout?: DigitalTwinLayout): {x: number, y: number} {

  if (!layout) {
    return defaultPos
  }

  const position = layout[getLayoutKey(type, name)]

  return position ?? defaultPos
}

function getNode(id: string, label: string, name: string, defaultPos: { x: number, y: number }, type: NodeType, layout?: DigitalTwinLayout): Node {

  let style
  let nodeType

  const position = getNodePosition(
    name,
    type,
    defaultPos,
    layout
  )

  if (type === 'balance') {
    style = {
      width: 150,
      borderRadius: 50,
      background: '#AFD4E8',
    }
  }

  if (type === 'commodity') {   
    style = {
      width: 150,
      borderRadius: 50,
      background: 'rgb(239, 130, 76, 0.7)',
    }
  }

  if (type === 'unit') {
    style = {
      width: 150,
      borderRadius: 10,
      background: 'rgb(40, 180, 99, 0.3)',
    }
  }

  if (type === 'model') {
    nodeType = 'input',
    style = {
      width: 200,
      background: 'rgba(248, 166, 23, 0.4)',
      borderRadius: 5,
    }
  }

  return {
    id: id,
    data: {
      label: label,
      type,
      name: name,
    },
    style: style,
    type: nodeType,
    position: position,
  }

}

export function getNodesAndEdgesFromCurrentDigitalTwin(currentDigitalTwin?: DigitalTwin): {nodeArray: Node[], edgeArray: Edge[]} {
  if (!currentDigitalTwin) {
    return {nodeArray: [], edgeArray: []}
  }

  const hasAModel = JSON.stringify(currentDigitalTwin.model) !== '{}'

  if (!hasAModel) {
    return {nodeArray: [], edgeArray: []}
  }

  return getNodesAndEdgesFromJson(currentDigitalTwin)
}

export function getLayoutKey(type: NodeType, name: string): string {
  return `${type}.${name}`
}