import React, { ReactElement } from 'react'

import authStore from 'store/auth/auth'

import {  List, ListItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './MyPermissions.module.less'

export default function MyPermissions(): ReactElement {
  const { t } = useTranslation()

  const authSnap = useSnapshot(authStore)
  const isSuperUser = authSnap.isSuperUser
  return (
    <div className={styles.MyPermissions}>
      <List>
        {isSuperUser && <ListItem>{t('You are super user')}</ListItem>}
        {authSnap?.permissionsAndDescriptions?.map((item, index) => {
          return (
            <ListItem key={index}>{t(item.description)}</ListItem>
          )
        })}
      </List>
      <i>{t(`Do you have questions about your permissions? Please contact person in charge of your organisation or us via the 'Contact Us' form found at the top of the page.`)}</i>
    </div>
  )
}

