import { ReactElement } from 'react'

import {
  ConfigurableProperty,
  DigitalTwin,
  DigitalTwinPropertyObject,
} from 'api/digitalTwin/digitalTwin.api'
import { Dialog } from 'ui/components/Dialog/Dialog'
import { DigitalTwinSettingDialog } from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialog'
import { CreateSettingData } from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialogContent'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import { UnitSettingFormData } from 'ui/components/UnitSettingForm/UnitSettingForm'

import { useTranslation } from 'react-i18next'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'


type CreateSettingModalProps = {
  defaultValues?: Partial<UnitSettingFormData>
  propertyItems: DigitalTwinPropertyObject[]
  digitalTwin: DigitalTwin
  onClose: () => void
  allowEmpty: boolean
  onSettingModified: () => void
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  isBaseSettings?: boolean
  initData: null | CreateSettingData
  configurableProperties: ConfigurableProperty[]
}

export default function CreateSettingModal({
  initData,
  configurableProperties,
  propertyItems,
  digitalTwin,
  onClose,
  onSettingModified,
  priorities,
  baseSettingPriorities,
  isBaseSettings = false,
}: CreateSettingModalProps): ReactElement {
  const { t } = useTranslation()
  
  if (!digitalTwin || !initData) {
    return <></>
  }

  const settingTypeName = initData.isTimeSeries
    ? 'time series setting'
    : 'setting'

  const isEditingExistingSetting = initData.id !== undefined
  const action = isEditingExistingSetting
    ? 'Modify'
    : 'Create'

  return (
    <Dialog
      fullWidth
      open={true}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      onClose={onClose}
    >
      <CustomDialogTitle
        title={initData.name
          ? t(`${action} ${settingTypeName} for {{unit}}`, {unit: getPrettyName(initData.name, digitalTwin?.translations)})
          : t(`Create new ${settingTypeName}`)
        }
        handleClose={onClose}
      />
      <DigitalTwinSettingDialog
        initData={initData}
        configurableProperties={configurableProperties}
        onClose={onClose}
        onSettingModified={onSettingModified}
        propertyItems={propertyItems}
        digitalTwin={digitalTwin}
        allowEmpty={false}
        priorities={priorities}
        baseSettingPriorities={baseSettingPriorities}
        isBaseSettings={isBaseSettings}
        hasUnitAndProperty={false}
      />
    </Dialog>
  )
}