import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import 'ui/uiConfig/components/index'
import { useAllSystemUiConfigs, useUpdateUiConfigMutation } from 'api/uiConfig/uiConfig.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { Button, Inline } from 'ui/atoms'
import { canMigrateUiConfigToDbS, migrateUiConfigToDbS } from 'utils/dbs/dbs.helper'

import { DiffEditor } from '@monaco-editor/react'
import { useSnapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'

export default function ReviewDatasetView(): ReactElement {
  const { mutate: updateUiConfig } = useUpdateUiConfigMutation()
  const [activeUid, setActiveUid] = useState(0)
  const [isMigrating, setIsMigrating] = useState(false)
  const [migratedUiConfig, setMigratedUiConfig] = useState<any>(() => ({}))
  const [migrateError, setMigrateError] = useState('')
  useAllSystemUiConfigs()

  const uiConfigSnap = useSnapshot(uiConfigStore)
  const uiConfigs = Object
    .values<UiConfig>(uiConfigSnap?.uiConfigs || {})
    .map((uiConfig) => clone(uiConfig))
    .filter((uiConfig) => uiConfig.id && uiConfig.id > 0)

  const autoMigratableUiConfigs = uiConfigs
    .filter((uiConfig) => canMigrateUiConfigToDbS(uiConfig))

  const notAutoMigratableUiConfigs = uiConfigs
    .filter((uiConfig) =>
      !canMigrateUiConfigToDbS(uiConfig)
      && uiConfig.dataset_instructions
        .filter(di => di.type === 'optresults' || di.type === 'meas')
        .length
    )

  const uiConfig = useMemo(() => clone(uiConfigSnap?.uiConfigs[activeUid]) || {}, [uiConfigSnap, activeUid])
  useEffect(() => {
    if (!uiConfig?.dataset_instructions) {
      setMigratedUiConfig({})
      return
    }

    setMigrateError('')

    try {
      const m = migrateUiConfigToDbS(uiConfig)
      setMigratedUiConfig(m)
    } catch (err: any) {
      console.error(err)
      setMigrateError(err.message)
      setMigratedUiConfig({})
    }
  }, [activeUid, uiConfig])

  console.debug({ uiConfigs, autoMigratableUiConfigs, notAutoMigratableUiConfigs, uiConfig, migratedUiConfig })

  return (
    <>
      <h1>Migrate To DbS</h1>
      <p>
        <b>{uiConfigs.length}</b> UiConfigs in system.
        <br />
        <b>{autoMigratableUiConfigs.length}</b> can be auto-migrated.
      </p>

      <Button danger
        disabled={isMigrating}
        onClick={async () => {
          setIsMigrating(true)
          setMigrateError('')

          await Promise.all(autoMigratableUiConfigs.map(async (uiConfig) => {
            try {
              const migratedUiConfig = migrateUiConfigToDbS(uiConfig)
              migratedUiConfig.is_published = true
              migratedUiConfig.mode = 'dev'
              await updateUiConfig(migratedUiConfig)
            } catch (err: any) {
              console.error(err)
              setMigrateError(err.message)
            }
          }))
        }}
      >
        {isMigrating ? 'MIGRATING...' : 'MIGRATE AND PUBLISH THEM ALL!'}
      </Button>
      {isMigrating && <pre>
        Check network tab for progress, refresh browser manually when completed.
      </pre>}

      <Inline spaceBetween alignTop>
        <div>
          <h3>Auto Migrateable</h3>
          <p>These do not throw any error if they would be migrated and the migration blob is not equal to the original blob.</p>
          <ol>
            {autoMigratableUiConfigs.map((uiConfig) => (
              <li key={uiConfig.id} onClick={() => {
                if (uiConfig.uid) {
                  setActiveUid(uiConfig.uid)
                }
              }}>
                <Inline>
                  {activeUid === uiConfig.uid && <>👉 </>}
                  <span><b>{uiConfig.display_name}</b> [{uiConfig.component}] <i>{uiConfig.id}.{uiConfig.version}</i></span>
                </Inline>
              </li>
            ))}
          </ol>
        </div>

        <div>
          <h3>Not auto migratable</h3>
          <p>But still have Dataset Instructions. Check these for errors and if they can be manually migrated or should be ignored.</p>
          <ol>
            {notAutoMigratableUiConfigs.map((uiConfig) => (
              <li key={uiConfig.id} onClick={() => {
                if (uiConfig.uid) {
                  setActiveUid(uiConfig.uid)
                }
              }}>
                <Inline>
                  {activeUid === uiConfig.uid && <>👉 </>}
                  <span><b>{uiConfig.display_name}</b> [{uiConfig.component}] <i>{uiConfig.id}.{uiConfig.version}</i></span>
                </Inline>
              </li>
            ))}
          </ol>

          {notAutoMigratableUiConfigs.length === 0 && <p><i>None.</i></p>}
        </div>
      </Inline>


      {activeUid > 0 && (
        <div>
          <h2>Active Id: {activeUid}</h2>

          <pre style={{ color: 'darkred' }}>
            {migrateError}
          </pre>

          <Button danger secondary marginBottom
            onClick={() => {
              migratedUiConfig.is_published = true
              migratedUiConfig.mode = 'dev'
              updateUiConfig(migratedUiConfig)
              window.location.reload()
            }}
          >
            Migrate and publish this UiConfig
          </Button>

          <DiffEditor
            original={JSON.stringify(uiConfig, null, 2)}
            modified={JSON.stringify(migratedUiConfig, null, 2)}
            language='json'
            height={800}
            options={{
              minimap: { enabled: false },
              readOnly: true,
              wordWrap: 'on',
              automaticLayout: true,
              diffAlgorithm: 'legacy',
              diffCodeLens: true,
              renderSideBySide: true,
              accessibilityVerbose: true,
            }}
          />
        </div>
      )}
    </>
  )
}