import React, { ReactElement } from 'react'

import { Icon, Button, Grid } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './PopoverCloseButton.module.less'

type PopoverCloseButtonProps = {
  handleClose: () => void
}

export default function PopoverCloseButton({ handleClose }: PopoverCloseButtonProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Grid item>
      <Button onClick={handleClose} className={styles.PopoverCloseButton_CloseButton} color="grey">
        {t(`Close`)}
        <Icon fontSize="inherit" className={classNames(`fal fa-times`, styles.PopoverCloseButton_CloseIcon)} />
      </Button>
    </Grid>
  )
}
