import React, { ReactElement, ReactNode, useContext, useEffect } from 'react'

import type { DateValue } from 'react-aria-components'
import {
  DatePicker as ReactAriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DatePickerStateContext,
} from 'react-aria-components'

import { Calendar } from '../Calendar/Calendar'
import { Popover } from '../Calendar/Popover'

import { CalendarDateField } from './DateField'
import styles from './DatePicker.module.less'
import { HelpText } from './HelpText'

type DatePickerProps = AriaDatePickerProps<DateValue> & {
  errorMessage?: string
  label?: string
  isPeriod?: boolean
  isEndPeriod?: boolean
  helperText?: ReactNode
  showWeekNumbers?: boolean
  allowEmpty?: boolean
  onError?: (error: boolean) => void
}

function InternalDatePicker(props: DatePickerProps): ReactElement {
  const { onError } = props
  const datePickerState = useContext(DatePickerStateContext)

  useEffect(() => {
    onError && onError(datePickerState.isInvalid)
  }, [onError, datePickerState.isInvalid])

  return (
    <>
      <div className={styles.DatePicker_DateFieldContainer}>
        <CalendarDateField label={props.label} disabled={props.isDisabled} {...props} />
        <HelpText {...props} />
      </div>

      <Popover>
        <Calendar showWeekNumbers={props.showWeekNumbers} allowEmpty={props.allowEmpty} />
      </Popover>
    </>
  )
}

export default function DatePicker(props: DatePickerProps): ReactElement {
  const fieldClass = props.isEndPeriod ? 'endDateField' : 'startDateField'

  return (
    <div className={props.isPeriod ? styles.DatePicker_period : styles.DatePicker}>
      <ReactAriaDatePicker className={`${fieldClass} ${styles.DatePicker_ReactAriaDatePicker}`} hideTimeZone {...props}>
        <InternalDatePicker {...props} />
      </ReactAriaDatePicker>
    </div>
  )
}
