import React, { ReactElement } from 'react'

import { useAuth } from 'ui/components/AuthContext/AuthContext'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'


// eslint-disable-next-line no-restricted-imports
import { Event } from '../../BarCalendar.types'

import styles from './BCEventTooltip.module.less'

type EventTooltipProps = {
  event: Event
}

export default function EventTooltip({ event }: EventTooltipProps): ReactElement {
  const { t } = useTranslation()
  const { hasAccess } = useAuth()

  const tooltipPeriod = `${t('Period')}: `
  const tooltipPeriodStartTime = event.startTime ? `${Datetime.toLocalTime(event.startTime, 'longDayTextWithoutYear')}` : ''
  const tooltipTo = `${t('to')}`

  const endTimeWith59Minutes = event.endTime ? Datetime.ISODatetimeTo59Minutes(event.endTime) : null
  const settingEndTimeLabel =
  (endTimeWith59Minutes === null) ? t('Until further notice') : Datetime.toLocalTime(endTimeWith59Minutes, 'longDayTextWithoutYear')
  const tooltipPeriodEndTime = `${settingEndTimeLabel}`

  const hasAccessToUMMCheckbox = hasAccess({ submodule: 'operational_event_umm', module: 'events' })

  return (
    <div className={styles.EventTooltip_Text}>
      <div className={styles.EventTooltip_Circle} style={{ backgroundColor: event.color }} />
      <div>
        <div
          className={`${styles.EventTooltip__bold}
            ${styles.EventTooltip__small}
            ${styles.EventTooltip__text_align_left}
            ${styles.EventTooltip__no_wrap}`}
        >
          {`${event.categoryName}: `}
          {event.tooltipHeader ?? event.name}
        </div>
        <div className={styles.EventTooltip__no_wrap}>
          {tooltipPeriod} <b>{tooltipPeriodStartTime}</b> {tooltipTo} <b>{tooltipPeriodEndTime}</b>
        </div>
        <div className={`${styles.EventTooltip__text_align_left} ${styles.EventTooltip__no_wrap}`}>
          {event?.meta?.value != undefined ? (
            <div>
              {t('Value')}: 
              {` ${event.meta.value}`}
            </div>
          ) : null}
          {event?.meta?.tooltipElement ?? ''}
          {event.require_umm && hasAccessToUMMCheckbox && (
            <div>
              {`${t('Requires UMM')}: `}{`${t('Yes')}`}
            </div>
          )}
          {event?.meta?.creatorName ? (
            <div>
              {t('Last modified by')}: 
              {` ${event.meta.creatorName}`}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
