import React, { useState } from 'react'

import { Button } from 'ui/atoms'

import { DateValue, Form } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import ReactAriaDatePicker from '../internal/DatePicker/DatePicker'

import { CompatibleDate, toDateValue, toISODateTime } from './utils/dateAdapter'

type ReactAriaDatePickerProps = React.ComponentPropsWithoutRef<typeof ReactAriaDatePicker>

type DatePickerProps = {
  value: CompatibleDate
  minValue?: CompatibleDate
  maxValue?: CompatibleDate
  onChange: (value: ISODateTime | null | undefined) => void
} & Omit<ReactAriaDatePickerProps, 'value' | 'minValue' | 'maxValue' | 'onChange'>

export function DatePicker(props: DatePickerProps): JSX.Element {
  const { value, onChange, minValue, maxValue, ...restProps } = props

  return (
    <ReactAriaDatePicker
      value={toDateValue(value)}
      minValue={toDateValue(minValue) || undefined}
      maxValue={toDateValue(maxValue) || undefined}
      onChange={(date) => {
        onChange(toISODateTime(date))
      }}
      {...restProps}
    />
  )
}

type DatePickerInFormProps = {
  value: CompatibleDate
  onSubmit: (value: ISODateTime) => void
} & Omit<ReactAriaDatePickerProps, 'value' | 'onChange'>

export function DatePickerInForm(props: DatePickerInFormProps): JSX.Element {
  const { t } = useTranslation()
  const { value, onSubmit, ...restProps } = props
  const [draftValue, setDraftValue] = useState<DateValue | null | undefined>(toDateValue(value))

  return (
    <Form
      style={{ display: `flex`, flexDirection: `row`, alignItems: `center` }}
      onSubmit={(e) => {
        e.preventDefault()

        // Should not be null or undefined after form has been submitted
        // The following is just done to appease TypeScript
        const isoDateTimeDraftValue = toISODateTime(draftValue)
        if (isoDateTimeDraftValue) {
          onSubmit(isoDateTimeDraftValue)
        }
      }}
    >
      <ReactAriaDatePicker
        {...restProps}
        value={draftValue}
        isRequired
        onChange={(date) => {
          setDraftValue(date)
        }}
      />
      <Button style={{ marginLeft: 10 }} primary icon="fal fa-redo">
        {t('Load')}
      </Button>
    </Form>
  )
}
