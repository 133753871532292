import { AlertVariant } from 'ui/components/Alert/Alert'

import { SnackbarProps } from '@mui/material'
import { proxy } from 'valtio'

export type Alert = { variant: AlertVariant; message: string; props?: Partial<SnackbarProps> }

type AlertSetter = (message: string, props?: Partial<SnackbarProps>) => void

type AlertStore = {
  alert?: Alert,
  error: AlertSetter,
  success: AlertSetter,
  warning: AlertSetter,
  info: AlertSetter,
  alertClear: () => void
}

const store: AlertStore = proxy<AlertStore>({
  error: (message: string, props?: Partial<SnackbarProps>) => store.alert = { variant: 'error', message, props },
  success: (message: string, props?: Partial<SnackbarProps>) => store.alert = { variant: 'success', message, props },
  warning: (message: string, props?: Partial<SnackbarProps>) => store.alert = { variant: 'warning', message, props },
  info: (message: string, props?: Partial<SnackbarProps>) => store.alert = { variant: 'info', message, props },
  alertClear: () => store.alert = undefined,
})

export default store