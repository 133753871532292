import React, { ReactElement, ReactNode } from 'react'

import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps, registerComponentHeight } from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import {
  getReturnIdsFromDatasetInstruction,
} from 'helpers/dataset.helper/dataset.helper'

import styles from './RealTimeOptimizationBars.module.less'
import RealTimeCard, { RealTimeCardData } from './components/RealTimeCard/RealTimeCard'


type RealTimeOptimizationBarsProps = {
  uid: UiConfigUid
  items?: ChartItem[]
  show_warning_at_age_in_minute: number
  card_width: number
  highlight_diff_at: number
  datasets: Dataset[]
  children: ReactNode
}

const defaultProps: Omit<RealTimeOptimizationBarsProps, `uid` | `datasets` | `children` | `ignoreZoom`> = {
  items: [],
  show_warning_at_age_in_minute: 15,
  card_width: 150,
  highlight_diff_at: 2,
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: RealTimeOptimizationBarsProps
): RealTimeOptimizationBarsProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId ?? ``,
    data_id: returnId,
    color: `$color-1`,
    decimals: 2,
    tooltip: ``,
    unit: ``,
    bar: false,
    stack_group: 0,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent('real_time_optimization_bars', RealTimeOptimizationBars, { isSingletonComponent: true })
registerAppendPropsWithDatasets('real_time_optimization_bars', appendPropsWithDataset)
registerDefaultProps('real_time_optimization_bars', defaultProps)
registerComponentHeight('real_time_optimization_bars', 91)

const OPTIMAL_ID_PREFIX = 'optimal'
const OUTCOME_ID_PREFIX = 'outcome'

function getLatestDatasetValue(datasetMap: Record<string, Dataset>, id: string): { time: ISODateTime | number, value: number } {
  const value = datasetMap[id]?.values?.[0] || 0
  const time = datasetMap[id]?.times?.[0] || Datetime.getISONow(0, false)
  return { value, time }
}

export default function RealTimeOptimizationBars({ items, datasets, show_warning_at_age_in_minute, card_width, highlight_diff_at }: RealTimeOptimizationBarsProps): ReactElement {
  const { t } = useTranslation()

  if (!items?.length) {
    return <></>
  }

  const showWarningAtAgeInMinute = show_warning_at_age_in_minute || 15
  card_width = 200
  const cardWidth = `${card_width || 150}px`
  const highlightDiffAt = highlight_diff_at ?? 2
  const realTimeCardsMap: Record<string, RealTimeCardData> = {}
  const datasetMap = datasets.reduce((acc, dataset) => ({ ...acc, [dataset.return_id]: dataset }), {})
  let maxValue = 0

  items.forEach((item, index) => {
    const id = item.data_id.replace(OPTIMAL_ID_PREFIX, '').replace(OUTCOME_ID_PREFIX, '')
    const isOptimal = item.data_id.startsWith(OPTIMAL_ID_PREFIX)
    const isOutcome = item.data_id.startsWith(OUTCOME_ID_PREFIX)
    const { value, time } = getLatestDatasetValue(datasetMap, item.data_id)
    const ageInMinutes = Datetime.getNrOfMinutesBetween(Datetime.getISONow(0, false), time)
    const isShowAsNegative = item.show_as_negative
    const calculatedValue = isShowAsNegative ? Math.abs(value) : value

    if (calculatedValue > maxValue) {
      maxValue = value
    }

    if (!realTimeCardsMap[id]) {
      realTimeCardsMap[id] = {
        title: item.title,
        id: id,
        optimalId: '',
        outcomeId: '',
        optimalIndex: -1,
        outcomeIndex: -1,
        optimalValue: 0,
        outcomeValue: 0,
        optimalAge: ageInMinutes,
        outcomeAge: ageInMinutes,
        age: ageInMinutes,
        isPeakUnit: !!item.is_peak_unit,
        highlightValue: false,
        unit: item.unit || '',
        dataType: item.data_type || '',
        color: item.color || 'black',
      }
    }

    const realTimeCard = realTimeCardsMap[id]

    if (isOptimal) {
      realTimeCard.optimalId = item.data_id
      realTimeCard.optimalIndex = index
      realTimeCard.optimalValue = calculatedValue
      realTimeCard.optimalAge = ageInMinutes
    } else if (isOutcome) {
      realTimeCard.outcomeId = item.data_id
      realTimeCard.outcomeIndex = index
      realTimeCard.outcomeValue = calculatedValue
      realTimeCard.outcomeAge = ageInMinutes
    }

    realTimeCard.age = Math.max(realTimeCard.optimalAge, realTimeCard.outcomeAge)
    realTimeCard.highlightValue = Math.abs(realTimeCard.outcomeValue - realTimeCard.optimalValue) > highlightDiffAt
    realTimeCardsMap[id] = realTimeCard
  })

  const realTimeCards = Object.values(realTimeCardsMap)
  const peakUnits = realTimeCards
    .filter(item => item.isPeakUnit)
    .filter(item => (item.optimalValue !== 0) || (item.outcomeValue !== 0))
  const baseUnits = realTimeCards.filter(item => !item.isPeakUnit)

  return (
    <div className={styles.RealTimeOptimizationBars}>
      {baseUnits.map((item, index) => RealTimeCard({ item, maxValue, cardWidth, showWarningAtAgeInMinute, key: index.toString() }))}

      {peakUnits.length !== 0 &&
        <div className={styles.RealTimeOptimizationBars_Delimiter}>
          <div className={styles.RealTimeOptimizationBars_Delimiter_Line} />
          <div className={styles.RealTimeOptimizationBars_Delimiter_Text}>{t('Peak units')}</div>
        </div>
      }

      {peakUnits.map((item, index) => RealTimeCard({ item, maxValue, cardWidth, showWarningAtAgeInMinute, key: index.toString()}))}
    </div>
  )
}
