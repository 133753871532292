import {
  ConfigurableProperty,
  DigitalTwinSetting,
  DeviationSettingLevel,
  DigitalTwin,
  TranslatedDeviationSetting,
  DigitalTwinSettingMetadata,
  DigitalTwinModel,
} from 'api/digitalTwin/digitalTwin.api'
import { Button } from 'ui/atoms'
import { PropertiesTableItemWithTooltip } from 'ui/components/PropertiesTable/PropertiesTable.types'
import SettingsStatusField from 'ui/components/PropertiesTable/components/SettingsStatusField/SettingsStatusField'
import Datetime from 'utils/datetime/datetime'

import { TFunction } from 'i18next'

import { getDigitalTwinSettingValueString } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { matchesSomeTagFromEveryTopLevel } from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'

export function getSettingValue(
  now: ISODateTime,
  setting?: TranslatedDeviationSetting | DigitalTwinSetting
): number | null {
  if (!setting) {
    return null
  }

  if (typeof setting.value === 'string') {
    return parseFloat(setting.value)
  } else if (typeof setting.value === 'number') {
    return setting?.value
  } else if (setting.start_time && setting.value && setting.value?.length > 0) {
    const hours = Datetime.getHoursBetween(setting.start_time, now)

    if (setting.value.length >= hours.length && hours.length > 0) {
      const value = setting.value[hours.length - 1].value
      if (value != null) {
        return value
      }
    }
  }
  return null
}

type UseSettingsTableDataPrams = {
  activeBaseSettingByProperty: { [key: string]: TranslatedDeviationSetting }
  activeSettingByProperty: { [key: string]: TranslatedDeviationSetting }
  digitalTwin?: DigitalTwin
  filteredProperties: ConfigurableProperty[]
  historySettingByProperty: { [key: string]: TranslatedDeviationSetting }
  isBaseSettings: boolean
  periodStartTime: ISODateTime | null
  periodEndTime: ISODateTime | null
  canEdit: boolean
  t: TFunction<'translation', undefined>
  upcomingSettingByProperty: { [key: string]: TranslatedDeviationSetting }
  setActiveSetting: (setting?: { level: DeviationSettingLevel; name: string; attribute?: string }) => void
  loading: boolean
}

// TODO: Only used for sandbox, should be changed to digital twin setting metadata
export function getPropertyTableItems({
  activeBaseSettingByProperty,
  activeSettingByProperty,
  digitalTwin,
  filteredProperties,
  historySettingByProperty,
  isBaseSettings,
  periodStartTime,
  periodEndTime,
  canEdit,
  t,
  upcomingSettingByProperty,
  setActiveSetting,
  loading,
}: UseSettingsTableDataPrams): PropertiesTableItemWithTooltip[] {
  return filteredProperties.map((property, i) => {
    const activeSetting =
      activeSettingByProperty[
        JSON.stringify({
          level: property.level,
          name: property.name,
          attribute: property.attribute,
        })
      ]
    const activeBaseSetting =
      activeBaseSettingByProperty[
        JSON.stringify({
          level: property.level,
          name: property.name,
          attribute: property.attribute,
        })
      ]
    const upcomingSetting =
      upcomingSettingByProperty[
        JSON.stringify({
          level: property.level,
          name: property.name,
          attribute: property.attribute,
        })
      ]
    const historicSetting =
      historySettingByProperty[
        JSON.stringify({
          level: property.level,
          name: property.name,
          attribute: property.attribute,
        })
      ]

    // Values can be string, number or time serie (list of object with id & value)
    let valueToDisplay: string | null = '-'
    let changedAtTime = '-'
    let statusSortOrder = 0

    // Show
    // 1. active value for the priority levels of this view (Deviation or base) if a setting exists
    // 2. upcoming value for the priority levels of this view (Deviation or base) if a setting exists
    // 3. active value for the base level of this view if base level (Deviation or base) priorities are set and a setting exists
    // 4. default value for the given property

    if (activeSetting?.value !== undefined && activeSetting?.value !== null && !isBaseSettings) {
      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: activeSetting.start_time ?? periodStartTime,
        settingEndTime: activeSetting.end_time,
        activeValue: activeSetting.value,
        baseValue: activeBaseSetting?.value,
        defaultValue: property.default_value,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: activeSetting.isTimeSeries,
      })
      changedAtTime = activeSetting?.updated_at ?? '-'
      statusSortOrder = 1
    } else if (upcomingSetting?.value !== undefined && upcomingSetting?.value !== null && !isBaseSettings) {
      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: upcomingSetting.start_time,
        settingEndTime: upcomingSetting.end_time,
        activeValue: upcomingSetting.value,
        baseValue: activeBaseSetting?.value,
        defaultValue: property.default_value,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: upcomingSetting.isTimeSeries,
      })
      changedAtTime = upcomingSetting.updated_at ?? '-'
      statusSortOrder = 1
    } else if (activeBaseSetting?.value !== undefined && activeBaseSetting?.value !== null) {

      // If base setting value and model default is the same there is no need to show the default.
      const defaultValue = property.default_value !== activeBaseSetting.value ? property.default_value : null

      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: activeBaseSetting.start_time ?? periodStartTime,
        settingEndTime: activeBaseSetting.end_time,
        activeValue: activeBaseSetting.value,
        defaultValue,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: activeBaseSetting.isTimeSeries,
      })
      changedAtTime = activeBaseSetting?.updated_at ?? '-'
    } else {
      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: null,
        settingEndTime: null,
        activeValue: property.default_value ?? null,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: false,
      })
      changedAtTime = historicSetting?.updated_at ?? '-'
    }

    return {
      group: getPrettyName(property.group || property.name, digitalTwin?.translations),
      property: getPrettyName(property.display_name || property.attribute, digitalTwin?.translations),
      status: {
        loading,
        sortOrder: statusSortOrder,
        icon: <SettingsStatusField value={activeSetting ?? upcomingSetting} loading={loading} />,
      },
      currentValue: valueToDisplay,
      unit: property.measurement_unit ? getPrettyName(property.measurement_unit, digitalTwin?.translations, true) : '-',
      changedAt: changedAtTime,
      meta: {
        level: property.level,
        name: property.name,
        attribute: property.attribute,
      },
      propertyGroupName: getPrettyName(property.level, digitalTwin?.translations, true),
      edit: (
        <Button
          key={`${i}-show/edit-${property.level}-${property.name}-${property.attribute}`}
          onClick={() => {
            setActiveSetting({
              level: property.level,
              name: property.name,
              attribute: property.attribute,
            })
          }}
          primary
          icon={canEdit ? 'fas fa-edit' : undefined}
        >
          {canEdit ? t('Show/edit') : t('Show')}
        </Button>
      ),
    } as PropertiesTableItemWithTooltip
  })
}

type UseSettingMetadataTableDataPrams = {
  activeBaseSettingByProperty: { [key: string]: DigitalTwinSettingMetadata }
  activeSettingByProperty: { [key: string]: DigitalTwinSettingMetadata }
  digitalTwin?: DigitalTwin
  filteredProperties: ConfigurableProperty[]
  isBaseSettings: boolean
  periodStartTime: ISODateTime | null
  periodEndTime: ISODateTime | null
  canEdit: boolean
  t: TFunction<'translation', undefined>
  upcomingSettingByProperty: { [key: string]: DigitalTwinSettingMetadata }
  setActiveSetting: (setting?: { level: DeviationSettingLevel; name: string; attribute?: string }) => void
  loading: boolean
  settingMetadataCreatedAtDict: { [key: string]: DigitalTwinSettingMetadata }
}

export function getPropertyTableItemsForSettingMetadata({
  activeBaseSettingByProperty,
  activeSettingByProperty,
  digitalTwin,
  filteredProperties,
  isBaseSettings,
  periodStartTime,
  periodEndTime,
  canEdit,
  t,
  upcomingSettingByProperty,
  setActiveSetting,
  loading,
  settingMetadataCreatedAtDict,
}: UseSettingMetadataTableDataPrams): PropertiesTableItemWithTooltip[] {
  return filteredProperties.map((property, i) => {
    const key = JSON.stringify({
      level: property.level,
      name: property.name,
      attribute: property.attribute,
    })
    const activeSetting = activeSettingByProperty[key]
    const activeBaseSetting = activeBaseSettingByProperty[key]
    const upcomingSetting = upcomingSettingByProperty[key]

    // Values can be string, number or time serie (list of object with id & value)
    let valueToDisplay: string | null = '-'
    const changedAtTime = settingMetadataCreatedAtDict[key]?.created_at ?? '-'
    let statusSortOrder = 0

    // Show
    // 1. active value for the priority levels of this view (Deviation or base) if a setting exists
    // 2. upcoming value for the priority levels of this view (Deviation or base) if a setting exists
    // 3. active value for the base level of this view if base level (Deviation or base) priorities are set and a setting exists
    // 4. default value for the given property

    if (activeSetting?.value !== undefined && activeSetting?.value !== null && !isBaseSettings) {
      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: activeSetting.start_time ?? periodStartTime,
        settingEndTime: activeSetting.end_time,
        activeValue: activeSetting.value,
        baseValue: activeBaseSetting?.value,
        defaultValue: property.default_value,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: activeSetting.is_time_series,
      })
      statusSortOrder = 1
    } else if (upcomingSetting?.value !== undefined && upcomingSetting?.value !== null && !isBaseSettings) {
      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime: upcomingSetting.start_time,
        settingEndTime: upcomingSetting.end_time,
        activeValue: upcomingSetting.value,
        baseValue: activeBaseSetting?.value,
        defaultValue: property.default_value,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: upcomingSetting.is_time_series,
      })
      statusSortOrder = 1
    } else {
      const activeValue = activeSetting?.value ?? activeBaseSetting?.value ?? property.default_value

      // If we are looking at deviations, and we show the base setting value, we won't show any 'active from' or 'active until'.
      // For base settings view, 'activeSetting' is the base setting, so we will show the correct 'active from' and 'active until'.
      const settingStartTime = activeSetting?.start_time ?? periodStartTime
      const settingEndTime = activeSetting?.end_time

      valueToDisplay = getDigitalTwinSettingValueString({
        settingStartTime,
        settingEndTime,
        activeValue,
        periodStartTime,
        periodEndTime,
        attribute: property.attribute,
        isTimeSeries: activeSetting?.is_time_series,
      })
    }

    return {
      group: getPrettyName(property.group || property.name, digitalTwin?.translations),
      property: getPrettyName(property.display_name || property.attribute, digitalTwin?.translations),
      status: {
        loading,
        sortOrder: statusSortOrder,
        icon: <SettingsStatusField value={activeSetting ?? upcomingSetting} loading={loading} />,
      },
      currentValue: valueToDisplay,
      unit: property.measurement_unit ? getPrettyName(property.measurement_unit, digitalTwin?.translations, true) : '-',
      changedAt: changedAtTime,
      meta: {
        level: property.level,
        name: property.name,
        attribute: property.attribute,
      },
      propertyGroupName: getPrettyName(property.level, digitalTwin?.translations, true),
      edit: (
        <Button
          key={`${i}-show/edit-${property.level}-${property.name}-${property.attribute}`}
          onClick={() => {
            setActiveSetting({
              level: property.level,
              name: property.name,
              attribute: property.attribute,
            })
          }}
          primary
          icon={canEdit ? 'fas fa-edit' : undefined}
        >
          {canEdit ? t('Show/edit') : t('Show')}
        </Button>
      ),
    } as PropertiesTableItemWithTooltip
  })
}

// We only want properties that:
//   1. have no specific priorities, or that have specific priorities that matches the given priorities
//   and
//   2. have tags that matches the current filters
export function getFilteredProperties(
  allConfigurableProperties: ConfigurableProperty[],
  priorities: SystemSettingPriorityLevel[],
  filterTags: string[],
  baseSettingPriorities?: SystemSettingPriorityLevel[]
): [ConfigurableProperty[], ConfigurableProperty[]] {
  return getFilteredPropertiesByPriority(
    allConfigurableProperties.filter(
      (property) =>
        property?.tags?.length && filterTags.length && matchesSomeTagFromEveryTopLevel(property.tags, filterTags)
    ),
    priorities,
    baseSettingPriorities
  )
}

export function getFilteredPropertiesByPriority(
  allConfigurableProperties: ConfigurableProperty[],
  priorities: SystemSettingPriorityLevel[],
  baseSettingPriorities?: SystemSettingPriorityLevel[]
): [ConfigurableProperty[], ConfigurableProperty[]] {
  const currentPriorityProperties: ConfigurableProperty[] = []
  const basePriorityProperties: ConfigurableProperty[] = []
  allConfigurableProperties.forEach((property) => {
    if (
      property.available_priorities === undefined ||
      property.available_priorities.some((p) => priorities.some((priority) => priority.priority_level === p))
    ) {
      currentPriorityProperties.push(property)
    }
    if (
      property.available_priorities === undefined ||
      property.available_priorities.some((p) =>
        baseSettingPriorities?.some((priority) => priority.priority_level === p)
      )
    ) {
      basePriorityProperties.push(property)
    }
  })
  return [currentPriorityProperties, basePriorityProperties]
}

export function getUnitConfigurableProperty(
  name: string,
  attributes: string[],
  digitalTwin?: DigitalTwin
): ConfigurableProperty[] {
  if (!digitalTwin || !attributes.length) {
    return []
  }

  const properties: ConfigurableProperty[] = []

  for (const node of digitalTwin?.model?.nodes ?? []) {
    for (const unit of node.units ?? []) {
      if (unit.name !== name) {
        continue
      }
      for (const property of unit.configurable_properties ?? []) {
        if (attributes.includes(property.attribute)) {
          const propertyToReturn: ConfigurableProperty = JSON.parse(JSON.stringify(property))
          propertyToReturn.name = unit.name
          propertyToReturn.level = 'unit'
          properties.push(propertyToReturn)
        }
      }
    }
  }

  return properties
}

export function getPropertiesFromDigitalTwinModel(
  digitalTwinName: string,
  digitalTwinModel: DigitalTwinModel
): ConfigurableProperty[] {
  const properties: ConfigurableProperty[] = []

  // model
  const modelProperties = addNameAndLevelToProperty(digitalTwinName, 'model', digitalTwinModel.configurable_properties)
  properties.push(...modelProperties)

  // nodes & units
  digitalTwinModel.nodes?.forEach((node) => {
    const nodeProperties = addNameAndLevelToProperty(node.name, 'node', node.configurable_properties)
    properties.push(...nodeProperties)

    node.units?.forEach((unit) => {
      const unitProperties = addNameAndLevelToProperty(unit.name, 'unit', unit.configurable_properties)
      properties.push(...unitProperties)
    })
  })

  // commodities & byproducts
  digitalTwinModel.commodities?.forEach((commodity) => {
    const commodityProperties = addNameAndLevelToProperty(
      commodity.name,
      'commodity',
      commodity.configurable_properties
    )
    properties.push(...commodityProperties)

    commodity.byproducts?.forEach((byproduct) => {
      const byproductProperties = addNameAndLevelToProperty(
        byproduct.name,
        'byproduct',
        byproduct.configurable_properties
      )
      properties.push(...byproductProperties)
    })
  })

  // exchanges
  digitalTwinModel.exchanges?.forEach((exchange) => {
    const exchangeProperties = addNameAndLevelToProperty(exchange.name, 'exchange', exchange.configurable_properties)
    properties.push(...exchangeProperties)
  })

  // balances
  digitalTwinModel.balances?.forEach((balance) => {
    const balanceProperties = addNameAndLevelToProperty(balance.name, 'balance', balance.configurable_properties)
    properties.push(...balanceProperties)
  })

  return properties
}

function addNameAndLevelToProperty(
  name: string,
  level: DeviationSettingLevel,
  properties?: ConfigurableProperty[]
): ConfigurableProperty[] {
  if (!properties) {
    return []
  }

  return properties.map((property) => ({
    ...property,
    level: level,
    name: name,
    display_name: `${level}.${name}.${property.attribute}`,
    group: `${level}.${name?.split('.')[0]}`,
  }))
}