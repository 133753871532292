import React, { ReactElement, useMemo, useState } from 'react'

import { getSandboxProjectUiConfigRefreshToken } from 'api/dataset/dataset.api'
import { DeviationSettingLevel } from 'api/digitalTwin/digitalTwin.api'
import { getOptjobAsObject, useLatestOptJobStatus } from 'api/optJobs/optJobs.api'
import { OptProject } from 'api/optProjects/optProjects.api'
import { TabObject } from 'ui/components/Tabs/RegularTabs'
import Tabs from 'ui/components/Tabs/Tabs'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import ChangeLogSettings from './components/ChangeLogSettings/ChangeLogSettings'

type ChangeLogTabsProps = {
  project: OptProject
}

function ChangeLogTabs({ project }: ChangeLogTabsProps): ReactElement {
  const { t } = useTranslation()
  const { data: latestOptJob } = useLatestOptJobStatus({
    type: `sandbox`,
    sandboxProjectId: project.id,
    status: `Finished`,
  })
  const firstLatestSandboxOptJob = getOptjobAsObject(latestOptJob)
  const datasetCreatedAt = firstLatestSandboxOptJob?.created_at ? firstLatestSandboxOptJob.created_at : undefined

  const tabs: TabObject[] = [
    {
      label: t(`Settings`),
      id: 1,
    },
    ...(firstLatestSandboxOptJob != null
      ? [
        {
          label: t(`Results`),
          id: 2,
        },
      ]
      : []),
  ]
  const [currentTab, setCurrentTab] = useState<'settings' | 'results'>('settings')

  function handleChange(event: React.ChangeEvent<unknown>, newValue: number): void {
    setCurrentTab(newValue === 1 ? 'settings' : 'results') // 1 == settings, 2 == results
  }

  const location = useLocation()
  const history = useHistory()

  // Check if there are query parameters in the URL that specify a setting to open
  const propertyFromQueryParameters = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const level = params.get('level') as DeviationSettingLevel
    const name = params.get('name')
    const attribute = params.get('attribute')
    const isBaseSetting = params.get('base_setting') === '1' // We default to deviation settings
    const qpSetting = level && name && attribute ? { level, name, attribute, isBaseSetting: isBaseSetting } : undefined

    if (qpSetting !== undefined) {
      // If we navigate to the sandbox project via an url with a setting specified in the query parameters, we should open the settings tab.
      // This navigation can happen from inside the sandbox project view itself as well.
      setCurrentTab('settings')

      // Clear query parameters. Necessary for the user to be able to close the accumulator level modal and open it again (navigate to the same URL twice)
      const newPath = history.location.pathname
      history.push(newPath)
    }

    return qpSetting
  }, [history, location.search])

  return (
    <Tabs tabObjects={tabs} handleChange={handleChange} value={currentTab === 'settings' ? 1 : 2}>
      {currentTab === 'settings' ? (
        <ChangeLogSettings project={project} propertyToEditInitially={propertyFromQueryParameters} />
      ) : (
        <UiConfig
          type="sandbox_production_plan"
          datasetStartTime={Datetime.toISOString(project.start_time)}
          datasetEndTime={Datetime.toISOString(project.end_time)}
          datasetCreatedAt={datasetCreatedAt}
          datasetCreatedAtOperator={'eq'}
          ignoreZoom={false}
          overrideAlias={{ opt_model_id: project.opt_model ?? -1, opt_job_type_id: 8 }}
          datasetRefreshToken={getSandboxProjectUiConfigRefreshToken(project.id)}
        />
      )}
    </Tabs>
  )
}

export default ChangeLogTabs
