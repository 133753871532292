import React, { ReactElement } from 'react'

import { HeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting, useObjectPropertySettings } from 'api/settings/settings.api'
import SettingsDropdown from 'ui/components/SettingsDropdown/SettingsDropdown'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'

import { CircularProgress, Grid } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import StatusBar from './components/StatusBar/StatusBar'
import SupplierSettingsItem from './components/SupplierSettingsItem'

function SettingListContent({
  settings,
  heatSupplierProps,
}: {
  settings: Setting[]
  heatSupplierProps: ObjectProperty[]
}): ReactElement | null {
  const { t } = useTranslation()

  return settings.length ? (
    <SettingsDropdown customButtonTitle={t(`Has changes`)}>
      {settings.map((setting, key) => {
        const supplier = heatSupplierProps.find((prop) => prop.id === setting.object_property)?.parent
        return (
          <SupplierSettingsItem key={key} setting={setting} supplierName={supplier ? getDisplayName(supplier) : '-'} />
        )
      })}
    </SettingsDropdown>
  ) : null
}

type SupplierViewHeaderInterface = {
  heatSuppliers: HeatSupplier[]
  currentDate: Moment
  setCurrentDate: (newDate: Moment) => void
  supplierId?: number
}

export default function SupplierViewHeader({
  heatSuppliers,
  currentDate,
  setCurrentDate,
  supplierId,
}: SupplierViewHeaderInterface): ReactElement {
  const { t } = useTranslation()

  const heatSupplierProps = heatSuppliers
    .reduce<ObjectProperty[]>((current, next) => [...current, ...next.props], [])
    .filter((next) => next.name === `availability`)
  const { data: settings, status } = useObjectPropertySettings(
    heatSupplierProps.map((prop) => prop.id),
    {
      active_from: moment().format(),
    }
  )

  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start">
      <Grid item xs={12} sm={12} md={5} lg={4} xl={2}>
        <ArrowDatePicker
          prevButtonTooltip={t(`Previous day`)}
          nextButtonTooltip={t(`Next day`)}
          format={`ddd D MMM`}
          value={currentDate}
          step={{ unit: `day`, amount: 1 }}
          onChange={(date) => setCurrentDate(date)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
        <StatusBar currentDate={currentDate} supplierId={supplierId} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4} xl={2}>
        <StatusComponent
          data={{ settings }}
          status={status}
          Component={SettingListContent}
          ComponentProps={{ heatSupplierProps }}
          LoadingFallback={<CircularProgress />}
        />
      </Grid>
    </Grid>
  )
}
