import React from 'react'

import { DateValue } from 'react-aria-components'

import ReactAriaDatePickerPeriod from '../internal/DatePicker/DatePickerPeriod'
import { defaultTransformEndDate, defaultTransformStartDate } from '../internal/DatePicker/utils/datePicker.helper'
import {
  DEFAULT_MAX_DATE_FOR_DATE_PICKER,
  DEFAULT_MIN_DATE_FOR_DATE_PICKER,
} from '../internal/DatePicker/utils/validation'

import { CompatibleDate, toDateValue, toISODateTime } from './utils/dateAdapter'

type DatePickerPeriodProps = React.ComponentPropsWithoutRef<typeof ReactAriaDatePickerPeriod>


export type Props = {
  startDate: CompatibleDate
  endDate: CompatibleDate
  minDate?: CompatibleDate
  maxDate?: CompatibleDate
  onSelect: (startTime: ISODateTime | null | undefined, endTime: ISODateTime | null | undefined) => void

  // Let the caller override the default behavior (start date is set to HH:00:00 and end date is set to HH:59:59)
  startDateTransform?: (date: DateValue | null) => DateValue | null
  endDateTransform?: (date: DateValue | null) => DateValue | null
} & Omit<DatePickerPeriodProps, 'startDate' | 'endDate' | 'minDate' | 'maxDate' | 'onSelect'>

export function DatePickerPeriod(props: Props): JSX.Element {
  // a datepicker period component which always make sure that start hour is at minute :00 and end hour is at minute :59 and that there are min and max limits on date
  const { startDate, endDate, minDate, maxDate, onSelect, ...restProps } = props

  const startDateTransform = props.startDateTransform || defaultTransformStartDate
  const endDateTransform = props.endDateTransform || defaultTransformEndDate

  const minimalDate = minDate ?? DEFAULT_MIN_DATE_FOR_DATE_PICKER
  const maximumDate = maxDate ?? DEFAULT_MAX_DATE_FOR_DATE_PICKER

  return (
    <ReactAriaDatePickerPeriod
      // Hack to make sure that null isn't passed
      minDate={toDateValue(minimalDate) || undefined}
      maxDate={toDateValue(maximumDate) || undefined}
      startDate={startDate ? startDateTransform(toDateValue(startDate) as DateValue) : toDateValue(startDate)}
      endDate={endDate ? endDateTransform(toDateValue(endDate) as DateValue) : toDateValue(endDate)}
      onSelect={(newStartDate, newDate) => {
        onSelect(toISODateTime(newStartDate), toISODateTime(newDate))
      }}
      isDisabled={props.isDisabled}
      startDateTransform={startDateTransform}
      endDateTransform={endDateTransform}
      {...restProps}
    />
  )
}
