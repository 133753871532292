
import ChartJS from 'chart.js/auto'
import 'chartjs-adapter-moment'
import zoomPlugin from 'chartjs-plugin-zoom'
import { Chart as ReactChartJS2 } from 'react-chartjs-2'


import crosairPlugin from './helpers/crosairPlugin'
import syncYAxisPlugin from './helpers/syncYAxisPlugin'
import todayMarkerPlugin from './helpers/todayMarkerPlugin'
import verticalLinePlugin from './helpers/verticalLinePlugin'

if (ChartJS && zoomPlugin) {
  ChartJS.register(zoomPlugin)
}

if (ChartJS && crosairPlugin) {
  ChartJS.register(crosairPlugin)
}

if (ChartJS && todayMarkerPlugin) {
  ChartJS.register(todayMarkerPlugin)
}

if (ChartJS && verticalLinePlugin) {
  ChartJS.register(verticalLinePlugin)
}

if (ChartJS && syncYAxisPlugin) {
  ChartJS.register(syncYAxisPlugin)
}

export default ReactChartJS2