import React, { PropsWithChildren, ReactElement, ReactNode, useMemo, useState } from 'react'

import filterStore, { allFiltersSelected } from 'store/filter/filter'
import Icon from 'ui/atoms/Icon/Icon'
import { ToggleButton } from 'ui/molecules/ToggleButton/ToggleButton'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import FilterComponent from '../Filter/FilterComponent'
import { getRoute } from 'helpers/route.helper/route.helper'
import { getAvailableFilters } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import {
  getTopLevelOptions,
  parseTags,
} from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'

import InfoBox from './InfoBox'
import styles from './ModuleHeader.module.less'

type ModuleHeaderProps = {
  infoHeader: string | JSX.Element
  onInfoButtonClick?: () => void
  beta?: boolean
  secondaryChildren?: ReactNode,
  includePropertyFilter?: boolean
}

export default function ModuleHeader ({
  infoHeader,
  onInfoButtonClick,
  children,
  beta,
  secondaryChildren,
  includePropertyFilter = true,
}: PropsWithChildren<ModuleHeaderProps>): ReactElement {
  const { t } = useTranslation()

  const { filterComponentPerRoute, allTagsPerRoute, currentActiveTagsPerRoute } = useSnapshot(filterStore)
  const [filterIsCollapsed, setFilterCollapsed] = useState<boolean>(true)
  const route = getRoute()

  // Only show filter button if there are filters to show
  const showFilterButton = useMemo(() => {
    const routeHasFilterComponent = filterComponentPerRoute[route]
    const allTags = allTagsPerRoute[route] ?? []
    const options = parseTags(Array.from(allTags))
    const items = filterStore.tagsPerUiconfig
    const currentTags = currentActiveTagsPerRoute[route] ?? new Set()
    const values = Array.from(currentTags)
    const availableFilters = getAvailableFilters(items, values, new Set())

    // Note (Lage): We don't use 'hidden_default_tags' here.
    // Maybe we should, but I don't think that field is used anymore so we probably don't need to.
    const topLevelOptions = getTopLevelOptions(availableFilters, options, [])

    return routeHasFilterComponent && topLevelOptions.length > 0
  }, [allTagsPerRoute, currentActiveTagsPerRoute, filterComponentPerRoute, route])

  const toggleFilterCollapse = (): void => {
    setFilterCollapsed(!filterIsCollapsed)
  }

  const filterIcon = allFiltersSelected(route) ? '' 
    : <Icon tooltip={{ title: t('Filters active'), placement: 'right' }} icon="fas fa-filter" />

  return (
    <div className={styles.ModuleHeader_Root}>
      <div className={styles.ModuleHeader_RootRow}>
        <div className={styles.ModuleHeader_InfoBoxContainer}>
          <InfoBox infoHeader={infoHeader} onInfoButtonClick={onInfoButtonClick} beta={beta}/>
        </div>
        <div
          className={styles.ModuleHeader_MiddleRow}
        >
          {showFilterButton && (
            <div className={styles.ModuleHeader_FilterButtonContainer}>
              <ToggleButton isCollapsed={filterIsCollapsed} onClick={toggleFilterCollapse}>
                <span>{t('Filter')} {filterIcon}</span>
              </ToggleButton>
            </div>
          )}
          {children}
        </div>
      </div>
      {includePropertyFilter && (
        <div style={{ display: filterIsCollapsed ? 'none' : 'block' }}>
          <FilterComponent datasetRefreshToken={'datasetRefreshToken'} />
        </div>
      )}
      <div className={styles.ModuleHeader_SecondaryChildrenContainer}>
        {secondaryChildren}
      </div>
    </div>
  )
}

