import React, { ReactElement } from 'react'

import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'

import { DialogActions, Grid, Button, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './AddDialogActions.module.less'

type AddDialogActionsProps = {
  onDelete?: () => void
  onCancel: () => void
  hasEditPermission?: boolean
  editing: boolean
  saveButtonDisabled: boolean
  isSubmitting: boolean
}

export default function AddDialogActions({
  onDelete,
  onCancel,
  hasEditPermission,
  editing,
  saveButtonDisabled,
  isSubmitting,
}: AddDialogActionsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <DialogActions style={{ justifyContent: `flex-start` }}>
      <Grid container spacing={1} direction="row" justifyContent="flex-start">
        <Grid item container justifyContent="flex-start" xs={4}>
          {editing && onDelete ? (
            <>
              <Button
                className={styles.AddDialogActions_DeleteButton}
                color="secondary"
                disabled={!hasEditPermission || isSubmitting}
                onClick={() => {
                  onDelete()
                }}
              >
                <Icon
                  className={classNames(styles.AddDialogActions_ButtonIcon, `fal fa-trash-alt`)}
                  style={{ color: `red` }}
                  fontSize="small"
                />
                {t(`Remove setting`)}
              </Button>
            </>
          ) : null}
        </Grid>

        <Grid item container justifyContent="flex-end" spacing={1} xs={8}>
          <Grid item>
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="secondary"
              onClick={() => {
                onCancel()
              }}
            >
              {t(`Cancel`)}
            </Button>
          </Grid>
          <Grid item>
            <ContainedIconButton
              type="submit"
              color="primary"
              disabled={!hasEditPermission || saveButtonDisabled}
              icon={editing ? `fal fa-save` : `fal fa-plus`}
              label={editing ? t(`Save`) : t(`Add`)}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogActions>
  )
}
