import React, { ReactElement, useRef, useState } from 'react'

import { PlanValues } from 'api/elplan/elplan.api'
import elplanBidStore from 'store/elplan/elplan'
import { Dialog } from 'ui/components/Dialog/Dialog'
import FormRow from 'ui/components/FormRow/FormRow'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import { TimePicker } from 'ui/molecules/pickers/TimePicker/TimePicker'
import Datetime from 'utils/datetime/datetime'

import { DialogContent, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

// eslint-disable-next-line no-restricted-imports
import TimeEffectTable from '../../TimeEffectTable/TimeEffectTable'
import { FormattedBid, FormattedBidWithIdAndTitle, generatePlanValues } from '../DayAheadBidManagement.helper'
import { isArray, useCurrency } from 'helpers/global.helper/global.helper'

import styles from './BidModal.module.less'

type BidModalProps = {
  editBid: (obj: FormattedBidWithIdAndTitle) => void
  createBid?: (obj: FormattedBid) => void
  deleteBid?: (obj: FormattedBidWithIdAndTitle) => void
  bid?: FormattedBidWithIdAndTitle
  closeModal: () => void
  type?: 'spot' | 'block' | 'profile'
  isEditing?: boolean

}

export default function BidModal({
  editBid, 
  closeModal, 
  bid, 
  createBid, 
  type, 
  deleteBid, 
  isEditing,
}: BidModalProps): ReactElement {
  const tomorrow = Datetime.getTomorrowDate()
  const elplanSnap = useSnapshot(elplanBidStore)
  const currency = useCurrency()
  const { t } = useTranslation()

  let startHour = ''
  if (bid?.startTime) {
    startHour = bid.startTime
  } else if (elplanSnap.date.start_time) {
    startHour = Datetime.getStartOfDay(elplanSnap.date.start_time)
  } else {
    startHour = tomorrow.startTime
  }
  
  let endHour = ''
  if (bid?.endTime) {
    endHour = Datetime.ISODatetimeTo59Minutes(bid.endTime as ISODateTime)
  } else if (elplanSnap.date.start_time) {
    endHour = Datetime.ISODatetimeTo59Minutes(Datetime.getEndOfDay(elplanSnap.date.start_time))
  } else {
    endHour = Datetime.ISODatetimeTo59Minutes(tomorrow.endTime)
  }

  const [hoursPeriod, setHoursPeriod] = useState<{ startHour: ISODateTime, endHour: ISODateTime }>({
    startHour: startHour as ISODateTime,
    endHour: endHour as ISODateTime,
  })

  const [price, setPrice] = useState<number | number[]>(bid?.price ?? 0)
  const [volume, setVolume] = useState<number | number[]>(bid?.volume ?? 0)
  const [planValues, setPlanValues] = useState<PlanValues[]>(bid?.planValues ?? [])
  const ref = useRef(null)

  const bidType = type === 'block' ? t('Block bid') : (type === 'spot' ? t('Spot bid') : t('Profile bid'))
  const modalTitle = isEditing ? `${t('Edit')} ${bid?.title ?? bidType}` : `${t('Create')} ${bidType}`

  const hourLimit = (type === 'block' || type === 'profile') 
    ? elplanSnap.blockBidInfo.minimum_hours_for_bid 
    : elplanSnap.spotBidWithoutPriceInfo.minimum_hours_for_bid
  const hourPeriodIsLessThanMinimum = hourLimit 
    ? Datetime.getHoursBetween(hoursPeriod.startHour, hoursPeriod.endHour).length <= hourLimit - 1
    : false
  const hourLimitValidationErrorText = hourLimit === 1 ? hourLimit + ` ` + t('hour') + `.` : hourLimit + ` ` + t('hours') + `.`

  function updatePlanValues(period: { startHour: ISODateTime, endHour: ISODateTime }): void {
    if (type === 'profile') {
      const oldPlanValues = planValues ?? []
      const newPlanValues = generatePlanValues(0, period.startHour, new Datetime(period.endHour).addHours(-1).ISOString)      
      const updatedValues = newPlanValues.map((value) => {
        const existingValue = oldPlanValues.find((v) => v.start_time === value.start_time && v.end_time === value.end_time && v.volume !== 0)
        return existingValue ? existingValue : value
      })
      setPlanValues(updatedValues)
    }
  }

  function generatePlanValuesFromBid(bid: FormattedBidWithIdAndTitle) : PlanValues[] {
    if (!isArray(bid?.volume)) {
      return [{ 
        volume: bid.volume as number, 
        start_time: new Datetime(bid.startTime).ISOString, 
        end_time: new Datetime(bid.endTime).ISOString,
      }]
    }

    const planValues = bid?.volume?.map((value, index) => {
      const start_time = new Datetime(bid.startTime).addHours(index).ISOString
      const end_time = new Datetime(bid.startTime).addHours(index + 1).ISOString
      return { start_time, end_time, volume: value }
    }) ?? []
    return planValues
  }

  if (type === 'profile' && bid?.volume && !planValues.length) {
    setPlanValues(generatePlanValuesFromBid(bid))
  }

  return (
    <div className={styles.BidModal} >
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={modalTitle} handleClose={() => closeModal()} />
        <DialogContent>
          <div className={styles.BidModal_TimePickers}>
            <FormRow icon="fal fa-alarm-clock">
              <Grid item xs={12} md={12} lg={6}>
                <TimePicker
                  error={hourPeriodIsLessThanMinimum}
                  value={hoursPeriod.startHour}
                  label={t('From')}
                  onChange={(newStartHour) => {
                    const newPeriod = { startHour: newStartHour, endHour: hoursPeriod.endHour }
                    setHoursPeriod(newPeriod)
                    updatePlanValues(newPeriod)
                  }}
                  dateTransform={Datetime.ISODatetimeTo00Minutes}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TimePicker
                  error={hourPeriodIsLessThanMinimum}
                  label={t('To')}
                  value={hoursPeriod.endHour}
                  onChange={(newEndHour) => {
                    const newPeriod = { startHour: hoursPeriod.startHour, endHour: newEndHour }
                    setHoursPeriod(newPeriod)
                    updatePlanValues(newPeriod)
                  }}
                  dateTransform={Datetime.ISODatetimeTo59Minutes}
                />
              </Grid>
              {hourPeriodIsLessThanMinimum && (
                <Grid item xs={12}>
                  <Typography color="error">
                    {`${t('Minimum hour length of bid has to be at least')} ${hourLimitValidationErrorText}`}
                  </Typography>
                </Grid>
              )}
            </FormRow>
          </div>
          <div className={styles.BidModal_TextFields}>
            { (type === 'block' || type === 'spot') &&
            <FormRow icon="fal fa-cogs">
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id={'volume'}
                  label={t('Volume') + ' (MW)'}
                  aria-label={'volume'}
                  disabled={false}
                  variant="outlined"
                  fullWidth
                  defaultValue={volume}
                  error={volume === null}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement
                    if (target.value) {
                      setVolume(parseFloat(target.value))
                    }
                  }}
                />
              </Grid>
            </FormRow>
            }
          </div>
          {(type === 'block' || type === 'profile') && <div className={styles.BidModal_TextFields}>
            <FormRow icon="fal fa-bolt">
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id={'electricityPrice'}
                  label={t('Avg price') + ` (${currency}/MWh)`}
                  aria-label={'electricityPrice'}
                  disabled={false}
                  variant="outlined"
                  fullWidth
                  defaultValue={price}
                  error={price === null}
                  type="number"
                  onInput={e => {
                    const target = e.target as HTMLInputElement
                    if (target.value) {
                      setPrice(parseFloat(target.value))
                    }
                  }}
                />
              </Grid>
            </FormRow>
          </div>}
          { type === 'profile' &&
          <FormRow icon="fal fa-cogs">
            <Grid item xs={12} md={12} lg={12}>
              <TimeEffectTable
                currentPlanValues={planValues}
                onCellChange={(value, index) => {
                  planValues[index].volume = value
                }}
              />
            </Grid>
          </FormRow>
          }
          <div className={styles.BidModal_TextFields}>
            <FormRow icon="fal fa-file-signature">
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id={'name'}
                  label={t('Name (optional)')}
                  aria-label={'name'}
                  variant="outlined"
                  fullWidth
                  defaultValue={bid?.name}
                  onInput={e => {
                    const target = e.target as HTMLInputElement
                    if (bid && target.value) {
                      bid.name = target.value
                    }
                  }}
                />
              </Grid>
            </FormRow>
          </div>
          <ModalButtons
            onSubmit={() => {
              if (type === 'block') {
                const obj = {
                  type: bid?.type ?? 'blockbid',
                  fake: bid?.fake,
                  block_order: bid?.block_order,
                  displayStartTime: hoursPeriod.startHour,
                  displayEndTime: hoursPeriod.endHour,
                  startTime: Datetime.toISOString(hoursPeriod.startHour),
                  endTime: Datetime.ISODatetimeTo00Minutes(hoursPeriod.endHour),
                  volume: [volume as number],
                  price: price as number,
                  editable: true,
                  clear: true,
                  name: bid?.name,
                  scenarioId: bid?.scenarioId,
                }
                if (bid) {
                  editBid({...obj, id: bid.id, title: bid.title})
                } else if (createBid) {
                  createBid(obj)
                }
              }

              if (type === 'spot') {
                const obj: FormattedBid = {
                  type: bid?.type ?? 'spotbid_without_price',
                  displayStartTime: hoursPeriod.startHour,
                  displayEndTime: hoursPeriod.endHour,
                  startTime: Datetime.toISOString(hoursPeriod.startHour),
                  endTime: Datetime.ISODatetimeTo00Minutes(hoursPeriod.endHour),
                  volume: volume,
                  editable: true,
                  clear: true,
                  scenarioId: bid?.scenarioId,
                }
                if (bid) {
                  editBid({...obj, id: bid.id, title: bid.title})
                } else if (createBid) {
                  createBid(obj)
                }
              }

              if (type === 'profile') {
                const obj: FormattedBid = {
                  type: 'profilebid',
                  fake: bid?.fake,
                  block_order: bid?.block_order,
                  displayStartTime: hoursPeriod.startHour,
                  displayEndTime: hoursPeriod.endHour,
                  startTime: Datetime.toISOString(hoursPeriod.startHour),
                  endTime: Datetime.ISODatetimeTo00Minutes(hoursPeriod.endHour),
                  volume: planValues.map((value) => value.volume),
                  price: price as number,
                  editable: true,
                  clear: true,
                  name: bid?.name,
                  scenarioId: bid?.scenarioId,
                }
                if (bid) {
                  editBid({...obj, id: bid.id, title: bid.title})
                } else if (createBid) {
                  createBid(obj)
                }
              }

              closeModal()
            }}
            onCancel={() => closeModal()}
            onDelete={(isEditing && deleteBid) ? () => {
              closeModal()
            } : void 0}
            disableSubmit={hourPeriodIsLessThanMinimum}
            infoText={elplanSnap.elplan.has_plan ? t('There is already an electrity plan created for this period. Your changes will not affect the current electricity plan. Would you still like to make changes?') : undefined}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
