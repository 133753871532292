// [2024-06-13] Niklas Andreasson:
// This component was created to provide defaults that are compatible with the DatePicker components.
// Since MUI dialogs have a focus trap enabled by default (trying to ensure that the focus stays within the dialog), it can cause issues when the Datepicker dialog is used in a modal.
// 
// RangeError: Maximum call stack size exceeded.
// at windowObject.HTMLElement.focus \(chunk-...js)
// at HTMLDocument.onFocus \(chunk-...js)
// at windowObject.HTMLElement.focus \(chunk-...js)
// at HTMLDocument.contain2 \(chunk-...js)
// at windowObject.HTMLElement.focus \(chunk-...js)
// at HTMLDocument.onFocus \(chunk-...js)
// at windowObject.HTMLElement.focus \(chunk-...js)
// at HTMLDocument.contain2 \(chunk-...js)
// at windowObject.HTMLElement.focus \(chunk-...js)
// at HTMLDocument.onFocus \(chunk-...js)

import React from 'react'

// eslint-disable-next-line no-restricted-imports -- Since this is a wrapper component, we need to import the original component
import { Dialog as MUIDialog } from '@mui/material'

type DialogProps = React.ComponentProps<typeof MUIDialog>

export function Dialog(props: DialogProps): React.ReactElement {
  const { disableEnforceFocus = true, ...restProps } = props

  return <MUIDialog {...restProps} disableEnforceFocus={disableEnforceFocus} />
}
