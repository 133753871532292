import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import {
  OBJECT_PROPERTIES_QUERY_KEY,
  ObjectProperty,
  useObjectProperties,
} from 'api/objectProperties/objectProperties.api'
import { useSettingMutation } from 'api/settings/settings.api'
import { useSystem } from 'api/systems/systems.api'
import { Unit, useUnits } from 'api/units/units.api'
import { Button, Switch, TextField } from 'ui/atoms'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'

import { CircularProgress, Collapse, Grid, InputAdornment, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../AuthContext/AuthContext'
import { LevelValues, UnitSettings } from '../OptimizeFormProvider/OptimizeFormProvider'
import { clone } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'

import styles from './OptModelsAccumulatorLevels.module.less'

function roundToTwo(number: number): number {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

type OptModelsAccumulatorLevelsProps = {
  setIsValid: (isValid: boolean) => void
  setUnitSettings: (unitSettings: UnitSettings) => void
  optModelId: number
}

export function OptModelsAccumulatorLevels({
  setIsValid,
  setUnitSettings,
  optModelId,
}: OptModelsAccumulatorLevelsProps): ReactElement {
  const { t } = useTranslation()
  const { systemId } = useAuth()
  const { data: activeSystem } = useSystem({ id: systemId })
  const { data: units = [] } = useUnits({ optModel: optModelId, unit_type: `Storage` })

  const { mutate: updateSetting } = useSettingMutation()
  const [toggleState, setToggleState] = useState<'loading' | 'error' | ''>('')
  const [unitIdToUnitMap, setUnitIdToUnitMap] = useState<Record<number, Unit>>({})
  const [showAccInputs, setShowAccInputs] = useState(false)

  useEffect(() => {
    setShowAccInputs(units.length < 2)
  }, [activeSystem?.primary_digital_twin, units.length])

  useEffect(() => {
    const initUnitMap: Record<number, Unit> = {}

    units.forEach((unit) => {
      initUnitMap[unit.id] = clone(unit)
    })

    setUnitIdToUnitMap(initUnitMap)
  }, [units])

  // [2023-12-21]: https://app-eu.wrike.com/open.htm?id=1148309904
  const { data: objectPropertyFictiveAckEndLevel = [] } = useObjectProperties({
    optModel: activeSystem?.primary_opt_model?.id,
    name: 'endlevel_fictive_income',
    start_time: Datetime.getISONow(0, true),
    end_time: Datetime.getISONow(1, true),
  })
  const { data: objectPropertyFictiveAckEndLevelEnabled = [] } = useObjectProperties({
    optModel: activeSystem?.primary_opt_model?.id,
    name: 'endcost_active',
    start_time: Datetime.getISONow(0, true),
    end_time: Datetime.getISONow(1, true),
  })

  const ackLevels = useMemo(() => {
    const objectProperties: ObjectProperty[] = [
      ...objectPropertyFictiveAckEndLevel,
      ...objectPropertyFictiveAckEndLevelEnabled,
    ]

    return units.reduce<LevelValues[]>((prev, unit) => {
      if (!unit.latest_storage_value) return prev
      const {
        value: startLevel,
        min_level: minLevel,
        max_level: maxLevel,
        end_level: endLevel,
      } = unit.latest_storage_value

      let dynamicEndLevelAvailable = false
      let dynamicEndLevelActive = false
      let dynamicEndLevelValue = 0
      let dynamicEndLevelActiveId = 0
      let dynamicEndLevelValueId = 0

      objectProperties
        .filter((op) => op.parent?.id === unit.id)
        .forEach((op) => {
          if (op.name === 'endcost_active') {
            dynamicEndLevelAvailable = true
            dynamicEndLevelActive = op.current_active_value === 1
            dynamicEndLevelActiveId = op.id
          }

          if (op.name === 'endlevel_fictive_income') {
            dynamicEndLevelValue = op.current_active_value ?? op.default_value
            dynamicEndLevelValueId = op.id
          }
        })

      return [
        ...prev,
        {
          unit: unit,
          startLevel: String(roundToTwo(startLevel)),
          endLevel:
            endLevel != null && endLevel > maxLevel
              ? String(roundToTwo(maxLevel))
              : endLevel != null
                ? String(roundToTwo(endLevel))
                : ``,
          minLevel,
          maxLevel,
          dynamicEndLevelAvailable,
          dynamicEndLevelActive,
          dynamicEndLevelValue,
          dynamicEndLevelActiveId,
          dynamicEndLevelValueId,
        },
      ]
    }, [])
  }, [units, objectPropertyFictiveAckEndLevel, objectPropertyFictiveAckEndLevelEnabled])

  const { unitIdToErrors, unitIdToBoundries, unitIdToAccLevels } = useMemo(() => {
    const unitIdsWithErrors = new Set<number>([])
    const unitIdToErrors: Record<string, { startLevel: boolean; endLevel: boolean }> = {}
    const unitIdToBoundries: Record<
      string,
      { startLevel: number; endLevel: number; minLevel: number; maxLevel: number }
    > = {}
    const unitIdToAccLevels: Record<string, LevelValues> = {}

    Object.entries(unitIdToUnitMap).map(([unitId, unit]) => {
      const accLevel = ackLevels.find(({ unit }) => String(unit.id) === unitId) as LevelValues

      const { minLevel, maxLevel } = accLevel ?? {}
      const startLevel = unit.latest_storage_value?.value as number
      const endLevel = unit.latest_storage_value?.end_level as number
      const errors = {
        startLevel: false,
        endLevel: false,
      }

      if (minLevel > startLevel || startLevel > maxLevel) {
        errors.startLevel = true
      }

      if (minLevel > endLevel || endLevel > maxLevel) {
        errors.endLevel = true
      }

      if (errors.startLevel || errors.endLevel) {
        unitIdsWithErrors.add(unit.id)
      } else {
        unitIdsWithErrors.delete(unit.id)
      }

      unitIdToErrors[unitId] = errors
      unitIdToBoundries[unitId] = { minLevel, maxLevel, startLevel, endLevel }
      unitIdToAccLevels[unitId] = accLevel
    })

    setIsValid(unitIdsWithErrors.size === 0)

    return {
      unitIdToErrors,
      unitIdToBoundries,
      unitIdToAccLevels,
    }
  }, [ackLevels, setIsValid, unitIdToUnitMap])

  async function handleDynamicEndLevelToggle(
    dynamicEndLevelActiveId: number,
    dynamicEndLevelAvailable: boolean,
    dynamicEndLevelActive: boolean
  ): Promise<void> {
    if (!dynamicEndLevelAvailable || toggleState === 'loading') {
      return
    }

    setToggleState('loading')

    try {
      await updateSetting({
        data: {
          object_property: dynamicEndLevelActiveId,
          value: dynamicEndLevelActive ? 0 : 1,
          start_time: Datetime.getISONow(),
        },
      })

      // NOTE: Invalidation is triggered before update updateSetting, even when await is used. Could be an internal debounce in react-query. Hence the timeout.
      await new Promise((resolve) => setTimeout(resolve, 500))
      await queryClient.invalidateQueries(OBJECT_PROPERTIES_QUERY_KEY)
      setToggleState('')
    } catch (error: unknown) {
      sentry.captureException(error as Error)
      setToggleState('error')
    }
  }

  useEffect(() => {
    const unitSettings: UnitSettings = {}

    Object.values(unitIdToUnitMap).forEach((unit) => {
      unitSettings[unit.name] = {
        start_level: unit.latest_storage_value?.value,
        end_level: unit.latest_storage_value?.end_level,
      }
    })

    setUnitSettings(unitSettings)
  }, [setUnitSettings, unitIdToUnitMap])

  const accumulatorInputs = Object.entries(unitIdToUnitMap).map(([unitId, unit]) => {
    const accLevel = unitIdToAccLevels[unitId]
    const errors = unitIdToErrors[unitId]
    const { minLevel, maxLevel, startLevel, endLevel } = unitIdToBoundries[unitId]

    return (
      <div key={unitId}>
        {Object.keys(unitIdToUnitMap).length >= 2 && <Typography>{unit.display_name}</Typography>}
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} lg={6}>
            <Tooltip
              title={
                activeSystem?.automatic_optimization
                  ? t(`Accumulator levels cannot be changed when automatic optimization is active`)
                  : ''
              }
              placement="top"
              arrow
            >
              <div style={{ marginBottom: accLevel?.dynamicEndLevelAvailable ? '37px' : undefined }}>
                <TextField
                  type="number"
                  margin="normal"
                  value={roundToTwo(startLevel)}
                  label={t('Start level accumulator')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">MWh</InputAdornment>,
                  }}
                  onChange={(value) => {
                    setUnitIdToUnitMap({
                      ...unitIdToUnitMap,
                      [unitId]: {
                        ...unit,
                        latest_storage_value: {
                          ...unit.latest_storage_value,
                          value: Number(value),
                          end_level: endLevel,
                        },
                      },
                    })
                  }}
                  error={errors.startLevel}
                  disabled={activeSystem?.automatic_optimization}
                  rules={{
                    min: minLevel,
                    max: maxLevel,
                    required: true,
                  }}
                  helperText={
                    errors.startLevel
                      ? t(`Range between {{lower}} - {{upper}}`, { lower: minLevel, upper: maxLevel })
                      : undefined
                  }
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Tooltip
              title={
                activeSystem?.automatic_optimization
                  ? t(`Accumulator levels cannot be changed when automatic optimization is active`)
                  : ''
              }
              placement="top"
              arrow
            >
              <div>
                <TextField
                  type="number"
                  margin="normal"
                  value={roundToTwo(endLevel)}
                  label={
                    accLevel?.dynamicEndLevelAvailable && accLevel?.dynamicEndLevelActive
                      ? t('Max end level accumulator')
                      : t('End level accumulator')
                  }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">MWh</InputAdornment>,
                  }}
                  rules={{
                    min: minLevel,
                    max: maxLevel,
                    required: true,
                  }}
                  error={errors.endLevel}
                  disabled={activeSystem?.automatic_optimization}
                  helperText={
                    errors.endLevel
                      ? t(`Range between {{lower}} - {{upper}}`, { lower: minLevel, upper: maxLevel })
                      : undefined
                  }
                  onChange={(value) => {
                    setUnitIdToUnitMap({
                      ...unitIdToUnitMap,
                      [unitId]: {
                        ...unit,
                        latest_storage_value: {
                          ...unit.latest_storage_value,
                          start_level: startLevel,
                          end_level: Number(value),
                        },
                      },
                    })
                  }}
                />
              </div>
            </Tooltip>

            {accLevel?.dynamicEndLevelAvailable && (
              <Grid container direction="row">
                <Switch
                  value={
                    accLevel?.dynamicEndLevelAvailable && accLevel?.dynamicEndLevelActive ? t(`Dynamic`) : t(`Static`)
                  }
                  items={[
                    {
                      value: t(`Dynamic`),
                      label: t(`Dynamic`),
                      tooltip: t(`Setting for end level in accumulator`),
                    },
                    {
                      value: t(`Static`),
                      label: t(`Static`),
                      tooltip: t(`Setting for end level in accumulator`),
                    },
                  ]}
                  onClick={() => {
                    if (!accLevel) {
                      setToggleState('error')
                      return
                    }

                    handleDynamicEndLevelToggle(
                      accLevel.dynamicEndLevelActiveId,
                      accLevel.dynamicEndLevelAvailable,
                      accLevel.dynamicEndLevelActive
                    )
                  }}
                />
                {toggleState && (
                  <CircularProgress size={25} className={styles.OptModelsAccumulatorLevels_CircularProgress} />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  })

  if (units.length >= 2) {
    return (
      <div>
        <Button link={true} onClick={() => setShowAccInputs(!showAccInputs)}>
          {t('Start and end levels for accumulators')}
        </Button>
        <Collapse in={showAccInputs} className={styles.OptModelsAccumulatorLevels_Collapse}>
          <div className={styles.OptModelsAccumulatorLevels_AccumulatorInputs}>{accumulatorInputs}</div>
        </Collapse>
      </div>
    )
  }

  return <div>{accumulatorInputs}</div>
}
