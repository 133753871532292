import { DigitalTwinSetting } from 'api/digitalTwin/digitalTwin.api'
import { OptJobStatusObject } from 'api/optJobs/optJobs.api'
import { Setting } from 'api/settings/settings.api'

import i18n from 'i18next'
import moment, { Moment } from 'moment'

export const timeCategoryField = `time`
export const nonStackedIndex = 10000

export function getOptimizationPeriod(): {
  startTime: Moment
  endTime: Moment
  } {
  const startTime = moment().add(1, `hour`).startOf(`hour`)
  return {
    startTime: startTime,
    endTime: moment(startTime).add(167, `hours`),
  }
}

export function isActiveSetting(setting: Setting | DigitalTwinSetting): boolean {
  const optStartTime = moment().add(1, `hour`).set(`minutes`, 0).set(`seconds`, 0)
  return optStartTime <= moment(setting.end_time) || setting.end_time === null
}

export function isHistorySetting(setting: Setting | DigitalTwinSetting): boolean {
  const optStartTime = moment().add(1, `hour`).set(`minutes`, 0).set(`seconds`, 0)
  return moment(setting.end_time) < optStartTime
}

export function getDelay(): number {
  const now = moment()
  const nextHour = moment().add(1, `hour`).minutes(0).seconds(0)
  return nextHour.diff(now)
}

export function optJobIsDone(optJobStatus?: OptJobStatusObject | null): boolean {
  if (!optJobStatus) {
    return true
  }

  return optJobStatus.status !== `Created` && optJobStatus.status !== `Queued` && optJobStatus.status !== `Running`
}

export function getTranslatedCategoryDisplayName(category: string | null): string {
  return ({
    Grundvärde: i18n.t(`Base value`),
    Gränsvärde: i18n.t(`Limit value`),
    Tillgänglighet: i18n.t(`Availability`),
    Tvångskörning: i18n.t(`Forced availability`),
  }[category || ``] ?? `-`) as string
}
