import React, { ReactElement } from 'react'

import { TextField, MenuItem, OutlinedTextFieldProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type BinarySettingAlternative = {
  value: number
  name: string
}

type BinarySettingSelectProps = Partial<OutlinedTextFieldProps> & {
  alternatives: BinarySettingAlternative[]
}

export default function BinarySettingSelect({
  alternatives,
  defaultValue,
  ...props
}: BinarySettingSelectProps): ReactElement {
  const { t } = useTranslation()
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name="value"
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          id="value"
          label={t(`Value `)}
          select
          variant="outlined"
          margin="normal"
          fullWidth
          disabled
          {...field}
          {...props}
        >
          {alternatives.map((alternative) => (
            <MenuItem key={alternative.value} value={alternative.value}>
              {alternative.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}
