import React, { useState, ReactElement } from 'react'

import { useBulletinBoardCommentMutation, useBulletinBoardThreadMutation } from 'api/bulletinBoard/bulletinBoard.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { useCardWidget } from 'ui/components/CardWidget/CardWidget'

import Picker from '@emoji-mart/react'
import {
  TextField,
  Grid,
  Chip,
  Icon,
  InputAdornment,
  IconButton,
  Button,
  Popper,
} from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'


import { useHasPermission } from 'helpers/global.helper/global.helper'

import styles from './TextField.module.less'

type BulletinBoardTextFieldProps = {
  ids: number[]
  type: `thread` | `comment`
  content: number
}

export default function BulletinBoardTextField({ ids, type }: BulletinBoardTextFieldProps): ReactElement {
  const { t } = useTranslation()

  const [text, setText] = useState(``)
  const [files, setFiles] = useState<File[] | undefined>()
  const [commentTextCursorIndex, setCommentTextCursorIndex] = useState(-1)
  const [anchorEmoji, setAnchorEmoji] = React.useState(false)
  const emojiRef = React.useRef(null)

  const { systemId } = useAuth()
  const { mutate: bulletinBoardThreadPost } = useBulletinBoardThreadMutation()
  const { mutate: bulletinBoardCommentPost } = useBulletinBoardCommentMutation()

  function post(): void {
    const filesToPost = files
    setFiles(undefined)
    setText(``)
    if (type === `comment`) {
      bulletinBoardCommentPost({ bulletin_board_thread: ids[0], content: text, files: filesToPost })
    } else {
      if (systemId) {
        bulletinBoardThreadPost({ system: systemId, content: text, files: filesToPost })
      }
      if (ids.length > 0) {
        ids.forEach(function (receiverId) {
          bulletinBoardThreadPost({ system: receiverId, content: text, files: filesToPost })
        })
      }
    }
  }

  function textfieldCursorChange(e: React.SyntheticEvent): void {
    const target = e.target as HTMLInputElement
    if (
      target.selectionStart !== undefined &&
      target.selectionStart !== null &&
      target.selectionStart !== commentTextCursorIndex
    ) {
      setCommentTextCursorIndex(target.selectionStart)
    }
  }

  function keyPress(e: React.KeyboardEvent): void {
    const target = e.target as HTMLTextAreaElement
    if (e.ctrlKey && e.keyCode === 13 && target.value?.trim().length > 0) {
      post()
    }
  }

  const { node } = useCardWidget()

  const hasChangeCommentsAccess = useHasPermission('change_comments')

  return (
    <>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid
          item
          lg={type === `comment` ? 8 : 10}
          xs={9}
          className={type === `comment` ? undefined : styles.TextField_TextFieldThread}
        >
          <TextField
            minRows={1}
            variant="standard"
            maxRows={10}
            value={text}
            aria-label="Post text field"
            onFocus={textfieldCursorChange}
            onKeyUp={(event) => {
              textfieldCursorChange(event)
              keyPress(event)
            }}
            onClick={textfieldCursorChange}
            onChange={(e) => {
              textfieldCursorChange(e)
              setText(e.target.value)
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <Grid container justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <InputAdornment position="end" className={styles.TextField_IconTextField} ref={emojiRef}>
                      <IconButton
                        aria-label="Emoji picker"
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick={(ev) => {
                          // Intended to circumvent issue where the Emojipart
                          // picker would close immediately after opening (the
                          // event is viewed as a click outside the picker,
                          // closing it immediately)
                          ev.stopPropagation()
                          setAnchorEmoji(!anchorEmoji)
                        }}
                        className={classNames(`fal fa-smile`, styles.TextField_EmojiIconButton)}
                        size="large"
                      />
                      {/* <FileDropArea sendFile={handleSentFile} /> TODO: Sort out file drop when time and ready*/}
                    </InputAdornment>
                  </Grid>
                </Grid>
              ),
            }}
            placeholder={type === `comment` ? t(`Write your comment here..`) : t(`Write your message here..`)}
            multiline
            className={styles.TextField_TextField}
          />

          {files
            ? files.map((file) => {
              return (
                <Chip
                  key={file.name}
                  size="small"
                  icon={<Icon className={`fal fa-file`} />}
                  onDelete={() => setFiles(undefined)}
                  label={file.name}
                  className={styles.TextField_UploadFileIcon}
                />
              )
            })
            : null}
        </Grid>
        <Grid item container lg={type === `comment` ? 1 : 2} xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => post()}
            className={type === `comment` ? styles.TextField_SendButtonComment : styles.TextField_SendButtonThread}
            disabled={(text.trim().length <= 0 ? true : false) || (!hasChangeCommentsAccess ? true : false)}
          >
            {type === `comment` ? t(`Send`) : t(`Post`)}
          </Button>
        </Grid>
        <Grid>
          <Popper anchorEl={emojiRef.current} open={Boolean(anchorEmoji)} container={node?.current}>
            <Picker
              set="twitter"
              title="Emojis"
              onEmojiSelect={(emoji: { native: string }) =>
                setText(
                  text.substring(0, commentTextCursorIndex) +
                      (emoji).native +
                      text.substring(commentTextCursorIndex)
                )
              }
              showPreview={false}
              showSkinTones={false}
              onClickOutside={() => {
                setAnchorEmoji(false)}
              }
            />
          </Popper>
        </Grid>
      </Grid>
    </>
  )
}
