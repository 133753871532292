import { useEffect, useState } from 'react'

import { Setting, SettingCreateParams, useSettingDeleteMutation, useSettingMutation } from 'api/settings/settings.api'
import alertStore from 'store/alert/alert'
import { samenergiStore } from 'store/samenergi/samenergi'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { settingInPeriod } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { checkIfSweSummerTime } from 'helpers/samEnergi.helper/samEnergi.helper'

export type SettingAlertTexts = {
  change?: {
    success?: string
    error?: string
    warning?: string
  }
  delete?: {
    success?: string
    error?: string
  }
}

export function useSettingChange({
  alertTexts,
}: {
  alertTexts?: SettingAlertTexts
} = {}): {
  edit: (
    id: number,
    object_property: number,
    start_time: string,
    end_time: string | null,
    value: number,
    is_base_value: boolean,
    comment: string | null,
    classification?: string
  ) => Promise<Setting | undefined>
  add: (
    object_property: number,
    start_time: string,
    end_time: string | null,
    value: number,
    is_base_value: boolean,
    comment: string | null,
    classification?: string
  ) => Promise<Setting | undefined>
  remove: (id: number, objectPropertyId: number) => Promise<{ id: number; objectPropertyId: number } | undefined>
} {
  const { t } = useTranslation()

  const { mutateAsync: mutate } = useSettingMutation()
  const { mutateAsync: deleteMutate } = useSettingDeleteMutation()

  const snap = useSnapshot(samenergiStore)

  const [settingAdded, setSettingAdded] = useState({
    added: false,
    startTime: null,
    endTime: null,
    classification: null,
  })

  useEffect(() => {
    if (
      snap.unplannedSummerRemaining < 0 &&
      checkIfSweSummerTime(moment(settingAdded.startTime) || moment(settingAdded.endTime)) === true &&
      settingAdded.classification === 'unplanned'
    ) {
      alertStore.warning(
        alertTexts?.change?.warning ??
          t(
            `The limit for the number of hours allowed to decline has been exceeded. The setting is still saved and you need to take action so that we don't break the contract with the waste heat supplier!`
          )
      )
    } else if (
      snap.unplannedWinterRemaining < 0 &&
      checkIfSweSummerTime(moment(settingAdded.startTime) || moment(settingAdded.endTime)) === false &&
      settingAdded.classification === 'unplanned'
    ) {
      alertStore.warning(
        alertTexts?.change?.warning ??
          t(
            `The limit for the number of hours allowed to decline has been exceeded. The setting is still saved and you need to take action so that we don't break the contract with the waste heat supplier!`
          )
      )
    } else if (snap.plannedRemaining < 0 && settingAdded.classification === 'planned') {
      alertStore.warning(
        alertTexts?.change?.warning ??
          t(
            `The limit for the number of hours allowed to decline has been exceeded. The setting is still saved and you need to take action so that we don't break the contract with the waste heat supplier!`
          )
      )
    } else if (settingAdded.added) {
      alertStore.success(
        alertTexts?.change?.success ??
          t(`The new setting affects the production plan. Make sure to update the production plan`)
      )
    }
  }, [
    snap.unplannedWinterRemaining,
    snap.unplannedSummerRemaining,
    snap.plannedRemaining,
    settingAdded.startTime,
    settingAdded.endTime,
    settingAdded.added,
    settingAdded.classification,
    alertTexts?.change?.success,
    alertTexts?.change?.warning,
    t,
  ])

  const changeFunction = ({ id, data }: { id?: number; data: SettingCreateParams }): Promise<Setting | undefined> =>
    mutate(
      {
        id,
        data,
      },
      {
        onSuccess: (data) => {
          if (
            settingInPeriod(moment(data.start_time), data.end_time ? moment(data.end_time) : null) &&
            data.classification !== 'unplanned' &&
            data.classification !== 'planned'
          ) {
            alertStore.success(
              alertTexts?.change?.success ??
                t(`The new setting affects the production plan. Make sure to update the production plan`)
            )
          } else if (data.classification !== 'unplanned' && data.classification !== 'planned') {
            alertStore.success(alertTexts?.change?.success ?? t(`Setting saved.`))
          }
        },
        onError: () => {
          alertStore.error(alertTexts?.change?.error ?? t(`The setting could not be saved`))
        },
      }
    )

  async function edit(
    id: number,
    object_property: number,
    start_time: string,
    end_time: string | null,
    value: number,
    is_base_value: boolean,
    comment: string | null,
    classification?: string
  ): Promise<Setting | undefined> {
    setSettingAdded({ added: true, startTime: start_time, endTime: end_time, classification: classification })
    return changeFunction({
      id,
      data: { object_property, start_time, end_time, value, is_base_value, comment, classification },
    })
  }

  async function add(
    object_property: number,
    start_time: string,
    end_time: string | null,
    value: number,
    is_base_value: boolean,
    comment: string | null,
    classification?: string
  ): Promise<Setting | undefined> {
    setSettingAdded({ added: true, startTime: start_time, endTime: end_time, classification: classification })
    return changeFunction({
      data: { object_property, start_time, end_time, value, is_base_value, comment, classification },
    })
  }

  async function remove(
    id: number,
    objectPropertyId: number
  ): Promise<{ id: number; objectPropertyId: number } | undefined> {
    return deleteMutate(
      { id, objectPropertyId },
      {
        onSuccess: () => {
          alertStore.success(alertTexts?.delete?.success ?? t(`Setting removed.`))
        },
        onError: (message) => {
          alertStore.error(alertTexts?.delete?.error ?? t(`The setting could not be removed`))
          return Promise.reject(message)
        },
      }
    )
  }

  return {
    add,
    edit,
    remove,
  }
}
