import React, { useState, ReactElement } from 'react'

import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import SettingsTable, { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getDateTimePickerRangesFromPermissions } from 'helpers/settingsModal.helper/settingsModal.helper'

const OptimizeModelSettingHeaderContentClass = `optSettingsHeader`
const widgetConstants = {
  OPTIMIZE_MODEL_SETTINGS_TABLE: `optimize-model-settings-table`,
  OPTIMIZE_SETTINGS_PRICES: `optimize-settings-prices`,
}

const modelInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_MODEL_SETTINGS_TABLE,
    content: `Here you can find all active base values for the plants' optimization model. \n\nA base value is an input to the optimization model and does not categorize as a deviation. These settings are used to change the base value for a property, ie. which value is used when there are no deviation settings currently active. Click on 'Show/edit' to change base value, edit and delete current settings.\n\nNote that base values affect the production plan for the entire system!`,
    title: `Table showing all base values`,
  },
]

const priceInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_SETTINGS_PRICES,
    content: `All prices and cost inputs to the optimization model are shown here. These properties have the same functionality as base values.`,
    title: `Opt model prices`,
  },
]

const steps: Array<Step> = [...modelInfo, ...priceInfo]

export default function OptimizeModelSettingsView(): ReactElement {
  const { t } = useTranslation()

  const [runTour, setRunTour] = useState<null | number>(null)

  const permissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_model_settings`),
    canEditHistoric: useHasPermission(`change_historic_model_settings`),
  }

  const startEndTimeRange = getDateTimePickerRangesFromPermissions(permissions.canEditHistoric)

  return (
    <View
      header={
        <div className={OptimizeModelSettingHeaderContentClass}>
          <ModuleHeader
            infoHeader={t(`Opt model settings`)}
            onInfoButtonClick={() => {
              setRunTour(0)
            }}
          />
        </div>
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? modelInfo : runTour === 2 ? priceInfo : steps}
      />

      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.OPTIMIZE_MODEL_SETTINGS_TABLE}
          title={t(`Optimization settings - base values`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <SettingsTable
            permissions={permissions}
            startEndTimeRange={startEndTimeRange}
            classification={`base`}
            tableOptions={{
              initialState: { sortBy: [{ id: `updatedAt`, desc: true }], hiddenColumns: [`category`, `status`] },
            }}
          />
        </CardWidget>
      </Grid>

      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.OPTIMIZE_SETTINGS_PRICES}
          title={t(`Prices`)}
          handleInfoIconClick={() => {
            setRunTour(2)
          }}
        >
          <SettingsTable
            permissions={permissions}
            startEndTimeRange={startEndTimeRange}
            classification={`price`}
            tableOptions={{
              initialState: { sortBy: [{ id: `updatedAt`, desc: true }], hiddenColumns: [`category`, `status`] },
            }}
          />
        </CardWidget>
      </Grid>
    </View>
  )
}
