import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './Badge.module.less'

type BadgeProps = {
  title: string
  success?: boolean
  warning?: boolean
  danger?: boolean
  info?: boolean
  right?: boolean
  absolute?: boolean
}

export default function Badge({ title, success, warning, danger, info, right, absolute }: BadgeProps): ReactElement {
  return (
    <div
      className={dynamicClassName({
        [styles.Badge]: true,
        [styles.Badge__success]: !!success,
        [styles.Badge__warning]: !!warning,
        [styles.Badge__danger]: !!danger,
        [styles.Badge__info]: !!info,
        [styles.Badge__right]: !!right,
        [styles.Badge__absolute]: !!absolute,
      })}
    >
      {title}
    </div>
  )
}
