import moment, { Moment } from 'moment-timezone'

export type datesAndWeeks = {
  startDate: Moment
  endDate: Moment
  weekNumber: string
}

export type datesAndWeeksString = {
  startDate: string
  endDate: string
  weekNumber: string
}

function getStartAndEndForWeek(date: Moment): datesAndWeeks {
  return {
    startDate: date.clone().startOf(`isoWeek`),
    endDate: date.clone().endOf(`isoWeek`).hour(23).minutes(0).seconds(0),
    weekNumber: date.clone().isoWeek().toString(),
  }
}

function recursive(date: Moment, endDate: Moment, array: datesAndWeeks[]): datesAndWeeks[] {
  if (date.isoWeek() !== endDate.isoWeek()) {
    array.push(getStartAndEndForWeek(date))
    const newDate = date.add(1, `weeks`)
    return recursive(newDate, endDate, array)
  }
  return array
}

function getStringDateFormat(array: datesAndWeeks[]): datesAndWeeksString[] {
  const stringArray: datesAndWeeksString[] = []

  array.forEach((element) => {
    stringArray.push({
      startDate: element.startDate.startOf('second').toISOString(),
      endDate: element.endDate.startOf('second').toISOString(),
      weekNumber: element.weekNumber,
    })
  })

  return stringArray
}

export function convertToFollowupPeriod(startTime: Moment, endTime: Moment, timeZone?: string): datesAndWeeksString[] {
  const clonedStartTime = timeZone ? startTime.clone().tz(timeZone) : startTime.clone()
  const clonedEndTime = timeZone ? endTime.clone().tz(timeZone) : endTime.clone()
  const currentWeek = timeZone ? moment().tz(timeZone).isoWeek() : moment().isoWeek()

  const firstWeekObject = clonedStartTime.isoWeek() !== currentWeek ? getStartAndEndForWeek(clonedStartTime) : undefined
  const lastWeekObject =
    clonedEndTime.isoWeek() !== currentWeek
      ? getStartAndEndForWeek(clonedEndTime)
      : getStartAndEndForWeek(clonedEndTime.subtract(1, `weeks`))

  if (firstWeekObject) {
    const weekObjectArray: datesAndWeeks[] = recursive(firstWeekObject?.endDate, lastWeekObject.endDate, [])
    weekObjectArray.push(lastWeekObject)

    return getStringDateFormat(weekObjectArray)
  }

  return []
}
