import React, { ReactElement } from 'react'

import { EvaluationStatus } from 'api/shiftHandovers/shiftHandovers.api'

import { Icon, Radio, RadioProps } from '@mui/material'
import classNames from 'classnames'

import styles from './EvaluationEmojiIcon.module.less'

const faClasses: Record<EvaluationStatus, string | undefined> = {
  very_good: `fa fa-laugh`,
  good: `fa fa-smile`,
  bad: `fa fa-meh`,
  very_bad: `fa fa-frown`,
  undefined: `undefined`,
}

const statusStyle = {
  very_good: {
    color: `#009D3F`,
  },
  good: {
    color: `#ABD700`,
  },
  bad: {
    color: `#FFCB00`,
  },
  very_bad: {
    color: `#BD2727`,
  },
  undefined: {
    color: `#BD2727`,
  },
  active: {
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
} as const

type EvaluationEmojiIconProps = RadioProps & {
  evaluationStatus: EvaluationStatus
}

export function EmojiIcon({ evaluationStatus }: EvaluationEmojiIconProps): ReactElement {
  return <Icon sx={statusStyle[evaluationStatus]} className={classNames(faClasses[evaluationStatus])} fontSize="large" />
}

const EvaluationEmojiIcon = React.forwardRef<HTMLSpanElement, EvaluationEmojiIconProps>(function EvaluationEmojiIcon(
  { evaluationStatus, disabled, ...props }: EvaluationEmojiIconProps,
  ref
): ReactElement {
  return (
    <span ref={ref}>
      <Radio
        aria-label={evaluationStatus}
        disabled={disabled}
        value={evaluationStatus}
        disableRipple
        color="default"
        checkedIcon={
          <Icon sx={statusStyle[evaluationStatus]} className={classNames(faClasses[evaluationStatus])} fontSize="large" />
        }
        icon={
          <Icon
            sx={statusStyle[evaluationStatus]}
            className={classNames(
              faClasses[evaluationStatus],
              styles.EvaluationEmojiIcon_Active
            )}
            fontSize="large"
          />
        }
        {...props}
      />
    </span>
  )
})
export default EvaluationEmojiIcon
