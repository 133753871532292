import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import styles from './WarningTriangle.module.less'

type WarningTriangleProps = {
  tooltip?: string
  visible?: boolean
}

export default function WarningTriangle({ tooltip, visible }: WarningTriangleProps): ReactElement {
  if (visible === false) {
    return <></>
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip} display='block' arrow>
        <div className={styles.WarningTriangle}>
          <i className="fal fa-exclamation-triangle" />
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={styles.WarningTriangle}>
      <i className="fal fa-exclamation-triangle" />
    </div>
  )
}
