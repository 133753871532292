import React, { ReactElement, ReactNode, useMemo, useState } from 'react'

import { postStat } from 'api/stats/stats.api'
import filterStore, { allFiltersSelected } from 'store/filter/filter'
import { resetZoom } from 'store/zoom/zoom'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import FilterComponent from 'ui/components/Filter/FilterComponent'
import InfoBox from 'ui/components/ModuleHeader/InfoBox'
import { ToggleButton } from 'ui/molecules/ToggleButton/ToggleButton'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import OptimizeHeaderContent from '../OptimizeHeaderContent/OptimizeHeaderContent'
import DataResolutionSwitch from '../PeriodPicker/DataResolutionSwitch'
import OptimizeViewDateTimePeriodPicker from '../PeriodPicker/OptimizeViewDateTimePeriodPicker'
import { OutcomeCheckbox } from '../PeriodPicker/OutcomeCheckbox'
import { getRoute } from 'helpers/route.helper/route.helper'
import { getAvailableFilters } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import {
  getTopLevelOptions,
  parseTags,
} from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'
import optimizeViewStore, { OptimizeViewStore } from 'views/OptimizeView/store/optimizeViewStore'

import styles from './OptimizeModuleHeader.module.less'

type ModuleHeaderProps = {
  onInfoButtonClick?: () => void
  beta?: boolean
  secondaryChildren?: ReactNode
  includePropertyFilter?: boolean
}

type ZoomResolutionState = OptimizeViewStore['zoomResolutionSwitch']['type']


export default function OptimizeModuleHeader({
  onInfoButtonClick,
  beta,
  includePropertyFilter = true,
}: ModuleHeaderProps): ReactElement {
  const { t } = useTranslation()
  const { hasAccess } = useAuth()

  const { filterComponentPerRoute, allTagsPerRoute, currentActiveTagsPerRoute } = useSnapshot(filterStore)
  const [filterIsCollapsed, setFilterCollapsed] = useState<boolean>(true)
  const route = getRoute()

  const optimizeViewSnap = useSnapshot(optimizeViewStore)
  const zoomResolution = optimizeViewSnap.zoomResolutionSwitch.type

  const [resolutionState, setResolutionState] = useState(zoomResolution)
  const isCustomResolution = resolutionState === 'custom'

  const [baseResolutionState, setBaseResolutionState] = useState(zoomResolution)
  // We want to know when the optimize view store resolution changes, e.g. due to
  // several switch components, or if we reset the store for some reason (e.g.
  // switching system)
  if (baseResolutionState !== zoomResolution) {
    setBaseResolutionState(zoomResolution)
    setResolutionState(zoomResolution)
  }

  const handleDataResolutionSwitchChange = (value: ZoomResolutionState): void => {
    // Reflect the change in the UI, e.g. to open the date picker
    setResolutionState(value)

    // Hold off with updating the store until the user has selected a custom date
    if (value !== 'custom') {
      optimizeViewStore.zoomResolutionSwitch = { type: value }
      setBaseResolutionState(value)
      resetZoom()
    }
  }

  const handleCustomPeriodChange = (period: { startTime: ISODateTime; endTime: ISODateTime }): void => {
    optimizeViewStore.zoomResolutionSwitch = { type: 'custom', ...period }
    setBaseResolutionState('custom')
    resetZoom()
  }

  // Filter button is feature flagged
  // Only show filter button if there are filters to show
  const showFilterButton = useMemo(() => {
    if (!hasAccess({ submodule: 'optimize_filter', module: 'optimize' })) {
      return false
    }

    const routeHasFilterComponent = filterComponentPerRoute[route]
    const allTags = allTagsPerRoute[route] ?? []
    const options = parseTags(Array.from(allTags))
    const items = filterStore.tagsPerUiconfig
    const currentTags = currentActiveTagsPerRoute[route] ?? new Set()
    const values = Array.from(currentTags)
    const availableFilters = getAvailableFilters(items, values, new Set())

    // Note (Lage): We don't use 'hidden_default_tags' here.
    // Maybe we should, but I don't think that field is used anymore so we probably don't need to.
    const topLevelOptions = getTopLevelOptions(availableFilters, options, [])

    return routeHasFilterComponent && topLevelOptions.length > 0
  }, [allTagsPerRoute, currentActiveTagsPerRoute, filterComponentPerRoute, hasAccess, route])

  const filterIcon = allFiltersSelected(route) ? (
    ''
  ) : (
    <Icon tooltip={{ title: t('Filters active'), placement: 'right' }} icon="fas fa-filter" />
  )

  const toggleFilterCollapse = (): void => {
    postStat('/optimize', 'toggle-filter')
    setFilterCollapsed(!filterIsCollapsed)
  }

  return (
    <div className={styles.OptimizeModuleHeader_Container}>
      <div className={styles.OptimizeModuleHeader_Row}>
        <InfoBox infoHeader={t('Production plan')} onInfoButtonClick={onInfoButtonClick} beta={beta} />

        <div className={styles.OptimizeModuleHeader__padding}>
          <div className={styles.OptimizeViewPeriodPicker}>
            <div className={styles.OptimizeViewPeriodPicker_Container}>
              <DataResolutionSwitch onClick={handleDataResolutionSwitchChange} value={resolutionState} />
              {!isCustomResolution && (
                <div className={styles.OptimizeViewPeriodPicker_Container_Checkbox}>
                  <OutcomeCheckbox />
                </div>
              )}
              {isCustomResolution && <OptimizeViewDateTimePeriodPicker handlePeriodChange={handleCustomPeriodChange} />}
            </div>
          </div>{' '}
        </div>

        {showFilterButton && (
          <div className={styles.OptimizeModuleHeader__padding}>
            <ToggleButton isCollapsed={filterIsCollapsed} onClick={toggleFilterCollapse}>
              <span>
                {' '}
                {t('Filter')} {filterIcon}{' '}
              </span>
            </ToggleButton>
          </div>
        )}
        <div className={classNames(styles.OptimizeModuleHeader__rightaligned, styles.OptimizeModuleHeader__padding)}>
          <OptimizeHeaderContent type="regular" permission="run_regular_opt" />
        </div>
        {includePropertyFilter && (
          <div
            className={styles.OptimizeModuleHeader__bottompadding}
            style={{ display: filterIsCollapsed ? 'none' : 'block' }}
          >
            <FilterComponent datasetRefreshToken={'datasetRefreshToken'} />
          </div>
        )}
      </div>
    </div>
  )
}
