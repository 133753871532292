import React, { ReactElement } from 'react'

import { Icon, IconButton, IconButtonProps, TooltipProps, Tooltip } from '@mui/material'
import classNames from 'classnames'

type TooltipIconButtonProps = IconButtonProps & {
  icon: string | ReactElement
  tooltip: string
  tooltipProps?: Partial<TooltipProps>
  disabled?: boolean,
  iconColor?: string,
  iconSize?: 'small' | 'medium' | 'large'
}

function TooltipIconButton({
  className,
  icon,
  tooltip,
  tooltipProps,
  disabled,
  iconColor,
  iconSize,
  onClick,
}: TooltipIconButtonProps): ReactElement {
  return (
    <Tooltip title={tooltip} placement="top" arrow {...tooltipProps}>
      <span>
        <IconButton
          style={{padding: 6,  color: iconColor}}
          onClick={onClick}
          aria-label={tooltip}
          disabled={disabled}
        >
          {typeof icon === `string` ? <Icon className={classNames(icon, className)} fontSize={iconSize} /> : icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default TooltipIconButton
