import React, { ReactElement, useState } from 'react'

import { OptProject, useOptProjects, useOptProjectsDeleteMutation } from 'api/optProjects/optProjects.api'
import alertStore from 'store/alert/alert'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import HeaderPeriodPicker from 'ui/components/HeaderPeriodPicker/HeaderPeriodPicker'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Button, Icon, IconButton } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'

import CreateSandboxPopover from '../CreateSandboxPopover/CreateSandboxPopover'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'
import { getSandboxOptStatus, getSandboxOptType } from 'helpers/sandbox.helper/sandbox.helper'

import styles from './SandboxTable.module.less'

type ProjectStatus = `delete` | `create` | undefined

type SandboxTableContentProps = {
  projects: OptProject[]
}

function SandboxTableContent({ projects }: SandboxTableContentProps): ReactElement {
  const { t } = useTranslation()

  const [confirmStatus, setConfirmStatus] = useState<{ status: ProjectStatus; project?: OptProject }>({
    status: undefined,
    project: undefined,
  })

  const { mutateAsync: deleteMutate } = useOptProjectsDeleteMutation()

  function onDelete(id: number): void {
    deleteMutate(id).then(
      () => {
        alertStore.info(t(`The project was deleted`))
      },
      (errorMessage) => {
        alertStore.error(`${errorMessage}`)
      }
    )
  }

  const tableData = projects.map((project) => {
    const timeValue = project.updated_at ?? project.created_at

    return {
      createdChanged: timeValue !== `-` ? formatDateTimeToLocalizedString(timeValue, true) : timeValue,
      name: project.display_name,
      type: getSandboxOptType(project.project_type),
      period: `${formatDateTimeToLocalizedString(project.start_time, true)} - ${formatDateTimeToLocalizedString(
        project.end_time,
        true
      )}`,
      status: getSandboxOptStatus(project.status),
      manage: (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Link to={`/sandbox/` + project.id + `/`}>
              <Button variant="contained" color="primary">
                <Icon className={classNames(styles.SandboxTable_Icon, `fal fa-folder-open`)} />
                {project.status === `Finished` ? t(`Show result`) : t(`Open`)}
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Tooltip arrow placement="top" title={t(`Copy project`)}>
              <IconButton
                size="small"
                className="fal fa-copy"
                aria-label={t(`Copy project`)}
                onClick={() => setConfirmStatus({ status: `create`, project: project })}
              />
            </Tooltip>
            <Tooltip arrow placement="top" title={t(`Delete project`)}>
              <IconButton
                size="small"
                className={classNames(styles.SandboxTable_DeleteIcon, `fal fa-trash-alt`)}
                aria-label={t(`Delete project`)}
                onClick={() => setConfirmStatus({ status: `delete`, project: project })}
              />
            </Tooltip>
          </Grid>
        </Grid>
      ),
    }
  })

  const columns: Column<Record<string, unknown>>[] = [
    {
      accessor: `createdChanged`,
      Header: t(`Created/Changed`),
    },
    {
      accessor: `name`,
      Header: t(`Name`),
    },
    {
      accessor: `type`,
      Header: t(`Type of sandbox optimization`),
    },
    {
      accessor: `period`,
      Header: t(`Optimization period`),
    },
    {
      accessor: `status`,
      Header: t(`Status`),
    },
    {
      accessor: `manage`,
      Header: t(`Manage`),
    },
  ]

  return (
    <Grid item container lg={12}>
      <DefaultTable data={tableData} columns={columns} emptyTableText={t(`No previous projects found`)} />

      <ConfirmDialog
        open={confirmStatus.status === `delete`}
        onClose={() => {
          setConfirmStatus({ status: undefined, project: undefined })
        }}
        onConfirm={() => {
          confirmStatus.project && onDelete(confirmStatus.project.id)
          setConfirmStatus({ status: undefined, project: undefined })
        }}
        textObject={{
          title: t(`Are you sure you want to delete the selected project?`),
          text: t(`You cannot reverse this action.`),
        }}
      />

      {confirmStatus.project && (
        <CreateSandboxPopover
          open={confirmStatus.status === `create`}
          closePopover={() => setConfirmStatus({ status: undefined, project: undefined })}
          defaultValues={{ ...confirmStatus.project, display_name: confirmStatus.project.display_name + ` copy` }}
        />
      )}
    </Grid>
  )
}
function SandboxTable(): ReactElement {
  const [period, setPeriod] = useState({
    startTime: moment().subtract(10, `d`).startOf(`day`),
    endTime: moment().endOf(`day`),
  })
  const { data: projects, status } = useOptProjects({
    start_time: period.startTime.format(),
    end_time: period.endTime.format(),
  })

  return (
    <Grid container direction="row" justifyContent="flex-end">
      <Grid>
        <HeaderPeriodPicker period={period} handlePeriodChange={setPeriod} />
      </Grid>
      <StatusComponent status={[status]} data={{ projects }} Component={SandboxTableContent} />
    </Grid>
  )
}
export default SandboxTable
