import React, { ReactElement, useEffect, useState } from 'react'

import { useOptProject } from 'api/optProjects/optProjects.api'
import { resetZoom } from 'store/zoom/zoom'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CreateSandboxPopover from '../SandboxView/components/CreateSandboxPopover/CreateSandboxPopover'

import SandboxProjectHeader from './components/SandboxProjectHeader/SandboxProjectHeader'
import SandboxProjectWidget from './components/SandboxProjectWidget/SandboxProjectWidget'
import SandboxTitleContent from './components/SandboxTitleContent/SandboxTitleContent'

const widgetConstants = {
  SANDBOX_PROJECT: `sandboxProject`,
}

export default function SandboxProjectView(): ReactElement {
  const { id }: { id?: string } = useParams()
  const { t } = useTranslation()

  const { data: project, status } = useOptProject({ id: id != null ? Number(id) : id })
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  useEffect(() => {
    resetZoom()
  }, [id])

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={
            <Grid container direction="column">
              <Grid item>
                {t(`Sandbox`)}
                {project ? ` - ${project.display_name}` : null}
              </Grid>
              <SandboxTitleContent setCreateDialogOpen={setCreateDialogOpen} />
            </Grid>
          }
        >
          {project && <SandboxProjectHeader project={project} />}
        </ModuleHeader>
      }
    >
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.SANDBOX_PROJECT}
          title={t(`Conditions for sandbox optimization`)}
        >
          <StatusComponent data={{ project }} status={status} Component={SandboxProjectWidget} />
        </CardWidget>
      </Grid>
      <CreateSandboxPopover open={createDialogOpen} closePopover={() => setCreateDialogOpen(false)} />
    </View>
  )
}
