import { ReactElement, useState } from 'react'

import { DatePicker } from 'ui/molecules/pickers/DatePicker/DatePicker'

import { Button, Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import styles from './CopyBidsModal.module.less'

type Items = {
  label?: string
  heading?: string
  infoText?: string
  close?: string
  confirmButtonLabel?: string
}

type CopyBidsModalProps = {
  value: ISODateTime
  onSelect: (date: ISODateTime) => void
  showWeekNumbers?: boolean
  items?: Items
}

export default function CopyBidsModal(props: CopyBidsModalProps): ReactElement {
  const { t } = useTranslation()
  const [date, setDate] = useState<ISODateTime | null | undefined>(props.value)
  const isFaultyDate = !date

  function handleCallback(date: ISODateTime): void {
    props.onSelect(date)
  }


  return (
    <ModalOverlay className={styles.CopyBidsModal}>
      <Modal className={styles.CopyBidsModal_Modal}>
        <Dialog role="alertdialog" className={styles.CopyBidsModal_Dialog}>
          {({ close }) => (
            <>
              <Heading slot="title" className={styles.CopyBidsModal_Heading}>
                {props.items?.heading}
                <button className={styles.CopyBidsModal_close} onClick={close}>
                  <span>{props.items?.close}</span>
                  <span className="fal fa-times" />
                </button>
              </Heading>
              <div className={styles.CopyBidsModal_DatePickerContainer}>
                <DatePicker
                  value={date}
                  onChange={(date) => setDate(date)}
                  isRequired
                  showWeekNumbers
                  granularity={'day'}
                  label={t('Chosen date')}
                />
              </div>
              <div className={styles.CopyBidsModal_lowerSection}>
                <div className={styles.CopyBidsModal_infoSection}>
                  <span className={`fas fa-info-circle ${styles.CopyBidsModal_infoIcon}`} />
                  <p className={styles.CopyBidsModal_infoText}>{props.items?.infoText}</p>
                </div>
                <div className={styles.CopyBidsModal_buttonSection}>
                  <DialogButton className={styles.CopyBidsModal_Buttons__secondary} onPress={close}>
                    {t('Cancel')}
                  </DialogButton>
                  <DialogButton
                    className={`${isFaultyDate ? styles.CopyBidsModal_Buttons__primary_disabled : styles.CopyBidsModal_Buttons__primary}`}
                    isDisabled={isFaultyDate}
                    onPress={() => {
                      close()
                      // Assert that is defined since only able to submit if !isFaultyDate
                      if (date) {
                        handleCallback(date)
                      }
                    }}
                  >
                    {props.items?.confirmButtonLabel}
                  </DialogButton>
                </div>
              </div>
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  )
}

function DialogButton({ className, ...props }: { className: string; [key: string]: any }): ReactElement {
  return <Button {...props} className={`${styles.CopyBidsModal_Buttons} ${className}`} />
}
