import React, { ReactElement } from 'react'

import { OptProject, useOptProjectOptimizeMutation } from 'api/optProjects/optProjects.api'
import OptimizeFormProvider, { UnitSettings } from 'ui/components/OptimizeFormProvider/OptimizeFormProvider'

function OptProjectHeader({ project }: { project: OptProject }): ReactElement {
  const { mutateAsync, status: jobCreationStatus } = useOptProjectOptimizeMutation()

  function optimize(unitSettings: UnitSettings): void {
    mutateAsync({ id: project.id, inputData: { unit_settings: unitSettings } })
  }
  return (
    <OptimizeFormProvider
      optimize={optimize}
      sandboxProject={project}
      type="sandbox"
      creatingOptJob={jobCreationStatus === 'loading'}
    />
  )
}

export default OptProjectHeader
