import React, { ReactElement } from 'react'

import styles from './BCRow.module.less'

type RowProps = {
  height?: number
  width?: number | string
  children?: ReactElement | ReactElement[] | string
  centerContent?: boolean
  padded?: boolean
}

export default function Row({
  height = 35,
  width = '100%',
  centerContent = false,
  padded = false,
  children,
}: RowProps): ReactElement {
  const customClassName = centerContent ? styles.Row_Center : ``
  const paddedClassName = padded ? styles.Row_Padded : ``
  const classes = `${styles.Row} ${customClassName} ${paddedClassName}`

  return (
    <div
      className={classes}
      style={{
        height: height,
        maxHeight: height,
        width: width,
      }}
    >
      {children}
    </div>
  )
}
