import React, { ReactElement, useMemo, useRef } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import elplanStore from 'store/elplan/elplan'
import filterStore, { setGrouping } from 'store/filter/filter'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import UiConfigDebugMenu from 'ui/components/UiConfigDebugMenu/UiConfigDebugMenu'
import getUiConfigComponent, { getComponentHeight } from 'ui/uiConfig/factory'
import sentry from 'utils/sentry/sentry'

import { ErrorBoundary } from 'react-error-boundary'
import { useSnapshot } from 'valtio'

import { getReturnIdsFromDatasetsInstruction } from 'helpers/dataset.helper/dataset.helper'
import {
  filterDatasetInstructionsNotShown,
  getDigitalTwinTagsFromDataId,
  getItemsFromVariableCalcs,
  getItemsWithTags,
} from 'helpers/filterComponent.helper/filterComponent.helper'
import { isNumber } from 'helpers/global.helper/global.helper'
import { useGrouping } from 'helpers/grouping.helper/grouping.helper'
import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './UiConfigComponent.module.less'
import { parseWildcardItems } from './components/wildcardItems.helper'

type UiConfigComponentProps = {
  id?: number
  uid?: number
  systemId?: number | null
  datasetStartTime?: ISODateTime
  datasetEndTime?: ISODateTime
  noWrappingDiv?: boolean
  tourId?: string
  ignoreZoom?: boolean
  overrideAlias?: UiConfigAliases
  overrideProps?: UiConfigOverrideProps
  datasetRefreshToken?: string
  key_prefix?: string | number
  onDatasets?: (uid: number, datasets: Dataset[]) => void
  datasetCreatedAt?: string
  datasetCreatedAtOperator?: DatasetCreatedAtOperators
}

export default function UiConfigComponent({
  id: uiConfigId,
  uid,
  systemId,
  datasetStartTime,
  datasetEndTime,
  noWrappingDiv,
  tourId,
  ignoreZoom: _ignoreZoom,
  overrideAlias,
  overrideProps = {},
  datasetRefreshToken,
  key_prefix = '',
  onDatasets,
  datasetCreatedAt,
  datasetCreatedAtOperator,
}: UiConfigComponentProps): ReactElement | null {
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const filterSnap = useSnapshot(filterStore)
  const elplanSnap = useSnapshot(elplanStore)
  const route = getRoute()

  if (!uiConfigId && uid) {
    uiConfigId = uiConfigSnap.uiConfigs[uid]?.id
  }
  if (!uid && uiConfigId) {
    uid = uiConfigSnap.idToUiConfig[uiConfigId]?.uid as number
  }
  if (!systemId && uiConfigId) {
    systemId = uiConfigSnap.idToUiConfig[uiConfigId]?.system
  }

  const { activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true, true)
  const uiConfig = uid ? uiConfigSnap.getParsedUiConfig(uid, overrideAlias, { overrideProps }) : null
  const allTagsDict = useMemo(() => digitalTwin?.model?.all_tags_dict || {}, [digitalTwin?.model])
  const uiConfigProps = { ...uiConfig?.props }

  const applyFilterComponent = uiConfigProps.include_items_in_filtering && !uiConfigProps.ignore_filter_component

  if (uiConfigProps.has_filter_component) {
    filterStore.filterComponentPerRoute[route] = true
  }

  // Using useRef instead of useEffect as the conditions for applying the filter component are not static and in risk of rerendering too many times with a dependency array
  const initializeFiltering = useRef(applyFilterComponent ?? false)
  if (initializeFiltering.current && uiConfig?.props?.items && digitalTwin?.model?.nodes) {
    const tagsPerItem: string[][] = []

    uiConfig?.props?.items?.forEach((item) => {
      const dataIdComponents = item?.data_id?.split('.')
      const unitName = dataIdComponents[dataIdComponents.length - 1]
      const digitalTwinTags = getDigitalTwinTagsFromDataId(unitName, allTagsDict) ?? []

      tagsPerItem.push(item.tags ?? digitalTwinTags)
    })
    filterStore.tagsPerUiconfig.push(...tagsPerItem)

    if (uiConfigProps.include_items_in_grouping && !filterSnap.groupingPerRoute[route]) {
      setGrouping('')
    }

    initializeFiltering.current = false
  }

  const itemsWithTags = useMemo(() => {
    if (applyFilterComponent) {
      const itemsMergedWithTags = getItemsWithTags(uiConfigProps.items ?? [], allTagsDict)
      const itemsFromVariableCalcs = getItemsFromVariableCalcs(
        uiConfig?.dataset_instructions ?? [],
        itemsMergedWithTags,
        allTagsDict
      ) //creates items of variables in calcs to be used in filtering of dataset_instructions
      return { itemWithTags: itemsMergedWithTags, fakeItemsFromVariableCalcs: itemsFromVariableCalcs }
    }

    return { itemWithTags: uiConfigProps.items ?? [], fakeItemsFromVariableCalcs: [] }
  }, [allTagsDict, applyFilterComponent, uiConfig?.dataset_instructions, uiConfigProps.items])

  //only has values if elplan_info_id is not 0 aka it has an elplan module
  const itemsWithWildCards: UiConfigPropsItem[] | undefined = useMemo(() => {
    if (uiConfig?.props.items && elplanSnap.elplan_info_id !== 0 && route.includes('electricity')) {
      //TODO: can we refactor and use dynamicItems or wildCardItems instead? since they work very different from items
      return parseWildcardItems(uiConfig?.props.items as UiConfigPropsItem[])
    }
    return undefined
  }, [elplanSnap.elplan_info_id, route, uiConfig?.props.items])

  const { filteredDatasetInstructions, onlyReturnIds } = useMemo(() => {
    let filteredDatasetInstructions: DatasetInstruction[]
    if (applyFilterComponent) {
      if (filterSnap.currentActiveTagsPerRoute[route] && filterSnap.allTagsPerRoute[route]) {
        filteredDatasetInstructions = filterDatasetInstructionsNotShown(
          uiConfig?.dataset_instructions ?? [],
          itemsWithTags.itemWithTags.concat(itemsWithTags.fakeItemsFromVariableCalcs),
          filterSnap.currentActiveTagsPerRoute[route],
          filterSnap.allTagsPerRoute[route],
          allTagsDict
        )
      } else {
        filteredDatasetInstructions = []
      }
    } else {
      filteredDatasetInstructions = uiConfig?.dataset_instructions ?? []
    }
    const onlyReturnIds = getReturnIdsFromDatasetsInstruction(filteredDatasetInstructions)

    return {
      filteredDatasetInstructions,
      onlyReturnIds,
    }
  }, [
    applyFilterComponent,
    filterSnap.currentActiveTagsPerRoute,
    filterSnap.allTagsPerRoute,
    route,
    uiConfig?.dataset_instructions,
    itemsWithTags.itemWithTags,
    itemsWithTags.fakeItemsFromVariableCalcs,
    allTagsDict,
  ])

  const ignoreZoom = _ignoreZoom || !!uiConfig?.props?.ignore_zoom
  const datasetRes = useDatasets(
    uiConfig?.id || 0,
    uiConfig?.version || 0,
    filteredDatasetInstructions ?? [],
    datasetStartTime,
    datasetEndTime,
    {
      uid,
      datasetRefreshToken,
      ignoreZoom,
      overrideAlias,
      onlyReturnIds,
      datasetCreatedAt,
      datasetCreatedAtOperator,
    }
  )
  let datasets = datasetRes.data || []

  //group datasets if needed
  const { datasets: groupedDatasets, items: groupedItems } = useGrouping(
    itemsWithTags.itemWithTags,
    uiConfigProps,
    datasets,
    uid,
    digitalTwin?.model?.hidden_default_tags ?? [],
    filterSnap.groupingPerRoute[route],
    uiConfig?.props?.grouping_tag,
    uiConfig?.props?.tags_config
  )

  if (groupedDatasets && groupedItems) {
    datasets = groupedDatasets
    uiConfigProps.items = groupedItems
  }

  const showPlaceholder = !datasets?.length && uiConfig?.dataset_instructions?.length && datasetRes.isFetching

  const translations = digitalTwin?.translations

  if (!uiConfig) {
    return <></>
  }
  const Component = getUiConfigComponent(uiConfig.component)
  if (!Component) {
    return null
  }

  const children = (uiConfig.children_ids || [])
    .map((id) => {
      for (const key in uiConfigSnap.uiConfigs) {
        const uiConfig = uiConfigSnap.uiConfigs[key]
        if (uiConfig.id === id) {
          return [id, uiConfig.uid]
        }
      }

      return [undefined, undefined]
    })
    .filter(([id, uid]) => isNumber(uid) && isNumber(id))
    .map(([id, uid]) => (
      <UiConfigComponent
        uid={uid}
        key={`${key_prefix}${uid}`}
        id={id}
        datasetStartTime={datasetStartTime}
        datasetEndTime={datasetEndTime}
        datasetCreatedAt={datasetCreatedAt}
        datasetCreatedAtOperator={datasetCreatedAtOperator}
        ignoreZoom={ignoreZoom}
        overrideAlias={overrideAlias}
        datasetRefreshToken={datasetRefreshToken}
        onDatasets={(uid, datasets) => {
          if (onDatasets) {
            onDatasets(uid, datasets)
          }
        }}
      />
    ))

  const debugMenu = (
    <UiConfigDebugMenu
      uid={uid}
      id={uiConfigId || uiConfig?.id}
      datasets={datasets}
      datasetStartTime={datasetStartTime}
      datasetEndTime={datasetEndTime}
      datasetCreatedAt={datasetCreatedAt}
      datasetCreatedAtOperator={datasetCreatedAtOperator}
      overrideAlias={overrideAlias}
    />
  )

  if (onDatasets) {
    onDatasets(uid, datasets)
  }

  if (noWrappingDiv) {
    if (showPlaceholder) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
          <LoadingPlaceholderContainer height={getComponentHeight(uiConfig.component)} />
        </ErrorBoundary>
      )
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
        <Component
          component={uiConfig.component}
          key={`${key_prefix}${uid}`}
          uid={uid}
          systemId={systemId}
          {...uiConfigProps}
          items={itemsWithWildCards ?? uiConfigProps.items}
          ignoreZoom={ignoreZoom}
          datasets={datasets}
          datasetStartTime={datasetStartTime}
          datasetEndTime={datasetEndTime}
          datasetCreatedAt={datasetCreatedAt}
          datasetCreatedAtOperator={datasetCreatedAtOperator}
          overrideAlias={overrideAlias}
          datasetRefreshToken={datasetRefreshToken}
          translations={translations}
        >
          {children}
        </Component>
        {debugMenu}
      </ErrorBoundary>
    )
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
      <div
        className={styles.UiConfigComponent + (tourId !== undefined ? ` ` + tourId : ``)}
        data-id={uiConfig.id}
        data-version={uiConfig.version}
        data-uid={uiConfig.uid}
      >
        {uiConfig.mode === `beta` && <div className={styles.UiConfigComponent_Beta}>Beta</div>}
        {showPlaceholder ? (
          <LoadingPlaceholderContainer height={getComponentHeight(uiConfig.component)} />
        ) : (
          <>
            <Component
              component={uiConfig.component}
              key={`${key_prefix}${uid}`}
              uid={uid}
              systemId={systemId}
              ignoreZoom={ignoreZoom}
              {...uiConfigProps}
              items={itemsWithWildCards ?? uiConfigProps.items}
              datasets={datasets}
              datasetStartTime={datasetStartTime}
              datasetEndTime={datasetEndTime}
              datasetCreatedAt={datasetCreatedAt}
              datasetCreatedAtOperator={datasetCreatedAtOperator}
              overrideAlias={overrideAlias}
              datasetRefreshToken={datasetRefreshToken}
              translations={translations}
            >
              {children}
            </Component>
          </>
        )}
        {debugMenu}
      </div>
    </ErrorBoundary>
  )
}
