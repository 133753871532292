import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { DigitalTwinSettingCreationRequest, TimeSeriesSettingValue } from 'api/digitalTwin/digitalTwin.api'
import { postStat } from 'api/stats/stats.api'
import { TimeSeriesDataTableColumnDef } from 'ui/components/TimeSeriesDataTable/types'
import DatasetTable from 'ui/uiConfig/components/DatasetTable/DatasetTable'

import { useTranslation } from 'react-i18next'

import { getPrettyName, getPropertyPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import { CalculateTimeSeriesSettingsForm, ParseResult } from './CalculateTimeSeriesSettingsForm'
import createEditableColumnFactory from './editableColumnFactory'
import { TimeSeriesSettingTableProps } from './types'
import { useCustomCheckboxColumn } from './useCustomCheckboxColumn'

// Constants for scrolling when dragging to copy table values.
const SCROLL_INTERVAL_MILLISECONDS = 30
const SCROLL_AMOUNT_EACH_STEP = 4
const DISTANCE_FROM_EDGE_TO_START_SCROLLING = 60

function getValuesArrayFromSettingValue(
  times: ISODateTime[],
  value?: number | null | { [time: ISODateTime]: { id?: number; value: number | null } }
): { id?: number; value: number | null }[] {
  return times.map((time) => (value && typeof value === 'object' && time in value ? value[time] : { value: 0 }))
}

export function getSettingData(settingData: DigitalTwinSettingCreationRequest[], index: number): DigitalTwinSettingCreationRequest | undefined {
  const setting = settingData.at(index)
  if (setting) {
    if (setting.isTimeSeries && typeof setting.value === 'object') {
      return setting
    }
  }

  return undefined
}

export function getTableKey(values: { id?: number; value: number | null }[]): string {
  return `${values.reduce((v, s) => `(${s.id},${s.value}),${v}`, '')}`
}

export default function TimeSeriesSettingTable({
  chartItems,
  datasets,
  dialogContentRef,
  invalidValuesIndex,
  setSettingData,
  settingsData,
  digitalTwinTranslations,
  times,
  tableKey,
  setTableKey,
  enableComputableSettings,
  disabled,
}: TimeSeriesSettingTableProps): ReactElement {
  const [mouseDownValue, setMouseDownValue] = useState<{ rowIndex: number; value: number } | null>(null)
  const [mouseOverIndex, setMouseOverIndex] = useState<number | null>(null)
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null)
  const [focusedCell, setFocusedCell] = useState<{row: number, column: string}>({row: 0, column: ''})

  const { t } = useTranslation()

  const tableContainerRef = useRef<HTMLDivElement>(null)

  // This is necessary to get an updated value inside the interval function, where we update the scroll amount.
  const scrollDirectionRef = useRef<'none' | 'up' | 'down'>('none')

  const updateTimeSeriesValues = useCallback(
    (newSettingValues: TimeSeriesSettingValue, index: number): void => {
      // You can only paste excel values into a time series setting.
      const settingData = getSettingData(settingsData, index)
      if (settingData === undefined) {
        return
      }

      const newSettingValue = {
        ...settingData.value as TimeSeriesSettingValue,
        ...newSettingValues,
      }
      const newSettingData = {
        ...settingData,
        value: newSettingValue,
      }

      // Update table key to force rerender.
      const newValuesArray = getValuesArrayFromSettingValue(times, newSettingValue)
      const newTableKey = getTableKey(newValuesArray)
      setTableKey(newTableKey)
      setSettingData(newSettingData)
    },
    [setSettingData, setTableKey, settingsData, times]
  )

  const copyDatasetToSettingValues = useCallback(
    (dataset: Dataset, index: number) => {
      const settingData = getSettingData(settingsData, index)
      if (settingData === undefined) {
        return
      }

      const newSettingValues = {...settingData.value as TimeSeriesSettingValue}
      dataset.times.forEach((datasetTime, index) => {
        const time = datasetTime as ISODateTime
        const settingValue = settingData.value as TimeSeriesSettingValue
        const datasetValue = dataset.values[index]
        newSettingValues[time] = {
          ...settingValue[time],
          value: datasetValue !== null ? datasetValue : settingValue[time].value,
        }
      })

      updateTimeSeriesValues(newSettingValues, index)
    },
    [settingsData, updateTimeSeriesValues]
  )
  
  useEffect(() => {
    const handleMouseUp = (): void => {
      // Clear value being dragged.
      setMouseDownValue(null)
      setMouseOverIndex(null)

      // Stop scrolling.
      scrollDirectionRef.current = 'none'
      if (intervalId !== null) {
        clearInterval(intervalId)
        setIntervalId(null)
      }
    }

    window.addEventListener('mouseup', handleMouseUp)

    // Clear current listener before registering a new one, or when component unmounts.
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [intervalId])

  const setScrollDirection = useCallback((scrollDirection: 'none' | 'up' | 'down'): void => {
    scrollDirectionRef.current = scrollDirection

    if (scrollDirection !== 'none' && intervalId === null && dialogContentRef) {
      let scrollAmount = 0 // Start at 0, then increase/decrease each interval step.

      // Scroll continuously while the mouse is down.
      const intervalId = setInterval(() => {
        const dialogContent = dialogContentRef?.current
        const tableContainerRect = tableContainerRef.current?.getBoundingClientRect()
        const dialogContentRect = dialogContent?.getBoundingClientRect()

        if (!dialogContent || !tableContainerRect || !dialogContentRect) {
          return
        }

        // Don't continue scrolling past the table in either direction.
        const scrolledToBottom =
          scrollDirectionRef.current === 'down' && tableContainerRect.bottom < dialogContentRect.bottom
        const scrolledToTop = scrollDirectionRef.current === 'up' && tableContainerRect.top > dialogContentRect.top
        if (scrolledToBottom || scrolledToTop) {
          clearInterval(intervalId)
          setIntervalId(null)
          return
        }

        // Scroll down a little each inteval step.
        dialogContent.scrollTo(0, dialogContent.scrollTop + scrollAmount)
        if (scrollDirectionRef.current === 'down') {
          scrollAmount += SCROLL_AMOUNT_EACH_STEP
        } else if (scrollDirectionRef.current === 'up') {
          scrollAmount -= SCROLL_AMOUNT_EACH_STEP
        }
      }, SCROLL_INTERVAL_MILLISECONDS)
      setIntervalId(intervalId)
    } else if (intervalId !== null) {
      // Stop scrolling when the scrollDirection or dialogContent is no longer set.
      clearInterval(intervalId)
      setIntervalId(null)
    }
  },[dialogContentRef, intervalId ])

  const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement>): void => {
    if (mouseDownValue !== null) {
      // If mouse was moved to top or bottom of the table while some of the table is off screen, start scrolling.
      checkScrollDirection(event.nativeEvent)
    }
  }

  const checkScrollDirection = useCallback((event: MouseEvent): void => {
    const tableContainerRect = tableContainerRef.current?.getBoundingClientRect()
    const dialogContentRect = dialogContentRef?.current?.getBoundingClientRect()

    if (!tableContainerRect || !dialogContentRect) {
      return
    }

    const { bottom: tableBottom, top: tableTop } = tableContainerRect
    const { bottom: modalBottom, top: modalTop } = dialogContentRect

    const mouseCloseToTop = event.clientY < modalTop + DISTANCE_FROM_EDGE_TO_START_SCROLLING
    const mouseCloseToBottom = event.clientY > modalBottom - DISTANCE_FROM_EDGE_TO_START_SCROLLING

    const tableOutsideModalTop = tableTop < modalTop
    const tableOutsideModalBottom = tableBottom > modalBottom

    const shouldScrollUp = tableOutsideModalTop && mouseCloseToTop
    const shouldScrollDown = tableOutsideModalBottom && mouseCloseToBottom

    const currentScrollDirection = scrollDirectionRef.current

    if (shouldScrollUp && currentScrollDirection !== 'up') {
      setScrollDirection('up')
    } else if (shouldScrollDown && currentScrollDirection !== 'down') {
      setScrollDirection('down')
    } else if (!shouldScrollUp && !shouldScrollDown && currentScrollDirection !== 'none') {
      setScrollDirection('none')
    }
  },[dialogContentRef, setScrollDirection])

  const handleMouseEvent = useCallback((event: React.MouseEvent<Element>, newMouseDownValue: { rowIndex: number; value: number } | null): void => {
    if (event.button === 0) {
      setMouseDownValue(newMouseDownValue)
      if (newMouseDownValue !== null) {
        // Mouse button might have been pressed at the top or bottom, so check if we should start scrolling immediately.
        checkScrollDirection(event.nativeEvent)
      } else {
        // Mouse up, stop scrolling.
        setScrollDirection('none')
      }
    }
  },[checkScrollDirection, setScrollDirection])

  const columnOverrides = useMemo(() => {
    const overrides: { [key: string]: { enableEditing: boolean } } = {}

    chartItems.forEach((item) => {
      overrides[item.data_id] = {
        enableEditing: false,
      }
    })

    return overrides
  }, [chartItems])

  const cellRefs = useRef<{ [key: string]: React.RefObject<HTMLInputElement> }>({})
  useEffect(() => {
    const key = `${focusedCell.column}-${focusedCell.row}`
    cellRefs.current[key]?.current?.focus()
  }, [focusedCell])

  const editableColumnFactory = useMemo(() => createEditableColumnFactory({
    datasets,
    chartItems,
    invalidValuesIndex,
    mouseDownValue,
    mouseOverIndex,
    focusedCell,
    times,
    settingsData,
    t,
    copyDatasetToSettingValues,
    handleMouseEvent,
    setFocusedCell,
    setMouseOverIndex,
    setMouseDownValue,
    updateTimeSeriesValues,
    setSettingData,
    cellRefs,
  }), [
    chartItems, copyDatasetToSettingValues, datasets, handleMouseEvent,
    invalidValuesIndex, mouseDownValue, mouseOverIndex, setSettingData,
    settingsData, t, times, updateTimeSeriesValues, focusedCell,
  ])
  
  const createEditableColumnOverrides = useCallback((title: string, returnId: string, unit?: string, index = 0): TimeSeriesDataTableColumnDef => {
    return {
      Header: editableColumnFactory.Header({ title, returnId, unit, index }),
      muiTableBodyCellProps: editableColumnFactory.muiTableBodyCellProps({ index }),
      muiTableBodyCellEditTextFieldProps: editableColumnFactory.muiTableBodyCellEditTextFieldProps({ index, returnId }),
    }
  }, [editableColumnFactory])

  const editableColumnsOverrides = useMemo(() => {
    const overrides: { [key: string]: TimeSeriesDataTableColumnDef } = {}
    settingsData?.forEach((settingData, index) => {
      const prettyName = getPropertyPrettyName(settingData.name, settingData.attribute, digitalTwinTranslations)
      overrides[settingData.attribute] = createEditableColumnOverrides(prettyName, settingData.attribute, settingData.unit, index)
    })
    return overrides
  }, [digitalTwinTranslations, createEditableColumnOverrides, settingsData])
  
  const [
    customColumns, 
    settingsDataIndicesToComputed, 
    resetSettingsDataIndicesToComputed,
  ] = useCustomCheckboxColumn({ datasets, enableComputableSettings })
  
  const onCalculateClick = useCallback((parseResult: ParseResult) => {
    const settingData = getSettingData(settingsData, 0)
    if (typeof parseResult.selectedDataset === 'undefined' || typeof settingData?.value !== 'object') {
      return
    }
    
    const newSettingValues = { ...settingData.value as TimeSeriesSettingValue }
    settingsDataIndicesToComputed
      .map((index) => {
        const value = parseResult.selectedDataset.values[index]
          ? parseResult.percentageInput
            ? parseResult.selectedDataset.values[index] * parseResult.percentageInput
            : parseResult.mwInput
              ? parseResult.selectedDataset.values[index] + parseResult.mwInput
              : null
          : null
        return {
          time: parseResult.selectedDataset.times[index] as ISODateTime,
          value: value,
        }
      })
      .forEach((computedSetting) => {
        newSettingValues[computedSetting.time] = { 
          id: newSettingValues[computedSetting.time].id, 
          value: computedSetting.value,
        }
      })
    
    postStat(
      'timeseries-setting',
      `calculate-new-values-with-${parseResult.percentageInput 
        ? `${parseResult.percentageInput}%` 
        : `${parseResult.mwInput}MW`}`
    ) //DATA LOG FOR PILOT 1,5
    updateTimeSeriesValues(newSettingValues, 0)
    resetSettingsDataIndicesToComputed()
  }, [
    resetSettingsDataIndicesToComputed, 
    settingsData,
    settingsDataIndicesToComputed, 
    updateTimeSeriesValues,
  ])

  return (
    <>
      {enableComputableSettings && (
        <CalculateTimeSeriesSettingsForm 
          datasets={datasets}
          chartItems={chartItems}
          disableCalculateButton={settingsDataIndicesToComputed.length === 0}
          onCalculateClick={onCalculateClick}/>
      )}
      <DatasetTable
        tableContainerRef={tableContainerRef}
        items={chartItems.map((item) => {
          return {
            title: item.title,
            data_id: item.data_id,
            unit: item.unit ?? '',
            decimals: item.decimals ?? 2,
            table_order: item.order ?? 0,
          }
        })}
        customColumns={customColumns}
        datasets={datasets}
        columnOverrides={{
          ...columnOverrides,
          ...editableColumnsOverrides,
          modified_setting_value: createEditableColumnOverrides(
            `${getPrettyName(settingsData[0].name, digitalTwinTranslations)}: ${getPrettyName(settingsData[0].attribute, digitalTwinTranslations)}`,
            'modified_setting_value',
            settingsData[0].unit
          ),
        }}
        tableKey={tableKey}
        children={undefined}
        title=""
        do_not_show_zero_or_null_values_in_table={false}
        do_not_show_download_button
        do_not_show_sum_or_average_row
        do_not_show_top_toolbar
        do_not_show_min_max_row
        show_only_value_in_future={false}
        show_initial_page_with_current_optimization_hour={false}
        enableEditing={!disabled}
        enableColumnActions={false}
        onMouseMove={onMouseMove}
        disableCustomSorting
        hideSingleValueDatasets={false}
        onPaginationChange={resetSettingsDataIndicesToComputed}
      />
    </>
  )
}