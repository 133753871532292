import React, { ReactElement, useState } from 'react'

import { HeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { useSettingsModalTitle } from 'hooks/utilsHooks/utilsHooks'
import ObjectPropertyFormSelector from 'ui/components/ObjectPropertyFormSelector/ObjectPropertyFormSelector'
import SettingsModal from 'ui/components/SettingsModal/SettingsModal'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import ManageButton from '../ManageButton/ManageButton'
import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getDateTimePickerRangesFromPermissions } from 'helpers/settingsModal.helper/settingsModal.helper'

type ManageButtonsProps = {
  supplier: HeatSupplier
  currentDate: Moment
}
export default function ManageButtons({ supplier, currentDate }: ManageButtonsProps): ReactElement {
  const { t } = useTranslation()

  const planSettingsPermissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_plan_settings`),
    canEditHistoric: useHasPermission(`change_historic_plan_settings`),
  }
  const modelSettingsPermissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_model_settings`),
    canEditHistoric: useHasPermission(`change_historic_model_settings`),
  }

  const [prop, setProp] = useState<ObjectProperty | undefined>(undefined)
  const period = { startTime: currentDate, endTime: moment(currentDate).endOf(`day`).minute(0) }
  const [startEndTimeRange, setStartEndTimeRange] = useState(getDateTimePickerRangesFromPermissions(false))
  const [currentPropPermissions, setCurrentPropPermissions] = useState(planSettingsPermissions)

  const datetimeStartTime = moment(currentDate).startOf(`day`)
  const datetimeEndTime = moment(currentDate).endOf(`day`).minute(0).second(0)
  const electricityPriceSetAt = Datetime.toISOString(moment(datetimeStartTime).subtract(1, `day`).hour(13).minute(45).second(0))

  function handleOpen(currentPermissions: SettingsPermissions, name?: string): void {
    setStartEndTimeRange(getDateTimePickerRangesFromPermissions(currentPermissions.canEditHistoric))
    setCurrentPropPermissions(currentPermissions)

    const newProp = supplier.props.find((prop) => {
      return prop.name === name
    })
    setProp(newProp)
  }
  const title = useSettingsModalTitle({ objectProperty: prop })
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" style={{ width: `100%`, marginTop: 30 }}>
        <ManageButton
          title={t(`Decline delivery`)}
          infoTooltip={t(`Setting for declining delivery at specific hours for the specific supplier`)}
          onClick={() => handleOpen(planSettingsPermissions, `availability`)}
        />
        <ManageButton
          title={t(`Manage requirement on delivery temperature`)}
          infoTooltip={t(`Setting for adjusting the temperature demand for the specific supplier`)}
          onClick={() => handleOpen(modelSettingsPermissions, `temperature_demand`)}
        />
        <ManageButton
          title={t(`Adjust hours to decline`)}
          infoTooltip={t(
            `Setting for editing the agreement on how many hours you are able to deny delivery from the specific supplier.`
          )}
          onClick={() => handleOpen(modelSettingsPermissions, `available_hours`)}
        />
        <Grid item xs={12} md={10}>
          <UiConfig
            type="samenergi_excel_button"
            datasetCreatedAt={electricityPriceSetAt}
            datasetCreatedAtOperator='lte'
            datasetStartTime={datetimeStartTime}
            datasetEndTime={datetimeEndTime}
          />
        </Grid>
      </Grid>
      <SettingsModal
        closeModal={() => setProp(undefined)}
        open={prop !== undefined}
        title={title}
        form={
          <ObjectPropertyFormSelector
            objectProperty={prop}
            ObjectPropertySettingFormProps={{
              defaultValues: { start_time: period.startTime, end_time: period.endTime },
              showCreateButton: true,
              showPlannedUnplanned: (prop) => prop?.name === `availability`,
              showHistory: true,
              startEndTimeRange: startEndTimeRange,
              permissions: currentPropPermissions,
            }}
            AvailableHoursFormProps={{
              supplier: supplier,
              onFormFinished: () => {
                handleOpen(planSettingsPermissions, undefined)
              },
              hasEditPermission: modelSettingsPermissions.canEdit,
            }}
          />
        }
      />
    </>
  )
}
