import React, { ReactElement, useEffect } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'

import { useSnapshot } from 'valtio'

import UiConfigColorInput from '../UiConfigColorInput/UiConfigColorInput'
import { clone } from 'helpers/global.helper/global.helper'


type UiConfigTagsConfTableProps = {
  tags_config: Record<string, TagsConfig>
  uid: number
}

export default function UiConfigTagsConfigTable({ tags_config, uid }: UiConfigTagsConfTableProps): ReactElement {
  const [previewTags, setPreviewTags] = React.useState(tags_config)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  useEffect(() => {
    const tagsConfig = uiConfigSnap.previewCollection[uid]?.props?.tags_config

    if (tagsConfig) {
      setPreviewTags(tagsConfig)
    }
  }, [uid, uiConfigSnap.previewCollection])

  function preview({ tag, value, key }: { tag: string; value: string; key?: string }): void {
    const newTags: Record<string, TagsConfig> = clone(previewTags)

    if (key) {
      newTags[tag][key] = value
    } else {
      newTags[value] = newTags[tag]
      delete newTags[tag]
    }

    setPreviewTags(newTags)

    uiConfigStore.previewCollection = {
      ...uiConfigSnap.previewCollection,
      [uid]: {
        ...(uiConfigSnap.previewCollection[uid] || {}),
        props: {
          ...(uiConfigSnap.previewCollection[uid]?.props || {}),
          tags_config: newTags,
        },
      },
    }
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Tag</th>
          <th>Color</th>
        </tr>
      </thead>

      <tbody>
        {Object.entries(tags_config).map(([tag, tagConfig]) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <tr key={tag}>
            <td>
              <input
                aria-label="tag"
                defaultValue={tag}
                onBlur={(ev) => {
                  preview({ tag, value: ev.target.value})
                }}
              />
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <UiConfigColorInput
                onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                  preview({ tag, value: ev.target.value, key: `color` })
                }}
                color={tagConfig.color}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
