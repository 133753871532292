import Datetime from 'utils/datetime/datetime'

export function initCurrentDataPointIndex(
  data: { time: string }[],
  showInitialPageWithCurrentOptimizationHour: boolean,
  showInitialPageWithCurrentDay: boolean,
  currentOptimizationHour: ISODateTime = Datetime.getCurrentOptimizationHour()
): number {
  if (showInitialPageWithCurrentOptimizationHour) {
    return findIndexOfTime(data, currentOptimizationHour)
  } else if (showInitialPageWithCurrentDay) {
    return findIndexOfTime(data, Datetime.getTodayDate().startTime)
  } else if (data) {
    return 0
  } else {
    return -1
  }
}

function findIndexOfTime(data: { time: string }[], time: ISODateTime): number {
  const formattedTime = Datetime.toLocalTime(time, 'longDayTextWithoutYear')
  return data.findIndex((row) => row.time === formattedTime)
}
