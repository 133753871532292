import React, { ReactElement } from 'react'

import { Card } from 'ui/atoms'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import UiConfig from 'ui/uiConfig/UiConfig'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'


export default function ReferenceGuidesView(): ReactElement {
  const { t } = useTranslation()

  return (
    <View
      header={
        <ModuleHeader infoHeader={t('Reference guides')} />
      }
    >
      <Grid item xs={12}>
        <Card collapsible margin
        >
          <UiConfig type="reference_guides"/>
        </Card>
      </Grid>
    </View>
  )
}
