import React, { ReactElement, ReactNode } from 'react'

import { dynamicClassName } from 'styles/helper'
import { Card, WarningTriangle } from 'ui/atoms'
import {
  registerUiConfigComponent,
  registerAppendPropsWithDatasets,
  registerDefaultProps,
  registerComponentHeight,
} from 'ui/uiConfig/factory'

import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { applyAggregateFromUiConfig, getReturnIdsFromDatasetInstruction } from 'helpers/dataset.helper/dataset.helper'
import { getTypeObject } from 'helpers/global.helper/global.helper'

import styles from './FollowUpProdKpi.module.less'

type Kpi = {
  title: string
  data_id: string
  color?: string
  unit?: string
  decimals?: number
  tooltip?: string
  data_type?: string
  no_data_icon?: string
  stack_group?: number
  bar?: boolean
}

type FollowUpProdKpiProps = {
  uid: UiConfigUid
  items?: Kpi[]
  datasets: Dataset[]
  children: ReactNode
  ignoreZoom: boolean
}

const defaultProps: Omit<FollowUpProdKpiProps, `uid` | `datasets` | `children` | `ignoreZoom`> = {
  items: [],
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: FollowUpProdKpiProps
): FollowUpProdKpiProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId ?? ``,
    data_id: returnId,
    color: `$color-1`,
    decimals: 2,
    tooltip: ``,
    unit: ``,
    data_type: ``,
    bar: false,
    stack_group: 0,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent('follow_up_prod_kpi', FollowUpProdKpi, { isSingletonComponent: true })
registerAppendPropsWithDatasets('follow_up_prod_kpi', appendPropsWithDataset)
registerDefaultProps('follow_up_prod_kpi', defaultProps)
registerComponentHeight('follow_up_prod_kpi', 91)

registerUiConfigComponent('kpi_value_card', FollowUpProdKpi)
registerAppendPropsWithDatasets('kpi_value_card', appendPropsWithDataset)
registerDefaultProps('kpi_value_card', defaultProps)
registerComponentHeight('kpi_value_card', 91)

export default function FollowUpProdKpi({ uid, items, datasets }: FollowUpProdKpiProps): ReactElement {
  const { t } = useTranslation()

  if (!items?.length) {
    return <></>
  }

  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, { skipInvalidIndices: true })

  const datasetsByReturnId: Record<string, Dataset> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: dataset,
    }),
    {}
  )

  const stackGroupMaxValue = items.reduce(
    (acc, item) => {
      const stackGroup = item.stack_group || 0
      const itemMaxValue = Math.max(...(datasetsByReturnId[item.data_id]?.values || []).map((v) => v || 0))

      if (itemMaxValue > (acc[stackGroup] || 0)) {
        acc[stackGroup] = itemMaxValue
      }

      return acc
    },
    {} as Record<number, number>
  )

  function getBarHeight(item: Kpi): string {
    if (!item.bar) {
      return `100%`
    }

    const stackGroup = item.stack_group || 0
    const maxValue = stackGroupMaxValue[stackGroup]
    const value = datasetsByReturnId[item.data_id]?.values?.[0]

    if (!value) {
      return `0px`
    }

    const height = (value / maxValue) * 100
    return `${height}%`
  }

  return (
    <div className={styles.FollowUpProdKpi}>
      {items.map((item, index) => {
        const hasError = datasetsByReturnId[item.data_id]?.status === `error`
        const typeObj = getTypeObject(
          datasetsByReturnId[item.data_id]?.values?.[0],
          item.data_type,
          item.unit,
          item.decimals
        )
        const value = typeObj.value
        const unit = typeObj.unit
        const icon = item.no_data_icon || ''

        const itemComponent = (
          <div className={styles.FollowUpProdKpi_Item} key={index}>
            <Card key={index}>
              {item.color && (
                <div
                  className={styles.FollowUpProdKpi_Item_Color}
                  style={{ backgroundColor: item.color, height: getBarHeight(item) }}
                />
              )}

              <div
                className={dynamicClassName({
                  [styles.FollowUpProdKpi_Item_Value]: true,
                  [styles.FollowUpProdKpi_Item_Value__blue]: icon && value === '-',
                })}
              >
                <WarningTriangle visible={hasError} tooltip={t(`Data missing`)} />
                {icon && value === '-' ? <i className={icon} /> : value}
              </div>
              <div className={styles.FollowUpProdKpi_Item_Unit}>{unit}</div>
              <div className={styles.FollowUpProdKpi_Item_Title}>{item.title}</div>
            </Card>
          </div>
        )

        if (item.tooltip) {
          return (
            <Tooltip title={item.tooltip} arrow key={`tooltip_${index}`}>
              <span>
                {itemComponent}
              </span>
            </Tooltip>
          )
        }

        return itemComponent
      })}
    </div>
  )
}
