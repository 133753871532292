import React, { ReactElement, useState } from 'react'

import { Thread } from 'api/bulletinBoard/bulletinBoard.api'
import { useSystems } from 'api/systems/systems.api'

import { Grid } from '@mui/material'

import BulletinBoardTextField from '../TextField/TextField'

import SystemsPicker from './components/SystemsPicker/SystemsPicker'

type BulletinBoardNewThreadProps = {
  threads: Thread[]
  onFilterClick?: () => void
  filterOnUserPosts?: boolean
}

function BulletinBoardNewThread({
  threads,
  onFilterClick,
  filterOnUserPosts,
}: BulletinBoardNewThreadProps): ReactElement {
  const [sendList, setSendList] = useState<Array<number>>([])
  const { data: systems = [] } = useSystems()
  return (
    <>
      <SystemsPicker
        currentSystems={Object.values(systems).sort((a, b) => a.display_name.localeCompare(b.display_name))}
        sendList={sendList}
        setSendList={setSendList}
        onFilterClick={onFilterClick}
        filterOnUserPosts={filterOnUserPosts}
      />
      <Grid container direction="row">
        <BulletinBoardTextField ids={sendList} type="thread" content={threads ? threads.length : 0} />
      </Grid>
    </>
  )
}

export default BulletinBoardNewThread
