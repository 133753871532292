/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react'

import { Setting } from 'api/settings/settings.api'
import {
  useShiftHandoverMutation,
  Comment,
  ShiftHandoverFormData,
} from 'api/shiftHandovers/shiftHandovers.api'
import alertStore from 'store/alert/alert'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Serie } from 'ui/components/BaseChart/types'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { DialogContent } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { roundToNearestHour } from 'helpers/dateTime.helper/dateTime.helper'

import HandoverActionButtons from './components/HandoverActionButtons/HandoverActionButtons'
import HandoverComments from './components/HandoverComments/HandoverComments'
import HandoverEvaluation from './components/HandoverEvaluation/HandoverEvaluation'
import HandoverSelectors from './components/HandoverSelectors/HandoverSelectors'
import HandoverSettingsTable from './components/HandoverSettingsTable/HandoverSettingsTable'

type FormProps = {
  defaultValues?: ShiftHandoverFormData
  handleModalClose: () => void
  handoverSubmitted?: boolean
  settings: Setting[]
  series?: Record<number, Serie[]> | null
}

export default function Form({ defaultValues, handleModalClose, handoverSubmitted, settings }: FormProps): ReactElement {
  const { t } = useTranslation()

  const useFormProps  : {
    mode: `onChange`,
    defaultValues?: ShiftHandoverFormData,
  } = {
    mode: `onChange`,
    defaultValues: defaultValues
      ? { evaluation_status: `undefined`, ...defaultValues }
      : undefined,
  }

  const methods = useForm(useFormProps)

  const { register, handleSubmit, reset } = methods

  const { mutateAsync, isLoading } = useShiftHandoverMutation()
  const { systemId } = useAuth()

  function saveOrSubmitHandover(data: ShiftHandoverFormData, submit: boolean): void {
    const { id, comment_groups, ...rest } = data
    if (typeof systemId !== 'undefined') {
      mutateAsync({
        id,
        data: {
          ...rest,
          system: systemId,
          comments: comment_groups
            .reduce((prevComments: Comment[], currentGroup) => {
              return [...prevComments, ...currentGroup.comments]
            }, [])
            .filter((comment) => comment.content !== ``)
            .map((comment) => ({
              ...comment,
              id: comment.id && comment.id !== `` && !isNaN(Number(comment.id)) ? comment.id : undefined,
            })),
          submit,
        },
      }).then(
        () => {
          handleModalClose()
          if (submit) {
            alertStore.success(t(`Your shift handover has been reported!`))
          } else {
            alertStore.info(t(`Your shift handover has been saved as a draft`))
          }
        },
        (errorMessage) => {
          alertStore.error(`${errorMessage}`)
        }
      )
    }
  }

  const onSubmitSubmit = handleSubmit((data) => {
    saveOrSubmitHandover(data, true)
  })

  const onSubmitSave = handleSubmit((data) => {
    saveOrSubmitHandover(data, false)
  })

  const handoverTime = Datetime.toISOString(moment(defaultValues?.handover_time))
  const startTime = Datetime.toISOString(
    defaultValues?.start_time ? roundToNearestHour(defaultValues?.start_time) : moment()
  )
  const endTime = Datetime.toISOString(defaultValues?.end_time ? roundToNearestHour(defaultValues?.end_time) : moment())

  return (
    <>
      <DialogContent>
        <FormProvider {...methods}>
          <form>
            <input type="hidden" {...register(`id`)} />
            <input type="hidden" {...register(`start_time`, { required: true })} />
            <input type="hidden" {...register(`end_time`, { required: true })} />
            <input type="hidden" {...register(`handover_time`, { required: true })} />
            <HandoverSelectors disabled={handoverSubmitted} />
            <UiConfig
              type="shift_handover_chart"
              overrideAlias={{
                meas_start_time: startTime,
                shift_handover_time: handoverTime,
                plan_end_time: endTime,
              }}
            />
            <HandoverSettingsTable settings={settings} />
            <HandoverComments settings={settings} disabled={handoverSubmitted} />
            <HandoverEvaluation disabled={handoverSubmitted} />
          </form>
        </FormProvider>
      </DialogContent>
      <HandoverActionButtons
        handleClose={handleModalClose}
        onSubmit={onSubmitSubmit}
        onSave={onSubmitSave}
        resetForm={() => reset(undefined)}
        isSubmitting={isLoading}
        disabled={handoverSubmitted}
      />
    </>
  )
}
