import React, { ReactElement, useDeferredValue, useState } from 'react'

import { DigitalTwinTranslations } from 'api/digitalTwin/digitalTwin.api'
import { chartStore, hideReturnIdInGroup, showReturnIdInGroup } from 'store/chart/chart'
import { dynamicClassName } from 'styles/helper'
import { Button } from 'ui/atoms'
import { MODIFY_SETTING_COLUMN_RETURN_ID } from 'ui/components/DigitalTwinSettingForm/constants'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './ChartLegend.module.less'

type ChartLegendProps = {
  groupId: string,
  digitalTwinTranslations?: DigitalTwinTranslations
  settingInfo?: { name: string; attribute: string; }
}

export default function ChartLegend({ groupId, digitalTwinTranslations, settingInfo }: ChartLegendProps ): ReactElement {
  const { t } = useTranslation()
  const chartSnap = useSnapshot(chartStore)
  const hiddenReturnIds: Set<string> = chartSnap.groupIdHiddenReturnIds[groupId] || new Set()
  const [clickCount, setClickCount] = useState(0)
  const [singleClickTimer, setSingleClickTimer] = useState<NodeJS.Timeout | null>(null)

  const items = useDeferredValue(chartSnap.groupIdItems[groupId] || [])

  return (
    <div className={styles.ChartLegend}>
      {items.length > 0 && <Button
        secondary
        marginTop
        marginRight
        marginBottom
        hideBorder
        onClick={() => {
          if (hiddenReturnIds.size === 0) {
            hideReturnIdInGroup({ groupId, returnId: items.map(i => i.data_id) })
          } else {
            showReturnIdInGroup({ groupId, returnId: items.map(i => i.data_id) })
          }
        }}
      >
        {hiddenReturnIds.size === 0 ? t('Hide all') : t('Show all')}
      </Button>}

      {items.map((item, index) => {
        const isHidden = hiddenReturnIds.has(item.data_id)
        const style: Record<string, string | undefined> = {}

        const settingsName = (item.title === MODIFY_SETTING_COLUMN_RETURN_ID && settingInfo)
          ? `${getPrettyName(settingInfo.name, digitalTwinTranslations)}: ${getPrettyName(settingInfo.attribute, digitalTwinTranslations)}`
          : getPrettyName(item.title, digitalTwinTranslations)

        if (item.dashed) {
          style.border = `dashed ${item.color}`
        } else if (!item.fill) {
          style.border = `2px solid ${item.color}`
        } else {
          style.backgroundColor = item.color
        }

        return (
          <div
            key={`${item.data_id}_${index}`}
            className={styles.ChartLegend_Item}
            onClick={() => {
              setClickCount(prevCount => prevCount + 1)
              if (singleClickTimer) {
                clearTimeout(singleClickTimer)
              }

              setSingleClickTimer(setTimeout(() => {
                if (clickCount === 0) {
                  // Single click
                  if (isHidden) {
                    showReturnIdInGroup({ groupId, returnId: item.data_id })
                  } else {
                    hideReturnIdInGroup({ groupId, returnId: item.data_id })
                  }
                } else if (clickCount === 1) {
                  // Double click
                  showReturnIdInGroup({ groupId, returnId: item.data_id })
                  hideReturnIdInGroup({ groupId, returnId: items.filter(i => i.data_id !== item.data_id).map(i => i.data_id) })
                }

                setClickCount(0)
              }, 200)) // 200ms delay
            }}
          >
            <span
              className={dynamicClassName({
                [styles.ChartLegend_Item_Marker]: true,
                [styles.ChartLegend_Item_Marker__hidden]: isHidden,
              })}
              style={style}
            />

            <p
              className={dynamicClassName({
                [styles.ChartLegend_Item_Text]: true,
                [styles.ChartLegend_Item_Text__hidden]: isHidden,
              })}
            >
              {settingsName}
            </p>
          </div>
        )
      })}
    </div>
  )
}