import React, { ReactElement } from 'react'

import { Button } from 'ui/atoms'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'

import { Grid } from '@mui/material'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import { openUrl } from 'helpers/global.helper/global.helper'

import styles from './ReferenceGuides.module.less'

type ReferenceGuide = {
  title: string
  url: string
  content: string
}

type ReferenceGuidesProps = {
  guides: ReferenceGuide[]
}

const defaultProps: Omit<ReferenceGuidesProps, 'datasets' | 'children'> = {
  guides: [
    {
      title: 'Guide 1',
      url: 'https://veryimportantnottoforgethttpsinbeginning.xyz',
      content: 'In this field you can write a short summary about the quick reference guide.',
    },
  ],
}

registerUiConfigComponent('reference_guides', ReferenceGuides, {
  isSingletonComponent: true,
  title: i18n.t('Reference guides'),
})
registerDefaultProps('reference_guides', defaultProps)

export default function ReferenceGuides({ guides }: ReferenceGuidesProps): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <Grid container>
        {guides.map((guide, index) => (
          <Grid key={index} item xs={12} md={6}>
            <div className={styles.ReferenceGuides_Guide}>
              <TitleWithIcon size={'medium'} title={guide.title} />
              <div className={styles.ReferenceGuides_Content}>{guide.content}</div>
              <Button primary onClick={() => openUrl(guide.url)}>
                {t('Open reference guide')}
              </Button>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
