import React, { ReactElement, useRef, useState } from 'react'

import elplanBidStore from 'store/elplan/elplan'
import { Dialog } from 'ui/components/Dialog/Dialog'
import FormRow from 'ui/components/FormRow/FormRow'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import { DatePicker } from 'ui/molecules/pickers/DatePicker/DatePicker'
import Datetime from 'utils/datetime/datetime'

import { DialogContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './CopyBidsModal.module.less'

type CopyBidsModalProps = {
  onSubmit: (date: ISODateTime) => void
  closeModal: () => void
}

export default function CopyBidsModal({closeModal, onSubmit}: CopyBidsModalProps): ReactElement {
  const elplanSnap = useSnapshot(elplanBidStore)
  const { t } = useTranslation()

  const [date, setDate] = useState<ISODateTime | null | undefined>(elplanSnap.date.start_time)
  const isFaultyDate = !date


  const ref = useRef(null)


  return (
    <div className={styles.BidModal} >
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={t('Choose a date to copy bids from')} handleClose={() => closeModal()} />
        <DialogContent><div className={styles.BidModal_TimePickers}>
          <FormRow>
            <Grid item xs={12} md={12} lg={6}>
              <DatePicker
                value={date}
                onChange={(date) => {
                  setDate(date)
                }}
                label={t('Chosen date')}
                showWeekNumbers
                granularity={'day'}
                isRequired
              />
            </Grid>

          </FormRow>
        </div>
        <ModalButtons
          submitLabel={t('Copy bids')}
          disableSubmit={isFaultyDate}
          onSubmit={() => {
            if (date) {
              onSubmit(Datetime.toISOString(date))
            }
          }}
          onCancel={() => closeModal()}
          infoText={t('Copying bids only works for dates that contains that have bids in the same format')}
        />
        </DialogContent>
      </Dialog>
    </div>
  )
}
