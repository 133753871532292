export function capitalize(s?: string): string {
  if (!s) {
    return ''
  }
  return s.charAt(0).toUpperCase() + s.slice(1)
}
import i18n from 'i18next'

export function translateWithSpecificTranslations(keys: string[], translations?: {[key: string]: string}): string[] {
  return keys.map(k => translateKeyWithSpecificTranslations(k, translations))
}

export function translateKeyWithSpecificTranslations(key: string, translations?: {[key: string]: string}, firstLetterUpperCase?: boolean): string {
  let translation = key

  // Use specific translation if one exists.
  if (translations && key in translations) {
    translation = translations[key]
  } else {
    // Try to use 'standard' translations if no specific translation exists.
    // If no translation exists, return the key itself.
    translation = i18n.t(key) ?? key
  }

  if (firstLetterUpperCase) {
    return capitalize(translation)
  } else {
    return translation
  }
}

export function formatNumberString(s: string, allowedDecimalPlaces = 1): string | undefined {
  if (s === '') {
    return s
  }

  const newCharacter = s.at(-1)
  if (
    newCharacter !== '-' &&
    newCharacter !== '.' &&
    newCharacter !== ',' &&
    (newCharacter === undefined || isNaN(parseFloat(newCharacter)))
  ) {
    return undefined // If last character is invalid, do not update the value
  }

  if (s.split(/,|\./).length > 2 || s.split('-').length > 2) {
    return undefined // Do not allow more than one minus sign or more than one dot/comma
  }

  if (newCharacter === '-' && s.length > 1) {
    return undefined // Do not allow minus signs in the middle of the number
  }

  const isDecimalNumber = s.split(/,|\./).length == 2
  if (isDecimalNumber && s.split(/,|\./)[1].length > allowedDecimalPlaces) {
    return undefined
  }

  s = s.replace(',', '.') // Replace comma with dot so we can parse the float

  return s
}
