import React, { ReactElement } from 'react'

import { HeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { TabObject } from 'ui/components/Tabs/RegularTabs'
import Tabs from 'ui/components/Tabs/Tabs'

import { Grid } from '@mui/material'
import { Moment } from 'moment'

import SupplierDetails from './components/SupplierDetails/SupplierDetails'
import SupplierTable from './components/SupplierTable/SupplierTable'

type SupplierViewTabsProps = {
  heatSuppliers: HeatSupplier[]
  currentDate: Moment
  supplier?: HeatSupplier
  handleChange: (event: React.ChangeEvent<unknown>, newValue: number) => void
}

export default function SupplierViewTabs({
  heatSuppliers,
  currentDate,
  supplier,
  handleChange,
}: SupplierViewTabsProps): ReactElement {
  const tabs: TabObject[] = heatSuppliers
    .map((supplier) => {
      return { label: supplier.display_name, id: supplier.id }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  return (
    <Grid container direction="row" spacing={1} style={{ marginTop: 20 }}>
      {supplier && (
        <Tabs handleChange={handleChange} value={supplier?.id} tabObjects={tabs}>
          <Grid item container direction="row" justifyContent="space-between" spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <SupplierTable currentDate={currentDate} supplierId={supplier.id} />
            </Grid>
            <Grid item xs={12} md={5}>
              <SupplierDetails currentDate={currentDate} supplier={supplier} />
            </Grid>
          </Grid>
        </Tabs>
      )}
    </Grid>
  )
}
