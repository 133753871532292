import React, { ReactElement, useState } from 'react'

import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import ModuleHeaderButton from 'ui/components/ModuleHeaderButton/ModuleHeaderButton'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import CreateSandboxPopover from './components/CreateSandboxPopover/CreateSandboxPopover'
import SandboxTable from './components/SandboxTable/SandboxTable'

const widgetConstants = {
  SANDBOX_TABLE: `sandboxTable`,
}

export default function SandboxView(): ReactElement {
  const { t } = useTranslation()

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const { activeSystem } = useAuth()
  const system = activeSystem ? getDisplayName(activeSystem) : ``

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={
            <Grid container direction="column">
              {t(`Sandbox`)}
            </Grid>
          }
        >
          <>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6} md={5} lg={3} xl={2}>
                <ModuleHeaderButton
                  icon="fal fa-redo"
                  onClick={() => {
                    setCreateDialogOpen(true)
                  }}
                >
                  {t(`Create new project`)}
                </ModuleHeaderButton>
              </Grid>
            </Grid>
          </>
        </ModuleHeader>
      }
    >
      <Grid item xs={12}>
        <CardWidget id={widgetConstants.SANDBOX_TABLE} title={t(`My sandbox projects for: {{system}}`, { system })}>
          <SandboxTable />
        </CardWidget>
      </Grid>
      <CreateSandboxPopover open={createDialogOpen} closePopover={() => setCreateDialogOpen(false)} />
    </View>
  )
}
