import React, { ReactElement, useMemo, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import {
  ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
  PlanValues,
  useCopyBidsFromDate,
  useCreateElplanBidsMutation,
  useDeleteElplanBidsForDateMutation,
  useElplan,
  useElplanBidCreateMutation,
  useElplanBidDeleteMutation,
  useElplanBids,
  useElplanSendingBidMutation,
  useElplanBidUpdateMutation,
  useUpdateElplanBidsMutation,
  useElplanInfo,
  BlockBid,
  useSentBidsToBroker,
} from 'api/elplan/elplan.api'
import { useUiConfigAnchorComponent } from 'api/uiConfig/uiConfig.api'
import alertStore from 'store/alert/alert'
import elplanStore, { setCurrentDate } from 'store/elplan/elplan'
import { Button, Switch } from 'ui/atoms'
import LineDivider from 'ui/atoms/Line/LineDivider'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { DatePickerInForm } from 'ui/molecules/pickers/DatePicker/DatePicker'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'

import { Tooltip } from '@mui/material'
import { t } from 'i18next'
import { Button as ReactAriaButton, DialogTrigger } from 'react-aria-components'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { calcDataset, exportPlannedElectricityProductionToExcel } from 'helpers/dataset.helper/dataset.helper'
import { convertNumberDecimalSign } from 'helpers/global.helper/global.helper'

import styles from './BidManagementView.module.less'
import BlockBidTable from './BlockBidTable/BlockBidTable'
import CopyBidsModal from './CopyBidsModal/CopyBidsModal'
import SpotBidTable from './SpotBidTable/SpotBidTable'
import {
  BidValues,
  formatBid,
  FormattedBid,
  FormattedBidWithIdAndTitle,
  getDatasetInstructionsForSumVolumeCalc,
  getDefaultBlockBids,
  getItemsForExcel,
  getSpotBidsFromOptElplan,
} from './bidManagement.helper'

const showSentBidsAtBanner = (sentBidsToBrokerAtDate: undefined | { latest_date: ISODateTime }): ReactElement => {

  if (sentBidsToBrokerAtDate?.latest_date) {
    const formattedDate = Datetime.toLocalTime(sentBidsToBrokerAtDate.latest_date, 'hour')
    return (
      <InfoBanner
        style={'info'}
        text={t(`Bids sent at {{sentDate}}.`, {
          sentDate: formattedDate,
        })}
      />
    )
  }

  return <InfoBanner style={'warningWithoutBackground'} text={t('No bids sent')} />
}


export default function BidManagementView(): ReactElement {
  const elplanSnap = useSnapshot(elplanStore)
  const { t } = useTranslation()
  const auth = useAuth()

  const [currentView, setCurrentView] = useState<'tomorrow' | 'week' | ''>('tomorrow')
  const [chosenDate, setChosenDate] = useState<{ startTime: ISODateTime; endTime: ISODateTime }>({
    startTime: Datetime.getTomorrowDate().startTime,
    endTime: Datetime.getTomorrowDate().endTime,
  })
  const [typeOfBidToCopy, setTypeOfBidToCopy] = useState<string>()

  useElplan(elplanSnap.elplan_info_id, Datetime.toLocalTime(chosenDate.startTime, 'onlyDate') as ISODateTime)
  const systemHasBlockBids = elplanSnap.bid_types.find((bid) => bid.type === 'blockbid')
  const amountOfBlockBids = elplanSnap.blockBidInfo.amount || 0

  // Datasets for excel export of bids
  const onlyReturnIds = useMemo(() => {
    const onlyReturnIds = ['spotbid_without_price.volume']

    if (systemHasBlockBids) {
      for (let i = 0; i < amountOfBlockBids; i++) {
        onlyReturnIds.push(`blockbid.${i + 1}.volume`)
        onlyReturnIds.push(`blockbid.${i + 1}.price`)
      }
    }

    return onlyReturnIds
  }, [systemHasBlockBids, amountOfBlockBids])

  const { data: elplanBidsUiConfig } = useUiConfigAnchorComponent('elplan_bids')
  const { data: elplanBidsDatasets = [] } = useDatasets(
    elplanBidsUiConfig?.id ?? -1,
    elplanBidsUiConfig?.version ?? -1,
    elplanBidsUiConfig?.dataset_instructions || [],
    chosenDate.startTime,
    chosenDate.endTime,
    {
      onlyReturnIds,
      fillMissingHourTimestamps: false,
      datasetRefreshToken: ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
      overrideAlias: {
        elplan_info_id: elplanSnap.elplan_info_id,
      },
    }
  )

  const itemsForExcel = getItemsForExcel(amountOfBlockBids)
  const itemsDataId: Set<string> = new Set(itemsForExcel.map((item) => item.data_id))
  const visibleDatasets = elplanBidsDatasets.filter(({ return_id }) => itemsDataId.has(return_id))
  const datasetNames = (itemsForExcel || []).reduce((acc, item) => ({ ...acc, [item.data_id]: item.title }), {})

  // Data for creating/updating bids
  const { data: topicalBids } = useElplanBids(elplanSnap.elplan_info_id, chosenDate?.startTime, chosenDate?.endTime)

  const allFormattedBids = useMemo(() => {
    const formatted = topicalBids?.map((bid) => formatBid(bid as BlockBid))
    const spotBids = formatted?.filter((bid) => bid.type === 'spotbid_without_price') || []
    const blockBids = formatted?.filter((bid) => bid.type === 'blockbid')

    const defaultBlockData: FormattedBidWithIdAndTitle[] = getDefaultBlockBids(blockBids, amountOfBlockBids, {
      startTime: chosenDate.startTime,
      endTime: chosenDate.endTime,
    })
    const updatedBlockBids: FormattedBidWithIdAndTitle[] = []
    defaultBlockData.forEach((defaultBid) => {
      const blockBid = blockBids?.find((bid) => bid.block_order === defaultBid.block_order)
      if (blockBid) {
        updatedBlockBids.push(blockBid)
      } else {
        updatedBlockBids.push(defaultBid)
      }
    })

    return {
      spotBids: spotBids,
      blockBids: updatedBlockBids,
    }
  }, [chosenDate.endTime, chosenDate.startTime, topicalBids, amountOfBlockBids])

  const { mutate: createBid } = useElplanBidCreateMutation()
  const { mutate: updateBid } = useElplanBidUpdateMutation()
  const { mutate: deleteBid } = useElplanBidDeleteMutation()
  const { mutate: createSpotBids } = useCreateElplanBidsMutation()
  const { mutate: updateSpotBids } = useUpdateElplanBidsMutation()
  const { mutate: copyBidsFromDate } = useCopyBidsFromDate()
  const { mutate: deleteSpotBidsFromDate } = useDeleteElplanBidsForDateMutation()
  const { mutate: sendBidToElplan } = useElplanSendingBidMutation()


  function addNewBid(bid: FormattedBid): void {
    createBid({
      elplan_info: elplanSnap.elplan_info_id,
      start_time: Datetime.toISOString(bid.startTime),
      end_time: Datetime.toISOString(bid.endTime),
      type: bid.type,
      block_order: bid.block_order,
      volume: convertNumberDecimalSign(bid.volume),
      price: convertNumberDecimalSign(bid.price),
    })
  }

  async function createSpotbidsFromElplan(): Promise<void> {
    let bids: BidValues = []

    try {
      bids = await getSpotBidsFromOptElplan(chosenDate.startTime, chosenDate.endTime, !!elplanSnap.only_positive_bids)
      bids = bids.map((bid) => ({
        ...bid,
        volume: bid.volume !== undefined && !isNaN(parseFloat(bid.volume.toString())) 
          ? Number(bid.volume) 
          : 0,
      }))
    } catch (err: any) {
      sentry.captureException(err)
      alertStore.error(t('Could not copy spot bids from production plan (data missing)'))
      return
    }

    createSpotBids({
      elplan_info: elplanSnap.elplan_info_id,
      type: 'spotbid_without_price',
      bids,
    })
  }

  function addNewSpotBidsForDate(spotBids: PlanValues[]): void {
    const bids = spotBids.map((bid) => {
      return { time: bid.start_time, volume: convertNumberDecimalSign(bid.volume) }
    })
    createSpotBids({
      elplan_info: elplanSnap.elplan_info_id,
      type: 'spotbid_without_price',
      bids,
    })
  }

  function editSpotBidsForDate(spotBids: PlanValues[]): void {
    const bidsToBeUpdated = spotBids
      .map((bid) => {
        return { id: bid.id, volume: convertNumberDecimalSign(bid.volume) }
      })
      .filter((bid) => bid.id)

    updateSpotBids({
      elplan_info: elplanSnap.elplan_info_id,
      bids: bidsToBeUpdated,
    })
  }

  function removeBlockBid(bid: FormattedBidWithIdAndTitle): void {
    if (bid.id && !bid.fake) {
      deleteBid({ id: bid.id })
    }
  }

  function editBid(bid: FormattedBidWithIdAndTitle): void {
    updateBid({
      id: bid.id,
      start_time: Datetime.toISOString(bid.startTime),
      end_time: Datetime.toISOString(bid.endTime),
      volume: convertNumberDecimalSign(bid.volume),
      price: convertNumberDecimalSign(bid.price),
    })
  }
  function onDateTimePickerChange(value: ISODateTime): void {
    setChosenDate({ startTime: Datetime.getStartOfDay(value), endTime: Datetime.getEndOfDay(value) })
    setCurrentDate(Datetime.getStartOfDay(value), Datetime.getEndOfDay(value))
    setCurrentView('')
  }

  const graphDates = useMemo(() => {
    if (currentView === 'tomorrow') {
      setChosenDate({ startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime })
      return { startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime }
    }

    if (currentView === 'week') {
      setChosenDate({ startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getDateOneWeekAhead() })
      return { startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getDateOneWeekAhead() }
    }
  }, [currentView])

  function copySpotBidsFromDate(date: ISODateTime, type: string): void {
    copyBidsFromDate({
      elplan_info: elplanSnap.elplan_info_id,
      from_date: Datetime.toLocalTime(date, 'onlyDate') as ISODateTime,
      to_date: Datetime.toLocalTime(chosenDate.startTime, 'onlyDate') as ISODateTime,
      type: type,
    })
  }

  function removeSpotBidsForDate(date: ISODateTime): void {
    deleteSpotBidsFromDate({
      elplan_info: elplanSnap.elplan_info_id,
      date: Datetime.toLocalTime(date, 'onlyDate') as ISODateTime,
      type: 'spotbid_without_price',
    })
  }

  const elplanInfo = useElplanInfo(auth.systemId)

  const sentBidsToBrokerAtDate = useSentBidsToBroker(
    elplanSnap.elplan_info_id, chosenDate.startTime, chosenDate.endTime, undefined
  )
  const noIntegration =
    elplanInfo?.data?.integration === undefined ||
    elplanInfo?.data?.integration === null ||
    elplanInfo?.data?.integration === ''

  const calcDatasets = calcDataset(elplanBidsDatasets, getDatasetInstructionsForSumVolumeCalc(amountOfBlockBids))
  const maxBidVolume = elplanSnap?.maxBidVolume
  const bidsIsOvermaxBidVolume = typeof maxBidVolume === 'number'
    ? calcDatasets.values.some((num) => num !== null && num > maxBidVolume)
    : false

  return (
    <CardWidget title={t(`Bid management`)} id={''}>
      <div className={styles.ElectricityPlanningView_Period}>
        <Switch
          value={currentView}
          items={[
            { label: t('Tomorrow'), value: 'tomorrow' },
            { label: t('Week'), value: 'week' },
          ]}
          onClick={(value) => {
            setCurrentView(value)
          }}
          setNoTabActive={currentView === ''}
        />
        <DatePickerInForm
          value={chosenDate.startTime}
          onSubmit={(date) => {
            onDateTimePickerChange(date)
          }}
          label={t('Chosen date')}
          showWeekNumbers
          granularity={'day'}
          isRequired
        />
        <div className={styles.ElectricityPlanningView_Period__right}>
          <Button
            icon={'fas fa-cloud-download-alt'}
            secondary
            tooltip={`${t('Download bids as an excel')}`}
            onClick={() => exportPlannedElectricityProductionToExcel(visibleDatasets, datasetNames, chosenDate)}
          >
            {t('Export bids')}
          </Button>
          {!noIntegration && (
            <Tooltip
              title={
                bidsIsOvermaxBidVolume
                  ? t(`Some bids are over the maximum bid volume. Max volume for bids is {{maxBidVolume}} MW`, {
                    maxBidVolume: elplanSnap.maxBidVolume,
                  })
                  : ''
              }
              arrow
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Button
                  primary
                  marginLeft
                  disabled={bidsIsOvermaxBidVolume || currentView === 'week'}
                  onClick={() => {
                    const activeSystemName = auth.activeSystem?.name
                    if (!activeSystemName) {
                      return
                    }
                    return sendBidToElplan({
                      date: Datetime.toLocalTime(chosenDate.startTime, 'onlyDate'),
                      system: activeSystemName,
                    })
                  }}
                >
                  {t('Send bids')}
                </Button>
                {currentView !== 'week' && showSentBidsAtBanner(sentBidsToBrokerAtDate.data ?? undefined)}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.ElectricityPlanningView_Tables}>
        <UiConfig
          type="electricity_bid_management"
          datasetStartTime={chosenDate ? chosenDate?.startTime : graphDates?.startTime}
          datasetEndTime={chosenDate ? chosenDate?.endTime : graphDates?.endTime}
          datasetRefreshToken={ELPLAN_BID_REFRESH_EVENT_QUERY_KEY}
        />
      </div>
      <LineDivider />

      {systemHasBlockBids && (
        <div className={styles.ElectricityPlanningView_Tables}>
          <TitleWithIcon size="medium" title={t('Block bid editor')} />
          <div className={styles.ElectricityPlanningView_Tables__right}>
            <DialogTrigger>
              <ReactAriaButton
                className={styles.ElectricityPlanningView_ReactAriaButton}
                onPress={() => {
                  setTypeOfBidToCopy('blockbid')
                }}
              >
                <Button secondary marginRight icon={'fas fa-copy'}>
                  {t('Copy block bids from a chosen date')}
                </Button>
              </ReactAriaButton>
              <CopyBidsModal
                value={chosenDate.startTime}
                onSelect={(date) => {
                  copySpotBidsFromDate(date, typeOfBidToCopy ?? '')
                }}
                showWeekNumbers
                items={{
                  label: t('Copy block bids from a chosen date'),
                  heading: t('Choose a date to copy bids from'),
                  infoText: t('Copying bids only works for dates that contains that have bids in the same format'),
                  close: t('Close'),
                  confirmButtonLabel: t('Copy bids'),
                }}
              />
            </DialogTrigger>
          </div>
          <BlockBidTable
            blockBids={allFormattedBids.blockBids}
            createBid={(bid: FormattedBid) => addNewBid(bid)}
            updateBid={(bid: FormattedBidWithIdAndTitle) => editBid(bid)}
            deleteBid={(bid: FormattedBidWithIdAndTitle) => removeBlockBid(bid)}
            view={currentView}
          />
        </div>
      )}

      {
        <div className={styles.ElectricityPlanningView_Tables}>
          <TitleWithIcon size="medium" title={t('Spot bid editor')} />
          <div className={styles.ElectricityPlanningView_Tables__right}>
            <Button
              onClick={createSpotbidsFromElplan}
              secondary
              marginRight
              icon={'fas fa-copy'}
            >
              {t('Copy spot bids from production plan')}
            </Button>
            <DialogTrigger>
              <ReactAriaButton
                className={styles.ElectricityPlanningView_ReactAriaButton}
                onPress={() => {
                  setTypeOfBidToCopy('spotbid_without_price')
                }}
              >
                <Button secondary marginRight icon={'fas fa-copy'}>
                  {t('Copy spot bids from a chosen date')}
                </Button>
              </ReactAriaButton>
              <CopyBidsModal
                value={chosenDate.startTime}
                onSelect={(date) => {
                  copySpotBidsFromDate(date, typeOfBidToCopy ?? '')
                }}
                showWeekNumbers
                items={{
                  label: t('Copy block bids from a chosen date'),
                  heading: t('Choose a date to copy bids from'),
                  infoText: t('Copying bids only works for dates that contains that have bids in the same format'),
                  close: t('Close'),
                  confirmButtonLabel: t('Copy bids'),
                }}
              />
            </DialogTrigger>
          </div>
          <SpotBidTable
            currentDate={chosenDate}
            spotBids={allFormattedBids.spotBids}
            createBid={(bid: PlanValues[]) => addNewSpotBidsForDate(bid)}
            updateBid={(bid: PlanValues[]) => editSpotBidsForDate(bid)}
            deleteBidForDate={(date: ISODateTime) => removeSpotBidsForDate(date)}
          />
        </div>
      }
    </CardWidget>
  )
}
