import React, { ReactElement, useMemo } from 'react'

import { BASE_SETTING_PRIORITIES, DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'

import DigitalTwinSettingsView from './DigitalTwinSettingsView'

export default function DigitalTwinDeviationSettingsView(): ReactElement | null {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()
  
  const permissions = {
    canEdit: useHasPermission(`change_plan_settings`),
    canEditHistoric: useHasPermission(`change_historic_plan_settings`),
  }

  const priorities = useMemo(() => [
    ...DEVIATION_SETTING_PRIORITIES,
    ...(activeSystem?.setting_priority_levels ?? []),
  ], [activeSystem])

  return (
    <DigitalTwinSettingsView
      priorities={priorities}
      baseSettingPriorities={BASE_SETTING_PRIORITIES}
      showCalendar={true}
      title={t(`Deviation settings`)}
      calendarTitle={t(`Deviation settings - calendar`)}
      tableTitle={t(`Deviation settings - table`)}
      permissions={permissions}
    />
  )
}
