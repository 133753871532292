import React, { ReactElement } from 'react'

import { IconButton, Icon, Tooltip } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { startTimeLimit } from 'helpers/global.helper/global.helper'

type MonthPickerProps = {
  currentDate: Moment
  setCurrentDate: (arg0: Moment) => void
}

export default function MonthPicker({ currentDate, setCurrentDate }: MonthPickerProps): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <Tooltip title={t(`Previous month`)} arrow placement="top">
        <IconButton
          data-testid="monthPicker-previous"
          disabled={currentDate.clone().isBefore(moment(startTimeLimit).add(1, `d`))}
          onClick={() => {
            setCurrentDate(moment(currentDate.add(-1, `month`)))
          }}
          size="large"
        >
          <Icon className="fal fa-angle-left" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {currentDate.format(`MMMM YYYY`)}
      <Tooltip title={t(`Following month`)} arrow placement="top">
        <IconButton
          data-testid="monthPicker-next"
          onClick={() => {
            setCurrentDate(moment(currentDate.add(1, `month`)))
          }}
          size="large"
        >
          <Icon className="fal fa-angle-right" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  )
}
