import { apiClient } from 'api/apiClient/apiClient'

import { useMutation, useQuery, useQueryClient } from 'react-query'

export type LogBookPost = {
  id: string
  created_by?: string
  created_at?: string | ISODateTime
  updated_at?: string | ISODateTime | undefined
  updated_by?: string | undefined | null
  is_pinned: boolean
  is_reported: boolean
  category: string | undefined
  content: string
  written_by?: string
  updated_written_by?: string | undefined | null
  system_id: number | undefined
}

export const LOG_BOOK_QUERY_KEY = `logbook`

export function useLogBookPostCreate() {
  const queryClient = useQueryClient()

  return useMutation((data: LogBookPost) => apiClient<LogBookPost>(`logbook_post`, { data, method: `POST` }), {
    onSuccess: () => {
      queryClient.invalidateQueries(LOG_BOOK_QUERY_KEY)
    },
  })
}

export function useUpdateLogBookPostMutation() {
  const queryClient = useQueryClient()
  return useMutation(
    (data: LogBookPost) => apiClient<LogBookPost>(`logbook_post/${data.id}/update`, { data, method: `PATCH` }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LOG_BOOK_QUERY_KEY)
      },
    }
  )
}

export function useDeleteLogBookPostMutation() {
  const queryClient = useQueryClient()
  return useMutation((postId: string) => apiClient<void>(`logbook_post/${postId}/delete`, { method: `DELETE` }), {
    onSuccess: () => {
      queryClient.invalidateQueries(LOG_BOOK_QUERY_KEY)
    },
  })
}

export function useGetPinnedLogBookPosts(systemId: number | undefined) {
  return useQuery(
    [LOG_BOOK_QUERY_KEY, systemId, true], 
    async () => {
      const data = apiClient<LogBookPost[]>('logbook_posts', {
        method: 'GET',
        params: {
          system_id: systemId,
          is_pinned: true,
        },
      })

      if (!data) {
        return []
      }
      return data
    }, 
    {
      enabled: !!systemId,
    }
  )
}

type GetLogBookPostsParams = {
  systemId?: number | undefined
  createdFrom?: Date
  createdTo?: Date
  queryString?: string
}

export function useGetLogBookPosts({ systemId, createdFrom, createdTo, queryString }: GetLogBookPostsParams) {
  return useQuery([LOG_BOOK_QUERY_KEY, systemId, createdFrom, createdTo, queryString], async () => {
    const data = apiClient<LogBookPost[]>('logbook_posts', {
      method: 'GET',
      params: {
        system_id: systemId,
        created_from: createdFrom?.toISOString(),
        created_to: createdTo?.toISOString(),
        query: queryString?.trim(),
      },
    })

    if (!data) {
      return []
    }
    return data
  })
}

export function useGetLogBookCategories(systemId?: number) {
  return useQuery([LOG_BOOK_QUERY_KEY, systemId], async () => {
    const data = apiClient<string[]>('logbook_categories', { method: 'GET', params: { system_id: systemId } })

    if (!data) {
      return []
    }
    return data
  })
}
