import React, { ReactElement } from 'react'

import { useHeatSupplierData } from 'api/heatSuppliers/heatSuppliers.api'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Icon, Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'


import { getDisplayDate } from 'helpers/global.helper/global.helper'

import styles from './StatusBar.module.less'

export type SupplierViewStatus = `open` | `closed`

type StatusItemProps = {
  textClassName: string
  title: string
  text: string
  tooltip: string
}

function StatusItem({ textClassName, text, title, tooltip }: StatusItemProps): ReactElement {
  return (
    <Grid item container direction="column" alignItems="flex-start" xs={6} className={styles.StatusBar_Container}>
      <Grid item>
        <Typography className={styles.StatusBar_Title} display="inline">
          {title}
        </Typography>
        <Tooltip arrow placement="top" title={tooltip}>
          <Icon className={classNames(`fas fa-info-circle`, styles.StatusBar_InfoIcon)} fontSize="inherit" />
        </Tooltip>
      </Grid>
      <Grid item>
        <Typography className={textClassName}> {text}</Typography>
      </Grid>
    </Grid>
  )
}

type StatusBarProps = {
  currentDate: Moment
  supplierId?: number
  isDigitalTwin?: boolean
}

export default function StatusBar({ currentDate, supplierId, isDigitalTwin }: StatusBarProps): ReactElement {
  const { t } = useTranslation()

  const { data: heatSupplierData = [] } = useHeatSupplierData(
    moment(currentDate).startOf(`day`),
    moment(currentDate).endOf(`day`).minutes(0),
    true,
    supplierId,
    isDigitalTwin
  )
  const sortedData = heatSupplierData.filter((data) =>
    moment(data.time).isBefore(moment(currentDate).hour(13).minute(20))
  )
  const referenceTime = sortedData[sortedData.length - 1]?.reference_time
  const displayDate = referenceTime
    ? getDisplayDate(moment(referenceTime)) + moment(referenceTime).format(` D MMM HH:mm `)
    : `-`

  const status =
    moment().add(1, `day`).isBefore(currentDate) ||
    (moment().add(1, `day`).isSame(currentDate, `day`) && moment().isBefore(moment().hour(13).minute(20)))
      ? `open`
      : `closed`

  return (
    <Grid container direction="row" justifyContent="center">
      <StatusItem
        textClassName={status === `open` ? styles.StatusBar_Open : styles.StatusBar_Closed}
        text={status === `open` ? t(`Open`) : t(`Closed`)}
        title={t(`Status`)}
        tooltip={t(
          `The status shows that the prices for the next day are either still open for editing or closed and already sent to the supplier. The prices for the next day are locked at 13.20 each day and if any editing is done after that, it will not be part of the information that is sent to the supplier.`
        )}
      />
      <StatusItem
        textClassName={styles.StatusBar_Optimization}
        text={displayDate}
        title={t(`Optimization`)}
        tooltip={t(
          `Here, the time for the last optimization is showed, which is the foundation for the EnergyCollab price for the specific day. Optimizations are runned automatically every hour and are updating the price until 13.20 when the price is locked from the latest sucessful optimization.`
        )}
      />
    </Grid>
  )
}
