import Datetime from 'utils/datetime/datetime'

import ChartJS from 'chart.js/auto'

export type VerticalLineOptions = {
  enabled?: boolean,
  text?: string,
  x: ISODateTime
}
export default {
  id: `verticalLine`,
  enabled: true,
  afterDraw: function (chart: ChartJS, _args: any, options: VerticalLineOptions[]) {
    const labels = chart?.data?.labels ?? []

    if (labels.length <= 2) {
      return
    }

    if (!options?.length) {
      return
    }

    options.forEach((option) => {
      const now = option.x
      const textLabel = option.text || ``
      const startTime: ISODateTime = labels[0] as ISODateTime
      const endTime: ISODateTime = labels[labels.length - 1] as ISODateTime

      if (!startTime || !endTime) {
        return
      }

      if (Datetime.isBefore(now, startTime) || Datetime.isAfter(now, endTime)) {
        return
      }

      let nowIndex = 0
      let labelTime = startTime
      while (nowIndex < labels.length - 1) {
        labelTime = labels[nowIndex] as ISODateTime
        if (Datetime.isBeforeOrEqual(now, labelTime)) {
          break
        }

        nowIndex++
      }

      if (nowIndex <= 0 || nowIndex >= labels.length - 1) {
        return
      }

      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x },
      } = chart
      ctx.save()

      ctx.strokeStyle = `#1b72b8`
      ctx.lineWidth = 2
      ctx.strokeRect(x.getPixelForValue(nowIndex), top, 0, bottom - 10)

      ctx.fillStyle = `#1b72b8`
      ctx.textAlign = `center`
      ctx.font = `14px sans-serif`
      ctx.fillText(textLabel, x.getPixelForValue(nowIndex), top)
      ctx.restore()
    })
  },
}
