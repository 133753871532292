import React, { ReactElement, useState } from 'react'

import { ShiftTeam, useShiftTeams } from 'api/shiftTeams/shiftTeams.api'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Grid } from '@mui/material'
import moment from 'moment'


import styles from './ShiftInfoWidget.module.less'
import ShiftInfoCalendar from './components/ShiftInfoCalendar/ShiftInfoCalendar'
import ShiftInfoTeamList from './components/ShiftInfoTeamList/ShiftInfoTeamList'

function Wrapper({ shiftTeams }: { shiftTeams: ShiftTeam[] }): ReactElement {
  const [currentDate, setCurrentDate] = useState(moment().startOf(`isoWeek`))

  return (
    <>
      <Grid item xs={6} md={3} className={styles.ShiftInfoWidget_ShiftAchievements}>
        <ShiftInfoTeamList shiftTeams={shiftTeams} />
      </Grid>
      <Grid item xs={12} md={9}>
        <ShiftInfoCalendar shiftTeams={shiftTeams} currentDate={currentDate} setCurrentDate={setCurrentDate} />
      </Grid>
    </>
  )
}

export default function ShiftInfoWidget(): ReactElement {
  const { data: shiftTeams, status } = useShiftTeams()

  return (
    <Grid container direction="row" justifyContent="center" className={styles.ShiftInfoWidget_ShiftInBox}>
      <StatusComponent data={{ shiftTeams }} status={status} Component={Wrapper} />
    </Grid>
  )
}
