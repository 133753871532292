import React, { ReactElement, useState } from 'react'

import { useUiConfigById } from 'api/uiConfig/uiConfig.api'
import { Button } from 'ui/atoms'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'

import { TextField, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './CopyForm.module.less'

type CopyFormProps = {
  onSubmit: (uiConfig: UiConfig) => void
}

export default function CopyForm({ onSubmit }: CopyFormProps): ReactElement {
  const [uiConfigId, setUiConfigId] = useState<number | null>(null)
  const { data: uiConfigs } = useUiConfigById(uiConfigId)
  const { t } = useTranslation()
  const uid = uiConfigs?.[0]?.uid

  function copyUiConfig(): void {
    if (!uiConfigId || !uid) {
      return
    }

    onSubmit(uiConfigs[0])
  }

  return (
    <div className={styles.CopyForm}>
      <h2>{t(`Copy Ui Config`)}</h2>

      <Grid container direction="row">
        <Grid item xs={12}>
          <TextField
            error={uiConfigId === null}
            variant="outlined"
            label="Ui Config ID"
            aria-label="Ui Config ID"
            type="number"
            onBlur={(e) => setUiConfigId(Number(e.target.value))}
          />
          <Button primary disabled={!uid} onClick={copyUiConfig} marginLeft marginBottom>
            {t(`Copy`)}
          </Button>
        </Grid>
      </Grid>

      {uid && <UiConfigComponent uid={uid} />}
    </div>
  )
}
