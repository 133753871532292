import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import {
  ConfigurableProperty,
  DigitalTwinSettingCreationRequest,
  DeviationSettingValue,
  DigitalTwin,
  useDigitalTwinSettings,
} from 'api/digitalTwin/digitalTwin.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import authStore from 'store/auth/auth'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import Dropdown from 'ui/components/Dropdown/Dropdown'
import FormRow from 'ui/components/FormRow/FormRow'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import { NonFormSettingDateTimePicker } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/SettingDateTimePicker/SettingDateTimePicker'
import DeviationSettings from 'ui/uiConfig/anchorComponents/FollowupProduction/DeviationSettings/DeviationSettings'
import Chart from 'ui/uiConfig/components/Chart/Chart'
import { DEFAULT_TIME_SERIES_CONFIG } from 'ui/uiConfig/components/Chart/chart.constants'
import { ChartConfigWithZoom, ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'
import Datetime from 'utils/datetime/datetime'

import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import DigitalTwinSettingValueSelect from '../DigitalTwinSettingValueSelect/DigitalTwinSettingValueSelect'
import { DateTimePickerRange } from 'helpers/settingsModal.helper/settingsModal.helper'
import DisplayEventContainer from 'views/EventsView/components/DisplayEventContainer/DisplayEventContainer'
import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './DigitalTwinSettingForm.module.less'
import TimeSeriesSettingTable, { getTableKey } from './TimeSeriesSettingTable'
import { MODIFY_SETTING_COLUMN_RETURN_ID, TIME_SERIES_VISUALIZATION } from './constants'
import { useShouldEnableComputableTimeSeriesSettings } from './useShouldEnableComputableTimeSeriesSettings'

const MAX_REASON_INPUT_LENGTH = 255

function getValuesArrayFromSettingValue(
  times: ISODateTime[],
  value?: number | null | { [time: ISODateTime]: { id?: number; value: number | null } }
): { id?: number; value: number | null }[] {
  return times.map((time) => (value && typeof value === 'object' && time in value ? value[time] : { value: 0 }))
}

type DigitalTwinSettingFormProps = {
  settingData: DigitalTwinSettingCreationRequest
  setSettingData: (data: DigitalTwinSettingCreationRequest) => void
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  startEndTimeRange: DateTimePickerRange
  isUpdate: boolean
  digitalTwin: DigitalTwin
  activeSettingId?: number
  errorHelperText?: string
  activeConfigurableProperty?: ConfigurableProperty
  dialogContentRef?: React.RefObject<HTMLDivElement>
  invalidValuesIndex?: number[]
  operationalEvent?: EventPost | null
  disableAll?: boolean
}

function getReturnIdRegexFilterForSetting(settingData: DigitalTwinSettingCreationRequest): string {
  const isOverrideDemandForNode = settingData.level === 'node' && settingData.attribute === 'override_demand'
  const regexFilter = isOverrideDemandForNode
    ? `^demands\\..*\\.${settingData.name}.*`
    : `^(.*\\.)?${settingData.name}(\\..*)?$`
  return regexFilter
}

export default function DigitalTwinSettingForm({
  settingData,
  setSettingData,
  priorities,
  baseSettingPriorities,
  startEndTimeRange,
  isUpdate,
  digitalTwin,
  activeSettingId,
  errorHelperText,
  activeConfigurableProperty,
  dialogContentRef,
  invalidValuesIndex,
  operationalEvent,
  disableAll = false,
}: DigitalTwinSettingFormProps): ReactElement {
  const { activeSystem, systemId: globalSystemId } = useAuth()
  const systemId = activeSystem?.id ?? globalSystemId

  const { t } = useTranslation()
  
  const hasSettingsOutsideTimePeriod = useMemo(() => {
    if (!operationalEvent || !settingData.start_time || !settingData.end_time) {
      return false
    }

    const startsBeforeEvent = Datetime.isBefore(Datetime.toISOString(settingData.start_time), Datetime.toISOString(operationalEvent.start_time))
    const endsAfterEvent = Datetime.isAfter(Datetime.toISOString(settingData.end_time), Datetime.toISOString(operationalEvent.end_time))
    return startsBeforeEvent || endsAfterEvent
  }, [operationalEvent, settingData.end_time, settingData.start_time])

  // Used to display the time period the user has selected in the time pickers.
  const [editedTimePeriod, setEditedTimePeriod] = useState<{
    start: ISODateTime | null | undefined
    end: ISODateTime | null | undefined
  }>({
    start: settingData.start_time,
    end: settingData.end_time,
  })

  // Used to keep track of when the edited setting changes.
  // If we switch which setting we edit, we want to restore the time pickers to the start and end time of the new setting.
  const [settingStartAndEnd, setSettingStartAndEnd] = useState<{
    start: ISODateTime | null | undefined
    end: ISODateTime | null | undefined
  }>({
    start: settingData.start_time,
    end: settingData.end_time,
  })

  const times = useMemo(
    () =>
      settingData.isTimeSeries && settingData.start_time && settingData.end_time
        ? Datetime.getHoursBetween(settingData.start_time, settingData.end_time)
        : [],
    [settingData.end_time, settingData.isTimeSeries, settingData.start_time]
  )

  const values = getValuesArrayFromSettingValue(times, settingData.value)
  const [tableKey, setTableKey] = useState<string>(getTableKey(values))

  const startTime = settingData.start_time
  const endTime = settingData.end_time

  // Convert the setting value to a dataset for the chart
  const chartSettingDataSet = useMemo(() => {
    const chartSettingItem: Dataset = {
      return_id: MODIFY_SETTING_COLUMN_RETURN_ID,
      times: [],
      values: [],
    }
    if (settingData.isTimeSeries && settingData.value && typeof settingData.value === 'object') {
      Object.entries(settingData.value).forEach(([time, { value }]) => {
        (chartSettingItem.times as ISODateTime[]).push(time as ISODateTime)
        chartSettingItem.values.push(value)
      })
    }
    return chartSettingItem
  }, [settingData.isTimeSeries, settingData.value])

  const [settingBaseValue, setSettingBaseValue] = useState<DeviationSettingValue | string>()
  const [commentInputLength, setCommentInputLength] = useState<number>(settingData.comment.length)
  const { mutateAsync: fetchDigitalTwinSettings } = useDigitalTwinSettings()
  useEffect(() => {
    if (baseSettingPriorities === undefined || baseSettingPriorities.length === 0) {
      // The current view has no base setting priorities specified, so we just use the model default.
      setSettingBaseValue(activeConfigurableProperty?.default_value)
    } else {
      fetchDigitalTwinSettings({
        activeFrom: settingData.start_time,
        activeTo: settingData.end_time,
        properties: [
          {
            level: settingData.level,
            name: settingData.name,
            attribute: settingData.attribute,
          },
        ],
        priorities: baseSettingPriorities,
      }).then((settings) => {
        if (settings.length > 0) {
          setSettingBaseValue(Array.isArray(settings[0].value) ? settings[0].value[0].value : settings[0].value)
        } else {
          setSettingBaseValue(activeConfigurableProperty?.default_value)
        }
      })
    }
  }, [
    activeConfigurableProperty,
    baseSettingPriorities,
    fetchDigitalTwinSettings,
    settingData.attribute,
    settingData.end_time,
    settingData.level,
    settingData.name,
    settingData.start_time,
  ])

  // Use the dataset instructions of the `TIME_SERIES_VISUALIZATION` anchor component to fetch data.
  const authSnap = useSnapshot(authStore)
  const uiConfigTimeSeriesRes = useUiConfig(systemId, TIME_SERIES_VISUALIZATION, { mode: authSnap.isBeta ? `beta` : null })
  const tmpTimeSeriesUiConfig = uiConfigTimeSeriesRes?.data?.find((u) => u.component === TIME_SERIES_VISUALIZATION)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const uiConfig = uiConfigSnap.getParsedUiConfig(tmpTimeSeriesUiConfig?.uid ?? 0, {}, {})

  const regexFilter = getReturnIdRegexFilterForSetting(settingData)

  const datasetRes = useDatasets(
    uiConfig?.id ?? 0,
    uiConfig?.version ?? 0,
    uiConfig?.dataset_instructions ?? [],
    startTime ?? undefined,
    endTime ?? undefined,
    {
      uid: uiConfig?.uid ?? 0,
      returnIdRegexFilter: regexFilter,
      forceFillMissingHours: true,
    }
  )

  const chartConfig = DEFAULT_TIME_SERIES_CONFIG
  if (chartConfig.options) {
    chartConfig.options.ignoreUpdateDataResolutionOnZoom = true
  }

  const setDates = useCallback(
    (startTime: ISODateTime | undefined | null, endTime: ISODateTime | undefined | null) => {
      setSettingData({
        ...settingData,
        start_time: startTime || null,
        end_time: endTime || null,
      })
    },
    [setSettingData, settingData]
  )

  const updateTableKey = useCallback(
    (newStartTime: ISODateTime | undefined | null, newEndTime: ISODateTime | undefined | null) => {
      const newTimes =
        typeof settingData.value === 'object' && newStartTime && newEndTime
          ? Datetime.getHoursBetween(newStartTime, newEndTime)
          : []

      // When start time changes, we have to refresh the table. Otherwise, the start time will be the same
      // and the end time will change. When we refresh the table, all rows are pushed up to their new correct slot.
      const newTableKey = `${newTimes.reduce((v, s) => `${s},${v}`, '')}`
      setTableKey(newTableKey)
    },
    [settingData.value]
  )

  const updateSelectedTimePeriod = useCallback(
    (newStartTime: ISODateTime | undefined | null, newEndTime: ISODateTime | undefined | null) => {
      setDates(newStartTime, newEndTime)
      updateTableKey(newStartTime, newEndTime)
    },
    [setDates, updateTableKey]
  )
  const enableComputableTimeSeriesSettingsFeatureFlag = useShouldEnableComputableTimeSeriesSettings()

  // Check if user has switched which setting is being edited, or if the user is now creating a new setting.
  // If this is the case, update the time pickers to match the new setting.
  useEffect(() => {
    if (settingStartAndEnd.start !== settingData.start_time || settingStartAndEnd.end !== settingData.end_time) {
      setEditedTimePeriod({
        start: settingData.start_time,
        end: settingData.end_time,
      })
      updateTableKey(settingData.start_time, settingData.end_time)
      setSettingStartAndEnd({
        start: settingData.start_time,
        end: settingData.end_time,
      })
    }
  }, [settingData.end_time, settingData.start_time, settingStartAndEnd.end, settingStartAndEnd.start, updateTableKey])

  const timePickerPeriodMatchesSetting =
    editedTimePeriod.start === settingData.start_time && editedTimePeriod.end === settingData.end_time
  const endTimeIsBeforeStartTime = !!(
    editedTimePeriod.start &&
    editedTimePeriod.end &&
    editedTimePeriod.end < editedTimePeriod.start
  )

  return (
    <>
      <FormRow icon="fal fa-alarm-clock" spacing={1}>
        <div className={styles.DigitalTwinSettingForm_DatePickers}>
          <NonFormSettingDateTimePicker
            value={{
              start: editedTimePeriod.start,
              end: editedTimePeriod.end,
            }}
            isDisabled={disableAll || (isUpdate && settingData.isTimeSeries)}
            allowEmptyEndDate={!settingData.isTimeSeries}
            minValue={startEndTimeRange?.startTime.min}
            maxValue={startEndTimeRange?.endTime.max}
            setDates={(newStartTime, newEndTime) => {
              setEditedTimePeriod({ start: newStartTime, end: newEndTime })

              // For time series settings we don't update the time period until user presses the 'load period' button.
              // For normal settings we update the time period immediately.
              if (!settingData.isTimeSeries) {
                updateSelectedTimePeriod(newStartTime, newEndTime)
              }
            }}
          />
        </div>
      </FormRow>
      {hasSettingsOutsideTimePeriod && !settingData.isTimeSeries && (
        <FormRow icon={' '} spacing={1} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <InfoBanner text={t(`The time period for this setting is outside the connected event`)} style={'warning'} />
        </FormRow>
      )}
      {settingData.isTimeSeries && (
        <FormRow icon={' '} spacing={1}>
          {isUpdate && !disableAll && (
            <InfoBanner
              style="info"
              text={t(
                'Changes to the period of an existing time series setting is not allowed - create a new setting if you want to make changes'
              )}
            />
          )}

          <div className={styles.DigitalTwinSettingForm_LoadPeriodContainer}>
            <Button
              disabled={disableAll || timePickerPeriodMatchesSetting || endTimeIsBeforeStartTime}
              primary
              icon="fal fa-redo"
              onClick={() => {
                updateSelectedTimePeriod(editedTimePeriod.start, editedTimePeriod.end)
              }}
            >
              {t(`Load period`)}
            </Button>
            {!timePickerPeriodMatchesSetting && (
              <div className={styles.DigitalTwinSettingForm_TimeChangedContainer}>
                <Icon icon="fas fa-info-circle" size="medium" />
                <i className={styles.DigitalTwinSettingForm_TimeChangedText}>{t('Time has changed, load period to update the table.')}</i>
              </div>
            )}
            {hasSettingsOutsideTimePeriod && (
              <div style={{ marginLeft: '10px' }}>
                <InfoBanner text={t(`The time period for this setting is outside the connected event`)} style={'warning'}/>
              </div>
            )}
          </div>
        </FormRow>
      )}
      <FormRow icon="fal fa-wrench" spacing={1}>
        <div className={styles.DigitalTwinSettingForm_DeviationTypeAndValue}>
          <div className={`${styles.DigitalTwinSettingForm_DeviationTypeDropdownContainer} ${styles.DigitalTwinSettingForm_CustomWidth}`}>
            <Dropdown
              disabled={disableAll || priorities.length === 1 || isUpdate}
              fullWidth
              label={t('Deviation type')}
              items={priorities.map((p) => ({
                value: p.priority_level,
                label: t(p.display_name) ?? p.priority_level,
              }))}
              handleChange={(v: React.ChangeEvent<{ name?: string; value: number | unknown }>): void => {
                const value = v?.target?.value as number | undefined
                setSettingData({
                  ...settingData,
                  priority: Number(value),
                })
              }}
              value={settingData.priority}
            />
          </div>
          {!settingData.isTimeSeries
          && !(typeof settingData.value === 'object')
          && (
            <div className={styles.DigitalTwinSettingForm_CustomWidth}>
              <DigitalTwinSettingValueSelect
                attribute={settingData.attribute}
                value={settingData.value}
                disabled={disableAll}
                label={t('Value')}
                settingBaseValue={settingBaseValue}
                required
                onChange={(value) => {
                  setSettingData({
                    ...settingData,
                    value: value === '' ? null : value,
                  })
                }}
              />
              <p className={styles.DigitalTwinSettingForm_HelperText}>{errorHelperText}</p>
            </div>
          )}
        </div>
      </FormRow>
      <FormRow icon="fal fa-comment-alt-lines">
        <div className={styles.DigitalTwinSettingForm_CommentFieldContainer}>
          <TextField
            fullWidth
            margin="normal"
            id={'reason'}
            label={t('Reason') + (commentInputLength > 0 ? ` (${commentInputLength}/${MAX_REASON_INPUT_LENGTH})` : '')}
            aria-label={'Reason'}
            disabled={disableAll}
            required
            inputProps={{ maxLength: MAX_REASON_INPUT_LENGTH }}
            variant="outlined"
            defaultValue={settingData.comment}
            onChange={(e) => {
              const val = e.target.value as string
              setCommentInputLength(val.length)
              setSettingData({
                ...settingData,
                comment: val,
              })
            }}
          />
        </div>
      </FormRow>
      <div className={settingData.operational_event ? styles.DigitalTwinSettingForm_InformationContainer : ''}>
        {settingData.operational_event && (
          <DisplayEventContainer 
            operationalEventId={settingData.operational_event}
            operationalEvent={operationalEvent}
          />
        )}
        {settingData.start_time && settingData.end_time && (
          <div className={styles.DigitalTwinSettingForm_DeviationSettings}>
            <DeviationSettings
              startTime={settingData.start_time}
              endTime={settingData.end_time}
              forceListView
              activeSettingId={activeSettingId}
              specificDigitalTwin={digitalTwin}
            />
          </div>
        )}
      </div>

      {settingData.isTimeSeries && (
        <div className={styles.DigitalTwinSettingForm_StickyChart}>
          <FormRow icon="fal fa-chart-line" spacing={1}>
            <Chart
              title={t('Meas values')}
              items={(uiConfig?.props.items ?? []) as ChartItem[]}
              config={chartConfig as ChartConfigWithZoom}
              datasets={[...(datasetRes.data ?? []), chartSettingDataSet]}
              hide_legend={false}
              digitalTwinTranslations={digitalTwin.translations}
              settingInfo={{ name: settingData.name, attribute: settingData.attribute }}
            />
          </FormRow>
        </div>
      )}

      <FormRow dataTestId="time-series-setting-table" iconMarginLeft>
        {settingData.isTimeSeries && (
          <div className={styles.DigitalTwinSettingForm_TimeSeriesSettingTableContainer}>
            <TimeSeriesSettingTable
              chartItems={(uiConfig?.props.items ?? []) as ChartItem[]}
              datasets={[...(datasetRes.data ?? []), chartSettingDataSet]}
              dialogContentRef={dialogContentRef}
              invalidValuesIndex={invalidValuesIndex}
              setSettingData={setSettingData}
              settingsData={[settingData]}
              times={times}
              tableKey={tableKey}
              setTableKey={setTableKey}
              digitalTwinTranslations={digitalTwin.translations}
              enableComputableSettings={enableComputableTimeSeriesSettingsFeatureFlag}
              disabled={disableAll}
            />
            <div className={styles.DigitalTwinSettingForm_InvalidValuesHelperText}>{errorHelperText}</div>
          </div>
        )}
      </FormRow>

    </>
  )
}
