import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DateValue } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import DatePicker from './DatePicker'
import styles from './DatePickerPeriod.module.less'
import { useFormatErrorMessage } from './utils/useFormatErrorMessage'
import { validateRange } from './utils/validation'

type DatePickerProps = React.ComponentPropsWithoutRef<typeof DatePicker>
type AcceptedDateValue = DatePickerProps['value']

type DatePickerPeriodProps = {
  startDate: AcceptedDateValue
  endDate: AcceptedDateValue
  minDate?: DatePickerProps['minValue']
  maxDate?: DatePickerProps['minValue']
  isDisabled?: boolean
  startLabel?: string
  endLabel?: string
  onSelect?: (startTime: DateValue | null | undefined, endTime: DateValue | null | undefined) => void
  onError?: (error: boolean) => void
  granularity?: DatePickerProps['granularity']
  allowEmptyEndDate?: boolean
  startDateTransform?: (date: DateValue | null) => DateValue | null //Applies any special rules to date object which is applied onChange
  endDateTransform?: (date: DateValue | null) => DateValue | null //Applies any special rules to date object which is applied onChange
  startName?: string
  endName?: string
}

export default function DatePickerPeriod(props: DatePickerPeriodProps): React.ReactElement {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    startLabel,
    endLabel,
    onSelect,
    onError,
    allowEmptyEndDate = false,
    startName = 'start_date',
    endName = 'end_date',
    startDateTransform,
    endDateTransform,
    ...restProps
  } = props
  const { t } = useTranslation()
  const { format } = useFormatErrorMessage()
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)

  const [localStartDate, setLocalStartDate] = useState<DateValue | null | undefined>(startDate)
  const [localEndDate, setLocalEndDate] = useState<DateValue | null | undefined>(endDate)

  // We only want to update local start/end date when the prop changes, not on initial render.
  // This is to avoid double-rendering when the parent component sets the initial date.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [initialStartDate, initialEndDate] = useMemo(() => [startDate, endDate], [])
  useEffect(() => {
    if (!initialStartDate || startDate?.compare(initialStartDate) !== 0) {
      setLocalStartDate(startDate)
    }
    if (!initialEndDate || endDate?.compare(initialEndDate) !== 0) {
      setLocalEndDate(endDate)
    }
  }, [endDate, startDate, initialStartDate, initialEndDate])

  const validationResult = validateRange({
    startDate: localStartDate,
    endDate: localEndDate,
    minValue: minDate,
    maxValue: maxDate,
    allowEmptyEndDate,
  })

  // Return undefined when our validation is valid, to not override pickers internal validation
  const isInvalid = !validationResult.isValid || undefined

  function propagateError(): void {
    if (!onError) return

    const hasError = startDateError || endDateError || !validationResult.isValid
    onError(hasError)
  }
  useEffect(propagateError, [onError, startDateError, endDateError, validationResult.isValid])

  const handleOnBlur = (): void => {
    if (!startDateError && !endDateError) {
      onSelect?.(localStartDate, localEndDate)
    }
  }

  const handleOnEndDateChange = useCallback(
    (date: DateValue) => {
      if (endDateTransform && date) {
        setLocalEndDate(endDateTransform(date))
      } else {
        setLocalEndDate(date)
      }
    },
    [endDateTransform]
  )

  const handleOnStartDateChange = useCallback(
    (date: DateValue) => {
      if (startDateTransform && date) {
        setLocalStartDate(startDateTransform(date))
      } else {
        setLocalStartDate(date)
      }
    },
    [startDateTransform]
  )
  return (
    <div className={styles.DatePickerPeriod}>
      <DatePicker
        data-testid="start-date-picker"
        isPeriod
        isRequired
        name={startName}
        isInvalid={isInvalid}
        minValue={minDate}
        maxValue={maxDate}
        value={localStartDate}
        label={startLabel ?? t('Start date')}
        onChange={(date) => handleOnStartDateChange(date)}
        onBlur={handleOnBlur}
        showWeekNumbers
        errorMessage={!validationResult.isValid ? format(validationResult.error) : undefined}
        onError={setStartDateError}
        {...restProps}
      />
      <DatePicker
        data-testid="end-date-picker"
        isPeriod
        isRequired={!allowEmptyEndDate}
        value={localEndDate}
        isInvalid={isInvalid}
        label={endLabel ?? t('End date')}
        onChange={(date) => handleOnEndDateChange(date)}
        onError={setEndDateError}
        onBlur={handleOnBlur}
        minValue={minDate}
        maxValue={maxDate}
        name={endName}
        isEndPeriod
        allowEmpty={allowEmptyEndDate}
        showWeekNumbers
        {...restProps}
      />
    </div>
  )
}
