import React, { ReactElement, useRef, useState } from 'react'

import { DigitalCreationRequest } from 'api/digitalTwin/digitalTwin.api'
import { Dialog } from 'ui/components/Dialog/Dialog'
import FormRow from 'ui/components/FormRow/FormRow'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'

import { DialogContent, Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './DigitalTwinCreateModal.module.less'

type DigitalTwinCreateModalProps = {
  createNewModel: (requestData: DigitalCreationRequest) => void
  closeModal: () => void
}

export default function DigitalTwinCreateModal({createNewModel, closeModal }: DigitalTwinCreateModalProps): ReactElement {
  const { t } = useTranslation()

  const [displayName, setDisplayName] = useState<string | undefined>()
  const [name, setName] = useState<string | null>(null)
  const [description, setDescription] = useState<string | undefined>()

  const ref = useRef(null)
  return (
    <div className={styles.DigitalTwinCreateModal} >
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
      >
        <CustomDialogTitle title={t('Create new model')} handleClose={() => closeModal()} />
        <DialogContent style={{paddingTop: '10px'}}>
          <FormRow>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                id={'name'}
                label={t('Name')}
                aria-label={'name'}
                disabled={false}
                variant="outlined"
                fullWidth
                defaultValue={''}
                inputProps={{ style: { textTransform: 'lowercase' } }}
                helperText={t('Name must be in lower case')}
                error={name === null}
                onInput={e => {
                  const textAreaElement = e.target as HTMLTextAreaElement
                  if(textAreaElement.value){
                    setName(textAreaElement.value)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                id={'display_name'}
                label={t('Display name')}
                aria-label={'display_name'}
                disabled={false}
                variant="outlined"
                fullWidth
                defaultValue={''}
                error={displayName === null}
                onInput={e => {
                  const textAreaElement = e.target as HTMLTextAreaElement
                  if(textAreaElement.value){
                    setDisplayName(textAreaElement.value)
                  }
                }}
              />
            </Grid>
          </FormRow>
          <div className={styles.DigitalTwinCreateModal_TextFields}>
            <FormRow>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id={'description'}
                  label={t('Description')}
                  aria-label={'description'}
                  disabled={false}
                  variant="outlined"
                  fullWidth
                  defaultValue={''}
                  error={description === null}
                  onInput={e => {
                    const textAreaElement = e.target as HTMLTextAreaElement
                    if(textAreaElement.value){
                      setDescription(textAreaElement.value)
                    }
                  }}
                />
              </Grid>
            </FormRow>
          </div>
          <ModalButtons 
            onSubmit={() => {
              if (!name) {
                return
              }

              const requestData: DigitalCreationRequest = {
                name: name,
                display_name: displayName,
                description: description,
                model: {},
              }
              createNewModel(requestData)
              closeModal()
            }} 
            onCancel={() => closeModal()} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
