import React, { ReactElement, useMemo } from 'react'

import { OptJobType, useOptJobMutation } from 'api/optJobs/optJobs.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import OptimizeFormProvider, { UnitSettings } from 'ui/components/OptimizeFormProvider/OptimizeFormProvider'

import moment from 'moment'

export const OptimizeHeaderContentClass = `OptimizeHeaderContent`

type OptimizeHeaderContentProps = {
  type: OptJobType
  permission: UserPermission
  enableAutomaticOptimization?: boolean
  optStartTime?: moment.Moment
  optEndTime?: moment.Moment
}

export default function OptimizeHeaderContent({
  type,
  enableAutomaticOptimization,
  optStartTime,
  optEndTime,
}: OptimizeHeaderContentProps): ReactElement {
  const { activeSystem } = useAuth()
  const { mutate: optJobCreate, status: jobCreationStatus } = useOptJobMutation()

  const { startTime, endTime } = useMemo(
    () => ({
      startTime: optStartTime || moment().startOf('hour'),
      endTime: optEndTime?.startOf('second'),
    }),
    [optEndTime, optStartTime]
  )

  function optimize(unitSettings: UnitSettings): void {
    if (!activeSystem) {
      return
    }

    optJobCreate({
      opt_job_type: type,
      input_data: {
        opt_start_time: startTime.toISOString(),
        opt_end_time: endTime?.toISOString(),
        unit_settings: unitSettings,
      },
    })
  }

  return (
    <OptimizeFormProvider
      type={type}
      optimize={optimize}
      enableAutomaticOptimization={enableAutomaticOptimization ?? true}
      optStartTime={startTime}
      optEndTime={endTime}
      creatingOptJob={jobCreationStatus === 'loading'}
    />
  )
}
