import React, { ReactElement } from 'react'

import { WeatherForecast, WeatherLocation } from 'api/weatherLocations/weatherLocations.api'

import { Typography, Grid, Tooltip } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { getDisplayName, round } from 'helpers/global.helper/global.helper'

import styles from './WeatherInfo.module.less'

// eslint-disable-next-line @typescript-eslint/no-var-requires

type WeatherInfoProps = {
  forecast: WeatherForecast[]
  location: WeatherLocation
}

export default function WeatherInfo({ forecast, location }: WeatherInfoProps): ReactElement {
  const { t } = useTranslation()

  const weatherIndex = forecast?.[0]?.weather_symbol
  const referenceTime = forecast?.[1]?.reference_time
    ? t(`Updated: today at `) + moment(forecast[1].reference_time).format(`HH:00`)
    : ``
  return (
    <>
      {weatherIndex ? (
        <Grid container item direction="column">
          <Grid item>
            <Typography variant="h2" className={styles.WeatherInfo_CityText}>
              {location ? getDisplayName(location) : null}
            </Typography>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Tooltip
                arrow
                title={
                  <>
                    {t(`Source: `)}
                    <span className={styles.WeatherInfo_SourceText}>{forecast[0].source}</span>
                    <br />
                    {referenceTime}
                  </>
                }
              >
                <img
                  className={styles.WeatherInfo_ImageWeather}
                  src={`/img/wsymb/wsymb` + weatherIndex + `.png`}
                  alt="weather_img"
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={styles.WeatherInfo_DegreeText}>
                {round(forecast[0].temperature, 1)} °C
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}
