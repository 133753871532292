import React, { ReactElement, useState, useRef, useEffect } from 'react'

import { useChangePasswordMutation } from 'api/auth/auth.api'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'

import { Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'


import { hasLowerCase, hasUpperCase, hasSpecialChar } from 'helpers/global.helper/global.helper'
import RequirementField from 'views/LoginView/components/ResetPassword/components/RequirementField/RequirementField'

import styles from './ChangePasswordBox.module.less'

export default function ChangePasswordBox(): ReactElement {
  const [newPassword1, setNewPassword1] = useState(``)
  const [newPassword2, setNewPassword2] = useState(``)
  const [currentPassword, setCurrentPassword] = useState(``)
  const [meetLowerCaseReq, setMeetLowerCaseReq] = useState(false)
  const [meetUppercaseOrSpecialCharReq, setMeetUppercaseOrSpecialCharReq] = useState(false)
  const [meetPasswordLengthReq, setMeetPasswordLengthReq] = useState(false)
  const [hasCurrentPassword, setHasCurrentPassword] = useState(false)
  const { mutateAsync, isSuccess } = useChangePasswordMutation()
  const { t } = useTranslation()

  const buttonDisabled =
    !hasCurrentPassword === false &&
    !meetUppercaseOrSpecialCharReq === false &&
    !meetLowerCaseReq === false &&
    !meetPasswordLengthReq === false &&
    newPassword1 === newPassword2
      ? false
      : true

  function validatePasswordRequirements(pass: string): void {
    setMeetLowerCaseReq(hasLowerCase(pass))
    setMeetUppercaseOrSpecialCharReq(hasUpperCase(pass) || hasSpecialChar(pass))
    setMeetPasswordLengthReq(pass.length >= 8)
  }

  function validateCurrentPassword(pass: string): void {
    if (pass !== ``) setHasCurrentPassword(true)
    else setHasCurrentPassword(false)
  }

  const currPswd = useRef<HTMLInputElement | null>(null)
  const pswd1 = useRef<HTMLInputElement | null>(null)
  const pswd2 = useRef<HTMLInputElement | null>(null)

  function resetPswdFields(): void {
    if (isSuccess && currPswd.current && pswd1.current && pswd2.current) {
      currPswd.current.value = ``
      pswd1.current.value = ``
      pswd2.current.value = ``
      setMeetUppercaseOrSpecialCharReq(false)
      setMeetPasswordLengthReq(false)
      setMeetLowerCaseReq(false)
    }
  }

  useEffect(() => {
    if (isSuccess) resetPswdFields()
  })

  return (
    <Grid className={styles.ChangePasswordBox_PasswordBox}>
      <Grid item>
        <Typography className={styles.ChangePasswordBox_ChangePasswordTitle}>{t(`Change password`)}</Typography>
      </Grid>

      <Grid item xs={10}>
        <TextField
          inputProps={{ id: `currentpass`, 'data-testid': `currentpass` }}
          inputRef={currPswd}
          className={styles.ChangePasswordBox_TextField}
          variant="outlined"
          label={t(`Current password`)}
          type="password"
          autoComplete="current-password"
          aria-label={t(`Current password`)}
          onChange={(event) => {
            setCurrentPassword(event.target.value)
            validateCurrentPassword(event.target.value)
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          inputProps={{ id: `newpass1`, 'data-testid': `newpass1` }}
          inputRef={pswd1}
          className={styles.ChangePasswordBox_TextField}
          variant="outlined"
          label={t(`New password`)}
          aria-label={t(`New password`)}
          type="password"
          onChange={(event) => {
            setNewPassword1(event.target.value)
            validatePasswordRequirements(event.target.value)
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          inputProps={{ id: `newpass2`, 'data-testid': `newpass2` }}
          inputRef={pswd2}
          className={styles.ChangePasswordBox_TextField}
          variant="outlined"
          label={t(`Repeat password`)}
          aria-label={t(`Repeat password`)}
          type="password"
          onChange={(event) => {
            setNewPassword2(event.target.value)
          }}
        />
      </Grid>
      <Grid container direction="column" alignItems="flex-start" className={styles.ChangePasswordBox_ReqField}>
        <RequirementField
          meetLowerCaseReq={meetLowerCaseReq}
          meetUppercaseOrSpecialCharReq={meetUppercaseOrSpecialCharReq}
          meetPasswordLengthReq={meetPasswordLengthReq}
          matchingPasswords={newPassword1 === newPassword2 && newPassword1 !== `` && newPassword2 !== ``}
        />
      </Grid>
      <Grid item container direction="row" justifyContent="flex-end">
        <Grid item className={styles.ChangePasswordBox_Button}>
          <ContainedIconButton
            onClick={() => {
              mutateAsync({ newPassword1, newPassword2, currentPassword })
              setNewPassword1(``)
              setNewPassword2(``)
            }}
            disabled={buttonDisabled}
            label={t(`Update password`)}
            icon="fal fa-key"
            aria-label={t(`Update password`)}
            color="primary"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}