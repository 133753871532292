import React, { ReactElement, useRef, useState } from 'react'

import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'

import { DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './SettingsModal.module.less'
import CustomDialogTitle from './components/CustomDialogTitle/CustomDialogTitle'

export type SettingsModalProps = {
  open: boolean
  closeModal: () => void
  form: ReactElement | null
  title: string | ReactElement
}

export default function SettingsModal({ open, closeModal, form, title }: SettingsModalProps): ReactElement {
  const [dirtyForm, setDirtyForm] = useState(false)
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
  const handleClose = (): void => (!dirtyForm ? closeModal() : setCloseConfirmOpen(true))

  const { t } = useTranslation()
  const ref = useRef(null)

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        className={styles.SettingsModalDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        container={ref.current}
        // To prevent MUI and react-aria-components from fighting over focus
        disableEnforceFocus
      >
        <CustomDialogTitle title={title} handleClose={handleClose} />
        <DialogContent>{form ? React.cloneElement(form, { setIsDirty: setDirtyForm }) : form}</DialogContent>
      </Dialog>
      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          setCloseConfirmOpen(false)
          closeModal()
        }}
        textObject={{
          title: t(`Do you really want to close without saving?`),
          text: t(
            `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
          ),
        }}
      />
    </div>
  )
}
