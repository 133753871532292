import React, { ReactElement, useState, useEffect, KeyboardEvent, useMemo } from 'react'

import {
  useUpdateUiConfigMutation,
  useUnpublishUiConfigMutation,
  useDeleteUiConfigMutation,
} from 'api/uiConfig/uiConfig.api'
import uiConfigStore, { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import Button from 'ui/atoms/Button/Button'
import { Dialog } from 'ui/components/Dialog/Dialog'
import { appendPropsWithDataset } from 'ui/uiConfig/factory'
import { canMigrateUiConfigToDbS, migrateUiConfigToDbS } from 'utils/dbs/dbs.helper'
import { DeepReadonly } from 'utils/types'

import MonacoEditor from '@monaco-editor/react'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { CompareUiConfigVersions } from '../CompareVersions/CompareVersions'
import { getMissingCalcVariables, mapReturnIdtoVariables } from 'helpers/dataset.helper/dataset.helper'
import { isEqual } from 'helpers/global.helper/global.helper'

import styles from './UiConfigEditor.module.less'
import AddDatasetForm from './components/AddDatasetForm/AddDatasetForm'
import CopyForm from './components/CopyForm/CopyForm'

type EditablePartOfUiConfig = Pick<
  UiConfig,
  `display_name` | `props` | `children_ids` | `dataset_instructions` | `alias`
>

function extractEditableFields(uiConfig: DeepReadonly<UiConfig>): DeepReadonly<EditablePartOfUiConfig> {
  return {
    display_name: uiConfig.display_name,
    props: uiConfig.props,
    children_ids: uiConfig.children_ids,
    dataset_instructions: uiConfig.dataset_instructions,
    alias: uiConfig.alias,
  }
}

export default function UiConfigEditor(): ReactElement {
  const { mutate: updateUiConfig } = useUpdateUiConfigMutation()
  const { mutate: unpublishUiConfig } = useUnpublishUiConfigMutation()
  const { mutate: deleteUiConfigs } = useDeleteUiConfigMutation()
  const [jsonEditorValue, setJsonEditorValue] = useState('')
  const [isValidJson, setIsValidJson] = useState(true)
  const [datasetFormIsOpen, setAddDatasetFormIsOpen] = useState(false)
  const [copyFormIsOpen, setCopyFormIsOpen] = useState(false)
  const [showCompareDialog, setShowCompareDialog] = useState(false)

  const [confirmSaveMessage, setConfirmSaveMessage] = useState<string>()
  const [showConfirmDelete, setShowConfigmDelete] = useState(false)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const { t } = useTranslation()
  const editingUiConfigSnap = useSnapshot(editingUiConfigStore)
  const uid = uiConfigSnap.editingUid
  const uiConfigBeingEdited = editingUiConfigSnap.editingUiConfig

  const jsonEditorValueAsUiConfig = useMemo(() => {
    try {
      return JSON.parse(jsonEditorValue) as UiConfig
    } catch (error: unknown) {
      return undefined
    }
  }, [jsonEditorValue])

  const showDbSMigrationButton = useMemo(() => {
    if (!jsonEditorValueAsUiConfig) {
      return false
    }

    return canMigrateUiConfigToDbS(jsonEditorValueAsUiConfig)
  }, [jsonEditorValueAsUiConfig])

  useEffect(() => {
    const editableUiConfig = uiConfigBeingEdited ? extractEditableFields(uiConfigBeingEdited) : {}

    if (isEqual(editableUiConfig, jsonEditorValueAsUiConfig)) {
      return
    }

    setJsonEditorValue(JSON.stringify(editableUiConfig, null, 2))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiConfigBeingEdited?.uid])

  function addNewDataset(datasetInstruction: DatasetInstruction): void {
    const componentName = uiConfigBeingEdited?.component

    if (!componentName) {
      return
    }

    const newJsonEditorValue = {
      ...jsonEditorValueAsUiConfig,
      props: appendPropsWithDataset(componentName, datasetInstruction, jsonEditorValueAsUiConfig?.props || {}),
      dataset_instructions: [...(jsonEditorValueAsUiConfig?.dataset_instructions || []), datasetInstruction],
    } as UiConfig

    setJsonEditorValue(JSON.stringify(newJsonEditorValue, null, 2))
    setAddDatasetFormIsOpen(false)
  }

  function checkCalcsAndSave(publishingUiConfig?: boolean): void {
    if (!uiConfigBeingEdited || !jsonEditorValueAsUiConfig || !uid) {
      return // We are not editing a UI config, or the JSON is invalid.
    }

    const datasetInstructions = jsonEditorValueAsUiConfig.dataset_instructions as DatasetInstruction[]
    const returnIdToVariables = mapReturnIdtoVariables(datasetInstructions)
    const missingVariables = getMissingCalcVariables(datasetInstructions, returnIdToVariables)

    if (missingVariables.size > 0) {
      const missingVariablesString = Array.from(missingVariables).map(v => `"${v}"`).join(', ')
      setConfirmSaveMessage(`${t('The UiConfig has calcs with missing variables')}: ${missingVariablesString}`)
    } else {
      save(publishingUiConfig)
    }
  }

  function save(publishingUiConfig?: boolean): void {
    if (!uiConfigBeingEdited || !jsonEditorValueAsUiConfig || !uid) {
      return // We are not editing a UI config, or the JSON is invalid.
    }

    const uiConfig: UiConfig = {
      ...uiConfigBeingEdited,
      ...(jsonEditorValueAsUiConfig ?? {}),
      is_published: publishingUiConfig === true,
    }

    // Reset mode when publishing
    if (publishingUiConfig) {
      uiConfig.mode = `dev`
    }

    updateUiConfig(uiConfig)

    if (uiConfig.uid) {
      uiConfigStore.previewCollection[uiConfig.uid] = {}
    }
  }

  function openCompareDialog(): void {
    setShowCompareDialog(true)
  }

  function unPublish(): void {
    if (!uid) {
      return
    }

    unpublishUiConfig(uid)
  }

  function deleteAllVersions(): void {
    if (!uid) {
      return
    }

    const id = uiConfigSnap.getUnparsedUiConfig(uid)?.id

    if (!id) {
      return
    }

    deleteUiConfigs(id)
  }

  if (!uid) {
    return <div className={styles.UiConfigEditor} />
  }

  if (!uiConfigBeingEdited) {
    return <div className={styles.UiConfigEditor} />
  }

  function triggerShortcut(ev: KeyboardEvent<HTMLDivElement>): void {
    const key = ev.key.toLowerCase()
    if (ev.ctrlKey && key === 's') {
      checkCalcsAndSave()
      ev.preventDefault()
      ev.stopPropagation()
    }
  }

  return (
    <div className={styles.UiConfigEditor} onKeyUp={triggerShortcut}>
      <div className={styles.UiConfigEditor_Header}>
        <Button link onClick={() => setAddDatasetFormIsOpen(true)}>
          {t(`Add dataset`)}
        </Button>

        <Dialog fullWidth maxWidth="md" open={datasetFormIsOpen} onClose={() => setAddDatasetFormIsOpen(false)}>
          <AddDatasetForm onSubmit={addNewDataset} />
        </Dialog>

        <Button link onClick={() => setCopyFormIsOpen(true)}>
          {t(`Copy`)}
        </Button>

        <Dialog fullWidth maxWidth="md" open={copyFormIsOpen} onClose={() => setCopyFormIsOpen(false)}>
          <CopyForm
            onSubmit={(uiConfig) => {
              setJsonEditorValue(JSON.stringify(uiConfig))
              setCopyFormIsOpen(false)
            }}
          />
        </Dialog>

        <Button link onClick={() => openCompareDialog()}>
          {t(`Compare`)}
        </Button>

        <Dialog
          PaperProps={{
            sx: {
              minHeight: '80vh',
            },
          }}
          fullWidth
          maxWidth="xl"
          open={showCompareDialog}
          onClose={() => setShowCompareDialog(false)}
        >
          <DialogTitle>{t('Compare UI config versions')}</DialogTitle>
          <DialogContent>
            <CompareUiConfigVersions
              config={{
                ...uiConfigBeingEdited,
                ...(jsonEditorValueAsUiConfig ?? {}),
              }}
            />
          </DialogContent>
        </Dialog>
      </div>

      <div className={styles.UiConfigEditor_Body}>
        <MonacoEditor
          height="100%"
          language="json"
          value={jsonEditorValue}
          options={{
            minimap: { enabled: false },
            readOnly: false,
            wordWrap: `on`,
            automaticLayout: true,
            formatOnType: false,
            formatOnPaste: false,
          }}
          onChange={(value) => {
            setJsonEditorValue(value || '')

            try {
              setIsValidJson(true)
            } catch (error: unknown) {
              setIsValidJson(false)
            }
          }}
        />
      </div>

      <div className={styles.UiConfigEditor_Footer}>
        <Button
          secondary
          onClick={() => checkCalcsAndSave(false)}
          tooltip="ctrl + s"
          disabled={!isValidJson}
          disabledTooltip="Invalid JSON"
        >
          {t(`Save`)}
        </Button>
        {uiConfigBeingEdited.is_published && (
          <Button danger onClick={unPublish}>
            {t(`Unpublish`)}
          </Button>
        )}
        {!uiConfigBeingEdited.is_published && (
          <Button
            danger
            secondary
            onClick={() => checkCalcsAndSave(true)}
            disabled={!isValidJson}
            disabledTooltip="Invalid JSON"
          >
            {t(`Publish`)}
          </Button>
        )}
        <Button danger onClick={() => setShowConfigmDelete(true)}>
          {t('Delete')}
        </Button>

        {showDbSMigrationButton && (
          <Button
            primary
            onClick={() => {
              const migratedUiConfig = migrateUiConfigToDbS(jsonEditorValueAsUiConfig as UiConfig)

              console.debug('Migrating to DbS', {
                originalUiConfig: jsonEditorValueAsUiConfig,
                migratedUiConfig,
              })

              setJsonEditorValue(JSON.stringify(migratedUiConfig, null, 2))
            }}
          >DbS-ify</Button>
        )}

        <Dialog open={showConfirmDelete} onClose={() => setShowConfigmDelete(false)}>
          <DialogTitle fontWeight="bold">{t('Delete')}</DialogTitle>
          <DialogContent>{t('Are you sure you want to delete all versions of this Ui Config?')}</DialogContent>
          <DialogActions>
            <Button
              secondary
              onClick={() => {
                setShowConfigmDelete(false)
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              danger
              onClick={() => {
                deleteAllVersions()
                setShowConfigmDelete(false)
              }}
            >
              {t('Delete')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmSaveMessage !== undefined} onClose={() => setConfirmSaveMessage(undefined)}>
          <DialogTitle fontWeight="bold">{t('Save')}</DialogTitle>
          <DialogContent>
            <div>{confirmSaveMessage}</div>
            <div>{t('Are you sure you want to save?')}</div>
          </DialogContent>
          <DialogActions>
            <Button
              secondary
              onClick={() => {
                setConfirmSaveMessage(undefined)
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              primary
              onClick={() => {
                save()
                setConfirmSaveMessage(undefined)
              }}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}
