import React, { ReactElement, useState } from 'react'

import Button from 'ui/atoms/Button/Button'

import html2PDF from 'jspdf-html2canvas'
import printJS from 'print-js'
import { useTranslation } from 'react-i18next'

type PrintButtonProps = {
  title?: string
  fileName?: string
  fitOverflowY?: {
    overflowInParentElement?: boolean
  }
}

const htmlElementToBase64Pdf = async (htmlElement: HTMLElement): Promise<string> => {
  const pdf = await html2PDF(htmlElement, {
    success: () => undefined,
  })
  return btoa(pdf.output())
}

export default function PrintButton({
  title: _title,
  fileName: documentTitle = '',
}: PrintButtonProps): ReactElement {
  const { t } = useTranslation()

  const [printInProgress, setPrintInProgress] = useState(false)
  const title = _title || t('Print')

  return (
    <Button
      disabled={printInProgress}
      onClick={async () => {
        if (printInProgress) {
          return
        }
        
        setPrintInProgress(true)

        const htmlElements = document.querySelectorAll<HTMLElement>('[data-printable]')
        if (!htmlElements.length) {
          return
        }
        
        const printable = await htmlElementToBase64Pdf(htmlElements[0])

        printJS({ 
          printable, 
          type: 'pdf',
          base64: true,
          documentTitle,
        })

        setPrintInProgress(false)
      }}
      secondary
      large
      marginRight
      icon={`far fa-file-image`}
    >
      {title}
    </Button>
  )
}
