import React, { useEffect } from 'react'

import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import filterStore, { addTags } from 'store/filter/filter'

import { useSnapshot } from 'valtio'

import { useAuth } from '../AuthContext/AuthContext'
import { queryClient } from 'helpers/queryClient'
import { getRoute } from 'helpers/route.helper/route.helper'
import MultiLevelDropdown from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown'
import { parseTags } from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'

type FilterComponentProps = {
  datasetRefreshToken: string
}

export default function FilterComponent({ datasetRefreshToken }: FilterComponentProps): JSX.Element {
  const {currentActiveTagsPerRoute, allTagsPerRoute} = useSnapshot(filterStore)
  const route = getRoute()
  const { activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true, true)

  useEffect(() => {
    if (digitalTwin) {

      const allTagsFromModel: string[] = digitalTwin?.model?.all_tags ?? []

      if (allTagsFromModel.length > 0) {
        addTags(allTagsFromModel, route)
      }
    }
  }, [digitalTwin, route])

  const allTags = allTagsPerRoute[route] ?? []
  const currentTags = currentActiveTagsPerRoute[route] ?? new Set()
  const translations = digitalTwin?.translations


  return (
    <MultiLevelDropdown
      options={parseTags(Array.from(allTags))}
      onSelected={(value: string[]) => {
        filterStore.currentActiveTagsPerRoute[route] = new Set(value)
        queryClient.invalidateQueries([datasetRefreshToken])
      }}
      values={Array.from(currentTags)}
      translations={translations}
      hiddenFilters={digitalTwin?.model?.hidden_default_tags}
      topLevelSortingOrder={digitalTwin?.model?.tag_sorting_order}
      items={filterStore.tagsPerUiconfig}
    />
  )
}
