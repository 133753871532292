import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { getLabelForEventStatus, EventStatus as EventStatusType, getColorForEventStatus } from '../events.helper'

import styles from './EventStatus.module.less'

type EventStatusProps = {
  status: EventStatusType
}

export default function EventStatus({ status }: EventStatusProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles.EventStatus_Container}>
      <div className={`${styles.EventStatus_Status}`} style={{backgroundColor: `${getColorForEventStatus(status)}`}}>{t(getLabelForEventStatus(status)) }</div>
    </div>
  )
}