import React, { ReactElement, ReactNode, useState } from 'react'

import { dynamicClassName } from 'styles/helper'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps, registerComponentHeight } from 'ui/uiConfig/factory'

import { sortChartItems } from '../Chart/Tooltip/tooltip.helper'
import {
  getReturnIdsFromDatasetInstruction,
  applyAggregateFromUiConfig,
} from 'helpers/dataset.helper/dataset.helper'
import {getTypeObject } from 'helpers/global.helper/global.helper'

import styles from './HorizontalBars.module.less'

export type HorizontalBarsItem = {
  title: string
  data_id: string
  color?: string
  unit?: string
  decimals?: number
  tooltip?: string
  data_type?: string
  order?: number | string | undefined
  stack_group: string | number
  [key: string]: any
}

type HorizontalBarStackGroup = {
  title: string
  id: string | number
  include_only_base_items_with_attr?: Record<string, boolean | number | string | null>
}

type HorizontalBarsProps = {
  uid: number
  items: HorizontalBarsItem[]
  stack_groups: HorizontalBarStackGroup[]
  title: string
  data_type: string
  datasets: Dataset[]
  children: ReactNode
  ignoreZoom: boolean
}

const defaultProps: Omit<HorizontalBarsProps, `datasets` | `children` | `uid` | `ignoreZoom`> = {
  items: [
    {
      title: ``,
      data_id: ``,
      color: `$color-1`,
      unit: ``,
      order: 0,
      stack_group: 0,
      decimals: 1,
      data_type: ``,
    },
  ],
  stack_groups: [
    {
      title: ``,
      id: 0,
    },
    {
      title: ``,
      id: 1,
    },
  ],
  title: ``,
  data_type: '',
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: HorizontalBarsProps
): HorizontalBarsProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId,
    data_id: returnId,
    color: `$color-1`,
    unit: ``,
    order: 0,
    stack_group: 0,
    decimals: 1,
    data_type: ``,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}
registerUiConfigComponent('horizontal_bars', HorizontalBars)
registerAppendPropsWithDatasets('horizontal_bars', appendPropsWithDataset)
registerDefaultProps('horizontal_bars', defaultProps)
registerComponentHeight('horizontal_bars', 112)

export default function HorizontalBars({
  uid,
  items,
  datasets,
  title,
  stack_groups,
  data_type,
}: HorizontalBarsProps): ReactElement {
  const [showTable, setShowState] = useState(false)

  if (!datasets?.length && !items?.length) {
    return <></>
  }
  if (!stack_groups?.length) {
    return <></>
  }

  sortChartItems(items)

  //add the data_ids that should only be included  if "include_only_base_items_with_attr" is set on stack_group items
  const onlyIncludedDataIdsFromAttr: string[][] = [[], []]

  stack_groups.forEach(stackGroup => {
    const stackGroupId = Number(stackGroup.id)
    Object.entries(stack_groups[stackGroupId].include_only_base_items_with_attr || {}).forEach(([attr, value]) => {
      items?.forEach((item) => {
        if (item[attr] === value && item.stack_group === stackGroupId) {
          onlyIncludedDataIdsFromAttr[stackGroupId].push(item.data_id)
        }
      })
    })
  })

  const aggregatedDatasets = applyAggregateFromUiConfig(datasets, uid, {
    invalidIndexCheckIds: items.map(({ data_id }) => data_id),
    skipInvalidIndices: true,
  })
  let dataType = ``
  let itemUnit = ``
  let decimals = 1
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.data_type) {
      dataType = item.data_type
      break
    }
    if (item.unit) {
      itemUnit = item.unit
      break
    }
  }
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (item.decimals) {
      decimals = item.decimals
      break
    }
  }

  const stackGroupTotal: Record<string | number, number> = {}
  const returnIdToStackGroupId: Record<string, string | number> = {}
  stack_groups.forEach((stackGroup) => {
    stackGroupTotal[stackGroup.id] = 0

    items
      .filter((item) => (item.stack_group === stackGroup.id)).forEach((item) => {
        returnIdToStackGroupId[item.data_id] = stackGroup.id
      })

  })

  aggregatedDatasets
    .filter(({ return_id }) => returnIdToStackGroupId[return_id] !== undefined)
    .forEach((dataset) => {
      let value = 0
      const stackGroupId = Number(returnIdToStackGroupId[dataset.return_id])

      if (stack_groups[stackGroupId].include_only_base_items_with_attr) {
        if (onlyIncludedDataIdsFromAttr[stackGroupId].find((item) => item === dataset.return_id)) {
          value = dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0
        }
      } else {
        value = dataset.values.filter((v) => v !== null && v !== undefined)?.[0] ?? 0
      }

      if (!stackGroupTotal[stackGroupId]) {
        stackGroupTotal[stackGroupId] = 0
      }
      stackGroupTotal[stackGroupId] += value
    })
  let maxValue = Math.max(...Object.values(stackGroupTotal))
  if (maxValue < 0) {
    maxValue = 0
  }

  const idToValueMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]: dataset.values.filter((v) => v !== null && v !== undefined)?.[0],
    }),
    {}
  )
  const idToPercentageMap: Record<string, number> = aggregatedDatasets.reduce(
    (acc, dataset) => ({
      ...acc,
      [dataset.return_id]:
        ((dataset.values.filter((v) => v !== null && v !== undefined && v > 0)?.[0] ?? 0) / maxValue) * 100,
    }),
    {}
  )

  const titleToStackGroupToValueMap: Record<string, Record<string | number, number>> = {}
  const titleToStyleMap: Record<string, { color: string; dataType: string; unit: string }> = {}
  const itemTitles = Array.from(new Set(items.map((item) => item.title)))
  items.forEach((item) => {
    if (!titleToStackGroupToValueMap[item.title]) {
      titleToStackGroupToValueMap[item.title] = {}
    }
    if (!titleToStyleMap[item.title]) {
      titleToStyleMap[item.title] = {
        color: item.color ?? ``,
        dataType: data_type ?? item.data_type ?? ``,
        unit: item.unit ?? ``,
      }
    }

    titleToStackGroupToValueMap[item.title][item.stack_group] = idToValueMap[item.data_id] ?? 0
  })

  return (
    <div
      onMouseEnter={() => setShowState(true)}
      onMouseLeave={() => {
        setShowState(false)
      }}
    >
      {title && <TitleWithIcon title={title} size={`small`} padding={false} />}
      {stack_groups.map((stackGroup) => {
        const barItems = stackGroup.include_only_base_items_with_attr
          ? items.filter((item) => typeof stackGroup.id === 'number'
            && item.stack_group === stackGroup.id
            && onlyIncludedDataIdsFromAttr[stackGroup.id].find((id) => id === item.data_id))
          : items.filter((item) => item.stack_group === stackGroup.id)
        const total = stackGroupTotal[stackGroup.id] || 0
        let totalPercentage = (total / maxValue) * 100
        const typeObj = getTypeObject(total, data_type ?? dataType, itemUnit, decimals)

        if (totalPercentage < 0) {
          totalPercentage = 0
        }

        return (
          <div key={`barWrapper_${stackGroup.id}`}>
            <div className={styles.HorizontalBars_Bar_Title}>{stackGroup.title}</div>
            <div className={styles.HorizontalBars_Bar}>
              <div className={styles.HorizontalBars_Bar_Item_Wrapper}>
                {barItems
                  .map((item, itemIndex) => (
                    <div
                      key={`${stackGroup.id}_${item.data_id}_${itemIndex}`}
                      className={dynamicClassName({
                        animation: true,
                        [styles.HorizontalBars_Bar_Item]: true,
                      })}
                      style={{ width: `${idToPercentageMap[item.data_id] || 0}%`, backgroundColor: item.color }}
                    />
                  ))}
              </div>

              <div className={styles.HorizontalBars_Bar_Value + ` animation`} style={{ left: `${totalPercentage}%` }}>
                <div className={styles.HorizontalBars_Bar_Value_Value}>{typeObj.value}</div>
                <div className={styles.HorizontalBars_Bar_Value_Unit}>{typeObj.unit}</div>
              </div>
            </div>
          </div>
        )
      })}
      {showTable && (
        <div className={styles.HorizontalBars_Table}>
          <table>
            <thead>
              <tr>
                <th>{``}</th>
                {stack_groups.map((stackGroup) => (
                  <th key={`header_${stackGroup.id}`}>{stackGroup.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {itemTitles
                .filter((title) => {
                  for (let i = 0; i < stack_groups.length; i++) {
                    const stackGroup = stack_groups[i]
                    const value = titleToStackGroupToValueMap[title][stackGroup.id] || 0

                    if (value) {
                      return true
                    }
                  }
                  return false
                })
                .map((title) => {
                  const styling = titleToStyleMap[title]
                  const backgroundColor = styling.color
                  const dataType = styling.dataType
                  const unit = styling.unit
                  const decimals = 2

                  return (
                    <tr key={title}>
                      <td>
                        <div className={styles.HorizontalBars_Table_Dot} style={{ backgroundColor }} />
                        {title}
                      </td>
                      {stack_groups.map((stackGroup) => {
                        let value: string | number | null = titleToStackGroupToValueMap[title][stackGroup.id] ?? 0
                        if (typeof stackGroup.id === 'number' && stackGroup.include_only_base_items_with_attr) {
                          const theItem = items.find((item) => item.title === title && item.stack_group === stackGroup.id)
                          const dataIdsFromAttrForStackGroup = onlyIncludedDataIdsFromAttr[stackGroup.id]
                          const dataIdFromAttr = dataIdsFromAttrForStackGroup.find((data_id) =>
                            data_id === theItem?.data_id && theItem?.stack_group === stackGroup.id
                          )
                          if (theItem && !dataIdFromAttr) {
                            value = null
                          }
                        }
                        const typeObj = getTypeObject(
                          value,
                          dataType,
                          unit,
                          decimals
                        )
                        return (
                          <td className={styles.HorizontalBars_Table_Cell} key={`${title}_${stackGroup.id}`}>
                            <div className={styles.HorizontalBars_Bar_Value_Value}>{typeObj.value}</div>
                            <div className={styles.HorizontalBars_Bar_Value_Unit}>{typeObj.unit}</div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
