import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'


import SelectButton from 'api/SelectButton/SelectButton'
import {
  ConfigurableProperty,
  DeviationSettingLevel,
  useDigitalTwin,
  DigitalTwinPropertyObject,
  DigitalTwinSettingTarget,
  useDigitalTwinSettingsCreatedAtMetadata,
  useDigitalTwinSettingsActiveMetadata,
  DigitalTwinSettingMetadata,
} from 'api/digitalTwin/digitalTwin.api'
import { postStat } from 'api/stats/stats.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import { Card, Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import DigitalTwinEditSettingsModal from 'ui/components/DigitalTwinEditSettingsModal/DigitalTwinEditSettingsModal'
import { CreateSettingData } from 'ui/components/DigitalTwinSettingDialogContent/DigitalTwinSettingDialogContent'
import { TIME_SERIES_VISUALIZATION } from 'ui/components/DigitalTwinSettingForm/constants'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import styles from 'ui/components/ModuleHeader/ModuleHeader.module.less'
import PropertiesTable from 'ui/components/PropertiesTable/PropertiesTable'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import View from 'ui/components/View/View'
import ResetFilters from 'ui/molecules/ResetFilters/ResetFilters'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { ToggleButton } from 'ui/molecules/ToggleButton/ToggleButton'
import Datetime from 'utils/datetime/datetime'
import { getFilteredProperties, getPropertiesFromDigitalTwinModel, getPropertyTableItemsForSettingMetadata } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'
import { systemFeatureFlagActive } from 'utils/systemUtils'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  allFiltersSelected,
  getActiveSettingMetadataByProperty,
  getDictFromSettingMetadata,
  isSettingMetadataPriorityIncluded,
} from './DigitalTwinSettingsView.helper'
import CreateSettingModal from './components/CreateSettingModal/CreateSettingModal'
import MultiLevelDropdown from './components/DigitalTwinMultilevelDropdown/MultilevelDropdown'
import {
  MultilevelDropdownOptions,
  parseTagsFromProperties,
} from './components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'
import DigitalTwinSettingsCalendar from './components/DigitalTwinSettingsCalendar/DigitalTwinSettingsCalendar'
import DigitalTwinSettingsOverviewModal from './components/DigitalTwinSettingsOverview/DigitalSettingsOverviewModal'

const OptimizeSettingHeaderContentClass = `optSettingsHeader`

function setPropertyObject(
  list: DigitalTwinPropertyObject[],
  level: DeviationSettingLevel,
  properties?: DigitalTwinPropertyObject[]
): void {
  properties?.forEach((p) => {
    p.level = level
    list.push(p)
  })
}

export function getAllFilterTags(filters: MultilevelDropdownOptions): string[] {
  return Object.keys(filters).flatMap((topLevel) => filters[topLevel].map((subLevel) => `${topLevel}/${subLevel}`))
}

type DigitalTwinSettingsViewProps = {
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  showCalendar?: boolean
  title: string
  calendarTitle: string
  tableTitle: string
  isBaseSettings?: boolean
  permissions: SettingsPermissions
}

export default function DigitalTwinSettingsView({
  priorities,
  baseSettingPriorities,
  showCalendar = true,
  title,
  calendarTitle,
  tableTitle,
  isBaseSettings = false,
  permissions,
}: DigitalTwinSettingsViewProps): ReactElement {
  const periodStartTime = Datetime.getISONow()
  const periodEndTime = Datetime.addWeeks(periodStartTime, 1)
  const periodUpcomingStartTime = Datetime.addHours(periodStartTime, 1)

  const { t } = useTranslation()
  const history = useHistory()
  const { activeSystem, hasAccess } = useAuth()

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [])
  const [expandAllTableRows, setExpandAllTableRows] = useState(!!urlSearchParams.get('filter'))

  const [filterIsCollapsed, setFilterCollapsed] = useState(true)
  const [activeSetting, setActiveSetting] = useState<undefined | {
    level: DeviationSettingLevel
    name: string
    attribute?: string
  }>()
  const [settingsLoaded, setSettingsLoaded] = useState(false)
  const [createDeviationSettingData, setCreateDeviationSettingData] = useState<null | CreateSettingData>(null)
  const settingPropertyFilterEnabled = systemFeatureFlagActive(
    activeSystem,
    'digital_twin_setting_property_filter_enable'
  )

  //used to check if time series uiconfig exists for current system since time series charts and table functionality does not work at all without it
  const uiConfigTimeSeriesVisualization = useUiConfig(activeSystem?.id, TIME_SERIES_VISUALIZATION)
  const timeSeriesUiConfigExistsForCurrentSystem = !!(uiConfigTimeSeriesVisualization?.data?.length)

  const showOrEditGroupEnabled = systemFeatureFlagActive(activeSystem, 'edit_digital_twin_property_table_group')
  const primaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin, isLoading: loadingDigitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true)

  useEffect(() => {
    if (digitalTwin) {
      // Digital twin has changed, so we need to refetch settings. We show a loading spinner until new settings are loaded.
      setSettingsLoaded(false)
    }
  }, [digitalTwin])

  const allConfigurableProperties: ConfigurableProperty[] = useMemo(() => {
    const properties = digitalTwin?.model
      ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model)
      : []

    // If there are query parameters that match an existing property, set that as the active setting
    const level = urlSearchParams.get('level') as DeviationSettingLevel
    const name = urlSearchParams.get('name')
    const attribute = urlSearchParams.get('attribute')
    if (level && name && attribute) {
      const settingFromQuerystring = { level, name, attribute }
      for (const property of properties) {
        if (
          settingFromQuerystring?.level === property.level &&
          settingFromQuerystring?.name === property.name &&
          settingFromQuerystring?.attribute === property.attribute
        ) {
          setActiveSetting(settingFromQuerystring)
        }
      }
    }

    return properties
  }, [digitalTwin, urlSearchParams])

  const propertyItems = useMemo(() => {
    if (!digitalTwin?.model) {
      return []
    }

    const propertyObjects: DigitalTwinPropertyObject[] = []

    // model
    digitalTwin.model.name = digitalTwin.name
    digitalTwin.model.level = 'model' as DeviationSettingLevel
    propertyObjects.push(digitalTwin.model)

    // nodes & units
    digitalTwin.model.nodes.forEach((n) => {
      setPropertyObject(propertyObjects, 'unit', n.units)

      n.level = 'node' as DeviationSettingLevel
      propertyObjects.push(n)
    })

    // commodities & byproducts
    digitalTwin.model.commodities.forEach((c) => {
      setPropertyObject(propertyObjects, 'byproduct', c.byproducts)

      c.level = 'commodity' as DeviationSettingLevel
      propertyObjects.push(c)
    })

    // exchanges
    setPropertyObject(propertyObjects, 'exchange', digitalTwin.model.exchanges)

    // balances
    setPropertyObject(propertyObjects, 'balance', digitalTwin.model.balances)

    return propertyObjects
  }, [digitalTwin?.model, digitalTwin?.name])

  const filters: MultilevelDropdownOptions = useMemo(
    () => parseTagsFromProperties(allConfigurableProperties, priorities),
    [allConfigurableProperties, priorities]
  )

  // filterTags is a list of filters on the form "top_level/sub_level"
  const allFilterTags = useMemo(() => getAllFilterTags(filters), [filters])
  const [filterTags, setFilterTags] = useState<string[]>([])
  const [tagsLoaded, setTagsLoaded] = useState(false)

  useEffect(() => {
    const filterSearchParam = urlSearchParams.get('filter')
    if (filterSearchParam) {
      // TODO: This is a temporary solution to handle the case for Exergi where they only
      // want to filter on the 'component/' tags. This should be generalized if we want to
      // keep this feature.
      const matchingProperties = allConfigurableProperties.filter((p) =>
        p.name?.toLowerCase() === filterSearchParam.toLowerCase())
      const tagPrefixToMatch = 'component/'
      const matchingComponentTags = matchingProperties
        .map((p) => p.tags ?? [])
        .flat()
        .filter((t) => t.startsWith(tagPrefixToMatch))
      const tagsToSet = allFilterTags.filter((t) => !t.startsWith(tagPrefixToMatch) || matchingComponentTags.includes(t))
      setFilterTags(tagsToSet)

      history.replace(history.location.pathname, { shallow: true })
    }
    else if (filters) {
      setFilterTags(allFilterTags)
    }

    setTagsLoaded(filters && allFilterTags.length > 0)
  }, [filters, allFilterTags, allConfigurableProperties, urlSearchParams, history])

  const [filteredProperties] = useMemo<ConfigurableProperty[][]>(
    () => getFilteredProperties(allConfigurableProperties, priorities, filterTags, baseSettingPriorities),
    [allConfigurableProperties, filterTags, priorities, baseSettingPriorities]
  )

  const resetFilters = useCallback((): void => {
    setFilterTags(allFilterTags)
    setExpandAllTableRows(false)
  }, [allFilterTags])

  // Fetch settings using the filteredProperties.
  const [settingMetadataCreatedAt, setSettingMetadataCreatedAt] = useState<DigitalTwinSettingMetadata[]>([])
  const [settingMetadataActive, setSettingMetadataActive] = useState<DigitalTwinSettingMetadata[]>([])
  const [settingMetadataActiveUpcoming, setSettingMetadataActiveUpcoming] = useState<DigitalTwinSettingMetadata[]>([])

  // Make sure we have separate `isLoading` fields for base and deviation settings.
  // If we use the same mutation for both, `isLoading` will be false when the first one is done, even if the second one is still loading.
  const { mutateAsync: fetchSettingMetadataCreatedAt, status: loadingSettingMetadataCreatedAtStatus } = useDigitalTwinSettingsCreatedAtMetadata()
  const { mutateAsync: fetchSettingMetadataActive, status: loadingSettingMetadataActiveStatus } = useDigitalTwinSettingsActiveMetadata()
  const { mutateAsync: fetchSettingMetadataActiveUpcoming, status: loadingSettingMetadataActiveUpcomingStatus } = useDigitalTwinSettingsActiveMetadata()

  // Setting & Base settings - Created At
  const refetchSettingMetadataCreatedAt = useCallback(() => {
    if (loadingDigitalTwin) {
      return
    }
    fetchSettingMetadataCreatedAt({
      digitalTwinUid: digitalTwin?.uid,
      priorities: priorities,
    }).then(setSettingMetadataCreatedAt)
  }, [
    digitalTwin?.uid,
    priorities,
    fetchSettingMetadataCreatedAt,
    loadingDigitalTwin,
  ])
  useEffect(refetchSettingMetadataCreatedAt, [refetchSettingMetadataCreatedAt])

  // Setting & Base settings - Currenrtly Active
  const refetchSettingMetadataActive = useCallback(() => {
    if (loadingDigitalTwin) {
      return
    }
    fetchSettingMetadataActive({
      digitalTwinUid: digitalTwin?.uid,
      priorities: baseSettingPriorities ? [...baseSettingPriorities, ...priorities] : priorities,
      startTime: periodStartTime,
      endTime: periodStartTime,
    }).then(setSettingMetadataActive)
  }, [
    digitalTwin?.uid,
    priorities,
    baseSettingPriorities,
    fetchSettingMetadataActive,
    periodStartTime,
    loadingDigitalTwin,
  ])
  useEffect(refetchSettingMetadataActive, [refetchSettingMetadataActive])

  // Setting - Active Upcoming
  const refetchSettingMetadataActiveUpcoming = useCallback(() => {
    if (loadingDigitalTwin || isBaseSettings) {
      return
    }
    fetchSettingMetadataActiveUpcoming({
      digitalTwinUid: digitalTwin?.uid,
      priorities: priorities,
      startTime: periodStartTime,
      endTime: periodEndTime,
      startFrom: periodUpcomingStartTime,
    }).then(setSettingMetadataActiveUpcoming)
  }, [
    digitalTwin?.uid,
    priorities,
    fetchSettingMetadataActiveUpcoming,
    periodStartTime,
    periodEndTime,
    periodUpcomingStartTime,
    loadingDigitalTwin,
    isBaseSettings,
  ])
  useEffect(refetchSettingMetadataActiveUpcoming, [refetchSettingMetadataActiveUpcoming])

  // Setting objects
  const settingMetadataCreatedAtDict = useMemo(() => getDictFromSettingMetadata(settingMetadataCreatedAt), [settingMetadataCreatedAt])
  const activeSettingByProperty = useMemo(() => getActiveSettingMetadataByProperty(settingMetadataActive, priorities), [settingMetadataActive, priorities])
  const upcomingSettingByProperty = useMemo(() => getActiveSettingMetadataByProperty(settingMetadataActiveUpcoming, priorities), [settingMetadataActiveUpcoming, priorities])

  // Base settings
  const baseSettingMetadataActive = useMemo(() => {
    if (baseSettingPriorities) {
      return settingMetadataActive.filter((settingMetadata) => isSettingMetadataPriorityIncluded(settingMetadata, baseSettingPriorities))
    } else {
      return []
    }
  }, [baseSettingPriorities, settingMetadataActive])
  const activeBaseSettingByProperty = useMemo(() => {
    if (baseSettingPriorities) {
      return getActiveSettingMetadataByProperty(baseSettingMetadataActive, baseSettingPriorities)
    } else {
      return {}
    }
  }, [baseSettingPriorities, baseSettingMetadataActive])

  // We want to show a loding spinner for every property in the table from when the page is opened until all settings are loaded.
  // There is a moment between the digital twin is loaded until the settings start loading, and we still want to show
  // the spinner in that moment.
  // So we keep it as a state variable instead of just looking at (loadingDigitalTwin || loadingBaseSettings || loadingSettings).
  useEffect(() => {
    if (
      !settingsLoaded &&
      !loadingDigitalTwin &&
      loadingSettingMetadataCreatedAtStatus === 'success' &&
      loadingSettingMetadataActiveStatus === 'success' &&
      loadingSettingMetadataActiveUpcomingStatus === 'success'
    ) {
      setSettingsLoaded(true)
    }
  }, [
    settingsLoaded,
    loadingDigitalTwin,
    loadingSettingMetadataCreatedAtStatus,
    loadingSettingMetadataActiveStatus,
    loadingSettingMetadataActiveUpcomingStatus,
  ])

  const data = useMemo(
    () =>
      getPropertyTableItemsForSettingMetadata({
        activeBaseSettingByProperty,
        activeSettingByProperty,
        digitalTwin,
        filteredProperties,
        isBaseSettings,
        periodStartTime,
        periodEndTime,
        canEdit: permissions.canEdit,
        t,
        upcomingSettingByProperty,
        setActiveSetting,
        loading: !settingsLoaded,
        settingMetadataCreatedAtDict,
      }),
    [
      activeBaseSettingByProperty,
      activeSettingByProperty,
      digitalTwin,
      filteredProperties,
      isBaseSettings,
      periodStartTime,
      periodEndTime,
      permissions.canEdit,
      t,
      upcomingSettingByProperty,
      settingsLoaded,
      settingMetadataCreatedAtDict,
    ]
  )

  // If feature flag is turned off, we want onEditGroup to be undefined so that the edit button is hidden.
  // useCallback cannot return undefined, so we need to wrap it in useMemo.
  const onEditGroup = useMemo(
    () =>
      showOrEditGroupEnabled
        ? (meta: DigitalTwinSettingTarget) => {
          setActiveSetting({
            level: meta.level,
            name: meta.name,
          })
        }
        : undefined,
    [showOrEditGroupEnabled]
  )

  const onCreateSettingModalClose = useCallback(() => {
    setCreateDeviationSettingData(null)
  }, [])

  // Clear any query parameters without reloading the page. This is needed due to user possibly coming from "Production plan" accumulator edit redirect (which sets query params in URL upon redirect)
  const onDigitalTwinEditSettingsModalClose = useCallback(() => {
    history.push(history.location.pathname, { shallow: true })
    setActiveSetting(undefined)
  }, [history])

  // The settings are modified or created in children modal.
  // The toggle is used to notify other children components that also need to refetch settings.
  const [refetchTriggerToggle, setRefetchTriggerToggle] = useState(false)
  const refetchSettingsOnClose = useCallback(() => {
    refetchSettingMetadataCreatedAt()
    refetchSettingMetadataActive()
    if (!isBaseSettings) {
      refetchSettingMetadataActiveUpcoming()
    }

    setRefetchTriggerToggle((prev) => !prev)
  }, [
    isBaseSettings,
    refetchSettingMetadataActive,
    refetchSettingMetadataCreatedAt,
    refetchSettingMetadataActiveUpcoming,
  ])

  const onMultiLevelDropdownSelected = useCallback((selectedTags: string[]) => {
    setFilterTags(selectedTags)
    setExpandAllTableRows(false)
  }, [])

  const onClickFilterCollapsed = useCallback(() => {
    postStat('/digitalTwinDeviationSettings', 'toggle-filter')

    setFilterCollapsed(!filterIsCollapsed)
  }, [filterIsCollapsed])

  const filterIcon = allFiltersSelected(filterTags, allFilterTags) ? '' : <Icon tooltip={{ title: t('Filters active'), placement: 'right' }} icon="fas fa-filter" />
  
  if (loadingDigitalTwin) {
    return <></>
  }

  if (!digitalTwin) {
    return <></>
  }

  return (
    <View
      header={
        <div className={OptimizeSettingHeaderContentClass}>
          <ModuleHeader
            infoHeader={title}
            includePropertyFilter={false}
            secondaryChildren={!filterIsCollapsed && tagsLoaded && ( // Avoid showing unchecked checkboxes while the filters are still loading
              <MultiLevelDropdown
                translations={digitalTwin.translations}
                options={filters}
                onSelected={onMultiLevelDropdownSelected}
                values={filterTags}
                hiddenFilters={digitalTwin.model.hidden_default_tags}
                topLevelSortingOrder={digitalTwin.model.tag_sorting_order}
                items={allConfigurableProperties.map((p) => p.tags ?? [])}
              />
            )}
          >
            {hasAccess({ submodule: 'optimizesettings_filter', module: 'optimizesettings' }) && (
              <div className={styles.ModuleHeader_FilterButtonContainer}>
                <ToggleButton
                  isCollapsed={filterIsCollapsed}
                  onClick={onClickFilterCollapsed}
                >
                  <span>{t('Filter')} {filterIcon}</span>

                </ToggleButton>
              </div>
            )}
            <Grid container direction="row" justifyContent="flex-end">
              {hasAccess({ submodule: 'optimizesettings_time_series', module: 'optimizesettings' }) && timeSeriesUiConfigExistsForCurrentSystem
                ? (
                  <SelectButton
                    icon="fal fa-plus"
                    primary
                    disabled={!permissions.canEdit}
                    disabledTooltip={t(
                      'Unfortunately, you do not have permission to create settings on this user/facility'
                    )}
                    menuItems={[
                      {
                        label: t('Setting'),
                        onClick: () =>
                          setCreateDeviationSettingData({
                            isTimeSeries: false,
                          }),
                      },
                      {
                        label: t('Time series setting'),
                        onClick: () =>
                          setCreateDeviationSettingData({
                            value: [],
                            isTimeSeries: true,
                          }),
                      },
                    ]}
                  >
                    {t(`Create new setting`)}
                  </SelectButton>
                )
                : (
                  <Button
                    primary
                    onClick={() =>
                      setCreateDeviationSettingData({
                        isTimeSeries: false,
                      })
                    }
                    disabled={!permissions.canEdit}>
                    {t(`Create new setting`)}
                  </Button>
                )
              }
            </Grid>
          </ModuleHeader>
        </div>
      }
      stickyHeader
    >
      {filteredProperties.length > 0 ? (
        <>
          {showCalendar && (
            <Card width="100%" collapsible rememberCollapsed margin title={<TitleWithIcon title={calendarTitle} />}>
              <DigitalTwinSettingsCalendar
                digitalTwin={digitalTwin}
                onEditDeviationSetting={setting => {
                  if (setting) {
                    const value = typeof setting.value === 'string' ? Number(setting.value) : setting.value
                    setCreateDeviationSettingData({
                      ...setting,
                      value: value,
                    })
                  } else {
                    setCreateDeviationSettingData(null)
                  }
                }}
                configurableProperties={filteredProperties}
                filteredProperties={filteredProperties}
                settingPriortyLevels={activeSystem?.setting_priority_levels}
                activeBaseSettings={baseSettingMetadataActive}
                settingPropertyFilterEnabled={settingPropertyFilterEnabled}
                refetchTriggerToggle={refetchTriggerToggle}
              />
            </Card>
          )}
          <Card width="100%" margin title={<TitleWithIcon title={tableTitle} />}>
            <PropertiesTable
              dataIn={data}
              translations={digitalTwin.translations}
              priorities={priorities}
              permissions={permissions}
              editGrouping={onEditGroup}
              isBaseSettings={isBaseSettings}
              expanded={expandAllTableRows ? expandAllTableRows : undefined} // MaterialReactTable uses undefined to not expand all rows
            />
          </Card>
        </>
      ) : (
        tagsLoaded && (
          <ResetFilters onClick={resetFilters} />
        )
      )}

      {activeSetting && activeSetting.attribute === undefined && (
        <DigitalTwinSettingsOverviewModal
          initData={activeSetting}
          propertyItems={propertyItems}
          digitalTwin={digitalTwin}
          onClose={() => setActiveSetting(undefined)}
          onSettingModified={refetchSettingsOnClose}
          priorities={priorities}
          activeSettingMetadataDict={activeSettingByProperty}
          activeBaseSettingMetadataDict={activeBaseSettingByProperty}
        />
      )}
      {activeSetting && activeSetting.attribute && (
        <DigitalTwinEditSettingsModal
          configurableProperties={filteredProperties}
          level={activeSetting?.level}
          attribute={activeSetting?.attribute}
          name={activeSetting?.name}
          onClose={onDigitalTwinEditSettingsModalClose}
          digitalTwin={digitalTwin}
          permissions={permissions}
          priorities={priorities}
          baseSettingPriorities={baseSettingPriorities}
          onSettingModified={refetchSettingsOnClose}
          isBaseSettings={isBaseSettings}
        />
      )}
      {createDeviationSettingData && (
        <CreateSettingModal
          allowEmpty
          initData={createDeviationSettingData}
          configurableProperties={filteredProperties}
          digitalTwin={digitalTwin}
          onClose={onCreateSettingModalClose}
          onSettingModified={refetchSettingsOnClose}
          propertyItems={propertyItems}
          priorities={priorities}
          baseSettingPriorities={baseSettingPriorities}
          isBaseSettings={isBaseSettings}
        />
      )}
    </View>
  )
}
