import { DigitalTwinSetting, DigitalTwinTranslations } from 'api/digitalTwin/digitalTwin.api'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

export const calculateSearchScore = (key: string, lowerSearchTerm: string): number => {
  const lowerKey = key.toLowerCase()

  if (lowerKey === lowerSearchTerm) {
    return 3 // Exact match
  } else if (lowerKey.startsWith(lowerSearchTerm)) {
    return 2 // Starts with search term
  } else if (lowerKey.includes(lowerSearchTerm)) {
    return 1 // Contains search term
  } else {
    return 0 // No match
  }
}

export const getGroupedSettingsMatchingSearchTerm = (searchTerm: string,  groupedSettings: [string, DigitalTwinSetting[]][], translations?: DigitalTwinTranslations | undefined): [string, DigitalTwinSetting[]][] => {
  const searchTermLower = searchTerm.toLowerCase()

  const sortedEntries = groupedSettings.map((entry) => {
    const prettyName = getPrettyName(entry[0], translations).toLowerCase()
    const groupScore = calculateSearchScore(prettyName, searchTermLower)
    return { group: entry, score: groupScore }
  }).sort(({ score: scoreA }, { score: scoreB }) => { 
    return scoreB - scoreA // Sort in descending order of score
  })

  return sortedEntries.filter(e => e.score > 0).map(({ group }) => group)
}

export const getGroupedSettings = (digitalTwinSettings: DigitalTwinSetting[], language: string | null, translations?: DigitalTwinTranslations | undefined): [string, DigitalTwinSetting[]][] => {
  // Group settings by name
  const grouped = new Map<string, DigitalTwinSetting[]>()
  digitalTwinSettings.forEach((setting) => {
    const name = setting.name || ''
    if (!grouped.has(name)) {
      grouped.set(name, [])
    }
    grouped.get(name)?.push(setting)
  })

  // Sort each group by name
  grouped.forEach((group) => {
    group.sort((a: DigitalTwinSetting, b: DigitalTwinSetting): number => {
      if (a.name && b.name) {
        const nameA = getPrettyName(a.name, translations)
        const nameB = getPrettyName(b.name, translations)
        return nameA.localeCompare(nameB, language ?? 'sv')
      }
      return 0
    })
  })

  // Sort the grouped settings by key
  const sortedGroupedSettings = new Map(
    Array.from(grouped.entries()).sort(([keyA], [keyB]) =>{
      const nameA = getPrettyName(keyA, translations)
      const nameB = getPrettyName(keyB, translations)
      return nameA.localeCompare(nameB, language ?? 'sv')
    })
  )

  return Array.from(sortedGroupedSettings.entries())
}