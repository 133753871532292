import { apiClient } from 'api/apiClient/apiClient'
import { OptJobInputData, OptJobType } from 'api/optJobs/optJobs.api'
import { DefaultPageNumberPagination } from 'api/types'
import alertStore from 'store/alert/alert'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'

import { queryClient } from 'helpers/queryClient'

import { BASE_SETTING_PRIORITIES, DEVIATION_SETTING_PRIORITIES } from './digitalTwinConstants'

export type DeviationSettingLevel = 'model' | 'node' | 'unit' | 'exchange' | 'balance' | 'commodity' | 'byproduct'

export type DigitalTwinTranslations = { [key: string]: string }

export type DigitalTwinTimeSeriesSetting = {
  id: number
  created_at: ISODateTime | null
  start_time: ISODateTime | null
  end_time: ISODateTime | null
  comment: string
  settings: DigitalTwinSetting[]
  creator_name?: string
}

export type DeviationSettingValue = number | null | { id: number; value: number | null }[]

export type DigitalTwinSetting = {
  id: number
  digital_twin_id: number
  level: DeviationSettingLevel
  name: string
  updated_at: ISODateTime | null
  attribute: string
  start_time: ISODateTime | null
  end_time: ISODateTime | null
  comment: string
  priority: number
  value?: DeviationSettingValue
  deleted_at?: ISODateTime | null
  isTimeSeries: boolean
  unit?: string
  creator_name?: string
  operational_event?: number | null
  time_series_setting?: number | null
}

export type DigitalTwinSettingMetadata = {
  level: DeviationSettingLevel,
  name: string,
  attribute: string,
  created_at?: ISODateTime,
  priority?: number,
  value?: number | null,
  start_time?: ISODateTime,
  end_time?: ISODateTime,
  is_time_series?: boolean,
}

export type TranslatedDeviationSetting = Omit<DigitalTwinSetting, 'value'> & { value?: DeviationSettingValue | string }

export type DigitalTwinSettingTarget = {
  level: DeviationSettingLevel
  name: string
  attribute: string
}

export type ConfigurableProperty = DigitalTwinSettingTarget & {
  display_name?: string
  group?: string
  min_value?: number | null
  max_value?: number | null
  measurement_unit?: string
  data_type?: string
  tags?: string[]
  available_priorities?: number[]
  default_value?: number | null
}

export type AccumulatorInfo = {
  unit_name: string
  start_level_base_setting_value: number
  end_level_base_setting_value: number
  start_level_deviation_setting_value: number
  end_level_deviation_setting_value: number
  start_level_meas_value: number
  start_min_level?: number
  start_max_level?: number
  end_min_level?: number
  end_max_level?: number
}

export type DigitalTwinPropertyObject = {
  name: string
  level: DeviationSettingLevel
  attribute: string
  display_name?: string
  tags?: string[]
  configurable_properties?: ConfigurableProperty[]
  measurement_unit?: string | undefined
  available_priorities?: number[]
}

export type Balance = DigitalTwinPropertyObject & {
  add_to_demand: number
  commodity: string
}

export type Exchange = DigitalTwinPropertyObject & {
  balance_to: string
  balance_from: string
  max_transfer: number
}

export type Commodity = DigitalTwinPropertyObject & {
  byproducts: DigitalTwinPropertyObject[]
  is_el: boolean
  cost: number
}

export type DigitalTwinUnit = DigitalTwinPropertyObject & {
  inputs?: { [name: string]: { [attribute: string]: string | number } }
  outputs?: { [name: string]: { [attribute: string]: string | number } }
} & {
  [attribute: string]: number | string | (DigitalTwinNode | DigitalTwinUnit | ConfigurableProperty)[]
}

export type DigitalTwinNode = DigitalTwinPropertyObject & {
  units?: DigitalTwinUnit[]
} & {
  [attribute: string]: number | string | (DigitalTwinNode | DigitalTwinUnit | ConfigurableProperty)[]
}

export type DigitalTwinModel = DigitalTwinPropertyObject & {
  nodes: DigitalTwinNode[]
  balances: Balance[]
  exchanges: Exchange[]
  commodities: Commodity[]
  tag_sorting_order?: string[]
  hidden_default_tags?: string[]
  all_tags?: string[]
  all_tags_dict?: { [key: string]: string[] }
  show_model_node?: boolean
}

export type DigitalTwin = {
  uid: number
  id: number
  name: string
  display_name: string
  version: number
  description?: string
  created_at: ISODateTime
  updated_at: ISODateTime
  model: DigitalTwinModel
  created_by?: number
  system?: number
  layout?: DigitalTwinLayout
  translations?: DigitalTwinTranslations
}

export type DigitalTwinLayout = { [name: string]: { x: number; y: number } }

export function useDigitalTwin(
  uid?: number,
  include_inherited_tags?: boolean,
  include_all_tags?: boolean
): UseQueryResult<DigitalTwin | undefined> {
  return useQuery(['get_digital_twin', uid, include_inherited_tags, include_all_tags], async () => {
    if (uid === undefined || uid === null) {
      return undefined
    }

    const params: { include_inherited_tags?: number; include_all_tags?: number } = {}
    if (include_inherited_tags) {
      params.include_inherited_tags = 1
    }
    if (include_all_tags) {
      params.include_all_tags = 1
    }
    return await apiClient<DigitalTwin[]>(`digital_twin/${uid}`, { params })
  })
}

export function useAllDigitalTwins(
  systemId: number | undefined,
  pageNumber: number,
  include_inherited_tags?: boolean
): UseQueryResult<DefaultPageNumberPagination<DigitalTwin[]>> {
  return useQuery(['list_all_latest_digital_twins', systemId, include_inherited_tags, pageNumber], async () => {
    if (systemId === null || systemId === undefined) {
      return { next: null, previous: null, results: [] }
    }

    const params: { page: number; system_id?: number; include_inherited_tags?: string } = {
      page: pageNumber,
      system_id: systemId,
    }

    if (include_inherited_tags) {
      params['include_inherited_tags'] = '1'
    }

    return await apiClient<DefaultPageNumberPagination<DigitalTwin[]>>('digital_twins', { params })
  })
}

export function useDigitalTwinVersions(uid?: number, limit?: number): UseQueryResult<DigitalTwin[]> {
  return useQuery(['get_digital_twin_versions', uid, limit], async () => {
    const params = limit !== undefined ? { limit: limit } : {}

    return await apiClient<DigitalTwin[]>(`digital_twin/${uid}/versions`, { params })
  })
}

export type DigitalTwinUpdateRequest = {
  name: string
  display_name?: string
  model: DigitalTwinModel | Record<string, never> //django requires this to exist when sending post (but is okay with an empty object)
  layout?: DigitalTwinLayout
  description?: string
}

export type UpdateDigitalTwinParams = {
  uid: number
  data: DigitalTwinUpdateRequest
}

export function useUpdateDigitalTwin(): UseMutationResult<DigitalTwin, unknown, UpdateDigitalTwinParams, unknown> {
  const { t } = useTranslation()
  return useMutation(
    ({ uid, data }: UpdateDigitalTwinParams) =>
      apiClient<DigitalTwin>(`digital_twin/${uid}/update`, { method: 'PUT', data: data }),
    {
      onError: () => {
        alertStore.error(t('Failed to save digital twin'))
      },
      onSuccess: () => {
        alertStore.success(t('Digital twin saved successfully'))
      },
    }
  )
}

export type DigitalCreationRequest = DigitalTwinUpdateRequest & {
  system_id?: number
}

export type CreateDigitalTwinParams = {
  data: DigitalCreationRequest
}

export function useCreateDigitalTwin(): UseMutationResult<DigitalTwin, unknown, CreateDigitalTwinParams, unknown> {
  return useMutation(({ data }: CreateDigitalTwinParams) =>
    apiClient<DigitalTwin>('digital_twin', { method: 'POST', data })
  )
}

export type DigitalTwinOptJobCreationRequest = {
  opt_job_type: OptJobType
  input_data: OptJobInputData
}

export type CreateDigitalTwinOptJobParams = {
  uid: number
  data: DigitalTwinOptJobCreationRequest
}

export function useCreateDigitalTwinOptJob(): UseMutationResult<
  DigitalTwin,
  unknown,
  CreateDigitalTwinOptJobParams,
  unknown
  > {
  return useMutation(({ uid, data }: CreateDigitalTwinOptJobParams) =>
    apiClient<DigitalTwin>(`digital_twin/${uid}/queue_opt_job`, { method: 'POST', data })
  )
}

export function useConfigurablePropertys(uid?: number): UseQueryResult<ConfigurableProperty[]> {
  return useQuery(['get_configurable_digital_twin_properties', uid], async () => {
    if (!uid) {
      return []
    }

    return await apiClient<ConfigurableProperty[]>(`digital_twin/${uid}/configurable_properties`)
  })
}

export type FetchSettingsParams = {
  digitalTwinUid?: number
  properties?: DigitalTwinSettingTarget[]
  priorities?: SystemSettingPriorityLevel[]
  activeFrom?: ISODateTime | null
  activeTo?: ISODateTime | null
  usePropertyFiltersList?: boolean
  operational_event?: number | null
  operational_event_include_inactive?: boolean
}

export function useDigitalTwinSettings(): UseMutationResult<
  DigitalTwinSetting[],
  unknown,
  FetchSettingsParams,
  unknown
  > {
  const { activeSystem } = useAuth()

  return useMutation(
    async ({
      digitalTwinUid,
      properties,
      priorities,
      activeFrom,
      activeTo,
      usePropertyFiltersList,
      operational_event,
      operational_event_include_inactive = false,
    }: FetchSettingsParams): Promise<DigitalTwinSetting[]> => {
      const uid = digitalTwinUid ?? activeSystem?.primary_digital_twin?.uid
      if (!uid) {
        return Promise.resolve([])
      }

      const propertyFilters =
        properties
          ?.filter((property) => property.level && property.name && property.attribute)
          ?.map((property) => ({
            level: property.level,
            name: property.name,
            attribute: property.attribute,
          })) ?? []

      const params: {
        priority?: number[]
        start_time?: string
        end_time?: string
        use_property_filters_list?: boolean
        operational_event?: number
        operational_event_include_inactive?: boolean
      } = {}

      if (activeFrom) {
        params['start_time'] = activeFrom
      }

      if (activeTo) {
        params['end_time'] = activeTo
      }

      if (priorities) {
        params['priority'] = priorities.map((priority) => priority.priority_level)
      }

      if (usePropertyFiltersList) {
        params['use_property_filters_list'] = usePropertyFiltersList
      }

      if (operational_event) {
        params['operational_event'] = operational_event
      }

      if (operational_event_include_inactive) {
        params['operational_event_include_inactive'] = operational_event_include_inactive
      }

      const [result1, result2] = await Promise.allSettled([
        apiClient<DigitalTwinSetting[]>(`digital_twin/${uid}/settings`, {
          method: 'POST',
          data: { property_filters: propertyFilters },
          params,
          addBracketsToArrayParamKeys: false,
        }),
        apiClient<DigitalTwinTimeSeriesSetting[]>(`digital_twin/${uid}/time_series_settings`, {
          method: 'POST',
          data: { property_filters: propertyFilters },
          params,
          addBracketsToArrayParamKeys: false,
        }),
      ]).then((results) => {
        return results.map((result) =>
          result.status === 'fulfilled' ? result.value : []
        )
      })

      const settingsToReturn: DigitalTwinSetting[] = [...result1, ...result2].map((setting_data) => {
        if (isDigitalTwinSetting(setting_data)) {
          return setting_data
        } else if (isDigitalTwinTimeSeriesSetting(setting_data)) {
          const digitalTwinSetting = {
            id: setting_data.id,
            digital_twin_id: setting_data.settings[0].digital_twin_id,
            level: setting_data.settings[0].level,
            name: setting_data.settings[0].name,
            updated_at: setting_data.created_at,
            attribute: setting_data.settings[0].attribute,
            start_time: setting_data.start_time,
            end_time: setting_data.end_time,
            comment: setting_data.comment,
            creator_name: setting_data.creator_name,
            priority: setting_data.settings[0].priority,
            value: setting_data.settings.map((time_series_setting) => ({
              id: time_series_setting.id,
              value: (time_series_setting.value ?? null) as number | null,
            })),
            isTimeSeries: true,
            operational_event: setting_data.settings[0].operational_event,
          }
          return digitalTwinSetting
        } else {
          throw new Error('Unexpected type for time_series')
        }
      })

      return settingsToReturn
    }
  )
}

// Type guard to check if the object is a DigitalTwinSetting
function isDigitalTwinSetting(obj: any): obj is DigitalTwinSetting {
  return 'value' in obj && 'time_series_setting' in obj
}

// Type guard to check if the object is a DigitalTwinTimeSeriesSetting
function isDigitalTwinTimeSeriesSetting(obj: any): obj is DigitalTwinTimeSeriesSetting {
  return 'settings' in obj && Array.isArray(obj.settings)
}

export type FetchSettingMetadataParams = {
  digitalTwinUid?: number
  startTime?: ISODateTime | null
  endTime?: ISODateTime | null
  startFrom?: ISODateTime | null
  priorities?: SystemSettingPriorityLevel[]
}

function getDigitalTwinSettingsMetadataParams({
  startTime,
  endTime,
  startFrom,
  priorities,
}: FetchSettingMetadataParams): {
  priority?: number[]
  start_time?: string
  end_time?: string
  } {
  const params: {
      start_time?: string
      end_time?: string
      start_from?: string,
      priority?: number[]
    } = {}

  if (startTime) {
    params['start_time'] = startTime
  }

  if (endTime) {
    params['end_time'] = endTime
  }

  if (startFrom) {
    params['start_from'] = startFrom
  }

  if (priorities) {
    params['priority'] = priorities.map((priority) => priority.priority_level)
  }

  return params
}

export function useDigitalTwinSettingsCreatedAtMetadata(): UseMutationResult<
  DigitalTwinSettingMetadata[],
  unknown,
  FetchSettingMetadataParams,
  unknown
  > {
  const { activeSystem } = useAuth()

  return useMutation((metadataParams: FetchSettingMetadataParams): Promise<DigitalTwinSettingMetadata[]> => {
    const uid = metadataParams.digitalTwinUid ?? activeSystem?.primary_digital_twin?.uid
    if (!uid) {
      return Promise.resolve([])
    }

    const params = getDigitalTwinSettingsMetadataParams(metadataParams)
    return apiClient<DigitalTwinSettingMetadata[]>(
      `digital_twin/${uid}/settings/metadata/created_at`, {
        method: 'GET',
        params,
        addBracketsToArrayParamKeys: false,
      }
    )
  }
  )
}

export function useDigitalTwinSettingsActiveMetadata(): UseMutationResult<
  DigitalTwinSettingMetadata[],
  unknown,
  FetchSettingMetadataParams,
  unknown
  > {
  const { activeSystem } = useAuth()

  return useMutation((metadataParams: FetchSettingMetadataParams): Promise<DigitalTwinSettingMetadata[]> => {
    const uid = metadataParams.digitalTwinUid ?? activeSystem?.primary_digital_twin?.uid
    if (!uid) {
      return Promise.resolve([])
    }

    const params = getDigitalTwinSettingsMetadataParams(metadataParams)
    return apiClient<DigitalTwinSettingMetadata[]>(
      `digital_twin/${uid}/settings/metadata/active`, {
        method: 'GET',
        params,
        addBracketsToArrayParamKeys: false,
      }
    )
  }
  )
}

type SettingCreationRequestBase = {
  level: DeviationSettingLevel
  name: string // object name / target, e.g. "p1"
  attribute: string // property name, e.g. "availability"
  comment: string
  priority: number
}

export type TimeSeriesSettingValue = { [time: ISODateTime]: { id?: number; value: number | null } }

export type DigitalTwinSettingCreationRequest = SettingCreationRequestBase & {
  start_time: ISODateTime | null
  end_time: ISODateTime | null
  value?: number | null | TimeSeriesSettingValue
  deleted_at?: ISODateTime | null
  isTimeSeries: boolean
  unit: string
  isBaseSetting: boolean
  operational_event?: number | null
}

export type CreateDigitalTwinSettingParams = {
  overwrite: boolean
  digitalTwinUid: number
  data: DigitalTwinSettingCreationRequest
}

export type DigitalTwinSettingCreationResult = {
  overlapping: DigitalTwinSetting[]
  created?: DigitalTwinSetting | null
}

export type TimeSeriesSettingsCreationResult = {
  overlapping_settings: DigitalTwinSetting[]
  overlapping_time_series_settings: DigitalTwinTimeSeriesSetting[]
  time_series?: DigitalTwinTimeSeriesSetting | null
}

function setSettingRequestMinutes(requestData: DigitalTwinSettingCreationRequest | TimeSeriesSettingCreationRequest) {
  // When modifying settings, we sometimes set the minutes of the end time to 59 for presentation purposes.
  // The backend expects all times to be on the hour, so we need to reset the minutes to 00.
  // A setting "00:00 - 23:59" is "00:00 - 23:00" in the backend.
  const newStartTime = requestData.start_time === null ? null : Datetime.ISODatetimeTo00Minutes(requestData.start_time)
  const newEndTime = requestData.end_time === null ? null : Datetime.ISODatetimeTo00Minutes(requestData.end_time)
  return { ...requestData, start_time: newStartTime, end_time: newEndTime }
}

export function useCreateDigitalTwinSetting(): UseMutationResult<
  DigitalTwinSettingCreationResult,
  unknown,
  CreateDigitalTwinSettingParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ digitalTwinUid, data, overwrite }: CreateDigitalTwinSettingParams) => {

      return apiClient<DigitalTwinSettingCreationResult>(`digital_twin/${digitalTwinUid}/setting`, {
        method: 'POST',
        data: setSettingRequestMinutes(data),
        params: { overwrite: overwrite ? 1 : 1 },
      })
    },
    {
      onSuccess: (data, params) => {
        if (data?.created) {
          queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
          if (!params.data.isBaseSetting) {
            alertStore.success(t('Deviation setting created successfully'))
          } else {
            alertStore.success(t('Base setting created successfully'))
          }
        } else if (data?.overlapping.find((overlapping) => overlapping.time_series_setting) !== undefined) {
          alertStore.error(`${t('Failed to create setting.')} ${t('Setting cannot overlap with existing time series setting.')}`)
        }
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('Failed to create setting.') + ' ' + t(error[0]))
        } else {
          alertStore.error(t('Failed to create setting.'))
        }
      },
    }
  )
}

export type TimeSeriesSettingCreationRequest = SettingCreationRequestBase & {
  start_time: ISODateTime
  end_time: ISODateTime
  value: { [time: ISODateTime]: { id?: number; value: number | null } }
  deleted_at?: ISODateTime | null
  operational_event?: number | null
}

export type CreateTimeSeriesSettingParams = {
  overwrite: boolean
  digitalTwinUid: number
  data: TimeSeriesSettingCreationRequest
  splitOnNullValues: boolean
}

function splitTimeSeriesSettingOnNullValues(request: TimeSeriesSettingCreationRequest): TimeSeriesSettingCreationRequest[] {
  const times = Datetime.getHoursBetween(request.start_time, request.end_time)
  const newRequests: TimeSeriesSettingCreationRequest[] = []
  let currentStartTime: ISODateTime | null = null
  let currentValues: { [time: ISODateTime]: { id?: number; value: number | null } } = {}

  for (let i = 0; i < times.length; i++) {
    const time = times[i]
    const valueObject = request.value[time]

    if (valueObject && valueObject.value !== null) {
      if (currentStartTime === null) {
        currentStartTime = time
      }
      currentValues[time] = valueObject
    } else if (currentStartTime !== null) {
      const newRequest: TimeSeriesSettingCreationRequest = {
        start_time: currentStartTime,
        end_time: times[i - 1],
        value: currentValues,
        deleted_at: request.deleted_at,
        level: request.level,
        name: request.name,
        attribute: request.attribute,
        comment: request.comment,
        priority: request.priority,
        operational_event: request.operational_event,
      }
      newRequests.push(newRequest)
      currentStartTime = null
      currentValues = {}
    }
  }

  if (currentStartTime !== null) {
    const newRequest: TimeSeriesSettingCreationRequest = {
      start_time: currentStartTime,
      end_time: times[times.length - 1],
      value: currentValues,
      deleted_at: request.deleted_at,
      level: request.level,
      name: request.name,
      attribute: request.attribute,
      comment: request.comment,
      priority: request.priority,
      operational_event: request.operational_event,
    }
    newRequests.push(newRequest)
  }

  return newRequests
}

export function checkSettingModificationSuccessful(result: DigitalTwinSettingCreationResult | TimeSeriesSettingsCreationResult): boolean {
  if ('time_series' in result) {
    return result.time_series !== undefined && result.time_series !== null
  } else if ('created' in result) {
    return result.created !== undefined && result.created !== null
  } else {
    return false
  }
}

export function useCreateTimeSeriesSetting(): UseMutationResult<
  TimeSeriesSettingsCreationResult[],
  unknown,
  CreateTimeSeriesSettingParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ digitalTwinUid, data, overwrite, splitOnNullValues }: CreateTimeSeriesSettingParams) => {
      let timeSeriesSettingsCreationRequests: TimeSeriesSettingCreationRequest[] = []
      if (splitOnNullValues) {
        timeSeriesSettingsCreationRequests = splitTimeSeriesSettingOnNullValues(data)
      } else {
        timeSeriesSettingsCreationRequests.push(data)
      }

      const mutationPromises = timeSeriesSettingsCreationRequests.map((request) => {
        const times = Datetime.getHoursBetween(request.start_time, request.end_time)
        const value = times.map((time) => (time in request.value ? request.value[time].value : null))

        return apiClient<TimeSeriesSettingsCreationResult>(`digital_twin/${digitalTwinUid}/time_series_setting`, {
          method: 'POST',
          data: { ...setSettingRequestMinutes(request), value },
          params: { overwrite: overwrite ? 1 : 1 },
        })
      })

      return Promise.all(mutationPromises)
    },
    {
      onSuccess: (data) => {
        const success = data.every((result) => checkSettingModificationSuccessful(result))
        if (success) {
          queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
          alertStore.success(t('Setting saved.'))
        } else {
          alertStore.error(`${t('Failed to save setting.')} ${t('Setting cannot overlap with existing time series setting.')}`)
        }
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('Failed to create setting.') + ' ' + t(error[0]))
        } else {
          alertStore.error(t('Failed to create setting.'))
        }
      },
    }
  )
}

export type UpdateDigitalTwinSettingParams = {
  overwrite: boolean
  settingId: number
  data: DigitalTwinSettingCreationRequest
  digitalTwinUid?: number
  operational_event?: number | null
}

export function useUpdateDigitalTwinSetting(): UseMutationResult<
  DigitalTwinSettingCreationResult,
  unknown,
  UpdateDigitalTwinSettingParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ settingId, data, overwrite }: UpdateDigitalTwinSettingParams) => {
      return apiClient<DigitalTwinSettingCreationResult>(`digital_twin/setting/${settingId}/update`, {
        method: 'PUT',
        data: setSettingRequestMinutes(data),
        params: { overwrite: overwrite ? 1 : 0 },
      })
    },
    {
      onSuccess: (data) => {
        if (data?.created) {
          queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
          alertStore.success(t('Setting saved.'))
        } else if (data?.overlapping.find((overlapping) => overlapping.time_series_setting) !== undefined) {
          alertStore.error(`${t('Failed to save setting.')} ${t('Setting cannot overlap with existing time series setting.')}`)
        }
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('Failed to save setting.') + ' ' + t(error[0]))
        } else {
          alertStore.error(t('Failed to save setting.'))
        }
      },
    }
  )
}

export function useUpdateTimeSeriesSetting(): UseMutationResult<
  TimeSeriesSettingsCreationResult,
  unknown,
  UpdateDigitalTwinSettingParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ settingId, data, overwrite, digitalTwinUid }: UpdateDigitalTwinSettingParams) => {
      return apiClient<TimeSeriesSettingsCreationResult>(`digital_twin/${digitalTwinUid}/time_series_settings/${settingId}/update`, {
        method: 'PUT',
        data: { ...setSettingRequestMinutes(data), settings: Object.values(data.value ?? {}) },
        params: { overwrite: overwrite ? 1 : 0 },
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
          alertStore.success(t('Setting saved.'))
        }
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('Failed to save setting.') + ' ' + t(error[0]))
        } else {
          alertStore.error(t('Failed to save setting.'))
        }
      },
    }
  )
}

export type DeleteDeviationSettingParams = {
  settingId: number
  digitalTwinUid?: number
}

export function useDeleteDeviationSetting(): UseMutationResult<
  undefined,
  unknown,
  DeleteDeviationSettingParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ settingId }: DeleteDeviationSettingParams) =>
      apiClient<undefined>(`digital_twin/setting/${settingId}`, { method: 'DELETE' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
        alertStore.success(t('Setting removed.'))
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('The setting could not be removed') + ': ' + t(error[0]))
        } else {
          alertStore.error(t('The setting could not be removed'))
        }
      },
    }
  )
}

export function useDeleteTimeSeriesSetting(): UseMutationResult<undefined, unknown, DeleteDeviationSettingParams, unknown> {
  const { t } = useTranslation()

  return useMutation(
    ({ settingId, digitalTwinUid }: DeleteDeviationSettingParams) =>
      apiClient<undefined>(`digital_twin/${digitalTwinUid}/time_series_settings/${settingId}`, { method: 'DELETE' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY)
        alertStore.success(t('Setting removed.'))
      },
      onError: (error) => {
        if (Array.isArray(error) && error.length > 0) {
          alertStore.error(t('The setting could not be removed') + ': ' + t(error[0]))
        } else {
          alertStore.error(t('The setting could not be removed'))
        }
      },
    }
  )
}

export type DeviationSettingUpdateRequest = {
  name: string
  display_name?: string
  model?: DigitalTwinModel
  layout?: DigitalTwinLayout
  description?: string
}

export type UpdateDeviationSettingTwinParams = {
  settingId: number
  data: DeviationSettingUpdateRequest
}

export const GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY = 'get_digital_twin_accumulator_info'

export function useDigitalTwinAckumulatorInfo(
  uid?: number,
  startTime?: string,
  endTime?: string
): UseQueryResult<AccumulatorInfo[]> {
  return useQuery([GET_DIGITAL_TWIN_ACCUMULATOR_INFO_QUERY_KEY, uid], async () => {
    if (!uid) {
      return []
    }

    return await apiClient<AccumulatorInfo[]>(`digital_twin/${uid}/accumulator_info`, {
      params: {
        start_time: startTime,
        end_time: endTime,
        priority: [
          ...DEVIATION_SETTING_PRIORITIES.map((level) => level.priority_level),
          ...BASE_SETTING_PRIORITIES.map((level) => level.priority_level),
        ],
      },
    })
  })
}

const DIGITAL_TWIN_SETTINGS_QUERY_KEY = 'digital_twin_settings'

export function useOutdatedPropertiesWithSettings(
  uid?: number
): UseQueryResult<{ level: string; name: string; attribute: string; count: number }[]> {
  return useQuery([DIGITAL_TWIN_SETTINGS_QUERY_KEY, uid], async () => {
    if (!uid) {
      return []
    }

    return await apiClient<ConfigurableProperty[]>(`digital_twin/${uid}/outdated_configurable_properties`)
  })
}

type BulkDeleteSettingsParams = {
  level: string
  name: string
  attribute: string
  digitalTwinUid: number
}

export function useBulkDeleteSettings(): UseMutationResult<
  { deleted: number },
  unknown,
  BulkDeleteSettingsParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ digitalTwinUid, level, name, attribute }: BulkDeleteSettingsParams) =>
      apiClient<{ deleted: number }>(`digital_twin/${digitalTwinUid}/settings/bulk_delete`, {
        method: 'DELETE',
        params: {
          level,
          name,
          attribute,
        },
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(DIGITAL_TWIN_SETTINGS_QUERY_KEY)
        alertStore.success(t('{{count}} settings removed', { count: result.deleted }))
      },
      onError: () => {
        alertStore.error(t('Failed to remove settings'))
      },
    }
  )
}

type BulkUpdateSettingPropertiesParams = {
  digitalTwinUid: number
  from: {
    level: string
    name: string
    attribute: string
  }
  to: {
    level: string
    name: string
    attribute: string
  }
}

export function useBulkUpdateSettingProperties(): UseMutationResult<
  { updated_settings: number },
  string[],
  BulkUpdateSettingPropertiesParams,
  unknown
  > {
  const { t } = useTranslation()

  return useMutation(
    ({ digitalTwinUid, from, to }: BulkUpdateSettingPropertiesParams) =>
      apiClient<{ updated_settings: number }>(`digital_twin/${digitalTwinUid}/settings/bulk_update_properties`, {
        method: 'PATCH',
        data: { migrate_from: from, migrate_to: to },
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(DIGITAL_TWIN_SETTINGS_QUERY_KEY)
        alertStore.success(t('{{count}} settings updated', { count: result.updated_settings }))
      },
      onError: (errorMessages) => {
        alertStore.error(t(errorMessages?.at(0) ?? 'Failed to update settings'))
      },
    }
  )
}
