import React, { ReactElement, useState } from 'react'

import uiConfigStore, { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import { resetZoom } from 'store/zoom/zoom'
import { dynamicClassName } from 'styles/helper'
import Checkbox from 'ui/atoms/Checkbox/Checkbox'
import Icon from 'ui/atoms/Icon/Icon'
import ResizeableRow from 'ui/atoms/ResizeableRow/ResizeableRow'
import { getComponentTitle, getAllSingletonComponentNames } from 'ui/uiConfig/factory'

import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import CreateNewUiConfig from 'views/UiConfigView/components/CreateNewUiConfig/CreateNewUiConfig'
import UiConfigBadge from 'views/UiConfigView/components/UiConfigBadge/UiConfigBadge'

import styles from './UiConfigPanel.module.less'
import UiConfigVersions from './components/UiConfigVersions/UiConfigVersions'

export default function UiConfigPanel(): ReactElement {
  const [showVersionForId, setShowVersionForId] = useState<UiConfigId | null>(null)
  const uiConfigSnap = useSnapshot(editingUiConfigStore)
  const editingUiConfigSnap = useSnapshot(editingUiConfigStore)
  const { t } = useTranslation()
  const panelUids = uiConfigSnap.activeUids
  const uiConfigs = panelUids.map((uid) => uiConfigSnap.uiConfigs[uid]).reverse()
  const editingUiConfigChildrenUids = uiConfigSnap.editingUiConfigChildrenUids
  const singletonComponentNames = new Set(getAllSingletonComponentNames())
  const [filter, setFilter] = useState<('Anchor components' | 'Components')[]>(['Anchor components', 'Components'])

  function selectUiConfig(uiConfig: UiConfig): void {
    resetZoom()
    if (uiConfig.id && uiConfigSnap.editingUid === uiConfig.uid) {
      toggleShowVersionForId(uiConfig.id)
      return
    }

    uiConfigStore.editingUid = uiConfig.uid
    setShowVersionForId(null)
  }

  function toggleShowVersionForId(id: UiConfigId): void {
    setShowVersionForId(showVersionForId === id ? null : id)
  }

  return (
    <div className={styles.UiConfigPanel}>
      <div style={{ display: 'flex' }}>
        <Checkbox
          label={t('Anchor components')}
          isChecked={filter.includes('Anchor components')}
          onClick={() => {
            if (filter.includes('Anchor components')) {
              setFilter(filter.filter((f) => f !== 'Anchor components'))
            } else {
              setFilter([...filter, 'Anchor components'])
            }
          }}
        />
        <Checkbox
          label={t('Components')}
          isChecked={filter.includes('Components')}
          onClick={() => {
            if (filter.includes('Components')) {
              setFilter(filter.filter((f) => f !== 'Components'))
            } else {
              setFilter([...filter, 'Components'])
            }
          }}
        />
      </div>
      {filter.map((f, index) => {
        let infoText = ''
        const filteredUiConfigs = uiConfigs.filter((uiConfig) => {
          if (!uiConfig.component) {
            return false
          }

          if (f === 'Anchor components') {
            infoText = t(
              'Anchor components are UiConfigs that can only be used once per system. They have one dedicated placeholder in the UI, e.g followup_production which is only visible in /followupproduction.'
            )
            return singletonComponentNames.has(uiConfig.component)
          } else if (f === 'Components') {
            infoText = t(
              'Components are UiConfigs that can be used multiple times per system. They are not visible unless added to a anchor component as a children_id.'
            )
            return !singletonComponentNames.has(uiConfig.component)
          }
        })

        return (
          <ResizeableRow height={`50%`}>
            <div className={styles.UiConfigPanel_Body} key={index}>
              <div className={styles.UiConfigPanel_Title}>
                {t(f) ?? f}
                <Tooltip title={infoText} arrow>
                  <div>
                    <Icon icon="fas fa-info-circle" />
                  </div>
                </Tooltip>
              </div>
              {filteredUiConfigs.map((uiConfig) => {
                const uiConfigIsAnchorComponent = singletonComponentNames.has(uiConfig.component as string)

                return (
                  <div
                    key={uiConfig.uid}
                    className={dynamicClassName({
                      animation: true,
                      [styles.UiConfigPanel_Item]: true,
                      [styles.UiConfigPanel_Item__active]: uiConfig.id === editingUiConfigSnap.editingId,
                      [styles.UiConfigPanel_Item__highlight]: !!uiConfig.id && editingUiConfigChildrenUids.has(uiConfig.id),
                      [styles.UiConfigPanel_Item__active__red]:
                      uiConfigIsAnchorComponent && uiConfig.id === editingUiConfigSnap.editingId,
                    })}
                  >
                    <div
                      className={dynamicClassName({
                        [styles.UiConfigPanel_Item_Text]: true,
                        [styles.UiConfigPanel_Item_Text__red]: uiConfigIsAnchorComponent,
                      })}
                      onClick={() => selectUiConfig(uiConfig)}
                    >
                      {uiConfigIsAnchorComponent ? (
                        <>
                          {`${uiConfig.id}.${uiConfig.version} ${uiConfig.display_name || getComponentTitle(uiConfig.component as string)}`}
                          <span
                            style={{ fontStyle: 'italic', fontSize: 'small', fontWeight: 'lighter' }}
                          >{` ${uiConfig.component}`}</span>
                        </>
                      ) : (
                        <>
                          {`${uiConfig.id}.${uiConfig.version} ${uiConfig.display_name || ''}`}
                          <span
                            style={{ fontStyle: 'italic', fontSize: 'small', fontWeight: 'lighter' }}
                          >{` ${getComponentTitle(uiConfig.component as string)}`}</span>
                        </>
                      )}

                      <UiConfigBadge uiConfig={uiConfig} />
                    </div>

                    {uiConfig.id === showVersionForId && <UiConfigVersions />}
                  </div>
                )
              })}
            </div>
          </ResizeableRow>
        )
      })}
      <CreateNewUiConfig />
    </div>
  )
}
