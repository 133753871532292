import React, { ReactElement, ReactNode } from 'react'

import { DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP } from 'api/dataset/dataset.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { dynamicClassName } from 'styles/helper'
import { Card, Tabs } from 'ui/atoms'
import { TabItem } from 'ui/atoms/Tabs/Tabs'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import { capitalize } from 'utils/stringUtils'

import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './FuelPlan.module.less'

type FuelPlanCell = {
  title?: string
  children_title?: string
  card_props?: {
    style: 'table' | 'default' | 'sub'
    default_collapsed: boolean
    display: boolean
  }
  ui_config_id?: number
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
  children_list?: FuelPlanCell[]
}

type FuelPlanTab = {
  children_list?: FuelPlanCell[]
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
  title: string
}

type FuelPlanHeaderTab = {
  title?: string
  tabs: FuelPlanTab[]
}

type FuelPlanProps = {
  headerTabs: FuelPlanHeaderTab[]
  systemId: number
  children: ReactNode[]
  datasetEndTime?: ISODateTime
  datasetStartTime?: ISODateTime
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

const defaultProps: Omit<FuelPlanProps, `datasets` | `children` | `systemId`> = {
  headerTabs: [
    {
      title: ``,
      tabs: [
        {
          title: `Värme`,
          children_list: [
            {
              title: 'Enligt prognos',
              card_props: { display: true, default_collapsed: false, style: 'table' },
              ui_config_id: NaN,
              override_alias: {},
              override_props: {},
              children_list: [
                {
                  title: 'Chart',
                  ui_config_id: NaN,
                  override_alias: {},
                  override_props: {},
                },
                {
                  title: 'Översiktsvy',
                  ui_config_id: NaN,
                  override_alias: {},
                  override_props: {},
                  card_props: { display: true, default_collapsed: true, style: 'sub' },
                  children_list: [
                    {
                      ui_config_id: NaN,
                      override_alias: {},
                      override_props: {},
                    },
                    {
                      ui_config_id: NaN,
                      override_alias: {},
                      override_props: {},
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
registerUiConfigComponent(`fuel_plan`, FuelPlan, {
  isSingletonComponent: true,
  title: 'Produktionsplan i scenarion',
})
registerDefaultProps(`fuel_plan`, defaultProps)

function getSystemtabs(
  route: string,
  handleTabChange: (index: number) => void,
  headerTabs: FuelPlanHeaderTab[]
): JSX.Element {
  const systemTabItems: TabItem[] = []
  const localStorageKey = `FuelPlanHeaderTabs_${route}`

  headerTabs.forEach((tab) => {
    systemTabItems.push({
      title: tab.title ?? '',
      children: [],
    })
  })

  if (route === `uiconfig`) {
    return <Tabs items={systemTabItems} onChange={handleTabChange} uniqueStorageKey={localStorageKey} />
  }

  if (route === `fuelplan`) {
    const element = document.getElementById(`portal_for_tabs`)
    if (element) {
      return ReactDOM.createPortal(
        <Tabs items={systemTabItems} onChange={handleTabChange} innerLeftPadding marginBottom={false} uniqueStorageKey={localStorageKey}/>,
        element
      )
    }
  }
  return <></>
}

export default function FuelPlan({
  headerTabs,
  systemId,
  datasetStartTime,
  datasetEndTime,
}: FuelPlanProps): ReactElement {
  const { t } = useTranslation()
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const [tabIndex, setTabIndex] = React.useState(0)
  const route = getRoute()

  function handleTabChange(index: number): void {
    setTabIndex(index)
  }

  const systemTabs = getSystemtabs(route, handleTabChange, headerTabs)
  return (
    <Card
      margin
      title={
        <TitleWithIcon
          size={'large'}
          title={t(capitalize(headerTabs[tabIndex].title)) ?? capitalize(headerTabs[tabIndex].title)}
        />
      }
      key={`card_${headerTabs[tabIndex].title}`}
      style={'default'}
      collapsible
    >
      {headerTabs.length > 1 && systemTabs}
      <Tabs
        items={headerTabs[tabIndex].tabs?.map((tab, indexTab) => ({
          title: tab.title,
          children: (
            <div className={styles.FuelPlan} key={`childkey_${indexTab}`}>
              {tab.children_list?.map((child, childIndex) => {
                return (
                  <Card
                    style={child.card_props?.style}
                    collapsible
                    key={childIndex}
                    defaultCollapsed={child.card_props?.default_collapsed}
                    title={
                      <TitleWithIcon size={'medium'} title={t(capitalize(child.title)) ?? capitalize(child.title)} />
                    }
                  >
                    <UiConfigComponent
                      systemId={systemId}
                      id={child.ui_config_id ?? NaN}
                      datasetStartTime={datasetStartTime}
                      datasetEndTime={datasetEndTime}
                      noWrappingDiv
                      uid={child.ui_config_id && uiConfigSnap.idToUiConfig[child.ui_config_id]?.uid}
                      overrideAlias={child.override_alias || tab.override_alias}
                      overrideProps={child.override_props || tab.override_props}
                      key_prefix={tabIndex}
                      datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
                    />

                    {child.children_list?.map((subChild, subIndex) => {
                      return (
                        <div key={`subChildKey_${subIndex}`}>
                          {subChild.ui_config_id && (
                            <UiConfigComponent
                              systemId={systemId}
                              id={subChild.ui_config_id ?? NaN}
                              datasetStartTime={datasetStartTime}
                              noWrappingDiv
                              datasetEndTime={datasetEndTime}
                              uid={uiConfigSnap.idToUiConfig[subChild.ui_config_id]?.uid}
                              overrideAlias={subChild.override_alias || tab.override_alias}
                              overrideProps={subChild.override_props || tab.override_props}
                              key_prefix={tabIndex}
                            />
                          )}
                          {subChild.children_list && (
                            <Card
                              style={subChild.card_props?.style}
                              collapsible
                              defaultCollapsed={subChild.card_props?.default_collapsed}
                              title={
                                <TitleWithIcon
                                  size={'small'}
                                  title={t(capitalize(subChild.title)) ?? capitalize(subChild.title)}
                                />
                              }
                            >
                              <div
                                className={dynamicClassName({
                                  [styles.FuelPlan_SubSubChild]: true,
                                  [styles.FuelPlan_SubSubChildParent]: subChild.children_list?.length === 3,
                                })}
                              >
                                {subChild.children_list?.map((subSubChild, subSubIndex) => {
                                  return (
                                    <div
                                      key={`subSubChildKey_${subSubIndex}`}
                                      className={dynamicClassName({
                                        [styles.div]: true,
                                        [styles.div0]: subSubIndex === 0,
                                        [styles.div1]: subSubIndex === 1,
                                        [styles.div2]: subSubIndex === 2,
                                      })}
                                    >
                                      <UiConfigComponent
                                        systemId={systemId}
                                        noWrappingDiv
                                        id={subSubChild.ui_config_id ?? NaN}
                                        datasetStartTime={datasetStartTime}
                                        datasetEndTime={datasetEndTime}
                                        uid={subSubChild.ui_config_id && uiConfigSnap.idToUiConfig[subSubChild.ui_config_id]?.uid}
                                        overrideAlias={subSubChild.override_alias || tab.override_alias}
                                        overrideProps={subSubChild.override_props || tab.override_props}
                                        key_prefix={tabIndex}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </Card>
                          )}
                        </div>
                      )
                    })}
                  </Card>
                )
              })}
            </div>
          ),
        }))}
        uniqueStorageKey={`FuelPlanTabs_${route}_${tabIndex}`}
      />
    </Card>
  )
}
