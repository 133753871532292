import React, { ReactElement, useState } from 'react'

import { Setting, useSettings } from 'api/settings/settings.api'
import {
  ShiftHandoverCommentGroup,
  useShiftHandoverCommentGroups,
} from 'api/shiftHandoverCommentGroups/shiftHandoverCommentGroups.api'
import {
  useShiftHandoverDetails,
  ShiftHandover,
  ShiftHandoverDetailsResponse,
} from 'api/shiftHandovers/shiftHandovers.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { DialogTitle, Grid, Typography, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Form from '../Form/Form'

type FormWrapperProps = {
  commentGroups: ShiftHandoverCommentGroup[]
  details: ShiftHandoverDetailsResponse
  liveSettings: Setting[]
  handleClose: () => void
  handover: ShiftHandover
}

function FormWrapper({ commentGroups, details, liveSettings, handleClose, handover }: FormWrapperProps): ReactElement {
  const {
    id,
    starting_shift_team,
    ending_shift_team,
    start_time,
    end_time,
    handover_time,
    evaluation_status,
    representatives_present,
    submitted_at,
  } = handover
  const { comments, series, settings } = details

  const commentGroupsWithComments = commentGroups
    .map((item) => ({
      ...item,
      comments: [
        ...comments.filter((comment) => item.id === comment.group),
        ...(!submitted_at ? [{ group: item.id, content: ``, is_reported: false, is_pinned: false }] : []),
      ],
    }))
    .filter((group) => group.comments.length > 0)
  return (
    <Form
      defaultValues={{
        id,
        starting_shift_team,
        ending_shift_team,
        start_time,
        end_time,
        handover_time,
        representatives_present,
        evaluation_status,
        comment_groups: commentGroupsWithComments,
        system: handover.system,
        submit: false,
      }}
      data={!submitted_at ? undefined : data}
      series={!submitted_at ? undefined : series}
      settings={!submitted_at ? liveSettings : settings}
      handleModalClose={handleClose}
      handoverSubmitted={!!handover.submitted_at}
    />
  )
}

type EditModalProps = {
  open: boolean
  handleClose: () => void
  handover?: ShiftHandover
}

function EditModal({ open, handleClose, handover }: EditModalProps): ReactElement {
  const { t } = useTranslation()

  const handoverSubmitted = handover?.submitted_at ? true : false
  const { data: commentGroups, status: commentGroupsStatus } = useShiftHandoverCommentGroups({ enabled: open })
  const { data: details, status: detailsStatus } = useShiftHandoverDetails(handover?.id)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const sameSubmitterCreator = handover?.submitter_name === handover?.creator_name ? true : false

  const { data: liveSettings = !handover?.submitted_at ? undefined : [], status: settingsStatus } = useSettings({
    params: {
      active_from: handover?.start_time,
      active_to: handover?.end_time,
    },
    queryConfig: { enabled: open },
  })

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
        scroll="paper"
        style={{ zIndex: 1299 }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container alignItems="center" justifyContent="flex-start" spacing={1} xs={11}>
              <Grid item>
                <Typography variant="h1" display="inline">
                  {t(`Shift handover report`)}
                </Typography>
              </Grid>

              {sameSubmitterCreator ? (
                <Grid item>
                  <Icon className="fal fa-user-edit" style={{ fontSize: 15, margin: 5 }} />
                  <Typography variant="h2" display="inline">
                    {t(`Created and submitted by: `)}
                    {handover?.creator_name}
                  </Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Icon className="fal fa-pen" style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography variant="h2" display="inline" style={{ marginRight: 10 }}>
                    {t('Created by') + ': '} {handover?.creator_name ?? ` - `}
                  </Typography>
                  {!handoverSubmitted && (
                    <InfoBanner
                      text={t(
                        `A draft will be automatically submitted if not submitted before the end of the scheduled shift.`
                      )}
                    />
                  )}
                </Grid>
              )}

              {handoverSubmitted && !sameSubmitterCreator && (
                <Grid item>
                  <Icon className="fal fa-paper-plane" style={{ fontSize: 15, marginRight: 5 }} />
                  <Typography variant="h2" display="inline">
                    {handover?.submitter_name
                      ? t(`Submitted by: `) + handover?.submitter_name
                      : t(`Automatically submitted`)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <PopoverCloseButton
              handleClose={handoverSubmitted ? () => handleClose() : () => setConfirmDialogOpen(true)}
            />
          </Grid>
        </DialogTitle>
        {handover && (
          <StatusComponent
            status={[commentGroupsStatus, detailsStatus, settingsStatus]}
            data={{ commentGroups, details, liveSettings }}
            Component={FormWrapper}
            ComponentProps={{ handover, handleClose }}
          />
        )}
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        textObject={{
          title: t(`Are you sure you want to cancel your shift handover?`),
          text: t(
            `All your progress will be lost and you have to redo the shift handover again if you close this window.`
          ),
        }}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          handleClose()
          setConfirmDialogOpen(false)
        }}
      />
    </>
  )
}

export default EditModal
