import React, { ReactElement } from 'react'

import { Event, EventCategory, isSettingReferenceFields } from 'api/systemEvents/systemEvents.api'

import { Typography, Grid, Icon } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import moment from 'moment'

import { formatEventText } from 'helpers/eventLoggerItem.helper/eventLoggerItem.helper'

import styles from './EventLoggerItem.module.less'

type EventLoggerItemProps = {
  event: Event
}

const variantIcon: Record<EventCategory, string> = {
  optimization_regular: `fal fa-chart-area`,
  optimization_followup_with_deviations: `fal fa-history`,
  optimization_followup_without_deviations: `fal fa-history`,
  optimization_measvalues_calculations: `fal fa-industry`,
  optimize_setting: `fal fa-cogs`,
  optimization_sandbox: `fal fa-flask`,
  forecast_load: `fal fa-thermometer-quarter`,
  forecast_elprice: `fal fa-bolt`,
}

export default function EventLoggerItem({ event }: EventLoggerItemProps): ReactElement {
  const objectProperty = isSettingReferenceFields(event.reference_fields)
    ? event.reference_fields.object_property
    : undefined
  const text = formatEventText(event, objectProperty)
  return (
    <List component="nav" aria-label="List">
      {text && (
        <>
          <ListItem>
            <Icon className={variantIcon[event.event_category]} />
            <Grid container direction="column" className={styles.EventLoggerItem_EventContainer}>
              <Typography variant="body2" className={styles.EventLoggerItem_EventText}>
                {moment(event.updated_at).format(`HH:mm:ss`)}
              </Typography>
              <ListItemText>
                <span className={styles.EventLoggerItem_CategorySpan}>{text.categoryText}</span>{' '}
                <span>{' ' + text.eventText}</span>
              </ListItemText>
            </Grid>
          </ListItem>
          <Divider />
        </>
      )}
    </List>
  )
}
