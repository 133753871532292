import React, { ReactElement, ChangeEvent } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { AppBar, Tabs, Tab, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TabObject } from '../RegularTabs'

import styles from './MobileTabs.module.less'

type MobileTabsProps = {
  handleChange: (event: React.ChangeEvent<unknown>, newValue: number) => void
  value: number
  tabObjects: TabObject[]
}

function MobileTabs({ handleChange, value, tabObjects }: MobileTabsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <AppBar position="static" color="default" className={styles.MobileTabs_AppBar}>
      <Tabs
        classes={{ indicator: styles.MobileTabs_Indicator }}
        className={styles.MobileTabs_Tabs}
        value={value}
        onChange={handleChange}
        indicatorColor={`primary`}
        textColor="primary"
        variant="fullWidth"
        aria-label={t(`Navigationbar`)}
      >
        {tabObjects.map((tab) => {
          return (
            <Tab
              className={styles.MobileTabs_Tab}
              key={tab.id}
              value={tab.id}
              aria-label={tab.label}
              label={
                <>
                  <div>
                    {tab.tooltip && (
                      <Tooltip title={tab.tooltip} placement="top">
                        <Icon className="fas fa-info-circle" fontSize="inherit" />
                      </Tooltip>
                    )}
                    &nbsp; {tab.label}
                  </div>
                </>
              }
            />
          )
        })}
      </Tabs>
    </AppBar>
  )
}

export default MobileTabs
