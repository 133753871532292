import React, { ReactElement, useMemo, useState } from 'react'

import { useNotifications } from 'api/notifications/notifications.api'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'
import Datetime from 'utils/datetime/datetime'

import { Box, Tab as BaseTab, Tabs, Typography, styled } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { NotificationType, getNotificationItem, getTime } from './NotificationCenter.helper'
import styles from './NotificationCenter.module.less'
import OptimizationFailedGuide from './OptimizationFailedGuide'

const RUNNING_STATUSES = ['created', 'queued', 'running']
const COMPLETED_STATUSES = ['finished', 'failed', 'unsolvable']

const Tab = styled(BaseTab)({
  textTransform: 'uppercase',
})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number): Record<string, string> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function NotificationCenter(): ReactElement {
  const [value, setValue] = React.useState(0)
  const [date, setDate] = useState<ISODateTime>(Datetime.getISONow())

  const { t } = useTranslation()
  const { data: response } = useNotifications({
    startTime: Datetime.getStartOfDay(date),
    endTime: Datetime.getEndOfDay(date),
    type: value === 0 ? 'optimization' : '',
    exclude: value === 1 ? 'optimization' : '',
  })
  const dataResponse: NotificationType[] = useMemo(() => response || [], [response])

  const history = useHistory()

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  function onLinkClicked(route: string): void {
    // const newRoute = decodeURIComponent(route) //TODO ta bort %skiten
    history.push(route)
  }

  const runningResponseOptimizationItems: NotificationType[] = dataResponse.filter(
    (item) => item.meta?.status && RUNNING_STATUSES.includes(item.meta?.status)
  )
  const finishedOrFailedOptimizationResponseItems: NotificationType[] = dataResponse.filter(
    (item) => item.meta?.status && COMPLETED_STATUSES.includes(item.meta?.status)
  )
  return (
    <div className={styles.NotificationCenter}>
      <div className={styles.NotificationCenter_Header}>
        <div className={styles.NotificationCenter_Header_Title}>{t('Log')}</div>
        <div className={styles.NotificationCenter_Header_CalendarContainer}>
          <ArrowDatePicker
            theme="dark"
            prevButtonTooltip={t(`Previous day`)}
            nextButtonTooltip={t(`Next day`)}
            format={`ddd D MMM`}
            step={{ unit: 'day', amount: 1 }}
            value={moment(date)}
            onChange={(date) => setDate(Datetime.toISOString(date))}
            maxValue={moment(Datetime.getTodayDate().endTime)}
          />
        </div>
      </div>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '70px', backgroundColor: '#152029' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab label={t('Optimizations')} {...a11yProps(0)} />
            <Tab label={t('Misc')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>

          <div className={styles.NotificationCenter_Body}>
            <div className={styles.NotificationCenter_Events}>
              <span>{t('Running')}</span>
              {runningResponseOptimizationItems.length !== 0 ? (
                runningResponseOptimizationItems.map((item, index) => {
                  return <div key={index}>{getNotificationItem(item, onLinkClicked)}</div>
                })
              ) : (
                <div className={styles.NotificationCenter_Events__noEvents}>{t('No running optimizations')}</div>
              )}
            </div>

            <div className={styles.NotificationCenter_Events}>
              <span>{t('Completed')}</span>
              <div className={styles.NotificationCenter_Events_Container}>
                {finishedOrFailedOptimizationResponseItems.length !== 0 ? (
                  finishedOrFailedOptimizationResponseItems.map((item, index) => {
                    return (
                      <div key={index}>
                        {getNotificationItem(item, onLinkClicked)}
                        {item.meta?.status === 'failed' && item.meta?.start_time && item.meta?.end_time && (
                          <OptimizationFailedGuide
                            startTime={Datetime.toISOString(item.meta?.start_time)}
                            endTime={Datetime.toISOString(item.meta?.end_time)}
                          />
                        )}

                        {getTime(item.updated_at as ISODateTime)}
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.NotificationCenter_Events__noEvents}>{t('No completed optimizations')}</div>
                )}
              </div>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className={styles.NotificationCenter_Body}>
            <div className={styles.NotificationCenter_Events}>
              {dataResponse.length !== 0 ? (
                dataResponse.map((item, index) => {
                  return (
                    <div key={index}>
                      {getNotificationItem(item, onLinkClicked)}
                    </div>
                  )
                })
              ) : (
                <div className={styles.NotificationCenter_Events__noEvents}>{t('No events')}</div>
              )}
            </div>
          </div>
        </CustomTabPanel>
      </Box>
    </div>
  )
}
