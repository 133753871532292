import React, { ReactElement } from 'react'

import { FormControl, FormControlProps, Switch } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type ReactHookFormSwitchProps = FormControlProps & {
  name: string
}

export default function ReactHookFormSwitch({ name, defaultValue, ...props }: ReactHookFormSwitchProps): ReactElement {
  const { control } = useFormContext()
  return (
    <FormControl {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ...rest } }) => (
          <Switch onChange={(e) => onChange(e.target.checked)} checked={value} {...rest} />
        )}
      />
    </FormControl>
  )
}
