import React, { ReactElement, useState } from 'react'

import { EvaluationStatus } from 'api/shiftHandovers/shiftHandovers.api'
import EvaluationEmojiIcon from 'ui/components/EvaluationEmojiIcon/EvaluationEmojiIcon'
import Tour from 'ui/components/Tour/Tour'

import { Grid, Typography, Tooltip, Icon, RadioGroup } from '@mui/material'
import classNames from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'


import styles from './HandoverEvaluation.module.less'

const handoverEmoji = `handoverEmoji`
const step: Array<Step> = [
  {
    target: handoverEmoji,
    content: `In this section you can leave a review of how the shift went and was experienced. This review will make it easier to follow up on the reasons why a shift was experienced as positive or negative.`,
    title: `Review`,
  },
]

const evaluationStatuses: EvaluationStatus[] = [`very_good`, `good`, `bad`, `very_bad`]

type HandoverEvaluationProps = {
  disabled?: boolean
}

export default function HandoverEvaluation({ disabled }: HandoverEvaluationProps): ReactElement {
  const [runTour, setRunTour] = useState(false)
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <>
      <Tour isSingle={true} run={runTour} setRunState={setRunTour} steps={step} />
      <Grid className={classNames(handoverEmoji, styles.HandoverEvaluation_Container)}>
        <Grid item container direction="row">
          <Grid item>
            <Icon
              className={classNames(styles.HandoverEvaluation_Icon, `fas fa-info-circle`)}
              onClick={() => setRunTour(true)}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={styles.HandoverEvaluation_Title} display="inline">
              {t(`How did the shift go?`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={styles.HandoverEvaluation_ButtonContainer}>
          <Controller
            control={control}
            defaultValue=""
            name="evaluation_status"
            render={({ field }) => (
              <RadioGroup row {...field}>
                {evaluationStatuses.map((item) => (
                  <Tooltip
                    key={item}
                    title={
                      {
                        very_good: t(`Very well`),
                        good: t(`Good`),
                        bad: t(`Meh`),
                        very_bad: t(`Not so well`),
                        undefined: ``,
                      }[item]
                    }
                    placement="bottom"
                    arrow
                  >
                    <EvaluationEmojiIcon evaluationStatus={item} disabled={disabled} />
                  </Tooltip>
                ))}
              </RadioGroup>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}
