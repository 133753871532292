import React, { PropsWithChildren, ReactElement } from 'react'

import { Grid } from '@mui/material'

import styles from './View.module.less'

type ViewProps = PropsWithChildren<{
  header: React.ReactNode,
  stickyHeader?: boolean
}>

export default function View({ children, header, stickyHeader }: ViewProps): ReactElement {

  return (
    <div className={styles.View}>
      <div className={`${styles.View_Header} ${stickyHeader ? styles.View_Header_sticky : ''}`}>
        {header}
        <div className={styles.View_Header_Portal}>
          <div id="headerPortalTabs" />
        </div>
      </div>
      <div className={styles.View_Body} data-printable>
        <Grid container direction="row" spacing={2}>
          {children}
        </Grid>
      </div>
    </div>
  )
}
