/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement, useEffect, useState } from 'react'

import { DeclinedHours, HeatSupplier, useDeclinedHours } from 'api/heatSuppliers/heatSuppliers.api'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting, useObjectPropertySettings } from 'api/settings/settings.api'
import { useSettingChange } from 'hooks/apiHooks/apiHooks'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Grid, Icon, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


import NoPermissionErrorMessage from '../ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import { addSetting, mapAvailableHoursSettings } from 'helpers/samEnergi.helper/samEnergi.helper'

import styles from './AvailableHoursForm.module.less'
import SeasonTextField from './components/SeasonTextField/SeasonTextField'

type AvailableHoursFormData = {
  year: number
  unplannedStopSummer: number
  plannedStop: number
  unplannedStopWinter: number
}

type Period = `unplannedStopSummer` | `plannedStop` | `unplannedStopWinter`
const PERIODS: Period[] = [`unplannedStopSummer`, `plannedStop`, `unplannedStopWinter`]

function AvailableHoursFormWrapper({
  settings,
  declinedHours,
  propId,
  year,
  hasEditPermission,
  setYear,
  setIsDirty,
  onFormFinished,
}: {
  settings: Setting[]
  declinedHours: DeclinedHours
  propId: number
  year: number
  hasEditPermission: boolean
  setYear: (year: number) => void
  setIsDirty?: (arg: boolean) => void
  onFormFinished?: () => void
}): ReactElement {
  const { t } = useTranslation()
  const { edit, add } = useSettingChange()

  const currentDeclinedHours = declinedHours ? declinedHours : { planned: 0, unplanned_winter: 0, unplanned_summer: 0 }

  const declined =
    currentDeclinedHours.planned + currentDeclinedHours.unplanned_summer + currentDeclinedHours.unplanned_winter

  const methods = useForm<AvailableHoursFormData>({
    mode: `onChange`,
    shouldUnregister: false,
  })
  const { handleSubmit, formState, reset, watch } = methods
  const [unplannedStopSummer, unplannedStopWinter, plannedStop] = watch([
    `unplannedStopSummer`,
    `unplannedStopWinter`,
    'plannedStop',
  ])
  const total = Number(unplannedStopSummer) + Number(plannedStop) + Number(unplannedStopWinter)

  const { isValid, isSubmitting, isDirty } = formState
  useEffect(() => {
    setIsDirty?.(isDirty)
  }, [isDirty, setIsDirty])

  useEffect(() => {
    const hourSettings = mapAvailableHoursSettings(settings)

    reset({
      unplannedStopSummer: hourSettings.unplannedStopSummer?.value ?? 0,
      plannedStop: hourSettings.plannedStop?.value ?? 0,
      unplannedStopWinter: hourSettings.unplannedStopWinter?.value ?? 0,
    })
  }, [settings, reset])
  async function onSubmit(data: AvailableHoursFormData): Promise<void> {
    const hourSettings = mapAvailableHoursSettings(settings)

    for (const period of PERIODS) {
      if (hourSettings[period] === undefined) {
        addSetting(period, year, propId, data[period], ``, add).then(() => onFormFinished?.())
      } else {
        const setting = hourSettings[period]
        const newValue = Number(data[period])
        const oldValue = Number(setting?.value)

        if (setting && newValue !== oldValue) {
          edit(
            setting.id,
            propId,
            moment(setting.start_time).format(),
            moment(setting.end_time).format(),
            data[period],
            true,
            ``,
            setting.classification
          ).then(() => onFormFinished?.())
        }
      }
    }
  }

  return (
    <FormProvider {...methods}>
      {!hasEditPermission ? (
        <NoPermissionErrorMessage
          title={t(`Unfortunately, you do not have permission to make changes here`)}
          message={t('Unfortunately, you do not have permission to change settings on this user/facility')}
        />
      ) : null}
      <form aria-label="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          spacing={2}
          container
          direction="column"
          justifyContent="space-evenly"
          className={styles.AvailableHoursForm_Form}
          alignItems="flex-start"
        >
          <Grid item container direction="row" alignItems="center" justifyContent="flex-start">
            <Grid container direction="row" item xs={5} spacing={3}>
              <Grid item className={styles.AvailableHoursForm_Icon} style={{ alignSelf: `center` }}>
                <Icon className="fal fa-alarm-clock" />
              </Grid>

              <Grid item xs={9}>
                <TextField
                  disabled={!hasEditPermission}
                  fullWidth
                  error={year < 2020}
                  name="year"
                  id="year"
                  value={year}
                  inputProps={{
                    'data-testid': `year`,
                    min: 2020,
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setYear(Number(event.target.value))
                  }}
                  variant="outlined"
                  className={styles.AvailableHoursForm_TextField}
                  type="number"
                  label={t(`Year`)}
                  aria-label={t(`Year`)}
                  helperText={year < 2020 ? t(`Year not available`) : undefined}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                <span className={styles.AvailableHoursForm_BoldText}>{t(`Total of allowed hours: `)}</span>
                {total} h
              </Typography>
              <Typography variant="body1" title="declined">
                <span className={styles.AvailableHoursForm_BoldText}>{t(`Consumed hours: `)}</span>
                {declined} h
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <SeasonTextField
                disabled={!hasEditPermission}
                name="plannedStop"
                id="plannedStop"
                error={formState?.errors?.plannedStop ? true : false}
                declinedHours={currentDeclinedHours.planned}
                label={t(`Planned hours, this year`)}
                inputIcon="fal fa-redo"
              />
            </Grid>
            <Grid item container direction="row" xs={12} sm={4} alignItems="center" justifyContent="flex-start">
              <SeasonTextField
                disabled={!hasEditPermission}
                name="unplannedStopWinter"
                id="unplannedStopWinter"
                error={formState?.errors?.unplannedStopWinter ? true : false}
                declinedHours={currentDeclinedHours.unplanned_winter}
                label={t(`Unplanned hours, winter`)}
                inputIcon="fal fa-snowflake"
              />
            </Grid>
            <Grid item container direction="row" xs={12} sm={4} alignItems="center" justifyContent="flex-start">
              <SeasonTextField
                disabled={!hasEditPermission}
                name="unplannedStopSummer"
                id="unplannedStopSummer"
                error={formState?.errors?.unplannedStopSummer ? true : false}
                declinedHours={currentDeclinedHours.unplanned_summer}
                label={t(`Unplanned hours, summer`)}
                inputIcon="fal fa-sun"
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <ContainedIconButton
              color="primary"
              type="submit"
              icon="fal fa-save"
              label={t(`Save`)}
              disabled={!hasEditPermission || isSubmitting || !isValid}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export type AvailableHoursFormProps = {
  supplier: HeatSupplier
  objectProperty: ObjectProperty
  setIsDirty?: (arg: boolean) => void
  onFormFinished?: () => void
  defaultValues?: Partial<AvailableHoursFormData>
  hasEditPermission?: boolean
}

export default function AvailableHoursForm({
  supplier,
  objectProperty,
  hasEditPermission,
  ...rest
}: AvailableHoursFormProps): ReactElement {
  const [year, setYear] = useState(moment().year())
  const propId = objectProperty.id
  const { data: settings, status: settingsStatus } = useObjectPropertySettings(propId, {
    active_from: moment().year(year).startOf(`year`).format(),
    active_to: moment().year(year).endOf(`year`).format(),
    is_base_value: true,
  })

  const { data: declinedHours, status: declinedHoursStatus } = useDeclinedHours(year, supplier.id)

  return (
    <StatusComponent
      status={[settingsStatus, declinedHoursStatus]}
      data={{ settings, declinedHours }}
      Component={AvailableHoursFormWrapper}
      ComponentProps={{ year, propId, setYear, hasEditPermission, ...rest }}
    />
  )
}
