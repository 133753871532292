import React, { ReactElement } from 'react'

import zoomStore from 'store/zoom/zoom'
import { dynamicClassName } from 'styles/helper'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './DatasetZoomRange.module.less'

type DatasetZoomRangeProps = {
  margin?: boolean
}

export default function DatasetZoomRange({ margin }: DatasetZoomRangeProps): ReactElement {
  const { t } = useTranslation()
  const zoomSnap = useSnapshot(zoomStore)
  const startTime = zoomSnap.startTime ? Datetime.toLocalTime(zoomSnap.startTime, `longDayTextWithoutYear`) : ``

  let endTime = ''
  if (zoomSnap.endTime) {
    const endDate = new Date(zoomSnap.endTime)
    endDate.setMinutes(59)
    endTime = Datetime.toLocalTime(endDate.toISOString() as ISODateTime, `longDayTextWithoutYear`)
  }
  
  return (
    <div className={dynamicClassName({ [styles.DatasetZoomRange]: true, [styles.DatasetZoomRange__margin]: !!margin })}>
      <div className={styles.DatasetZoomRange_DateRange}>
        <div className={styles.DatasetZoomRange_DateRange_Column}>
          <div className={styles.DatasetZoomRange_DateRange_Column_Date}>{startTime}</div>
          <div className={styles.DatasetZoomRange_DateRange_Column_Label}>{t(`From`)}</div>
          <div className={styles.DatasetZoomRange_DateRange_Column_Date}>{endTime}</div>
          <div className={styles.DatasetZoomRange_DateRange_Column_Label}>{t(`To`)}</div>
          <div />
        </div>

        <div className={styles.DatasetZoomRange_DateRange_Column} />
      </div>
    </div>
  )
}
