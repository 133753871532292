import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useUser } from 'api/users/users.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { dynamicClassName } from 'styles/helper'
import theme from 'styles/theme/theme'
import Datetime from 'utils/datetime/datetime'

import { Box, MenuItem, ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material'
import i18n from 'i18next'
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_FullScreenToggleButton,
  MRT_Localization,
  MRT_PaginationState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_SV } from 'material-react-table/locales/sv'
import moment from 'moment'
import { snapshot, useSnapshot } from 'valtio'

import ExportDatasetsMenu from '../ExportDatasetsMenu/ExportDatasetsMenu'
import {
  getAggregatedDatasets,
  normalizeDatasetId,
  serializeTableAccessor,
} from 'helpers/dataset.helper/dataset.helper'
import { hexToRgbA } from 'helpers/global.helper/global.helper'
import optimizeViewStore from 'views/OptimizeView/store/optimizeViewStore'

import styles from './TimeSeriesDataTable.module.less'
import { TimeSeriesDataTableData, TimeSeriesDataTableColumnDef } from './types'
import { initCurrentDataPointIndex } from './utils/initCurrentDataPointIndex'

type TimeSeriesDataTableProps = {
  columns: TimeSeriesDataTableColumnDef[]
  data: TimeSeriesDataTableData[]
  initialPageSize?: number
  hideDownloadButton?: boolean
  showInitialPageWithCurrentOptimizationHour: boolean
  showInitialPageWithCurrentDay: boolean
  datasetsInExport?: Dataset[]
  datasetNames?: Record<string, string>
  hideTopToolbar?: boolean
  useColumnColorFromItem?: boolean
  editingMode?: 'modal' | 'cell' | 'row' | 'table'
  enableEditing?: boolean
  tableKey?: string
  disableCustomSorting?: boolean
  tableContainerRef?: React.RefObject<HTMLDivElement>
  footerCalculatesPerPage?: boolean
  hideSumAndAverageRow?: boolean
  hideMinMaxRow?: boolean
  returnIdToDecimals: Record<string, number>
  onMouseMove?: (event: React.MouseEvent<HTMLTableCellElement>) => void
  max_height?: string | number | boolean | undefined
  sticky_table_header?: boolean
  onPaginationChange?: ({ pageIndex, pageSize }: MRT_PaginationState) => void
}

function getColumnsWithSortingEnabled(
  columns: TimeSeriesDataTableColumnDef[],
  setDataState: React.Dispatch<React.SetStateAction<TimeSeriesDataTableData[]>>,
  setCurrentDataPointIndex: React.Dispatch<React.SetStateAction<number>>,
  currentOptimizationHour: ISODateTime
): TimeSeriesDataTableColumnDef[] {
  const sortData = (accessorKey: unknown, ascending: boolean): void => {
    setDataState((prevDataState: TimeSeriesDataTableData[]) => {
      const sortedData = [...prevDataState].sort((aColumn, bColumn) => {
        const a: number | string = aColumn[accessorKey]
        const b: number | string = bColumn[accessorKey]

        if (a === '-' || b === '-') return a === '-' ? 1 : -1 // Sort '-' to the bottom

        if (accessorKey === 'time') {
          const dateA = new Date(a).getTime()
          const dateB = new Date(b).getTime()
          return ascending ? dateA - dateB : dateB - dateA
        }
        if (typeof a === 'string' && typeof b === 'string') return ascending ? a.localeCompare(b) : b.localeCompare(a)
        if (typeof a === 'number' && typeof b === 'number') return ascending ? a - b : b - a
        // If a and b are not of the same type, sort by type
        if (typeof a === 'number' && typeof b === 'string') return ascending ? -1 : 1
        if (typeof a === 'string' && typeof b === 'number') return ascending ? 1 : -1
        return 0
      })
      const currentDataPoint = Datetime.toLocalTime(currentOptimizationHour, 'longDayTextWithoutYear')
      const currentDataPointIndex = sortedData.findIndex((row) => row.time === currentDataPoint)

      if (currentDataPointIndex === -1) {
        setCurrentDataPointIndex(0)
      } else {
        setCurrentDataPointIndex(currentDataPointIndex)
      }

      return sortedData
    })
  }

  columns.forEach((column: TimeSeriesDataTableColumnDef) => {
    column.renderColumnActionsMenuItems = ({ closeMenu }) => [
      <MenuItem
        key={`sort-ascending-${column.accessorKey}`}
        onClick={() => {
          sortData(column.accessorKey, true)
          closeMenu()
        }}
      >
        <i
          className={dynamicClassName({
            ['fas fa-arrow-up' + ` ` + styles.Button__icon]: true,
            [styles.TimeSeriesDataTable_ActionsMenuItem]: true,
          })}
        />
        {i18n.t('Sort in ascending order')}
      </MenuItem>,
      <MenuItem
        key={`sort-descending-${column.accessorKey}`}
        onClick={() => {
          sortData(column.accessorKey, false)
          closeMenu()
        }}
      >
        <i
          className={dynamicClassName({
            ['fas fa-arrow-down' + ` ` + styles.Button__icon]: true,
            [styles.TimeSeriesDataTable_ActionsMenuItem]: true,
          })}
        />
        {i18n.t('Sort in descending order')}
      </MenuItem>,
    ]
  })

  return columns
}

function useUserLocalization(): MRT_Localization {
  const { data: user } = useUser()
  const localization = user?.settings.language
  switch (localization) {
    case 'sv':
      return MRT_Localization_SV
    default:
      return MRT_Localization_EN
  }
}

function cropDataToPagination(
  data: TimeSeriesDataTableData[],
  pagination: MRT_PaginationState
): TimeSeriesDataTableData[] {
  let croppedData = data.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize
  )

  // Remove all rows that only have values '-', these are dummy rows to ensure current hour is on line 0 on any page
  croppedData = croppedData.filter((row) => {
    return Object.values(row).some((value) => value !== '-')
  })

  return croppedData
}

function setFooterByPage(
  dataOnPage: TimeSeriesDataTableData[],
  returnIdToDecimals: Record<string, number>,
  columns: TimeSeriesDataTableColumnDef[],
  hideSumAndAverageRow?: boolean,
  hideMinMaxRow?: boolean
): void {
  const groupedData = dataOnPage.reduce((group: Record<string, (string | number)[]>, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (!group[key]) {
        group[key] = []
        group[key].push(key)
      }
      group[key].push(value as number)
    })
    return group
  }, {})

  const formattedData: { return_id: string; values: (number | null)[]; decimal?: number }[] = Object.entries(
    groupedData
  ).map(([return_id, values]) => ({
    return_id,
    values: values.slice(1) as (number | null)[],
    decimal: returnIdToDecimals[normalizeDatasetId(return_id)],
  }))

  const sumAndAverageRows = !hideSumAndAverageRow
    ? [...getAggregatedDatasets(formattedData, 'Sum'), ...getAggregatedDatasets(formattedData, 'Average')]
    : []

  const minAndMaxRows = !hideMinMaxRow
    ? [...getAggregatedDatasets(formattedData, 'Max'), ...getAggregatedDatasets(formattedData, 'Min')]
    : []

  columns.slice(1).forEach((column) => {
    setColumnFooter(column, sumAndAverageRows, minAndMaxRows, hideSumAndAverageRow, hideMinMaxRow)
  })
}

function setColumnFooter(
  column: TimeSeriesDataTableColumnDef,
  sumAndAverageRows: TimeSeriesDataTableData[],
  minAndMaxRows: TimeSeriesDataTableData[],
  hideSumAndAverageRow?: boolean,
  hideMinMaxRow?: boolean
): void {
  column.Footer = () => {
    return (
      <>
        {!hideSumAndAverageRow && (
          <>
            <span>{sumAndAverageRows[0][serializeTableAccessor(column.accessorKey as string)]}</span>
            <br />
            <span>{sumAndAverageRows[1][serializeTableAccessor(column.accessorKey as string)]}</span>
          </>
        )}
        {!hideMinMaxRow && (
          <>
            {!hideSumAndAverageRow && <br />}
            <span>{minAndMaxRows[0][serializeTableAccessor(column.accessorKey as string)]}</span>
            <br />
            <span>{minAndMaxRows[1][serializeTableAccessor(column.accessorKey as string)]}</span>
          </>
        )}
      </>
    )
  }
}

export default function TimeSeriesDataTable({
  columns,
  data,
  tableKey,
  initialPageSize = 24,
  hideDownloadButton = false,
  datasetsInExport = [],
  datasetNames = {},
  hideTopToolbar = false,
  showInitialPageWithCurrentOptimizationHour,
  showInitialPageWithCurrentDay,
  editingMode = 'modal',
  enableEditing = false,
  disableCustomSorting = false,
  tableContainerRef,
  useColumnColorFromItem,
  footerCalculatesPerPage,
  hideSumAndAverageRow,
  hideMinMaxRow,
  returnIdToDecimals,
  onMouseMove,
  max_height,
  sticky_table_header,
  onPaginationChange,
}: TimeSeriesDataTableProps): ReactElement {
  const optimizeViewSnap = useSnapshot(optimizeViewStore)

  const [globalFilter, setGlobalFilter] = useState('')
  const [density, setDensity] = useState('compact')
  const [fontScale, setFontScale] = useState('120%')
  const [fontBoldness, setFontBoldness] = useState('600')
  const [lastTableKey, setLastTableKey] = useState<string>()
  const [dataState, setDataState] = useState(data)
  const [currentDataPointIndex, setCurrentDataPointIndex] = useState(
    initCurrentDataPointIndex(data, false, showInitialPageWithCurrentDay, optimizeViewSnap.currentOptimizationHour)
  ) //which hour should decide what data you see on first render?

  const tableColumns: TimeSeriesDataTableColumnDef[] = useMemo(
    () =>
      disableCustomSorting
        ? columns
        : getColumnsWithSortingEnabled(
          columns,
          setDataState,
          setCurrentDataPointIndex,
          optimizeViewSnap.currentOptimizationHour
        ),
    [columns, disableCustomSorting, optimizeViewSnap.currentOptimizationHour]
  )

  const columnOrder = useMemo(() => {
    return tableColumns.map((c) => c.accessorKey)
  }, [tableColumns])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: Math.max(0, Math.floor(currentDataPointIndex === 0 ? 0 : currentDataPointIndex / initialPageSize)),
    pageSize: initialPageSize,
  })

  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  useEffect(() => {
    if (sticky_table_header) {
      const handleResize = (): void => setScreenHeight(window.innerHeight)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [sticky_table_header, setScreenHeight])

  const dataOnPage = useMemo(() => {
    return cropDataToPagination(dataState, pagination)
  }, [dataState, pagination])

  const [key, setKey] = useState(Math.random())

  useEffect(() => {
    //Quickfix to make footer calculate per page work
    if (footerCalculatesPerPage) {
      setFooterByPage(dataOnPage, returnIdToDecimals, tableColumns, hideSumAndAverageRow, hideMinMaxRow)
      setKey(Math.random()) //Force rerender to update footer
    }
  }, [
    tableColumns,
    dataOnPage,
    hideMinMaxRow,
    hideSumAndAverageRow,
    footerCalculatesPerPage,
    returnIdToDecimals,
  ])

  const reloadTable = useCallback(() => {
    setLoading(true)

    // "isLoading true => false" forces the table to rerender.
    // This is used when data is pasted into the table from excel for example.
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [])

  useEffect(() => {
    if (tableKey !== lastTableKey) {
      setLastTableKey(tableKey)
      reloadTable()
    }
  }, [lastTableKey, reloadTable, tableKey])

  useEffect(() => {
    if (enableEditing) {
      setDataState(data)
      return // Don't add dummy data or modify pagination when editing
    }

    const originalDataPointIndex = initCurrentDataPointIndex(
      data,
      showInitialPageWithCurrentOptimizationHour,
      showInitialPageWithCurrentDay,
      optimizeViewSnap.currentOptimizationHour
    )
    const currentDataPointIndexOnPage =
      originalDataPointIndex === -1 ? -1 : originalDataPointIndex % pagination.pageSize

    // A currentDataPointIndexOnPage of 0 means that the data point we're
    // looking for is already the first row on the page (no need to add padding)
    const nrOfEmptyRows =
      originalDataPointIndex === -1 || currentDataPointIndexOnPage === 0
        ? 0
        : pagination.pageSize - currentDataPointIndexOnPage
    let currentDataPointPageNumber =
      originalDataPointIndex === -1 ? 0 : Math.floor(originalDataPointIndex / pagination.pageSize)

    if (nrOfEmptyRows > 0) {
      currentDataPointPageNumber += 1
    }

    setPagination({ pageIndex: currentDataPointPageNumber, pageSize: pagination.pageSize })

    // Fill first rows with dummy data so that current hour is on line 0 on any page
    const dummyData = data[0] ? Object.fromEntries(Object.keys(data[0]).map((key) => [key, '-'])) : {}
    const dataWithPadding: TimeSeriesDataTableData[] = [...new Array(nrOfEmptyRows).fill(dummyData), ...data]

    const filteredData = dataWithPadding.filter((row) => {
      for (const key in row) {
        if (!globalFilter) {
          return true
        }
        if (row[key].toString().toLowerCase().includes(globalFilter.toLowerCase())) {
          return true
        }
      }
      return false
    })

    setDataState(filteredData)
    setCurrentDataPointIndex(
      initCurrentDataPointIndex(
        filteredData,
        showInitialPageWithCurrentOptimizationHour,
        showInitialPageWithCurrentDay,
        optimizeViewSnap.currentOptimizationHour
      )
    )
  }, [
    data,
    enableEditing,
    globalFilter,
    optimizeViewSnap.currentOptimizationHour,
    pagination.pageSize,
    showInitialPageWithCurrentDay,
    showInitialPageWithCurrentOptimizationHour,
  ])

  useEffect(() => {
    if (density === 'compact') {
      setFontScale('120%')
      setFontBoldness('600')
    } else if (density === 'comfortable') {
      setFontScale('130%')
      setFontBoldness('700')
    } else if (density === 'spacious') {
      setFontScale('140%')
      setFontBoldness('700')
    }
  }, [density])

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: theme.palette.primary,
          secondary: theme.palette.secondary,
          info: theme.palette.info,
        },
      }),
    []
  )

  const [isLoading, setLoading] = useState(false)
  function customStringToISODateTime(customString: string): string {
    return moment(customString, 'D MMM HH:mm').toISOString(true)
  }

  const dataOnPageWithRowData = useMemo(
    () =>
      dataOnPage.map((row) => {
        const rowTimeToISoDateTime = customStringToISODateTime(row.time)
        const rowIsAfterCurrentOptHour = Datetime.isAfter(
          Datetime.toISOString(rowTimeToISoDateTime),
          optimizeViewSnap.currentOptimizationHour
        )
        const rowIsCurrentOptHour =
          row.time === Datetime.toLocalTime(optimizeViewSnap.currentOptimizationHour, 'longDayTextWithoutYear')

        return {
          ...row,
          rowIsAfterCurrentOptHour,
          rowIsCurrentOptHour,
        }
      }),
    [dataOnPage, optimizeViewSnap.currentOptimizationHour]
  )

  const onMrtPaginationChange = useCallback((newPagination: MRT_PaginationState) => {
    setPagination(newPagination)
    if (enableEditing) {
      reloadTable()
    }

    if (onPaginationChange) {
      onPaginationChange(newPagination)
    }
  }, [enableEditing, onPaginationChange, reloadTable])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          key={key}
          enableBottom
          enableTopToolbar={!hideTopToolbar}
          enableStickyHeader={max_height || sticky_table_header ? true : undefined}
          enableStickyFooter={sticky_table_header ? true : undefined}
          editingMode={editingMode}
          enableEditing={enableEditing}
          columns={tableColumns as MRT_ColumnDef<TimeSeriesDataTableData>[]}
          data={dataOnPageWithRowData}
          localization={useUserLocalization()}
          enableColumnFilters={false}
          enableColumnResizing
          enableSorting={false}
          onPaginationChange={onMrtPaginationChange}
          muiTablePaginationProps={{
            rowsPerPageOptions: [6, 12, 24, 48, 72, 96],
          }}
          onGlobalFilterChange={setGlobalFilter}
          manualFiltering={true}
          manualPagination={true}
          rowCount={dataState.length}
          onDensityChange={setDensity}
          state={{
            pagination,
            globalFilter,
            columnOrder: columnOrder ?? [],
            density,
            isLoading,
          }}
          initialState={{
            columnPinning: { left: ['time'] },
            density: density,
            pagination: {
              pageSize: pagination.pageSize,
              pageIndex: pagination.pageIndex,
            },
          }}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <MRT_ToggleDensePaddingButton table={table} color="primary" style={{ padding: '5px' }} />
              <MRT_FullScreenToggleButton table={table} color="primary" style={{ padding: '5px' }} />
              <MRT_ToggleGlobalFilterButton table={table} color="primary" style={{ padding: '5px' }} />
              {!hideDownloadButton && (
                <ExportDatasetsMenu datasets={datasetsInExport} datasetNames={datasetNames} />
              )}
            </Box>
          )}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableFooterCellProps={({ column }) => {
            return {
              align: column.columnDef.align,
              sx: {
                fontFamily: 'monospace, monospace',
                fontSize: '110%',
                fontWeight: '600',
                paddingRight: '8px',
              },
            }
          }}
          muiTableBodyCellProps={({ column, row, table }) => {
            const allColumns = table.getAllColumns()
            const thisColumnIndex = allColumns.findIndex((c) => c.id === column.id)

            let columnColor = 'rgba(255, 255, 255, 1)'

            const { rowIsAfterCurrentOptHour, rowIsCurrentOptHour } = row.original
            // Get hours after current optimization hour

            const configStore = snapshot(uiConfigStore)

            if (rowIsCurrentOptHour) {
              columnColor = '#a1c6e0'
            } else if (rowIsAfterCurrentOptHour && configStore.markHoursAhead) {
              columnColor = '#a5d6a7'
            } else if (useColumnColorFromItem && column.columnDef?.color) {
              columnColor = hexToRgbA(column.columnDef.color, 0.35) //opacity 35% on background colors in table
            } else if (!useColumnColorFromItem && thisColumnIndex % 2) {
              //every even column should have grey background
              columnColor = 'rgba(242, 242, 242, 1)'
            }

            return {
              align: column.columnDef.align,
              sx: {
                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
                fontSize: fontScale,
                fontWeight: fontBoldness,
                fontFamily: 'monospace, monospace',
                borderRight: column.columnDef.borderRight ? '2px solid #000' : 'none',
                backgroundColor: columnColor,
              },
            }
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: max_height ? max_height : sticky_table_header ? screenHeight - 250 : 'inherit',
            },
            ref: tableContainerRef,
            onMouseMove: onMouseMove,
          }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
