import React, { ReactElement, useState } from 'react'

import { dynamicClassName } from 'styles/helper'
import { Button } from 'ui/atoms'

// TODO: We can probably replace react-dropzone with a react aria: https://react-spectrum.adobe.com/react-aria/DropZone.html
import { Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { UseMutationResult } from 'react-query'

import styles from './FileUpload.module.less'

export type FileUploadProps = {
  onClose?: () => void
  onConfirm?: (file: File[]) => void
  onUpload?: (file: File[]) => void
  onFileUpload?: UseMutationResult<unknown, unknown, { file: File }, unknown>
}

export default function FileUpload({ onConfirm, onUpload, onFileUpload }: FileUploadProps): ReactElement {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [errorText, setErrorText] = useState<string>()

  const { t } = useTranslation()

  const onDrop = (acceptedFiles: File[]): void => {
    setErrorText(undefined)
    setUploadedFiles(acceptedFiles)
    if (onUpload) {
      onUpload(acceptedFiles)
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleUpload = (file: File): void => {
    if (onFileUpload) {
      setIsUploading(true)
      onFileUpload.mutate(
        { file },
        {
          onSuccess: () => {
            setIsUploading(false)
            if (onConfirm) {
              onConfirm(uploadedFiles)
            }
          },
          onError: () => {
            setIsUploading(false)
          },
        }
      )
    }
  }

  return (
    <div className={styles.FileUpload_Root}>
      <div
        {...getRootProps({
          className: dynamicClassName({
            [styles.FileUpload_Dropzone]: true,
            [styles.FileUpload_Dropzone__active]: isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
      </div>
      {uploadedFiles.length > 0 && (
        <aside>
          <h4>Files</h4>
          {uploadedFiles.map((file, index) => (
            <li key={index}>
              {file.name} - {(file.size / 1048576).toFixed(2)} MB
            </li>
          ))}
        </aside>
      )}
      <div className={styles.FileUpload_Actions}>
        <div>
          <Button
            secondary
            onClick={() => {
              setUploadedFiles([])
            }}
            disabled={isUploading}
          >
            {t('Clear')}
          </Button>
          <Button
            marginLeft
            primary
            onClick={() => {
              if (uploadedFiles.length > 0) {
                handleUpload(uploadedFiles[0])
              } else {
                setErrorText(t('No file selected'))
              }
            }}
            disabled={isUploading}
            icon={isUploading ? 'fal fa-redo-alt fa-spin' : 'fal fa-upload'}
          >
            {t('Upload')}
          </Button>
        </div>
        <Typography color="error">{errorText}</Typography>
      </div>
    </div>
  )
}
