import { debugFilterStore } from 'store/filter/filter'
import uiConfigStore from 'store/uiConfig/uiConfig'

import { getFuelFromTags } from 'helpers/filterComponent.helper/filterComponent.helper'

export type DatasetAndItem = {
  item: UiConfigPropsItem
  dataset?: Dataset
}

export function itemHasHiddenDefaultTag(
  item: UiConfigPropsItem,
  hiddenDefaultTags: string[],
  activeGroupingTag: string
): boolean {
  const itemTags = item.tags ?? []
  //activeGroupingTag is always top level tag, so we check if tag in itemTags starts with activeGroupingTag and if it exists in hiddenDefaultTags TODO: if we no longer only group on top level we need to change this
  if (itemTags.length !== 0 && activeGroupingTag !== '') {
    return itemTags.some((tag) => tag.startsWith(activeGroupingTag) && hiddenDefaultTags.includes(tag))
  }
  return false
}
export function useGrouping(
  itemsMergedWithTags: UiConfigPropsItem[],
  uiConfigProps: UiConfigProps,
  datasets: Dataset[],
  uid: number,
  tagsHiddenFromGrouping: string[],
  groupingTag: string,
  overriddenGroupingTag?: string,
  tags_config?: Record<string, TagsConfig>
): { datasets: Dataset[] | undefined; items: UiConfigPropsItem[] | undefined } {
  const activeGroupingTag = overriddenGroupingTag ?? groupingTag

  if (activeGroupingTag && itemsMergedWithTags && uiConfigProps?.include_items_in_grouping && datasets.length > 0) {
    //if uiconfig has items with tags and is supposed to be included in aggregation/grouping
    const itemsExcludedFromGraph = itemsMergedWithTags.filter((item) =>
      itemHasHiddenDefaultTag(item, tagsHiddenFromGrouping, activeGroupingTag)
    )
    const itemsIncludedInGrouping = itemsMergedWithTags.filter(
      (item) =>
        !item.exclude_from_grouping && item?.tags && item?.tags?.length !== 0 && !itemsExcludedFromGraph.includes(item)
    ) //only include items in grouping if they have any tags at all, or if they do not have the attribute 'exclude_from_grouping'
    const datasetsIncludedInGrouping = datasets.filter((dataset) =>
      itemsIncludedInGrouping.some((item) => item.data_id === dataset.return_id)
    )

    const itemsExcludedFromGrouping = itemsMergedWithTags.filter(
      (item) => !itemsIncludedInGrouping.includes(item) && !itemsExcludedFromGraph.includes(item)
    ) //items not included in grouping should still be visible in graphs
    const datasetsExcludedFromGrouping = datasets.filter((dataset) =>
      itemsExcludedFromGrouping.some((item) => item.data_id === dataset.return_id)
    )

    const { datasets: groupedDatasets, items: groupedItems } = getGroupedDatasetsAndNewItems({
      uiConfigProps,
      datasets: datasetsIncludedInGrouping,
      itemsWithTags: itemsIncludedInGrouping,
      activeGroupingTag,
      uid,
      tags_config,
    })

    //only items with tags and tags not with exclude_from_grouping
    const mergedDatasets = groupedDatasets.concat(datasetsExcludedFromGrouping)
    const mergedItems = groupedItems.concat(itemsExcludedFromGrouping)

    return { datasets: mergedDatasets, items: mergedItems }
  } else {
    return { datasets: undefined, items: undefined }
  }
}
//get a collection of datasets and items per top level tag (which is then used to sum up the datasets and create new items and datasets)
export function getAllItemsAndDatasetsBasedOnDatasetReturnId(
  groupingTag: string,
  itemsWithTags: UiConfigPropsItem[],
  datasets: Dataset[]
): { [subTag: string]: { [returnId: string]: DatasetAndItem } } {
  const allSubTags: { [subTag: string]: UiConfigPropsItem[] } = {}
  const allSubTagsWithItemAndDataset: { [subTag: string]: { [returnId: string]: DatasetAndItem } } = {}
  itemsWithTags.forEach((item) => {
    item?.tags?.forEach((tag) => {
      const splitTag = tag.split('/') ?? ''
      const groupTag = item.grouping_type && splitTag[1] ? `${splitTag[1]}&&${item.grouping_type}` : splitTag[1] //split grouping of same tag if they have a grouping_tag
      // const groupingSplitTag = item.grouping_tag ? `${tag.split('/')[1]}_${item.grouping_tag}` : tag.split('/')
      if (splitTag[0] === groupingTag && groupTag) {
        //if item has the toplevel tag we are looking for (i.e should be included in the grouping per tag) (and it has a subtag that we should group the item by)

        if (!allSubTags[groupTag]) {
          allSubTags[groupTag] = []
        }
        if (!allSubTagsWithItemAndDataset[groupTag]) {
          allSubTagsWithItemAndDataset[groupTag] = {}
        }
        allSubTagsWithItemAndDataset[groupTag][item.data_id] = {
          item: item,
          dataset: datasets.find((dataset) => dataset.return_id === item.data_id),
        } //add item and dataset to subtag in collection
        allSubTags[groupTag].push(item)
      }
    })
  })

  return allSubTagsWithItemAndDataset
}

interface DatasetsAndItemsPerTagOptions {
  activeGroupingTag: string
  allSubTagsWithItemAndDataset: { [subTag: string]: { [returnId: string]: DatasetAndItem } }
  prefix?: string
  tags_config?: Record<string, TagsConfig>
}

//group(sum) datasets and create new items and datasets per subtag
export function getDatasetsAndItemsPerTag({
  activeGroupingTag,
  allSubTagsWithItemAndDataset,
  prefix,
  tags_config,
}: DatasetsAndItemsPerTagOptions): { newDatasets: Dataset[]; newItems: UiConfigPropsItem[] } {
  const newSumDatasetsForThisTag: Dataset[] = []
  const newSumUiConfigItemsForThisTag: UiConfigPropsItem[] = []
  Object.keys(allSubTagsWithItemAndDataset).forEach((subTag) => {
    const subTagItems = allSubTagsWithItemAndDataset[subTag]
    const firstSourceWithDatasetAndItem = Object.keys(subTagItems).find(
      (source) => subTagItems[source].dataset && subTagItems[source].item
    )
    const dataId = prefix ? `${prefix}sum_of_${subTag}` : `sum_of_${subTag}` //adding prefixes for multi_base_override for e.g horizontal bars

    if (firstSourceWithDatasetAndItem) {
      //can only create aggregation of datasets if there is a dataset and item to use as reference
      const firstItem = subTagItems[firstSourceWithDatasetAndItem] //reference item
      if (!firstItem.dataset) {
        return
      }

      const tag = `${activeGroupingTag}/${subTag}`

      //TODO: as of now the first item in the list decides what the new sum item will look like, this should be changed to a more dynamic approach (when we have decide how to handle this)
      const newUiConfigSumItem: UiConfigPropsItem = {
        ...firstItem.item,
        data_id: dataId,
        tags: [tag],
        color: (tags_config ?? {})[tag]?.color ?? firstItem?.item.color,
        title: subTag,
        fuel_type: firstItem.item.grouping_type ? getFuelFromTags(firstItem.item.tags) : null,
      }

      const newGroupedDataset = sumElementWiseDatasetsToNewDataset(
        dataId,
        firstItem.dataset,
        Object.values(subTagItems).map((item) => item.dataset as Dataset)
      )
      newSumDatasetsForThisTag.push(newGroupedDataset)
      newSumUiConfigItemsForThisTag.push(newUiConfigSumItem)
    }
  })

  return { newDatasets: newSumDatasetsForThisTag, newItems: newSumUiConfigItemsForThisTag }
}

//sum datasets element wise and create new dataset based on the summation
export function sumElementWiseDatasetsToNewDataset(
  returnIdOfNewDataset: string,
  firstDataset: Dataset,
  allDatasetsToBeSummed: Dataset[]
): Dataset {
  const newSumDatasetPerSubTag: Dataset = {
    return_id: returnIdOfNewDataset,
    times: firstDataset.times ?? [],
    values: [],
  }
  let newValues: number[] = []
  allDatasetsToBeSummed.forEach((dataset: Dataset) => {
    const newValuesThisRound: number[] = []
    for (let i = 0; i < firstDataset.times.length; i++) {
      let element = newValues[i] ?? 0

      element += dataset?.values[i] ?? 0
      newValuesThisRound.push(element)
    }

    newValues = newValuesThisRound
  })

  newSumDatasetPerSubTag.values = newValues

  return newSumDatasetPerSubTag
}

type GroupedDatasetsAndNewItemsOptions = {
  uiConfigProps: UiConfigProps
  datasets: Dataset[]
  itemsWithTags: UiConfigPropsItem[]
  activeGroupingTag: string
  uid?: number
  tags_config?: Record<string, TagsConfig>
}

//returns new datasets and items based on the grouping of other datasets and items
export function getGroupedDatasetsAndNewItems({
  uiConfigProps,
  datasets,
  itemsWithTags,
  activeGroupingTag,
  uid,
  tags_config,
}: GroupedDatasetsAndNewItemsOptions): { datasets: Dataset[]; items: UiConfigPropsItem[] } {
  let baseItemsHasPrefix = false
  let returnIdPrefixes: string[] = []
  if (uiConfigProps.multi_base_items_override) {//if we use multi_base_items_override we need to group by the return_id_prefix (e.g Horizontal bars in follow-up)

    returnIdPrefixes = uiConfigProps.multi_base_items_override.map((item) => item.return_id_prefix) as string[]

    //unsure what happens if only one has a prefix, and the other one is undefined. Will they not group?
    if (returnIdPrefixes.some((prefix) => prefix !== undefined)) {
      baseItemsHasPrefix = true
    }
  }

  if (baseItemsHasPrefix) {

    if (uid) {
      uiConfigStore.uiConfigs[uid].props.include_items_in_grouping = true //set include_items_in_grouping to true so that the items are included in the grouping (and we can avoid parsing with uiconfig in e.g horizontal bars)
    }

    const allNewDatasets: Dataset[] = []
    const allNewItems: UiConfigPropsItem[] = []

    returnIdPrefixes.forEach((prefix) => {
      const itemsWithPrefix = itemsWithTags.filter((item) => item.data_id.startsWith(prefix))
      const datasetWithPrefix = datasets.filter((dataset) => dataset.return_id.startsWith(prefix))
      const allSubTagsWithItemAndDataset: { [subTag: string]: { [returnId: string]: DatasetAndItem } } =
        getAllItemsAndDatasetsBasedOnDatasetReturnId(activeGroupingTag, itemsWithPrefix, datasetWithPrefix)

      const { newDatasets: newSumDatasets, newItems: newSumItems } = getDatasetsAndItemsPerTag({
        activeGroupingTag,
        allSubTagsWithItemAndDataset,
        prefix,
        tags_config,
      })

      allNewDatasets.push(...newSumDatasets)
      allNewItems.push(...newSumItems)
    })

    return { datasets: allNewDatasets, items: allNewItems }
  } else {
    const allSubTagsWithItemAndDataset: { [subTag: string]: { [returnId: string]: DatasetAndItem } } =
      getAllItemsAndDatasetsBasedOnDatasetReturnId(activeGroupingTag, itemsWithTags, datasets)

    if (uid) {
      debugFilterStore.datasetAndItemPerSubTagPerId[uid.toString()] = allSubTagsWithItemAndDataset
    }
    const { newDatasets: newSumDatasets, newItems: newSumItems } = getDatasetsAndItemsPerTag({
      activeGroupingTag,
      allSubTagsWithItemAndDataset,
      tags_config,
    })
    return { datasets: newSumDatasets, items: newSumItems }
  }
}
