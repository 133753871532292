import React, { ReactElement, useState } from 'react'

import { Thread } from 'api/bulletinBoard/bulletinBoard.api'

import { Grid, Card, Icon, Typography, Button } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import GeneralPost from './GeneralPost/GeneralPost'
import BulletinBoardTextField from './TextField/TextField'
import styles from './Thread.module.less'
import Like from './components/Like'

type BulletinBoardThreadProps = {
  thread: Thread
}

export default function BulletinBoardThread({ thread }: BulletinBoardThreadProps): ReactElement {
  const { t } = useTranslation()

  const [showComments, setShowComments] = useState(false)
  const handleOnClick = (commentShow: boolean): void => {
    setShowComments(commentShow)
  }
  const commentCount = Object.keys(thread.comments).length

  const postHasCommentsTitle = `${t(`The post has `)} ${commentCount}
  ${commentCount > 1 ? t(`comments`) : t(`comment`)}`

  return (
    <Grid container>
      <Card className={styles.Thread_Thread}>
        <GeneralPost post={thread} />
        <Grid container direction="row">
          <Grid item>
            <Like post={thread} />
          </Grid>

          <Grid item>
            <Button
              className={styles.Thread_ShowCommentButton}
              onClick={showComments === false ? () => handleOnClick(true) : () => handleOnClick(false)}
            >
              <Icon
                style={{ display: `inline-block`, color: `grey` }}
                className={classNames(styles.Thread_CommentIcon, `fal fa-comments`)}
                fontSize="medium"
              />
              <Typography className={styles.Thread_ShowCommentsText}>
                {commentCount > 0 ? postHasCommentsTitle : t('Comment')}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {showComments &&
          thread.comments.map((comment, index) => {
            return (
              <Grid key={index}>
                <Card className={styles.Thread_Comment}>
                  <GeneralPost post={comment} />
                </Card>
              </Grid>
            )
          })}
        {showComments && (
          <Grid container className={styles.Thread_CommentField} direction="row" alignItems="center">
            <BulletinBoardTextField type="comment" ids={[thread.id]} content={thread.comments.length} />
          </Grid>
        )}
      </Card>
    </Grid>
  )
}
