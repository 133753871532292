import React, { ReactElement, useMemo, useState } from 'react'

import { useAllUsersFromOrganization, useDeleteUser } from 'api/admin/admin.api'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import ManageField from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/PreviousSettingsTables/components/ManageField/ManageField'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import Datetime from 'utils/datetime/datetime'

import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, TablePagination, DialogContent, Tooltip, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import EditUserModal from '../EditUserModal/EditUserModal'
import ViewUserPermissions from '../ViewUserPermissions/ViewUserPermissions'

import styles from './UsersList.module.less'

export type EditUserData = {
  first_name: string,
  last_name: string,
  email: string,
  primary_system: number | undefined,
  system_roles: [
    {system: number | undefined, role: number | undefined}
  ]
}

type SortableColumns = 'first_name' | 'email' | 'primary_system' | 'created_at'

function getUserToolTipLabel(user: User): string {
  const toolTipLabel = user?.system_roles?.reduce((acc: string[], curr) => {
    if (!acc.includes(curr.system_name)) {
      acc.push(curr.system_name)
    }
    return acc
  }, [])

  return toolTipLabel?.join(', ')
}

export default function UsersList(): ReactElement {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [isOpenDialogUser, setIsOpenDialogUser] = useState<User | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<User | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [sortColumn, setSortColumn] = useState<SortableColumns>('first_name')

  const { t } = useTranslation()
  const { mutateAsync: deleteUser } = useDeleteUser()
  const { data: allUsers = [] } = useAllUsersFromOrganization()

  const { usersOnPage, filteredUsers } = useMemo(() => {
    const filterByTerm = (user: User): boolean => {
      const userString = JSON.stringify(user).toLowerCase()
      return userString.includes(searchTerm.toLowerCase())
    }

    const filteredUsers = allUsers.filter(filterByTerm)

    // Sort table
    filteredUsers?.sort((a, b) => {
      let valueA = a[sortColumn] ?? ''
      let valueB = b[sortColumn] ?? ''

      if (sortColumn === 'primary_system') {
        valueA = a.primary_system?.display_name
        valueB = b.primary_system?.display_name
      }

      if (direction === 'asc') {
        return valueA > valueB ? 1 : -1
      } else {
        return valueA < valueB ? 1 : -1
      }
    })

    // Pagination
    const startIndex = page * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const usersOnPage = filteredUsers.slice(startIndex, endIndex)
    return { usersOnPage, filteredUsers }
  }, [allUsers, searchTerm, direction, sortColumn, page, rowsPerPage])


  function handleSort(column: SortableColumns): void {
    if (column === sortColumn) {
      setDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setDirection('asc')
    }
  }

  return (
    <>
      <TextField
        margin='normal'
        fullWidth
        label={t(`Search`)}
        aria-label={t(`Search`)}
        variant="outlined"
        placeholder={t(`Search`)}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t(`Name`)}
                <Button onClick={() => handleSort('first_name')} tight marginLeft>
                  {sortColumn === 'first_name' ? (direction === 'asc' ? <Icon icon={'fas fa-arrow-up'} /> : <Icon icon={'fas fa-arrow-down'} /> ) : <Icon icon={'fas fa-arrow-up'} />}
                </Button>
              </TableCell>
              <TableCell>
                {t(`E-mail`)}
                <Button onClick={() => handleSort('email')} tight marginLeft>
                  {sortColumn === 'email' ? (direction === 'asc' ? <Icon icon={'fas fa-arrow-up'} /> : <Icon icon={'fas fa-arrow-down'} /> ) : <Icon icon={'fas fa-arrow-up'} />}
                </Button>
              </TableCell>
              <TableCell>
                {t(`System`)}
                <Button onClick={() => handleSort('primary_system')} tight marginLeft>
                  {sortColumn === 'primary_system' ? (direction === 'asc' ? <Icon icon={'fas fa-arrow-up'} /> : <Icon icon={'fas fa-arrow-down'} />) : <Icon icon={'fas fa-arrow-up'} />}
                </Button>
              </TableCell>
              <TableCell>
                {t(`Registered since`)}
                <Button onClick={() => handleSort('created_at')} tight marginLeft>
                  {sortColumn === 'created_at' ? (direction === 'asc' ? <Icon icon={'fas fa-arrow-up'} /> : <Icon icon={'fas fa-arrow-down'} /> ) : <Icon icon={'fas fa-arrow-up'} />}
                </Button>
              </TableCell>
              <TableCell>{t(`Permissions`)}</TableCell>
              <TableCell>{t(`Manage`)}</TableCell>

            </TableRow>
          </TableHead>

          {usersOnPage?.map((user: User) => (
            <TableBody key={`${user.id}`}>
              <TableRow className={styles.UsersList_UserRow}>
                <TableCell>
                  {user?.first_name ? <p>{user?.first_name} {user?.last_name}</p> : <p >-</p>}
                </TableCell>
                <TableCell>
                  {user?.email}
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={getUserToolTipLabel(user)}>
                    <div>{user.primary_system?.display_name ?? <p>-</p>}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {Datetime.toLocalTime(user.created_at as ISODateTime, 'onlyDate')}
                </TableCell>
                <TableCell>
                  <Button
                    primary
                    onClick={() => {
                      setIsOpenDialogUser(user)
                    }}
                  >
                    {t(`Show`)}
                  </Button>
                </TableCell>
                <TableCell>
                  <ManageField
                    onEditClick={() => setSelectedUser(user)}
                    onDeleteClick={() => setConfirmDeleteUser(user)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          labelRowsPerPage={t(`Rows per page:`)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t(`of`)} ${count}`}
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage)
          }}
          onRowsPerPageChange={(ev) => {
            setRowsPerPage(Number(ev.target.value))
            setPage(0)
          }}
        />
      </TableContainer>

      {isOpenDialogUser && (
        <Dialog
          fullWidth
          maxWidth='md'
          open={!!isOpenDialogUser}
          aria-labelledby="Permissions"
        >
          <CustomDialogTitle title={t(`Permission`)} handleClose={() => {
            setIsOpenDialogUser(null)
          }}
          />

          <DialogContent>
            <ViewUserPermissions user={isOpenDialogUser} />
          </DialogContent>
        </Dialog>
      )}

      {confirmDeleteUser && (
        <ConfirmDialog
          confirmButtonStyle={{ backgroundColor: '#e0182d' }}
          open={!!confirmDeleteUser}
          onClose={() => {
            setConfirmDeleteUser(null)
          }}
          onConfirm={() => {
            setConfirmDeleteUser(null)
            setIsOpenDialogUser(null)
            deleteUser({ id: confirmDeleteUser.id })
          }}
          textObject={{
            title: t(`Do you really want to delete this user?`),
            text: t(
              `This user will be deleted and set as inactive, are you completely sure you want to delete this user?`
            ),
            confirm: t(`Remove user`),
          }}
        />
      )}

      {selectedUser && (
        <EditUserModal
          user={selectedUser}
          closeModal={() => {
            setSelectedUser(null)
          }}
        />
      )}
    </>
  )
}
