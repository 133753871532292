import { 
  TreeSelectItem,
  RecursiveMap,
  KeyPathValueTuple,
} from './types'

/**
 * Converts an array of paths into a hierarchical structure suitable for use with a TreeSelect component.
 *
 * @param paths - An array of strings representing paths, where each path is a string of labels separated by a defined path separator.
 * @param separator - A string that separates the labels in the paths.
 * @returns An array of TreeSelectItem objects representing the hierarchical structure of the paths.
 */
export function extractTreeSelectItems(paths: string[], separator: string): TreeSelectItem[] {
  const treeSelectItems: TreeSelectItem[] = []

  paths.forEach(label => {
    const parts = label.split(separator)
    let currentLevel = treeSelectItems

    parts.forEach((part, index) => {
      const id = parts.slice(0, index + 1).join(separator)
      let existingItem = currentLevel.find(item => item.id === id)

      if (!existingItem) {
        existingItem = {
          id,
          label: part,
          children: [],
        }
        currentLevel.push(existingItem)
      }

      currentLevel = existingItem.children
    })
  })
  return treeSelectItems
}

/**
* A generator function that performs a depth-first traversal of a nested object structure.
* 
* @param rootKey - The root key to prepend to all paths.
* @param obj - The nested object to traverse.
* @yields A tuple containing the path to the current key and the value at that key.
*/
export function* walkDepthFirst(
  rootKey: string, 
  obj: RecursiveMap<string, number>
): Generator<KeyPathValueTuple<string, number>, void, undefined> {
  const walk = function* (o: RecursiveMap<string, number>, path: string[] = [rootKey]): Generator<KeyPathValueTuple<string, number>, void, undefined> {
    for (const key of Object.keys(o)) {
      if (typeof o[key] == 'object' && o[key] !== null) {
        yield* walk(o[key] as RecursiveMap<string, number>, [...path, key])
      } else {
        yield [[...path, key], o[key]]
      }
    }
  }

  if (typeof obj == 'undefined') return

  yield* walk(obj)
}