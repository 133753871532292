import React, { useState, ReactElement } from 'react'

import { DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP } from 'api/dataset/dataset.api'
import { postStat } from 'api/stats/stats.api'
import { useFollowupPeriod } from 'hooks/utilsHooks/utilsHooks'
import { resetZoom } from 'store/zoom/zoom'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import FollowupHeader from 'ui/components/Followup/FollowupHeader/FollowupHeader'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import UiConfig from 'ui/uiConfig/UiConfig'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { useHasAnchorComponent } from 'helpers/global.helper/global.helper'

import styles from './FollowupProductionView.module.less'
import { followUpOptInfoSteps, legacySteps, widgetConstants } from './FollowupProductionViewTour'

export type handlePeriodChange = (period: Period) => void

export default function FollowupProductionView(): ReactElement {
  const [runTour, setRunTour] = useState<null | number>(null)
  const search = useLocation().search
  const { t } = useTranslation()
  const params = new URLSearchParams(search)
  const { activeSystem } = useAuth()
  const isRegularSystem = activeSystem?.tier === `regular`

  const startTime: string | null = params.get('startTime')
  const endTime: string | null = params.get('endTime')
  const history = useHistory()

  const [followupPeriod, setFollowupPeriod] = useFollowupPeriod(
    startTime ? moment(startTime) : undefined,
    endTime ? moment(endTime) : undefined
  )

  function followupPeriodChange(period: Period): void {
    setFollowupPeriod(period)
    history.push(`/followupproduction`)
    resetZoom()
  }

  const hasPublishedAnchorComponent = useHasAnchorComponent('followup_production')

  const tourSteps = hasPublishedAnchorComponent ? followUpOptInfoSteps : legacySteps

  return (
    <View
      header={
        <>
          <ModuleHeader
            infoHeader={t(`Follow-up - Production`)}
            onInfoButtonClick={() => {
              setRunTour(0)
              postStat(`header-info-button`, `click`)
            }}
          >
            <FollowupHeader period={followupPeriod} handlePeriodChange={followupPeriodChange} isFollowupProduction />
          </ModuleHeader>
          <div className={styles.FollowupProductionView_HeaderTabs__portal} id="portal_for_tabs" />
        </>
      }
      stickyHeader
    >
      <div className={styles.FollowupProductionView}>
        <Tour
          isSingle={!!runTour}
          run={runTour !== null}
          setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
          steps={tourSteps}
        />
        {
          <>
            <UiConfig
              type="follow_up_prod_kpi"
              tourId={widgetConstants.FOLLOWUP_PRODUCTION_WIDGET_2}
              datasetStartTime={followupPeriod.startTime}
              datasetEndTime={followupPeriod.endTime}
              datasetRefreshToken={DATASET_FOR_UI_CONFIG_REFRESH_FOLLOW_UP}
            />
            <div id="portal_for_followupprodkpi" />
          </>
        }
        <UiConfig
          type="followup_production"
          tourId={widgetConstants.FOLLOWUP_PRODUCTION_WIDGET_1}
          datasetStartTime={followupPeriod.startTime}
          datasetEndTime={followupPeriod.endTime}
        />
        {isRegularSystem && (
          <UiConfig
            type={`followup_forecast`}
            tourId={widgetConstants.FOLLOWUP_FORECAST_GRID}
            datasetStartTime={followupPeriod.startTime}
            datasetEndTime={followupPeriod.endTime}
          />
        )}

        <QueueProgressBar />
      </div>
    </View>
  )
}
