import React, { ReactElement, useEffect, useState } from 'react'

import Button from 'ui/atoms/Button/Button'
import Inline from 'ui/atoms/Inline/Inline'
import TextField from 'ui/atoms/TextField/TextField'

import { useTranslation } from 'react-i18next'

import { clone } from 'helpers/global.helper/global.helper'

type RecordInputProps = {
  value: Record<string, string>;
  onChange: (value: Record<string, string>) => void;
};

export default function RecordInput({ value, onChange }: RecordInputProps): ReactElement {
  const [aboutToCreateKey, setAboutToCreateKey] = useState('')
  const [aboutToCreateValue, setAboutToCreateValue] = useState('')
  const [localValue, setLocalValue] = useState(value)
  const { t } = useTranslation()

  const handleChange = (key: string, newValue: string): void => {
    const updatedValue = { ...localValue, [key]: newValue }
    setLocalValue(updatedValue)
    onChange(updatedValue)
  }

  const removeKey = (key: string): void => {
    const updatedValue = clone(localValue)
    delete updatedValue[key]
    setLocalValue(updatedValue)
    onChange(updatedValue)
  }

  const addNewKey = (): void => {
    if (!aboutToCreateKey || !aboutToCreateValue) {
      return
    }

    handleChange(aboutToCreateKey, aboutToCreateValue)
    setAboutToCreateKey('')
    setAboutToCreateValue('')
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div>
      {Object.keys(localValue).map((key) => (
        <Inline key={key}>
          <TextField
            label={key}
            type="text"
            value={localValue[key]}
            onChange={(v) => handleChange(key, String(v))}
          />

          <Button marginLeft danger onClick={() => removeKey(key)}>{t('Delete')}</Button>
        </Inline>
      ))}

      <hr />

      <Inline spaceBetween>
        <TextField
          label="key"
          value={aboutToCreateKey}
          onChange={v => setAboutToCreateKey(String(v))}
        />
        <TextField
          label="value"
          value={aboutToCreateValue}
          onChange={v => setAboutToCreateValue(String(v))}
          onEnter={addNewKey}
        />
        <Button marginLeft primary
          disabled={aboutToCreateKey === ''}
          onClick={addNewKey}>
          {t('Add')}
        </Button>
      </Inline>
    </div>
  )
}