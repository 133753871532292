import React, { ReactElement, useState } from 'react'

import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'

import { Button, Grid, DialogActions, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './HandoverActionButtons.module.less'

type HandoverActionButtonsProps = {
  handleClose(): void
  onSubmit: () => void
  onSave: () => void
  resetForm(): void
  isDraft?: boolean
  isSubmitting?: boolean
  disabled?: boolean
}

export default function HandoverActionButtons({
  handleClose,
  onSubmit,
  onSave,
  resetForm,
  isDraft,
  isSubmitting,
  disabled,
}: HandoverActionButtonsProps): ReactElement {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  return (
    <>
      <DialogActions>
        <Grid
          container
          alignItems="flex-end"
          spacing={1}
          className={styles.HandoverActionButtons_Container}
          direction="row"
        >
          <Grid item container justifyContent="flex-start" alignItems="flex-start" spacing={2} xs={6}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={styles.HandoverActionButtons_CancelButton}
                onClick={() => (disabled ? handleClose() : setOpen(true))}
                disableRipple={true}
              >
                {t(`Cancel`)}
              </Button>
            </Grid>
            {!disabled && (
              <Grid item>
                <Button color="secondary" className={styles.HandoverActionButtons_ClearButton} onClick={resetForm}>
                  <Icon className="fal fa-trash-alt" />
                  {isDraft ? t(`Delete draft`) : t(`Clear fields`)}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="flex-end" direction="column" spacing={2} xs={6}>
            {isSubmitting && (
              <Grid item xs={6}>
                <InfoBanner text={t(`Your handover is being saved`)} />
              </Grid>
            )}
            {!disabled && (
              <Grid item container justifyContent="flex-end" alignItems="flex-end" direction="row" spacing={2} xs={6}>
                <Grid item>
                  <ContainedIconButton
                    color="primary"
                    onClick={() => {
                      onSave()
                    }}
                    disabled={isSubmitting}
                    icon="fal fa-save"
                    label={t(`Save draft`)}
                  />
                </Grid>
                <Grid item>
                  <ContainedIconButton
                    color="primary"
                    onClick={() => {
                      onSubmit()
                    }}
                    disabled={isSubmitting}
                    icon="fal fa-paper-plane"
                    label={t(`Send`)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogActions>

      <ConfirmDialog
        open={open}
        textObject={{
          title: t(`Are you sure you want to cancel your shift handover?`),
          text: t(
            `All your progress will be lost and you have to redo the shift handover again if you close this window.`
          ),
        }}
        onClose={() => setOpen(false)}
        onConfirm={() => handleClose()}
      />
    </>
  )
}
