import React, { ReactElement, ChangeEvent } from 'react'

import FormRow from 'ui/components/FormRow/FormRow'

import { TextField, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './PropertyMenu.module.less'

export type PropertyChoice = {
  value: number
  name: string
}

type PropertyMenuProps = {
  disabled?: boolean
  value: number | undefined
  choices: PropertyChoice[]
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function PropertyMenu({
  disabled,
  value,
  choices,
  handleChange,
}: PropertyMenuProps): ReactElement<PropertyMenuProps> {
  const { t } = useTranslation()

  return (
    <>
      <FormRow icon="fal fa-cog">
        <TextField
          margin="normal"
          id="objectProperty"
          name="objectProperty"
          select
          label={t(`Property`)}
          variant="outlined"
          fullWidth
          defaultValue=""
          disabled={disabled}
          value={value}
          onChange={handleChange}
          SelectProps={{
            MenuProps: {
              className: styles.PropertyMenu_Menu,
            },
          }}
        >
          {choices.map((choice) => (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.name}
            </MenuItem>
          ))}
        </TextField>
      </FormRow>
    </>
  )
}
