import React, { useMemo } from 'react'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

type DatasetTableHeaderProps = {
  title: string
  sub_title?: string
  unit?: string
}

import styles from './DatasetTableHeader.module.less'


export default function DatasetTableHeader({ title, sub_title, unit }: DatasetTableHeaderProps): JSX.Element {
  const { titleLength, formattedTitle, titleWords } = useMemo(() => {
    const len = title.split(/[\s,]+/).length
    const formatted =
      `${title
        .split(/[\s,]+/)
        .splice(0, 2)
        .toString()
        .replace(',', ' ')}`
    const words: string[] = title
      .split(/[\s,]+/)
      .splice(2, len)
      .map((word) => {
        if (word === ':') {
          return ''
        }
        return word ? ` ${word}` : ``
      })

    return { titleLength: len, formattedTitle: formatted, titleWords: words }
  }, [title])
  return (
    <div className={styles.DatasetTableHeader}>
      {titleLength < 3 && <span>{`${getPrettyName(title)}`}</span>}
      {titleLength >= 3 && (
        <>
          <span>{formattedTitle}</span>
          {titleWords.map((word, index) => (
            <span key={`title-word-${index}`}>{` ${word}`}</span>
          ))}
        </>
      )}
      <br />
      {sub_title && (
        <>
          <span style={{ whiteSpace: 'pre-wrap'}}>{sub_title ? `${sub_title}` : ``}</span>
          <br />
        </>
      )}
      <span style={{ whiteSpace: 'pre-wrap', fontWeight: '200', fontSize: '12px'}}>{unit ? `${unit}` : ``}</span>
    </div>
  )
}
