import React, { ReactElement, useEffect, useState } from 'react'

import { HeatSupplier, useHeatSuppliers } from 'api/heatSuppliers/heatSuppliers.api'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'


import SupplierViewHeader from './components/SupplierViewHeader/SupplierViewHeader'
import SupplierViewTabs from './components/SupplierViewTabs/SupplierViewTabs'

function SupplierViewContent({
  orderedSuppliers,
  currentDate,
  setCurrentDate,
}: {
  orderedSuppliers: HeatSupplier[]
  currentDate: Moment
  setCurrentDate: (arg0: Moment) => void
}): ReactElement {
  const { t } = useTranslation()

  const [supplier, setSupplier] = useState<HeatSupplier | undefined>(orderedSuppliers[0])
  useEffect(() => setSupplier(orderedSuppliers[0]), [orderedSuppliers])

  function handleChange(_event: React.ChangeEvent<unknown>, newValue: number): void {
    const selectedSupplier = orderedSuppliers.find((supplier) => supplier.id === newValue)
    setSupplier(selectedSupplier)
  }
  if (orderedSuppliers.length === 0)
    return (
      <>
        <Typography variant="h2">{t(`There are no suppliers registered on this system`)}</Typography>
      </>
    )

  return (
    <>
      <SupplierViewHeader
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        heatSuppliers={orderedSuppliers}
        supplierId={supplier?.id}
      />
      <SupplierViewTabs
        currentDate={currentDate}
        heatSuppliers={orderedSuppliers}
        supplier={supplier}
        handleChange={handleChange}
      />
    </>
  )
}

export default function SupplierView(): ReactElement {
  const [currentDate, setCurrentDate] = useState(moment().add(1, `d`))
  const { data: heatSuppliers, status: heatSupplierStatus } = useHeatSuppliers()
  const orderedSuppliers = heatSuppliers?.sort((a, b) => a.display_name.localeCompare(b.display_name))

  return (
    <StatusComponent
      data={{ orderedSuppliers }}
      status={[heatSupplierStatus]}
      Component={SupplierViewContent}
      ComponentProps={{ currentDate, setCurrentDate }}
    />
  )
}
