import { apiClient } from 'api/apiClient/apiClient'
import alertStore from 'store/alert/alert'	
import authStore from 'store/auth/auth'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { initDbSApiToken } from 'utils/dbs/dbs'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const USERS_QUERY_KEY = `users`

export function useUser() {
  const { user } = useAuth()

  return useQuery<User>(
    [USERS_QUERY_KEY, user?.id],
    async () => {
      const resUser = await apiClient<User>(`users/${user?.id}`)
      const newLanguage = resUser?.settings?.language

      // Sync client language with user language
      if (newLanguage && newLanguage !== i18n.language) {
        localStorage.setItem('language', i18n.language)

        resUser.settings = { ...resUser.settings, language: i18n.language as LanguageCode }
        await apiClient<User>(`users/${resUser.id}`, {
          method: `PATCH`,
          data: {
            settings: resUser.settings,
          },
        })
      }
      authStore.user = resUser

      await initDbSApiToken(resUser)
      return resUser
    },
    {
      enabled: !!user,
    }
  )
}

export function useUserMutation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: number; data: User }) =>
      apiClient<User>(`users/${id}`, {
        method: `PATCH`,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          system: data.system,
          settings: data.settings,
        },
      }),
    {
      onMutate: async ({ data }: { data: User }) => {
        const key = [USERS_QUERY_KEY, data.id]
        // Canceling any conflicting queries to not overwrite the optimistic update
        await queryClient.cancelQueries(key)

        // Saving previous users for rollback if error
        const previousUsers = queryClient.getQueryData<User | undefined>(key)

        // Optimistically remove user
        queryClient.setQueryData<User | undefined>(key, data)

        // Return rollback function
        return () => queryClient.setQueryData(key, previousUsers)
      },
      onError: (error, id, rollback) => {
        alertStore.error(t(`We could not update your personal information, please try again`))
        rollback?.()
      },
      onSuccess: () => {
        alertStore.success(t(`Personal information has been updated`))
        queryClient.invalidateQueries([USERS_QUERY_KEY])
      },
    }
  )
}
