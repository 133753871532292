import React, { ReactElement } from 'react'

import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'

import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Joyride, { Step, CallBackProps, STATUS, TooltipRenderProps } from 'react-joyride'

type TourProps = {
  run: boolean
  setRunState: (arg0: boolean) => void
  steps: Array<Step>
  isSingle?: boolean
  dialogWidth?: number
}

function Tour({ run, setRunState, steps, isSingle, dialogWidth }: TourProps): ReactElement {
  const finalSteps = steps.map(({ target, ...rest }) => ({
    target: `.` + target,
    disableBeacon: true,
    ...rest,
  }))

  function handleJoyrideCallback(data: CallBackProps): void {
    const { status } = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setRunState(false)
    }
  }

  const CustomJoyRideTooltip = ({
    continuous,
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps): JSX.Element => {
    const { t } = useTranslation()

    return (
      <Grid container {...tooltipProps} style={{ background: `white`, width: dialogWidth ? dialogWidth : 700, padding: 20, borderRadius: 10 }}>
        <Grid item xs={12}>
          {step.title && (
            <Typography variant="h1" display="block">
              {t(`${step.title}`)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {step.content && (
            <Typography style={{ whiteSpace: `pre-line`, marginTop: 10 }}>
              {typeof step.content === 'string' ? t(`${step.content}`) : step.content}
            </Typography>
          )}
        </Grid>
        <Grid item container direction="row" justifyContent="space-between" style={{ marginTop: 10 }}>
          <Grid item>
            {!isLastStep && (
              <Button {...skipProps}>
                <Typography id="cancel">{t(`Cancel`)}</Typography>
              </Button>
            )}
          </Grid>
          <Grid item>
            {index > 0 && (
              <ContainedIconButton
                icon="fal fa-angle-left"
                label={t(`Back`)}
                color="primary"
                {...backProps}
                style={{ marginRight: 10 }}
              />
            )}
            {continuous && !isLastStep && (
              <ContainedIconButton
                icon="fal fa-angle-right"
                color="primary"
                label={t(`Next`)}
                rightIcon
                {...primaryProps}
              />
            )}
            {isLastStep && (
              <ContainedIconButton icon="fal fa-times" color="secondary" label={t(`Close`)} {...primaryProps} />
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const { t } = useTranslation()

  return (
    <>
      {run && (
        <Joyride
          tooltipComponent={CustomJoyRideTooltip}
          callback={handleJoyrideCallback}
          continuous={isSingle ? false : true}
          run={run}
          scrollToFirstStep={true}
          showProgress
          showSkipButton
          disableScrolling={true}
          disableOverlayClose={false}
          steps={finalSteps}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: `#fff`,
              backgroundColor: `#fff`,
              overlayColor: `rgba(0,0,0,0.4)`,
              primaryColor: `#004f5e`,
              textColor: `#000`,
              width: 900,
            },
            buttonClose: {
              display: `none`,
            },
          }}
          locale={{
            back: t(`Back`),
            close: t(`Close`),
            last: t(`Close`),
            next: t(`Next`),
            skip: t(`Cancel`),
          }}
        />
      )}
    </>
  )
}

export default Tour
