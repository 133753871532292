import React, { ReactElement, useState } from 'react'

import { useGetEvent } from 'api/events/events.api'
import Icon from 'ui/atoms/Icon/Icon'

import { useTranslation } from 'react-i18next'

import EventPostViewModal from '../EventPostViewModal/EventPostViewModal'
import { EventPost } from '../events.helper'

import styles from './DisplayEventContainer.module.less'

type DisplayEventContainerProps = {
  operationalEventId: number
  operationalEvent?: EventPost | null
}

export default function DisplayEventContainer({operationalEventId, operationalEvent}: DisplayEventContainerProps): ReactElement {
  const { t } = useTranslation()
  const fetchEvent = useGetEvent(operationalEvent ? undefined : operationalEventId)
  const data = operationalEvent ?? fetchEvent.data
  const [openEventPostModal, setOpenEventPostModal] = useState(false)

  return (
    <>
      <div className={styles.DisplayEventContainer_OperationalEventContainer}>
        <Icon icon="fal fa-calendar-week" large onClick={() => setOpenEventPostModal(true)} />
        <p
          onClick={() => {
            if (operationalEvent === undefined || operationalEvent === null) {
              setOpenEventPostModal(true)
            }
          }}
        >
          {t('Event')}: {data?.name}
        </p>
      </div>
      {openEventPostModal && (
        <EventPostViewModal
          selectedEventPostId={data?.id}
          closeModal={() => setOpenEventPostModal(false)} />
      )}
    </>
  )
}
