import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { useEventCreateMutation, useGetAllEvents, useGetAllEventTags } from 'api/events/events.api'
import alertStore from 'store/alert/alert'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import Tour from 'ui/components/Tour/Tour'
import ResetFilters from 'ui/molecules/ResetFilters/ResetFilters'
import { ToggleButton } from 'ui/molecules/ToggleButton/ToggleButton'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'
import { useHistory, useParams } from 'react-router-dom'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import MultiLevelDropdown from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown'

import styles from './EventsView.module.less'
import EventPostModal from './components/EventPostModal/EventPostModal'
import EventsCalendar from './components/EventsCalendar/EventsCalendar'
import EventsTable from './components/EventsTable/EventsTable'
import EventsTour from './components/EventsTour/EventsTour'
import { EventPost, EventStatus, getEventFields } from './components/events.helper'

const widgetConstants = {
  EVENTS_WIDGET_1: `item-1`,
  EVENTS_WIDGET_2: `item-2`,
  EVENTS_WIDGET_3: `item-3`,
  EVENTS_WIDGET_4: `item-4`,
  EVENTS_WIDGET_5: `item-5`,
}


export default function EventsView(): ReactElement {
  const { t } = useTranslation()
  const { systemId, activeSystem } = useAuth()
  const primaryDigitalTwin = activeSystem?.primary_digital_twin

  // Include all tags as this is done in the filter component
  // which causes a cache miss in React Query unless we use the same
  // query key here.
  const { data: digitalTwin, isLoading: isLoadingDigitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true, true)

  const { data: allEventTagsData } = useGetAllEventTags(systemId)
  const { mutateAsync: createEventPost } = useEventCreateMutation()
  const history = useHistory()
  const { eventId } = useParams<{ eventId?: string }>()

  const eventsSteps: Array<Step> = useMemo(() => [
    {
      target: widgetConstants.EVENTS_WIDGET_1,
      content: <EventsTour />,
      title: `Welcome to the Event module`,
    },
    {
      target: widgetConstants.EVENTS_WIDGET_2,
      content: `Create new event - Content`,
      title: `Create a new event`,
    },
    {
      target: widgetConstants.EVENTS_WIDGET_3,
      content: `Events calendar - Content`,
      title: `Events - Calendar`,
    },
    {
      target: widgetConstants.EVENTS_WIDGET_4,
      content: `Events table - Content`,
      title: `Events - Table`,
    },
    {
      target: widgetConstants.EVENTS_WIDGET_5,
      content: `Filter events - Content`,
      title: `Filter events`,
    },
  ], [])

  const hasCreateOperationalEventsAccess = useHasPermission('create_operational_events')

  const allEventTags = useMemo(() => allEventTagsData || [], [allEventTagsData])

  const [runTour, setRunTour] = useState<null | number>(null)
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false)
  const [openSelectedEventPostModal, setOpenSelectedEventPostModal] = useState(false)
  const [filterIsCollapsed, setFilterCollapsed] = useState<boolean>(true)

  const [filteredEvents, setFilteredEvents] = useState<EventPost[]>([])
  const [filterTags, setFilterTags] = useState<string[]>(allEventTags)

  const initDatePeriod = 'productionPlanPeriod'
  const startTimeProductionPlan = Datetime.getStartOfDay(Datetime.getISONow(0))
  const endTimeProductionPlan = Datetime.getEndOfDay(Datetime.getISONow(168))
  const [datePeriod, setDatePeriod] = useState<{ startTime: ISODateTime, endTime: ISODateTime }>(
    { startTime: startTimeProductionPlan, endTime: endTimeProductionPlan }
  )
  const { data: eventsData } = useGetAllEvents(systemId, datePeriod.startTime, datePeriod.endTime)
  const events = useMemo(() => eventsData || [], [eventsData])

  const toggleFilterCollapse = (): void => {
    setFilterCollapsed(!filterIsCollapsed)
  }

  const allFilterTagsApplied = useMemo(() => {
    return filterTags.length === 0 || filterTags.length === allEventTags.length
  }, [allEventTags.length, filterTags.length])

  const initialEventModalState = useMemo((): EventPost => {
    const currentTime = Datetime.getISONow()

    return {
      digital_twin_id: activeSystem?.primary_digital_twin?.id,
      system_id: systemId,
      start_time: currentTime,
      end_time: Datetime.getEndOfDay(currentTime),
      status: 'Draft',
      input_field: {
        ProductionEvent: getEventFields('ProductionEvent'),
        GridEvent: getEventFields('GridEvent'),
      },
      require_umm: false,
    }
  }, [activeSystem?.primary_digital_twin?.id, systemId])

  const [eventPost, setEventPost] = useState<EventPost>(initialEventModalState)

  const createEvent = useCallback(async (data: EventPost) => {
    try {
      const updatedData = {
        ...data,
        status: 'Draft' as EventStatus,
      }

      const response = await createEventPost(updatedData)
      const updatedEventPostWithId = { ...updatedData, id: response.id }
      setEventPost(updatedEventPostWithId)
    } catch (e) {
      alertStore.error(t(`Failed to create event`))
    }
  }, [createEventPost, t])

  const eventTags = useMemo(() => events.map((event) => {
    return event.tags ?? []
  }), [events])

  const tagsOptions = useMemo(() => {
    const options: { [key: string]: string[] } = {}
    allEventTags.forEach(tag => {
      const [key, value] = tag.split('/')
      if (!options[key]) {
        options[key] = []
      }
      if (!options[key].includes(value)) {
        options[key].push(value)
      }
    })
    return options
  }, [allEventTags])

  useEffect(() => {
    const filteredEvents = events.filter((event) => {
      return event.tags?.every((tag) => filterTags.includes(tag))
    })

    setFilteredEvents((prevFilteredEvents) => {
      if (JSON.stringify(prevFilteredEvents) !== JSON.stringify(filteredEvents)) {
        return filteredEvents
      }
      return prevFilteredEvents
    })
  }, [events, filterTags])

  useEffect(() => {
    setFilterTags(allEventTags)
  }, [allEventTags])

  const setRunTourCallback = useCallback((state: boolean) => {
    setRunTour(state ? 0 : null)
  }, [])

  const setDatePeriodCallback = useCallback((startTime: ISODateTime, endTime: ISODateTime) => {
    setDatePeriod({ startTime, endTime })
  }, [])

  useEffect(() => {
    if (eventId) {
      setOpenSelectedEventPostModal(true)
    }
  }, [eventId])

  const resetPathName = useCallback(() => {
    history.push('/events')
  }, [history])

  const pathNameId = eventId ? parseInt(eventId) : undefined

  return (
    <div className={widgetConstants.EVENTS_WIDGET_1}>
      <ModuleHeader infoHeader={t('Events')}
        onInfoButtonClick={() => {
          setRunTour(0)
        }}
        secondaryChildren={
          <div style={{ display: filterIsCollapsed ? 'none' : 'block' }}>
            {!isLoadingDigitalTwin && (
              <>
                <MultiLevelDropdown
                  options={tagsOptions}
                  items={eventTags}
                  values={filterTags}
                  translations={digitalTwin?.translations}
                  onSelected={(value) => setFilterTags(value)}
                />
              </>
            )}

          </div>
        }
      >
        <div className={styles.EventsView_Header}>
          <Tooltip title={eventTags.length === 0 ? t('There is no events to filter') : ''} arrow>
            <div className={widgetConstants.EVENTS_WIDGET_5}>
              <ToggleButton isCollapsed={filterIsCollapsed} onClick={toggleFilterCollapse} disabled={eventTags.length === 0}>
                <span> {t('Filter')} {!allFilterTagsApplied && <Icon tooltip={{ title: t('Filters active'), placement: 'right', arrow: true }} icon="fas fa-filter" />} </span>
              </ToggleButton>
            </div>
          </Tooltip>
          <div className={widgetConstants.EVENTS_WIDGET_2}>
            <Button
              primary
              marginRight
              icon='fal fa-plus'
              disabled={!hasCreateOperationalEventsAccess}
              onClick={() => {
                createEvent(initialEventModalState)
                setCreateEventModalOpen(true)
              }
              }
            >
              {t('New event')}
            </Button>
          </div>
        </div>
      </ModuleHeader>

      {filteredEvents.length === 0 && filterTags.length !== allEventTags.length ? (
        <ResetFilters onClick={() => setFilterTags(allEventTags)} />
      ) : (
        <div className={styles.EventsView_Container}>
          <CardWidget title={t('Events - Calendar')} id={widgetConstants.EVENTS_WIDGET_3}>
            <EventsCalendar
              events={filteredEvents}
              initDatePeriod={initDatePeriod}
              datePeriod={datePeriod}
              setDatePeriod={setDatePeriodCallback}
            />
          </CardWidget>

          <CardWidget title={t('Events - Table')} id={widgetConstants.EVENTS_WIDGET_4}>
            <EventsTable events={filteredEvents} />
          </CardWidget>

          <Tour
            isSingle={!!runTour}
            run={runTour !== null}
            setRunState={setRunTourCallback}
            steps={eventsSteps}
            dialogWidth={900}
          />

          {createEventModalOpen && (
            <EventPostModal
              newEvent
              selectedEventPostId={eventPost?.id}
              closeModal={() => setCreateEventModalOpen(false)}
            />
          )}
          {openSelectedEventPostModal && (
            <EventPostModal
              selectedEventPostId={pathNameId}
              closeModal={() => {
                setOpenSelectedEventPostModal(false)
                resetPathName()
              }}
            />
          )}
        </div>
      )}

    </div>
  )
}
