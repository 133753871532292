import React, { ReactNode, useContext } from 'react'

import classNames from 'classnames'
import {
  FieldError,
  Text,
  FieldErrorContext,
} from 'react-aria-components'

import styles from './HelpText.module.less'

type Props = {
  errorMessage?: string
  helperText?: ReactNode
}

export function HelpText(props: Props): JSX.Element | null {
  const { helperText } = props
  const fieldError = useContext(FieldErrorContext)
  const isInvalid = fieldError.isInvalid

  const errorMessage = props.errorMessage  || fieldError.validationErrors.join(' ')
  const hasErrorMessage = errorMessage && isInvalid
  const hasHelpText = !!helperText

  if (!(hasHelpText || hasErrorMessage)) {
    return null
  }

  return (
    <div className={classNames(styles.HelpText, {
      [styles.HelpText_error]: hasErrorMessage,
    })}>
      {hasErrorMessage ? <FieldError>{errorMessage}</FieldError> : <Text slot="description">{helperText}</Text>}
    </div>
  )
}