import React, { ReactElement, PropsWithChildren } from 'react'

import { Grid, Icon } from '@mui/material'

import styles from './FormRow.module.less'

type FormRowProps = {
  icon?: string
  iconMarginLeft?: boolean
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  onMouseUp?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
  dataTestId?: string
}

function FormRow({ icon, spacing, iconMarginLeft, children, onMouseUp, style, dataTestId }: PropsWithChildren<FormRowProps>): ReactElement {
  return (
    <Grid item container direction="row" onMouseUp={onMouseUp} style={style} data-testid={dataTestId}>
      {icon ?
        <Grid item container xs={1} alignItems="flex-start" justifyContent="center" className={styles.FormRow_Icon}>
          <Icon className={icon} />
        </Grid>
        : (
          iconMarginLeft
            ? <Grid item container xs={1} alignItems="flex-start" justifyContent="center" className={styles.FormRow_Icon}/>
            : <></>
        )
      }
      <Grid item container xs={11} spacing={spacing !== undefined ? spacing: 1 } direction="row" alignItems="flex-start" justifyContent="space-between">
        {children}
      </Grid>
    </Grid>
  )
}

export default FormRow
