import React, { ReactElement, ReactNode } from 'react'

import { FormControl, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'

type DropdownProps<ValueType> = {
  label: string
  items: { value: ValueType; label: ReactNode; subHeader?: string }[]
  value: ValueType
  handleChange: (event: SelectChangeEvent<ValueType>, child: ReactNode) => void
  fullWidth?: boolean
  disabled?: boolean
  margin?: 'none' | 'dense' | 'normal'
  style?: React.CSSProperties
  displayEmpty?: boolean
  inputProps?: Record<string, ReactNode>
  error?: boolean
}

export default function Dropdown<ValueType extends string | number | undefined>({
  label,
  items,
  value,
  handleChange,
  fullWidth,
  disabled = false,
  margin = 'none',
  style,
  displayEmpty,
  inputProps,
  error,
}: DropdownProps<ValueType>): ReactElement {
  const labelId = `dropdown-label-${label}`

  // Group items by subHeader
  const groupedItems = items.reduce<Record<string, { value: ValueType; label: ReactNode }[]>>((groupedItems, item) => {
    const groupKey = item.subHeader || 'default'
    if (!groupedItems[groupKey]) {
      groupedItems[groupKey] = []
    }
    groupedItems[groupKey].push(item)
    return groupedItems
  }, {})

  return (
    <FormControl variant="outlined" fullWidth={fullWidth} margin={margin} style={style}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        fullWidth={fullWidth}
        labelId={labelId}
        id={`dropdown-select-${label}`}
        value={value}
        onChange={handleChange}
        label={label}
        displayEmpty={displayEmpty}
        disabled={disabled}
        inputProps={inputProps}
        error={error}
      >
        {Object.entries(groupedItems).map(([subHeader, groupItems]) => {
          const dropdownItems = []

          if (subHeader !== 'default') {
            dropdownItems.push(
              <ListSubheader
                key={`subheader-${subHeader}`}
                color='inherit'
                sx={{
                  padding: { xs: '0 10px' },
                  height: { xs: '40px' },
                }}>
                {subHeader}
              </ListSubheader>)
          }

          dropdownItems.push(
            ...groupItems.map(({ value, label: itemLabel }, index) => (
              <MenuItem
                key={`dropdown-${label}-${itemLabel}-${index}`}
                value={value}>
                {itemLabel}
              </MenuItem>
            ))
          )

          return dropdownItems
        })}
      </Select>
    </FormControl>
  )
}
