import React, { ReactElement, useState } from 'react'

import { Inline } from 'ui/atoms'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import { FormDatePickerPeriod } from 'ui/molecules/pickers/DatePicker/FormDatePickerPeriod'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

export default function AbSLabsView(): ReactElement {
  const { t } = useTranslation()
  const [period, setPeriod] = useState<{
    startTime: ISODateTime
    endTime: ISODateTime
  }>({
    startTime: Datetime.getISONow(-168),
    endTime: Datetime.getISONow(168),
  })

  return (
    <View
      header={
        <ModuleHeader infoHeader={t(`AbS Labs`)}>
          <Inline flexEnd>
            <FormDatePickerPeriod
              granularity='minute'
              defaultPeriod={{
                startTime: period.startTime,
                endTime: period.endTime,
              }}
              onSubmit={(period) => {
                setPeriod({
                  startTime: period.startTime,
                  endTime: period.endTime,
                })
              }}
            />
          </Inline>
        </ModuleHeader>
      }
    >
      <UiConfig type="abs_labs" datasetStartTime={period.startTime} datasetEndTime={period.endTime} />
      <QueueProgressBar />
    </View>
  )
}
