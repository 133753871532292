import React, { ReactElement } from 'react'

import { DigitalTwinSettingMetadata, TranslatedDeviationSetting } from 'api/digitalTwin/digitalTwin.api'
import { Setting } from 'api/settings/settings.api'
import ActiveSettingText from 'ui/components/ActiveSettingText/ActiveSettingText'

import { CircularProgress, Icon , Tooltip as MuiTooltip } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'


import styles from './SettingsStatusField.module.less'

// NOTE: Stolen and modified from src\ui\components\CustomTable\components\SettingsStatusField\SettingsStatusField.tsx
type SettingsStatusFieldProps = {
  value: Setting | DigitalTwinSettingMetadata | TranslatedDeviationSetting | null | Array<Setting | TranslatedDeviationSetting | null>
  loading?: boolean
}
export default function SettingsStatusField({ value, loading }: SettingsStatusFieldProps): ReactElement {
  const { t } = useTranslation()

  if (loading) {
    return <CircularProgress size={24} />
  }

  if (Array.isArray(value)) {
    if (value.every((v) => v === undefined || v === null)) {
      return <Icon className={classNames(styles.SettingsStatusField_Check, `fas fa-check-circle`)} />
    }
    return <Icon className={classNames(styles.SettingsStatusField_Exclamation, `fas fa-exclamation-circle`)} />
  }

  return value?.value === undefined || value?.value === null ? (
    <MuiTooltip arrow placement="top" title={t(`No deviation`)}>
      <Icon className={classNames(styles.SettingsStatusField_Check, `fas fa-check-circle`)} />
    </MuiTooltip>
  ) : (
    <MuiTooltip
      arrow
      placement="top"
      title={<ActiveSettingText startTime={value.start_time} endTime={value.end_time} />}
    >
      <Icon className={classNames(styles.SettingsStatusField_Exclamation, `fas fa-exclamation-circle`)} />
    </MuiTooltip>
  )
}
