import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import StatusFlow from './StatusFlow/StatusFlow'

export default function EventsTour(): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <p>{`${t('In this guide we will go over the different parts of the event module')}:`}</p>
      <ul>
        <li>
          {t('Form for creating a new event')}
        </li>
        <li>
          {t('A calendar view for events')}
        </li>
        <li>
          {t('A table view for events')}
        </li>
        <li>
          {t('A filter view where you can filter which events you want to see')}
        </li>
      </ul>
      <StatusFlow />
    </>
  )
}
