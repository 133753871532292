import React, { ReactElement } from 'react'

import SelectButton from 'api/SelectButton/SelectButton'
import { getOptjobAsObject, OptJobType, useLatestOptJobStatus, useOptJobMutation } from 'api/optJobs/optJobs.api'
import { useSystem } from 'api/systems/systems.api'

import moment from 'moment'

import { useAuth } from '../AuthContext/AuthContext'
import ModuleHeaderButton from '../ModuleHeaderButton/ModuleHeaderButton'
import { buttonTranslationMapping } from '../OptimizeFormProvider/components/OptimizeButton/OptimizeButton'
import { useHasPermission } from 'helpers/global.helper/global.helper'
import { optJobIsDone } from 'helpers/optimize.helper/optimize.helper'

import styles from './OptimizeWithSubtypes.module.less'

type OptimizeWithSubtypesProps = {
  type: OptJobType
  disabled?: boolean
  optimizeAllSubtypes?: () => void
  isValid?: boolean
  permission: UserPermission
  optStartTime?: moment.Moment
  optEndTime?: moment.Moment
  creatingOptJob?: boolean
}

export default function OptimizeWithSubtypes({
  type,
  disabled,
  optimizeAllSubtypes,
  isValid,
  permission,
  optStartTime,
  optEndTime,
  creatingOptJob,
}: OptimizeWithSubtypesProps): ReactElement {
  const { systemId } = useAuth()
  const { data: activeSystem } = useSystem({ id: systemId })
  const { data: latestOptJob, status: fetchingLatestJobStatus } = useLatestOptJobStatus({ type })
  const firstLatestOptJob = getOptjobAsObject(latestOptJob)

  const hasRunRegularOptAccess = useHasPermission(permission)
  const { mutate: optJobCreate } = useOptJobMutation()

  const subtypes = activeSystem?.subtypes[type]?.map((subtype: Subtype) => subtype) ?? []

  function optimizeSubtypes(selectedSubtype: string): void {
    if (!activeSystem) return

    const startTime = optStartTime || moment().startOf('hour')
    optJobCreate({
      opt_job_type: type,
      input_data: {
        opt_start_time: startTime.toISOString(),
        opt_end_time: optEndTime?.startOf('second').toISOString(),
        include_subtypes: [selectedSubtype],
      },
    })
  }

  return (
    <>
      <div className={styles.OptimizeWithSubtypes_Button}>
        <ModuleHeaderButton
          icon="fal fa-redo-alt"
          loading={creatingOptJob || fetchingLatestJobStatus === 'loading' || !optJobIsDone(firstLatestOptJob)}
          disabled={
            latestOptJob === undefined ||
            !optJobIsDone(firstLatestOptJob) ||
            !isValid ||
            disabled ||
            !hasRunRegularOptAccess
          }
          style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', boxShadow: 'none' }}
          onClick={() => {
            if (optimizeAllSubtypes) {
              optimizeAllSubtypes()
            }
          }}
        >
          {buttonTranslationMapping(firstLatestOptJob?.status)}
        </ModuleHeaderButton>
        <SelectButton
          menuItems={subtypes.map((subtype: Subtype) => ({
            label: subtype.subtype_display_name,
            onClick: () => {
              optimizeSubtypes(subtype.subtype)
            },
          }))}
          noRadiusRight
          icon="fas fa-sort-down"
        />
      </div>
    </>
  )
}
