import React, { ReactElement } from 'react'

import { Switch } from 'ui/atoms'
import { FormDatePickerPeriod } from 'ui/molecules/pickers/DatePicker/FormDatePickerPeriod'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import { handlePeriodChange } from 'views/FollowupProductionView/FollowupProductionView'

import styles from './OverviewPeriodPickerAndSwitch.module.less'

type OverviewPeriodPickerProps = {
  handlePeriodChange: handlePeriodChange
  period: Period
}

export function OverViewPeriodPickerAndSwitch({ handlePeriodChange, period }: OverviewPeriodPickerProps): ReactElement {
  const { t } = useTranslation()

  function handleSwitch(str: string): void {
    if (str === 'today') {
      handlePeriodChange({
        startTime: Datetime.getStartOfDay(Datetime.getISONow(0)),
        endTime: Datetime.getEndOfDay(Datetime.getISONow(0)),
      })
    }
    if (str === 'tomorrow') {
      handlePeriodChange({
        startTime: Datetime.getTomorrowDate().startTime,
        endTime: Datetime.getTomorrowDate().endTime,
      })
    }
  }

  const items = [
    { value: 'today', label: `${t('Today')}` },
    { value: 'tomorrow', label: `${t('Tomorrow')}` },
  ]

  return (
    <div className={styles.OverviewPeriodPickerAndSwitch}>
      <div className={styles.OverviewPeriodPickerAndSwitch_Switch}>
        <Switch items={items} onClick={handleSwitch} />
      </div>
      <div className={styles.OverviewPeriodPickerAndSwitch_PeriodPicker}>
        <FormDatePickerPeriod
          defaultPeriod={{
            startTime: period.startTime,
            endTime: period.endTime,
          }}
          onSubmit={(period: Period) => {
            const dateTimePeriod = {startTime: Datetime.toISOString(period.startTime), endTime: Datetime.toISOString(period.endTime)}
            handlePeriodChange(dateTimePeriod)
          }}
          key={period.startTime + period.endTime}
        />
      </div>
    </div>
  )
}
