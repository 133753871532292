import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

type CommentFieldProps = {
  value: string
}

export default function CommentField({ value }: CommentFieldProps): ReactElement {
  return (
    <Tooltip title={value} arrow placement="top" display="inline-block">
      <div
        style={{
          whiteSpace: `nowrap`,
          textOverflow: `ellipsis`,
          overflow: `hidden`,
          maxWidth: 250,
        }}
      >
        {value}
      </div>
    </Tooltip>
  )
}
