import React, { ReactElement } from 'react'

import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'

import { InputAdornment, OutlinedTextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'


import BinarySettingSelect from '../BinarySettingSelect/BinarySettingSelect'
import { isBinarySetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getAlternativeValue } from 'helpers/settingsModal.helper/settingsModal.helper'

type SettingValueInputProps = Partial<OutlinedTextFieldProps> & {
  objectProperty: ObjectProperty
}

function SettingValueInput({
  error,
  objectProperty,
  ...props
}: SettingValueInputProps): ReactElement {
  const { t } = useTranslation()

  const validRange = objectProperty.valid_range
  const baseValue = objectProperty.current_base_value ?? objectProperty.default_value
  const unit = objectProperty?.measurement_unit !== null ? objectProperty?.measurement_unit : ``

  const strictValidRangeUpper = validRange?.upper != null ? validRange.upper - 0.1 : undefined

  let helperText = t(`Base value: {{baseValue}}`, { baseValue })

  if ((validRange?.lower || validRange?.lower === 0) && (baseValue < validRange?.lower)) {
    helperText = t(`Range higher than {{lower}}`, { lower: validRange?.lower })
  } else if (validRange?.upper && (baseValue > validRange?.upper)) {
    helperText = t(`Range lower than {{higher}}`, { higher: validRange.upper })
  } else if (error && !validRange) {
    helperText = t(`Incorrect value`)
  } else if (error && validRange?.upper) {
    helperText = t(`Range between {{lower}} - {{upper}}`, { lower: validRange?.lower || 0, upper: validRange?.upper})

  } else if (error) {
    helperText = t(`Range higher than {{lower}}`, { lower: validRange?.lower || 0})
  }

  if (isBinarySetting(objectProperty.name)) {
    const { alternatives } = getAlternativeValue(objectProperty)

    if (objectProperty.name === 'availability' && (alternatives.length > 0)) { //TODO: Only show value Active for settings in availability, should be replaced with DT settings
      return (
        <BinarySettingSelect alternatives={[alternatives[0]]} />
      )
    } else {
      return (
        <BinarySettingSelect alternatives={alternatives} />
      )
    }
  }

  return (
    <ReactHookFormTextField
      {...props}
      id="value"
      name="value"
      rules={{
        min: validRange?.lower,
        max: strictValidRangeUpper,
        required: true,
      }}
      defaultValue=""
      error={error}
      helperText={helperText}
      type="number"
      inputProps={{ step: 0.1 }}
      label={t(`Enter value`) || ''}
      InputProps={{
        endAdornment: <InputAdornment position="end">{ unit }</InputAdornment>,
      }}
      required
      fullWidth
    />
  )
}

export default SettingValueInput
