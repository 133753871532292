import React, { ReactElement } from 'react'

import { useSettlementExcelExportMutation } from 'api/settlementExcelExport/settlementExcelExport'
import { postStat } from 'api/stats/stats.api'
import HeaderPeriodPicker from 'ui/components/HeaderPeriodPicker/HeaderPeriodPicker'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './SettlementsHeader.module.less'

type SettlementsProps = {
  period: Period
  handlePeriodChange: (period: Period) => void
}

export default function SettlementsHeader({ period, handlePeriodChange }: SettlementsProps): ReactElement {
  const { t } = useTranslation()
  const { mutate: getExcelExport } = useSettlementExcelExportMutation()

  function onExportClick(): void {
    getExcelExport(period)
  }
  return (
    <Grid container direction="row" justifyContent="flex-end">
      <Grid item xs={12} md={12} xl={12}>
        <div className={styles.SettlementsHeader_Container}>
          <HeaderPeriodPicker
            period={period}
            handlePeriodChange={(ev) => {
              handlePeriodChange(ev)
              postStat(`settlements-header`, `change-period`)
            }}
            view="hour"
            exportButtonTitle={t('Export invoice data')}
            onExport={onExportClick}
          />
        </div>
      </Grid>
    </Grid>
  )
}
