import React, { Profiler, ReactElement } from 'react'

import styles from './DevView.module.less'

export default function DevView(): ReactElement {
  return (
    <div className={styles.DevView}>
      <h1>DEV</h1>

      <Profiler id="chart" onRender={(id, phase, actualDuration) => {
        // eslint-disable-next-line no-console
        console.log(`${id}: ${actualDuration}ms to render ${phase}`)
      }}>
        {/*
          NOTE: Place your dev-component here.
          Tips: Copy props from production and store as a JSON file, then use it as <DevComponent {...props} /> if you want to test the component, but don't want to fetch data upstread.
        */}
      </Profiler>
    </div>
  )
}