import React, { ReactElement } from 'react'

import { Checkbox, FormControlLabel, CheckboxProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type ReactHookFormCheckboxProps = {
  name: string
  label: string
  checkedLabel?: string
  defaultValue?: string | number | boolean
  CheckboxProps?: CheckboxProps
  disabled?: boolean
}

export default function ReactHookFormCheckbox({
  name,
  label,
  checkedLabel,
  defaultValue,
  CheckboxProps,
  disabled,
}: ReactHookFormCheckboxProps): ReactElement {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ...rest } }) => (
        <FormControlLabel
          id={name}
          label={value && checkedLabel ? checkedLabel : label}
          control={
            <Checkbox
              id={name}
              {...rest}
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
              disabled={disabled}
              {...CheckboxProps}
            />
          }
        />
      )}
    />
  )
}
