import React, { ReactElement } from 'react'

import { followupHeaderContentClass } from 'ui/components/Followup/FollowupHeader/FollowupHeader'

import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

export const widgetConstants = {
  FOLLOWUP_PRODUCTION_GRID: `FOLLOWUP_PRODUCTION`,
  FOLLOWUP_PRODUCTION_WIDGET_1: `item-1`,
  FOLLOWUP_PRODUCTION_WIDGET_2: `item-2`,
  FOLLOWUP_FORECAST_GRID: `followup-forecast-grid`,
}

const legacyFollowupToOptTourObj = [
  {
    title: `Production outcome`,
    body: `Is the calculated cost for the actual production outcome with regard to fuel prices and other production related costs that have been included in Aurora. The cost is representing what is showed in the bottom graph, which is the same for all tabs in the follow-up.`,
  },
  {
    title: `Optimal`,
    body: `Represents the production cost from the optimal outcome in the given period. This cost varies between the different tabs due to different conditions applies for the current optimization you are currently looking at. Note that the cost is always based on the current input for the optimization, so the cost can vary between tabs in the same period.`,
  },
  {
    title: `PPS (potential production saving)`,
    body: `Is the difference between the cost for the production outcome and for the optimal production (PPS=Outcome - Optimal) for the first tab, 'Optimal production'. This cost gives a indication of how big the theoretical saving could be of the plant had been producing in the most optimal way. PPS is then devided into DEC and ODC so that PPS=DEC+ODC.`,
  },
  {
    title: `LMFU (lost margin from unavailability)`,
    body: `LMFU is the subset of the total PPS for the current period where deviation settings affects the cost. PPS is therefore calculated as OPTIMAL (Include deviations) - OPTIMAL (Optimal production) and is supposed to be easily identified by specific deviation settings or events.`,
  },
  {
    title: `ODC (operational deviation cost)`,
    body: `Is the remaining part of PPS that can not directly be derived to any deviation settings. ODC is therefore calculated as PRODUCTION OUTCOME - OPTIMAL (Include deviations)`,
  },
  {
    title: `PPS average`,
    body: `Is quite simply PPS devided by the total produced energy for the given period.`,
  },
]

function LegacyFollowupToOptTour(): ReactElement {
  const { t } = useTranslation()

  return (
    <Grid item container direction="column" alignItems="flex-start" justifyContent="flex-start">
      {t(
        `You will find your production follow-up here! A follow-up optimization consists of a specific type of optimization by using the plants' production actual outcome as input instead of using forecasts. Every available tab represents one type of follow-up.\n\nThe default setting is\n'Optimal production' which uses the production outcome as input, ignoring any deviation settings created for the current period.\n'Include deivations' works in the same way as optimal production except here all deviation settings are included in the current period.\n'Production plan' represents all the production plan results generated from the 'Production plan' module compared with the production outcome.\n\nYou also find a set of different KPIs in this module, where some of the default are:`
      )}
      {legacyFollowupToOptTourObj.map((item, key) => (
        <div key={key}>
          <Grid item>
            <Typography display="inline" style={{ fontWeight: `bold` }}>
              {item.title}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: 10 }}>
            <Typography>{item.body}</Typography>
          </Grid>
        </div>
      ))}
    </Grid>
  )
}

function FollowupToOptTour(): ReactElement {
  const { t } = useTranslation()

  return (
    <Grid item container direction="column" alignItems="flex-start" justifyContent="flex-start">
      {t(
        `You will find your production follow-up here! A follow-up optimization consists of a specific type of optimization by using the plants' production actual outcome as input instead of using forecasts. Every available tab represents one type of follow-up.\n\nThe default setting is\n'Optimal production' which uses the production outcome as input, ignoring any deviation settings created for the current period.\n'Include deivations' works in the same way as optimal production except here all deviation settings are included in the current period.\n'Production plan' represents all the production plan results generated from the 'Production plan' module compared with the production outcome.`
      )}

    </Grid>
  )
}


function FollowupViewKpiOnlyTour(): ReactElement {
  const { t } = useTranslation()

  const followupKpiInfo = [
    {
      title: t(`PPS (potential production saving)`),
      body: t(
        `Is the difference between the cost for the production outcome and for the optimal production (PPS=Outcome - Optimal) for the first tab, 'Optimal production'. This cost gives a indication of how big the theoretical saving could be of the plant had been producing in the most optimal way. PPS is then devided into DEC and ODC so that PPS=DEC+ODC.`
      ),
    },
    {
      title: t(`ODC (operational deviation cost)`),
      body: t(
        `Is the remaining part of PPS that can not directly be derived to any deviation settings. ODC is therefore calculated as PRODUCTION OUTCOME - OPTIMAL (Include deviations)`
      ),
    },
    {
      title: t(`LMFU (lost margin from unavailability)`),
      body: t(
        `LMFU is the subset of the total PPS for the current period where deviation settings affects the cost. PPS is therefore calculated as OPTIMAL (Include deviations) - OPTIMAL (Optimal production) and is supposed to be easily identified by specific deviation settings or events.`
      ),
    },
    {
      title: t(`PPS average`),
      body: t(`Is quite simply PPS devided by the total produced energy for the given period.`),
    },
  ]
  return (
    <Grid item container direction="column" alignItems="flex-start" justifyContent="flex-start">
      {t('These kpi values are the same for every scenario, and what and number of kpi values can differ from system to system. The most common ones are described below: ')}
      {followupKpiInfo.map((item, key) => (
        <div key={key}>
          <Grid item>
            <Typography display="inline" style={{ fontWeight: `bold` }}>
              {item.title}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: 10 }}>
            <Typography>{item.body}</Typography>
          </Grid>
        </div>
      ))}
    </Grid>
  )
}

export const followUpOptInfoSteps: Array<Step> = [
  {
    target: followupHeaderContentClass,
    content: `Choose start and end date to fetch data for the period you are interested in.`,
    title: `Choose period`,
  },
  {
    target: widgetConstants.FOLLOWUP_PRODUCTION_WIDGET_2,
    content: <FollowupViewKpiOnlyTour />,
    title: `KPI Dashboard`,
  },
  {
    target: widgetConstants.FOLLOWUP_PRODUCTION_WIDGET_1,
    content: <FollowupToOptTour />,
    title: `Production follow-up`,
  },
  {
    target: widgetConstants.FOLLOWUP_FORECAST_GRID,
    content:  `Here you can compare different forecasts with their respective outcomes. You can also apply filters depending on what data you want to analyze using the filter buttons above the chart. \n\n Unit and value for each graph is shown when you hover over the chart, and it is also possible to zoom in the chart using click-and-drag.`,
    title: `Follow-up forecast`,
  },
]

const legacyFollowUpOptInfo: Array<Step> = [
  {
    target: widgetConstants.FOLLOWUP_PRODUCTION_WIDGET_1,
    content: <LegacyFollowupToOptTour />,
    title: `Production follow-up`,
  },
]


export const legacySteps: Array<Step> = [
  ...legacyFollowUpOptInfo,
  {
    target: followupHeaderContentClass,
    content: `Choose start and end date to fetch data for the period you are interested in.`,
    title: `Choose period`,
  },
]