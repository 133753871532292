import { apiClient } from 'api/apiClient/apiClient'
import { DefaultPageNumberPagination } from 'api/types'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { InfiniteData, useInfiniteQuery, useMutation } from 'react-query'

import { queryClient } from 'helpers/queryClient'

export type BulletinSetting = {
  start_time: string
  end_time: string
  value: number
  comment: string
}

export type BulletinObjectProperty = DisplayNameObject & {
  parent: { name: string }
  category: string | null
}

export type Comment = {
  updated_at: string
  has_read: boolean
  user_liked: boolean
  total_likes: number
  created_by: number | null
  id: number
  content: string
  creator_full_name: string | null
}

export type Thread = {
  comments: Array<Comment>
  id: number
  has_read: boolean
  user_liked: boolean
  total_likes: number
  updated_at: string
  created_by: number | null
  content: string | null
  bulletin_setting: BulletinSetting | null
  object_property: BulletinObjectProperty | null
  creator_full_name: string | null
}

type ThreadsResponse = DefaultPageNumberPagination<Thread[]>

type ThreadPostData = {
  system: number
  content: string
  files?: File[]
  created_by?: string
  creator_full_name?: string
}

type CommentPostData = {
  bulletin_board_thread: number
  content: string
  files?: File[]
  created_by?: string
  creator_full_name?: string
}

export function isComment(post: Thread | Comment): post is Comment {
  return (post as Thread).comments ? false : true
}

export const BULLETIN_BOARD_THREADS_QUERY_KEY = `bulletinBoardThreads`

export function useBulletinBoardThreads() {
  const { systemId } = useAuth()
  return useInfiniteQuery(
    [BULLETIN_BOARD_THREADS_QUERY_KEY, { system: systemId }],
    ({ pageParam = 1 }) =>
      apiClient<ThreadsResponse>(`bulletin_board_threads`, {
        params: {
          system: systemId,
          page: pageParam,
        },
      }),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      getPreviousPageParam: (lastPage) => lastPage.previous,
      enabled: !!systemId,
    }
  )
}

export function useBulletinBoardThreadMutation() {
  return useMutation(({ system, content, files, created_by, creator_full_name }: ThreadPostData) =>
    apiClient(`bulletin_board_threads`, {
      data: {
        system: system,
        content: content,
        files: files,
        created_by: created_by,
        creator_full_name: creator_full_name,
      },
    })
  )
  // TODO: Optimistic updating
}

export function useBulletinBoardCommentMutation() {
  return useMutation(({ bulletin_board_thread, content, files, created_by, creator_full_name }: CommentPostData) =>
    apiClient(`bulletin_board_comments`, {
      data: {
        bulletin_board_thread: bulletin_board_thread,
        content: content,
        files: files,
        created_by: created_by,
        creator_full_name: creator_full_name,
      },
    })
  )
  // TODO: Optimistic updating
}

export function useBulletinBoardLikeMutation() {
  const { systemId } = useAuth()
  return useMutation(
    ({ id, type }: { id: number; type: `bulletin_board_threads` | `bulletin_board_comments` }) =>
      apiClient(`${type}/${id}/like`, { method: `POST` }),
    {
      onMutate: async ({ id, type }: { id: number; type: `bulletin_board_threads` | `bulletin_board_comments` }) => {
        const key = [BULLETIN_BOARD_THREADS_QUERY_KEY, { system: systemId }]
        await queryClient.cancelQueries(key)

        const threads = queryClient.getQueryData<InfiniteData<ThreadsResponse>>(key)

        queryClient.setQueryData<InfiniteData<ThreadsResponse> | undefined>(key, (old) =>
          old
            ? {
              ...old,
              pages: old?.pages.map((page) => ({
                ...page,
                results: page.results.map((result) =>
                  type === `bulletin_board_threads` && result.id === id
                    ? {
                      ...result,
                      total_likes: result.user_liked ? result.total_likes - 1 : result.total_likes + 1,
                      user_liked: !result.user_liked,
                    }
                    : type === `bulletin_board_comments`
                      ? {
                        ...result,
                        comments: result.comments.map((comment) =>
                          comment.id === id
                            ? {
                              ...comment,
                              total_likes: comment.user_liked ? comment.total_likes - 1 : comment.total_likes + 1,
                              user_liked: !comment.user_liked,
                            }
                            : comment
                        ),
                      }
                      : result
                ),
              })),
            }
            : old
        )

        return () => queryClient.setQueryData(key, threads)
      },
    }
  )
}

export function useBulletinBoardDeleteMutation() {
  return useMutation(({ id, type }: { id: number; type: `bulletin_board_threads` | `bulletin_board_comments` }) =>
    apiClient(`${type}/${id}`, { method: `DELETE` })
  )
  // TODO: Optimistic updating
}
