import { spreadsheetApiClient, SpreadsheetResult } from 'api/apiClient/apiClient'
import alertStore from 'store/alert/alert'
import authStore from 'store/auth/auth'

import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationResult } from 'react-query'
import { snapshot } from 'valtio'

export function useSettlementExcelExportMutation(): UseMutationResult<SpreadsheetResult, { result: string }, Period, unknown> {
  const { t } = useTranslation()
  const snap = snapshot(authStore)
  const systemid = snap.activeSystem?.id ?? null
  return useMutation(
    (period: Period) => {
      return spreadsheetApiClient(`systems/${systemid}/settlement`, {
        params: {
          start_time: period.startTime,
          end_time: period.endTime,
        },
      })
    },
    {
      onError: (errors, _id) => {
        alertStore.error(t(`Something went wrong unfortunately, see error or try another period: `) + errors?.result)
      },
      onSuccess: async (response) => {
        if (response.result) {
          const settlementExcelFile = new Blob([response.result], {
            type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`,
          })
          saveAs(settlementExcelFile, `${response.filename}`)
        }
      },
    }
  )
}
