import React, { ReactElement } from 'react'

import { useRolesFromOrganization } from 'api/admin/admin.api'
import Icon from 'ui/atoms/Icon/Icon'

import { useTranslation } from 'react-i18next'

type ViewUserPermissionsProps = {
  user: User
}

export default function ViewUserPermissions({ user }: ViewUserPermissionsProps): ReactElement {
  const { t } = useTranslation()

  const { data: rolesFromOrg = [] } = useRolesFromOrganization()
  const allUserRoleIds = new Set(user.system_roles.map(({ role_id }) => role_id))
  const allRolesForUser = rolesFromOrg.filter(role => allUserRoleIds.has(role.id))
  const allPermissionsForUser = allRolesForUser.flatMap(role => role.permissions)
  const uniqueTranslatedPermissionDesciptions = Array.from(
    new Set(allPermissionsForUser.map(p => t(p.description)))
  )

  return (
    <>
      {user.first_name
        ? (<h4 style={{ margin: '0px' }}>
          {t(`Permissions for {{name}}`, { name: `${user.first_name} ${user.last_name}` })}
        </h4>)
        : (<h4 style={{ margin: '0px' }}>
          {t(`Users permissions:`)}
        </h4>)
      }

      {uniqueTranslatedPermissionDesciptions.map((translatedDescription) => (
        <p key={translatedDescription}>- {translatedDescription}</p>
      ))}

      <p style={{ marginTop: '20px' }}>
        <Icon icon={'fas fa-info-circle'} size='medium' color='blue' />
        {t(`To change a user's permissions, the user's role needs to be changed`)}
      </p>
    </>
  )
}
