import React, { useCallback } from 'react'

import { toISODateTime } from 'ui/molecules/pickers/DatePicker/utils/dateAdapter'
import { TimeField } from 'ui/molecules/pickers/internal/DatePicker/DateField'

import { parseAbsoluteToLocal, ZonedDateTime } from '@internationalized/date'
import { TimeField as ReactAriaTimeField } from 'react-aria-components'

type TimePickerProps = {
  value: ISODateTime
  onChange: (value: ISODateTime) => void
  label?: string
  error?: boolean
  dateTransform?: (date: ISODateTime) => ISODateTime
}

export function TimePicker({ dateTransform, error, onChange, label, value: propsValue }: TimePickerProps): JSX.Element {
  const [value, setValue] = React.useState(propsValue)
  const zonedDateTime = parseAbsoluteToLocal(value)

  const onChangeFunction = useCallback(
    (newValue: ZonedDateTime) => {
      if (!newValue) {
        return
      }

      const isoDateTime = toISODateTime(newValue)
      if (!isoDateTime) {
        // Shouldn't happen .. Should really fix the toISODateTime function
        return
      }

      if (dateTransform) {
        setValue(dateTransform(isoDateTime))
      } else {
        setValue(isoDateTime)
      }
    },
    [dateTransform]
  )

  return (
    <ReactAriaTimeField
      isInvalid={error}
      hideTimeZone
      value={zonedDateTime}
      onChange={onChangeFunction}
      onBlur={() => {
        if (value) {
          onChange(value)
        }
      }}
    >
      <TimeField label={label} />
    </ReactAriaTimeField>
  )
}
