import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { DigitalTwinSetting, useDigitalTwin, useDigitalTwinSettings } from 'api/digitalTwin/digitalTwin.api'
import { DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { useGetAllEventMutation } from 'api/events/events.api'
import { postStat } from 'api/stats/stats.api'
import filterStore, { getTopLevelTagsForRoute } from 'store/filter/filter'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import Datetime from 'utils/datetime/datetime'
import { getFilteredPropertiesByPriority, getPropertiesFromDigitalTwinModel } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import {  Clear, Search } from '@mui/icons-material'
import { TextField, InputAdornment, Box } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { formatEndTime, formatStartTime } from 'helpers/dateTime.helper/dateTime.helper'
import { getDigitalTwinSettingValueString } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getRoute } from 'helpers/route.helper/route.helper'
import { getPrettyName, getPropertyPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { matchesSomeTagFromEveryTopLevel } from 'views/DigitalTwinSettingsView/components/DigitalTwinMultilevelDropdown/MultilevelDropdown.helper'
import EventPostViewModal from 'views/EventsView/components/EventPostViewModal/EventPostViewModal'
import { EventPost } from 'views/EventsView/components/events.helper'
import optimizeViewStore from 'views/OptimizeView/store/optimizeViewStore'

import DeviationSettingsListItem from './DeviationSettingsListItem'
import styles from './DeviationSettingsMenu.module.less'
import DeviationSettingsMenuTab from './DeviationSettingsMenuTab'
import { getGroupedSettings, getGroupedSettingsMatchingSearchTerm } from './DeviationSettingsMenuUtils'

type DeviationSettingsMenuProps = {
  menuWidth: number
  headerHeight: number
  onToggle: (isOpen: boolean) => void
};

export default function DeviationSettingsMenu( { menuWidth, headerHeight, onToggle }: DeviationSettingsMenuProps): ReactElement {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()
  const optimizeViewSnap = useSnapshot(optimizeViewStore)
  const { currentActiveTagsPerRoute, filterComponentPerRoute } = useSnapshot(filterStore)
  const language = localStorage.getItem('language')
  const route = getRoute()

  const systemPrimaryDigitalTwin = activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(systemPrimaryDigitalTwin?.uid, true, true)
  const { mutateAsync: fetchDigitalTwinSettings, isLoading: isLoadingSettings } = useDigitalTwinSettings()
  const currentTags = useMemo(() => currentActiveTagsPerRoute[route] ?? new Set(), [currentActiveTagsPerRoute, route])
  const topLevelTags = getTopLevelTagsForRoute(route)
  const hasFilterComponent = filterComponentPerRoute[route] && topLevelTags.length > 0

  //The link button filters DeviationSettings on the component-tag, the button won't work if the tag doesn't exist
  const showDeviationSettingsLinkButton = useMemo(() => {
    //Should be possible to change to getTopLevelTagsForRoute if DigitalTwinSettingsView uses FilterComponent
    return digitalTwin?.model.all_tags?.some(tag => tag.includes('component/')) ?? false
  }, [digitalTwin?.model.all_tags])

  const [digitalTwinSettings, setDigitalTwinSettings] = useState<DigitalTwinSetting[]>([])
  const [operationalEvents, setOperationalEvents] = useState<Map<number, EventPost>>(new Map<number, EventPost>())
  const [searchTerm, setSearchTerm] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [eventPostData, setEventPostData] = useState<EventPost | undefined>(undefined)

  const startTime = optimizeViewSnap.optimizedViewRange[0]
  const endTime = optimizeViewSnap.optimizedViewRange[1]

  useEffect(() => {
    if (digitalTwin?.uid !== undefined) {
      fetchDigitalTwinSettings({
        digitalTwinUid: digitalTwin?.uid,
        activeFrom: startTime,
        activeTo: endTime,
        priorities: [...DEVIATION_SETTING_PRIORITIES, ...(activeSystem?.setting_priority_levels ?? [])],
      }).then((settings) => {
        setDigitalTwinSettings(settings)
      })
    }
  }, [
    activeSystem?.setting_priority_levels,
    digitalTwin?.uid,
    endTime,
    fetchDigitalTwinSettings,
    startTime,
  ])

  const { mutateAsync: fetchOperationalEvents } = useGetAllEventMutation()
  const refetchOperationalEvents = useCallback(() => {
    const ids = Array.from(new Set(digitalTwinSettings
      .map((setting) => setting.operational_event)
      .filter((id) => id !== undefined && id !== null)))
    if (ids.length === 0) {
      return
    }

    fetchOperationalEvents({
      ids: ids,
    }).then((data) => {
      const dict = new Map<number, EventPost>()
      data.forEach((event) => {
        if (event.id !== undefined) {
          dict.set(event.id, event)
        }
      })
      setOperationalEvents(dict)
    })
  }, [fetchOperationalEvents, digitalTwinSettings])

  const handleMenuToggle = useCallback((isOpen: boolean) => {
    postStat('deviation_settings_menu', `menu-${isOpen ? 'opens' : 'closes'}`) //DATA LOG FOR PILOT 1,5
    setIsMenuOpen(isOpen)
    onToggle(isOpen)
    refetchOperationalEvents()
  }, [onToggle, refetchOperationalEvents])

  const activeSettingsAndNoSettings = useMemo(() => {
    //get all active settings
    const activeSettingsByName = getGroupedSettings(digitalTwinSettings, language, digitalTwin?.translations)
    //get all properties (including the ones with active settings)
    const allProperties = digitalTwin?.model
      ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model)
      : []

    //filter out properties depending on if they have tags and if they are deviation settings
    const filteredProperties = getFilteredPropertiesByPriority(
      allProperties.filter(property => property?.tags?.length),
      DEVIATION_SETTING_PRIORITIES
    )

    //map filtered properties to stringarray (we are only interested in the name and tags of the property)
    const filteredPropertiesNamesAndTags = filteredProperties[0].map(property => ({
      name: property.name,
      tags: property.tags?.filter(tag => topLevelTags.some(topLevelTag => tag.includes(topLevelTag))), //excluding tags that doesn't exist in production plan
    }))

    const activeSettingsMap = new Map(activeSettingsByName.map(([name, settings]) => [name, settings]))
    const uniquePropertiesNames = new Set(filteredPropertiesNamesAndTags.map(property => property.name))

    //add the names of properties without any active deviation settings
    const propertiesWithoutSettingsByName = Array.from(uniquePropertiesNames)
      .filter(propertyName => propertyName && !activeSettingsMap.has(propertyName))
      .map(propertyName => [propertyName, []])

    const resultWithTags = [...activeSettingsByName, ...propertiesWithoutSettingsByName].map(setting => {
      const matchedProperty = filteredPropertiesNamesAndTags.find(property => property.name === setting[0])
      return {
        name: setting[0],
        settings: setting[1] as DigitalTwinSetting[],
        tags: matchedProperty ? matchedProperty.tags : [],
      }
    })

    return resultWithTags
  }, [digitalTwinSettings, language, digitalTwin?.translations, digitalTwin?.model, digitalTwin?.name, topLevelTags])

  const filteredSettings: [string, DigitalTwinSetting[]][] = useMemo(() => {
    return activeSettingsAndNoSettings.filter(({tags}) => {
      return tags?.length && currentTags.size && matchesSomeTagFromEveryTopLevel(tags, Array.from(currentTags))
    }).map(({name, settings}) => [name as string, settings])
  }, [activeSettingsAndNoSettings, currentTags])

  const displayedSettings: [string, DigitalTwinSetting[]][] = useMemo(() => {
    return hasFilterComponent ? filteredSettings : activeSettingsAndNoSettings.map(({name, settings}) => [name as string, settings])
  }, [filteredSettings, hasFilterComponent, activeSettingsAndNoSettings])

  const activeSettingsAndNoSettingsMatchingSearchTerm = useMemo(() =>
    getGroupedSettingsMatchingSearchTerm(searchTerm, displayedSettings, digitalTwin?.translations),
  [searchTerm, displayedSettings, digitalTwin?.translations]
  )
  const propertiesWithActiveSettingsAndWithoutEntries = searchTerm.length > 0
    ? activeSettingsAndNoSettingsMatchingSearchTerm
    : displayedSettings

  const totalDigitalTwinSettingsCount = useMemo(() => {
    return displayedSettings.reduce((acc, [_, settings]) => acc + settings.length, 0)
  }, [displayedSettings])

  const settingsListItem = useMemo(() => {
    return propertiesWithActiveSettingsAndWithoutEntries.map(([key, settings], index) => {
      const targetObjectName = getPrettyName(key, digitalTwin?.translations)
      const transformedSettings = settings.map((setting) => ({
        id: setting.id,
        name: getPropertyPrettyName(setting.name, setting.attribute, digitalTwin?.translations),
        value: getDigitalTwinSettingValueString({
          settingStartTime: setting.start_time ?? Datetime.toISOString(moment().startOf('day')),
          settingEndTime: setting.end_time ? Datetime.toISOString(moment(setting.end_time)) : null,
          activeValue: setting.value,
          periodStartTime: startTime,
          periodEndTime: endTime,
          attribute: setting.attribute,
          baseValue: null,
          isTimeSeries: setting.isTimeSeries,
        }),
        startDate: formatStartTime(setting.start_time?.toString() ?? ''),
        endDate: formatEndTime(setting.end_time),
        comment: setting.comment,
        operationalEvent: setting.operational_event ? operationalEvents.get(setting.operational_event) : undefined,
      }))

      return (
        <div key={`${index}_${key}`}>
          <DeviationSettingsListItem
            name={key}
            prettyName={targetObjectName}
            settings={transformedSettings}
            showButton={showDeviationSettingsLinkButton}
            onEventClick={setEventPostData}
          />
        </div>
      )
    })}, [digitalTwin?.translations, endTime, operationalEvents, propertiesWithActiveSettingsAndWithoutEntries, startTime, showDeviationSettingsLinkButton])

  return (
    <>
      <DeviationSettingsMenuTab numberOfSettings={totalDigitalTwinSettingsCount} menuWidth={menuWidth} onToggle={handleMenuToggle} />
      {isMenuOpen && <Box sx={{ width: `${menuWidth}%`, height: `calc(100vh - ${headerHeight}px)`}}>
        <div className={styles.DeviationSettingsMenu}>
          <div className={styles.DeviationSettingsMenu_Header}>
            {t('Deviation settings')}
          </div>
          <div className={styles.DeviationSettingsMenu_Search}>
            <TextField
              margin='normal'
              fullWidth
              label={t(`Search`)}
              aria-label={t(`Search`)}
              variant="outlined"
              placeholder={t(`Search`)}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: '#539ed4',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#539ed4',
                  },
                  '& input': {
                    color: 'white',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: 'white' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <TooltipIconButton
                      icon={<Clear />}
                      iconColor={'#fff'}
                      iconSize={'medium'}
                      onClick={() => setSearchTerm('')}
                      tooltip={t('Clear search')}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {isLoadingSettings ? (
            <div>{t('Loading..')}</div>
          ) :
            activeSettingsAndNoSettings.length === 0 ? (
              <div>{t('No active deviation settings found for this period.')}</div>
            ) :
              activeSettingsAndNoSettingsMatchingSearchTerm.length === 0 ? (
                <div>{t('No matching deviation settings found.')}</div>
              ) :
                (
                  settingsListItem
                )}
        </div>
      </Box>}
      {eventPostData && (
        <EventPostViewModal
          closeModal={() => setEventPostData(undefined)}
          selectedEventPostId={eventPostData?.id}
        />
      )}
    </>
  )
}
