import React, { ReactElement } from 'react'

import { useRolesFromOrganization } from 'api/admin/admin.api'

import { useTranslation } from 'react-i18next'

export default function AboutPermissions(): ReactElement {
  const { t } = useTranslation()
  const { data: rolesFromOrg = [] } = useRolesFromOrganization()

  return (
    <div style={{marginLeft: '10px'}}>
      <h4>{t(`Each permission means what you as a user can see and do in the app. Here you can see what each authorization means and does:`)}</h4>
      <h4>{t(`To change a user's permissions, the user's role needs to be changed`)}</h4>

      {rolesFromOrg.map((p) => (
        <div key={p.id}>
          <h4>{p.display_name}:</h4>
          {p.permissions?.map((p, index) => (
            <p key={index}>- {t(`${p.description}`)}</p>
          ))}

        </div>
      )
      )}

    </div>
  )
}
