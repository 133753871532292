import { DigitalTwinSetting } from 'api/digitalTwin/digitalTwin.api'
import { Setting } from 'api/settings/settings.api'

import i18n from 'i18next'
import moment from 'moment'

export type HourSetting = `unplannedStopSummer` | `plannedStop` | `unplannedStopWinter`

// NOTE: Moment has 0-indexed months (month 4 = May, month 11 = December)
const WINTER_START_MONTH = 9
const WINTER_END_MONTH = 11
const SUMMER_START_MONTH = 4
const SUMMER_END_MONTH = 8

export async function addSetting(
  valueKey: 'unplannedStopSummer' | 'plannedStop'| 'unplannedStopWinter',
  year: number,
  propId: number,
  newValue: number,
  comment: string,
  settingsAdd: (
    object_property: number,
    start_time: string,
    end_time: string | null,
    value: number,
    is_base_value: boolean,
    comment: string | null,
    classification?: string
  ) => Promise<Setting | undefined>
): Promise<Setting | undefined> {
  const winterStart = moment(year, 'YYYY').month(WINTER_START_MONTH).startOf('month')
  const winterEnd = moment(year, 'YYYY').month(WINTER_END_MONTH).endOf('month')
  const summerStart = moment(year, 'YYYY').month(SUMMER_START_MONTH).startOf('month')
  const summerEnd = moment(year, 'YYYY').month(SUMMER_END_MONTH).endOf('month')
  const yearStart = moment(year, 'YYYY').startOf('year')
  const yearEnd = moment(year, 'YYYY').endOf('year')
  let start = winterStart
  let end = summerEnd
  let classification = ''

  switch (valueKey) {
    case 'unplannedStopSummer':
      start = summerStart
      end = summerEnd
      classification = 'unplanned'
      break

    case 'plannedStop':
      start = yearStart
      end = yearEnd
      classification = 'planned'
      break

    case 'unplannedStopWinter':
      start = winterStart
      end = winterEnd
      classification = 'unplanned'
      break
  }

  return settingsAdd(propId, start.format(), end.format(), newValue, true, comment, classification)
}

export function getAvailableHoursFromSettings(settings: DigitalTwinSetting[]): {
  unplannedStopSummer?: DigitalTwinSetting
  plannedStop?: DigitalTwinSetting
  unplannedStopWinter?: DigitalTwinSetting
} {
  const unplannedStopSummer = settings.find((setting) => setting.attribute === 'available_hours_unplanned_summer')
  const unplannedStopWinter = settings.find((setting) => setting.attribute === 'available_hours_unplanned_winter')
  const plannedStop = settings.find((setting) => setting.attribute === 'available_hours')

  return { unplannedStopSummer, plannedStop, unplannedStopWinter }
}

export function mapAvailableHoursSettings(settings: Setting[]): {
  unplannedStopSummer?: Setting
  plannedStop?: Setting
  unplannedStopWinter?: Setting
} {
  const unplannedStopSummer = settings.find((setting) => {
    return setting.classification === 'unplanned' && moment(setting.start_time).month() === SUMMER_START_MONTH
  })

  const unplannedStopWinter = settings.find((setting) => {
    return setting.classification === 'unplanned' && moment(setting.end_time).month() === WINTER_END_MONTH
  })

  const plannedStop = settings.find((setting) => {
    return setting.classification === 'planned'
  })

  return { unplannedStopSummer, plannedStop, unplannedStopWinter }
}

export function getSamenergiHeaderText(diff: number): string {
  const tomorrow = moment().add(1, `d`)
  const dayAfterTomorrow = moment().add(2, `d`)

  if (diff < 0){
    const submissionDate = dayAfterTomorrow.format(`D MMMM`)
    return (
      i18n.t(`(tomorrow at 13.20 `) +
      i18n.t(`, following period will be submitted: `) +
      i18n.t(` {{submissionDateStart}} at 00:00 - {{submissionDateEnd}} at 23:00)`, { submissionDateStart: submissionDate, submissionDateEnd: submissionDate  })
    )
  }
  else{
    const submissionDate = tomorrow.format(`D MMMM`)
    return (i18n.t(`(today at 13.20 `) +
      i18n.t(`, following period will be submitted: `) +
      i18n.t(` {{submissionDateStart}} at 00:00 - {{submissionDateEnd}} at 23:00)`, { submissionDateStart: submissionDate, submissionDateEnd: submissionDate  })
    )
  }
}

export function getLastSundayDate(year: number, month: number): moment.Moment {
  const lastDayOfMonth = moment({ year, month }).endOf('month')
  const lastSunday = lastDayOfMonth.subtract(lastDayOfMonth.day(), 'days')
  return lastSunday.startOf('day')
}

export function checkIfSweSummerTime(date: moment.Moment): boolean {
  const year = date.year()
  const marchLastSunday = getLastSundayDate(year, 2) // March is month index 2 (0-based)
  const octoberLastSunday = getLastSundayDate(year, 9) // October is month index 9 (0-based)

  return date.isSameOrAfter(marchLastSunday) && date.isBefore(octoberLastSunday)
}