import { DateValue } from 'react-aria-components'

export function defaultTransformEndDate(date: DateValue | null): DateValue | null {
  //we allow null becuase "Until further notice"
  if (date === null) return date
  const transformedDate = date.set({ minute: 59, second: 0o0 })
  return transformedDate
}

export function defaultTransformStartDate(date: DateValue | null): DateValue | null {
  if (date === null) return date
  const transformedDate = date.set({ minute: 0o0, second: 0o0 })
  return transformedDate
}