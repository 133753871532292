import React, { ReactElement } from 'react'

import Datetime from 'utils/datetime/datetime'

import { IconButton, Icon, Tooltip } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

type TimePeriodPickerProps = {
  startTime: Moment
  setTimePeriod: (startTime: Moment, periodType?: `month` | `week`, timePeriodLength?: number) => void
  periodType?: `month` | `week`
  timePeriodLength?: number
}

function Capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function TimePeriodPicker({
  startTime,
  setTimePeriod,
  periodType = `month`,
  timePeriodLength = 0,
}: TimePeriodPickerProps): ReactElement {
  const { t } = useTranslation()

  let timePeriodFormat = ''
  let timeUnit = ''
  switch (periodType) {
    case `month`:
      timePeriodFormat = `monthYear`
      timeUnit = `month`
      timePeriodLength = 1
      break
    case `week`:
      timePeriodFormat = `week`
      timeUnit = `week`
      timePeriodLength = 1
      break
    default:
      timePeriodFormat = `onlyDate`
      timeUnit = `day`
      break
  }
  return (
    <>
      <Tooltip title={t(`Previous`)} arrow placement="top">
        <IconButton
          onClick={() => {
            setTimePeriod(moment(startTime.add(-timePeriodLength, timeUnit)), periodType, timePeriodLength)
          }}
          size="large"
        >
          <Icon className="fal fa-angle-left" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {`${timeUnit === 'week' ? `${t('Week')} ` : ''}${Capitalize(Datetime.toLocalTime(startTime.toISOString(), timePeriodFormat))}`}
      <Tooltip title={t(`Following`)} arrow placement="top">
        <IconButton
          onClick={() => {
            setTimePeriod(moment(startTime.add(timePeriodLength, timeUnit)), periodType, timePeriodLength)
          }}
          size="large"
        >
          <Icon className="fal fa-angle-right" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  )
}
