import React, { ReactElement } from 'react'

import { FormDatePickerPeriod } from 'ui/molecules/pickers/DatePicker/FormDatePickerPeriod'

import { Grid } from '@mui/material'
import moment from 'moment'

type PeriodPickerProps = {
  setPeriod: (arg0: Period) => void
}

function PeriodPicker({ setPeriod: submitPeriod }: PeriodPickerProps): ReactElement {
  return (
    <Grid item style={{ marginTop: 10 }}>
      <FormDatePickerPeriod
        granularity="day"
        defaultPeriod={{
          startTime: moment().startOf('month'),
          endTime: moment().endOf('month'),
        }}
        onSubmit={submitPeriod}
      />
    </Grid>
  )
}

export default PeriodPicker
