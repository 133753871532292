import React, { ReactElement, useState } from 'react'

import {
  GetSystemsFromOrganization,
  NewUserCreationRequest,
  unpackPrimaryRoleFromUserResData,
  useRolesFromOrganization,
  useUpdateUser,
} from 'api/admin/admin.api'
import { Button } from 'ui/atoms'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import Dropdown from 'ui/components/Dropdown/Dropdown'
import FormRow from 'ui/components/FormRow/FormRow'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'

import { DialogContent, Grid, TextField, IconButton, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from '../UsersList/UsersList.module.less'
import { clone } from 'helpers/global.helper/global.helper'

type EditUserModalProps = {
  user: User
  closeModal: () => void
}

export default function EditUserModal({ user, closeModal }: EditUserModalProps): ReactElement {
  const { t } = useTranslation()
  const { data: systemsFromOrg = [] } = GetSystemsFromOrganization()
  const { data: rolesFromOrg = [] } = useRolesFromOrganization()
  const { mutateAsync: updateUser } = useUpdateUser()
  const [editingUser, setEditingUser] = useState<NewUserCreationRequest>(unpackPrimaryRoleFromUserResData(user))
  const [newSystem, setNewSystem] = useState<{ system: number | undefined, role: number | undefined }[]>([])
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)

  function addNewSystemToRoleList(): void {
    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    const values = editingUser.system_roles
    values.push({ system: undefined, role: undefined })
    const user = {...editingUser, system_roles: values }
    setEditingUser(user)
  }

  function insertAllSystemsToRoleList(): void {
    const systemRoles = systemsFromOrg
      .filter(({ id }) => id !== editingUser.primary_system)
      .map((s) => {
        return { system: s.id, role: undefined }
      })

    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    setEditingUser({
      ...editingUser,
      system_roles: systemRoles,
    })
  }

  function assignRoleToAllSystems(): void {
    const systemRoles = systemsFromOrg
      .filter(({ id }) => id !== editingUser.primary_system)
      .map((s) => {
        return { system: s.id, role: editingUser.primary_role }
      })

    setNewSystem([...newSystem, { system: undefined, role: undefined }])
    setEditingUser({
      ...editingUser,
      system_roles: systemRoles,
    })
  }

  function removeSystemFromRoleList(index: number): void {
    const systemRoles = editingUser.system_roles
    systemRoles.splice(index, 1)
    setEditingUser({
      ...editingUser,
      system_roles: systemRoles,
    })
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={true}
        aria-labelledby="Edit user"
      >
        <>
          <CustomDialogTitle title={t(`Edit user`)} handleClose={() => setCloseConfirmOpen(true)}/>

          <DialogContent dividers>
            <div className={styles.UsersList_Container} key={`${user.id}`}>
              <FormRow>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`First name`)}*`}
                    type='text'
                    value={editingUser?.first_name}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setEditingUser({
                        ...editingUser,
                        first_name: target.value,
                      })
                    }}
                  />
                </Grid>

                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`Last name`)}*`}
                    type='text'
                    value={editingUser?.last_name}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setEditingUser({
                        ...editingUser,
                        last_name: target.value,
                      })
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={`${t(`Email`)}*`}
                    type='text'
                    value={editingUser?.email}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement
                      setEditingUser({
                        ...editingUser,
                        email: target.value,
                      })
                    }}
                  />
                </Grid>

                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <Dropdown
                    fullWidth
                    disabled={systemsFromOrg.length == 1}
                    items={systemsFromOrg.map((s) => ({ label: s.display_name, value: s.id }))}
                    label={`${t(`Primary system`)}*`}
                    value={editingUser.primary_system}
                    handleChange={(e) => setEditingUser({
                      ...editingUser,
                      primary_system: e.target.value as number,
                    })}

                  />
                </Grid>
                <Grid item md={12} sm={12} style={{margin: 5}}>
                  <Dropdown
                    fullWidth
                    items={rolesFromOrg.map((s) => ({ label: t(`${s.display_name}`) ?? s.display_name, value: s.id }))}
                    label={`${t(`Role for primary system`)}*`}
                    value={editingUser.primary_role}
                    handleChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        primary_role: e.target.value as number,
                      })}
                  />
                </Grid>
                <p className={styles.EditUserModal_RequiredFields}>{t(`* Required fields`)}</p>
              </FormRow>

              <FormRow>
                {editingUser?.system_roles.map((userSystemRole, index) => (
                  <Grid item md={12} sm={12} style={{ margin: 5 }} key={`editing_system_role_${index}`}>
                    <div>
                      <Dropdown
                        style={{ width: '43.5%', marginRight: 5 }}
                        items={systemsFromOrg?.map((s) => ({ label: s.display_name, value: s.id }))}
                        label={t(`System`)}
                        value={userSystemRole?.system ?? 0}
                        handleChange={(e) => {
                          const systemRoles = clone(editingUser.system_roles)
                          systemRoles[index] = {
                            ...userSystemRole,
                            system: e.target.value as number,
                          }
                          setEditingUser({ ...editingUser, system_roles: systemRoles })
                        }
                        }

                      />
                      <Dropdown
                        style={{ width: '43.5%' }}
                        items={rolesFromOrg?.map((s) => ({ label: t(`${s.display_name}`) ?? s.display_name, value: s.id }))}
                        label={t(`Role`)}
                        value={userSystemRole.role ?? 0}
                        handleChange={(e) => {
                          const systemRoles = clone(editingUser.system_roles)
                          systemRoles[index] = {
                            ...userSystemRole,
                            role: e.target.value as number,
                          }
                          setEditingUser({ ...editingUser, system_roles: systemRoles })
                        }}
                      />
                      <IconButton onClick={() => removeSystemFromRoleList(index)} aria-label={t(`Remove field`)}>
                        <Icon style={{color: 'red'}} fontSize='small' className="fal fa-trash-alt" />
                      </IconButton>
                    </div>
                  </Grid>
                ))}

                <Button icon={'fal fa-plus'} marginTop onClick={addNewSystemToRoleList}>{t(`Add another system role`)}</Button>
                
                {systemsFromOrg.length > 1 ? 
                  <>
                    <Button icon={'fal fa-plus'} marginTop onClick={insertAllSystemsToRoleList}>{t(`Assign role to all systems...`)}</Button>
                    <Button icon={'fal fa-plus'} marginTop onClick={assignRoleToAllSystems}>{t(`Assign primary role to all systems`)}</Button>
                  </> : null}
              </FormRow>
            </div>

            <ModalButtons
              icon='fal fa-save'
              submitLabel={t(`Save`)}
              onCancel={() => setCloseConfirmOpen(true)}
              disableSubmit={!editingUser.first_name || !editingUser.last_name || !editingUser.email || !editingUser.primary_system || !editingUser.primary_role}
              onSubmit={async () => {
                await updateUser({
                  data: editingUser,
                  id: user.id,
                })
                closeModal()
              }}
            />

          </DialogContent>
        </>
      </Dialog>

      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          setCloseConfirmOpen(false)
          closeModal()
        }}
        textObject={{
          title: t(`Do you really want to close without saving?`),
          text: t(
            `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
          ),
        }} />
    </>
  )
}
