import React, { ReactElement, useState } from 'react'

import { useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import { ShiftHandoverFormData } from 'api/shiftHandovers/shiftHandovers.api'
import Tour from 'ui/components/Tour/Tour'

import { Grid, Typography, Icon } from '@mui/material'
import classNames from 'classnames'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'


import { getDisplayName, prettyPrintAnySettingValue } from 'helpers/global.helper/global.helper'

import styles from './HandoverComments.module.less'
import CommentField from './components/CommentField'

type HandoverCommentsProps = {
  settings: Setting[]
  disabled?: boolean
}

const handoverComments = `handoverComments`
const step: Array<Step> = [
  {
    target: handoverComments,
    content: `You fill out this form if there is something that occured during your shift regarding plant parts or grouped parts, which is worth noting. Information regarding producution plan settings for specific plant parts will be displayed automatically above the comment section, if applicable during your shift. If necessary, you can still add another comment below.`,
    title: `Comments`,
  },
]

export default function HandoverComments({ settings, disabled }: HandoverCommentsProps): ReactElement {
  const [runTour, setRunTour] = useState(false)
  const { data: objectProperties = [] } = useObjectProperties({ classification: `deviation` })
  const { control } = useFormContext<ShiftHandoverFormData>()
  const { t } = useTranslation()
  const { fields } = useFieldArray({
    control,
    name: `comment_groups`,
    keyName: `group_id`,
  })

  return (
    <>
      <Tour isSingle={true} run={runTour} setRunState={setRunTour} steps={step} />
      <Grid container direction="column" className={handoverComments}>
        <Grid container className={styles.HandoverComments_Container}>
          <Grid item container direction="row">
            <Grid item>
              <Icon
                className={classNames(styles.HandoverComments_Icon, `fas fa-info-circle`)}
                onClick={() => setRunTour(true)}
              />
            </Grid>
            <Grid item>
              <Typography variant="h2" className={styles.HandoverComments_Title} display="inline">
                {t(`What happened during the shift?`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column" justifyContent="space-between" spacing={3}>
          {fields.length > 0 ? (
            fields.map((group, groupIndex) => (
              <CommentField
                key={`group_` + group.group_id}
                groupIndex={groupIndex}
                groupId={String(group.id) ?? ``}
                name={getDisplayName({ ...group, name: `Group` })}
                disabled={disabled}
                settingComments={settings.reduce((prevComments: string[], currentSetting) => {
                  const property = objectProperties.find((prop) => prop.id === currentSetting.object_property)
                  if (property?.parent?.type === `unit` && group?.units?.includes(property?.parent.id)) {
                    const commentStr =
                      currentSetting.comment !== ``
                        ? `{{property}} for {{group}} has value {{propertyValue}} {{unit}} with cause: {{commentCause}}`
                        : `{{property}} for {{group}} has value: {{propertyValue}} {{unit}}`
                    return [
                      ...prevComments,
                      t(commentStr, {
                        property: getDisplayName(property),
                        group: getDisplayName(property.parent),
                        propertyValue: prettyPrintAnySettingValue(property, currentSetting),
                        unit: property.measurement_unit ?? ``,
                        commentCause: currentSetting.comment,
                      }),
                    ]
                  }
                  return prevComments
                }, [])}
              />
            ))
          ) : (
            <Grid item container alignItems="center" justifyContent="center" xs={12}>
              <Typography variant="body1">{t(`No comments from this handover`)}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}
