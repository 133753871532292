import { apiClient } from 'api/apiClient/apiClient'
import { useNodes } from 'api/nodes/nodes.api'

import { Moment } from 'moment'
import { useQueries } from 'react-query'

export const WEATHER_LOCATION_QUERY_KEY = `weatherLocation`
export const WEATHER_FORECAST_QUERY_KEY = `weatherForecast`

export type WeatherLocation = DisplayNameObject & {
  id: number
}
export type WeatherForecast = ResultTimeObject & {
  id: number
  temperature: number
  weather_symbol: number
  source: string
  reference_time: string
}

export function useWeatherLocations() {
  const { data: nodes = [] } = useNodes()
  const weatherLocations = nodes.reduce(
    (prev: number[], next) => [...prev, ...next.weather_locations.map((location) => location.weather_location)],
    []
  )
  return useQueries(
    weatherLocations.map((location) => ({
      queryKey: [WEATHER_LOCATION_QUERY_KEY, location],
      queryFn: () => apiClient<WeatherLocation>(`weather_locations/${location}`),
    }))
  )
}
export function useWeatherForecast({ start_time }: { start_time: Moment }) {
  const { data: nodes = [] } = useNodes()
  const weatherLocations = nodes.reduce(
    (prev: number[], next) => [...prev, ...next.weather_locations.map((location) => location.weather_location)],
    []
  )

  return useQueries(
    weatherLocations.map((location) => ({
      queryKey: [WEATHER_FORECAST_QUERY_KEY, location],
      queryFn: () =>
        apiClient<WeatherForecast[]>(`weather_locations/${location}/weather_forecast`, {
          params: {
            start_time: start_time.format(`YYYY-MM-DD HH:mm`),
            end_time: start_time.clone().add(1, `hour`).format(`YYYY-MM-DD HH:mm`),
          },
        }).then((response) => {
          return { [location]: response }
        }),
    }))
  )
}
