import { setMinMax } from 'store/chart/chart'

import { Chart } from 'chart.js'

import { ChartConfigWithZoom } from '../chartTypes'

type SyncYAxisPlugin = {
  enabled: boolean
}

export type SyncYAxisOptions = {
  enabled: boolean
}

type ChartWithSyncYAxis = Chart & ChartConfigWithZoom & {
  corsair: SyncYAxisPlugin
}

export function updateChartConfigWithYAxisLimits(
  config: ChartConfigWithZoom,
  yAxisLimits: Record<string, { min: number, max: number }>
): ChartConfigWithZoom {
  if (yAxisLimits) {
    Object.entries(yAxisLimits).forEach(([yAxisId, limits]) => {
      if (config.options?.scales?.[yAxisId]) {
        if (config.options.scales[yAxisId].suggestedMin === undefined) {
          config.options.scales[yAxisId].suggestedMin = limits.min
        }

        if (config.options.scales[yAxisId].suggestedMax === undefined) {
          config.options.scales[yAxisId].suggestedMax = limits.max
        }
      }
    })
  }

  return config
}

export default {
  id: 'syncYAxis',
  afterUpdate(chart: ChartWithSyncYAxis, _: any, opts: SyncYAxisOptions) {
    if (!opts.enabled) {
      return
    }

    if (!chart.options.plugins.chartLegend?.shared) {
      return
    }

    const axisMinMax: Record<string, { min: number, max: number }> = {}

    Object.entries(chart.scales)
      .filter(([axisId]) => axisId !== 'x')
      .forEach(([axisId, { min, max }]) => {
        axisMinMax[axisId] = { min, max }
      })

    setMinMax(chart.id, axisMinMax)
  },
}
