import { apiClient } from 'api/apiClient/apiClient'
import { HEAT_SUPPLIERS_DATA_QUERY_KEY } from 'api/heatSuppliers/heatSuppliers.api'
import { OBJECT_PROPERTIES_QUERY_KEY } from 'api/objectProperties/objectProperties.api'
import { UNITS_QUERY_KEY } from 'api/units/units.api'
import alertStore from 'store/alert/alert'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient, useMutation, UseQueryOptions } from 'react-query'

export type Setting = DisplayNameObject & {
  id: number
  start_time: ISODateTime
  end_time: ISODateTime
  updated_at: string
  object_property: number
  value: number
  comment: string | null
  classification: string
  creator_name?: string
  is_base_value: boolean
}

type SettingsQueryParams = {
  object_property?: number[]
  is_base_value?: boolean
  active_from?: string
  active_to?: string
}

export type SettingCreateParams = {
  object_property: number
  start_time: string
  end_time: string | null
  value: number
  is_base_value: boolean
  comment: string | null
  classification?: string
}

export const SETTINGS_QUERY_KEY = `settings`
export function useObjectPropertySettings(objectPropertyIds: number | number[], params?: SettingsQueryParams) {
  return useQuery<Setting[]>(
    [SETTINGS_QUERY_KEY, { object_property: objectPropertyIds, is_base_value: false, ...params }],
    () =>
      apiClient<Setting[]>(`settings`, {
        params: {
          object_property: objectPropertyIds,
          is_base_value: false,
          ...params,
        },
      }),
    {
      refetchOnWindowFocus: true,
    }
  )
}

export function useSettings({
  optModel,
  params,
  queryConfig,
}: {
  optModel?: number
  params: SettingsQueryParams
  queryConfig?: UseQueryOptions<
    Setting[],
    Error,
    Setting[],
    [string, SettingsQueryParams & { belongs_to_opt_model?: number }]
  >
}) {
  const { activeSystem } = useAuth()
  const optModelId = optModel ?? activeSystem?.primary_opt_model?.id

  return useQuery(
    [
      SETTINGS_QUERY_KEY,
      {
        ...params,
        is_base_value: params?.is_base_value ?? false,
        belongs_to_opt_model: optModelId,
      },
    ],
    () =>
      apiClient<Setting[]>(`settings`, {
        params: {
          ...params,
          is_base_value: params?.is_base_value ?? false,
          belongs_to_opt_model: optModelId,
          active_from: params?.active_from ? moment(params.active_from).format() : undefined,
          active_to: params?.active_to ? moment(params.active_to).format() : undefined,
        },
      }),
    {
      ...queryConfig,
      enabled: !!optModelId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
      refetchOnWindowFocus: true,
    }
  )
}

export function useSettingMutation() {
  const postSetting = async (data: SettingCreateParams) => apiClient<Setting>(`settings`, { data })

  const patchSetting = async (id: number, data: SettingCreateParams) =>
    apiClient<Setting>(`settings/${id}`, { method: `PATCH`, data })

  const postOrPatchSetting = async ({ data, id }: { data: SettingCreateParams; id?: number }) => {
    if (id) return patchSetting(id, data)
    return postSetting(data)
  }

  const queryClient = useQueryClient()

  return useMutation<Setting, Error, { id?: number; data: SettingCreateParams }>(postOrPatchSetting, {
    onSuccess: async (data) => {
      queryClient.setQueryData<Setting[] | undefined>([SETTINGS_QUERY_KEY, { property: data.object_property }], (old) =>
        old?.map((setting) => (setting.id !== data.id ? setting : data))
      )
      queryClient.invalidateQueries(SETTINGS_QUERY_KEY)
      queryClient.invalidateQueries([OBJECT_PROPERTIES_QUERY_KEY, data.object_property], {
        refetchInactive: true,
      })
      queryClient.invalidateQueries(HEAT_SUPPLIERS_DATA_QUERY_KEY)
      queryClient.invalidateQueries(UNITS_QUERY_KEY)
    },
  })
}

export function useSettingDeleteMutation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, objectPropertyId: _ }: { id: number; objectPropertyId: number }) =>
      apiClient<{ id: number; objectPropertyId: number }>(`settings/${id}`, { method: `DELETE` }),
    {
      onMutate: async ({ id, objectPropertyId }: { id: number; objectPropertyId: number }) => {
        const key = [SETTINGS_QUERY_KEY, { property: objectPropertyId }]
        // Canceling any conflicting queries to not overwrite the optimistic update
        await queryClient.cancelQueries(key)

        // Saving previous settings for rollback if error
        const previousSettings = queryClient.getQueryData<Setting[] | undefined>([
          SETTINGS_QUERY_KEY,
          { property: objectPropertyId },
        ])

        // Optimistically remove setting
        queryClient.setQueryData<Setting[] | undefined>(key, (old) => old?.filter((setting) => setting.id !== id))

        // Return rollback function
        return () => queryClient.setQueryData(key, previousSettings)
      },
      onError: (error, id, rollback) => {
        rollback?.()
      },
      onSuccess: (_, { objectPropertyId }) => {
        alertStore.success(t(`Setting removed.`))

        queryClient.invalidateQueries([OBJECT_PROPERTIES_QUERY_KEY, objectPropertyId], { refetchInactive: true })
        queryClient.invalidateQueries(SETTINGS_QUERY_KEY)
        queryClient.invalidateQueries(HEAT_SUPPLIERS_DATA_QUERY_KEY)
        queryClient.invalidateQueries(UNITS_QUERY_KEY)
      },
    }
  )
}
