import { useCallback, useEffect, useState } from 'react'

import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Unit } from 'api/units/units.api'

import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'
export function useSettingsModalTitle({
  editing,
  unit,
  objectProperty,
}: {
  editing?: boolean
  unit?: Unit
  objectProperty?: ObjectProperty
  }): string {
  const { t } = useTranslation()

  if (unit) {
    return editing
      ? t(`Modify setting for {{unit}}`, { unit: getDisplayName(unit) })
      : t(`Create setting for {{unit}}`, { unit: getDisplayName(unit) })
  }

  const parentName = objectProperty?.parent ? getDisplayName(objectProperty.parent) : '-'
  return objectProperty ? `${getDisplayName(objectProperty)} - ${parentName}` : ``
}

export function useScheduledCallback({ time, callback }: { time?: Moment; callback: () => void }): void {
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined

    function clearCurrentTimeout(): void {
      if (timeout) clearTimeout(timeout)
    }
    clearCurrentTimeout()

    if (time) {
      timeout = setTimeout(() => {
        callback()
      }, moment(time).diff(moment()))
    }

    return () => {
      clearCurrentTimeout()
    }
  }, [time, callback])
}

export function useTimeoutInterval({
  startTime,
  interval,
  callback,
}: {
  startTime?: Moment
  interval: number
  callback: () => void
}): void {
  useEffect(() => {
    let intervalTimout: NodeJS.Timeout | undefined = undefined
    let timeout: NodeJS.Timeout | undefined = undefined

    function clearIntervalAndTimeout(): void {
      if (intervalTimout) clearInterval(intervalTimout)
      if (timeout) clearTimeout(timeout)
    }
    clearIntervalAndTimeout()

    if (startTime) {
      timeout = setTimeout(() => {
        callback()
        intervalTimout = setInterval(() => callback(), interval)
      }, moment(startTime).diff(moment()))
    } else {
      intervalTimout = setInterval(() => callback(), interval)
    }

    return () => {
      clearIntervalAndTimeout()
    }
  }, [startTime, interval, callback])
}

const followupPeriodSessionStorage = `followupPeriod`
function getFollowupPeriodFromSessionStorage(): Period | null {
  try {
    const followupPeriod = window.sessionStorage.getItem(followupPeriodSessionStorage)
    if (followupPeriod === null) return followupPeriod
    const parsedFollowupPeriod = JSON.parse(followupPeriod)
    return {
      startTime: moment(parsedFollowupPeriod.startTime),
      endTime: moment(parsedFollowupPeriod.endTime),
    }
  } catch {
    return null
  }
}

function setFollowupPeriodSessionStorage(period: Period): void {
  window.sessionStorage.setItem(followupPeriodSessionStorage, JSON.stringify(period))
}

export function useFollowupPeriod(paramStartTime?: Moment, paramEndTime?: Moment): [Period, (period: Period) => void] {
  const [period, setPeriodLocal] = useState<Period>(() => {
    const followupPeriod = getFollowupPeriodFromSessionStorage()

    if (paramStartTime && paramEndTime) {
      return { startTime: paramStartTime, endTime: paramEndTime.add(23, `hours`).minutes(0).seconds(0) }
    }
    return (
      followupPeriod ?? {
        startTime: moment().add(-1, `week`).startOf(`isoWeek`),
        endTime: moment().add(-1, `week`).endOf(`isoWeek`).minutes(0).seconds(0),
      }
    )
  })

  const setPeriod = useCallback((period: Period) => {
    setFollowupPeriodSessionStorage(period)
    setPeriodLocal(period)
  }, [])

  return [period, setPeriod]
}
