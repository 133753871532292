import { ReactElement } from 'react'

import Switch from 'ui/atoms/Switch/Switch'

import { useTranslation } from 'react-i18next'

import { OptimizeViewStore } from 'views/OptimizeView/store/optimizeViewStore'
import { useShouldShowCustomDatepicker } from 'views/OptimizeView/utils/useShouldShowCustomDatepicker'

type ZoomResolutionState = OptimizeViewStore['zoomResolutionSwitch']['type']

interface DataResolutionProps {
  onClick: (value: ZoomResolutionState) => void
  value: ZoomResolutionState
}

export default function DataResolutionSwitch(props: DataResolutionProps): ReactElement {
  const { t } = useTranslation()
  const { value, onClick } = props
  const shouldShowCustomDatepicker = useShouldShowCustomDatepicker()

  const baseItems = [
    {
      value: `week`,
      label: t(`Week`),
    },
    {
      value: `day`,
      label: t(`Day`),
    },
  ]

  const items: { value: string; label: string }[] = []
  if (shouldShowCustomDatepicker) {
    items.push({
      value: `custom`,
      label: t(`Custom`),
    })
  }
  items.push(...baseItems)

  // The Switch component stores the selected tabIndex in its internal state.
  // Given that we have a dynamic number of items we need to reset the state
  // when the items change.
  const key = items.map((item) => item.value).join(':')

  return <Switch key={key} value={value} onClick={onClick} items={items} />
}
