import {
  ConfigurableProperty,
  DigitalTwinSetting,
  DeviationSettingLevel,
  DigitalTwin,
  DigitalTwinUnit,
  DigitalTwinSettingMetadata,
} from 'api/digitalTwin/digitalTwin.api'
import { BarCalendarRow, Category, Event } from 'ui/components/BarCalendar/BarCalendar.types'

import i18n, { TFunction } from 'i18next'

import { isBinarySetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getPrettyName, getSettingsWithUnitsFromMatchingConfigurableProperty } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

export function generateDTSCCategoriesAndEvents(
  digitalTwin: DigitalTwin,
  configurableProperties: ConfigurableProperty[],
  deviationSettings: DigitalTwinSetting[],
  activeBaseSettings: DigitalTwinSettingMetadata[],
  t: TFunction
): { categories: Category[], events: Event[] } {
  const categories: Category[] = []
  const events: Event[] = []
  const deviationSettingsWithUnits = getSettingsWithUnitsFromMatchingConfigurableProperty(digitalTwin, deviationSettings)
  const items: BarCalendarRow<string, number>[] = convertDTSettingsToCalendarRows(digitalTwin, configurableProperties, deviationSettingsWithUnits, activeBaseSettings, t)

  items.forEach(item => {
    categories.push({
      id: item.id,
      name: item.name,
    })

    item.items.forEach(subItem => {
      events.push({
        id: subItem.id.toString(),
        attribute: subItem.attribute,
        type: subItem.label,
        startTime: subItem.startTime,
        endTime: subItem.endTime,
        categoryId: item.id,
        name: subItem.label,
        meta: {
          tooltipElement: subItem.tooltipElement,
          clickable: subItem.clickable,
          isTimeSeries: subItem.meta?.isTimeSeries,
          value: subItem.meta?.value,
          creatorName: subItem.meta?.creatorName,
        },
        icon: subItem.icon,
        tooltipHeader: subItem.tooltipHeader,
      })
    })
  })

  return { categories, events }
}

function addItem(digitalTwin: DigitalTwin, items: BarCalendarRow<string, number>[], lookUpIdToIndexMap: Record<string, number>, configurableLookUpIds: Set<string>, level: DeviationSettingLevel, name: string): void {
  const lookUpId = levelAndNameToLookUpId(level, name)
  if (!configurableLookUpIds.has(lookUpId)) {
    return
  }
  lookUpIdToIndexMap[lookUpId] = items.length
  items.push({
    id: lookUpId,
    name: getPrettyName(name, digitalTwin?.translations),
    color: 'black',
    items: [],
  })
}

export function convertDTSettingsToCalendarRows(
  digitalTwin: DigitalTwin,
  configurableProperties: ConfigurableProperty[],
  deviationSettings: DigitalTwinSetting[],
  activeBaseSettings: DigitalTwinSettingMetadata[],
  t: TFunction
): BarCalendarRow<string, number>[] {
  const items: BarCalendarRow<string, number>[] = []
  const lookUpIdToIndexMap: Record<string, number> = {}
  const configurableLookUpIds: Set<string> = new Set(configurableProperties.map(c => `${c.level}.${c.name}`))

  // model
  addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'model', digitalTwin.name)

  // nodes & units
  digitalTwin.model.nodes.forEach(node => {
    addItem(digitalTwin, items,lookUpIdToIndexMap,  configurableLookUpIds, 'node', node.name)

    node.units?.forEach((unit: DigitalTwinUnit) => {
      addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'unit', unit.name)
    })
  })

  // commodities & byproducts
  digitalTwin.model.commodities.forEach(commodity => {
    addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'commodity', commodity.name)

    commodity.byproducts?.forEach(byproduct => {
      addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'byproduct', byproduct.name)
    })
  })

  // exchanges
  digitalTwin.model.exchanges.forEach(exchange => {
    addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'exchange', exchange.name)
  })

  // balances
  digitalTwin.model.balances.forEach(balance => {
    addItem(digitalTwin, items, lookUpIdToIndexMap, configurableLookUpIds, 'balance', balance.name)
  })

  deviationSettings.forEach(ds => {
    const lookUpId = levelAndNameToLookUpId(ds.level, ds.name)
    const index = lookUpIdToIndexMap[lookUpId]

    if (index === -1 || index === undefined) {
      return
    }

    const typeOfSettingLabel = (ds.isTimeSeries && ds.operational_event) ? ` (${i18n.t('Time series & Event')})` : ds.isTimeSeries ? ` (${i18n.t('Time series')})` : ds.operational_event ? ` (${i18n.t('Event')})` : ''
    const attributeDisplayName = getPrettyName(`${ds.name}.${ds.attribute}`, digitalTwin?.translations)
    const item = items[index]
    const unit = ds.unit || ''
    let value = ''

    if (ds.isTimeSeries && ds.value instanceof Array && ds.value.length > 0) {
      if (isBinarySetting(ds.attribute)) {
        value = t('{{attribute}} active {{activeHours}} of {{totalHours}} hours', {
          attribute: attributeDisplayName,
          activeHours: ds.value.filter((v) => (ds.attribute.includes('forced') ? v.value === 1 : v.value === 0)).length,
          totalHours: ds.value.length,
        })
      } else {
        const valueArray = ds.value.map((v) =>
          v.value === null || v.value === undefined ? '-' : `${Math.round(v.value * 100) / 100} ${unit}`
        )

        if (valueArray.length > 3) {
          value = `[${valueArray[0]}, ${valueArray[1]}, ..., ${valueArray[valueArray.length - 1]}]`
        } else {
          value = '[' + valueArray.join(', ') + ']'
        }
      }
    } else {
      value = `${ds.value || attributeDisplayName} ${unit}`
    }

    const activeConfigurableProperty: ConfigurableProperty | undefined = configurableProperties.find(
      (p) => p.name === ds.name && p.attribute === ds.attribute
    )
    const defaultValue = activeConfigurableProperty?.default_value
    const defaultValueString =
      defaultValue !== undefined && defaultValue !== null && !isBinarySetting(ds.attribute)
        ? ` (${defaultValue} ${unit})`
        : ''

    const activeBaseSetting: DigitalTwinSettingMetadata | undefined = activeBaseSettings?.find(
      (p) =>
        p.name === ds.name &&
        p.attribute === ds.attribute
    )
    const baseSettingValue = activeBaseSetting?.value
    const baseSettingValueString =
      baseSettingValue !== undefined && baseSettingValue !== null && !isBinarySetting(ds.attribute)
        ? ` (${baseSettingValue} ${unit})`
        : ''

    let label = `${attributeDisplayName}${typeOfSettingLabel ? '' : `: ${value}${baseSettingValueString ? baseSettingValueString : defaultValueString}`}`

    if(ds.attribute.includes('forced') || ds.attribute.includes('availability')) {
      //special case for forced and availability attributes to not show the actual binary value as display name in the calendar
      label = attributeDisplayName
    }

    item.items.push({
      id: ds.id,
      attribute: ds.attribute,
      label: `${ds.value ? label : attributeDisplayName}`,
      tooltipHeader: `${attributeDisplayName}${typeOfSettingLabel}`,
      color: 'white',
      backgroundColor: 'black',
      clickable: true,
      tooltipElement: `${i18n.t('Cause')}: ${ds.comment}`,
      startTime: ds.start_time || null,
      endTime: ds.end_time || null,
      icon: ds.operational_event ? 'fal fa-calendar-week' : ds.isTimeSeries ? 'fal fa-clock' : undefined,
      meta: {
        isTimeSeries: ds.isTimeSeries,
        baseSettingValue: baseSettingValue,
        defaultValue: defaultValue,
        value: ds.value ? `${value}${baseSettingValueString ? baseSettingValueString : defaultValueString}` : null,
        creatorName: ds.creator_name,
      },
    })
  })

  return items
}

export function lookUpIdToLevelAndName(lookUpId: string): { level: DeviationSettingLevel, name: string } {
  const level = lookUpId.split('.')[0] as DeviationSettingLevel
  const name = lookUpId.replace(`${level}.`, '')

  return { level, name }
}

export function levelAndNameToLookUpId(level: DeviationSettingLevel, name: string): string {
  return `${level}.${name}`
}
