import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Button, Typography, Icon } from '@mui/material'
import classNames from 'classnames'

import styles from './ManageButton.module.less'

type ManageButtonProps = {
  title: string
  infoTooltip: string
  onClick: () => void
}

export default function ManageButton({ title, infoTooltip, onClick }: ManageButtonProps): ReactElement {
  return (
    <Grid item xs={12} md={10}>
      <Button variant="outlined" color="secondary" className={styles.ManageButton_Button} onClick={onClick}>
        <Tooltip arrow placement="top" title={title}>
          <Typography className={styles.ManageButton_ButtonText}>{title}</Typography>
        </Tooltip>
        <Typography className={styles.ManageButton_DetailedText} />
        <Tooltip arrow placement="top" title={infoTooltip}>
          <Icon className={classNames(`fas fa-info-circle`, styles.ManageButton_InfoIcon)} fontSize="inherit" />
        </Tooltip>
      </Button>
    </Grid>
  )
}
