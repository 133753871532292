import React, { ReactElement, useState } from 'react'

import alertStore from 'store/alert/alert'
import Button from 'ui/atoms/Button/Button'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps } from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import { fetchUiConfigDatasetsAndExportToExcel } from 'helpers/dataset.helper/dataset.helper'

export type DatasetExports = {
  ui_config_id: number
  title: string
  dataset_instructions?: DatasetInstruction[]
  defaultTimeFormat?: string
  overrideAlias?: UiConfigAliases
  overrideProps?: UiConfigProps
  show_times_in_utc?: boolean
  time_format?: string
}

type ExcelButtonProps = {
  defaultTimeFormat?: string
  tabs: DatasetExports[]
  title?: string
  style?: `button` | `icon`
  datasetStartTime?: ISODateTime
  datasetEndTime?: ISODateTime
  datasetCreatedAt?: string
  datasetCreatedAtOperator?: DatasetCreatedAtOperators
  disable_button_in_future?: boolean
}

export type ExcelButtonType = DatasetExports & {
  datasets: Dataset[]
  title: string
}

const DEFAULT_TIME_FORMAT = `YYYY-MM-DD HH:mm`

const defaultProps: ExcelButtonProps = {
  title: ``,
  style: `icon`,
  disable_button_in_future: false,
  tabs: [
    {
      ui_config_id: 0,
      title: `Sheet 1`,
      dataset_instructions: [],
      defaultTimeFormat: DEFAULT_TIME_FORMAT,
      show_times_in_utc: false,
    },
  ],
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: ExcelButtonProps
): ExcelButtonProps {
  return {
    ...previousProps,
    tabs: [
      ...(previousProps?.tabs || []),
      {
        ui_config_id: -1,
        title: `Sheet 1`,
        dataset_instructions: [],
        defaultTimeFormat: DEFAULT_TIME_FORMAT,
        show_times_in_utc: false,
      },
    ],
  }
}

registerUiConfigComponent(`excel_button`, ExcelButton)
registerAppendPropsWithDatasets(`excel_button`, appendPropsWithDataset)
registerDefaultProps(`excel_button`, defaultProps)

function isDateInFuture(date: ISODateTime): boolean {
  const currentDate = Datetime.getISONow()

  return Datetime.isAfterOrEqual(date, currentDate)
}

export default function ExcelButton({
  tabs,
  title,
  style,
  datasetStartTime,
  datasetEndTime,
  datasetCreatedAt,
  datasetCreatedAtOperator,
  disable_button_in_future,
}: ExcelButtonProps): ReactElement {
  const { t } = useTranslation()
  const [exportInProgress, setExportInProgress] = useState(false)
  const disabled = disable_button_in_future 
    ? datasetStartTime && isDateInFuture(datasetStartTime) 
    : false
  const disabledTooltip = t(`Cannot fetch optimization results from the future`)

  if (!tabs?.length) {
    return <></>
  }

  return (
    <Button
      disabled={exportInProgress || disabled}
      disabledTooltip={disabled ? disabledTooltip : ``}
      onClick={() => {
        if (!exportInProgress && datasetStartTime && datasetEndTime) {
          setExportInProgress(true)

          fetchUiConfigDatasetsAndExportToExcel({
            tabs,
            datasetStartTime,
            datasetEndTime,
            datasetCreatedAt,
            datasetCreatedAtOperator,
            time_format: DEFAULT_TIME_FORMAT,
          })
            .then(() => {
              setExportInProgress(false)
            })
            .catch((e) => {
              alertStore.error(e.message)
              setExportInProgress(false)
            })
        }
      }}
      link={style !== `button`}
      secondary={style === `button`}
      large
      marginTop
      marginRight
      icon={`far fa-file-excel`}
    >
      {title !== `` ? title : t(`Export`)}
    </Button>
  )
}
