import React, { ReactElement, useState } from 'react'

import authStore from 'store/auth/auth'
import globalStore from 'store/global/global'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { Card, Toggle } from 'ui/atoms'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import FaIcon from 'ui/components/FaIcon/FaIcon'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import MyPermissions from 'ui/components/MyPermissions/MyPermissions'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'
import { useSnapshot } from 'valtio'

import AccountSettings from './components/AccountSettings/AccountSettings'

const widgetConstants = {
  ACCOUNT_WIDGET: `AccountWidget`,
}

const account: Array<Step> = [
  {
    target: widgetConstants.ACCOUNT_WIDGET,
    content: `Here you can view and edit your user information. By security reasons you may not change your email-adress. If you must, please contact us through the contact form above.`,
    title: `Account settings`,
  },
]

export default function UserProfileView(): ReactElement {
  const [runTour, setRunTour] = useState<null | number>(null)
  const authSnap = useSnapshot(authStore)
  const isSuperUser = authSnap.isSuperUser
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const globalSnap = useSnapshot(globalStore)
  const { t } = useTranslation()

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={t(`Account settings`)}
          onInfoButtonClick={() => {
            setRunTour(0)
          }}
        />
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? account : account}
      />
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.ACCOUNT_WIDGET}
          icon={<FaIcon name="fal fa-user-cog" />}
          title={t(`Handle account settings`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <AccountSettings />
        </CardWidget>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Card
            width="97%"
            collapsible
            defaultCollapsed
            margin
            title={<TitleWithIcon size="medium" title={t(`Your permissions on this system:`)} />}
          >
            <MyPermissions />
          </Card>
        </Grid>
        {isSuperUser && (
          <Grid item xs={12} md={6}>
            <Card margin>
              <h3>{t('Super user settings')}</h3>
              <Toggle
                label={t('Show Ui Config debug menu')}
                value={uiConfigSnap.showDebugMenu}
                onClick={(value) => {
                  uiConfigStore.showDebugMenu = value
                }}
              />

              <br />

              <Toggle
                label={t('Open system menu with shortcut (s-key)')}
                value={globalSnap.systemShortcut}
                onClick={(value) => {
                  globalStore.systemShortcut = value
                }}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </View>
  )
}
