import { useCallback, useEffect, useState } from 'react'

const SHIFT_KEY_CODE = 'Shift'

export function useShiftKeySelected(): boolean {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false)

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === SHIFT_KEY_CODE) {
      setShiftKeyPressed(true)
    }
  }, [])
  const onKeyUp = useCallback((event: KeyboardEvent) => {
    if (event.key === SHIFT_KEY_CODE) {
      setShiftKeyPressed(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  })

  return shiftKeyPressed
}