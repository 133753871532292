import { apiClient } from 'api/apiClient/apiClient'

import { useMutation, useQuery, useQueryClient } from 'react-query'

export type EventType = `planned_backing` | `planned_unavailability` | ``

export type PlannedStopItem = {
  id: number
  event_type: EventType
  comment: string
  period: Period
}

export type PlannedStopFormData = {
  id?: number
  event_type: EventType
  comment: string
  start_time: string
  end_time: string
  unit: number
}

export const AVAILABILITY_EVENT_QUERY_KEY = `plannedStop`

export function usePlannedStop(unit: number, period?: Period) {
  return useQuery(
    [
      AVAILABILITY_EVENT_QUERY_KEY,
      {
        unit: unit,
        active_to: period?.endTime.endOf(`d`).format(),
        active_from: period?.startTime.startOf(`d`).format(),
      },
    ],
    () =>
      apiClient<PlannedStopFormData[]>(`planned_stop`, {
        params: {
          unit: unit,
          active_to: period?.endTime.endOf(`d`).format(),
          active_from: period?.startTime.startOf(`d`).format(),
        },
      }),
    {
      enabled: !!unit,
    }
  )
}

export function usePlannedStopMutation() {
  const postPlannedStop = (data: PlannedStopFormData) => apiClient(`planned_stop`, { data })

  const patchPlannedStop = (id: number, data: PlannedStopFormData) =>
    apiClient(`planned_stop/${id}`, { data, method: `PATCH` })

  const postOrPatchPlannedStop = ({ data, id }: { data: PlannedStopFormData; id?: number }) => {
    if (id) return patchPlannedStop(id, data)
    return postPlannedStop(data)
  }

  const queryClient = useQueryClient()

  return useMutation(postOrPatchPlannedStop, {
    onSuccess: () => {
      queryClient.invalidateQueries(AVAILABILITY_EVENT_QUERY_KEY)
    },
  })
}

export function usePlannedStopDeleteMutation() {
  const deletePlannedStop = (id: number) => apiClient<{ id: number }>(`planned_stop/${id}`, { method: `DELETE` })

  const queryClient = useQueryClient()

  return useMutation(deletePlannedStop, {
    onSuccess: () => {
      queryClient.invalidateQueries(AVAILABILITY_EVENT_QUERY_KEY)
    },
  })
}
