import React from 'react'

import { Button, DatePickerStateContext } from 'react-aria-components'


import styles from '../DatePicker/DateField.module.less'

type CalendarToggleButtonProps = {
  disabled?: boolean
}

export function CalendarToggleButton({disabled}: CalendarToggleButtonProps): JSX.Element {
  const state = React.useContext(DatePickerStateContext)

  return (
    <Button data-testid="calendar-toggle-btn" className={`${styles.DateField_Button} ${state.isOpen ? styles.DateField_Button_popoverOpen : ''}`}>
      <span
        className={`fal fa-calendar ${disabled ? styles.DateField_Button_ButtonIcon__Disabled : styles.DateField_Button_ButtonIcon} ${state.isOpen ? styles.DateField_Button_ButtonIcon_popoverOpen : ''}`}
      />
    </Button>
  )
}
