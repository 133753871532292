import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { Moment } from 'moment'
import { useQuery } from 'react-query'

export type WorkShift = DisplayNameObject & {
  id: number
  start_time: string
  end_time: string
  schedule_order: number
  should_report: boolean
}

export const WORK_SHIFTS_QUERY_KEY = `workShifts`

export function useWorkShifts() {
  const { systemId } = useAuth()

  const params = { system: systemId }
  return useQuery(
    [WORK_SHIFTS_QUERY_KEY, params],
    () =>
      apiClient<WorkShift[]>(`work_shifts`, { params }).then((workShifts) =>
        workShifts.sort((a, b) => a.start_time.localeCompare(b.start_time))
      ),
    {
      enabled: !!systemId,
    }
  )
}

export type ShiftSchedule = {
  time: string
  [key: string]: string
}

export const SHIFT_SCHEDULE_QUERY_KEY = `shiftSchedule`
export function useShiftSchedule(startTime: Moment, endTime: Moment) {
  const { systemId } = useAuth()

  const params = {
    system: systemId,
    start_time: startTime.format(`YYYY-MM-DD`),
    end_time: endTime.format(`YYYY-MM-DD`),
  }
  return useQuery<ShiftSchedule[], { message: string }>(
    [SHIFT_SCHEDULE_QUERY_KEY, params],
    () => apiClient<ShiftSchedule[]>(`work_shifts/shift_schedule`, { params }),
    {
      enabled: !!systemId,
    }
  )
}
