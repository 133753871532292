import React, { ReactElement, useState } from 'react'

import {
  UnitAvailabilityPlannedStopFormData,
  useUnitAvailabilityPlannedStopDeleteMutation,
} from 'api/unitAvailabilityPlannedStop/unitAvailabilityPlannedStop.api'
import alertStore from 'store/alert/alert'
import { dynamicClassName } from 'styles/helper'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Icon, IconButton, Typography } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import PlannedStopModal from '../PlannedStopModal'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'

import styles from './PlannedStopSettingItem.module.less'

type PlannedStopSettingItemProps = {
  hasChangePermission: boolean
  event: UnitAvailabilityPlannedStopFormData
  unitName: string
  onRefetch?: () => void
}

export default function PlannedStopSettingItem({
  hasChangePermission,
  event,
  unitName,
  onRefetch,
}: PlannedStopSettingItemProps): ReactElement {
  const { t } = useTranslation()
  const { mutateAsync: deleteMutate } = useUnitAvailabilityPlannedStopDeleteMutation()

  const [open, setOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const { comment, start_time, end_time, event_type } = event
  const color = event_type === 'planned_load_reduction' ? '#2A7AB0' : '#A74165'
  const tooltipTitle =
    event_type === 'planned_load_reduction' ? t('Planned load reduction') : t('Planned unavailability')

  function onDelete(id: number): void {
    deleteMutate(id).then(
      () => {
        alertStore.success(t('Delete event success'))
        if (onRefetch) {
          onRefetch()
        }
      },
      (errorMessage) => {
        alertStore.error(`${errorMessage}`)
      }
    )
  }

  return (
    <>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={styles.PlannedStopSettingItem_Container}
      >
        <Grid item xs={6}>
          <Tooltip title={tooltipTitle} arrow placement="top">
            <Icon
              className={dynamicClassName({
                [styles.PlannedStopSettingItem_Icon]: true,
                'fas fa-circle': true,
              })}
              fontSize="inherit"
              style={{ color: color }}
            />
          </Tooltip>
          <Typography variant="h4" display="inline">
            {comment}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" display="inline">
            {formatDateTimeToLocalizedString(moment(start_time).format(), true) + ' - '}
          </Typography>
          <Typography variant="h4">{formatDateTimeToLocalizedString(moment(end_time).format(), true)}</Typography>
        </Grid>
        <Grid item container justifyContent="space-between" xs={2}>
          <IconButton
            aria-label={t('Edit unavailability')}
            data-testid="PlannedStopEdit"
            className={classNames('fal fa-edit', styles.PlannedStopSettingItem_IconButton)}
            onClick={() => setOpen(true)}
            size="large"
          />
          {hasChangePermission && (
            <>
              <IconButton
                aria-label={t('Remove saved event')}
                data-testid="PlannedStopDelete"
                className={classNames('fal fa-trash-alt', styles.PlannedStopSettingItem_IconButtonDelete)}
                onClick={() => setConfirmModalOpen(true)}
                size="large"
              />
              <ConfirmDialog
                open={confirmModalOpen}
                onConfirm={() => {
                  if (event.id) {
                    onDelete(event.id)
                  }
                }}
                onClose={() => setConfirmModalOpen(false)}
                textObject={{
                  title: t('Remove saved event'),
                  text: t('Are you sure you would like to remove this event? This cannot be undone.'),
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <PlannedStopModal
        hasChangePermission={hasChangePermission}
        open={open}
        onClose={setOpen}
        edit
        event={event}
        unitName={unitName}
        onRefetch={onRefetch}
      />
    </>
  )
}
