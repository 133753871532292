import React, { ReactElement, useCallback, useMemo } from 'react'

import { DigitalTwinSetting } from 'api/digitalTwin/digitalTwin.api'
import {
  DeclinedHours,
  DigitalTwinHeatSupplier,
  HEAT_SUPPLIERS_DATA_QUERY_KEY,
  useDeclinedHours,
} from 'api/heatSuppliers/heatSuppliers.api'
import { samenergiStore } from 'store/samenergi/samenergi'
import Datetime from 'utils/datetime/datetime'
import { getSettingValue } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { CircularProgress, Grid } from '@mui/material'
import moment, { Moment } from 'moment'

import useDigitalTwinHeatSupplierSettings from '../hooks/useDigitalTwinHeatSupplierSettings'
import { queryClient } from 'helpers/queryClient'
import { getAvailableHoursFromSettings } from 'helpers/samEnergi.helper/samEnergi.helper'
import HourlyMetricField from 'views/SamEnergiView/components/SupplierView/components/SupplierViewTabs/components/SupplierDetails/components/HourlyMetricField/HourlyMetricField'

import { AVAILABLE_HOURS_SETTINGS_PRIO } from './DigitalTwinAvailableHoursForm/DigitalTwinAvailableHoursForm'
import DigitalTwinSamEnergiManageButtons, { AVAILABLE_HOURS_ATTRIBUTES } from './DigitalTwinSamEnergiManageButtons'

function SupplierDetailsContent({
  settings,
  declinedHours,
}: {
  declinedHours: DeclinedHours
  settings: DigitalTwinSetting[]
}): ReactElement {
  const availableHours = getAvailableHoursFromSettings(settings)
  const currentDeclinedHours = declinedHours ? declinedHours : { planned: 0, unplanned_winter: 0, unplanned_summer: 0 }

  const now = Datetime.getISONow()

  const plannedRemaining = (getSettingValue(now, availableHours.plannedStop) ?? 0) - currentDeclinedHours.planned
  const unplannedWinterRemaining =
    (getSettingValue(now, availableHours.unplannedStopWinter) ?? 0) - currentDeclinedHours.unplanned_winter
  const unplannedSummerRemaining =
    (getSettingValue(now, availableHours.unplannedStopSummer) ?? 0) - currentDeclinedHours.unplanned_summer

  samenergiStore.plannedRemaining = plannedRemaining
  samenergiStore.unplannedSummerRemaining = unplannedSummerRemaining
  samenergiStore.unplannedWinterRemaining = unplannedWinterRemaining

  return (
    <HourlyMetricField
      remainingHours={{
        plannedRemaining: plannedRemaining,
        unplannedWinterRemaining: unplannedWinterRemaining,
        unplannedSummerRemaining: unplannedSummerRemaining,
      }}
    />
  )
}

type DigitalTwinHeatSupplierDetailsProps = {
  heatSupplier: DigitalTwinHeatSupplier
  selectedDate: Moment
  refetchSelectedDateSettings: () => void
}

export default function DigitalTwinHeatSupplierDetails({
  heatSupplier,
  selectedDate,
  refetchSelectedDateSettings,
}: DigitalTwinHeatSupplierDetailsProps): ReactElement {
  const { year, startOfYear, endOfYear } = useMemo(() => {
    const year = moment(selectedDate).year()
    return {
      year,
      startOfYear: Datetime.toISOString(moment().year(year).startOf(`year`)),
      endOfYear: Datetime.toISOString(moment().year(year).endOf(`year`)),
    }
  }, [selectedDate])

  const availabileHoursPriorities = useMemo(
    () => [{ priority_level: AVAILABLE_HOURS_SETTINGS_PRIO, display_name: 'Available hours prio' }],
    []
  )

  const {
    fetchSettingsStatus,
    settings: heatSupplierAvailableHoursSettings,
    fetchSettings: refetchAvailableHoursSettings,
  } = useDigitalTwinHeatSupplierSettings({
    activeFrom: startOfYear,
    activeTo: endOfYear,
    attributes: AVAILABLE_HOURS_ATTRIBUTES,
    heatSupplier,
    priorities: availabileHoursPriorities,
  })

  const { data: declinedHours, status: declinedHoursStatus } = useDeclinedHours(year, heatSupplier.id, true)

  const refreshData = useCallback(() => {
    queryClient.invalidateQueries(HEAT_SUPPLIERS_DATA_QUERY_KEY)
    refetchAvailableHoursSettings()
    refetchSelectedDateSettings()
  }, [refetchAvailableHoursSettings, refetchSelectedDateSettings])

  return (
    <Grid container direction="row" spacing={3} style={{ padding: 20 }} justifyContent="center">
      <DigitalTwinSamEnergiManageButtons
        heatSupplier={heatSupplier}
        selectedDate={selectedDate}
        onDataChanged={refreshData}
      />

      <Grid item>
        {fetchSettingsStatus === 'loading' || declinedHoursStatus === 'loading' || declinedHours === undefined ? (
          <CircularProgress />
        ) : (
          <SupplierDetailsContent declinedHours={declinedHours} settings={heatSupplierAvailableHoursSettings} />
        )}
      </Grid>
    </Grid>
  )
}
