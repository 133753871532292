import React, { useState, ReactElement } from 'react'

import { Comment, isComment, Thread, useBulletinBoardDeleteMutation } from 'api/bulletinBoard/bulletinBoard.api'
import { useUser } from 'api/users/users.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Icon, Typography, IconButton } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import Like from '../components/Like'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'
import { getBulletinDisplayUserName, prettyPrintAnySettingValue } from 'helpers/global.helper/global.helper'
import { formatObjectPropertySettingName } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'

import styles from './GeneralPost.module.less'
import PostTitle from './components/PostTitle/PostTitle'

type GeneralPostProps = {
  post: Comment | Thread
}

function GeneralPost({ post }: GeneralPostProps): ReactElement {
  const { t } = useTranslation()

  const { mutate: bulletinBoardDelete } = useBulletinBoardDeleteMutation()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const currentSetting = (post as Thread).bulletin_setting
  const currentProperty = (post as Thread).object_property
  const { data: user } = useUser()
  return (
    <>
      {(!currentSetting || (currentSetting && currentProperty)) && (
        <>
          <Grid container direction="column">
            <Grid container direction="row">
              <Grid
                container
                item
                direction="row"
                className={styles.GeneralPost_ThreadInfo}
                justifyContent={`flex-end`}
              >
                <Grid item xs={11}>
                  {post.has_read ? null : (
                    <Icon className={classNames(`fas fa-circle`, styles.GeneralPost_HaveSeenCircle)} />
                  )}
                  <Icon className={classNames(`fal fa-comment-alt`, styles.GeneralPost_ThreadIcon)} />
                  <Typography className={styles.GeneralPost_TimeStampHeader} display="inline">
                    {moment(post.updated_at).isSame(moment(), `day`) ? (
                      <>
                        {t(`Today`)} {moment(post.updated_at).format(` [@] HH:mm:ss`)}
                      </>
                    ) : (
                      moment(post.updated_at).format(`dddd D MMMM [@] HH:mm:ss`)
                    )}
                  </Typography>
                </Grid>
                <Grid item container xs={1} alignItems={`flex-end`} justifyContent={`flex-end`}>
                  {post.created_by === user?.id ? (
                    <Tooltip title={t(`Delete`)} arrow placement="top">
                      <IconButton
                        className={styles.GeneralPost_DeleteIcon}
                        onClick={() => setConfirmDeleteModalOpen(true)}
                        size="large"
                      >
                        <Icon className="fal fa-trash-alt" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid alignItems="flex-start" justifyContent="center" container direction="row">
              <Grid item xs={11}>
                <PostTitle
                  settingPost={currentSetting !== null && currentSetting !== undefined}
                  title={getBulletinDisplayUserName(post.creator_full_name)}
                />
                <Grid item xs={11}>
                  {currentSetting && currentProperty ? (
                    <Grid item container direction="column" spacing={2}>
                      <Grid item>
                        <Typography variant="body1" display="inline">
                          {t(`{{name}} is active with the value `, {
                            name: formatObjectPropertySettingName(currentProperty),
                          })}
                        </Typography>
                        <Typography variant="body1" display="inline" style={{ fontWeight: `bold` }}>
                          {prettyPrintAnySettingValue(currentProperty, currentSetting) + ` `}
                        </Typography>

                        {currentSetting.comment && (
                          <>
                            <Typography variant="body1" display="inline">
                              {t(`with reason: `)}
                            </Typography>
                            <Typography variant="body1" display="inline" style={{ fontWeight: `bold` }}>
                              {currentSetting.comment}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" display="inline">
                          {t(`The setting is valid from`)}
                        </Typography>
                        <Typography variant="body1" display="inline" style={{ fontWeight: `bold` }}>
                          {t(` {{start}} - {{end}}`, {
                            start: formatDateTimeToLocalizedString(moment(currentSetting.start_time).format(), true),
                            end: currentSetting.end_time
                              ? formatDateTimeToLocalizedString(moment(currentSetting.end_time).format(), true)
                              : t(`Until further notice`),
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body1" style={{ whiteSpace: `pre-line`, paddingTop: `2px` }}>
                      {post.content}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{isComment(post) ? <Like post={post} /> : null}</Grid>
          <ConfirmDialog
            open={confirmDeleteModalOpen}
            onConfirm={() => {
              bulletinBoardDelete({
                id: post.id,
                type: !isComment(post) ? `bulletin_board_threads` : `bulletin_board_comments`,
              })
            }}
            onClose={() => setConfirmDeleteModalOpen(false)}
            textObject={{
              title: t(`Remove post`),
              text: t(`Are you sure you want to remove your post?`),
            }}
          />
        </>
      )}
    </>
  )
}

export default GeneralPost
