import React, { PropsWithChildren, ReactElement } from 'react'

import { Grid, Hidden } from '@mui/material'

import MobileTabs from './MobileTabs/MobileTabs'
import RegularTabs, { TabObject } from './RegularTabs'
import styles from './Tabs.module.less'


type TabsProps = {
  handleChange: (event: React.ChangeEvent<unknown>, newValue: number) => void
  tabObjects: TabObject[]
  value: number
}

export default function Tabs({ children, ...props }: PropsWithChildren<TabsProps>): ReactElement {
  return (
    <Grid container direction="column">
      <Grid item>
        <Hidden mdDown>
          <RegularTabs {...props} />
        </Hidden>
        <Hidden mdUp>
          <MobileTabs {...props} />
        </Hidden>
      </Grid>
      <Grid item className={styles.Tabs_Border}>
        {children}
      </Grid>
    </Grid>
  )
}
