import React, { ReactElement } from 'react'

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './PostTitle.module.less'

type PostTitleProps = {
  settingPost?: boolean
  title: string | null
}

export default function PostTitle({ settingPost, title }: PostTitleProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Typography variant="h4" className={styles.PostTitle_Title}>
      {settingPost ? t(`Optimization setting`) : title ?? t(`Anonymous`)}
    </Typography>
  )
}
