import React, { useState, ReactElement, useMemo, useEffect } from 'react'

import { OBJECT_PROPERTIES_QUERY_KEY , useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { SETTINGS_QUERY_KEY } from 'api/settings/settings.api'
import { useUnits } from 'api/units/units.api'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import SettingsModal from 'ui/components/Modals/SettingsModal'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import SettingsTable, { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'
import Datetime from 'utils/datetime/datetime'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import { Grid } from '@mui/material'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'
import { useQueryClient } from 'react-query'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getDateTimePickerRangesFromPermissions } from 'helpers/settingsModal.helper/settingsModal.helper'

import { SettingsCalendarItem, unitsToCalendarItems } from './Components/SettingsCalendarHelper'
import SettingsCalendarView from './Components/SettingsCalendarView'

type month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const widgetConstants = {
  OPTIMIZE_SETTINGS_CALENDAR: `optimize-settings-calendar`,
  OPTIMIZE_SETTINGS_TABLE: `optimize-settings-table`,
}

const OptimizeSettingHeaderContentClass = `optSettingsHeader`

const calendarInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_SETTINGS_CALENDAR,
    content: `What deviations are planned? Which deviations has there been historically? The deviation settings calendar gives you the answer! \n\nSorted at unit level, you can easily create deviation settings and also get a good overview of your production planning. Click and hold the left mouse button over any unit and period to create a new deviation setting. \n\nNote that deviation settings affect the production plan for the entire system!`,
    title: `Deviation settings calendar`,
  },
]
const tableInfo: Array<Step> = [
  {
    target: widgetConstants.OPTIMIZE_SETTINGS_TABLE,
    content: `Here you can find all active properties for which you can create deviation settings for. \n\nIf 'Status' shows an orange icon '!', this means that some active deviation for a specific property is currently affecting the production plan for some of the current production plan hours. \n\nClick on 'Show/edit' to create, edit and delete current deviation settings.`,
    title: `Table showing all deviation settings`,
  },
]

const steps: Array<Step> = [...calendarInfo, ...tableInfo]

export default function OptimizeSettingsView(): ReactElement {
  const { t } = useTranslation()

  const startTime = Datetime.getStartOfDay(Datetime.getISONow(0))
  const endTime = Datetime.getEndOfDay(Datetime.getISONow(168))

  const [runTour, setRunTour] = useState<null | number>(null)
  const { systemId } = useAuth()

  const permissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_plan_settings`),
    canEditHistoric: useHasPermission(`change_historic_plan_settings`),
  }
  const startEndTimeRange = getDateTimePickerRangesFromPermissions(permissions.canEditHistoric)

  const queryClient = useQueryClient()

  const channel = useChannel(systemId ? `aurora-django-setting-${systemId}` : undefined)

  useEvent(channel, `settings-changed`, () => {
    queryClient.invalidateQueries(SETTINGS_QUERY_KEY)
    queryClient.invalidateQueries(OBJECT_PROPERTIES_QUERY_KEY)
  })
  // NOTE: Moved up from  SettingsCalendarView.tsx
  const [currentCalendarView, setCurrentCalendarView] = useState<`productionPlan` | `month` | `week`>('productionPlan')
  const [currentPeriod, setCurrentPeriod] = useState<{ startTime: ISODateTime; endTime: ISODateTime }>({
    startTime: startTime,
    endTime: endTime,
  })

  useEffect(() => {
    if (currentCalendarView == 'productionPlan') {
      setCurrentPeriod({
        startTime: Datetime.getStartOfDay(Datetime.getISONow(0)),
        endTime: Datetime.getEndOfDay(Datetime.getISONow(168)),
      })
    }
    if (currentCalendarView == 'month') {
      const currentYear = new Date(Datetime.toLocalTime(currentPeriod.startTime)).getFullYear()
      const currentMonth = (new Date(Datetime.toLocalTime(currentPeriod.startTime)).getMonth() + 1) as month
      setCurrentPeriod({
        startTime: Datetime.getStartTimeEndTimeOfMonth(currentYear, currentMonth).startTime,
        endTime: Datetime.getStartTimeEndTimeOfMonth(currentYear, currentMonth).endTime,
      })
    }
    if (currentCalendarView == 'week') {
      setCurrentPeriod({
        startTime: Datetime.getStartTimeEndTimeOfWeek().startTime,
        endTime: Datetime.getStartTimeEndTimeOfWeek().endTime,
      })
    }
  }, [currentPeriod.startTime, currentCalendarView])

  function updateCurrentPeriod(date: Moment): void {
    if (date) {
      const ISODate = Datetime.toISOString(date)
      setCurrentPeriod(Datetime.getStartTimeEndTimeOfMonthByDate(ISODate))
    }
  }

  const { data: objectProperties } = useObjectProperties({
    classification: `deviation`,
    start_time: currentPeriod.startTime,
    end_time: currentPeriod.endTime,
  })
  const { data: units } = useUnits()

  const items = useMemo(() => {
    let combinedUnitAndSettings: SettingsCalendarItem[] = []
    if (objectProperties && units) {
      const filteredUnits = units?.filter((unit) => unit.visible_in_calendar)
      filteredUnits.sort((a, b) => a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase()))
      combinedUnitAndSettings = unitsToCalendarItems(objectProperties, filteredUnits)
    }
    return combinedUnitAndSettings
  }, [objectProperties, units])

  const closeModal = (): void => {
    setShowSettingsModal(false)
  }

  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const classification = 'deviation'

  return (
    <View
      header={
        <div className={OptimizeSettingHeaderContentClass}>
          <ModuleHeader
            infoHeader={t(`Deviation settings`)}
            onInfoButtonClick={() => {
              setRunTour(0)
            }}
          >
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                icon="fal fa-plus"
                primary
                onClick={() => {
                  setShowSettingsModal(true)
                }}
              >
                {t(`Create new setting`)}
              </Button>
            </Grid>
          </ModuleHeader>
        </div>
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? calendarInfo : runTour === 2 ? tableInfo : steps}
      />

      <SettingsModal
        closeModal={closeModal}
        open={showSettingsModal}
        newDeviationForm={true}
        permissions={permissions}
        startEndTimeRange={startEndTimeRange}
        classification={classification}
      />

      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.OPTIMIZE_SETTINGS_CALENDAR}
          title={t(`Deviation settings - calendar`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <SettingsCalendarView
            items={items}
            currentCalendarView={currentCalendarView}
            currentPeriod={currentPeriod}
            setCurrentCalendarView={setCurrentCalendarView}
            permissions={permissions}
            startEndTimeRange={startEndTimeRange}
            updateCurrentPeriod={updateCurrentPeriod}
          />
        </CardWidget>
      </Grid>

      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.OPTIMIZE_SETTINGS_TABLE}
          title={t(`Deviation settings - table`)}
          handleInfoIconClick={() => {
            setRunTour(2)
          }}
        >
          <SettingsTable
            permissions={permissions}
            startEndTimeRange={startEndTimeRange}
            classification={classification}
            tableOptions={{
              initialState: { hiddenColumns: [`category`] },
            }}
          />
        </CardWidget>
      </Grid>
    </View>
  )
}
