import React, { ReactElement, useState } from 'react'

import { useTable } from 'ui/components/CustomTable/CustomTable'

import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAsyncDebounce } from 'react-table'

export default function GlobalFilter(): ReactElement {
  const { t } = useTranslation()

  const { preGlobalFilteredRows, globalFilter, setGlobalFilter } = useTable()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      label={t(`Search`)}
      aria-label={t(`Search`)}
      variant="outlined"
      value={value || ``}
      fullWidth
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={`${count} ${t(`rows`)}...`}
      style={{
        fontSize: `1.1rem`,
        border: `0`,
      }}
    />
  )
}
