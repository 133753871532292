import React, { ReactElement, ReactNode } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import UiConfigItemTable from 'ui/molecules/UiConfigItemTable/UiConfigItemTable'
import UiConfigTagsConfigTable from 'ui/molecules/UiConfigTagsConfigTable/UiConfigTagsConfigTable'
import Chart from 'ui/uiConfig/components/Chart/Chart'
import { DEFAULT_PROD_PLAN_CONFIG } from 'ui/uiConfig/components/Chart/chart.constants'
import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'
import { registerUiConfigComponent, registerAppendPropsWithDatasets, registerDefaultProps } from 'ui/uiConfig/factory'

import { useSnapshot } from 'valtio'

import {
  getReturnIdsFromDatasetInstruction,
  getReturnIdsFromDatasetsInstruction,
} from 'helpers/dataset.helper/dataset.helper'
import { isArray } from 'helpers/global.helper/global.helper'

import styles from './BaseConfig.module.less'

type BaseConfigProps = {
  datasets: Dataset[]
  children: ReactNode[]
  dataset_instructions: DatasetInstruction[]
  alias: UiConfigAliases
  tags_config?: Record<string, TagsConfig>
  uid: number
  items: ChartItem[]
}

const defaultProps: Omit<BaseConfigProps, `datasets` | `children`> = {
  dataset_instructions: [],
  alias: {
    meas_temperature_tag: `calc_temperature`,
    meas_heatload_tag: `calc_heatload`,
    meas_locality: `meas_{ORG_SYSTEM}`,
    electricity_price_forecast_model: `forecast_lin_reg_median_no_const_err_corr`,
    electricity_price_locality: `forecast_electricity_price_se{BIDDING_AREA}`,
    weather_forecast_locality: `forecast_weather_{ORG_SYSTEM}`,
    heatload_forecast_locality: `forecast_heat_{ORG_SYSTEM}`,
    demand_heatload: 'demands.heat.heat',
  },
  items: [
    {
      fill: false,
      unit: ``,
      color: `#000000`,
      order: 0,
      title: `__Heat demand`,
      data_id: `$demand_heatload`,
      decimals: 1,
      tooltip_do_not_show_zero_values: false,
      data_type: `power`,
      y_axis_id: `y`,
      stack_group: undefined,
    },
  ],
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: BaseConfigProps
): BaseConfigProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    fill: true,
    unit: ``,
    color: `#000000`,
    order: 0,
    title: returnId.split(`.`).pop(),
    data_id: returnId,
    tooltip_do_not_show_zero_values: true,
    decimals: 1,
    data_type: `power`,
    y_axis_id: `y`,
    stack_group: 1,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
  }
}

registerUiConfigComponent(`base_config`, BaseConfig, { isSingletonComponent: true })
registerAppendPropsWithDatasets(`base_config`, appendPropsWithDataset)
registerDefaultProps(`base_config`, defaultProps)

export default function BaseConfig({
  items,
  dataset_instructions,
  alias,
  tags_config,
  uid,
  datasets,
  ...rest
}: BaseConfigProps): ReactElement {
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const returnIds = getReturnIdsFromDatasetsInstruction(isArray(dataset_instructions) ? dataset_instructions : [])
  const itemsWithAliases: ChartItem[] = uiConfigSnap.getUnparsedUiConfig(uid)?.props?.items || []

  return (
    <div className={styles.BaseConfig}>
      <h2>Items</h2>
      {!!items?.length && (
        <Chart items={items || []} datasets={datasets} uid={uid} config={DEFAULT_PROD_PLAN_CONFIG} {...rest} />
      )}
      <UiConfigItemTable items={itemsWithAliases} uid={uid} />

      {returnIds?.length ? (
        <>
          <h2>Dataset Instructions Templates</h2>
          {returnIds.map((returnId, index) => (
            <div key={`${index}_${returnId}`}>{returnId}</div>
          ))}
        </>
      ) : null}

      <h2>Alias</h2>
      <table>
        <thead>
          <tr>
            <th>{`Key`}</th>
            <th>{`Alias`}</th>
          </tr>
        </thead>

        <tbody>
          {Object.entries(alias || {}).map(([key, alias]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{alias}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {tags_config !== undefined && Object.entries(tags_config).length > 0 && (
        <>
          <h2>Tags Configuration</h2> <p>Config elements specified here is applied to grouped items - not in filtering</p>
          <UiConfigTagsConfigTable tags_config={tags_config} uid={uid}/>
        </>
      )}
    </div>
  )
}
