import React, { ReactElement, useMemo, useState } from 'react'

import {
  Classification,
  ObjectProperty,
  useObjectProperties,
  useObjectProperty,
} from 'api/objectProperties/objectProperties.api'
import { SettingAlertTexts } from 'hooks/apiHooks/apiHooks'
import { useSettingsModalTitle } from 'hooks/utilsHooks/utilsHooks'
import { Button } from 'ui/atoms'
import { TableProvider, Table, TableHead, TableBody, TableFooter } from 'ui/components/CustomTable/CustomTable'
import GlobalFilter from 'ui/components/CustomTable/components/GlobalFilter/GlobalFilter'
import SettingsStatusField from 'ui/components/CustomTable/components/SettingsStatusField/SettingsStatusField'
import ObjectPropertyFormSelector from 'ui/components/ObjectPropertyFormSelector/ObjectPropertyFormSelector'
import SettingsModal from 'ui/components/SettingsModal/SettingsModal'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Row, TableOptions } from 'react-table'

import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'
import { getDisplayName } from 'helpers/global.helper/global.helper'
import { getOptimizationPeriod, getTranslatedCategoryDisplayName } from 'helpers/optimize.helper/optimize.helper'
import { prettyPrintCurrentSettingValue } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { DateTimePickerRange } from 'helpers/settingsModal.helper/settingsModal.helper'

type TimeFieldProps = {
  value: string | null
}

function TimeField({ value }: TimeFieldProps): ReactElement {
  return <>{value ? formatDateTimeToLocalizedString(value, true) : `-`}</>
}

export type SettingsPermissions = {
  canEdit: boolean,
  canEditHistoric: boolean
}


type RowType = Row<Record<string, unknown>>
export const baseSettingTypes: Classification[] = [`base`, `price`]

type SettingsTableContentProps = {
  period: Period
  alertTexts?: SettingAlertTexts
  startEndTimeRange?: DateTimePickerRange
  classification: Classification
  objectProperties: ObjectProperty[]
  tableOptions?: Partial<TableOptions<Record<string, unknown>>>
  permissions: SettingsPermissions
}

function SettingsTableContent({
  period,
  alertTexts,
  startEndTimeRange,
  classification,
  objectProperties,
  tableOptions,
  permissions,
}: SettingsTableContentProps): ReactElement {
  const { t } = useTranslation()

  const [activePropertyId, setActivePropertyId] = useState<number | undefined>(undefined)

  const baseTableOptions = useMemo(
    () => ({
      ...tableOptions,
      autoResetGlobalFilter: false,
      initialState: {
        sortBy: [{ id: `status`, desc: false }, { id: `group` }],
        ...tableOptions?.initialState,
        hiddenColumns: [`id`, ...(tableOptions?.initialState?.hiddenColumns ?? [])],
      },
    }),
    [tableOptions]
  )

  const columns = useMemo(
    () => [
      { Header: `Id`, accessor: `id` },
      {
        Header: t(`Category`),
        accessor: `category`,
      },
      {
        Header: t(`Group`),
        accessor: `group`,
      },
      { Header: t(`Property`), accessor: `property` },
      {
        Header: t(`Status`),
        accessor: `status`,
        Cell: SettingsStatusField,
        sortType: (rowA: RowType, rowB: RowType, id: string, _desc: boolean | undefined) => {
          if (rowA.original[id] != null && rowB.original[id] == null) return -1
          if (rowB.original[id] != null && rowA.original[id] == null) return 1
          return 0
        },
      },
      { Header: t(`Current value`),
        accessor: `currentValue`,
        Cell: (row: RowType) => {
          return <>{row.value}</>
        },
      },
      {
        Header: t(`Unit `),
        accessor: `unit`,
      },
      {
        Header: t(`Last updated`),
        accessor: `updatedAt`,
        Cell: TimeField,
      },
      { Header: t(`Edit`), accessor: `edit`, disableSortBy: true },
    ],
    [t]
  )

  const buttonTitle = permissions.canEdit ? t(`Show/edit`) : t(`Show`)
  const data = useMemo(
    () =>
      objectProperties.map((property) => {
        const propertyGroup = getDisplayName(property.parent as DisplayNameObject)
        const propertyName = getDisplayName(property)
        const buttonTooltip = permissions.canEdit ? `${t(`Show/edit`)} ${propertyGroup} ${propertyName}` : `${t(`Show`)} ${propertyGroup} ${propertyName}`
        const firstActiveSetting = property.active_settings?.sort((a, b) => (
          Datetime.compare(a.start_time as ISODateTime, b.start_time as ISODateTime)
        ))[0]
        return {
          category: getTranslatedCategoryDisplayName(property.category),
          group: propertyGroup,
          property: propertyName,
          status: property.active_settings?.[0] ?? null,
          currentValue:
            classification && baseSettingTypes.includes(classification)
              ? property.current_base_value ?? property.default_value
              : prettyPrintCurrentSettingValue({
                startTime: firstActiveSetting?.start_time as ISODateTime,
                endTime: firstActiveSetting?.end_time as ISODateTime,
                activeValue: property.current_active_value,
                defaultValue: property.default_value,
                periodStartTime: period.startTime,
                periodEndTime: period.endTime,
                attribute: property.name,
                baseValue: property.current_base_value,
              }),
          unit: property.measurement_unit !== null ? t(property.measurement_unit) : `-`,
          updatedAt:
            classification && baseSettingTypes.includes(classification)
              ? property.latest_updated_base
              : property.latest_updated,
          edit: (
            <Button
              primary
              onClick={(): void => {
                setActivePropertyId(property.id)
              }}
              tooltip={buttonTooltip}
            >
              {buttonTitle}

            </Button>
          ),
        }
      }),
    [buttonTitle, classification, objectProperties, period.startTime, period.endTime, permissions.canEdit, t]
  )
  const { data: objectProperty, status } = useObjectProperty({
    id: activePropertyId,
    start_time: period.startTime.format(),
    end_time: period.endTime.format(),
  })
  const title = useSettingsModalTitle({ objectProperty })


  return (
    <>
      <TableProvider columns={columns} data={data} tableOptions={baseTableOptions}>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid item xs={4}>
              <GlobalFilter />
            </Grid>
          </Grid>
          <Table>
            <TableHead />
            <TableBody />
            <TableFooter />
          </Table>
        </Grid>
      </TableProvider>
      <SettingsModal
        closeModal={() => setActivePropertyId(undefined)}
        open={activePropertyId != null}
        title={title}
        form={
          <StatusComponent
            data={{ objectProperty }}
            status={status}
            Component={ObjectPropertyFormSelector}
            ComponentProps={{
              ObjectPropertySettingFormProps: {
                alertTexts,
                startEndTimeRange,
                isBaseValueSetting: classification ? baseSettingTypes.includes(classification) : false,
                showCreateButton: true,
                showHistory: true,
                permissions: permissions,
              },
              AvailableHoursFormProps: {
                onFormFinished: () => setActivePropertyId(undefined),
                hasEditPermission: permissions.canEdit,
              },
            }}
          />
        }
      />
    </>
  )
}

type SettingsTableProps = {
  classification: Classification
  alertTexts?: SettingAlertTexts
  startEndTimeRange?: DateTimePickerRange
  optModel?: number
  period?: Period
  tableOptions?: Partial<TableOptions<Record<string, unknown>>>
  permissions: SettingsPermissions,
}

export default function SettingsTable({
  optModel,
  period = getOptimizationPeriod(),
  classification,
  permissions,
  ...rest
}: SettingsTableProps): ReactElement {
  const { startTime, endTime } = period
  const { data: objectProperties, status } = useObjectProperties({
    optModel,
    classification,
    start_time: startTime.format(),
    end_time: endTime.format(),
  })

  return (
    <StatusComponent
      data={{ objectProperties }}
      status={status}
      Component={SettingsTableContent}
      ComponentProps={{ optModel, period, classification, permissions, ...rest }}
    />
  )
}
