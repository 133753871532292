import React, { ReactElement } from 'react'

import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { formatPossibleNullTimestamp } from 'helpers/dateTime.helper/dateTime.helper'

type ActiveSettingTextProps = {
  startTime?: Moment | string | null
  endTime?: Moment | string | null
}

function ActiveSettingText({ startTime, endTime }: ActiveSettingTextProps): ReactElement {
  const { t } = useTranslation()

  let end_time
  let start_time

  if (startTime?.toString().includes(moment().year().toString())) {
    start_time = moment(startTime).format(`ddd D MMM HH:mm`)
  } else {
    start_time = moment(startTime).format(`ddd D MMM YYYY HH:mm`)
  }

  if (endTime?.toString().includes(moment().year().toString())) {
    end_time =  formatPossibleNullTimestamp(endTime, `ddd D MMM HH:mm`, t(`Until further notice`))
  } else {
    end_time = formatPossibleNullTimestamp(endTime ?? null, `ddd D MMM YYYY HH:mm`, t(`Until further notice`))
  }
  return (
    <>
      {t(`Affects optimization period between {{startTime}} - {{endTime}}`, {
        startTime: start_time,
        endTime: end_time,
      })}
    </>
  )
}

export default ActiveSettingText
