/**
 * @file commonTranslations.ts
 * @description This module contains a mapping of property keys to their corresponding human-readable translations.
 *
 * @module commonTranslations
 */

const balance: { [key: string]: string } = {
  export_tariff: 'Export tariff',
  import_tariff: 'Import tariff',
}

const commodities: { [key: string]: string } = {
  biomass: 'Biomass',
  el: 'Electricity',
  fuel_price: 'Fuel price',
  gas: 'Gas',
  grot: 'Grot',
  oil: 'Oil',
  pellets: 'Pellets',
  rt: 'Waste wood',
  waste: 'Waste',
  woodchips: 'Woodchips',
}

const elProperties: { [key: string]: string } = {
  elcert: 'Electricity certificate',
  elcert_prod_ratio: 'Electricity certificate production ratio',
  elcert_ratio: 'Electricity certificate ratio',
  eltax: 'Electricity tax',
  eltax_industry: 'Industry electricity tax',
  industry_consumption_ratio: 'Industry consumption ratio',
  load_change_costs_are_fictive: 'Load change costs are fictive',
  costs_are_fictive: 'Costs are fictive',
}

const exchanges: { [key: string]: string } = {
  availability_incoming: 'Availability incoming',
  availability_outgoing: 'Availability outgoing',
  forced_incoming: 'Forced incoming',
  forced_min_transfer_incoming: 'Forced min transfer incoming',
  forced_min_transfer_outgoing: 'Forced min transfer outgoing',
  forced_outgoing: 'Forced outgoing',
  forced_transfer_incoming: 'Forced transfer incoming',
  forced_transfer_outgoing: 'Forced transfer outgoing',
  load_change_cost_incoming: 'Load change cost incoming',
  load_change_cost_outgoing: 'Load change cost outgoing',
  load_change_rate_incoming: 'Load change rate incoming',
  load_change_rate_outgoing: 'Load change rate outgoing',
  max_transfer_incoming: 'Max. transfer incoming',
  max_transfer_outgoing: 'Max. transfer outgoing',
  min_transfer_incoming: 'Min. transfer incoming',
  min_transfer_outgoing: 'Min. transfer outgoing',
  min_uptime_incoming: 'Min. uptime incoming',
  min_uptime_outgoing: 'Min. uptime outgoing',
  variable_loss_incoming: 'Variable loss incoming',
  variable_loss_outgoing: 'Variable loss outgoing',
}

const tags: { [key: string]: string } = {
  property_attribute: 'Property',
  unit_type: 'Unit type',
  node: 'Node',
  company: 'Company',
  site: 'Site',
  fuel: 'Fuel',
  property_category: 'Category',
  property_category_others: 'Others',
}

const standardUnits: { [key: string]: string } = {
  forced: 'Forced',
  forced_min: 'Forced min',
  min_downtime: 'Min. downtime',
  min_uptime: 'Min. uptime',
}

const unitProperties: { [key: string]: string } = {
  aux_consumption: 'Auxiliary consumption',
  aux_semifixed: 'Auxiliary semifixed',
  aux_variable: 'Auxiliary variable',
  consumption: 'Consumption',
  cost: 'Cost',
  negative_cost: 'Negative cost',
  oam_variable: 'Operation and maintenance variable',
  opt_cost: 'Optimization cost',
  production: 'Production',
  ratio: 'Ratio',
  temperature_demand: 'Temperature demand',
}

const otherProperties: { [key: string]: string } = {
  availability: 'Unavailability',
  efficiency: 'Efficiency',
  end_level: 'End level',
  forced_acticity: 'Forced activity',
  max_el: 'Max. electricity production',
  max_heat: 'Max. heat production',
  max_inflow: 'Max. inflow',
  max_level: 'Max. level',
  max_outflow: 'Max. outflow',
  max_output: 'Max. output',
  max_thermal: 'Max. thermal effect',
  min_el: 'Min. electricity production',
  min_heat: 'Min. heat production',
  min_inflow: 'Min. inflow',
  min_level: 'Min. level',
  min_outflow: 'Min. outflow',
  min_output: 'Min. output',
  min_thermal: 'Min. thermal effect',
  property_attribute: 'Property',
  ramp_hours_start: 'Ramp hours start',
  ramp_hours_stop: 'Ramp hours stop',
  run_cost: 'Run cost',
  start_cost: 'Start cost',
  start_level: 'Start level',
  load_change_cost: 'Load change cost',
  load_change_rate: 'Load change rate',
}

const other: { [key: string]: string } = {
  add_to_demand: 'Add to demand',
  heat_pump: 'Heat pump',
  heat: 'Heat',
  limit: 'Limit',
  price: 'Price',
}

export function keyToSourceFromCommonDicts(key: string): string {
  const dictionaries = [
    balance,
    commodities,
    elProperties,
    exchanges,
    other,
    otherProperties,
    standardUnits,
    tags,
    unitProperties,
  ]
  for (const dictionary of dictionaries) {
    if (dictionary[key]) {
      return dictionary[key]
    }
  }
  return key
}
