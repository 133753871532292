import React, { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import styles from './SubtypeOptJobInfoBox.module.less'

type SubtypeOptJobInfoBoxProps = {
  subtypeName: string
}

export default function SubtypeOptJobInfoBox({subtypeName}: SubtypeOptJobInfoBoxProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles.SubtypeOptJobInfoBox}>
      {t('Last optimized for {{subtype}}', { subtype: subtypeName })}
    </div>
  )
}
