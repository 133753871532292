import React, { ReactElement } from 'react'

import { Icon } from '@mui/material'
import classNames from 'classnames'

type FaIconProps = {
  name: string
}

export default function FaIcon({ name }: FaIconProps): ReactElement {
  return <Icon className={classNames(name)} fontSize="small" style={{ textAlign: 'center' }} />
}
