import React, { ReactElement } from 'react'

import 'ui/uiConfig/components/index'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import authStore from 'store/auth/auth'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'

import { Moment } from 'moment'
import { ErrorBoundary } from 'react-error-boundary'
import { useSnapshot } from 'valtio'

type UiConfigProps = {
  type: string
  systemId?: number
  noWrappingDiv?: boolean
  datasetStartTime?: Moment | ISODateTime
  datasetEndTime?: Moment | ISODateTime
  fallback?: ReactElement
  tourId?: string
  ignoreZoom?: boolean
  overrideAlias?: UiConfigAliases
  datasetRefreshToken?: string
  datasetCreatedAt?: string
  datasetCreatedAtOperator?: DatasetCreatedAtOperators
}

export default function UiConfig({
  type,
  systemId,
  datasetStartTime,
  datasetEndTime,
  fallback,
  noWrappingDiv,
  tourId,
  ignoreZoom,
  overrideAlias,
  datasetRefreshToken,
  datasetCreatedAt,
  datasetCreatedAtOperator,
}: UiConfigProps): ReactElement | null {
  const authSnap = useSnapshot(authStore)
  const { systemId: globalSystemId } = useAuth()
  const uiConfigRes = useUiConfig(systemId || globalSystemId, type, { mode: authSnap.isBeta ? `beta` : null })
  const uiConfig = uiConfigRes?.data?.find((u) => u.component === type)

  if (!uiConfigRes?.data?.length || !uiConfig) {
    return fallback || <></>
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
      <UiConfigComponent
        uid={uiConfig.uid}
        id={uiConfig.id}
        tourId={tourId}
        ignoreZoom={ignoreZoom}
        systemId={systemId || uiConfig.system}
        noWrappingDiv={noWrappingDiv}
        datasetStartTime={datasetStartTime ? Datetime.toISOString(datasetStartTime) : undefined}
        datasetEndTime={datasetEndTime ? Datetime.toISOString(datasetEndTime) : undefined}
        datasetCreatedAt={datasetCreatedAt}
        datasetCreatedAtOperator={datasetCreatedAtOperator}
        overrideAlias={overrideAlias}
        datasetRefreshToken={datasetRefreshToken}
      />
    </ErrorBoundary>
  )
}
