import React, { useEffect, useMemo, useState } from 'react'

import { 
  ConfigurableProperty, 
  DigitalTwin, 
  DigitalTwinPropertyObject, 
  DigitalTwinSettingCreationRequest, 
} from 'api/digitalTwin/digitalTwin.api'
import Datetime from 'utils/datetime/datetime'

import { useAuth } from '../AuthContext/AuthContext'
import { UnitSettingFormData } from '../UnitSettingForm/UnitSettingForm'
import { EventPost } from 'views/EventsView/components/events.helper'

import DigitalTwinSettingDialogActions from './DigitalTwinSettingDialogActions'
import DigitalTwinSettingDialogContent, { CreateSettingData } from './DigitalTwinSettingDialogContent'

type DigitalTwinSettingDialogProps = {
  initData: null | CreateSettingData
  defaultValues?: Partial<UnitSettingFormData>
  propertyItems: DigitalTwinPropertyObject[]
  configurableProperties: ConfigurableProperty[]
  digitalTwin: DigitalTwin
  allowEmpty: boolean
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  isBaseSettings: boolean
  hideActions?: boolean
  operationalEvent?: EventPost
  hasUnitAndProperty: boolean
  onClose: () => void
  onSettingModified: () => void
}

export function DigitalTwinSettingDialog({
  hideActions,
  initData,
  configurableProperties,
  digitalTwin,
  priorities,
  baseSettingPriorities,
  isBaseSettings,
  operationalEvent,
  propertyItems,
  hasUnitAndProperty,
  onClose,
  onSettingModified,
}: DigitalTwinSettingDialogProps): React.ReactElement {
  const { hasAccess } = useAuth()
  const hasAccessToTimeseries = (
    hasAccess({ submodule: 'optimizesettings_time_series', module: 'optimizesettings' }) || 
    hasAccess({ submodule: 'optimizesettings_time_series', module: 'events' })
  )

  const [newSetting, setNewSetting] = useState<DigitalTwinSettingCreationRequest | null>(null)
  const [valueHelperText, setValueHelperText] = useState('')

  const propertySelected = !!newSetting?.attribute

  const activeConfigurableProperty: ConfigurableProperty | undefined = useMemo(() => {
    return configurableProperties.find((p) => p.name === newSetting?.name && p.attribute === newSetting?.attribute)
  }, [configurableProperties, newSetting])

  useEffect(() => {
    if (initData) {
      setNewSetting(initializeNewSetting(initData, priorities, isBaseSettings))
    } else {
      setNewSetting(null)
    }
    
    setInvalidValuesIndex([])
    setValueHelperText('')
  }, [initData, priorities, isBaseSettings])

  // save the index of the invalid values when creating time series setting
  const [invalidValuesIndex, setInvalidValuesIndex] = useState<number[]>([])

  const updateNewSetting = (data: DigitalTwinSettingCreationRequest): void => {
    // If time range has changed, add new values for the parts of the range that were not previously included.
    if (
      data.start_time &&
    data.end_time &&
    (data.start_time != newSetting?.start_time || data.end_time != newSetting?.end_time) &&
    (data.start_time != newSetting?.start_time || data.end_time != newSetting?.end_time) &&
    data.value &&
    typeof data.value === 'object'
    ) {
      data.value = getDefaultValuesWhenTimeChanged(data, data.start_time, data.end_time)

      setInvalidValuesIndex([])
      setValueHelperText('')
    }

    data.isBaseSetting = isBaseSettings
    setNewSetting(data)
  }

  if (!newSetting) {
    return <></>
  }

  return (
    <>
      <DigitalTwinSettingDialogContent
        propertyItems={propertyItems}
        digitalTwin={digitalTwin}
        allowEmpty={false}
        priorities={priorities}
        baseSettingPriorities={baseSettingPriorities}
        hasUnitAndProperty={hasUnitAndProperty}
        isBaseSettings={isBaseSettings}
        disableAll={newSetting.isTimeSeries && !hasAccessToTimeseries}
        propertySelected={propertySelected}
        invalidValuesIndex={invalidValuesIndex}
        valueHelperText={valueHelperText}
        initData={initData}
        newSetting={newSetting}
        updateNewSetting={updateNewSetting}
        configurableProperties={configurableProperties}
        activeConfigurableProperty={activeConfigurableProperty}
        operationalEvent={operationalEvent}
      />
      {!hideActions && (
        <DigitalTwinSettingDialogActions
          initData={initData}
          newSetting={newSetting}
          activeConfigurableProperty={activeConfigurableProperty}
          digitalTwin={digitalTwin}
          onClose={onClose}
          onSettingModified={onSettingModified}
          onInvalidIndexChange={(invalidValuesIndexes, valueHelperText) => {
            setInvalidValuesIndex(invalidValuesIndexes)
            setValueHelperText(valueHelperText)
          }}
        />
      )}
    </>
  )
}

function initializeNewSetting(
  setting: CreateSettingData,
  priorities: SystemSettingPriorityLevel[],
  isBaseSetting: boolean
): DigitalTwinSettingCreationRequest {
  // start or end time is undefined means we are creating a new setting, so we use default start and end.
  const isoNow = Datetime.getISONow()
  const startTime: ISODateTime | null =
    setting.start_time !== undefined
      ? setting.start_time
      : isBaseSetting || setting.isTimeSeries
        ? isoNow
        : Datetime.getStartOfDay(isoNow)
  const endTime: ISODateTime | null =
    setting.end_time !== undefined
      ? setting.end_time
      : isBaseSetting && !setting.isTimeSeries
        ? null
        : Datetime.getEndOfDay(isoNow)

  // If value is a list (setting is a time series setting), convert
  // [{id: 3, value: 5}, {id: 4, value: 2}, ...] => { time1: {id: 3, value: 5}, time2: {id: 4, value: 2}, ... }
  // Make sure the whole intervall from start_time to end_time is covered, inserting default values where necessary.
  // For regular number values, just use the value as is.
  // If the setting is forced, set the default value to 1. If it is availability, set the defualt value to 0.
  let value: number | null | { [time: ISODateTime]: { id?: number; value: number | null } }
  if (Array.isArray(setting.value) && startTime && endTime) {
    value = Datetime.getHoursBetween(startTime, endTime)
      .map((time, index) => ({ time, index }))
      .reduce(
        (
          accumulated: { [time: ISODateTime]: { id?: number; value: number | null } },
          newEntry: { time: ISODateTime; index: number }
        ) => {
          accumulated[newEntry.time] =
            setting.value && Array.isArray(setting.value)
              ? (setting.value[newEntry.index] ?? { value: null })
              : { value: null }
          return accumulated
        },
        {}
      )
  } else if (setting.value && !Array.isArray(setting.value)) {
    value = setting.value
  } else if (setting.attribute === 'forced') {
    value = 1
  } else if (setting.attribute === 'availability') {
    value = 0
  } else {
    value = 0
  }

  return {
    level: setting.level ?? 'unit',
    name: setting.name ?? '',
    attribute: setting.attribute ?? '',
    start_time: startTime,
    end_time: endTime,
    comment: setting.comment ?? '',
    priority: setting.priority ?? priorities[0].priority_level,
    value: value ?? 0,
    isTimeSeries: setting.isTimeSeries,
    unit: '',
    isBaseSetting: isBaseSetting,
    operational_event: setting.operational_event,
  }
}

function getDefaultValuesWhenTimeChanged(
  input: DigitalTwinSettingCreationRequest,
  startTime: ISODateTime,
  endTime: ISODateTime
): number | null | { [time: ISODateTime]: { id?: number; value: number | null } } {
  return Datetime.getHoursBetween(startTime, endTime)
    .map((time, index) => ({ time, index }))
    .reduce(
      (
        accumulated: { [time: ISODateTime]: { id?: number; value: number | null } },
        newEntry: { time: ISODateTime; index: number }
      ) => {
        accumulated[newEntry.time] =
          input.value && typeof input.value === 'object' && newEntry.time in input.value
            ? (input.value[newEntry.time] ?? { value: null })
            : { value: null }
        return accumulated
      },
      {}
    )
}