import React, { ReactElement, PropsWithChildren, useEffect } from 'react'

import PopoverCloseButton from 'ui/components/PopoverCloseButton/PopoverCloseButton'

import { Icon, Button, Popover, Grid } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './SettingsDropdown.module.less'

type SettingsDropdownProps = {
  noOfSettings?: number
  customButtonTitle?: string
  handleToggle?: (open: boolean) => void
}

export default function SettingsDropdown({
  noOfSettings,
  children,
  customButtonTitle,
  handleToggle,
}: PropsWithChildren<SettingsDropdownProps>): ReactElement {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (handleToggle) {
      handleToggle(open)
    }
  }, [handleToggle, open])

  return (
    <>
      <Button onClick={handleClick} className={styles.SettingsDropdown_Button} color="grey">
        <Icon className={classNames(`fas fa-info-circle`, styles.SettingsDropdown_Icon)} fontSize="inherit" />
        <div className={styles.SettingsDropdown_Text}>
          {noOfSettings
            ? t(`{{number}} active deviation settings for current optimization period`, { number: noOfSettings })
            : customButtonTitle
              ? customButtonTitle
              : ``}
        </div>
      </Button>
      { open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: `bottom`,
            horizontal: `center`,
          }}
          transformOrigin={{
            vertical: `top`,
            horizontal: `center`,
          }}
        >
          <Grid container direction="row" justifyContent="flex-end" className={styles.SettingsDropdown_CloseButton}>
            <PopoverCloseButton handleClose={handleClose} />
          </Grid>
      
          <Grid container direction="column" className={styles.SettingsDropdown_Settings}>
            {children}
          </Grid>
        </Popover>
      )}
    </>
  )
}
