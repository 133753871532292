import React, { ReactElement } from 'react'

import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './RequirementField.module.less'

type RequirementFieldProps = {
  meetLowerCaseReq: boolean
  meetUppercaseOrSpecialCharReq: boolean
  meetPasswordLengthReq: boolean
  matchingPasswords: boolean
}

export default function RequirementField({
  meetLowerCaseReq,
  meetUppercaseOrSpecialCharReq,
  meetPasswordLengthReq,
  matchingPasswords,
}: RequirementFieldProps): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <Grid item>
        <Typography variant="body2" display="inline" className={styles.RequirementField_DescriptiveText}>
          {t(`• One lower case letter`)}
        </Typography>

        {meetLowerCaseReq ? (
          <Typography display="inline" className={styles.RequirementField_Validated}>
            {` ✔`}
          </Typography>
        ) : null}
      </Grid>
      <Grid item>
        <Typography variant="body2" display="inline" align="center" className={styles.RequirementField_DescriptiveText}>
          {t(`• One upper case letter OR a special character`)}
        </Typography>
        {meetUppercaseOrSpecialCharReq ? (
          <Typography display="inline" className={styles.RequirementField_Validated}>
            {` ✔`}
          </Typography>
        ) : null}
      </Grid>
      <Grid item>
        <Typography variant="body2" display="inline" className={styles.RequirementField_DescriptiveText}>
          {t(`• Minimum length of eight (8) characters`)}
        </Typography>
        {meetPasswordLengthReq ? (
          <Typography display="inline" className={styles.RequirementField_Validated}>
            {` ✔`}
          </Typography>
        ) : null}
      </Grid>
      <Grid item>
        <Typography variant="body2" display="inline" className={styles.RequirementField_DescriptiveText}>
          {t(`• Passwords matching`)}
        </Typography>
        {matchingPasswords ? (
          <Typography display="inline" className={styles.RequirementField_Validated}>
            {` ✔`}
          </Typography>
        ) : null}
      </Grid>
    </>
  )
}


