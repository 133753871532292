import React, { ReactElement, useMemo } from 'react'

import styles from './BCColumn.module.less'

type ColumnProps = {
  width?: string
  header?: {
    textContent?: string
    isToday?: boolean
    isWeekend?: boolean
  }
  children?: ReactElement | ReactElement[]
}

export default function Column({
  header = {},
  width = `100%`,
  children,
}: ColumnProps): ReactElement {
  const colour: string = useMemo(() => {
    if (header.isToday) return styles.Column_Header_Today
    if (header.isWeekend) return styles.Column_Header_Weekend
    return ``
  }, [header.isToday, header.isWeekend])

  const classes = `${styles.Column} ${colour}`

  return (
    <div
      className={classes}
      style={{
        // flexGrow: noFlexGrow ? 0 : 1,
        minWidth: `calc(${width})`,
        width: `calc(${width})`,
        maxWidth: `calc(${width})`,
      }}
    >
      {header.textContent && <div className={styles.Column_Header}>{header.textContent}</div>}
      {children}
    </div>
  )
}
