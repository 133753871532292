import { ReactElement, useCallback, useMemo, useState } from 'react'

import {
  useBulkDeleteSettings,
  useBulkUpdateSettingProperties,
  useOutdatedPropertiesWithSettings,
} from 'api/digitalTwin/digitalTwin.api'
import authStore from 'store/auth/auth'
import { Button, LoadingPlaceholderContainer, TextField } from 'ui/atoms'
import { Dialog } from 'ui/components/Dialog/Dialog'

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './OrphanedSettingsModal.module.less'

type PropertyRowProps = {
  level?: string
  name?: string
  attribute?: string
  setName?: (value: string) => void
  setLevel?: (value: string) => void
  setAttribute?: (value: string) => void
}

function PropertyRow({ level, name, attribute, setName, setLevel, setAttribute }: PropertyRowProps): ReactElement {
  return (
    <div className={styles.OrphanedSettingsModal_PropertyRow}>
      <TextField
        disabled={setLevel === undefined}
        variant="filled"
        marginRight
        label="Level"
        value={level}
        onChange={(value) => {
          if (setLevel !== undefined) {
            setLevel(`${value}`)
          }
        }}
      />
      <TextField
        disabled={setName === undefined}
        variant="filled"
        marginRight
        label="Component"
        value={name}
        onChange={(value) => {
          if (setName !== undefined) {
            setName(`${value}`)
          }
        }}
      />
      <TextField
        disabled={setAttribute === undefined}
        variant="filled"
        marginRight
        label="Property"
        value={attribute}
        onChange={(value) => {
          if (setAttribute !== undefined) {
            setAttribute(`${value}`)
          }
        }}
      />
    </div>
  )
}

type OrphanedSettingsModalProps = {
  open: boolean
  onClose: () => void
}

export default function OrphanedSettingsModal({ open, onClose }: OrphanedSettingsModalProps): ReactElement | null {
  const authSnap = useSnapshot(authStore)
  const primaryDigitalTwinUid = authSnap.activeSystem?.primary_digital_twin?.uid

  const [selectedProperty, setSelectedProperty] = useState<{
    level: string
    name: string
    attribute: string
    count: number
  }>()
  const [newLevel, setNewLevel] = useState<string>()
  const [newName, setNewName] = useState<string>()
  const [newAttribute, setNewAttribute] = useState<string>()

  const cancelEdit = useCallback(() => {
    setSelectedProperty(undefined)
    setNewLevel(undefined)
    setNewName(undefined)
    setNewAttribute(undefined)
  }, [])

  const setPropertyToEdit = useCallback(
    (property: { level: string; name: string; attribute: string; count: number }) => {
      setSelectedProperty(property)
      setNewLevel(property.level)
      setNewName(property.name)
      setNewAttribute(property.attribute)
    },
    []
  )

  const { t } = useTranslation()

  const { data: outdatedPropertiesWithSettings, status } = useOutdatedPropertiesWithSettings(primaryDigitalTwinUid)
  const sortedProperties = useMemo(() => {
    return (outdatedPropertiesWithSettings ?? []).sort((a, b) => {
      if (a.level.localeCompare(b.level) !== 0) {
        return a.level.localeCompare(b.level)
      }
      if (a.name.localeCompare(b.name) !== 0) {
        return a.name.localeCompare(b.name)
      }
      return a.attribute.localeCompare(b.attribute)
    })
  }, [outdatedPropertiesWithSettings])

  const { mutateAsync: bulkDeleteSettings } = useBulkDeleteSettings()
  const { mutateAsync: bulkUpdateSettingProperties } = useBulkUpdateSettingProperties()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('Orphaned settings')}</DialogTitle>
      <DialogContent>
        {status === 'loading' ? (
          <LoadingPlaceholderContainer width={500} />
        ) : (
          <>
            <TableContainer className={styles.OrphanedSettingsModal_Table}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Component')}</TableCell>
                    <TableCell>{t('Property')}</TableCell>
                    <TableCell>{t('Number of settings')}</TableCell>
                    <TableCell>{t('Edit')}</TableCell>
                    <TableCell>{t('Delete')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedProperties.map((outdatedPropertyWithSettings) => (
                    <TableRow
                      key={`${outdatedPropertyWithSettings.level}.${outdatedPropertyWithSettings.name}.${outdatedPropertyWithSettings.attribute}`}
                    >
                      <TableCell align="center">
                        {outdatedPropertyWithSettings.level}: {outdatedPropertyWithSettings.name}
                      </TableCell>
                      <TableCell align="center">{outdatedPropertyWithSettings.attribute}</TableCell>
                      <TableCell align="center">{outdatedPropertyWithSettings.count}</TableCell>
                      <TableCell align="center">
                        <Button
                          link
                          icon={'fal fa-pen'}
                          onClick={() => {
                            if (selectedProperty === outdatedPropertyWithSettings) {
                              cancelEdit()
                            } else {
                              setPropertyToEdit(outdatedPropertyWithSettings)
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          danger
                          link
                          icon={'fal fa-trash'}
                          onClick={() => {
                            if (primaryDigitalTwinUid !== undefined) {
                              bulkDeleteSettings({
                                level: outdatedPropertyWithSettings.level,
                                name: outdatedPropertyWithSettings.name,
                                attribute: outdatedPropertyWithSettings.attribute,
                                digitalTwinUid: primaryDigitalTwinUid,
                              })
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {selectedProperty && (
              <div className={styles.OrphanedSettingsModal_SelectedPropertyContainer}>
                <Typography fontWeight="bold">{`${t('Move {{count}} setting from', { count: selectedProperty.count })}:`}</Typography>
                <PropertyRow
                  level={selectedProperty.level}
                  name={selectedProperty.name}
                  attribute={selectedProperty.attribute}
                />
                <Typography fontWeight="bold" className={styles.OrphanedSettingsModal_ToLabel}>
                  {`${t('To')}}:`}
                </Typography>
                <PropertyRow
                  level={newLevel}
                  name={newName}
                  attribute={newAttribute}
                  setLevel={setNewLevel}
                  setName={setNewName}
                  setAttribute={setNewAttribute}
                />
                <div className={styles.OrphanedSettingsModal_EditPropertyButtonsContainer}>
                  <Button secondary className={styles.OrphanedSettingsModal_EditPropertyButton} onClick={cancelEdit}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    marginLeft
                    primary
                    className={styles.OrphanedSettingsModal_EditPropertyButton}
                    onClick={() => {
                      if (
                        newLevel !== undefined &&
                        newName !== undefined &&
                        newAttribute !== undefined &&
                        primaryDigitalTwinUid !== undefined
                      ) {
                        bulkUpdateSettingProperties({
                          digitalTwinUid: primaryDigitalTwinUid,
                          from: selectedProperty,
                          to: {
                            level: newLevel,
                            name: newName,
                            attribute: newAttribute,
                          },
                        })
                      }
                    }}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button secondary onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
