import React, { ReactElement, useEffect } from 'react'

import { TextField, StandardTextFieldProps, BaseTextFieldProps, FormControl } from '@mui/material'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import styles from './ReactHookFormTextField.module.less'

type ReactHookFormTextFieldProps = Omit<StandardTextFieldProps, `variant`> & {
  name: string
  label?: string
  rules?: RegisterOptions
  variant?: BaseTextFieldProps[`variant`]
  initialValidation?: boolean
  disabledTextAsCustomStyle?: boolean
}

export default function ReactHookFormTextField({
  name,
  label,
  defaultValue,
  disabled,
  rules,
  initialValidation,
  disabledTextAsCustomStyle,
  ...props
}: ReactHookFormTextFieldProps): ReactElement {
  const { control, trigger } = useFormContext()

  useEffect(() => {
    if (initialValidation) {
      trigger()
    }
  }, [initialValidation, trigger])

  return (
    <FormControl fullWidth margin="normal">
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            id={name}
            label={label}
            aria-label={label}
            disabled={disabled}
            variant="outlined"
            InputProps={
              disabledTextAsCustomStyle
                ? {
                  classes: {
                    disabled: styles.ReactHookFormTextField_Disabled,
                  },
                }
                : undefined
            }
            fullWidth
            {...field}
            {...props}
          />
        )}
      />
    </FormControl>
  )
}
