import React, { ReactElement } from 'react'

import { postStat } from 'api/stats/stats.api'
import authStore from 'store/auth/auth'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useSnapshot } from 'valtio'

import { openUrl } from 'helpers/global.helper/global.helper'

import styles from './ExplorerMenu.module.less'

export default function ExplorerMenu(): ReactElement {

  const authSnap = useSnapshot(authStore)
  const dbsToken = authSnap.user?.dbs_api_token
  const serviceType = 'abs_meas'
  const organizationName = authSnap.activeSystem?.organization_name

  const redirectUrl = `https://www.databysigholm.com/explorer?t=${dbsToken}&includeMetadata=abs_service:${serviceType},organization:${organizationName}`

  const { hasAccess } = useAuth()
  const hasAccessToDbsDataExplorer = hasAccess({module: 'dbsdataexplorer'})


  if(!hasAccessToDbsDataExplorer){
    return <></>
  }

  return (
    <div className={styles.ExplorerMenu}>
      <Tooltip arrow children={
        <div onClick={() => {
          postStat('dbs_data_explorer', 'click')
          openUrl(redirectUrl)
        }
        } className={styles.ExplorerMenu__pointer}>
          <span className={styles.ExplorerMenu_SearchIcon}>
            <Icon color={'white'} size='medium' icon={'far fa-search'} />
          </span>

          <IconButton>
            <Icon color={'white'} size='large' icon={'fal fa-chart-bar'} />
          </IconButton>
          {t('Review data')}
        </div>
      }
      title={
        <h4>{t('NEWS! Now you have the opportunity to review your own measurement data through the external tool Data by Sigholm (DbS). By pressing this button you will be directed to an external page.')}</h4>
      }
      />
    </div>
  )

}
