import React, { ReactElement, useState } from 'react'

import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import ShiftInfoWidget from './components/ShiftInfoWidget/ShiftInfoWidget'
import ShiftModuleHeader, { shiftHandoverPopoverButtonClass } from './components/ShiftModuleHeader/ShiftModuleHeader'
import ShiftReportsWidget, { shiftHandoverFilter } from './components/ShiftReportsWidget/ShiftReportsWidget'

const widgetConstants = {
  HANDOVER_GRID: `shiftHandover`,
  HANDOVER_WIDGET_1: `item-1`,
  HANDOVER_WIDGET_2: `item-2`,
}
const calendar: Array<Step> = [
  {
    target: widgetConstants.HANDOVER_WIDGET_1,
    content: `Here you can see the current shift schedule on a weekly basis. Each shift team has its own colour in the schedule. It is also possible to view the shift schedule several weeks ahead or back by pressing the arrows above the schedule.`,
    title: `Shift information`,
  },
]
const table: Array<Step> = [
  {
    target: widgetConstants.HANDOVER_WIDGET_2,
    content: `Shift reports that have been submitted end up in this table. Here you can find and view previous shift reports to see how they went.`,
    title: `Shift reports`,
  },
]
const steps: Array<Step> = [
  {
    target: shiftHandoverPopoverButtonClass,
    content: `You press this button to create a new shift report when it is time for a shift handover. The report contains an overview of how the shift went, but also good things to know in the upcoming shift. The report can be submitted no earlier than one hour before your shift ends.`,
    title: `Shift handover button`,
  },
  ...calendar,
  ...table,
  {
    target: shiftHandoverFilter,
    content: `You can filter the shift report table depending on what you want to see. To the right you can choose to load reports based on a period. To the left you can filter reports on shift teams, and in the table you can, e.g, filter on shift reports with a specific review.`,
    title: `Choose period and data`,
  },
]

const HandoverView = (): ReactElement => {
  const { t } = useTranslation()

  const [runTour, setRunTour] = useState<null | number>(null)

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={t(`Shift handover`)}
          onInfoButtonClick={() => {
            setRunTour(0)
          }}
        >
          <ShiftModuleHeader />
        </ModuleHeader>
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? calendar : runTour === 2 ? table : steps}
      />
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.HANDOVER_WIDGET_1}
          title={t(`Shift info`)}
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <ShiftInfoWidget />
        </CardWidget>
      </Grid>
      <Grid item xs={12}>
        <CardWidget
          id={widgetConstants.HANDOVER_WIDGET_2}
          title={t(`Shift reports`)}
          handleInfoIconClick={() => {
            setRunTour(2)
          }}
        >
          <ShiftReportsWidget />
        </CardWidget>
      </Grid>
    </View>
  )
}

export default HandoverView
