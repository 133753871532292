import React, { ReactElement, useState } from 'react'

import { useTimeoutInterval } from 'hooks/utilsHooks/utilsHooks'
import MetricField from 'ui/components/MetricField/MetricField'

import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import { getSamenergiHeaderText } from 'helpers/samEnergi.helper/samEnergi.helper'

import styles from './SamEnergiHeader.module.less'

export const CounterContentClass = `CounterContentClass`

export default function SamEnergiHeader(): ReactElement {
  const { t } = useTranslation()

  const dueDateToday = moment().hours(13).minutes(20)
  const [secondsToSubmit, setSecondsToSubmit] = useState(() => moment(dueDateToday).diff(moment(), `seconds`))

  function updateAll(): void {
    const timeToSubmit = moment(dueDateToday).diff(moment(), `seconds`)
    setSecondsToSubmit(timeToSubmit)
  }

  useTimeoutInterval({
    startTime: moment().add(1, `minute`).seconds(0),
    interval: 60 * 1000,
    callback: updateAll,
  })

  const headerText = getSamenergiHeaderText(secondsToSubmit)
  const secondDiff = secondsToSubmit < 0 ? secondsToSubmit + 86400 : secondsToSubmit
  const hours = Math.floor(secondDiff / 3600)
  const minutes = Math.floor((secondDiff / 60) % 60)
  return (
    <Grid container item xs={12} lg={7} className={CounterContentClass}>
      <Grid item xs={12}>
        <Typography variant="h1" className={styles.SamEnergiHeader_H3}>
          {t(`Time until next automatic submission`)}
        </Typography>
      </Grid>
      <Grid item container direction="row" xs={12} alignItems="flex-end">
        <Grid item>
          <MetricField value={hours} unit="h" />
        </Grid>
        <Grid item>
          <MetricField value={minutes} unit="min" />
        </Grid>
        <Typography variant="body2" className={styles.SamEnergiHeader_SmallText}>
          {headerText}
        </Typography>
      </Grid>
    </Grid>
  )
}
