import React, { ReactElement, useEffect, useState } from 'react'

import { setZoom } from 'store/zoom/zoom'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import SettlementsHeader from 'ui/components/SettlementsHeader/SettlementsHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './SettlementsView.module.less'

export default function SettlementsView(): ReactElement {
  const { t } = useTranslation()

  const handlePeriodChange = (currentPeriod: Period): void => {
    const isSameDate =
      Datetime.getDatesBetween(Datetime.getISONow(0), Datetime.toISOString(currentPeriod.endTime)).length !== 0

    const period = {
      startTime: Datetime.toISOString(currentPeriod.startTime),
      endTime: isSameDate
        ? Datetime.toISOString(currentPeriod.endTime)
        : Datetime.getEndOfDay(Datetime.toISOString(currentPeriod.endTime)),
    }

    setPeriod(period)
  }

  const [period, setPeriod] = useState({
    startTime: Datetime.getStartTimeEndTimeOfMonthRelativeToNow(1).startTime,
    endTime: Datetime.getStartTimeEndTimeOfMonthRelativeToNow(1).endTime,
  })

  useEffect(() => {
    setZoom(period.startTime, period.endTime)
  }, [period.endTime, period.startTime])

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={
            <Grid container direction="column">
              {t(`Settlements`)}
            </Grid>
          }
        >
          <SettlementsHeader period={period} handlePeriodChange={handlePeriodChange} />
        </ModuleHeader>
      }
    >
      <QueueProgressBar />
      <div className={styles.SettlementsView}>
        <UiConfig type="settlement_view" datasetStartTime={period.startTime} datasetEndTime={period.endTime} />
      </div>
    </View>
  )
}
