import React, { ReactElement } from 'react'

import { ApiRequestStatus } from 'api/api.utils'
import { DigitalTwinSetting } from 'api/digitalTwin/digitalTwin.api'
import { DigitalTwinHeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import SettingsDropdown from 'ui/components/SettingsDropdown/SettingsDropdown'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'
import Datetime from 'utils/datetime/datetime'
import { getSettingValue } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { Grid } from '@mui/material'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import StatusBar from 'views/SamEnergiView/components/SupplierView/components/SupplierViewHeader/components/StatusBar/StatusBar'
import SupplierSettingsItem from 'views/SamEnergiView/components/SupplierView/components/SupplierViewHeader/components/SupplierSettingsItem'

type DigitalTwinHeatSuppliersHeaderProps = {
  selectedDate: Moment
  setSelectedDate: (newDate: Moment) => void
  heatSupplier: DigitalTwinHeatSupplier
  fetchSettingsStatus: ApiRequestStatus
  settings: DigitalTwinSetting[]
}

export default function DigitalTwinHeatSuppliersHeader({
  selectedDate,
  setSelectedDate,
  heatSupplier,
  fetchSettingsStatus,
  settings,
}: DigitalTwinHeatSuppliersHeaderProps): ReactElement {
  const { t } = useTranslation()

  if (heatSupplier === undefined || fetchSettingsStatus === 'loading') {
    return <LoadingPlaceholderContainer />
  }

  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start">
      <Grid item xs={12} sm={12} md={5} lg={4} xl={2}>
        <ArrowDatePicker
          prevButtonTooltip={t(`Previous day`)}
          nextButtonTooltip={t(`Next day`)}
          format={`ddd D MMM`}
          value={selectedDate}
          step={{ unit: `day`, amount: 1 }}
          onChange={(date) => setSelectedDate(date)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
        <StatusBar currentDate={selectedDate} supplierId={heatSupplier.id} isDigitalTwin />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4} xl={2}>
        {settings.length ? (
          <SettingsDropdown customButtonTitle={t(`Has changes`)}>
            {settings.map((setting, key) => {
              const settingValue = getSettingValue(Datetime.getISONow(), setting)

              if (settingValue === null) {
                return null
              }

              return (
                <SupplierSettingsItem
                  key={key}
                  setting={{ ...setting, value: settingValue }}
                  supplierName={heatSupplier.display_name}
                />
              )
            })}
          </SettingsDropdown>
        ) : null}
      </Grid>
    </Grid>
  )
}
