import React, { ReactElement, useEffect, useState } from 'react'

import authStore from 'store/auth/auth'
import { setCurrentDate } from 'store/elplan/elplan'
import { Tabs } from 'ui/atoms'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import QueueProgressBar from 'ui/molecules/QueueProgressBar/QueueProgressBar'
import Datetime from 'utils/datetime/datetime'
import { systemFeatureFlagActive } from 'utils/systemUtils'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './ElectricityPlanningLightView.module.less'
import AncillaryServicesView from './components/AncillaryServicesView/AncillaryServicesView'
import BidManagementView from './components/BidManagementView/DayAheadBidManagementView'
import ElectricityPlanView from './components/ElectricityPlanView/DayAheadOverviewView'
import IntradayOverviewView from './components/IntradayOverviewView/IntradayOverviewView'

type TabName = 'DA - Bid management' | 'DA - Overview' | 'ID - Overview' | 'Ancillary services'

export default function ElectricityPlanningView(): ReactElement {
  const savedTab = parseInt(localStorage.getItem('activeTab') ?? '0', 10) ?? 0
  const [tabIndex, setTabIndex] = useState(savedTab)
  const { activeSystem } = useSnapshot(authStore) as typeof authStore
  const { t } = useTranslation()
  const route = getRoute()
  setCurrentDate(Datetime.getTomorrowDate().startTime, Datetime.getTomorrowDate().endTime)

  useEffect(() => {
    localStorage.setItem('activeTab', tabIndex.toString())
  }, [tabIndex])

  const intradayTabAvailable = systemFeatureFlagActive(activeSystem, 'electricity_planning_intraday_view')

  const ancillaryServicesTabAvailable = systemFeatureFlagActive(activeSystem, 'electricity_planning_ancillary_services_view')

  const tabs = [
    { title: t('DA - Bid management'), children: [] },
    { title: t('DA - Overview'), children: [] },
  ]
  const tabIndexes: Record<TabName, number | null> = {
    'DA - Bid management': 0,
    'DA - Overview': 1,
    'ID - Overview': null, // Hidden as default. Shown if the system has the correct feature flag, or if user is super user
    'Ancillary services': null, // Hidden as default for now. Only shown for super users
  }

  // Make sure all tabs have the right index, even if some tabs are hidden
  if (intradayTabAvailable) {
    tabIndexes['ID - Overview'] = 2
    tabs.push({ title: t('ID - Overview'), children: [] })
  }

  if (ancillaryServicesTabAvailable) {
    tabIndexes['Ancillary services'] = tabs.length // index 2 if intraday is hidden, index 3 if intraday is shown
    tabs.push({ title: t('Ancillary services'), children: [] })
  }

  return (
    <div className={styles.ElectricityPlanningView}>
      <View
        header={
          <>
            <ModuleHeader infoHeader={t(`Electricity Planning`)} />
            <div className={styles.ElectricityPlanningView_Tabs}>
              <Tabs 
                items={tabs} 
                onChange={(index) => setTabIndex(index)} 
                innerLeftPadding 
                marginBottom={false} 
                uniqueStorageKey={`ElectricityPlanningLightTabs_${route}`} 
              />
            </div>
          </>
        }
      >
        {tabIndex === tabIndexes['DA - Bid management'] && (
          <Grid item xs={12}>
            <BidManagementView />
          </Grid>
        )}

        {tabIndex === tabIndexes['DA - Overview'] && (
          <Grid item xs={12}>
            <ElectricityPlanView />
          </Grid>
        )}

        {/* If intraday is hidden, tabIndex for ID is null, so it will not be shown */}
        {tabIndex === tabIndexes['ID - Overview'] && (
          <Grid item xs={12}>
            <IntradayOverviewView />
          </Grid>
        )}

        {/* If Ancillary services tab is hidden, tabIndex for ID is null, so it will not be shown */}
        {tabIndex === tabIndexes['Ancillary services'] && (
          <Grid item xs={12}>
            <AncillaryServicesView />
          </Grid>
        )}

        <QueueProgressBar />
      </View>
    </div>
  )
}
