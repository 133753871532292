/* eslint-disable eqeqeq */
import React, { ReactElement, ReactNode } from 'react'

import { dynamicClassName } from 'styles/helper'
import { Card } from 'ui/atoms'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'

import styles from './Container.module.less'

type itemProps = {
  child_id: string
  size: number
  widthPercentage?: number
}

type ContainerProps = {
  items: itemProps[]
  children: ReactNode[]
  direction: `row` | `column`
  style?: `card` | ``
  title: string
  margin: boolean
  icon?: string
}

const defaultProps: Omit<ContainerProps, `datasets` | `children`> = {
  items: [
    {
      size: 1,
      child_id: ``,
    },
  ],
  direction: `column`,
  style: ``,
  title: ``,
  margin: false,
}
registerUiConfigComponent(`container`, Container)
registerDefaultProps(`container`, defaultProps)

export default function Container({
  children,
  direction,
  items,
  style,
  title,
  margin,
  icon,
}: ContainerProps): ReactElement {
  if (!items) {
    return <></>
  }
  const allChildren = children.map((child, index) => {
    const item = items?.find((x) => 
      React.isValidElement(child) && x.child_id.toString() === child.props.id.toString()
    )
    const size = item?.size
    const widthPercentage = item?.widthPercentage

    return (
      <div
        key={index}
        className={
          size
            ? dynamicClassName({
              [styles.Container_Child__flex0]: !!(size == 0),
              [styles.Container_Child__flex1]: !!(size == 1),
              [styles.Container_Child__flex2]: !!(size == 2),
              [styles.Container_Child__flex3]: !!(size == 3),
              [styles.Container_Child__10percent]: !!(widthPercentage == 10),
              [styles.Container_Child__20percent]: !!(widthPercentage == 20),
              [styles.Container_Child__30percent]: !!(widthPercentage == 30),
              [styles.Container_Child__40percent]: !!(widthPercentage == 40),
              [styles.Container_Child__50percent]: !!(widthPercentage == 50),
              [styles.Container_Child__60percent]: !!(widthPercentage == 60),
              [styles.Container_Child__70percent]: !!(widthPercentage == 70),
              [styles.Container_Child__80percent]: !!(widthPercentage == 80),
              [styles.Container_Child__90percent]: !!(widthPercentage == 90),
              [styles.Container_Child__100percent]: !!(widthPercentage == 100),
            })
            : styles.Container_Child
        }
      >
        {child}
      </div>
    )
  })

  const containerComponent = (
    <div
      className={dynamicClassName({
        [styles.Container]: true,
        [styles.Container_Row]: direction === `row`,
        [styles.Container_Column]: direction === `column`,
      })}
    >
      {allChildren}
    </div>
  )
  return style === `card` ? (
    <Card margin={margin}>
      <TitleWithIcon title={title} icon={icon} />
      {containerComponent}
    </Card>
  ) : (
    <>
      {title && <div className={styles.Container_Title}>{title}</div>}
      {containerComponent}
    </>
  )
}
