import React, { useEffect, ReactElement, useMemo, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import { 
  ELPLAN_BID_REFRESH_EVENT_QUERY_KEY, 
  ELPLAN_REFRESH_EVENT_QUERY_KEY, 
  PlanValues, 
  useCreateElplanMutation, 
  useElplan, 
  useElplanBids, 
  useElplanInfo, 
  useElplanSyncResultsMutation,
  useToggleLockMutation, 
  useUpdateElplanMutation, 
} from 'api/elplan/elplan.api'
import { useUiConfigAnchorComponent } from 'api/uiConfig/uiConfig.api'
import elplanStore from 'store/elplan/elplan'
import { Button, Switch } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import { DatePickerInForm } from 'ui/molecules/pickers/DatePicker/DatePicker'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getDatasetInstructionsForSumVolumeCalc } from '../BidManagementView/bidManagement.helper'
import { calcDataset } from 'helpers/dataset.helper/dataset.helper'
import { convertNumberDecimalSign } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'

import ElectricityPlanModal from './ElectricityPlanModal/ElectricityPlanModal'
import styles from './ElectricityPlanView.module.less'


function getElectricityPlan(dataset: Dataset): PlanValues[]
{
  const array: PlanValues[] = []

  dataset.times.forEach((time, index) => {
    array.push({
      volume: dataset.values[index] ?? 0,
      start_time: time as ISODateTime,
      end_time: dataset.times.length -1 === index ? Datetime.getEndOfDay(time as ISODateTime) : dataset.times[index + 1] as ISODateTime,
      time: time as ISODateTime,
    })

  })

  return array

}

export default function ElectricityPlanView(): ReactElement {
  const { t } = useTranslation()
  const auth = useAuth()
  const elplanInfo = useElplanInfo(auth.systemId)

  const [currentView, setCurrentView] = useState<'today' | 'tomorrow' | ''>('tomorrow')
  const elplanSnap = useSnapshot(elplanStore)
  const [date, setDate] = useState<{ 
    startTime: ISODateTime | undefined, 
    endTime: ISODateTime | undefined,
  }>({
    startTime: elplanSnap.date.start_time, 
    endTime: elplanSnap.date.end_time,
  })

  const [modalIsOpen, setModalIsOpen] = useState<boolean>()
  const [isEditingPlan, setIsEditingPlan] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  useElplan(
    elplanSnap.elplan_info_id, 
    date.startTime ? Datetime.toLocalTime(date.startTime, 'onlyDate') as ISODateTime : undefined
  )
  const amountOfBlockBids = elplanSnap.blockBidInfo.amount || 0
  const onlyReturnIds = (new Array(amountOfBlockBids))
    .fill('')
    .map((_, i) => `blockbid.${i + 1}.volume`)

  const { data: elplanBidsExistForCurrentDate } = useElplanBids(elplanSnap.elplan_info_id, date.startTime, date.endTime)
  const { data: elplanBidsUiConfig } = useUiConfigAnchorComponent('elplan_bids')
  const { data: elplanBidsDatasets = [] } = useDatasets(
    elplanBidsUiConfig?.id ?? -1,
    elplanBidsUiConfig?.version ?? -1,
    elplanBidsUiConfig?.dataset_instructions || [],
    date.startTime,
    date.endTime,
    {
      onlyReturnIds,
      fillMissingHourTimestamps: false,
      datasetRefreshToken: ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
      overrideAlias: {
        elplan_info_id: elplanSnap.elplan_info_id,
      },
    }
  )

  const electricityPlanFromBids = useMemo(() => {
    if (!elplanBidsDatasets?.length) {
      return []
    }

    const calcDatasets = calcDataset(elplanBidsDatasets, getDatasetInstructionsForSumVolumeCalc(amountOfBlockBids))
    return getElectricityPlan(calcDatasets)
  }, [elplanBidsDatasets, amountOfBlockBids])

  const elplanExistsForCurrentDate = elplanSnap.elplan.has_plan
  const isLocked = elplanSnap.elplan.is_locked

  const { mutate: createElplanValues} = useCreateElplanMutation()
  const { mutate: updateElplanValues } = useUpdateElplanMutation()
  const { mutate: toggleLockVerification } = useToggleLockMutation()
  const { mutate: syncElplanResults } = useElplanSyncResultsMutation()

  const graphDates = useMemo(() => {

    if (currentView === 'tomorrow') {
      setDate({startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime})
      return {startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime}
    }

    if (currentView === 'today') {
      setDate({startTime: Datetime.getTodayDate().startTime, endTime: Datetime.getTodayDate().endTime})
      return {startTime: Datetime.getTodayDate().startTime, endTime: Datetime.getTodayDate().endTime}
    }

  }, [currentView])

  useEffect(() => {
    queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
  }, [date])


  function updatingPlan(elplan: PlanValues[]): void {
    const values = elplan
      .map((value) => {
        return {
          id: value.id as number,
          volume: convertNumberDecimalSign(value.volume),
        }
      })
    updateElplanValues({id: elplanSnap.elplan.elplan_id, values})
  }

  function onCreatingPlan(): void {
    setModalIsOpen(true)
    setIsEditingPlan(!isEditingPlan)
  }

  function onEditingPlan(): void {
    setModalIsOpen(true)
    setIsEditingPlan(!isEditingPlan)
  }

  function verifyPlan(): void {
    toggleLockVerification({id: elplanSnap.elplan.elplan_id})
  }

  function createPlan(electricityPlan: PlanValues[]): void {
    const values = electricityPlan.map((bid) => {
      return {
        time: bid.time,
        volume: convertNumberDecimalSign(bid.volume),
      }
    })
    setModalIsOpen(false)

    if (date.startTime) {
      createElplanValues({
        elplan_info: elplanSnap.elplan_info_id, 
        date: Datetime.toLocalTime(date.startTime, 'onlyDate'),
        values,
      })
    }
  }

  function onDateTimePickerChange(value: ISODateTime): void{
    setDate({ startTime: Datetime.getStartOfDay(value), endTime: Datetime.getEndOfDay(value) })
    setCurrentView('')
  }

  const noIntegration = (elplanInfo?.data?.integration === undefined) || (elplanInfo?.data?.integration === null) || (elplanInfo?.data?.integration === '')

  return (
    <CardWidget
      title={t(`Current electricity plan`)} id={''}        >
      <div className={styles.ElectricityPlanView_Period}>
        <Switch value={currentView}
          items={[
            { label: t('Today'), value: 'today' },
            { label: t('Tomorrow'), value: 'tomorrow' },
          ]}
          onClick={(value) => { setCurrentView(value) }}
          setNoTabActive={currentView === ''} />
        <DatePickerInForm
          label={t('Chosen date')}
          value={date.endTime}
          onSubmit={(value) => { onDateTimePickerChange(value) }}
          showWeekNumbers
          granularity={'day'}
        />
        {elplanExistsForCurrentDate &&
          <InfoBanner
            text={elplanSnap.elplan.is_locked ? t('The electricity plan is locked in production plan') : t('As of now, this electricity plan does not affect the production plan. Lock the electricity plan with the button on the right')}
            style={!elplanSnap.elplan.is_locked ? 'warning' : 'info'} />}
      </div>
      <div className={styles.ElectricityPlanView_Buttons__right}>
        {(!noIntegration && !isLocked) && (
          <Button
            secondary
            marginBottom
            icon={'fal fa-sync'}
            onClick={() => {
              if (!auth.activeSystem?.name || !date.startTime) {
                return
              }

              syncElplanResults({
                date: Datetime.toLocalTime(date.startTime, 'onlyDate'),
                system: auth.activeSystem?.name,
              })
            }}
          >
            {t('Fetch results')}
          </Button>
        )}
        {!elplanExistsForCurrentDate ?
          <Button
            primary
            icon={'fal fa-plus'}
            marginLeft
            marginBottom
            disabled={!(elplanBidsExistForCurrentDate && elplanBidsExistForCurrentDate.length > 0) ? true : false}

            onClick={() => onCreatingPlan()}>
            {t('Create elplan from bids')}
          </Button>
          :
          elplanExistsForCurrentDate && !elplanSnap.elplan.is_locked ?
            <Button
              icon={'fal fa-pen'}
              primary
              marginBottom
              marginLeft
              disabled={elplanSnap.elplan.is_locked}
              onClick={() => onEditingPlan()}>{t('Edit electricity plan')}
            </Button> : null}
        {elplanExistsForCurrentDate ? <Button
          icon={elplanSnap.elplan.is_locked ? 'fal fa-unlock' : 'fal fa-lock'}
          primary={!elplanSnap.elplan.is_locked}
          warning={elplanSnap.elplan.is_locked}
          disabled={!elplanExistsForCurrentDate}
          marginLeft
          marginBottom
          tooltip={elplanSnap.elplan.is_locked ? t('Electricitiy plan will no longer be locked in production plan') : ''}
          onClick={elplanSnap.elplan.is_locked ? () => verifyPlan() : () => setConfirmDialog(true)}
        >
          {elplanSnap.elplan.is_locked ? t('Unlock to edit') : t('Lock in production plan')}
        </Button> : null}
      </div>

      <div className={styles.ElectricityPlanView_Tables}>

        <UiConfig type="electricity_elplan"
          datasetStartTime={date ? date?.startTime : graphDates?.startTime}
          datasetEndTime={date ? date?.endTime : graphDates?.endTime}
          datasetRefreshToken={ELPLAN_REFRESH_EVENT_QUERY_KEY}/>
      </div>

      {modalIsOpen &&
        <ElectricityPlanModal
          onClose={() => setModalIsOpen(false)}
          onSubmit={(electricityPlan) =>
            elplanExistsForCurrentDate ? updatingPlan(electricityPlan) :
              createPlan(electricityPlan)}
          elplanExistsWarningText={t('You are currently editing an already created electricity plan. Any changes you make to this plan may differ from the plan you have sent into trading.')}
          planValues={!elplanExistsForCurrentDate ? electricityPlanFromBids : elplanSnap.elplan.values} />}
      {confirmDialog &&
          <ConfirmDialog
            open={confirmDialog}
            onConfirm={() => {
              setConfirmDialog(false)
              verifyPlan()
            }}
            onClose={() => setConfirmDialog(false)}
            textObject={{
              title: t(`Are you sure you want to lock a manual electric plan?`),
              text: t(`Note that if you lock a manual electricity plan, the automatic electricity plan will not automatically enter. Do you want to continue to lock the electricity plan?`),
            }}
          />}
    </CardWidget>
  )
}
