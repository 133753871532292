import React, { ReactElement } from 'react'

import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'

import { InputAdornment, Icon, OutlinedTextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

type SeasonTextFieldProps = Partial<OutlinedTextFieldProps> & {
  inputIcon: string
  name: string
  label: string
  declinedHours: number
  error: boolean
}

export default function SeasonTextField({
  declinedHours,
  label,
  name,
  inputIcon,
  ...props
}: SeasonTextFieldProps): ReactElement {
  const { t } = useTranslation()

  return (
    <ReactHookFormTextField
      name={name}
      defaultValue=""
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ color: 'grey' }}>
            <Icon className={inputIcon} />
          </InputAdornment>
        ),
      }}
      helperText={(declinedHours + ` ` + t(`hours consumed`)) as string}
      type="number"
      variant="outlined"
      margin="normal"
      {...props}
    />
  )
}
