import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react'

import { Module, useModules } from 'api/modules/modules.api'
import { ACTIVE_SYSTEM_LOCAL_STORAGE_KEY, setCurrency } from 'store/auth/auth'
import sentry from 'utils/sentry/sentry'

export type FeatureRequirements = {
  submodule?: string
  module?: string
  permission?: UserPermission
}

type AuthContextType = {
  activeSystem: System | null
  user: User | null
  setActiveSystem: (system: System) => void
  setUser: (user: User | null) => void
  systemId?: number,
  hasAccess: ({module, submodule, permission }: FeatureRequirements) => boolean
}

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

const userLocalStorageKey = `user`

type AuthProviderProps = { children: React.ReactNode }

export function AuthProvider({ children }: AuthProviderProps): ReactElement {

  const [activeSystem, setActiveSystemLocal] = useState(() => {
    const activeSystemItem = localStorage.getItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY)
    const activeSystem: System | null = activeSystemItem ? JSON.parse(activeSystemItem) : null
    return activeSystem
  })

  const [user, setUserLocal] = useState(() => {
    const userItem = localStorage.getItem(userLocalStorageKey)
    const user: User | null = userItem ? JSON.parse(userItem) : null
    return user
  })

  const systemId = useMemo(() => activeSystem?.id ?? user?.system, [activeSystem, user])
  const { data: modules } = useModules(systemId)

  const setActiveSystem = useCallback((system: System) => {
    localStorage.setItem(ACTIVE_SYSTEM_LOCAL_STORAGE_KEY, JSON.stringify(system))
    setActiveSystemLocal(system)
    sentry.setActiveSystem(system)
    setCurrency(system.currency)
  }, [])

  const setUser = useCallback((user: User | null) => {
    if (user) {
      localStorage.setItem(userLocalStorageKey, JSON.stringify(user))
      sentry.setUser(user)
    }
    setUserLocal(user)
  }, [])

  const hasAccessCallback = useCallback((requirements: FeatureRequirements) => {
    if (!user || !modules) {
      return false
    }

    return hasAccess(user, modules, requirements)
  }, [modules, user])

  const value = useMemo(
    () => ({
      activeSystem,
      setActiveSystem,
      user,
      setUser,
      systemId,
      hasAccess: hasAccessCallback,
    }),
    [activeSystem, setActiveSystem, user, setUser, systemId, hasAccessCallback]
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within \`AuthProvider\``)
  }
  return context
}

export function hasAccess(
  user: User,
  modules: Module[],
  { module, submodule, permission }: FeatureRequirements
): boolean {
  if (user.is_staff && user.is_superuser) {
    return true
  }

  const hasSystemPermission = user.system_permissions.some((sp) => sp.permission === permission)
  const hasGlobalPermission = permission && !!user?.global_permissions?.includes(permission)

  if (permission && !(hasSystemPermission || hasGlobalPermission)) {
    return false
  }

  if (module && !submodule) {
    const hasModule = modules.some(({ name }) => name === module)
    if (!hasModule) {
      return false
    }
  }

  const hasSubmodule = modules
    .filter(({ name }) => !module || name === module)
    .some(({ submodules }) => submodules?.some((name) => name === submodule))

  if (submodule && !hasSubmodule) {
    return false
  }


  return true
}