import React, { ReactElement } from 'react'

import { OptProject } from 'api/optProjects/optProjects.api'
import UiConfig from 'ui/uiConfig/UiConfig'

import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import ChangeLogTabs from './components/ChangeLogTabs/ChangeLogTabs'

type SandboxProjectWidgetProps = {
  project: OptProject
}

export default function SandboxProjectWidget({ project }: SandboxProjectWidgetProps): ReactElement {
  const { t } = useTranslation()
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h2">{t('Forecast and meas values for period')}</Typography>
        {project?.start_time && project.end_time && (
          <UiConfig
            type="sandbox_forecast"
            datasetStartTime={moment(project?.start_time)}
            datasetEndTime={moment(project?.end_time)}
            overrideAlias={{ opt_model_id: project.opt_model ?? -1, opt_job_type_id: 8 }}
            datasetCreatedAt={project.created_at}
            datasetCreatedAtOperator={'lte'}
          />
        )}
      </Grid>
      <Grid item>
        <ChangeLogTabs project={project} />
      </Grid>
    </Grid>
  )
}
