import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { ConfigurableProperty, useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import { BASE_SETTING_PRIORITIES, DEVIATION_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'
import { DigitalTwinHeatSupplier, useDeclinedHours } from 'api/heatSuppliers/heatSuppliers.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import DigitalTwinEditSettingsModal from 'ui/components/DigitalTwinEditSettingsModal/DigitalTwinEditSettingsModal'
import SettingsModal from 'ui/components/SettingsModal/SettingsModal'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'
import { getUnitConfigurableProperty as getUnitConfigurableProperties } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { Grid } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'


import useDigitalTwinHeatSupplierSettings from '../hooks/useDigitalTwinHeatSupplierSettings'
import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getDateTimePickerRangesFromPermissions } from 'helpers/settingsModal.helper/settingsModal.helper'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import ManageButton from 'views/SamEnergiView/components/SupplierView/components/SupplierViewTabs/components/SupplierDetails/components/ManageButton/ManageButton'

import DigitalTwinAvailableHoursForm, {
  ActiveSettingsRange,
  AVAILABLE_HOURS_SETTINGS_PRIO,
  getDigitalTwinAvailableHoursPeriod,
} from './DigitalTwinAvailableHoursForm/DigitalTwinAvailableHoursForm'

export const AVAILABLE_HOURS_ATTRIBUTES = [
  'available_hours',
  'available_hours_unplanned_winter',
  'available_hours_unplanned_summer',
]

function activeSettingsRangeFromPermissions(canEditHistoric: boolean): ActiveSettingsRange {
  const dateRangeMin = getDateTimePickerRangesFromPermissions(canEditHistoric).endTime.min
  const newActiveFrom = dateRangeMin === undefined ? undefined : Datetime.toISOString(dateRangeMin)
  return { activeFrom: newActiveFrom, activeTo: undefined }
}

type DigitalTwinSamEnergiManageButtonsProps = {
  heatSupplier: DigitalTwinHeatSupplier
  selectedDate: Moment
  onDataChanged: () => void
}

export default function DigitalTwinSamEnergiManageButtons({
  heatSupplier,
  selectedDate,
  onDataChanged,
}: DigitalTwinSamEnergiManageButtonsProps): ReactElement {
  const { t } = useTranslation()

  const [year, setYear] = useState(moment().year())

  const { activeSystem } = useAuth()
  const primaryDigitalTwinUid = activeSystem?.primary_digital_twin?.uid
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwinUid, true)

  const planSettingsPermissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_plan_settings`),
    canEditHistoric: useHasPermission(`change_historic_plan_settings`),
  }
  const modelSettingsPermissions: SettingsPermissions = {
    canEdit: useHasPermission(`change_model_settings`),
    canEditHistoric: useHasPermission(`change_historic_model_settings`),
  }

  const [currentProperties, setCurrentProperties] = useState<ConfigurableProperty[]>([])
  const [startEndTimeRange, setStartEndTimeRange] = useState<ActiveSettingsRange>(
    activeSettingsRangeFromPermissions(false)
  )
  const [currentPropPermissions, setCurrentPropPermissions] = useState(planSettingsPermissions)

  const { priorities, attributes } = useMemo(
    () => ({
      priorities: [
        ...BASE_SETTING_PRIORITIES,
        ...DEVIATION_SETTING_PRIORITIES,
        { priority_level: AVAILABLE_HOURS_SETTINGS_PRIO, display_name: 'Declined hours' },
      ],
      attributes: currentProperties.map((prop) => prop.attribute),
    }),
    [currentProperties]
  )

  const { settings, fetchSettings, fetchSettingsStatus } = useDigitalTwinHeatSupplierSettings({
    heatSupplier,
    activeFrom: startEndTimeRange.activeFrom,
    activeTo: startEndTimeRange.activeTo,
    attributes,
    priorities,
  })

  const datetimeStartTime = moment(selectedDate).startOf(`day`)
  const datetimeEndTime = moment(selectedDate).endOf(`day`).minute(0).second(0)

  useEffect(() => {
    setStartEndTimeRange(getDigitalTwinAvailableHoursPeriod(year))
  }, [year])

  function handleOpen(currentPermissions: SettingsPermissions, newAttributes: string[]): void {
    if (newAttributes === AVAILABLE_HOURS_ATTRIBUTES) {
      setStartEndTimeRange(getDigitalTwinAvailableHoursPeriod(year))
    } else {
      setStartEndTimeRange(activeSettingsRangeFromPermissions(currentPermissions.canEditHistoric))
    }

    setCurrentPropPermissions(currentPermissions)

    const newProperties = getUnitConfigurableProperties(heatSupplier.unit_name, newAttributes, digitalTwin)
    setCurrentProperties(newProperties)
  }

  const onCloseModal = (): void => {
    setCurrentProperties([])
    setYear(moment().year())
  }
  
  const onSettingsModified = (): void => {
    onDataChanged()
    fetchSettings()
  }
  
  const onAvailableHoursModified = (): void => {
    onDataChanged()
    fetchSettings()
    onCloseModal()
  }

  const { data: declinedHours } = useDeclinedHours(moment(selectedDate).year(), heatSupplier.id, true)

  const currentProperty = currentProperties?.at(0)

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" style={{ width: '100%', marginTop: 30 }}>
        <ManageButton
          title={t('Decline delivery')}
          infoTooltip={t('Setting for declining delivery at specific hours for the specific supplier')}
          onClick={() => handleOpen(planSettingsPermissions, ['availability'])}
        />
        <ManageButton
          title={t('Manage requirement on delivery temperature')}
          infoTooltip={t('Setting for adjusting the temperature demand for the specific supplier')}
          onClick={() => handleOpen(modelSettingsPermissions, ['temperature_demand'])}
        />
        <ManageButton
          title={t('Adjust hours to decline')}
          infoTooltip={t(
            'Setting for editing the agreement on how many hours you are able to deny delivery from the specific supplier.'
          )}
          onClick={() => handleOpen(modelSettingsPermissions, AVAILABLE_HOURS_ATTRIBUTES)}
        />
        <Grid item xs={12} md={10}>
          <UiConfig
            type="samenergi_excel_button"
            datasetStartTime={datetimeStartTime}
            datasetEndTime={datetimeEndTime}
          />
        </Grid>
      </Grid>
      {digitalTwin && (
        <>
          {(currentProperty?.attribute === 'availability' || currentProperty?.attribute === 'temperature_demand') && (
            <DigitalTwinEditSettingsModal
              configurableProperties={currentProperty === undefined ? [] : [currentProperty]}
              period={{ startTime: datetimeStartTime, endTime: datetimeEndTime }}
              level={'unit'}
              attribute={currentProperty?.attribute}
              name={heatSupplier.unit_name}
              onClose={onCloseModal}
              digitalTwin={digitalTwin}
              permissions={currentPropPermissions}
              priorities={
                currentProperty.attribute === 'temperature_demand'
                  ? BASE_SETTING_PRIORITIES
                  : DEVIATION_SETTING_PRIORITIES
              }
              baseSettingPriorities={BASE_SETTING_PRIORITIES}
              onSettingModified={onSettingsModified}
              isBaseSettings={currentProperty.attribute === 'temperature_demand'}
            />
          )}
          {currentProperties.length === 3 &&
            currentProperties.every((prop) => AVAILABLE_HOURS_ATTRIBUTES.includes(prop.attribute)) &&
            declinedHours && (
            <SettingsModal
              closeModal={onCloseModal}
              open={currentProperty !== undefined}
              title={
                `${getPrettyName(`unit.${heatSupplier.unit_name}`, digitalTwin.translations)}: ` +
                  `${getPrettyName(`unit.${heatSupplier.unit_name}.available_hours`, digitalTwin.translations)}`
              }
              form={
                <DigitalTwinAvailableHoursForm
                  declinedHours={declinedHours}
                  settings={settings}
                  digitalTwinUid={digitalTwin.uid}
                  hasEditPermission={modelSettingsPermissions.canEdit}
                  onSettingsModified={onAvailableHoursModified}
                  heatSupplierUnitName={heatSupplier.unit_name}
                  year={year}
                  setYear={setYear}
                  fetchSettingsStatus={fetchSettingsStatus}
                />
              }
            />
          )}
        </>
      )}
    </>
  )
}
