import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery } from 'react-query'

export type ShiftTeam = DisplayNameObject & {
  id: number
  color: string
}

export type DashboardData = {
  id: number
  display_name: string
  color: string
  handovers: number
}

export type EmojiData =
  | {
      very_bad: number
      bad: number
      good: number
      very_good: number
    }
  | undefined


const SHIFT_TEAMS_QUERY_KEY = `shiftTeams`
export function useShiftTeams() {
  const { systemId } = useAuth()

  const params = { system: systemId }
  return useQuery(
    [SHIFT_TEAMS_QUERY_KEY, params],
    () => apiClient<ShiftTeam[]>(`shift_teams`, { params }).then((result) => result.sort((a, b) => a.name.localeCompare(b.name))),
    {
      enabled: !!systemId,
    }
  )
}
