import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import SelectButton from 'api/SelectButton/SelectButton'
import {
  DigitalTwinSetting,
  DeviationSettingLevel,
  DigitalTwin,
  useDigitalTwinSettings,
  ConfigurableProperty,
} from 'api/digitalTwin/digitalTwin.api'
import { useGetAllEventMutation } from 'api/events/events.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import { Button } from 'ui/atoms'
import { Dialog } from 'ui/components/Dialog/Dialog'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Datetime from 'utils/datetime/datetime'

import { DialogContent } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../AuthContext/AuthContext'
import { DigitalTwinSettingDialog } from '../DigitalTwinSettingDialogContent/DigitalTwinSettingDialog'
import { CreateSettingData } from '../DigitalTwinSettingDialogContent/DigitalTwinSettingDialogContent'
import { TIME_SERIES_VISUALIZATION } from '../DigitalTwinSettingForm/constants'
import InfoBanner from '../InfoBanner/InfoBanner'
import { getPrettyName, getSettingsWithUnitsFromMatchingConfigurableProperty } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './DigitalTwinEditSettingsModal.module.less'
import PreviousSettingsTable from './PreviousSettingsTable/PreviousSettingsTable'

type DigitalTwinEditSettingsModalProps = {
  onClose: () => void
  digitalTwin: DigitalTwin
  level: string | undefined
  attribute?: string
  name: string
  permissions: SettingsPermissions
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  originalPriority?: number
  onSettingModified: () => void
  isBaseSettings?: boolean
  period?: Period | undefined
  configurableProperties: ConfigurableProperty[]
}

export default function DigitalTwinEditSettingsModal({
  onClose,
  configurableProperties,
  digitalTwin,
  level,
  attribute,
  name,
  period,
  permissions,
  priorities,
  baseSettingPriorities,
  onSettingModified,
  isBaseSettings = false,
}: DigitalTwinEditSettingsModalProps): ReactElement {
  const [settingToCreate, setSettingToCreate] = useState<CreateSettingData | null>(null)

  let datepickerDefaultStartTime: ISODateTime
  if (period?.startTime) {
    datepickerDefaultStartTime = Datetime.toISOString(moment(period.startTime))
  } else if (isBaseSettings) {
    datepickerDefaultStartTime = Datetime.toISOString(moment())
  } else {
    datepickerDefaultStartTime = Datetime.toISOString(moment().startOf('day'))
  }

  let datepickerDefaultEndTime: ISODateTime | null = null
  if (period?.endTime) {
    datepickerDefaultEndTime = Datetime.toISOString(moment(period.endTime))
  } else if (!isBaseSettings) {
    datepickerDefaultEndTime = Datetime.toISOString(moment().startOf('day').hours(23))
  }

  const { t } = useTranslation()
  const { hasAccess, systemId } = useAuth()

  const filteredProperties = useMemo(
    () =>
      configurableProperties.filter(
        (property) =>
          property.level === level &&
          property.name === name &&
          (attribute === undefined || property.attribute === attribute)
      ),
    [attribute, configurableProperties, level, name]
  )

  const [settings, setSettings] = useState<DigitalTwinSetting[]>([])
  const settingsWithUnit = getSettingsWithUnitsFromMatchingConfigurableProperty(digitalTwin, settings)
  const [operationalEvents, setOperationalEvents] = useState<Map<number, EventPost>>(new Map<number, EventPost>())
  const { mutateAsync: fetchSettings } = useDigitalTwinSettings()
  const { mutateAsync: fetchOperationalEvents } = useGetAllEventMutation()

  const refetchOperationEvents = useCallback((settings: DigitalTwinSetting[]) => {
    // Fetch operational events only if at least one setting have it
    if (settings.some((setting) => setting.operational_event !== null)) {
      fetchOperationalEvents({
        object_category: level,
        object_name: name,
        attribute: attribute,
      }).then(data => {
        const dict = new Map<number, EventPost>()
        data.forEach((event) => {
          if (event.id !== undefined) {
            dict.set(event.id, event)
          }
        })
        setOperationalEvents(dict)
      })
    }
  }, [attribute, fetchOperationalEvents, level, name])

  const refetchSettings = useCallback(() => {
    if (filteredProperties.length === 0) {
      return
    }

    fetchSettings({
      digitalTwinUid: digitalTwin?.uid,
      properties: filteredProperties,
      priorities,
    }).then((settings) => {
      setSettings(settings)
      refetchOperationEvents(settings)
    })
  }, [digitalTwin?.uid, fetchSettings, refetchOperationEvents, filteredProperties, priorities])
  useEffect(refetchSettings, [refetchSettings])

  const doOnSettingModified = useCallback(() => {
    refetchSettings()
    onSettingModified()
  }, [onSettingModified, refetchSettings])

  //used to check if time series uiconfig exists for current system since time series charts and table functionality does not work at all without it
  const uiConfigTimeSeriesVisualization = useUiConfig(systemId, TIME_SERIES_VISUALIZATION)
  const timeSeriesUiConfigExistsForCurrentSystem = !!(uiConfigTimeSeriesVisualization?.data?.length)

  const hasAccessToTimeseries = hasAccess({ submodule: 'optimizesettings_time_series', module: 'optimizesettings' }) && timeSeriesUiConfigExistsForCurrentSystem

  const onCreateSettingClick = (): void => {
    setSettingToCreate({
      level: level as DeviationSettingLevel,
      name: name,
      attribute: attribute,
      start_time: datepickerDefaultStartTime,
      end_time: datepickerDefaultEndTime,
      comment: '',
      value: 0,
      priority: priorities[0].priority_level,
      isTimeSeries: false,
    })
  }
  
  const onCreateTimeseriesSettingClick = (): void => {
    setSettingToCreate({
      level: level as DeviationSettingLevel,
      name: name,
      attribute: attribute,
      start_time: datepickerDefaultStartTime,
      end_time: datepickerDefaultEndTime,
      comment: '',
      value: [],
      priority: priorities[0].priority_level,
      isTimeSeries: true,
    })
  }

  return (
    <Dialog
      open={attribute !== undefined}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
      classes={{ paper: styles.DigitalTwinEditSettingsModal_CustomDialogPaper }}
    >
      <CustomDialogTitle
        title={`${getPrettyName(`${level}.${name}`, digitalTwin.translations)}: ${getPrettyName(`${level}.${name}.${attribute}`, digitalTwin.translations)}`}
        handleClose={onClose}
      />
      <DialogContent>
        {!settingToCreate && (
          <div className={styles.DigitalTwinEditSettingsModal_Button__right}>
            {hasAccessToTimeseries
              ? (
                <SelectButton
                  icon="fal fa-plus"
                  primary
                  disabled={!permissions.canEdit}
                  disabledTooltip={t(
                    'Unfortunately, you do not have permission to create settings on this user/facility'
                  )}
                  menuItems={[
                    {
                      label: t('Setting'),
                      onClick: onCreateSettingClick,
                    },
                    {
                      label: t('Time series setting'),
                      onClick: onCreateTimeseriesSettingClick,
                    },
                  ]}
                >
                  {t(`Create new setting`)}
                </SelectButton>
              ) 
              : (
                <Button
                  primary
                  onClick={onCreateSettingClick}
                  disabled={!permissions.canEdit}>
                  {t(`Create new setting`)}
                </Button>
              )}
          </div>
        )}
        {settingToCreate?.isTimeSeries && !hasAccessToTimeseries && (
          <InfoBanner text={t('Unfortunately, you do not have permission to make changes here')} style='warning' />
        )}
        <DigitalTwinSettingDialog
          initData={settingToCreate}
          onSettingModified={doOnSettingModified}
          onClose={() => setSettingToCreate(null)}
          configurableProperties={configurableProperties}
          propertyItems={[]}
          digitalTwin={digitalTwin}
          allowEmpty={false}
          priorities={priorities}
          baseSettingPriorities={baseSettingPriorities}
          isBaseSettings={isBaseSettings}
          hasUnitAndProperty={true}
          hideActions={settingToCreate?.isTimeSeries && !hasAccessToTimeseries}
        />

        <PreviousSettingsTable
          allSettingsForChosenProperty={settingsWithUnit}
          operatinalEvents={operationalEvents}
          onEventPostModalClose={() => refetchOperationEvents(settings)}
          onEditSetting={(setting: DigitalTwinSetting) => {
            const value = typeof setting.value === 'string' ? Number(setting.value) : setting.value
            setSettingToCreate({
              ...setting,
              value: value,
              operational_event: setting.operational_event ?? null,
            })
          }}
          permissions={permissions}
        />
      </DialogContent>
    </Dialog>
  )
}
