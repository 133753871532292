import React, { ReactElement } from 'react'

import { useShiftHandoversCreateInfo } from 'api/shiftHandovers/shiftHandovers.api'
import { useScheduledCallback } from 'hooks/utilsHooks/utilsHooks'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'

import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import ShiftModuleButton from './components/ShiftModuleButton/ShiftModuleButton'

export const shiftHandoverPopoverButtonClass = `shiftHandoverPopoverButton`

export default function ShiftModuleHeader(): ReactElement {
  const { t } = useTranslation()

  const { data: createInfo, status: createInfoStatus, refetch } = useShiftHandoversCreateInfo()

  const endingShiftTime = createInfo?.handover_shifts?.ending_shift?.end_time

  useScheduledCallback({
    time: endingShiftTime ? moment(endingShiftTime).add(10, `s`) : undefined,
    callback: refetch,
  })

  const isDraft = createInfo?.handover_status === `draft`
  const isSubmitted = createInfo?.handover_status === `submitted`
  const noHandoverShifts = createInfo?.handover_shifts === null
  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
      <Grid item className={shiftHandoverPopoverButtonClass} style={{ maxWidth: 300 }}>
        <ShiftModuleButton
          disabled={isDraft || isSubmitted || noHandoverShifts || createInfoStatus !== `success`}
          createInfo={createInfo}
        />
      </Grid>
      {isSubmitted && (
        <Grid item container alignItems="flex-end" justifyContent="center" direction="column">
          <Typography variant="body1" style={{ fontSize: 12, fontStyle: `italic`, paddingTop: 10 }}>
            {t(`A handover for this shift has already been submitted`)}
          </Typography>
          {createInfo?.handover_shifts && (
            <Typography variant="body1" style={{ fontSize: 12, fontStyle: `italic`, paddingTop: 5 }}>
              {t(`Next handover can be submitted from:`)}
              {` ` + moment(createInfo.handover_shifts.ending_shift.end_time).format(`HH:mm`)}
            </Typography>
          )}
        </Grid>
      )}
      {isDraft && (
        <Grid item container alignItems="flex-end" justifyContent="center" direction="column">
          <InfoBanner
            text={t(
              `There is already a handover draft. Please open the draft in the table below to continue with the handover`
            )}
          />
        </Grid>
      )}
    </Grid>
  )
}
