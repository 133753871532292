import { hideTooltipInGroup, showTooltipInGroup } from 'store/chart/chart'

import ChartJS from 'chart.js/auto'

import { chartColors } from '../chartTypes'

type CrosairPlugin = {
  x: number
  y: number
  draw?: boolean
}

export type CrosairOptions = {
  enabled?: boolean
  width?: number
  dash?: number[]
  color?: string
}

type ChartWithCrossair = ChartJS & {
  corsair: CrosairPlugin
}

export default {
  id: 'corsair',
  afterInit: (chart: ChartWithCrossair) => {
    chart.corsair = {
      x: 0,
      y: 0,
    }
  },
  afterEvent: (chart: ChartWithCrossair, evt: any) => {
    const top = chart.chartArea.top
    const bottom = chart.chartArea.bottom
    const left = chart.chartArea.left
    const right = chart.chartArea.right
    const x = evt.event.x as number
    const y = evt.event.y as number

    if (x < left || x > right || y < top || y > bottom) {
      chart.corsair = {
        x,
        y,
        draw: false,
      }
      chart.draw()
      return
    }

    chart.corsair = {
      x,
      y,
      draw: true,
    }

    chart.draw()
  },
  afterDatasetsDraw: (chart: ChartWithCrossair, _: any, opts: CrosairOptions) => {
    const ctx = chart.ctx
    const top = chart.chartArea.top
    const bottom = chart.chartArea.bottom
    const { x, draw } = chart.corsair ?? { x: 0, draw: false}

    const index = chart.tooltip?.getActiveElements()?.[0]?.index
    if (index === undefined) {
      return
    }

    if (!draw) {
      hideTooltipInGroup(chart.id)
      return
    }

    try {
      showTooltipInGroup(chart.id, index)
    } catch (err: any) {
      // Ignore internal chart plugin error
    }

    ctx.lineWidth = opts.width || 0
    ctx.setLineDash(opts.dash || [])
    ctx.strokeStyle = opts.color || chartColors.crosshair

    ctx.save()
    ctx.beginPath()
    ctx.moveTo(x, bottom)
    ctx.lineTo(x, top)
    ctx.stroke()
    ctx.restore()
  },
}
