import { ReactElement, useRef, useState, useEffect, useMemo } from 'react'

import { chartStore, generateNewGroupId, registerChart, unregisterChart, setItemsToGroupId } from 'store/chart/chart'
import globalStore from 'store/global/global'
import { resetZoom } from 'store/zoom/zoom'
import { dynamicClassName } from 'styles/helper'
import HeightAdjuster from 'ui/atoms/HeightAdjuster/HeightAdjuster'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ExportDatasetsMenu from 'ui/components/ExportDatasetsMenu/ExportDatasetsMenu'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'
import {
  registerUiConfigComponent,
  registerAppendPropsWithDatasets,
  registerDefaultProps,
  registerComponentHeight,
} from 'ui/uiConfig/factory'
import sentry from 'utils/sentry/sentry'

import { Icon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import DatasetTable, { DatasetTableItem } from '../DatasetTable/DatasetTable'
import { getDatasetsInItems, getReturnIdsFromDatasetInstruction } from 'helpers/dataset.helper/dataset.helper'
import { clone } from 'helpers/global.helper/global.helper'

import styles from './Chart.module.less'
import ChartLegend from './ChartLegend/ChartLegend'
import FilterButtons from './FilterButtons/FilterButtons'
import { DEFAULT_CONFIG, DEFAULT_HEIGHT } from './chart.constants'
import {
  getConfig,
  downloadChartAsImage,
  printChart,
  setEmptyYAxisToHidden,
  setChartDatasetVisibility,
  filterVisibleItemsInChart,
  serializeChartConfig,
  withMaintainAspectRatioFix,
} from './chart.helper'
import ReactChartJS2 from './chart.init'
import { ChartConfigWithZoom, ChartProps, ChartJS } from './chartTypes'
import { updateChartConfigWithYAxisLimits } from './helpers/syncYAxisPlugin'

const defaultProps: Omit<ChartProps, `datasets` | `children`> = {
  base_items: {
    all: false,
  },
  items: [
    {
      title: ``,
      data_id: ``,
      color: `$color-1`,
      unit: ``,
      fill: false,
      dashed: false,
      order: 0,
      tooltip_do_not_show_zero_values: true,
      stack_group: undefined,
      decimals: 1,
      data_type: ``,
      y_axis_id: `y`,
    },
  ],
  config: DEFAULT_CONFIG,
  dataset_table: { default: false, display: false, show_only_value_in_future: true, initial_page_size: 25 },
  title: ``,
}

function appendPropsWithDataset(datasetInstruction: DatasetInstruction, previousProps: ChartProps): ChartProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId.split(`.`).pop(),
    data_id: returnId,
    color: `$color-1`,
    unit: ``,
    fill: false,
    dashed: false,
    tooltip_do_not_show_zero_values: true,
    order: 0,
    stack_group: undefined,
    decimals: 1,
    data_type: ``,
    y_axis_id: `y`,
  }))

  return {
    ...previousProps,
    items: [...(previousProps?.items || []), ...newItems],
    config: {
      ...previousProps?.config,
    },
  }
}
registerUiConfigComponent('chart', Chart)
registerAppendPropsWithDatasets('chart', appendPropsWithDataset)
registerDefaultProps('chart', defaultProps)
registerComponentHeight('chart', DEFAULT_HEIGHT)

export default function Chart({
  uid,
  items,
  config,
  datasets,
  title,
  filter_items,
  dataset_table,
  height: defaultHeight,
  hide_legend,
  maxWidth,
  digitalTwinTranslations,
  settingInfo,
}: ChartProps): ReactElement {
  const { t } = useTranslation()
  const chartSnap = useSnapshot(chartStore)
  const globalSnap = useSnapshot(globalStore)
  const [chart, setChart] = useState<ChartJS>()
  const [showDatasetTableUnderChart, setShowDatasetTableUnderChart] = useState<boolean>(
    (dataset_table?.default && dataset_table?.display) ?? false
  )
  const [showLegend, setShowLegend] = useState(true)
  const [legendClass, setLegendClass] = useState<string>(`${styles.Chart_TestLegend}`)
  const [hideExportButton, setHideExportButton] = useState(false)
  const serializedChartConfig = serializeChartConfig(clone(config || DEFAULT_CONFIG))
  const [chartConfig, setChartConfig] = useState<ChartConfigWithZoom>(withMaintainAspectRatioFix(serializedChartConfig))

  const [height, setHeight] = useState(defaultHeight || DEFAULT_HEIGHT)
  const chartRef = useRef(null)
  const [chartIsEmpty, setChartIsEmpty] = useState(false)

  const { systemId, user } = useAuth()

  const bodyWidth = globalSnap.bodyWidth

  if (hide_legend && config?.options?.plugins?.chartLegend?.display) {
    config.options.plugins.chartLegend.display = false
  }

  const groupId = useMemo(() => {
    if (!config?.options?.plugins?.chartLegend?.groupId || !config?.options?.plugins?.chartLegend?.shared) {
      return generateNewGroupId()
    }

    return config?.options?.plugins?.chartLegend?.groupId
  }, [config?.options?.plugins?.chartLegend?.groupId, config?.options?.plugins?.chartLegend?.shared])
  const { filteredItemsForDatasetTable, datasetsInItemsForExport, datasetNames } = useMemo(() => {
    const filteredItemsForDatasetTable = filter_items
      ? items.filter((item) => !chartSnap.groupIdHiddenReturnIds[groupId]?.has(item.data_id))
      :  items.filter((item) => !(item.show_only_in_chart || item.do_not_show_item))

    const itemsForExport = items.filter((item) => !item.do_not_show_item)
    const datasetsInItemsForExport = getDatasetsInItems(itemsForExport, datasets)
    const datasetNames = (itemsForExport).reduce((acc, item) => ({ ...acc, [item.data_id]: item.title }), {})

    return { filteredItemsForDatasetTable, datasetsInItemsForExport, datasetNames }
  }, [chartSnap.groupIdHiddenReturnIds, datasets, filter_items, groupId, items])

  useEffect(() => {
    const itemsToShowInChart = filterVisibleItemsInChart(items)

    let mergedConfig = getConfig(itemsToShowInChart, datasets, clone(config || DEFAULT_CONFIG), filter_items)

    mergedConfig = setChartDatasetVisibility(
      mergedConfig,
      (chartSnap.groupIdHiddenReturnIds[groupId] || new Set()) as Set<string>
    )

    mergedConfig = setEmptyYAxisToHidden(mergedConfig)

    if (mergedConfig.data.datasets.every((d) => d.hidden === true)) {
      setChartIsEmpty(true)
    } else {
      setChartIsEmpty(false)
    }

    mergedConfig = updateChartConfigWithYAxisLimits(mergedConfig, chartSnap.groupIdAxisLimits[groupId])

    setChartConfig(serializeChartConfig(mergedConfig))
    setShowLegend(mergedConfig.options.plugins?.chartLegend?.display !== false)
    setHideExportButton(mergedConfig.options.plugins?.exportButton?.display === false)
    setLegendClass(
      mergedConfig.options.plugins?.chartLegend?.display !== false ? `chartLegend ${styles.Chart_Legend}` : ``
    )
  }, [
    uid,
    datasets,
    items,
    config,
    filter_items,
    chartSnap.groupIdHiddenReturnIds,
    groupId,
    chartSnap.groupIdAxisLimits,
  ])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = chartRef.current as ChartJS
    chartStore.chartIdToGroupId[chart.id] = groupId

    setChart(chart)
    registerChart(groupId, chart)

    return () => {
      unregisterChart(groupId, chart)
    }
  }, [groupId, chartRef])

  useEffect(() => {
    setItemsToGroupId(groupId, filterVisibleItemsInChart(items), datasets)
  }, [groupId, items, datasets])

  useEffect(() => {
    if (chartIsEmpty) {
      sentry.captureException(new Error(`Chart [${uid}, ${title}] is empty. User: ${user?.id}, System: ${systemId}`))
    }
  }, [chartIsEmpty, uid, title, user, systemId])

  /*
  [2024-08-07] Niklas Andreasson:
  The upgrade to React 18 unveiled a bug in ChartJS where the chart stacking
  won't work consistently wihout an additional update after the datasets have been changed.
  Related to https://app-eu.wrike.com/open.htm?id=1467779737. This is intended as a
  temporary fix until the issue is resolved in ChartJS or we've provided the
  stacked scale option for all of our chart configs.
  */
  useEffect(() => {
    if (chart && chartConfig.data.datasets.length > 0) {
      chart.update()
    }
  }, [chart, chartConfig.data])

  return (
    <div className={styles.Chart} style={{ maxWidth: maxWidth ? maxWidth : `${bodyWidth - 30}px` }}>
      {title && (
        <TitleWithIcon title={title} size={`small`} marginTop marginBottom={showLegend} marginLeft padding={false} />
      )}

      {filter_items && <FilterButtons filterItems={filter_items ?? []} groupId={groupId} />}

      <div className={legendClass}>
        {showLegend && <ChartLegend groupId={groupId} digitalTwinTranslations={digitalTwinTranslations} settingInfo={settingInfo} />}

        <div className={styles.Chart_Legend_ExportAndZoom__right}>
          <TooltipIconButton
            tooltip={t('Increase chart height')}
            onClick={() => {
              setHeight(height + 50)
            }}
            icon={<Icon color="primary" className="far fa-plus" fontSize="small" />}
          />
          <TooltipIconButton
            tooltip={t('Decrease chart height')}
            onClick={() => {
              if (height < 200) {
                return
              }

              setHeight(height - 50)
            }}
            icon={<Icon color="primary" className="far fa-minus" fontSize="small" />}
          />

          {!hideExportButton && (
            <ExportDatasetsMenu
              datasets={datasetsInItemsForExport}
              datasetNames={datasetNames}
              onImageExport={() => chart && downloadChartAsImage(chart)}
              onPrint={() => chart && printChart(chart)}
            />
          )}

          {dataset_table?.display && (
            <TooltipIconButton
              tooltip={
                showDatasetTableUnderChart
                  ? t('Hide production plan values in table')
                  : t(`Show production plan values in table`)
              }
              onClick={() => setShowDatasetTableUnderChart(!showDatasetTableUnderChart)}
              icon={
                <Icon
                  color="primary"
                  className={
                    showDatasetTableUnderChart
                      ? styles.ExportDatasetsMenu_icon + ` far fa-th-list`
                      : styles.ExportDatasetsMenu_icon + ` fas fa-th-list`
                  }
                  fontSize="small"
                />
              }
            />
          )}

          {showLegend && (
            <TooltipIconButton
              tooltip={t(`Reset zoom`)}
              onClick={() => resetZoom()}
              icon={
                <Icon
                  color="primary"
                  className={`${styles.ExportDatasetsMenu_icon} fas fa-search-minus`}
                  fontSize="small"
                />
              }
            />
          )}
        </div>
      </div>

      <div
        className={dynamicClassName({
          [styles.Chart_Canvas]: true,
          [styles.Chart_Canvas__empty]: chartIsEmpty,
        })}
        style={{ height }}
      >
        <ReactChartJS2
          ref={chartRef}
          type={chartConfig.type || 'line'}
          data={chartConfig.data || { datasets: [] }}
          options={chartConfig.options || {}}
          plugins={chartConfig.plugins || []}
        />
        {chartIsEmpty && (
          <Typography variant="caption" className={styles.Chart_MissingData}>
            {t('No records to display')}
          </Typography>
        )}
      </div>

      {dataset_table && showDatasetTableUnderChart && (
        <div className={styles.Chart_Table}>
          <div className={styles.Chart_Table__table}>
            <DatasetTable
              items={filteredItemsForDatasetTable as DatasetTableItem[]}
              datasets={datasets}
              children={undefined}
              title={dataset_table.title ?? ''}
              do_not_show_zero_or_null_values_in_table={dataset_table.do_not_show_zero_or_null_values_in_table ?? false}
              do_not_show_download_button={dataset_table.do_not_show_download_button ?? true}
              do_not_show_sum_or_average_row={dataset_table.do_not_show_sum_or_average_row ?? false}
              do_not_show_min_max_row={dataset_table.do_not_show_min_max_row ?? false}
              initial_page_size={dataset_table.initial_page_size ?? 24}
              show_only_value_in_future={dataset_table.show_only_value_in_future ?? false}
              do_not_show_top_toolbar={dataset_table.do_not_show_top_toolbar ?? false}
              use_column_color_from_items={dataset_table.use_column_color_from_items ?? true}
              show_initial_page_with_current_day={dataset_table.show_initial_page_with_current_day ?? false}
              show_initial_page_with_current_optimization_hour={dataset_table.show_initial_page_with_current_optimization_hour ?? true}
              sorting_function={dataset_table.sorting_function ?? 'chart_items'}
              footer_calculates_per_page={dataset_table.footer_calculates_per_page ?? false}
              sticky_table_header={dataset_table.sticky_table_header ?? true}
              enableColumnActions={false}
            />
          </div>
        </div>
      )}

      <HeightAdjuster height={height} minHeight={200} maxHeight={1000} onChange={setHeight} />
    </div>
  )
}
