import React, { ReactElement, useState } from 'react'

import { Thread, useBulletinBoardThreads } from 'api/bulletinBoard/bulletinBoard.api'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Grid, Divider, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import NoPermissionErrorMessage from '../ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import { useHasPermission } from 'helpers/global.helper/global.helper'

import styles from './BulletinBoard.module.less'
import NewThread from './components/NewThread/NewThread'
import BulletinBoardThread from './components/Thread'

type BulletinBoardContentProps = {
  threads: Thread[]
  hasNextPage: boolean
  fetchNextPage: () => void
  isFetchingNextPage: boolean
}

function BulletinBoardContent({
  threads,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: BulletinBoardContentProps): ReactElement {
  const { t } = useTranslation()

  const hasViewCommentsAccess = useHasPermission('view_comments')
  const hasChangeCommentsAccess = useHasPermission('change_comments')

  const [filterOnUserPosts, setFilterOnUserPosts] = useState<boolean>(false)

  const changeFilterButton = (): void => {
    setFilterOnUserPosts((prev) => !prev)
  }

  threads = filterOnUserPosts ? threads?.filter((p) => p.creator_full_name !== null) : threads

  return (
    <>
      {!hasChangeCommentsAccess && (
        <NoPermissionErrorMessage
          title={t(`Unfortunately, you do not have permission to create a post or comment here`)}
          message={t(`Unfortunately, you do not have permission to change posts and comments on this user/facility`)}
        />
      )}

      <NewThread threads={threads} onFilterClick={changeFilterButton} filterOnUserPosts={filterOnUserPosts} />

      {threads?.length > 0 ? (
        <>
          {threads.map((thread) => (
            <Grid container item key={thread.id} className={styles.BulletinBoard_ThreadBox} direction="column">
              <BulletinBoardThread thread={thread} />
              <Divider className={styles.BulletinBoard_Divider} />
            </Grid>
          ))}
        </>
      ) : (
        t(`No messages to display`)
      )}

      {!hasViewCommentsAccess && (
        <NoPermissionErrorMessage
          title={t(`Unfortunately, you do not have permission to view posts and comments`)}
          message={t(`Unfortunately, you do not have permission to view posts and comments on this user/facility`)}
        />
      )}

      {hasNextPage ? (
        <Grid container justifyContent="center">
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <ContainedIconButton
              onClick={fetchNextPage}
              label={t(`Fetch earlier posts`)}
              icon="fal fa-arrow-alt-to-bottom"
              aria-label={t(`Fetch earlier posts`)}
              color="primary"
              disabled={!hasViewCommentsAccess ? true : false}
            />
          )}
        </Grid>
      ) : null}
    </>
  )
}

function BulletinBoard(): ReactElement {
  const { data: threadsResponse, status, hasNextPage, fetchNextPage, isFetchingNextPage } = useBulletinBoardThreads()

  const threads = threadsResponse?.pages?.flatMap((list) => list.results)

  return (
    <StatusComponent
      data={{ threads }}
      status={[status]}
      Component={BulletinBoardContent}
      ComponentProps={{ hasNextPage: hasNextPage ?? false, isFetchingNextPage, fetchNextPage }}
    />
  )
}

export default BulletinBoard
