import { apiClient } from 'api/apiClient/apiClient'
import { Setting } from 'api/settings/settings.api'
import { ShiftHandoverCommentGroup } from 'api/shiftHandoverCommentGroups/shiftHandoverCommentGroups.api'
import { useShiftTeams } from 'api/shiftTeams/shiftTeams.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Serie } from 'ui/components/BaseChart/types'

import moment from 'moment'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { roundToNearestHour } from 'helpers/dateTime.helper/dateTime.helper'

export type EvaluationStatus = `very_good` | `good` | `bad` | `very_bad` | `undefined`

export type Comment = {
  id?: string
  group?: number
  content: string
  is_reported: boolean
  is_pinned: boolean
}

export type ShiftHandover = {
  id: number
  creator_name: string
  submitter_name: string | null
  ending_shift_team: number
  starting_shift_team: number
  representatives_present: boolean
  start_time: string
  end_time: string
  handover_time: string
  evaluation_status: EvaluationStatus
  system: number
  created_at: string
  submitted_at: string | null
}

export type ShiftHandoverCreateParams = {
  id?: number
  ending_shift_team: number
  starting_shift_team: number
  representatives_present: boolean
  evaluation_status?: EvaluationStatus
  comments: Comment[]
  start_time: string
  end_time: string
  handover_time: string
  system?: number
  submit?: boolean
}

export type ShiftHandoverFormDefaultValues = {
  id?: number
  ending_shift_team: number
  starting_shift_team: number
  representatives_present: boolean
  evaluation_status?: EvaluationStatus
  comment_groups: {
    id: number
    system: number
    units: number[]
    display_name?: string
    comments: {
      id?: string
      group?: number
      content: string
      is_reported: boolean
      is_pinned: boolean
    }[]
  }[]
  start_time: string
  end_time: string
  handover_time: string
}

export type ShiftHandoverFormData = Omit<ShiftHandoverCreateParams, `comments`> & {
  comment_groups: (ShiftHandoverCommentGroup & { comments: Comment[] })[]
}

export type ShiftHandoverDetailsResponse = {
  handover_time: string
  start_time: string
  end_time: string
  comments: Comment[]
  settings: Setting[]
  series: Record<number, Serie[]> | null
}

type CountType = {
  count: number
}

export type Shift = {
  start_time: string
  end_time: string
  name: string
  team: string
}

export type ShiftHandoverCreateInfo = {
  handover_status: `draft` | `submitted`
  handover_shifts: { ending_shift: Shift; starting_shift: Shift } | null
  pinned_comments: Comment[]
}

export type ShiftHandoverStats = {
  evaluation_status_count: (CountType & { evaluation_status: EvaluationStatus })[]
  number_of_handovers: (CountType & { shift_team: string })[]
  number_of_points: ResultTimeObject[]
}

const SHIFT_HANDOVERS_QUERY_KEY = `shiftHandovers`
export function useShiftHandovers(period?: Period) {
  const { systemId } = useAuth()

  const params = {
    system: systemId,
    handover_time__lte: period?.endTime.endOf(`d`).format(),
    handover_time__gte: period?.startTime.startOf(`d`).format(),
  }
  return useQuery(
    [SHIFT_HANDOVERS_QUERY_KEY, params],
    () =>
      apiClient<ShiftHandover[]>(`shift_handovers`, {
        params,
      }),
    {
      enabled: !!systemId && !!period,
    }
  )
}

export function useShiftHandoversCreateInfo() {
  const { data: shiftTeams } = useShiftTeams()

  const { systemId } = useAuth()
  const queryClient = useQueryClient()

  const params = { system: systemId }
  return useQuery(
    [SHIFT_HANDOVERS_QUERY_KEY, `createInfo`, params],
    () =>
      apiClient<ShiftHandoverCreateInfo>(`shift_handovers/create_info`, { params }).then((data) => {
        return {
          ...data,
          handover_shifts: data.handover_shifts
            ? {
              starting_shift: {
                ...data.handover_shifts?.starting_shift,
                team: String(shiftTeams?.find((item) => item.name === data.handover_shifts?.starting_shift.team)?.id),
              },
              ending_shift: {
                ...data.handover_shifts?.ending_shift,
                team: String(shiftTeams?.find((item) => item.name === data.handover_shifts?.ending_shift.team)?.id),
              },
            }
            : null,
        }
      }),
    {
      enabled: !!systemId && !!shiftTeams,
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === SHIFT_HANDOVERS_QUERY_KEY && query.queryKey[1] !== `createInfo`,
        })
      },
    }
  )
}

export function useShiftHandoverMutation() {
  const postShiftHandover = (data: ShiftHandoverCreateParams) => apiClient(`shift_handovers`, { data })

  const patchShiftHandover = (id: number, data: ShiftHandoverCreateParams) =>
    apiClient(`shift_handovers/${id}`, { method: `PATCH`, data })

  const postOrPatchShiftHandover = ({ data, id }: { data: ShiftHandoverCreateParams; id?: number }) => {
    const formattedData = {
      ...data,
      start_time: moment(data.start_time).format(),
      end_time: moment(data.end_time).format(),
      handover_time: moment(data.handover_time).format(),
    }

    if (id) return patchShiftHandover(id, formattedData)
    return postShiftHandover(formattedData)
  }

  const queryClient = useQueryClient()

  return useMutation(postOrPatchShiftHandover, {
    onSuccess: () => {
      queryClient.invalidateQueries(SHIFT_HANDOVERS_QUERY_KEY)
    },
  })
}

const SHIFT_HANDOVERS_STATS_QUERY_KEY = `shiftHandoversStats`
export function useShiftHandoversStats(period: Period) {
  const { systemId } = useAuth()

  const params = { system: systemId, start_date: period.startTime.format(), end_date: period.endTime.format() }

  return useQuery<ShiftHandoverStats>(
    [SHIFT_HANDOVERS_STATS_QUERY_KEY, params],
    () => apiClient<ShiftHandoverStats>(`shift_handovers/stats`, { params }),
    {
      enabled: !!systemId && period !== undefined,
    }
  )
}

const SHIFT_HANDOVER_DETAILS_QUERY_KEY = `shiftHandoverDetails`
export function useShiftHandoverDetails(id?: number) {
  return useQuery(
    [SHIFT_HANDOVER_DETAILS_QUERY_KEY, id],
    () =>
      apiClient<ShiftHandoverDetailsResponse>(`shift_handovers/${id}/details`).then(
        (details: ShiftHandoverDetailsResponse): ShiftHandoverDetailsResponse => {
          const { start_time, handover_time, end_time, ...rest } = details
          const hourlyStartTime = roundToNearestHour(start_time)
          const hourlyHandoverTime = roundToNearestHour(handover_time)
          const hourlyEndTime = roundToNearestHour(end_time)

          return {
            ...rest,
            handover_time: hourlyHandoverTime.format(),
            start_time: hourlyStartTime.format(),
            end_time: hourlyEndTime.format(),
          }
        }
      ),
    {
      staleTime: 1000,
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  )
}
