import React from 'react'

import { useUploadOpusFile } from 'api/systems/systems.api'
import FileUpload from 'ui/components/FileUpload/FileUpload'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'

import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'

export default function OpusUploadView(): React.ReactElement {
  const { t } = useTranslation()
  const hasUploadAccess = useHasPermission('view_opus_upload')
  const uploadOpusFile = useUploadOpusFile()

  return (
    <View header={<ModuleHeader infoHeader={t('Upload OPUS file')} />}>
      {hasUploadAccess ? (
        <FileUpload onFileUpload={uploadOpusFile} />
      ) : (
        <div>{t('Unfortunately, you do not have permission to make changes here')}</div>
      )}
    </View>
  )
}
