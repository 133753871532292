import React, { ReactElement } from 'react'

import styles from './ProgressBar.module.less'

export type ProgressBarItem = {
  title: string
  value: number
  color: string
  backgroundColor: string
}

type ProgressBarProps = {
  items: ProgressBarItem[]
  total?: number
  showText?: boolean
  backgroundColor?: string
}

export default function ProgressBar({
  items,
  total,
  showText = true,
  backgroundColor,
}: ProgressBarProps): ReactElement {
  const totalValue = total ?? items.reduce((acc, item) => acc + item.value, 0)
  let style: Record<string, string> = {}

  if (backgroundColor) {
    style = { ...style, backgroundColor }
  }

  return (
    <div className={styles.ProgressBar} style={style}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`animation ${styles.ProgressBar_Item}`}
          style={{
            backgroundColor: item.backgroundColor,
            color: item.color,
            width: `${(item.value / totalValue) * 100}%`,
            display: item.value === 0 ? `none` : `inline-block`,
          }}
        >
          {showText && `${item.value} ${item.title}`}
        </div>
      ))}
    </div>
  )
}
