import React, { ReactElement } from 'react'

import { IconButton, Icon } from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { useTranslation } from 'react-i18next'

import styles from './TablePaginationActions.module.less'

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps): ReactElement {
  const { t } = useTranslation()

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const previousPageDisabled = page === 0
  const nextPageDisabled = page >= Math.ceil(count / rowsPerPage) - 1

  return (
    <div className={styles.TablePaginationActions_Root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={previousPageDisabled}
        aria-label={t(`First page`)}
        size="large">
        <Icon className="fas fa-arrow-to-left" />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={previousPageDisabled}
        aria-label={t(`Previous page`)}
        size="large">
        <Icon className="fas fa-arrow-left" />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={nextPageDisabled}
        aria-label={t(`Next page`)}
        size="large">
        <Icon className="fas fa-arrow-right" />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={nextPageDisabled}
        aria-label={t(`Last page`)}
        size="large">
        <Icon className="fas fa-arrow-to-right" />
      </IconButton>
    </div>
  )
}

export default TablePaginationActions
