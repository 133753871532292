import React, { ReactElement, useEffect } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import { Button } from 'ui/atoms'
import { ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import UiConfigColorInput from '../UiConfigColorInput/UiConfigColorInput'
import { clone } from 'helpers/global.helper/global.helper'

import styles from './UiConfigItemTable.module.less'

type UiConfigItemTableProps = {
  items: ChartItem[]
  uid: number
}

export default function UiConfigItemTable({ items, uid }: UiConfigItemTableProps): ReactElement {
  const [previewItems, setPreviewItems] = React.useState(items)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const { t } = useTranslation()


  useEffect(() => {
    const items = uiConfigSnap.previewCollection[uid]?.props?.items

    if (items) {
      setPreviewItems(items)
    }
  }, [uid, uiConfigSnap.previewCollection])

  function preview({ index, value, key }: { index: number; value: string | number | boolean; key: string }): void {
    const newItems = clone(previewItems)
    newItems[index][key] = value
    setPreviewItems(newItems)

    uiConfigStore.previewCollection = {
      ...uiConfigSnap.previewCollection,
      [uid]: {
        ...(uiConfigSnap.previewCollection[uid] || {}),
        props: {
          ...(uiConfigSnap.previewCollection[uid]?.props || {}),
          items: newItems,
        },
      },
    }
  }

  function removeItem(index: number): void {
    const newItems = clone(previewItems)
    newItems.splice(index, 1)
    setPreviewItems(newItems)
    uiConfigStore.previewCollection = {
      ...uiConfigSnap.previewCollection,
      [uid]: {
        ...(uiConfigSnap.previewCollection[uid] || {}),
        props: {
          ...(uiConfigSnap.previewCollection[uid]?.props || {}),
          items: newItems,
        },
      },
    }
  }

  return (
    <div className={styles.UiConfigItemTable}>
      <table>
        <thead>
          <tr>
            <th>title</th>
            <th>color</th>
            <th>fill</th>
            <th>data_id/return_id</th>
            <th>data_type</th>
            <th>order</th>
            <th>y_axis_id</th>
            <th>stack_group</th>
            <th>is_peak_unit</th>
            <th>h_energy_bar</th>
            <th>tag</th>
            <th>{' '}</th>
          </tr>
        </thead>
        <tbody>
          {previewItems.map((item, index) => (
            <tr key={`${index}_${item.data_id}`}>
              <td className={styles.UiConfigItemTable_Cell__wide}>
                <input
                  aria-label="title"
                  defaultValue={item.title}
                  onBlur={(ev) => {
                    preview({ index, value: ev.target.value, key: `title` })
                  }}
                />
              </td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td>
                <UiConfigColorInput
                  onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                    preview({ index, value: ev.target.value, key: `color` })
                  }}
                  color={item.color}/>
              </td>
              <td>
                <input
                  aria-label="fill"
                  type="checkbox"
                  checked={!!item.fill}
                  onChange={(_ev) => {
                    preview({ index, value: !item.fill, key: `fill` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="data_id"
                  defaultValue={item.data_id}
                  onBlur={(ev) => {
                    preview({ index, value: ev.target.value, key: `data_id` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="data_type"
                  defaultValue={item.data_type}
                  onBlur={(ev) => {
                    preview({ index, value: ev.target.value, key: `data_type` })
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  aria-label="order"
                  defaultValue={item.order}
                  onChange={(ev) => {
                    preview({ index, value: ev.target.value, key: `order` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="y_axis_id"
                  defaultValue={item.y_axis_id}
                  onBlur={(ev) => {
                    preview({ index, value: ev.target.value, key: `y_axis_id` })
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  aria-label="stack_group"
                  defaultValue={item.stack_group}
                  onChange={(ev) => {
                    preview({ index, value: ev.target.value, key: `stack_group` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="is_peak_unit"
                  type="checkbox"
                  checked={!!item.is_peak_unit}
                  onChange={(_ev) => {
                    preview({ index, value: !item.is_peak_unit, key: `is_peak_unit` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="h_energy_bar"
                  type="checkbox"
                  checked={!!item.h_energy_bar}
                  onChange={(_ev) => {
                    preview({ index, value: !item.h_energy_bar, key: `h_energy_bar` })
                  }}
                />
              </td>
              <td>
                <input
                  aria-label="y_axis_id"
                  defaultValue={item.tag ?? ''}
                  onBlur={(ev) => {
                    preview({ index, value: ev.target.value, key: `tag` })
                  }}
                />
              </td>
              <td className={styles.UiConfigItemTable_Cell__remove}>
                <Button
                  link
                  danger
                  noPadding
                  onClick={() => {
                    removeItem(index)
                  }}
                >
                  {t(`Delete`)}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
