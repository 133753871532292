import { ReactElement } from 'react'

import { useOutdatedPropertiesWithSettings } from 'api/digitalTwin/digitalTwin.api'
import authStore from 'store/auth/auth'
import { Button } from 'ui/atoms'

import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './OrphanedSettingsInfo.module.less'

type OrphanedSettingsInfoProps = {
  onClick?: () => void
}

export default function OrphanedSettingsInfo({ onClick }: OrphanedSettingsInfoProps): ReactElement | null {
  const authSnap = useSnapshot(authStore)
  const primaryDigitalTwinUid = authSnap.activeSystem?.primary_digital_twin?.uid

  const { t } = useTranslation()

  const { data: outdatedPropertiesWithSettings, status } = useOutdatedPropertiesWithSettings(primaryDigitalTwinUid)

  const totalMissingSettings = outdatedPropertiesWithSettings?.reduce((acc, curr) => acc + curr.count, 0)

  if (status === 'loading') {
    return (
      <div className={styles.OrphanedSettingsInfo}>
        <CircularProgress size={24} color="warning" />
      </div>
    )
  }

  if (totalMissingSettings === undefined || totalMissingSettings === 0) {
    return null
  }

  return (
    <div className={styles.OrphanedSettingsInfo}>
      <Button
        onClick={onClick}
        icon="fas fa-exclamation-triangle"
        marginLeft
        warning
      >{`${t('Primary model has {{count}} setting with missing properties', { count: totalMissingSettings })}`}</Button>
    </div>
  )
}
