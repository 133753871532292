import React, { ReactElement } from 'react'

import { ShiftTeam } from 'api/shiftTeams/shiftTeams.api'
import { useWorkShifts, useShiftSchedule, WorkShift } from 'api/workShifts/workShifts.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'

import { Grid, Table, TableHead, TableCell, TableBody, TableRow, Typography } from '@mui/material'
import classNames from 'classnames'
import moment, { Moment } from 'moment'

import ShiftScheduleContent from '../ShiftScheduleContent/ShiftScheduleContent'
import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './ShiftInfoCalender.module.less'

type CalendarWrapperProps = {
  currentDate: Moment
  setCurrentDate: (date: Moment) => void
  shiftTeams: ShiftTeam[]
  workShifts: WorkShift[]
}

function CalendarWrapper({ currentDate, setCurrentDate, shiftTeams, workShifts }: CalendarWrapperProps): ReactElement {
  const start = moment(currentDate)
  const end = moment(start).endOf(`week`)

  const { data: shiftSchedule, status: shiftScheduleStatus } = useShiftSchedule(start, end)

  const week: Moment[] = []
  const dateToLoop = currentDate.clone()
  for (let i = 0; i < 7; i++) {
    week.push(dateToLoop.clone())
    dateToLoop.add(1, `day`)
  }

  return (
    <Grid container direction="column">
      <Grid container item direction="row" justifyContent="center" alignItems="center">
        <ArrowDatePicker
          value={currentDate}
          prevButtonTooltip='Previous week'
          nextButtonTooltip='Following week'
          format="MMM YYYY [V]w"
          visualSelection='week'
          showWeekNumbers
          onChange={(date) => setCurrentDate(moment(date).startOf(`week`))}
          step={{ unit: `week`, amount: 1 }}
        />
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            {week.map((day, index) => {
              return (
                <TableCell
                  className={classNames({
                    [styles.ShiftInfoCalender_Highlight]: moment().isSame(day, `day`),
                  })}
                  key={index}
                >
                  <Grid container direction="column" alignItems="center">
                    <Grid item>{day.format(`dddd`)}</Grid>
                    <Grid item className={styles.ShiftInfoCalender_DateNumber}>
                      {day.format(`D`)}
                    </Grid>
                  </Grid>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <StatusComponent
            status={shiftScheduleStatus}
            data={{ shiftSchedule }}
            LoadingFallback={
              <TableRow>
                <TableCell colSpan={7}>
                  <LoadingPlaceholderContainer />
                </TableCell>
              </TableRow>
            }
            Component={ShiftScheduleContent}
            ComponentProps={{ workShifts, shiftTeams }}
          />
        </TableBody>
      </Table>

      <Grid container item direction="row" spacing={2} justifyContent="center" className={styles.ShiftInfoCalender_WorkShiftTimeBoxes}>
        {workShifts.map((shift) => {
          return (
            <Grid item className={styles.ShiftInfoCalender_WorkShiftTimeBox} key={shift.name}>
              <Typography variant="body1">
                {getDisplayName(shift) +
                  `: ` +
                  shift.start_time.slice(0, shift.start_time.lastIndexOf(`:`)) +
                  ` - ` +
                  shift.end_time.slice(0, shift.end_time.lastIndexOf(`:`))}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

type ShiftInfoCalendarProps = {
  currentDate: Moment
  setCurrentDate: (date: Moment) => void
  shiftTeams: ShiftTeam[]
}

function ShiftInfoCalendar(props: ShiftInfoCalendarProps): ReactElement {
  const { data: workShifts, status: workShiftsStatus } = useWorkShifts()

  return (
    <StatusComponent
      status={workShiftsStatus}
      data={{ workShifts }}
      Component={CalendarWrapper}
      ComponentProps={{ ...props }}
    />
  )
}

export default ShiftInfoCalendar
