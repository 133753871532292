import 'ui/uiConfig/anchorComponents/AbSLabs/AbSLabs'
import 'ui/uiConfig/anchorComponents/FollowUpProdKpi/FollowUpProdKpi'
import 'ui/uiConfig/anchorComponents/FollowupProduction/FollowupProduction'
import 'ui/uiConfig/anchorComponents/ReviewForecasts/ReviewForecasts'
import 'ui/uiConfig/anchorComponents/RealTimeOptimizationBars/RealTimeOptimizationBars'
import 'ui/uiConfig/anchorComponents/ElplanBids/ElplanBids'
import 'ui/uiConfig/components/BaseConfig/BaseConfig'
import 'ui/uiConfig/components/CardWidget/CardWidget'
import 'ui/uiConfig/components/Chart/Chart'
import 'ui/uiConfig/components/Container/Container'
import 'ui/uiConfig/components/DatasetTable/DatasetTable'
import 'ui/uiConfig/components/ExcelButton/ExcelButton'
import 'ui/uiConfig/components/HorizontalBars/HorizontalBars'
import 'ui/uiConfig/components/KpiValue/KpiValue'
import 'ui/uiConfig/components/DoubleKpiValue/DoubleKpiValue'
import 'ui/uiConfig/components/Table/Table'
import 'ui/uiConfig/components/Tabs/Tabs'
import 'ui/uiConfig/anchorComponents/DefaultComponent/DefaultComponent'
import 'ui/uiConfig/anchorComponents/Availability/Availability'
import 'ui/uiConfig/anchorComponents/ReferenceGuides/ReferenceGuides'
import 'ui/uiConfig/anchorComponents/FuelPlan/FuelPlan'
import 'ui/uiConfig/components/PieChart/PieChart'
import 'ui/uiConfig/components/GroupingTable/GroupingTable'
import 'ui/uiConfig/anchorComponents/UnitAvailability/UnitAvailability'

import { TIME_SERIES_VISUALIZATION } from 'ui/components/DigitalTwinSettingForm/constants'
import { registerUiConfigComponentAsOtherComponent } from 'ui/uiConfig/factory'

import i18n from 'i18next'


// Optimize production plan module
registerUiConfigComponentAsOtherComponent('optimize_production_plan', 'card_widget', {
  isSingletonComponent: true,
  title: 'Optimization result',
})
registerUiConfigComponentAsOtherComponent('production_plan_forecast', 'card_widget', {
  isSingletonComponent: true,
  title: 'Optimization forecasts',
})
registerUiConfigComponentAsOtherComponent('production_plan_table', 'card_widget', {
  isSingletonComponent: true,
  title: 'Optimization result - table',
})

// Follow-up modules
registerUiConfigComponentAsOtherComponent('followup_forecast', 'card_widget', {
  isSingletonComponent: true,
  title: 'Follow-up forecast',
  height: 300,
})

// Sandbox module
registerUiConfigComponentAsOtherComponent('sandbox_forecast', 'chart', {
  isSingletonComponent: true,
  height: 300,
})
registerUiConfigComponentAsOtherComponent('sandbox_production_plan', 'card_widget', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('marginal_cost_chart', 'chart', {
  isSingletonComponent: true,
})
registerUiConfigComponentAsOtherComponent('marginal_cost_kpi', 'kpi_value', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('optimize_view', 'default_component', {
  isSingletonComponent: true,
})

// Other modules
registerUiConfigComponentAsOtherComponent(`settlement_view`, `container`, {
  isSingletonComponent: true,
  title: i18n.t(`Settlements`),
})
registerUiConfigComponentAsOtherComponent(`samenergi_excel_button`, `excel_button`, {
  isSingletonComponent: true,
  title: i18n.t('SamEnergi Export Excel Button'),
})
registerUiConfigComponentAsOtherComponent('shift_handover_chart', 'card_widget', {
  isSingletonComponent: true,
  height: 300,
})

registerUiConfigComponentAsOtherComponent('electricity_bid_management', 'container', {
  isSingletonComponent: true,
})

// This anchor component is not used in a UiConfig or UiConfigComponent, because we display the data together with user inserted data
// Both the items and the dataset instructions are used, but not in the regular way, see `DigitalTwinSettingsForm.tsx`.
registerUiConfigComponentAsOtherComponent(TIME_SERIES_VISUALIZATION, 'chart', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('electricity_elplan', 'container', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('day_ahead_overview', 'container', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('day_ahead_bid_management', 'container', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('intraday_overview', 'container', {
  isSingletonComponent: true,
})

registerUiConfigComponentAsOtherComponent('ancillary_services', 'container', {
  isSingletonComponent: true,
})
