import React, { ReactElement, ReactNode, useState } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import DeviationSettings from '../FollowupProduction/DeviationSettings/DeviationSettings'
import { queryClient } from 'helpers/queryClient'
import { systemIsDigitalTwinSystem } from 'helpers/system.helper/system.helper'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import PeriodPicker from 'views/UnitAvailabilityView/components/UnitAvailabilityWidget/components/PeriodPicker/PeriodPicker'
import PlannedStopSettings from 'views/UnitAvailabilityView/components/UnitAvailabilityWidget/components/PlannedStopModal/PlannedStopSettings/PlannedStopSettings'

import styles from './UnitAvailability.module.less'

type UnitAvailabilityUnitWidget = {
  unit_name: string
  unit_display_name?: string
  kpi: { ui_config_id: number }
  chart: { ui_config_id: number }
}

type UnitAvailabilityUnitsProps = {
  unitAvailabilityUnits: UnitAvailabilityUnitWidget[]
  systemId: number
  children: ReactNode[]
  datasetEndTime?: ISODateTime
  datasetStartTime?: ISODateTime
  override_alias?: UiConfigAliases
  override_props?: UiConfigOverrideProps
}

export const UNIT_AVAILABILITY_QUERY_KEY = 'unitAvailabilityQueryKey'

const defaultProps: Omit<UnitAvailabilityUnitsProps, 'datasets' | 'children' | 'systemId'> = {
  unitAvailabilityUnits: [
    {
      unit_name: '',
      unit_display_name: '',
      kpi: {
        ui_config_id: NaN,
      },
      chart: {
        ui_config_id: NaN,
      },
    },
  ],
}
registerUiConfigComponent('unit_availability', UnitAvailability, {
  isSingletonComponent: true,
  title: 'Tillgänglighet',
})
registerDefaultProps('unit_availability', defaultProps)

type AvailabilityWidgetProps = {
  unitWidget: UnitAvailabilityUnitWidget
}

function UnitAvailabilityWidget({ unitWidget }: AvailabilityWidgetProps): ReactElement {
  const { activeSystem } = useAuth()
  let unitDisplayName = unitWidget.unit_display_name ?? unitWidget.unit_name 

  if (activeSystem && systemIsDigitalTwinSystem(activeSystem)) {
    const primaryDigitalTwin = activeSystem?.primary_digital_twin
    unitDisplayName = getPrettyName(unitWidget.unit_display_name ?? unitWidget.unit_name, primaryDigitalTwin.translations)
  }
  const [period, setPeriod] = useState({ startTime: moment().startOf('month'), endTime: moment().endOf('month') })
  const unitName = unitWidget.unit_name

  const uiConfigSnap = useSnapshot(uiConfigStore)
  const { t } = useTranslation()

  function handleOnChange(): void {
    queryClient.invalidateQueries(UNIT_AVAILABILITY_QUERY_KEY)
  }

  return (
    <div className={styles.UnitAvailability_Widget}>
      {
        <Grid item xs={12}>
          <CardWidget id={'AvailabilityWidget'} title={`${t('Availability')} - ${unitDisplayName}`}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              style={{ overflow: 'hidden' }}
            >
              <Grid
                item
                container
                direction="column"
                xs={4}
                spacing={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <PeriodPicker setPeriod={setPeriod} />
                <UiConfigComponent
                  datasetRefreshToken={UNIT_AVAILABILITY_QUERY_KEY}
                  systemId={activeSystem?.id ?? 0}
                  id={unitWidget.kpi.ui_config_id}
                  datasetStartTime={Datetime.toISOString(period.startTime)}
                  datasetEndTime={Datetime.toISOString(period.endTime)}
                  uid={uiConfigSnap.idToUiConfig[unitWidget.kpi.ui_config_id]?.uid}
                />
                <Grid item>
                  <DeviationSettings
                    startTime={period.startTime.toISOString()}
                    endTime={period.endTime.toISOString()}
                    specificUnit={unitName}
                  />
                </Grid>
                <PlannedStopSettings period={period} unitName={unitName} onRefetch={handleOnChange}/>
              </Grid>
              <Grid item xs={8}>
                <UiConfigComponent
                  datasetRefreshToken={UNIT_AVAILABILITY_QUERY_KEY}
                  systemId={activeSystem?.id ?? 0}
                  id={unitWidget.chart.ui_config_id}
                  datasetStartTime={Datetime.toISOString(period.startTime)}
                  datasetEndTime={Datetime.toISOString(period.endTime)}
                  uid={uiConfigSnap.idToUiConfig[unitWidget.chart.ui_config_id]?.uid}
                />
              </Grid>
            </Grid>
          </CardWidget>
        </Grid>
      }
    </div>
  )
}

export default function UnitAvailability({
  unitAvailabilityUnits,
}: UnitAvailabilityUnitsProps): ReactElement {
  return (
    <>
      {unitAvailabilityUnits.map((unitWidget, index) => {
        return <UnitAvailabilityWidget key={index} unitWidget={unitWidget} />
      })}
    </>
  )
}
