import { BulletinObjectProperty } from 'api/bulletinBoard/bulletinBoard.api'
import {
  DeviationSettingValue,
  DigitalTwinSettingCreationRequest,
  TimeSeriesSettingValue,
} from 'api/digitalTwin/digitalTwin.api'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import Datetime from 'utils/datetime/datetime'

import i18n, { TFunction } from 'i18next'
import moment, { Moment } from 'moment'

import { getDisplayName } from '../global.helper/global.helper'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'

export function getLatestUpdatedAtFromPropertySettings(
  objectProperty: ObjectProperty,
  settingsForObjectProperty: Setting[]
): string {
  const foundSettings = settingsForObjectProperty
    .filter((s) => s.object_property === objectProperty.id)
    .sort((a, b) => {
      const updatedAtA = a.updated_at || ''
      const updatedAtB = b.updated_at || ''
      return Datetime.compare(Datetime.toISOString(updatedAtB), Datetime.toISOString(updatedAtA))
    })

  if (foundSettings.length > 0) {
    return foundSettings[0].updated_at
  } else {
    return `-`
  }
}

export function settingInPeriod(settingStartTime: Moment, settingEndTime: Moment | null): boolean {
  const startDate = moment().add(1, `hour`).startOf(`hour`)
  const endDate = startDate.clone().add(168, `hour`)
  const settingStart = settingStartTime
  if (settingEndTime !== null) {
    return settingStart.isSameOrBefore(endDate) && settingEndTime.isSameOrAfter(startDate)
  } else {
    return settingStart.isSameOrBefore(endDate)
  }
}

export function formatSettingsCalendarTitle(prop: ObjectProperty, item: Setting): string {
  let currentBaseValueStr = ``
  if (item && prop) {
    let valueString: string | number = item.value
    if (prop.name.includes(`availability`) || prop.name.includes(`forced`)) {
      valueString = i18n.t(`Active`)
    } else {
      currentBaseValueStr = prop.current_base_value !== null ? ` (` + prop.current_base_value + `)` : ``
    }

    const measurementUnit = prop.measurement_unit !== null ? (prop.measurement_unit as string) : ``
    const name = getDisplayName(prop)

    return name + `: ` + valueString + ` ` + measurementUnit + currentBaseValueStr
  } else return `Error`
}

export function isValidSettingValue(property: ObjectProperty, settingValue: number): boolean {
  const lowerBoundary = property.valid_range.lower
  const upperBoundary = property.valid_range.upper
  return (
    (lowerBoundary === null || lowerBoundary <= settingValue) &&
    (upperBoundary === null || upperBoundary === undefined || settingValue <= upperBoundary)
  )
}

export function isBinarySetting(settingName: string): boolean {
  if ([`forced_min`, `forced_recharge`, `forced_op`,`forced_activity`,`forced_min_transfer_incoming`,`forced_min_transfer_outgoing`].includes(settingName)) {
    //2025-01-22: FIXME: Hotfix added to avoid Exergi model to not being able to set binary settings with attributes named with "forced" included, ref https://app-eu.wrike.com/open.htm?id=1578042695
    return false
  } else {
    return settingName.includes(`forced`) || settingName.includes(`availability`)
  }
}

export function formatObjectPropertySettingName(objectProperty: BulletinObjectProperty): string {
  return getDisplayName(objectProperty) + ` ` + getDisplayName(objectProperty.parent)
}

type PrettyPrintParams = {
  startTime: ISODateTime
  endTime: ISODateTime | null
  activeValue?: string | DeviationSettingValue | number | null
  defaultValue?: string | DeviationSettingValue | number | null
  periodStartTime: ISODateTime
  periodEndTime: ISODateTime
  unit?: string | null
  attribute?: string
  baseValue?: number | null
}

export function prettyPrintCurrentSettingValue(params: PrettyPrintParams): string {
  const { startTime, endTime, activeValue, defaultValue, periodStartTime, periodEndTime, unit, attribute, baseValue } =
    params

  if (endTime === null || Datetime.isAfter(endTime, periodEndTime)) {
    //setting is active
    if (attribute && (attribute.includes(`forced`) || attribute.includes(`availability`))) {
      return i18n.t(`Active`)
    } else {
      return unit
        ? `${activeValue} ${unit} (${baseValue ?? defaultValue})`
        : `${activeValue} (${baseValue ?? defaultValue})`
    }
  } else if (endTime && Datetime.isBefore(endTime, periodEndTime)) {
    // setting ends in period
    if (attribute && (attribute.includes(`forced`) || attribute.includes(`availability`))) {
      return i18n.t(`Active until `) + formatDateTimeToLocalizedString(endTime, true)
    } else {
      return unit
        ? `${activeValue} ${unit} ${i18n.t('until')} ${formatDateTimeToLocalizedString(endTime, true)} (${baseValue ?? defaultValue})`
        : `${activeValue} ${i18n.t('until')} ${formatDateTimeToLocalizedString(endTime, true)} (${baseValue ?? defaultValue})`
    }
  } else if (Datetime.isAfter(startTime, periodStartTime)) {
    // setting starts in period
    if (attribute && (attribute.includes(`forced`) || attribute.includes(`availability`))) {
      return i18n.t(`Active from `) + formatDateTimeToLocalizedString(startTime, true)
    } else {
      return unit
        ? `${activeValue} ${unit} ${i18n.t('from')} ${formatDateTimeToLocalizedString(startTime, true)} (${baseValue ?? defaultValue})`
        : `${activeValue} ${i18n.t('from')} ${formatDateTimeToLocalizedString(startTime, true)} (${baseValue ?? defaultValue})`
    }
  }

  if (attribute && attribute.includes(`forced`)) {
    return i18n.t(`Forced driving inactive`)
  }
  if (attribute && attribute.includes(`availability`)) {
    return i18n.t(`Unavailability inactive`)
  }
  return (baseValue ?? defaultValue)?.toString() ?? ''
}

type GetSettingValueStringParams = {
  settingStartTime?: ISODateTime | null
  settingEndTime?: ISODateTime | null
  activeValue?: string | DeviationSettingValue | number | null
  defaultValue?: string | DeviationSettingValue | number | null
  periodStartTime: ISODateTime | null
  periodEndTime: ISODateTime | null
  attribute?: string
  baseValue?: string | DeviationSettingValue | number | null
  isTimeSeries?: boolean
}

export function getDigitalTwinSettingValueString(params: GetSettingValueStringParams): string {
  const {
    settingStartTime,
    settingEndTime,
    activeValue,
    defaultValue,
    periodStartTime,
    periodEndTime,
    attribute,
    baseValue,
    isTimeSeries,
  } = params

  if (activeValue === null || activeValue === undefined) {
    return '-'
  }

  let output = `${activeValue}`

  if (isTimeSeries) {
    // Temporary fix until we are able to show a better value for time series settings.
    output = i18n.t('Time series')
  } else if (attribute && attribute.includes(`forced`) && activeValue === 1) {
    output = i18n.t(`Active`)
  } else if (attribute && attribute.includes(`forced`) && activeValue === 0) {
    output = i18n.t(`Forced driving inactive`)
  } else if (attribute && attribute.includes(`availability`) && activeValue === 1) {
    output = i18n.t(`Unavailability inactive`)
  } else if (attribute && attribute.includes(`availability`) && activeValue === 0) {
    output = i18n.t(`Active`)
  }

  // Show 'from time' only if setting has start time, and it is before the period start time.
  // If period has no start time, the setting start time is always after the period start.
  if (settingStartTime && (!periodStartTime || Datetime.isAfter(settingStartTime, periodStartTime))) {
    output = `${output} ${i18n.t('from')} ${formatDateTimeToLocalizedString(settingStartTime, true)}`
  }

  // Show 'until time' only if setting has end time, and it is before the period end time.
  // If period has no end time, the setting end time is always before the period end.
  if (settingEndTime && (!periodEndTime || Datetime.isBefore(settingEndTime, periodEndTime))) {
    const displayEndTime = Datetime.ISODatetimeTo59Minutes(settingEndTime)
    output = `${output} ${i18n.t('until')} ${formatDateTimeToLocalizedString(displayEndTime, true)}`
  }

  // For settings that are not 'forced' or 'availability', add the default value in parenthesis at the end
  if (!attribute?.includes('forced') && !attribute?.includes(`availability`) && !isTimeSeries) {
    const defaultSuffix = baseValue ?? defaultValue
    if (defaultSuffix !== null && defaultSuffix !== undefined) {
      output = `${output} (${defaultSuffix})`
    }
  }

  return output
}

export function getUnit(measurementUnit: string | null): string {
  return measurementUnit ? ` ` + measurementUnit : ``
}

export function getValueHelperText(
  attribute: string,
  value: undefined | number | unknown | null,
  minValue: undefined | number | null,
  maxValue: undefined | number | null,
  t: TFunction
): string | undefined {
  if (value === null || value === undefined) {
    return undefined
  }

  const inputValue = value // Save the original value for the error message

  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  if (typeof value !== 'number' || isNaN(value)) {
    return t("'{{inputValue}}' is not a valid number", { inputValue })
  }

  const hasMinValue = minValue !== null && minValue !== undefined
  const hasMaxValue = maxValue !== null && maxValue !== undefined

  if (isBinarySetting(attribute) && minValue === 0 && maxValue === 1 && value !== 0 && value !== 1) {
    return t('Please enter a value of 0 or 1')
  }

  if (hasMinValue && hasMaxValue && (value < minValue || value > maxValue)) {
    return t('Please enter a value between {{minValue}} and {{maxValue}}', { minValue, maxValue })
  }

  if (hasMinValue && value < minValue) {
    return t('Please enter a value greater than {{minValue}}', { minValue })
  }

  if (hasMaxValue && value > maxValue) {
    return t('Please enter a value less than {{maxValue}}', { maxValue })
  }

  return undefined
}

export function getValueHelperTextForSetting(
  newSetting: DigitalTwinSettingCreationRequest,
  minValue: undefined | number | null,
  maxValue: undefined | number | null,
  t: TFunction
): { errorMessage: string | undefined; invalidValuesIndexes: number[] } {
  if (!newSetting.isTimeSeries) {
    return {
      errorMessage: getValueHelperText(newSetting.attribute, newSetting.value, minValue, maxValue, t),
      invalidValuesIndexes: [],
    }
  }

  const settingValue = newSetting.value as TimeSeriesSettingValue

  if (Object.values(settingValue).every((idAndValue) => idAndValue.value === null || idAndValue.value === undefined)) {
    return {
      errorMessage: t('Please enter at least one valid number'),
      invalidValuesIndexes: [],
    }
  }

  const errorMessagesAndIdexes = Object.entries(settingValue).map(([timestampString, idAndValue], i) => {
    const timestamp = Datetime.toLocalTime(timestampString as ISODateTime)
    const valueHelperText = getValueHelperText(newSetting.attribute, idAndValue.value, minValue, maxValue, t)
    if (valueHelperText) {
      return {
        errorMessage: `${t("Invalid value at timestamp '{{timestamp}}'", { timestamp })}. ${valueHelperText}.`,
        index: i,
      }
    }
  })

  // Return all indexes for rows where the value is invalid, as well as the first error message
  return {
    errorMessage: errorMessagesAndIdexes.find((error) => error !== undefined)?.errorMessage,
    invalidValuesIndexes: errorMessagesAndIdexes.filter((error) => error !== undefined).map((error) => error.index),
  }
}
