import React, { ReactElement } from 'react'

import { DeviationSettingValue } from 'api/digitalTwin/digitalTwin.api'
import { TextField } from 'ui/atoms'
import Dropdown from 'ui/components/Dropdown/Dropdown'

import { SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { isBinarySetting } from 'helpers/optimizeSettings.helper/optimizeSettings.helper'
import { getLabelForAttributeValue } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

type ValueType = number | string | undefined
type DigitalTwinSettingValueSelectProps = {
  attribute: string
  value: ValueType
  label: string
  onChange: (value: ValueType) => void
  disabled?: boolean
  required?: boolean
  valueHelperText?: string
  settingBaseValue?: DeviationSettingValue | string
}

export default function DigitalTwinSettingValueSelect({
  attribute,
  value,
  label,
  onChange,
  disabled = false,
  required = false,
  valueHelperText,
  settingBaseValue,
}: DigitalTwinSettingValueSelectProps): ReactElement {
  const { t } = useTranslation()

  const handleDropdownChange = (v: SelectChangeEvent<ValueType>): void => {
    onChange(v.target.value as ValueType)
  }

  const baseValue =
    settingBaseValue === undefined ? '' : t('Base value: {{baseValue}}', { baseValue: `${settingBaseValue}` })

  if (isBinarySetting(attribute)) {
    return (
      <Dropdown
        disabled
        fullWidth
        label={label}
        items={[
          {
            value: value,
            label: getLabelForAttributeValue(attribute, value) as string,
          },
        ]}
        handleChange={handleDropdownChange}
        value={value}
        margin="normal"
      />
    )
  }

  switch (attribute) {
    case 'max_level':
    case 'min_level':
      return (
        <TextField
          variant="outlined"
          disabled={disabled}
          fullWidth
          type="number"
          unit="MWh"
          label={label}
          value={value}
          required={required}
          onChange={(value) => {
            onChange(value === '' ? undefined : value)
          }}
          margin="normal"
        />
      )
    case 'max_thermal':
    case 'min_thermal':
      return (
        <TextField
          disabled={disabled}
          fullWidth
          variant="outlined"
          type="number"
          label={label}
          value={value}
          required={required}
          helperText={valueHelperText ? valueHelperText : baseValue}
          unit="MW"
          onChange={(value) => {
            onChange(value === '' ? undefined : value)
          }}
          margin="normal"
        />
      )
    case 'end_level':
    case 'start_level':
      return (
        <TextField
          disabled={disabled}
          fullWidth
          type="number"
          variant="outlined"
          unit="MWh"
          value={value}
          required={required}
          onChange={(value) => {
            onChange(value === '' ? undefined : value)
          }}
          margin="normal"
        />
      )
    case 'electricity_price_modification_low':
    case 'electricity_price_modification_high':
      return (
        <TextField
          disabled={disabled}
          fullWidth
          type="number"
          variant="outlined"
          unit="%"
          value={value}
          required={required}
          onChange={(value) => {
            onChange(value === '' ? undefined : value)
          }}
          margin="normal"
        />
      )
    default:
      // i.e. 'efficiency', 'min_downtime', 'min_uptime'
      return (
        <TextField
          disabled={disabled}
          fullWidth
          type="number"
          variant="outlined"
          label={label}
          required={required}
          helperText={valueHelperText ? '' : baseValue}
          value={value}
          onChange={(value) => {
            onChange(value === '' ? undefined : value)
          }}
          margin="normal"
        />
      )
  }
}
