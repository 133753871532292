import React, { ReactElement } from 'react'

import { useSystems } from 'api/systems/systems.api'
import { useUser, useUserMutation } from 'api/users/users.api'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import ReactHookFormSelect from 'ui/components/ReactHookFormSelect/ReactHookFormSelect'
import ReactHookFormTextField from 'ui/components/ReactHookFormTextField/ReactHookFormTextField'

import { Grid, Typography, MenuItem } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './UserDetails.module.less'

export default function UserDetails(): ReactElement {
  const { mutateAsync } = useUserMutation()
  const { data: user } = useUser()
  const { data: systems } = useSystems()
  const { t } = useTranslation()

  const methods = useForm<User>({
    mode: `onChange`,
    defaultValues: { ...user, system: user?.system ? user?.system : undefined },
  })
  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = methods
  const onSubmit = handleSubmit((formData: User) => {
    mutateAsync({ id: formData.id, data: formData })
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Typography className={styles.UserDetails_ChangePasswordTitle}>{t(`Personal information`)}</Typography>
        <Grid item xs={7} className={styles.UserDetails_Container}>
          <ReactHookFormTextField
            inputProps={{ id: `firstname`, 'data-testid': `firstname` }}
            className={styles.UserDetails_TextField}
            variant="outlined"
            name="first_name"
            label={t(`First name`)}
            aria-label={t(`First name`)}
            type="name"
            autoComplete="given-name"
            error={errors?.first_name ? true : false}
            rules={{ required: true }}
            helperText={errors?.first_name ? t(`Please enter a name`) : ``}
          />
          <ReactHookFormTextField
            inputProps={{ id: `surname`, 'data-testid': `surname` }}
            className={styles.UserDetails_TextField}
            variant="outlined"
            label={t(`Last name`)}
            aria-label={t(`Last name`)}
            name="last_name"
            type="name"
            autoComplete="family-name"
          />
          <ReactHookFormTextField
            className={styles.UserDetails_TextField}
            variant="outlined"
            label={t(`Email`)}
            aria-label={t(`Email`)}
            type="email"
            autoComplete="email"
            name="email"
            disabled
          />
          <ReactHookFormSelect
            className={styles.UserDetails_SelectField}
            variant="outlined"
            label={t(`Main plant`)}
            aria-label={t(`Main plant`)}
            name="system"
          >
            <MenuItem value={undefined}>--------</MenuItem>
            {systems?.map((system) => (
              <MenuItem key={system.id} value={system.id}>
                {system.display_name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <div className={styles.UserDetails_Registred}>
          <Grid item xs={6}>
            <Typography variant="body1">{t(`Registered since:`)}</Typography>
            <Typography variant="subtitle1">{moment(user?.created_at).format(`YYYY-MM-DD`)}</Typography>
          </Grid>
          <Grid item container xs={7} className={styles.UserDetails_ButtonGrid}>
            <ContainedIconButton
              color="primary"
              disabled={!isValid || !isDirty}
              type="submit"
              icon="fal fa-save"
              label={t(`Save settings`)}
            />
          </Grid>
        </div>
      </form>
    </FormProvider>
  )
}
