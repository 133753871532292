import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import {
  DigitalTwin,
  DigitalTwinModel,
  DigitalTwinUpdateRequest,
  useUpdateDigitalTwin,
} from 'api/digitalTwin/digitalTwin.api'
import { SystemUpdateDataRequest, useUpdateSystem } from 'api/systems/systems.api'
import authStore from 'store/auth/auth'
import { Button } from 'ui/atoms'

import MonacoEditor from '@monaco-editor/react'
import { Card } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'

import styles from './DigitalTwinEditor.module.less'

type DigitalTwinEditorType = {
  currentDigitalTwin?: DigitalTwin
  setCurrentDigitalTwin: (digitalTwin: DigitalTwin) => void
  refetch: () => void
  preview: () => void
}

// eslint-disable-next-line no-empty-pattern
export default function DigitalTwinEditor({
  currentDigitalTwin,
  setCurrentDigitalTwin,
  refetch,
  preview,
}: DigitalTwinEditorType): ReactElement {
  const { t } = useTranslation()

  const { mutateAsync: updateDigitalTwin } = useUpdateDigitalTwin()
  const { mutateAsync: updateSystem } = useUpdateSystem()

  const [editingDigitalTwin, setEditingDigitalTwin] = useState<DigitalTwin>()
  const [isUpdatingPrimary, setUpdatingPrimary] = useState(false)

  const [jsonEditorValue, setJsonEditorValue] = useState('')
  const [isValidJson, setIsValidJson] = useState(true)

  const jsonEditorValueAsDigitalTwinModel = useMemo(() => {
    try {
      const value = JSON.parse(jsonEditorValue) as DigitalTwinModel
      setIsValidJson(true)
      return value
    } catch (error: unknown) {
      setIsValidJson(false)
      return undefined
    }
  }, [jsonEditorValue])

  const auth = useSnapshot(authStore)

  useEffect(() => {
    setEditingDigitalTwin(clone(currentDigitalTwin))
    setJsonEditorValue(JSON.stringify(currentDigitalTwin?.model, null, 2))
  }, [currentDigitalTwin])

  // Without this variable, reading the layout from currentDigitalTwin or editingDigitalTwin
  // inside the save method gives an outdated layout. I don't understand why. / Lage
  const layout = currentDigitalTwin?.layout

  function save(): void {
    if (!editingDigitalTwin || !jsonEditorValueAsDigitalTwinModel) {
      return
    }

    const data: DigitalTwinUpdateRequest = {
      name: editingDigitalTwin?.name,
      display_name: editingDigitalTwin?.display_name,
      model: jsonEditorValueAsDigitalTwinModel,
      layout: layout,
      description: editingDigitalTwin?.description ?? '',
    }

    updateDigitalTwin({ uid: editingDigitalTwin.uid, data }).then((updated) => {
      setCurrentDigitalTwin(updated)
      refetch()
    })
  }

  function setPrimary(): void {
    setUpdatingPrimary(true)
    const data: SystemUpdateDataRequest = {
      primary_digital_twin_id: editingDigitalTwin?.uid,
    }

    if (auth.activeSystem) {
      updateSystem({ id: auth.activeSystem.id, data })
        .then(() => {
          setUpdatingPrimary(false)
        })
        .catch(() => {
          setUpdatingPrimary(false)
        })
    }
  }

  if (!editingDigitalTwin) {
    return <></>
  }

  return (
    <Card className={styles.DigitalTwinEditor}>
      <div className={styles.DigitalTwinEditor_EditorContainer}>
        <MonacoEditor
          key={`digital_twin_json_editor_${editingDigitalTwin.uid}`}
          height="70vh"
          language="json"
          value={jsonEditorValue}
          options={{
            minimap: { enabled: false },
            readOnly: false,
            wordWrap: `on`,
            automaticLayout: true,
            formatOnType: false,
            formatOnPaste: false,
          }}
          onChange={(value) => {
            setJsonEditorValue(value ?? '')
          }}
        />
      </div>
      <div>
        <Button secondary marginLeft marginBottom onClick={preview}>
          {t('Preview')}
        </Button>
        <Button primary marginLeft marginBottom onClick={save} disabled={!isValidJson} disabledTooltip="Invalid JSON">
          {t('Save')}
        </Button>
        <Button
          marginLeft
          marginBottom
          primary
          disabled={isUpdatingPrimary || auth.activeSystem?.primary_digital_twin?.uid === editingDigitalTwin.uid}
          onClick={setPrimary}
        >
          {t('Make primary')}
        </Button>
      </div>
    </Card>
  )
}
