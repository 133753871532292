import React, { ReactElement } from 'react'

import { isComment, useBulletinBoardLikeMutation , Comment, Thread } from 'api/bulletinBoard/bulletinBoard.api'

import { Grid, Typography, IconButton } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './Like.module.less'

type LikeProps = {
  post: Thread | Comment
}

export default function Like({ post }: LikeProps): ReactElement {
  const { t } = useTranslation()
  const { mutate: bulletinBoardLike } = useBulletinBoardLikeMutation()

  const userLiked = post.user_liked
  const totalLikes = post.total_likes

  function postLikes(): void {
    bulletinBoardLike({
      id: post.id,
      type: !isComment(post) ? `bulletin_board_threads` : `bulletin_board_comments`,
    })
  }

  return (
    <Grid container item direction="row">
      <IconButton
        onClick={() => postLikes()}
        className={classNames(`fal fa-thumbs-up`, styles.Like_ThumbsUpIcon)}
        style={{
          color: userLiked ? `#2077B4` : undefined,
        }}
        aria-label="Like post"
        size="large"
      />
      <Typography className={styles.Like_LikeText}>
        {totalLikes >= 1 && userLiked === false
          ? totalLikes + (totalLikes >= 2 ? t(` others `) + t(` likes this`) : t(` other `) + t(` likes this`))
          : userLiked
            ? totalLikes > 1
              ? t(`You and`) +
                ` ` +
                (totalLikes - 1) +
                (totalLikes >= 2 ? t(` others `) + t(` likes this`) : t(` other `) + t(` likes this`))
              : t(`You`) + t(` likes this`)
            : t(`Like this`)}
      </Typography>
    </Grid>
  )
}
