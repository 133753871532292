import React, { ReactElement, useState } from 'react'

import elplanStore from 'store/elplan/elplan'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import { getBidColorBasedOnOrderAndType } from 'ui/uiConfig/components/wildcardItems.helper'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import BidModal from '../BidModal/BidModal'
import { FormattedBidWithIdAndTitle } from '../bidManagement.helper'

type BlockBidProps = {
  blockBids: FormattedBidWithIdAndTitle[]
  createBid: (bid: FormattedBidWithIdAndTitle) => void
  updateBid: (bid: FormattedBidWithIdAndTitle) => void
  deleteBid: (bid: FormattedBidWithIdAndTitle) => void
  view?: string
}
export default function BlockBidTable({
  blockBids,
  createBid,
  updateBid,
  deleteBid,
  view,
}: BlockBidProps): ReactElement {
  const { t } = useTranslation()

  const [currentBlockBid, setCurrentBlockBid] = useState<FormattedBidWithIdAndTitle>()
  const elplanSnap = useSnapshot(elplanStore)

  function editBlockBid(currentBlockBid: FormattedBidWithIdAndTitle): void {
    setCurrentBlockBid(currentBlockBid)
  }

  function closingModal(): void {
    setCurrentBlockBid(undefined)
  }

  const weekView = view === 'week'

  const columns = [
    {
      accessor: 'color',
      header: '',
      Cell: (tableContent: any) => {
        const order = tableContent.row.original.block_order
        const color = getBidColorBasedOnOrderAndType('blockbid', order)
        return <Icon color={color} icon={'fa fa-circle'} />
      },
    },
    {
      accessor: `title`,
      Header: t(`Block bid`),
    },
    {
      accessor: `date`,
      Header: t(`Date`),
    },
    {
      accessor: `displayStartTime`,
      Header: t(`Start time`),
    },
    {
      accessor: `displayEndTime`,
      Header: t(`End time`),
    },
    {
      accessor: `volume`,
      Header: `${t(`Volume`)} (MW)`,
    },
    {
      accessor: `price`,
      Header: `${t(`Avg price`)} (${elplanSnap.currency.toLocaleUpperCase()}/MWh)`,
    },
    {
      accessor: `editable`,
      Header: t(`Edit`),
      Cell: (tableContent: any) => (
        <Button link icon={'fal fa-pen'} disabled={weekView} onClick={() => editBlockBid(tableContent.row.original)}>
          {''}
        </Button>
      ),
    },
    {
      accessor: `clear`,
      Header: t(`Remove`),
      Cell: (tableContent: any) => (
        <Button link icon={'fal fa-trash'} onClick={() => deleteBid(tableContent.row.original)}>
          {''}
        </Button>
      ),
    },
  ]

  return (
    <div>
      {weekView && (
        <InfoBanner
          text={t(`You cannot edit blockbids when in week view, please change to day view to edit`)}
          style={'warning'}
          inlineBlock
        />
      )}
      <DefaultTable columns={columns} data={blockBids} initialPageSize={20} />
      {currentBlockBid !== undefined && (
        <BidModal
          closeModal={() => closingModal()}
          bid={currentBlockBid}
          editBid={(blockBid: FormattedBidWithIdAndTitle) => {
            if (currentBlockBid?.fake) {
              createBid(blockBid)
            } else {
              updateBid(blockBid)
            }
          }}
          type={'block'}
          isEditing
        />
      )}
    </div>
  )
}
