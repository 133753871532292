import React, { ReactElement, useMemo, useState } from 'react'

import SelectButton from 'api/SelectButton/SelectButton'
import { useOptJobMutation } from 'api/optJobs/optJobs.api'
import { postStat } from 'api/stats/stats.api'
import { useSystem } from 'api/systems/systems.api'
import alertStore from 'store/alert/alert'
import authStore from 'store/auth/auth'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { convertToFollowupPeriod, datesAndWeeksString } from './PeriodToWeeks.helper'
type FollowupButtonProps = {
  onClick?: () => void
  period: Period
}

export default function FollowupButton({ period }: FollowupButtonProps): ReactElement {
  const { t } = useTranslation()

  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
  const [selectedSubtype, setSelectedSubtype] = useState<Subtype | null>(null)
  const [weekObject, setWeekObject] = useState<datesAndWeeksString[]>([])
  const [weekNumbers, setWeekNumbers] = useState<string[]>([])
  const authSnap = useSnapshot(authStore)
  const allowedPeriod = weekNumbers.length < 3

  const clientTimezone = useMemo(() => moment.tz.guess(), [])
  const clientTimeZoneOffSet = moment.tz(clientTimezone).format('Z')
  const systemTimeZone = authSnap.activeSystem?.default_time_zone || '-'
  const systemTimeZoneOffSet = systemTimeZone ? moment.tz(systemTimeZone).format('Z') : '-'

  const allowedModalTextList: string[] = [
    t(`Re-runs of optimization can, as of now, only be done in whole weeks, both with and without deviations. `),
    t(
      `Make notice that a re-run of an optimization might affect MPK and other costs as well as the production plan and KPI!`
    ),
    t('Client') + '/' + t('timezone') + ': ' + clientTimezone + ` (${clientTimeZoneOffSet})`,
    t('System') + '/' + t('timezone') + ': ' + systemTimeZone + ` (${systemTimeZoneOffSet})`,
    t(`Would you like to re-run the optimizations for the weeks of`) +
      `${weekNumbers}` +
      ` ` +
      t(`in the period of`) +
      ` ` +
      moment(weekObject[0]?.startDate).format(`ddd YYYY-MM-DD HH:mm:ss`) +
      ` ` +
      t(`to`) +
      ` ` +
      moment(weekObject[weekObject.length - 1]?.endDate).format(`ddd YYYY-MM-DD HH:mm:ss`) +
      `?`,
  ]
  const allowedModalTitleText = {
    title: t(`A re-run of follow-up optimization can only be done in whole weeks`),
    text: ``,
    confirm: selectedSubtype ? t(`Re-run optimization for {{subtype}}`, {subtype: selectedSubtype.subtype_display_name}) : t(`Re-run optimization`),
  }

  const failedModalTextList: string[] = [
    t(`You have chosen `) +
      `${weekNumbers.length}` +
      t(
        ` weeks to re-run optimization for. As of now, a re-run of optimization can only be done 2 weeks at a time. Please choose a shorter period of time.`
      ),
  ]

  const failedModalTitleText = {
    title: t(`Too long period`),
    text: ``,
    confirm: t(`Choose a new period`),
  }

  const { mutate: optJobCreate } = useOptJobMutation()
  const { systemId } = useAuth()
  const { data: activeSystem } = useSystem({ id: systemId })

  const systemHasSubtypesForFollowup = (activeSystem?.subtypes?.followup_with_deviations?.length ?? 0) > 0
  const subtypes = activeSystem?.subtypes?.followup_with_deviations?.map((subtype: Subtype) => subtype) ?? []

  async function createOptJobs(element: datesAndWeeksString, subtype?: string): Promise<void> {
    const jobTypes = [
      'followup_with_deviations',
      'followup_without_deviations',
      'measvalues_calculations',
    ]

    const inputData = {
      opt_start_time: element.startDate,
      opt_end_time: element.endDate,
      ...(subtype && { include_subtypes: [subtype] }),
    }

    await Promise.all(
      jobTypes.map((jobType) =>
        optJobCreate({
          opt_model: authSnap.optModelId,
          opt_job_type: jobType,
          input_data: inputData,
        })
      )
    )
  }

  async function runFollowup(): Promise<void> {
    await Promise.all(weekObject.map((element) => createOptJobs(element)))

    alertStore.success(
      t(
        `The follow-up optimization have been successfully created. You will have to update the web page in a few minutes to see the results.`
      )
    )

    postStat(`followup-button`, `run-followup-optjob`)
  }

  async function runFollowupSubtype(subtype: string): Promise<void> {
    await Promise.all(weekObject.map((element) => createOptJobs(element, subtype)))

    alertStore.success(
      t(
        `The follow-up optimization have been successfully created. You will have to update the web page in a few minutes to see the results.`
      )
    )

    postStat(`followup-button`, `run-followup-optjob`)
  }

  function setWeekObjectFun(): void {
    const weekObjectsForFollowup = convertToFollowupPeriod(period.startTime, period.endTime, authSnap.activeSystem?.default_time_zone)
    const weekArray: string[] = []

    weekObjectsForFollowup.forEach((element) => {
      weekArray.push(` ` + element.weekNumber)
    })

    setWeekObject(weekObjectsForFollowup)
    setWeekNumbers(weekArray)
  }

  function openModal(): void {
    setCloseConfirmOpen(true)
  }

  const additionalMenuItems = subtypes.map((subtype: Subtype) => ({
    label: t(`Run follow up for {{subtype}}`, {subtype: subtype.subtype_display_name}),
    onClick: () => {
      setSelectedSubtype(subtype)
      setCloseConfirmOpen(true)
    },
  }))

  return (
    <>
      <SelectButton
        noRadiusRight
        onButtonClick={setWeekObjectFun}
        menuItems={[
          { label: t(`Run follow up`), onClick: openModal },
          ...(systemHasSubtypesForFollowup ? additionalMenuItems : []),
        ]}
        icon='fas fa-sort-down'
      />
      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          if (allowedPeriod) {
            if (selectedSubtype) {
              runFollowupSubtype(selectedSubtype.subtype)
            } else {
              runFollowup()
            }
          }
          setCloseConfirmOpen(false)
          setSelectedSubtype(null)
        }}
        textObject={allowedPeriod ? allowedModalTitleText : failedModalTitleText}
        subTextList={allowedPeriod ? allowedModalTextList : failedModalTextList}
      />
    </>
  )
}
