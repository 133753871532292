import React, { ReactElement } from 'react'

import { useDigitalTwinHeatSuppliers } from 'api/heatSuppliers/heatSuppliers.api'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Overview from 'views/SamEnergiView/components/Overview/Overview'
import SamEnergiHeader from 'views/SamEnergiView/components/SamEnergiHeader/SamEnergiHeader'

import DigitalTwinHeatSuppliers from './components/DigitalTwinHeatSuppliers'

const widgetConstants = {
  SAMENERGI_GRID: `samEnergiGrid`,
  OVERVIEW_WIDGET: `overview_widget`,
  SUPPLIER_WIDGET: `supplier_widget`,
}

function DigitalTwinSamEnergiView(): ReactElement {
  const { t } = useTranslation()

  const { data: heatSuppliers } = useDigitalTwinHeatSuppliers()
  const atleastOneSupplierIsReal = (heatSuppliers ?? []).some((supplier) => !supplier.is_fictive)

  return (
    <View
      header={
        <ModuleHeader infoHeader={t(`SamEnergi`)}>
          {atleastOneSupplierIsReal && (
            <Grid container justifyContent="flex-end">
              <SamEnergiHeader />
            </Grid>
          )}
        </ModuleHeader>
      }
    >
      <Grid item xs={12}>
        <CardWidget id={widgetConstants.OVERVIEW_WIDGET} title={t(`Overview`)}>
          <Overview />
        </CardWidget>
      </Grid>
      <Grid item xs={12}>
        <CardWidget id={widgetConstants.SUPPLIER_WIDGET} title={t(`Detailed supplier view`)}>
          <DigitalTwinHeatSuppliers />
        </CardWidget>
      </Grid>
    </View>
  )
}

export default DigitalTwinSamEnergiView
