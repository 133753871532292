import React, { createContext, PropsWithChildren, ReactElement, ReactNode, useContext, useMemo } from 'react'

import sentry from 'utils/sentry/sentry'

import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  TableFooter as MuiTableFooter,
  TableBody as MuiTableBody,
  Typography,
  TablePagination,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  useTable as useReactTable,
  useSortBy,
  usePagination,
  TableOptions,
  Row,
  useGlobalFilter,
  TableInstance,
} from 'react-table'

import styles from './CustomTable.module.less'
import TablePaginationActions from './components/TablePaginationActions/TablePaginationActions'

type TableProviderProps = TableOptions<Record<string, unknown>> & {
  tableOptions?: Partial<TableOptions<Record<string, unknown>>>
  initialPageSize?: number
  initialSortBy?: { id: string; desc: boolean }[]
}

type TableContextType = TableInstance<Record<string, unknown>>

const TableContext = createContext<TableContextType | undefined>(undefined)

export function TableProvider({
  columns,
  data,
  tableOptions,
  children,
  initialPageSize = 10,
  initialSortBy = [{ id: '', desc: false }],
}: PropsWithChildren<TableProviderProps>): ReactElement {
  const filteredColumns = useMemo(() => {
    const accessors = columns.map((col) => col.accessor)
    const accessorSet = new Set()
    const indicesToRemove: Set<number> = new Set()

    for (let i = 0; i < accessors.length; i++) {
      const a = accessors[i]
      if (accessorSet.has(a)) {
        indicesToRemove.add(i)
      } else {
        accessorSet.add(a)
      }
    }

    if (indicesToRemove.size > 0) {
      sentry.captureMessage('React table has duplicate columns.')
    }

    return columns.filter((_col, index) => !indicesToRemove.has(index))
  }, [columns])

  const value = useReactTable(
    {
      columns: filteredColumns,
      data,
      initialState: { pageSize: initialPageSize, sortBy: initialSortBy, ...tableOptions?.initialState },
      ...tableOptions,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return <TableContext.Provider value={{ ...value }}>{children}</TableContext.Provider>
}

export function useTable(): TableContextType {
  const context = useContext(TableContext)
  if (context === undefined) {
    throw new Error(`component must be used within TableProvider`)
  }
  return context
}

export function Table({ children }: { children: ReactNode }): ReactElement {
  const { getTableProps } = useTable()
  return <MuiTable {...getTableProps()}>{children}</MuiTable>
}

type TableHeadProps = {
  everyOtherColumnGrey?: boolean
}

export function TableHead({ everyOtherColumnGrey }: TableHeadProps): ReactElement {
  const { headerGroups } = useTable()
  const { t } = useTranslation()

  return (
    <MuiTableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${headerGroup.id}`}>
          {headerGroup.headers.map((col, index) => {
            return (
              <TableCell
                {...col.getHeaderProps(col.canSort ? col.getSortByToggleProps() : undefined)}
                key={`columnHeader-${col.id}`}
                title={t(`Sort`)}
                style={{
                  backgroundColor: everyOtherColumnGrey && index % 2 !== 0 ? '#F2F2F2' : '',
                  minWidth: '6vw',
                  lineHeight: '1rem',
                }}
              >
                {col.canSort ? (
                  <TableSortLabel active={col.isSorted} direction={col.isSortedDesc ? `desc` : `asc`}>
                    {col.render(`Header`)}
                  </TableSortLabel>
                ) : (
                  col.render(`Header`)
                )}
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </MuiTableHead>
  )
}

type TableBodyProps = {
  emptyTableText?: string
  rowClass?: string | ((row: Row) => string | undefined)
  coloredRows?: boolean
  everyOtherColumnGrey?: boolean
}

export function TableBody({
  emptyTableText,
  rowClass,
  coloredRows,
  everyOtherColumnGrey,
}: TableBodyProps): ReactElement {
  const { t } = useTranslation()

  const defaultEmptyTableText = emptyTableText ? emptyTableText : t(`No rows found`)
  const { columns, rows, page, getTableBodyProps, prepareRow } = useTable()
  return (
    <MuiTableBody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row)
        const classString = `CustomTable_TableRow__${row.id}`
        return (
          <TableRow
            {...row.getRowProps()}
            className={typeof rowClass === `string` ? rowClass : coloredRows ? styles[classString] : rowClass?.(row)}
            key={`row-${row.id}`}
          >
            {row.cells.map((cell, index) => {
              return (
                <TableCell
                  {...cell.getCellProps()}
                  style={{
                    backgroundColor: everyOtherColumnGrey && index % 2 !== 0 ? '#F2F2F2' : '',
                  }}
                  key={`${row.id}-${cell.column.id}`}
                >
                  {cell.render(`Cell`)}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
      {rows.length === 0 && (
        <TableRow>
          <TableCell align="center" colSpan={columns.length}>
            {defaultEmptyTableText}
          </TableCell>
        </TableRow>
      )}
    </MuiTableBody>
  )
}

export function TableFooter(): ReactElement {
  const { t } = useTranslation()

  const {
    rows,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable()

  return (
    <MuiTableFooter>
      {pageCount > 1 ? (
        <TableRow>
          <TablePagination
            className={styles.CustomTable}
            classes={{ spacer: styles.CustomTable_Spacer }}
            style={{ position: 'sticky', minHeight: 'auto' }}
            labelRowsPerPage={t(`Rows per page:`)}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t(`of`)} ${count}`}
            count={rows.length}
            page={pageIndex}
            rowsPerPage={pageSize}
            onPageChange={(event, newPage) => gotoPage(newPage)}
            onRowsPerPageChange={(event) => {
              setPageSize(parseInt(event.target.value, 10))
              gotoPage(0)
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </TableRow>
      ) : null}
    </MuiTableFooter>
  )
}

type DefaultTableProps = TableOptions<Record<string, unknown>> & {
  title?: string
  tableOptions?: Partial<TableOptions<Record<string, unknown>>>
  emptyTableText?: string
  isDatasetTable?: boolean
  rowClass?: string | ((row: Row) => string | undefined)
  coloredRows?: boolean
  everyOtherColumnGrey?: boolean
  initialPageSize?: number
  initialSortBy?: { id: string; desc: boolean }[]
}

export default function DefaultTable({
  columns,
  data,
  title,
  isDatasetTable,
  everyOtherColumnGrey,
  emptyTableText,
  tableOptions,
  rowClass,
  coloredRows,
  initialPageSize,
  initialSortBy,
}: DefaultTableProps): ReactElement {
  if (isDatasetTable) {
    return (
      <TableProvider
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        initialPageSize={initialPageSize ?? 25}
        initialSortBy={initialSortBy}
      >
        {title ? (
          <Typography variant="h1" className={styles.CustomTable_Title}>
            {title}
          </Typography>
        ) : null}
        <div className={styles.CustomTable_Table__overflow}>
          <Table>
            <TableHead everyOtherColumnGrey={everyOtherColumnGrey} />
            <TableBody
              emptyTableText={emptyTableText}
              rowClass={rowClass}
              coloredRows={coloredRows}
              everyOtherColumnGrey={everyOtherColumnGrey}
            />
            <TableFooter />
          </Table>
        </div>
      </TableProvider>
    )
  }

  return (
    <TableProvider
      data={data}
      initialPageSize={initialPageSize ?? 10}
      columns={columns}
      tableOptions={tableOptions}
      initialSortBy={initialSortBy}
    >
      {title ? (
        <Typography variant="h1" className={styles.CustomTable_Title}>
          {title}
        </Typography>
      ) : null}
      <Table>
        <TableHead />
        <TableBody emptyTableText={emptyTableText} rowClass={rowClass} coloredRows={coloredRows} />
        <TableFooter />
      </Table>
    </TableProvider>
  )
}