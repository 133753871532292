import React, { ReactElement, useRef } from 'react'

import { Icon, Tooltip } from '@mui/material'
import { Instance } from '@popperjs/core'
import i18n from 'i18next'

// eslint-disable-next-line no-restricted-imports
import { FILLER_EVENT_ID } from '../../BarCalendar'
// eslint-disable-next-line no-restricted-imports
import { Event } from '../../BarCalendar.types'
import EventTooltip from '../BCEventTooltip/BCEventTooltip'

import styles from './BCEventBar.module.less'

type EventBarProps = {
  event: Event
  width: string
  onClick?: (setting: Event) => void
}

export default function EventBar({
  event,
  width,
  onClick,
}: EventBarProps): ReactElement {
  // Make tooltip follow cursor.
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const popperRef = useRef<Instance>(null)
  const areaRef = useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>): void => {
    positionRef.current = { x: event.clientX, y: event.clientY }

    if (popperRef.current != null) {
      popperRef.current.update()
    }
  }


  const name = event.name || 'NO NAME'
  let styleClass = styles.EventBar
  if (typeof event.id === 'string' && event.id.includes('filler')) {
    styleClass = styles.EventBar_Filler
  }
  let turnicatedStyle = ''
  if (event.isTurnicatedStart && event.isTurnicatedEnd) {
    turnicatedStyle = styles.EventBar_TurnicatedBoth
  } else if (event.isTurnicatedStart) {
    turnicatedStyle = styles.EventBar_TurnicatedStart
  } else if (event.isTurnicatedEnd) {
    turnicatedStyle = styles.EventBar_TurnicatedEnd
  }

  const content = (
    <div
      onClick={() => {
        if (onClick) {
          onClick(event)
        }
      }}
      className={`${styleClass} ${turnicatedStyle}`}
      style={{
        backgroundColor: event.color,
        minWidth: width,
        width: width,
        maxWidth: width,
      }}
    >
      <div className={styles.EventBar_Title}>
        {i18n.t(name)}
        {event.icon && (
          <Icon fontSize='small' className={`${event.icon} ${styles.EventBar_Icon}`} />
        )}
      </div>
    </div>
  )

  // Filler should not have tooltip.
  if (event.id == FILLER_EVENT_ID) {
    return content
  }

  return (
    <Tooltip
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current?.getBoundingClientRect().y,
              0,
              0
            )
          },
        },
      }}
      placement='top'
      classes={{tooltip: styles.EventBar_Tooltip, popper: styles.Popper}}
      title={<EventTooltip event={event}/>}
    >
      <div
        ref={areaRef}
        onMouseMove={handleMouseMove}
        className={styles.EventBar__outline_none}
      >
        {content}
      </div>
    </Tooltip>
  )
}