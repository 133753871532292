import React, { ReactElement, useEffect } from 'react'

import { postStat } from 'api/stats/stats.api'
import { clearChartState } from 'store/chart/chart'
import { dynamicClassName } from 'styles/helper'

import styles from './Tabs.module.less'
import { getTabFromStorage } from './getTabFromStorage'

type TabTitle = string

type TabListProps = {
  items: TabTitle[]
  selectedTab: number
  onClick: (index: number) => void
  innerLeftPadding?: boolean
  marginBottom?: boolean
}

export function TabList(props: TabListProps): ReactElement {
  const {
    items,
    selectedTab,
    onClick,
    innerLeftPadding,
    marginBottom = true,
  } = props

  return (
    <div
      className={dynamicClassName({
        [styles.Tabs]: true,
        [styles.Tabs__marginBottom]: marginBottom,
      })}
    >
      <div className={styles.Tabs_BorderBottom} />

      {items.map((item, index) => {
        const isTabActive = selectedTab === index

        return (
          <div
            key={`tab_${index}`}
            className={dynamicClassName({
              [styles.Tabs_Tab]: true,
              [styles.Tabs_Tab__active]: isTabActive,
              [styles.Tabs_Tab__paddingLeft]: index === 0 && !!innerLeftPadding,
            })}
            onClick={() => {
              if (isTabActive) {
                return
              }
              postStat('tab', `navigate-to-tab-${item ?? index}`)
              onClick(index)
            }}
          >
            <div
              className={dynamicClassName({
                [styles.Tabs_Tab_Title]: true,
                [styles.Tabs_Tab_Title__active]: isTabActive,
              })}
            >
              {item}
              {index === selectedTab && <div className={styles.Tabs_Tab_BorderBottom} />}
            </div>
          </div>
        )
      }
      )}
    </div>
  )
}

export type TabItem = {
  title: TabTitle
  children: ReactElement[] | ReactElement
}


type TabsProps = {
  items: TabItem[]
  innerLeftPadding?: boolean
  marginBottom?: boolean
  uniqueStorageKey: string
  onChange?: (index: number) => void
}

export default function Tabs({ items, innerLeftPadding, marginBottom = true, onChange, uniqueStorageKey }: TabsProps): ReactElement {
  const localStorageKey = `activeTab_${uniqueStorageKey}`
  const savedTabIndex = getTabFromStorage(localStorageKey, 0)
  const initialTab = savedTabIndex < items.length ? savedTabIndex : 0

  const [activeTab, setActiveTab] = React.useState(initialTab)
  const activeChildren = items[activeTab]?.children

  useEffect(() => {
    localStorage.setItem(localStorageKey, activeTab.toString())
  }, [activeTab, localStorageKey])

  function onTabChange(index: number): void {
    if (onChange) {
      onChange(index)
    }

    // Changing tabs should have the same effect as changing module or reloading content. But Charts won't completely update on state alone, we need to trigger the clear chart state as well. See: https://app-eu.wrike.com/open.htm?id=1266563243
    clearChartState()
  }

  return (
    <>
      <TabList
        items={items.map((item) => item.title)}
        selectedTab={activeTab}
        onClick={(index) => {
          setActiveTab(index)
          onTabChange(index)
        }}
        innerLeftPadding={innerLeftPadding}
        marginBottom={marginBottom}
      />
      {activeChildren && <div className={styles.Tabs_Body} key={activeTab}>{activeChildren}</div>}
    </>
  )
}
