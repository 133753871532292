export function systemIsSystem(system: System | null): system is System {
  if (!system) {
    return false
  }
  return `id` in system
}

export function systemIsDigitalTwinSystem(system: System | null): system is DigitalTwinSystem {
  return system !== null && 'primary_digital_twin' in system
}

export function systemIsOptModelSystem(system: System | null): system is OptModelSystem {
  return system !== null && 'primary_opt_model' in system
}
