import { ReactElement, useEffect, useState } from 'react'

import { useUiConfigVersions } from 'api/uiConfig/uiConfig.api'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { DeepReadonly } from 'utils/types'

import { DiffEditor } from '@monaco-editor/react'
import { Select, MenuItem, InputLabel, FormControl, ListItemIcon } from '@mui/material'
import { useSnapshot } from 'valtio'

import UiConfigBadge from '../UiConfigBadge/UiConfigBadge'

interface Props {
  config: DeepReadonly<UiConfig>
}

interface VersionSelectProps {
  selectedConfig: DeepReadonly<UiConfig> | null
  versions: DeepReadonly<UiConfig>[]
  onSelect: (uiConfig: DeepReadonly<UiConfig>) => void
}

function useVersionsOfConfig(config: DeepReadonly<UiConfig>): {
  data: DeepReadonly<UiConfig>[]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
} {
  const snap = useSnapshot(uiConfigStore)
  const queryResult = useUiConfigVersions(config.uid)

  const versionUids = config.id 
    ? snap.versions[config.id] || [] 
    : []
  const sortByMostRecent = (a: DeepReadonly<UiConfig>, b: DeepReadonly<UiConfig>): number => { 
    if (!a.version || !b.version) return 0
    
    return b.version - a.version
  }
  const uiConfigs = versionUids.map((versionUid) => snap.uiConfigs[versionUid]).sort(sortByMostRecent)

  return {
    isLoading: queryResult.isLoading,
    isError: queryResult.isError,
    isSuccess: queryResult.isSuccess,
    data: uiConfigs,
  }
}

function uiConfigLabel(uiConfig: DeepReadonly<UiConfig>): string {
  return `(${uiConfig.id}.${uiConfig.version}) ${uiConfig.component}`
}

function UIConfigLabelWithBadge({ uiConfig }: { uiConfig: DeepReadonly<UiConfig> }): ReactElement {
  return (
    <>
      {uiConfigLabel(uiConfig)}
      <UiConfigBadge uiConfig={uiConfig} absolute={false} />
    </>
  )
}

export function VersionSelect({
  selectedConfig,
  onSelect,
  versions: configVersions,
}: VersionSelectProps): ReactElement {
  return (
    <FormControl sx={{ m: 1, minWidth: 200 }}>
      <InputLabel id="version-select-label">Select a version</InputLabel>
      <Select
        labelId="version-select-label"
        value={selectedConfig ? selectedConfig.uid : ''}
        sx={{ display: 'flex', alignItems: 'center' }}
        label="Version"
        autoWidth
        onChange={(event) => {
          const selectedConfig = configVersions.find((uiConfig) => uiConfig.uid === event.target.value)
          if (selectedConfig) {
            onSelect(selectedConfig)
          }
        }}
      >
        {configVersions.map((uiConfig) => (
          <MenuItem key={uiConfig.uid} value={uiConfig.uid}>
            {uiConfigLabel(uiConfig)}
            <ListItemIcon sx={{ ml: 1 }}>
              <UiConfigBadge uiConfig={uiConfig} absolute={false} />
            </ListItemIcon>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function isEdited(config: DeepReadonly<UiConfig>, versions: DeepReadonly<UiConfig>[]): boolean {
  const versionConfigIsBasedOf = versions.find((version) => version.version === config.version)
  return versionConfigIsBasedOf ? JSON.stringify(versionConfigIsBasedOf) !== JSON.stringify(config) : false
}

export function CompareUiConfigVersions({ config }: Props): ReactElement {
  const [selectedConfig, setSelectedConfig] = useState<DeepReadonly<UiConfig> | null>(null)
  const { isLoading, isError, isSuccess, data: configVersions } = useVersionsOfConfig(config)

  // Set a default config to compare against if none is selected, once the versions are loaded
  useEffect(() => {
    if (isSuccess && !selectedConfig && configVersions.length > 0) {
      // Corresponds to the most recent version of the config
      setSelectedConfig(configVersions[0])
    }
  }, [isSuccess, configVersions, selectedConfig])

  if (isLoading) {
    return <p>Fetching versions of config...</p>
  }

  if (isError) {
    return <p>Failed to fetch earlier versions of config</p>
  }

  if (isSuccess && configVersions.length === 0) {
    return <p>No earlier versions of config found</p>
  }

  return (
    <div>
      <VersionSelect
        versions={configVersions}
        selectedConfig={selectedConfig}
        onSelect={(config) => setSelectedConfig(config)}
      />
      {config && selectedConfig && (
        <>
          <p>
            Comparing selected <UIConfigLabelWithBadge uiConfig={selectedConfig} /> (left side) against{' '}
            {isEdited(config, configVersions) ? 'edit based on' : ''} <UIConfigLabelWithBadge uiConfig={config} /> (right side)
          </p>
          <DiffEditor
            height="90vh"
            language="json"
            original={JSON.stringify(selectedConfig, null, 2)}
            modified={JSON.stringify(config, null, 2)}
            options={{
              readOnly: true,
            }}
          />
        </>
      )}
    </div>
  )
}
