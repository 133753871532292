import Datetime from 'utils/datetime/datetime'

import { DateValue, ZonedDateTime, parseAbsoluteToLocal } from '@internationalized/date'
import moment, { Moment } from 'moment'

// TODO: Improve naming

export type CompatibleDate = Moment | ISODateTime | string | null | undefined

const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
export function toDateValue(value: CompatibleDate): ZonedDateTime | null | undefined{
  // TODO: look into cleanup

  // If null or undefined, return value
  if (!value && typeof value !== 'string') return value
  if (moment.isMoment(value) && !value.isValid()) {
    return null
  }

  const date = moment.isMoment(value) ? value : moment(value)
  return parseAbsoluteToLocal(date.format(ISO_FORMAT))
}

type TransformDate = (date: Moment) => Moment

export function toISODateTime(value: DateValue | null | undefined, transform?: TransformDate): ISODateTime | null | undefined{
  if (!value) return value
  const momentDate = moment(toMomentCompatibleDate(value))
  const transformedDate = transform ? transform(momentDate) : momentDate

  return Datetime.toISOString(transformedDate)
}

function toMomentCompatibleDate(val: DateValue): string {
  if (val instanceof ZonedDateTime) { 
    return val.toAbsoluteString()
  } else {
    return val.toString()
  }
}