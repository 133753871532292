import React, { ReactElement } from 'react'

import { Tab, Tabs } from '@mui/material'

type StyledTabsProps = {
  value: number
  onChange: (event: React.ChangeEvent<unknown>, newValue: number) => void
}

export const StyledTabs = (props: StyledTabsProps): JSX.Element => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div /> }}
    sx={{
      marginTop: 1,
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    }}
  />
)

type StyledTabProps = {
  label: ReactElement
}

export const StyledTab = (props: StyledTabProps): JSX.Element => (
  <Tab
    className="Tab"
    {...props}
    sx={{
      borderColor: `#767676`,
      borderTop: 1.2,
      borderLeft: 1.2,
      borderRight: 1.2,
      borderStyle: `solid`,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      textTransform: `uppercase`,
      color: `#767676`,
      fontSize: 15,
      marginRight: 1,
      marginLeft: 1,
      backgroundColor: `white`,
      boxShadow: `0 0 5px 0 rgba(0,0,0,0.2)`,
    }}
  />
)
