import { apiClient } from 'api/apiClient/apiClient'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import Datetime from 'utils/datetime/datetime'

import moment, { Moment } from 'moment'
import { useQuery, UseQueryOptions } from 'react-query'

export type HeatSupplierWithoutProps = {
  id: number
  display_name: string
  is_fictive: boolean
}

export type HeatSupplier = HeatSupplierWithoutProps & {
  props: ObjectProperty[]
}

export type DigitalTwinHeatSupplier = HeatSupplierWithoutProps & {
  name: string
  unit_name: string
}

export type DeclinedHours = {
  planned: number
  unplanned_winter: number
  unplanned_summer: number
}

export type HeatSupplierData = {
  marginal_cost: number
  reference_time: string
  time: string
  optimized_availability: number
  temperature_demand: number
  availability: number
}

export const HEAT_SUPPLIERS_QUERY_KEY = `heatSuppliers`
export function useHeatSuppliers(
  queryConfig?: UseQueryOptions<
    HeatSupplier[], 
    Error,
    HeatSupplier[], 
    [string, { belongs_to_system?: number }]
  >
) {
  const { systemId } = useAuth()
  return useQuery(
    [
      HEAT_SUPPLIERS_QUERY_KEY,
      {
        belongs_to_system: systemId,
      },
    ],
    () =>
      apiClient<HeatSupplier[]>(`heat_suppliers`, {
        params: {
          belongs_to_system: systemId,
        },
      }),
    {
      ...queryConfig,
      enabled: !!systemId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
    }
  )
}

export function useDigitalTwinHeatSuppliers() {
  const { systemId } = useAuth()
  return useQuery([HEAT_SUPPLIERS_QUERY_KEY, { belongs_to_system: systemId }], () =>
    apiClient<DigitalTwinHeatSupplier[]>(`heat_suppliers_vf`, {
      params: { system_id: systemId },
    })
  )
}

export const HEAT_SUPPLIERS_DATA_QUERY_KEY = `heatSuppliersData`
export function useHeatSupplierData(
  startTime: Moment,
  endTime: Moment,
  excludeDeclinedHours: boolean,
  currentSupplierId?: number,
  isDigitalTwin = false
) {
  const { systemId } = useAuth()

  const url = isDigitalTwin ? `heat_suppliers_vf/${currentSupplierId}/data` : `heat_suppliers/${currentSupplierId}/data`

  return useQuery(
    [
      HEAT_SUPPLIERS_DATA_QUERY_KEY,
      {
        id: currentSupplierId,
      },
      {
        belongs_to_system: systemId,
        start_time: startTime.format(`YYYY-MM-DD HH:mm`),
        end_time: endTime.format(`YYYY-MM-DD HH:mm`),
        exclude_declined_hours: excludeDeclinedHours,
      },
    ],
    () =>
      apiClient<HeatSupplierData[]>(url, {
        params: {
          belongs_to_system: systemId,
          start_time: startTime.format(`YYYY-MM-DD HH:mm`),
          end_time: endTime.format(`YYYY-MM-DD HH:mm`),
          exclude_declined_hours: excludeDeclinedHours,
        },
      }),
    {
      enabled: !!systemId && currentSupplierId !== undefined,
    }
  )
}

export function useDeclinedHours(year: number, id: number, isDigitalTwin = false) {
  const startOfYear = Datetime.toISOString(moment().year(year).startOf(`year`))
  const endOfYear = Datetime.toISOString(moment().year(year).endOf(`year`))

  const url = isDigitalTwin ? `heat_suppliers_vf/${id}/get_declined_hours` : `heat_suppliers/${id}/declined_hours`

  return useQuery(
    [
      HEAT_SUPPLIERS_DATA_QUERY_KEY,
      `declined_hours`,
      {
        id: id,
        start_time: startOfYear,
        end_time: endOfYear,
      },
    ],
    () =>
      apiClient<DeclinedHours>(url, {
        params: {
          start_time: startOfYear,
          end_time: endOfYear,
        },
      })
  )
}
