import React, { ReactElement, useMemo, useState } from 'react'

import filterStore, { resetGrouping, setGrouping } from 'store/filter/filter'
import { Button, TextField } from 'ui/atoms'

import { Checkbox, Collapse, Icon } from '@mui/material'
import Radio from '@mui/material/Radio'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'
import { getAvailableFilters, getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import { getTopLevelOptions, MultilevelDropdownOptions } from './MultilevelDropdown.helper'
import styles from './MultilevelDropdown.module.less'

type CheckedState = 'unchecked' | 'indeterminate' | 'checked'

type MultiLevelDropdownSubItemProps = {
  checked: boolean
  onChecked: (changed: string[], childChecked: boolean) => void
  label: string
  translations?: { [key: string]: string }
  parentPath: string
  handleDoubleClick: (value: string) => void
}

function MultiLevelDropdownSubItem({
  checked,
  onChecked,
  label,
  translations,
  parentPath,
  handleDoubleClick,
}: MultiLevelDropdownSubItemProps): ReactElement {
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changed = [parentPath + '/' + label]
    onChecked(changed, event.target.checked)
  }

  return (
    <div className={classNames([styles.Dropdown__Row, styles.Dropdown__Row__Card])}>
      <Checkbox
        sx={{
          padding: 0,
        }}
        classes={{
          root: styles.Dropdown_Checkbox,
        }}
        checked={checked}
        onChange={handleChecked}
        onClick={(event) => {
          event.stopPropagation()
        }}
        onDoubleClick={() => handleDoubleClick(parentPath + '/' + label)}
      />
      {getPrettyName(label, translations)}
    </div>
  )
}

type MultiLevelDropdownTopLevelItemProps = {
  onChecked: (changed: string[], originChecked: boolean, clearOthers?: boolean) => void
  label: string
  options: string[]
  translations?: { [key: string]: string }
  hiddenFilters?: string[]
  values: string[]
  availableFilters: Set<string>
  handleDoubleClick: (value: string) => void
}

function MultiLevelDropdownTopLevelItem({
  onChecked,
  label,
  options,
  translations,
  hiddenFilters,
  values,
  availableFilters,
  handleDoubleClick,
}: MultiLevelDropdownTopLevelItemProps): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [filterText, setFilterText] = useState('')
  const route = getRoute()
  const { t, i18n } = useTranslation()
  const selectedLanguage = i18n.language

  const checkedState: CheckedState = useMemo(() => {
    const allSubOptionsChecked = options.every((sublevelOption) => values.includes(`${label}/${sublevelOption}`))
    const someSubOptionsChecked = options.some((sublevelOption) => values.includes(`${label}/${sublevelOption}`))

    if (allSubOptionsChecked) {
      return 'checked'
    } else if (someSubOptionsChecked) {
      return 'indeterminate'
    } else {
      return 'unchecked'
    }
  }, [label, options, values])

  const handleChecked = (checked: boolean): void => {
    const changed = options.map((t) => label + '/' + t)
    onChecked(changed, checked)
  }

  const translatedChildren = useMemo(() => {
    return new Map(options.map((child) => [child, getPrettyName(child, translations)]))
    // Disable eslint since there's an implicit dependency on the selected language
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, translations, selectedLanguage])
  const getTranslation = (child: string): string => translatedChildren.get(child) ?? child

  const filteredChildren = useMemo(() => {
    return options.filter((child) => {
      const translatedChild = translatedChildren.get(child) ?? child
      const hiddenTags = hiddenFilters ?? []
      return (
        translatedChild.toLowerCase().includes(filterText.toLowerCase()) &&
        !hiddenTags.includes(`${label}/${child}`)
      )
    })
  }, [filterText, hiddenFilters, label, options, translatedChildren])

  const filterSnap = useSnapshot(filterStore)

  const groupingPerRoute: string = filterSnap.groupingPerRoute[route] || ''
  const prettyName = getPrettyName(label, translations)
  return (
    <div className={classNames([styles.Dropdown, styles.Dropdown__TopLevel__Margin])}>
      <div
        className={classNames([styles.Dropdown__Row, styles.Dropdown__TopLevel])}
        onClick={() => setExpanded(!expanded)}
      >
        <Checkbox
          sx={{
            padding: 0,
            marginRight: '10px',
          }}
          checked={checkedState === 'checked'}
          indeterminate={checkedState === 'indeterminate'}
          onChange={(event) => {
            handleChecked(event.target.checked)
          }}
          onClick={(event) => {
            event.stopPropagation()
          }}
        />
        {prettyName}
        {filteredChildren.length > 0 && (
          <>
            <div className={styles.Dropdown__Spacer} />
            <Icon fontSize="small" className={expanded ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
          </>
        )}
      </div>
      {expanded && (
        <span className={styles.Dropdown__SearchField}>
          <TextField
            autoFocus={true}
            value={filterText}
            variant="outlined"
            onClickAway={() => setExpanded(!expanded)}
            onChange={(value, e) => {
              setFilterText(`${value}`)
              e?.preventDefault()
              e?.stopPropagation()
            }}
            data-testid="dropdown-search-field"
          />
        </span>
      )}
      {filteredChildren.length > 0 && (
        <Collapse in={expanded} className={styles.Dropdown__Collapse}>
          <div
            className={styles.Dropdown__Card}
            style={{
              maxHeight: expanded ? 'none' : '0px',
              display: expanded ? 'block' : 'none',
            }}
          >
            {Object.keys(filterSnap.groupingPerRoute).some((key) => key.includes(route)) && (
              <div className={styles.Dropdown__Card__RadioButton}>
                <Radio
                  checked={groupingPerRoute === label}
                  onClick={() => {
                    if (groupingPerRoute === label) {
                      resetGrouping()
                    } else {
                      setGrouping(label)
                    }
                  }}
                />
                {t('Group filter')}
              </div>
            )}
            
            {filterText === '' && (
              <div className={classNames([styles.Dropdown__Row, styles.Dropdown__Row__Card])}>
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  checked={checkedState === 'checked'}
                  indeterminate={checkedState === 'indeterminate'}
                  onChange={(event) => {
                    handleChecked(event.target.checked)
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                />
                {t('Select all')}
              </div>
            )}

            {filteredChildren
              .filter((child) => availableFilters.has(`${label}/${child}`))
              .sort((a, b) => getTranslation(a).localeCompare(getTranslation(b), selectedLanguage))
              .map((child, index) => (
                <MultiLevelDropdownSubItem
                  key={`${child}.${index}`}
                  checked={values.includes(`${label}/${child}`)}
                  onChecked={onChecked}
                  translations={translations}
                  label={child}
                  parentPath={label}
                  handleDoubleClick={handleDoubleClick}
                />
              ))}
            {filterText !== '' && (
              <div
                className={classNames([styles.Dropdown__Row, styles.Dropdown__Row__Card, styles.Dropdown__Row__Button])}
              >
                <Button
                  secondary
                  hideBorder
                  justifyCenter
                  alignCenter
                  noPadding
                  onClick={() => {
                    onChecked(
                      filteredChildren.map((child) => `${label}/${child}`),
                      true,
                      true
                    )
                    setFilterText('')
                  }}
                >
                  {t('Mark only selected filters')}
                </Button>
              </div>
            )}
          </div>
        </Collapse>
      )}
    </div>
  )
}

function hasSameTopLevelTag(tagA: string, tagB: string): boolean {
  return tagA.split('/')[0] === tagB.split('/')[0]
}

// Used to sort top level tags according to the order in sortingOrder.
// Tags that are not in sortingOrder are sorted alphabetically at the end.
function compareTopLevelTags(
  labelA: string,
  labelB: string,
  sortingOrder: string[],
  translations?: { [key: string]: string }
): number {
  if (sortingOrder.includes(labelA) && sortingOrder.includes(labelB)) {
    return sortingOrder.indexOf(labelA) - sortingOrder.indexOf(labelB)
  } else if (sortingOrder.includes(labelA)) {
    return -1
  } else if (sortingOrder.includes(labelB)) {
    return 1
  } else {
    return getPrettyName(labelA, translations).localeCompare(
      getPrettyName(labelB, translations)
    )
  }
}

type MultiLevelDropdownProps = {
  options: MultilevelDropdownOptions // All available options, including those that are not currently selected.
  translations?: { [key: string]: string }
  onSelected: (value: string[]) => void
  previousLevel?: string
  forceChecked?: boolean
  values?: string[] // The currenly selected options (e.g. ['top_level_a/sub_level_a', 'top_level_a/sub_level_b', ...]).
  hiddenFilters?: string[] // Filters that should be hidden (e.g. ['unit', 'fuel/No Fuel', ...])
  topLevelSortingOrder?: string[] // Determines the order of the top level tags. Tags that are not in this list are sorted alphabetically at the end.
  items: string[][] // A collection of tags for each of the items that are filtered by this component to enable "filtering of filters".
}

export default function MultiLevelDropdown({
  options,
  translations,
  onSelected,
  values = [],
  hiddenFilters = [],
  topLevelSortingOrder = [],
  items,
}: MultiLevelDropdownProps): ReactElement {
  // Check which of the available filters are relevant with the current values selected.
  // Any filter that does not change anything (units that are in a hidden node, properties that only exist on hidden units, etc) should be hidden.
  const availableFilters = getAvailableFilters(items, values, new Set(hiddenFilters))

  const onDoubleClickHandler = (value: string) => () => {
    onSelected([...values.filter((v) => !hasSameTopLevelTag(v, value)), value])
  }

  const topLevelOptions = useMemo(
    () => getTopLevelOptions(availableFilters, options, hiddenFilters),
    [availableFilters, hiddenFilters, options]
  )

  return (
    <div className={classNames([styles.FlexRow])}>
      {topLevelOptions
        .sort((a, b) => compareTopLevelTags(a.label, b.label, topLevelSortingOrder, translations))
        .map((item, index) => (
          <MultiLevelDropdownTopLevelItem
            key={`${item.label}.${index}`}
            handleDoubleClick={onDoubleClickHandler}
            onChecked={(changed, originChecked, clearOthers) => {
              let newValues: string[]
              if (originChecked) {
                // If clearOthers is true, then all other tags that are on the same top level as the checkbox that was clicked should be removed.
                const existingValues =
                  clearOthers && changed.length > 0
                    ? values.filter((value) => !hasSameTopLevelTag(value, changed[0]))
                    : values

                // The checkbox that was clicked is now checked, so tags representing all of its children should be added to the existing values.
                // Transform "array -> set -> array" in order to remove duplicates.
                newValues = Array.from(new Set([...existingValues, ...changed]))
              } else {
                // The checkbox that was clicked is now unchecked, so tags representing all of its children should be removed from the current values.
                newValues = values.filter((s) => !changed.includes(s))
              }

              onSelected(newValues)
            }}
            values={values}
            label={item.label}
            options={options[item.label]}
            translations={translations}
            hiddenFilters={hiddenFilters}
            availableFilters={availableFilters}
          />
        ))}
    </div>
  )
}
