import { useRef, useState, useMemo, ReactElement } from 'react'


import { 
  useRecentOptJobs,
  useQueueItemResults,
  QueueItemResultsResponse, 
} from 'api/optJobs/optJobs.api'
import globalStore from 'store/global/global'
import { dynamicClassName } from 'styles/helper'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import HeightAdjuster from 'ui/atoms/HeightAdjuster/HeightAdjuster'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import Dropdown from 'ui/components/Dropdown/Dropdown' 
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import styles from 'ui/uiConfig/components/Chart/Chart.module.less'
import ReactChartJS2 from 'ui/uiConfig/components/Chart/chart.init'
import datetime from 'utils/datetime/datetime'

import { Grid, SelectChangeEvent } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { ChartDataset } from 'chart.js' 
import { useSnapshot } from 'valtio'

import { 
  walkDepthFirst, 
  extractTreeSelectItems,
} from 'views/OptimizationAnalysisView/utils'

const PATH_SEPARATOR = '-'

function Header(): ReactElement {
  return (
    <ModuleHeader infoHeader='Optimization Analysis' />
  )
}

function Chart({ 
  maxWidth,
  labels,
  datasets,
}: { 
  maxWidth: number, 
  labels: string[], 
  datasets: ChartDataset[],
}): ReactElement {
  const chartRef = useRef(null)
  const [height, setHeight] = useState(600)

  return (
    <div className={styles.Chart} style={{ maxWidth: `${maxWidth}px`, paddingTop: '45px' }}>
      <div
        className={dynamicClassName({
          [styles.Chart_Canvas]: true,
          [styles.Chart_Canvas__empty]: false,
        })}
        style={{ height }}
      >
        <ReactChartJS2
          ref={chartRef}
          type='line'
          data={{ labels, datasets }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
            },
          }}
        />
      </div>
      <HeightAdjuster height={height} minHeight={200} maxHeight={1000} onChange={setHeight} />
    </div>
  )
}

export default function OptimizationAnalysisView(): ReactElement {
  const globalSnap = useSnapshot(globalStore)

  const { 
    data: recentOptJobs,
    isFetching: isRecentOptJobsLoading, 
  } = useRecentOptJobs({ status: 'Finished' })
  const [selectedOptJobId, setSelectedOptJobId] = useState(recentOptJobs?.[0]?.id)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const { data: queueItemResults } = useQueueItemResults({ optJobId: selectedOptJobId })
  const dataPointValuesByTimeAndPath = useMemo(() => getDataPointValuesByTimeAndPath(queueItemResults), [queueItemResults])
  const chartProps: {
    labels: string[],
    datasets: ChartDataset[],
    maxWidth: number,
  } = useMemo(() => getChartProps(
    dataPointValuesByTimeAndPath,
    selectedItems,
    globalSnap.bodyWidth), 
  [dataPointValuesByTimeAndPath, selectedItems, globalSnap.bodyWidth])

  const allPaths = Object.keys(dataPointValuesByTimeAndPath).length > 0 
    ? Object.keys(dataPointValuesByTimeAndPath[Object.keys(dataPointValuesByTimeAndPath)[0]])
    : []
  const treeSelectItems = extractTreeSelectItems(allPaths, PATH_SEPARATOR)

  return (
    <View header={<Header />}>
      <Grid item xs={6}>
        <CardWidget id='1' title='Cost'>
          <Grid item md={6} lg={6} paddingBottom={5}>
            { isRecentOptJobsLoading 
              ? (<LoadingPlaceholderContainer height={24} width={56} />)
              : (
                <Dropdown
                  fullWidth
                  label='Opt job'
                  items={recentOptJobs ? recentOptJobs.map(optJob => ({ 
                    value: optJob.id, 
                    label: `${datetime.toLocalTime(optJob.created_at as ISODateTime)} - ${optJob.opt_job_type}` + (optJob.opt_model ? ` - ${optJob.opt_model} (OptModel)` : optJob.digital_twin ? ` - ${optJob.digital_twin} (Digital Twin)` : ''),
                  })) : []}
                  value={selectedOptJobId as number}
                  handleChange={(e: SelectChangeEvent<number>) => {
                    setSelectedOptJobId(parseInt(e.target.value as string, 10))
                  }}
                />
              )
            }
          </Grid>
          <RichTreeView 
            items={treeSelectItems} 
            checkboxSelection={ true }
            multiSelect={ true }
            onSelectedItemsChange={(event, selected) => setSelectedItems(selected)}
          />
          
        </CardWidget>
      </Grid>
      <Grid item xs={6}>
        <CardWidget id='2' title='Chart'>
          <Chart {...chartProps }
          />
        </CardWidget>
      </Grid>
    </View>
  )
}

function getDataPointValuesByTimeAndPath(queueItemResults: QueueItemResultsResponse | undefined) : { 
  [key: string]: { [key: string]: number }
} {
  const res: { 
    [key: string]: { [key: string]: number }
  } = {}
  queueItemResults?.forEach(([_, queueItemResult]) => {
    queueItemResult.solution.data
      .forEach(dataPoint => {
        const pathValueTuples = [
          ...walkDepthFirst('costs', dataPoint.costs),
          ...walkDepthFirst('production', dataPoint.production),
        ]
        for (const [path, value] of pathValueTuples) {
          const property = path.join(PATH_SEPARATOR)
          res[dataPoint.time] = res[dataPoint.time] || {}
          res[dataPoint.time][property] = value
        }    
      })
  })
  return res
}

function getChartProps(
  dataPointValuesByTimeAndPath: { [key: string]: { [key: string]: number } },
  selectedItems: string[],
  maxWidth: number
): { labels: string[], datasets: ChartDataset[], maxWidth: number } {
  const labels: string[] = []
  const dataPointsToPlotByPath: { [key: string]: number[] } = {}
  Object.keys(dataPointValuesByTimeAndPath).forEach(time => {
    if (selectedItems && selectedItems.length > 0) {
      labels.push(datetime.toLocalTime(time as ISODateTime))
    }

    Object.keys(dataPointValuesByTimeAndPath[time]).forEach(property => {
      if (selectedItems && selectedItems.includes(property)) {
        dataPointsToPlotByPath[property] = dataPointsToPlotByPath[property] || []
        dataPointsToPlotByPath[property].push(dataPointValuesByTimeAndPath[time][property])
      }
    })
  })
  const datasets: ChartDataset[] = Object.keys(dataPointsToPlotByPath).map(path => ({
    label: path,
    data: dataPointsToPlotByPath[path],
  }))

  return { labels, datasets, maxWidth }
}