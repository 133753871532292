import React, { ReactElement, PropsWithChildren } from 'react'

import { Button, Icon, CircularProgress, ButtonProps } from '@mui/material'
import classNames from 'classnames'

import styles from './ModuleHeaderButton.module.less'

type ModuleHeaderButtonProps = ButtonProps & {
  icon: string
  loading?: boolean
}

export default function ModuleHeaderButton({
  disabled,
  onClick,
  children,
  icon,
  loading,
  ...rest
}: PropsWithChildren<ModuleHeaderButtonProps>): ReactElement {
  return (
    <Button
      color="primary"
      variant="contained"
      disabled={loading || disabled}
      onClick={onClick}
      className={styles.ModuleHeaderButton_Button}
      startIcon={
        loading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <Icon className={classNames(icon, styles.ModuleHeaderButton_ButtonIcon)} fontSize="small" />
        )
      }
      {...rest}
    >
      {children}
    </Button>
  )
}
