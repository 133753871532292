import React, { ReactElement, useState } from 'react'

import IconButton from 'ui/atoms/Icon/Icon'
import Datetime from 'utils/datetime/datetime'

import { Typography, Grid, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatEndTime, formatStartTime } from 'helpers/dateTime.helper/dateTime.helper'
import EventPostViewModal from 'views/EventsView/components/EventPostViewModal/EventPostViewModal'
import { EventPost } from 'views/EventsView/components/events.helper'

import styles from './SettingsListItem.module.less'

type SettingsListItemProps = {
  name: string[]
  value: string
  startTime: string
  endTime: string | null
  comment: string | null
  settings_value?: string
  unit?: string
  measurementUnit?: string | null
  operationalEvent?: EventPost
}

export function SettingsListItem({
  name,
  value,
  startTime,
  endTime,
  comment,
  settings_value = '',
  unit = '',
  operationalEvent,
}: SettingsListItemProps): ReactElement {
  const { t } = useTranslation()
  const [openEventPost, setOpenEventPost] = useState(false)

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={styles.SettingsListItem_ListItem}
      >
        <div className={styles.SettingsListItem_ListItemIcon}>
          <Icon className={`fas fa-exclamation-circle`} fontSize="inherit" />
        </div>
        <div className={styles.SettingsListItem_ListItemText}>
          <Typography variant="body1" className={styles.SettingsListItem_Title}>
            <span className={styles.SettingsListItem_PropertyName}>
              {`${name[0]}`}
            </span>
            <span>
              {`${name[1]}: `}
            </span>
            { !Datetime.isAfter(Datetime.toISOString(startTime), Datetime.getISONow()) ?
              <>
                <span className={styles.SettingsListItem_SettingValue}>
                  {`${value.toString().split(' ').slice(0, -1).join(' ')}`}
                </span>
                <span>
                  {`${value.toString().split(' ').at(-1)}`}
                </span>
              </> :
              <>
                <span style={{ fontWeight: `bold` }}>
                  {`${settings_value} ${unit}`}
                </span>
                <span>
                  {`${t('from')} ${formatStartTime(startTime)} (${value})`}
                </span>
              </>
            }
          </Typography>
          <Typography variant="body1" className={styles.SettingsListItem_Comment}>
            <span style={{ fontWeight: `bold` }}>
              {`${t('Period')}: `}
            </span>
            {`${formatStartTime(startTime)} - ${formatEndTime(endTime)}`}
          </Typography>
          {comment && (
            <Typography variant="body1" className={styles.SettingsListItem_Comment}>
              <span style={{ fontWeight: `bold` }}>
                {`${t('Cause')}: `}
              </span>
              {comment}
            </Typography>
          )}
          {operationalEvent && (
            <Typography variant="body1" className={styles.SettingsListItem_Comment}>
              <span style={{ fontWeight: `bold` }}>{`${t('Event')}: `}</span>
              <span
                className={styles.SettingsListItem_OperationalEvent}
                onClick={() => setOpenEventPost(true)}
              >
                {operationalEvent?.name}
              </span>
              <IconButton
                icon="fal fa-calendar-week"
                size='small'
                tooltip={{ title: t('View event'), arrow: true, placement: 'right' }}
                onClick={() => setOpenEventPost(true)}
              />
            </Typography>
          )}
        </div>
      </Grid>

      {openEventPost && (
        <EventPostViewModal
          closeModal={() => setOpenEventPost(false)}
          selectedEventPostId={operationalEvent?.id}
        />
      )}
    </>
  )
}
