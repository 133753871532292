import React, { ReactElement } from 'react'

import { useShiftTeams } from 'api/shiftTeams/shiftTeams.api'
import ReactHookFormCheckbox from 'ui/components/ReactHookFormCheckbox/ReactHookFormCheckbox'
import ReactHookFormSelect from 'ui/components/ReactHookFormSelect/ReactHookFormSelect'

import { MenuItem, Grid, Icon } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'

import styles from './HandoverSelectors.module.less'

type HandoverSelectorsProps = {
  disabled?: boolean
}
export default function HandoverSelectors({ disabled }: HandoverSelectorsProps): ReactElement {
  const { data: shiftTeams } = useShiftTeams()
  const { t } = useTranslation()

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Icon className={`fal fa-sign-out`} fontSize="large" />
      </Grid>
      <Grid item>
        <ReactHookFormSelect
          name="ending_shift_team"
          label={t(`Scheduled shift team`)}
          margin="normal"
          variant="outlined"
          defaultValue=""
          className={styles.HandoverSelectors_TextField}
          disabled={disabled}
        >
          <MenuItem key={`empty_ending_team`} value={``}>
            ---
          </MenuItem>
          {shiftTeams?.map((shiftTeam) => (
            <MenuItem key={shiftTeam.name} value={shiftTeam.id}>
              {getDisplayName(shiftTeam)}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item>
        <Icon className={`fal fa-sign-in`} fontSize="large" />
      </Grid>
      <Grid item>
        <ReactHookFormSelect
          name="starting_shift_team"
          label={t(`Next shift team`)}
          margin="normal"
          variant="outlined"
          defaultValue=""
          className={styles.HandoverSelectors_TextField}
          disabled={disabled}
        >
          <MenuItem key={`empty_starting_team`} value={``}>
            ---
          </MenuItem>
          {shiftTeams?.map((shiftTeam) => (
            <MenuItem key={shiftTeam.id} value={shiftTeam.id}>
              {getDisplayName(shiftTeam)}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item>
        <ReactHookFormCheckbox
          name="representatives_present"
          label={t(`Representatives from both shifts present`)}
          defaultValue={false}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
