import React, { ReactElement, ReactNode } from 'react'

import { Card } from 'ui/atoms'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'

import i18n from 'i18next'

type AbSLabsProps = {
  children: ReactNode[]
}

const defaultProps: Omit<AbSLabsProps, `datasets` | `children`> = {}
registerUiConfigComponent(`abs_labs`, AbSLabs, {
  isSingletonComponent: true,
  title: i18n.t(`AbS Labs`),
})
registerDefaultProps(`abs_labs`, defaultProps)

export default function AbSLabs({ children }: AbSLabsProps): ReactElement {
  return (
    <>
      {children.map((child, index) => (
        <Card key={`abs_labs_${index}`} margin>
          {child}
        </Card>
      ))}
    </>
  )
}
