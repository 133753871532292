import React, { ReactElement, useState } from 'react'

import authStore from 'store/auth/auth'
import Tour from 'ui/components/Tour/Tour'

import { Accordion, AccordionDetails, AccordionSummary, Button, Icon, List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './NoPermissionErrorMessage.module.less'

type NoPermissionErrorMessageProps = {
  title: string
  message: string
}

const widgetConstants = {
  PERMISSION_ERROR_WIDGET: `permission_error_widget`,
}

export default function NoPermissionErrorMessage({ title, message }: NoPermissionErrorMessageProps): ReactElement {
  const { t } = useTranslation()

  const authSnap = useSnapshot(authStore)
  const [runTour, setRunTour] = useState<null | number>(null)

  const steps: Array<any> = [
    {
      target: widgetConstants.PERMISSION_ERROR_WIDGET,

      content: (
        <div>
          <Typography variant="h6">
            <Icon fontSize="small" className={`${`fas fa-times-circle`} ${styles.NoPremissionText_Icon_X}`} />
            {message}
          </Typography>

          <br />

          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<Icon fontSize="small" className={`fas fa-chevron-up`} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.NoPremissionText_Accordion}
            >
              <Typography>
                <Icon fontSize="small" className={`${`fas fa-check-circle`} ${styles.NoPremissionText_Icon_Check}`} />
                {t(`Your permissions:`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {authSnap?.permissionsAndDescriptions.map((item, index) => {
                  return <ListItem key={index}>{t(item.description)}</ListItem>
                })}
                <i>
                  {t(
                    `Do you have questions about your permissions? Please contact person in charge of your organisation or us via the 'Contact Us' form found at the top of the page.`
                  )}
                </i>
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      ),
      title: t(`Your permissions:`),
    },
  ]

  const handleClick = (): void => {
    setRunTour(0)
  }

  const InfoBox = (): ReactElement => (
    <div>
      <Button color="inherit" className={styles.NoPremissionText_infoButton} onClick={handleClick}>
        <Icon fontSize="small" className={`${`fas fa-info-circle`} ${styles.NoPremissionText_infoButton_Icon}`} />
        <Typography className={styles.NoPremissionText_infoButton_Text}>{title}</Typography>
      </Button>
    </div>
  )

  return (
    <div className={widgetConstants.PERMISSION_ERROR_WIDGET}>
      <InfoBox />

      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={steps}
      />
    </div>
  )
}
