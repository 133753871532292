import { apiClient } from 'api/apiClient/apiClient'
import { postProcessDatasets } from 'api/dataset/dataset.api.helper'
import authStore from 'store/auth/auth'
import Datetime from 'utils/datetime/datetime'
import getLogger from 'utils/log/log'
import sentry from 'utils/sentry/sentry'

import { createClient, Client } from '@sigholm/dbs-js-client'
import type { DataPoint as DbSDataPoint, Dataset as DbSDataset } from '@sigholm/dbs-js-client/lib/esm/index.d'
import { snapshot } from 'valtio'

import { addMissingDatasets } from 'helpers/dataset.helper/dataset.helper'
import { clone, isArray } from 'helpers/global.helper/global.helper'

import { serializeMetadataFilter } from './dbs.helper'

const log = getLogger('dbs')

export function getDbSStartAndEndTimeFromDatasetInstruction(datasetInstruction: DatasetInstruction): {
  startTime: string
  endTime: string
  createdAt?: string
  createdAtOperator?: string
  relativeCreatedAt?: string
} {
  const { filter } = datasetInstruction
  let startTime = filter.start_time as string
  let endTime = filter.end_time as string
  const relativeCreatedAt: string | undefined = filter.relative_created_at

  // Use offset if time is not present
  if (!startTime) {
    const offset = filter.offset_start_time || 0
    const negativeSign = offset < 0 ? '-' : ''
    const absOffset = Math.abs(offset)
    startTime = `${negativeSign}PT${absOffset}H`
  }
  if (!endTime) {
    const offset = filter.offset_end_time || 0
    const negativeSign = offset < 0 ? '-' : ''
    const absOffset = Math.abs(offset)
    endTime = `${negativeSign}PT${absOffset}H`
  }

  // Created at
  let createdAt: string | undefined = datasetInstruction.filter?.created_at
  let createdAtOperator: string | undefined = datasetInstruction.filter?.created_at_operator

  if (!createdAt) {
    createdAt = undefined
  }

  if (createdAt && !createdAtOperator) {
    createdAtOperator = 'lte'
  }

  return { startTime, endTime, createdAt, createdAtOperator, relativeCreatedAt }
}

function getDbSAggregateFunction(datasetInstruction: DatasetInstruction): string | undefined {
  if (!datasetInstruction.filter?.aggregate) {
    return undefined
  }

  // Do not aggregate in backend
  if (datasetInstruction.filter.aggregate_in_frontend) {
    return undefined
  }

  const aggregate = datasetInstruction.filter.aggregate
  const theSameAggregate = new Set(['latest', 'min', 'max', 'sum'])
  if (theSameAggregate.has(aggregate)) {
    return aggregate
  }

  if (aggregate === 'mean') {
    return 'avg'
  }

  throw new Error(
    `Inknown aggregation function: ${datasetInstruction.filter.aggregate} in dataset instruction: ${datasetInstruction}`
  )
}

export function filterInternalIdsByOnlyReturnIds(
  _datasetInstruction: Partial<DatasetInstruction>,
  onlyReturnIds: string[],
  returnIdRegexFilter?: string
): {
  datasetInstruction: Partial<DatasetInstruction>
  earlyReturn: boolean
} {
  // No filter to apply
  if (!onlyReturnIds.length && !returnIdRegexFilter) {
    return {
      datasetInstruction: _datasetInstruction,
      earlyReturn: false,
    }
  }

  // Not valid DbS dataset instruction
  if (!_datasetInstruction.contract?.metadata_filter?.include?.internal_id) {
    return {
      datasetInstruction: _datasetInstruction,
      earlyReturn: false,
    }
  }

  const datasetInstruction = clone(_datasetInstruction)

  // Filter by regex
  if (returnIdRegexFilter) {
    let allInternalIds: string[] = _datasetInstruction.contract.metadata_filter.include.internal_id
    if (typeof allInternalIds === 'string') {
      allInternalIds = [allInternalIds]
    }

    const regex = new RegExp(returnIdRegexFilter)

    const datasetContract = datasetInstruction.contract as DatasetContractDbS

    datasetContract.metadata_filter.include.internal_id = allInternalIds.filter((internalId) => {
      const returnId = (_datasetInstruction.contract.id_renaming || {})[internalId] || internalId

      if (isArray(returnId)) {
        for (let i = 0; i < returnId.length; i++) {
          if (regex.test(returnId[i])) {
            return true
          }
        }
      } else {
        return regex.test(returnId)
      }
    })

    // Filter id_renaming as well
    datasetContract.id_renaming = Object.entries(datasetContract.id_renaming || {}).reduce(
      (acc, [internalId, returnId]) => {
        const returnIds = isArray(returnId) ? returnId : [returnId]

        // Only keep the array if any returnId matches the regex
        const filteredReturnIds = returnIds.filter((id) => regex.test(id))

        if (filteredReturnIds.length) {
          acc[internalId] = filteredReturnIds
        }

        return acc
      },
      {} as Record<string, string | string[]>
    )
  }

  // Filter by onlyReturnIds
  if (onlyReturnIds.length) {
    const onlyReturnIdsSet = new Set(onlyReturnIds)
    let internalIds: string[] = datasetInstruction.contract.metadata_filter.include.internal_id

    if (typeof internalIds === 'string') {
      internalIds = [internalIds]
    }

    datasetInstruction.contract.metadata_filter.include.internal_id = internalIds.filter((internalId) => {
      const returnId = (datasetInstruction.contract.id_renaming || {})[internalId] || internalId

      if (isArray(returnId)) {
        for (let i = 0; i < returnId.length; i++) {
          if (onlyReturnIdsSet.has(returnId[i])) {
            return true
          }
        }
      } else {
        return onlyReturnIdsSet.has(returnId)
      }
    })
  }

  // Skip data fetching if all datasets are filtered away
  const earlyReturn = datasetInstruction.contract.metadata_filter.include.internal_id.length === 0

  if (earlyReturn) {
    console.debug(`Early returning due to filtering away all datasets`, {
      originalDatasetInstruction: _datasetInstruction,
      datasetInstruction,
      onlyReturnIds,
    })
  }

  return { datasetInstruction, earlyReturn }
}

let revalidateApiTokenStatus: '' | 'loading' | 'success' | 'error' = ''
let refreshedApiTokenAt: Date | null = null
let ongoingRequest: Promise<{ dbsApiToken: string; dbsGlobalReadApiToken: string }> | null = null
const TOKEN_REFRESH_COOLDOWN = 1000 * 60 * 5 // 5 minutes
let dbsClient!: Client
let dbsGlobalReadClient!: Client
let dbsApiToken!: string
let dbsBaseUrl!: string
let dbsGlobalReadBaseUrl!: string
let dbsGlobalReadApiToken!: string

export async function revalidateApiToken(): Promise<{ dbsApiToken: string; dbsGlobalReadApiToken: string }> {
  log.debugging(`@revalidateApiToken`)
  if (revalidateApiTokenStatus === 'loading' && ongoingRequest) {
    log.debugging(`@revalidateApiToken Already revalidating token, returning ongoing request...`)
    return ongoingRequest
  }

  if (refreshedApiTokenAt) {
    const now = new Date()
    const diff = now.getTime() - refreshedApiTokenAt.getTime()
    if (diff < TOKEN_REFRESH_COOLDOWN) {
      log.error(`@revalidateApiToken DbS API Token was refreshed ${diff / 1000} seconds ago, skipping revalidation...`)
      throw new Error(`DbS API Token was refreshed ${diff / 1000} seconds ago, skipping revalidation...`)
    }
  }

  revalidateApiTokenStatus = 'loading'
  ongoingRequest = (async () => {
    try {
      // Test validity of existing token
      let isOk = false
      if (dbsBaseUrl && dbsApiToken) {
        const client = createClient({
          baseUrl: dbsBaseUrl,
          apiToken: dbsApiToken,
          maxRetryAttempts: 1,
          verbose: true,
        })

        try {
          isOk = await client.ping()
          if (isOk) {
            log.warn(`@revalidateApiToken DbS API Token is already valid`)
          }
        } catch (error: unknown) {
          // skip, se expect this to not be valid
        }
      }

      // Fetch new token from backend
      if (!isOk) {
        log.debugging(`@revalidateApiToken Fetching new DbS API Token...`)
        const newToken = await apiClient<{ dbs_api_token: string; dbs_global_read_api_token: string }>(
          'users/refresh_dbs_api_token',
          {
            method: 'POST',
          }
        )
        dbsApiToken = newToken.dbs_api_token
        dbsGlobalReadApiToken = newToken.dbs_global_read_api_token
      }

      if (!authStore.user) {
        throw new Error(`No user found to set DbS API Token to.`)
      }

      authStore.user.dbs_api_token = dbsApiToken || ''
      authStore.user.dbs_global_read_api_token = dbsGlobalReadApiToken || ''
      refreshedApiTokenAt = new Date()
      revalidateApiTokenStatus = 'success'
      log.debugging(`@revalidateApiToken New DbS API Token fetched`, {
        dbsApiToken: maskToken(dbsApiToken),
        dbsGlobalReadApiToken: maskToken(dbsGlobalReadApiToken),
      })

      return { dbsApiToken, dbsGlobalReadApiToken }
    } catch (error: unknown) {
      sentry.captureException(error as Error)
      revalidateApiTokenStatus = 'error'
      throw error
    } finally {
      ongoingRequest = null
    }
  })()

  return ongoingRequest
}

function setDbSClients({
  dbsBaseUrl,
  dbsApiToken,
  dbsGlobalReadBaseUrl,
  dbsGlobalReadApiToken,
}: {
  dbsBaseUrl: string | undefined
  dbsApiToken: string
  dbsGlobalReadBaseUrl: string | undefined
  dbsGlobalReadApiToken: string
}): void {
  log.debugging(`@setDbSClients`, {
    dbsBaseUrl,
    dbsGlobalReadBaseUrl,
    dbsApiToken: maskToken(dbsApiToken),
    dbsGlobalReadApiToken: maskToken(dbsGlobalReadApiToken),
  })

  dbsClient = createClient({
    baseUrl: dbsBaseUrl,
    apiToken: dbsApiToken,
    maxRetryAttempts: 3,
    backoffFactor: 4,
    retryDelay: 1000,
    verbose: true,
    onUnauthorized: async () => {
      log.warn(`dbsClient.onUnauthorized`)
      const data = await revalidateApiToken()
      return data.dbsApiToken
    },
  })

  const isSameClientForGlobalRread = dbsBaseUrl === dbsGlobalReadBaseUrl && dbsApiToken === dbsGlobalReadApiToken
  log.debugging(`@setDbSClients set global read client isSame: ${isSameClientForGlobalRread}`)

  dbsGlobalReadClient = isSameClientForGlobalRread ? dbsClient : createClient({
    baseUrl: dbsGlobalReadBaseUrl,
    apiToken: dbsGlobalReadApiToken,
    maxRetryAttempts: 3,
    backoffFactor: 4,
    retryDelay: 1000,
    verbose: true,
    onUnauthorized: async () => {
      log.warn(`dbsGlobalReadClient.onUnauthorized`)
      const data = await revalidateApiToken()
      return data.dbsGlobalReadApiToken
    },
  })
}

function maskToken(token: string): string {
  return '...' + token.slice(-4)
}

export async function initDbSApiToken(user?: User): Promise<void> {
  if (!user) {
    user = snapshot(authStore).user as User
    if (!user) {
      throw new Error(`No user found to initialize DbS API Token with.`)
    }
  }

  log.debugging(`@initDbSApiToken`, { user })
  dbsApiToken = user.dbs_api_token
  dbsBaseUrl = user.dbs_url
  dbsGlobalReadBaseUrl = user.dbs_global_read_url
  dbsGlobalReadApiToken = user.dbs_global_read_api_token

  if (!dbsApiToken) {
    log.debugging(`@initDbSApiToken No DbS API Token found in user, try retrieving from auth store...`)
    const authSnap = snapshot(authStore)
    dbsApiToken = authSnap.user?.dbs_api_token || dbsApiToken
    dbsBaseUrl = authSnap.user?.dbs_url || dbsBaseUrl
    dbsGlobalReadBaseUrl = authSnap.user?.dbs_global_read_url || dbsGlobalReadBaseUrl
    dbsGlobalReadApiToken = authSnap.user?.dbs_global_read_api_token || dbsGlobalReadApiToken
  }

  log.debugging(`@initDbSApiToken Initializing DbS API Token`)
  await revalidateApiToken()

  setDbSClients({ dbsBaseUrl, dbsApiToken, dbsGlobalReadBaseUrl, dbsGlobalReadApiToken })
}

type fetchDatasetsFromDbSOptions = {
  onlyReturnIds?: string[]
  returnIdRegexFilter?: string
}

export async function fetchDatasetsFromDbS(
  datasetInstructions: Partial<DatasetInstruction>[],
  options: fetchDatasetsFromDbSOptions = {}
): Promise<Dataset[]> {
  const finalDatasets: Dataset[] = []
  const finalDatasetsByIndex: Record<number, Dataset[]> = {}

  if (!dbsClient || !dbsGlobalReadClient) {
    log.warn(`@fetchDatasetsFromDbS No DbS Clients, initializing again...`)
    await initDbSApiToken()
  }

  const dbsInstructions = datasetInstructions.filter((instruction) => instruction.type === 'dbs')

  await Promise.all(
    dbsInstructions.map(async (_instruction, index) => {
      const { datasetInstruction: instruction, earlyReturn } = filterInternalIdsByOnlyReturnIds(
        _instruction,
        options.onlyReturnIds || [],
        options.returnIdRegexFilter
      )
      if (earlyReturn) {
        return
      }

      const contract = instruction.contract as DatasetContractDbS
      const aggregateFunction = getDbSAggregateFunction(instruction)
      const { startTime, endTime, createdAt, createdAtOperator, relativeCreatedAt } =
        getDbSStartAndEndTimeFromDatasetInstruction(instruction)
      const metadataFilter = serializeMetadataFilter(contract.metadata_filter)

      let datasets: Pick<DbSDataset, 'id' | 'metadata'>[] = []
      let dataPoints: Pick<DbSDataPoint, 'time' | 'value' | 'datasetId'>[] = []

      if (aggregateFunction === 'latest') {
        const data = await (contract.global_read_api ? dbsGlobalReadClient : dbsClient).getLatestDatasetsAndDataPoints({
          metadataFilter,
        })

        datasets = data.datasets
        dataPoints = data.dataPoints
      } else {
        const data = await (contract.global_read_api ? dbsGlobalReadClient : dbsClient).getDatasetsAndDataPointsByPostRequest(
          {
            startTime,
            endTime,
            metadataFilter,
            aggregateFunction,
            createdAt,
            createdAtOperator,
            relativeCreatedAt,
            fields: ['time', 'value', 'datasetId'],
            datasetFields: ['id', 'metadata'],
          }
        )

        datasets = data.datasets
        dataPoints = data.dataPoints
      }

      // Convert DbS IDs to AbS return_id
      const datasetIdToInternalIdMap: Record<string, string[]> = {}
      datasets.forEach((dataset: DbSDataset) => {
        const id = dataset?.metadata?.internal_id || dataset.id
        let internalId: string[] = [id]
        const renamedId = contract.id_renaming?.[id]

        if (renamedId) {
          if (isArray(renamedId)) {
            internalId = renamedId
          } else {
            internalId = [renamedId]
          }
        }

        datasetIdToInternalIdMap[dataset.id] = internalId
      })

      // Convert data points to AbS format
      const datasetInternalIdToDatasetIndexMap: Record<string, number> = {}
      let datasetsPreProcess: Dataset[] = []
      dataPoints.forEach((dataPoint: DbSDataPoint) => {
        const internalIds = datasetIdToInternalIdMap[dataPoint.datasetId]

        // Duplicate data points when multiple internalIds are mapped to the same dataset
        internalIds.forEach((internalId) => {
          if (datasetInternalIdToDatasetIndexMap[internalId] === undefined) {
            datasetInternalIdToDatasetIndexMap[internalId] = datasetsPreProcess.length
            datasetsPreProcess.push({
              return_id: internalId,
              times: [],
              values: [],
            })
          }

          const index = datasetInternalIdToDatasetIndexMap[internalId]
          const time = Datetime.toISOString(dataPoint.time)

          datasetsPreProcess[index].times.push(time)
          datasetsPreProcess[index].values.push(dataPoint.value)
        })
      })

      // Fill in missing internal_ids with null values
      datasetsPreProcess = addMissingDatasets(datasetsPreProcess, instruction)

      // Post process datasets
      const datasetsPostProcess = postProcessDatasets(datasetsPreProcess, instruction)
      finalDatasetsByIndex[index] = datasetsPostProcess

      // Debug why data response is empty
      if (dataPoints.length === 0) {
        console.warn(`Empty DbS response for Metadata Filter`, { instruction, datasets, dataPoints })

        const dbsUrl = new URL('https://www.databysigholm.com/app/dataset')

        // Add url params so that filters are like '?includeMetadata=key1:value1,key2:value2'
        Object.entries(contract.metadata_filter.include).forEach(([key, value]) => {
          const values = typeof value === 'string' ? [value] : value
          values.forEach((val) => {
            dbsUrl.searchParams.append('includeMetadata', `${key}:${val}`)
          })
        })
        console.debug(`View results at: ${dbsUrl.toString()}`)
      }
    })
  )

  // Sort datasets in the same chunk as dataset instructions are listed
  for (let i = 0; i < dbsInstructions.length; i++) {
    finalDatasets.push(...(finalDatasetsByIndex[i] || []))
  }

  return finalDatasets
}
