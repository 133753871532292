import { ReactElement, useEffect, useState } from 'react'

import {
  DigitalCreationRequest,
  DigitalTwin,
  useAllDigitalTwins,
  useCreateDigitalTwin,
} from 'api/digitalTwin/digitalTwin.api'
import authStore from 'store/auth/auth'
import { Button } from 'ui/atoms'
import { Dialog } from 'ui/components/Dialog/Dialog'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import View from 'ui/components/View/View'
import TitleWithIcon from 'ui/molecules/TitleWithIcon/TitleWithIcon'

import { Card, DialogActions, DialogContent, List, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import styles from './DigitalTwinVersionControlView.module.less'
import DigitalTwinCreateModal from './components/DigitalTwinCreateModal/DigitalTwinCreateModal'
import DigitalTwinEditor from './components/DigitalTwinEditor/DigitalTwinEditor'
import DigitalTwinFlow from './components/DigitalTwinFlow/DigitalTwinFlow'
import DigitalTwinList from './components/DigitalTwinList/DigitalTwinList'
import OrphanedSettingsInfo from './components/OrphanedSettingsInfo/OrphanedSettingsInfo'
import OrphanedSettingsModal from './components/OrphanedSettingsModal/OrphanedSettingsModal'

export default function DigitalTwinVersionControlView(): ReactElement {
  const { t } = useTranslation()
  const authSnap = useSnapshot(authStore)

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [modalIsClosed, setModalIsClosed] = useState<boolean>(true)
  const [currentDigitalTwin, setCurrentDigitalTwin] = useState<DigitalTwin>()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [orphanedSettingsOpen, setOrphanedSettingsOpen] = useState(false)

  const { data: digitalTwins, refetch: refetchAllDigitalTwins } = useAllDigitalTwins(authSnap.systemId, pageNumber)
  const { mutateAsync: createDigitalTwin } = useCreateDigitalTwin()

  // Refetch all digital twins when the page number changes
  useEffect(() => {
    refetchAllDigitalTwins()
  }, [pageNumber, refetchAllDigitalTwins])

  function onCreateNewModel(): void {
    setModalIsClosed(false)
  }

  function createNewModel(requestData: DigitalCreationRequest): void {
    createDigitalTwin({ data: { system_id: authSnap.activeSystem?.id, ...requestData } })
      .then(setCurrentDigitalTwin)
      .then(() => {
        refetchAllDigitalTwins()
      })
  }

  return (
    <View
      header={
        <ModuleHeader infoHeader={t('Version Control Digital Twin')} beta>
          <OrphanedSettingsInfo onClick={() => setOrphanedSettingsOpen(true)} />
        </ModuleHeader>
      }
    >
      <Card className={styles.DigitalTwinVersionControlView_TableCard}>
        <div className={styles.DigitalTwinVersionControlView_Table}>
          <div className={styles.DigitalTwinVersionControlView_Title}>
            <TitleWithIcon title={t('Models')} size={'large'} />
            <div className={styles.DigitalTwinVersionControlView_Button}>
              <Button primary icon={'fal fa-plus'} onClick={onCreateNewModel}>
                {t('Create new model')}
              </Button>
            </div>
          </div>
          <List component="nav" className={styles.List} aria-labelledby="model-sub-header">
            {digitalTwins?.results?.map((twin) => {
              return (
                <DigitalTwinList
                  key={twin.uid}
                  digitalTwin={twin}
                  primaryDigitalTwinUid={authSnap.activeSystem?.primary_digital_twin?.uid}
                  setCurrentDigitalTwin={setCurrentDigitalTwin}
                  currentDigitalTwin={currentDigitalTwin}
                />
              )
            })}
          </List>
          <div className={styles.DigitalTwinVersionControlView_Buttons}>
            <Button
              primary
              disabled={!digitalTwins?.previous}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
            >
              {t('Previous page')}
            </Button>
            <Typography margin={1}>{`${t('Page')} ${pageNumber}`}</Typography>
            <Button
              primary
              disabled={!digitalTwins?.next}
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
            >
              {t('Next page')}
            </Button>
          </div>
        </div>
      </Card>
      <div className={styles.DigitalTwinVersionControlView_Editor}>
        <DigitalTwinEditor
          refetch={refetchAllDigitalTwins}
          currentDigitalTwin={currentDigitalTwin}
          setCurrentDigitalTwin={setCurrentDigitalTwin}
          preview={() => setPreviewOpen(true)}
        />
      </div>
      {!modalIsClosed && (
        <DigitalTwinCreateModal createNewModel={createNewModel} closeModal={() => setModalIsClosed(!modalIsClosed)} />
      )}
      <Dialog open={previewOpen} fullWidth maxWidth="xl">
        <DialogContent>
          <DigitalTwinFlow currentDigitalTwin={currentDigitalTwin} />
        </DialogContent>
        <DialogActions>
          <Button
            primary
            onClick={() => {
              setPreviewOpen(false)
            }}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <OrphanedSettingsModal open={orphanedSettingsOpen} onClose={() => setOrphanedSettingsOpen(false)} />
    </View>
  )
}
