import { makeValueNegative } from 'api/dataset/dataset.api.helper'
import zoomStore, { setZoom } from 'store/zoom/zoom'
import { colors } from 'styles/variables'
import Datetime from 'utils/datetime/datetime'

import ChartJS, { Tick } from 'chart.js/auto'
import printJS from 'print-js'
import { snapshot } from 'valtio'

import { getFileName } from 'helpers/export.helper'
import { clone, merge } from 'helpers/global.helper/global.helper'

import { externalTooltipHandler, tooltipLabel, tooltipTitle } from './Tooltip/tooltip.helper'
import {
  BASE_CONFIG,
  yAxisGridSettingsDisplayFalse,
  yAxisGridSettingsDisplayTrue,
  xAxisGridSettings,
} from './chart.constants'
import { ChartConfigWithZoom, ChartJSDatasetConfig, ChartItem, FilterItems } from './chartTypes'
import crosairPlugin from './helpers/crosairPlugin'
import todayMarkerPlugin from './helpers/todayMarkerPlugin'
import verticalLinePlugin from './helpers/verticalLinePlugin'

export function convertDatasetToChartJsDataset(items: ChartItem[], datasets: Dataset[]): ChartJSDatasetConfig[] {
  const itemsByDataId: Record<string, ChartItem> =
    items?.reduce((acc, item) => ({ ...acc, [item.data_id]: item }), {}) ?? {}

  const chartJSDatasets = datasets
    .filter((dataset) => !!itemsByDataId[dataset.return_id])
    .map((dataset, index) => {
      const item = itemsByDataId[dataset.return_id]
      const values = item.show_as_negative ? [...dataset.values.map((v) => (v ? makeValueNegative(v) : v))] : [...dataset.values]

      const datasetConfig: ChartJSDatasetConfig = {
        label: dataset.return_id,
        returnId: dataset.return_id,
        data: values,
      }

      if (!item) {
        return datasetConfig
      }

      if (item.default_hidden !== undefined) {
        datasetConfig.hidden = item.default_hidden
      }

      if (item.color !== undefined) {
        datasetConfig.borderColor = item.opacity ? item.color + `C0` : item.color // if we want to have border on filled opacity stacked lines
        datasetConfig.backgroundColor = item.opacity ? item.color + `C0` : item.color
        datasetConfig.hoverBackgroundColor = item.color + `C1`
        datasetConfig.borderWidth = item.opacity ? 1 : 3
      }

      if (item.border_width !== undefined) {
        datasetConfig.borderWidth = item.border_width
      }

      if (item.title !== undefined) {
        /*
        [2023-11-27] Mattias:
        ChartJS v4.4.0 has a bug where datasets with the same label are hiding each other, even when they are on different y-axes or stack-groups. Hence, prefixing with the index-key. This prefix is then removed in the tooltip.
        */
        datasetConfig.label = `INDEX${index}:${item.title}`
      }

      if (item.unit !== undefined) {
        datasetConfig.unit = item.unit
      }

      if (item.decimals !== undefined) {
        datasetConfig.decimals = item.decimals
      }

      if (item.fill !== undefined) {
        datasetConfig.fill = item.fill
      }

      if (item.dashed) {
        datasetConfig.borderDash = [1, 1]
      }

      if (item.y_axis_id) {
        datasetConfig.yAxisID = item.y_axis_id
      }

      if (item.order !== undefined) {
        datasetConfig.order = item.order
      }

      if (item.stack_group) {
        datasetConfig.stack = item.stack_group
      }

      if (item.data_type !== undefined) {
        datasetConfig.data_type = item.data_type
      }
      if (item.tooltip_always_positive !== undefined) {
        datasetConfig.tooltip_always_positive = item.tooltip_always_positive
      }

      if (item.tooltip_do_not_show_zero_values !== undefined) {
        datasetConfig.tooltip_do_not_show_zero_values = item.tooltip_do_not_show_zero_values
      }

      if (item.tooltip_show_time_as_interval !== undefined) {
        datasetConfig.tooltip_show_time_as_interval = item.tooltip_show_time_as_interval
      }

      if (item.tooltip_do_not_show_value !== undefined) {
        datasetConfig.tooltip_do_not_show_value = item.tooltip_do_not_show_value
      }

      if (item.show_arrow_icon !== undefined) {
        datasetConfig.show_arrow_icon = item.show_arrow_icon
      }

      return {
        ...datasetConfig,
        ...(item?.config ?? {}),
      }
    })

  return chartJSDatasets
}

function getTimeTickLabel(labels: ISODateTime[], tickValue: string | number, index: number, ticks: Tick[]): string {
  if (!labels.length || !labels[tickValue]) {
    return ``
  }

  const zoomSnap = snapshot(zoomStore)
  const zoomStartTime = zoomSnap.startTime || (labels[0] as ISODateTime)
  const zoomEndTime = zoomSnap.endTime || (labels[labels.length - 1] as ISODateTime)
  const hoursDiff = Datetime.getNrOfHoursBetween(zoomStartTime, zoomEndTime)
  const timestamp = labels[tickValue]
  const nrOfDays = Math.floor(hoursDiff / 24)

  const timeStampIsAtMidnight = Datetime.toLocalTime(timestamp, `hour`) === '00:00'
  const timeStampIsAtTwelve = Datetime.toLocalTime(timestamp, `hour`) === '12:00'

  if (nrOfDays >= 31) {
    if (Math.round(nrOfDays / 30) >= ticks.length - 1) {
      return Datetime.toLocalTime(timestamp, `month`)
    }
    if (timeStampIsAtMidnight) {
      const date = Datetime.toLocalTime(timestamp, 'date')
      if ((date as number) % 2 === 0) {
        return Datetime.toLocalTime(timestamp, 'shortDay')
      }
    }
  }

  if (nrOfDays < 31 && timeStampIsAtMidnight) {
    return Datetime.toLocalTime(timestamp, 'shortDay')
  }

  if (nrOfDays < 10 && timeStampIsAtTwelve) {
    return Datetime.toLocalTime(timestamp, 'hour')
  }

  if (nrOfDays <= 1) {
    return Datetime.toLocalTime(timestamp, 'hour')
  }
}

export function serializeChartConfig(config: ChartConfigWithZoom): ChartConfigWithZoom {
  // FIXME: [2024-04-24, Mattias]: Sometimes scale.grid is a string and not a dict. I could not find what is causing this issue and instead opt for post-fix with this serializer. If someone finds out what is causing this, please let me know! https://app-eu.wrike.com/open.htm?id=1355670507
  if (config.options?.scales) {
    Object.entries(config.options.scales).forEach(([key, axisConfig]) => {
      if (typeof axisConfig?.grid === 'string') {
        const invalidGridStr = axisConfig.grid
        // eslint-disable-next-line no-console
        console.warn(`Chart config option scale ${key} is not valid format (it's "${invalidGridStr}")`, config)

        if (invalidGridStr === 'yAxisGridSettingsDisplayFalse') {
          config.options.scales[key].grid = clone(yAxisGridSettingsDisplayFalse)
        } else if (invalidGridStr === 'yAxisGridSettingsDisplayTrue') {
          config.options.scales[key].grid = clone(yAxisGridSettingsDisplayTrue)
        } else if (invalidGridStr === 'xAxisGridSettings') {
          config.options.scales[key].grid = clone(xAxisGridSettings)
        } else {
          config.options.scales[key].grid = { color: colors.gridLines }
        }
      }
    })
  }

  return config
}

export function withMaintainAspectRatioFix(config: ChartConfigWithZoom): ChartConfigWithZoom {
  // Unless the user has explicitly set maintainAspectRatio, we will set it to false (related to https://app-eu.wrike.com/open.htm?id=1478388460)
  return {
    ...config,
    options: {
      ...config.options,
      maintainAspectRatio: config.options?.maintainAspectRatio ?? false,
    },
  }
}

function setCallbacks(config: ChartConfigWithZoom, labels: string[]): ChartConfigWithZoom {
  const tempConfig = config

  if (tempConfig.options.scales) {
    //configure all y-axes
    const allAxes: string[] = Object.keys(tempConfig.options.scales)
    allAxes.forEach((item) => {
      if (item.includes(`y`)) {
        tempConfig.options.scales[item].beginAtZero = tempConfig.options.scales[item].beginAtZero
          ? true
          : { beginAtZero: true }
        tempConfig.options.scales[item].grid = tempConfig.options.scales[item].grid ?? {
          grid: { color: colors.gridLines },
        }
        tempConfig.options.scales[item].grid.color = (ctx) => {
          if (ctx.tick.value === 0 && tempConfig.options.scales[item].show_zero_line) {
            tempConfig.options.scales[item].grid.lineWidth = 2
            return colors.black
          } else {
            tempConfig.options.scales[item].grid.lineWidth = 1
            return colors.gridLines
          }
        }
      }
    })

    tempConfig.options.scales.x.ticks.callback = (tickValue: string | number, index: number, ticks: Tick[]) => {
      return getTimeTickLabel(labels, tickValue, index, ticks)
    }
  }

  // Set tooltip callbacks
  if (tempConfig.options.plugins?.tooltip?.callbacks) {
    tempConfig.options.plugins.tooltip.callbacks.label = tooltipLabel
    tempConfig.options.plugins.tooltip.callbacks.title = tooltipTitle
    tempConfig.options.plugins.tooltip.external = externalTooltipHandler
  }

  // Set zoom callback
  // TODO: ignoreUpdateDataResolutionOnZoom temporary solution to fix data without changing the data resolution
  if (tempConfig.options.plugins?.zoom?.zoom && !tempConfig.options.ignoreUpdateDataResolutionOnZoom) {
    tempConfig.options.plugins.zoom.zoom.onZoomComplete = ({ chart }) => {
      const xIndexMin: number | null = chart?.scales?.x?.min ?? null
      const xIndexMax: number | null = chart?.scales?.x?.max ?? null
      const xMin: string | null = xIndexMin !== null ? (chart?.data?.labels?.[xIndexMin] ?? null) : null
      const xMax: string | null = xIndexMax !== null ? (chart?.data?.labels?.[xIndexMax] ?? null) : null
      const startTime = xMin ? Datetime.toISOString(xMin) : null
      const endTime = xMax ? Datetime.toISOString(xMax) : null

      setZoom(startTime, endTime)
    }
  }

  return tempConfig
}

export function getlabels(datasets: Dataset[]): string[] {
  return [...(datasets?.[0]?.times || [])]
}

export function filterEmptyDatasets(datasets: Dataset[], items: UiConfigPropsItem[]): Dataset[] {
  const emptyReturnIds: Set<string> = new Set()
  const ignoreEmptyFilterReturnIds: Set<string> = new Set()

  items?.forEach((item) => {
    if (item?.ignore_empty_filter) {
      ignoreEmptyFilterReturnIds.add(item.data_id)
    }
  })
  datasets
    .filter((d) => !ignoreEmptyFilterReturnIds.has(d.return_id))
    .forEach((d) => {
      for (let i = 0; i < d.values.length; i++) {
        const v = d.values[i]

        if (v === null || v === undefined) {
          continue
        }

        if (v || v === 0) {
          //We still want to include dataset that has only 0 values
          return
        }
      }

      emptyReturnIds.add(d.return_id)
    })

  return datasets.filter((d) => !emptyReturnIds.has(d.return_id))
}

export function filterVisibleItemsInChart(items: ChartItem[]): ChartItem[] {
  if (!items) {
    return []
  }

  return items.filter((item) => {
    if (item.do_not_show_item || item.show_only_in_export) {
      return
    } else {
      return item
    }
  })
}

export function setEmptyYAxisToHidden(config: ChartConfigWithZoom): ChartConfigWithZoom {
  const yAxisIds = new Set(Object.keys(config.options.scales || {}).filter((id) => id !== 'x'))
  const yAxisIdsToHide: Set<string> = new Set()
  Array.from(yAxisIds).forEach((yAxisId) => {
    if (config.data.datasets.filter((d) => d.yAxisID === yAxisId).every((d) => d.hidden === true)) {
      yAxisIdsToHide.add(yAxisId)
    }
  })

  Array.from(yAxisIdsToHide).forEach((yAxisId) => {
    config.options.scales[yAxisId].display = false
  })

  return config
}

export function setChartDatasetVisibility(
  config: ChartConfigWithZoom,
  hiddenReturnIds: Set<string>
): ChartConfigWithZoom {
  config.data.datasets = config.data.datasets.map((dataset) => {
    const returnId = dataset.returnId as string

    if (hiddenReturnIds.has(returnId)) {
      dataset.hidden = true
    } else {
      dataset.hidden = false
    }

    return dataset
  })

  return config
}

export function getConfig(
  items: ChartItem[],
  datasets: Dataset[],
  config?: ChartConfigWithZoom,
  filterItems?: FilterItems[]
): ChartConfigWithZoom {
  const hiddenReturnIds: Set<string> = new Set()
  const showReturnIds: Set<string> = new Set()

  filterItems?.forEach((filterItem) => {
    if (filterItem.default_hidden) {
      filterItem?.return_ids?.forEach((returnId) => {
        hiddenReturnIds.add(returnId)
      })
    } else if (!filterItem.default_hidden) {
      filterItem?.return_ids?.forEach((returnId) => {
        showReturnIds.add(returnId)
      })
    }
  })

  items = items.map((item) => {
    if (hiddenReturnIds.has(item.data_id) && !showReturnIds.has(item.data_id)) {
      item.default_hidden = true
    }
    return item
  })

  const labels = getlabels(datasets)
  const chartJSDatasets = convertDatasetToChartJsDataset(items, filterEmptyDatasets(datasets, items))
  const baseConfigWithData: ChartConfigWithZoom = {
    ...BASE_CONFIG,
    data: {
      labels: labels,
      datasets: chartJSDatasets,
    },
  }

  const mergedConfig = serializeChartConfig(merge(clone(baseConfigWithData), clone(config || {})))

  const updatedCallbacksConfig = setCallbacks(mergedConfig, labels)

  if (updatedCallbacksConfig?.options?.plugins?.todayMarker?.enabled) {
    updatedCallbacksConfig.plugins = [todayMarkerPlugin]
  }

  if (updatedCallbacksConfig?.options?.plugins?.verticalLine) {
    updatedCallbacksConfig.plugins = [...(updatedCallbacksConfig.plugins || []), verticalLinePlugin]
  }

  if (updatedCallbacksConfig?.options?.plugins?.corsair) {
    updatedCallbacksConfig.plugins = [...(updatedCallbacksConfig.plugins || []), crosairPlugin]
  }

  // Set default precision of 0 if not specified
  if (updatedCallbacksConfig?.options?.scales) {
    Object.entries(updatedCallbacksConfig.options.scales).forEach(([key, _axisConfig]) => {
      if (!updatedCallbacksConfig.options.scales[key].ticks) {
        updatedCallbacksConfig.options.scales[key]['ticks'] = {}
      }

      if (updatedCallbacksConfig.options.scales[key].ticks.precision === undefined) {
        updatedCallbacksConfig.options.scales[key].ticks.precision = 0
      }
    })
  }

  return updatedCallbacksConfig
}

export function downloadChartAsImage(chart: ChartJS): void {
  const dummyLink = document.createElement(`a`)
  dummyLink.href = chart.toBase64Image()
  dummyLink.download = getFileName(`png`)
  dummyLink.click()
}

export function printChart(chart: ChartJS): void {
  const url = chart.toBase64Image()
  printJS({ printable: url, type: `image` })
}

export function getFilteredItemsForDatasetTable(
  isHidden: boolean,
  chartItems: ChartItem[],
  filteredItems: ChartItem[],
  returnIds: string[]
): ChartItem[] {
  const copyItems: ChartItem[] = []
  const itemsToBeShownOrHidden = chartItems.filter((item) => returnIds.includes(item.data_id))
  if (isHidden) {
    chartItems.forEach((chartItem) => {
      if (itemsToBeShownOrHidden.find((item) => item === chartItem)) {
        copyItems.push(chartItem)
      } else if (filteredItems.find((item) => item === chartItem)) {
        copyItems.push(chartItem)
      } else {
        return
      }
    })
  } else {
    chartItems.forEach((chartItem) => {
      if (itemsToBeShownOrHidden.find((item) => item === chartItem)) {
        return
      } else if (filteredItems.find((item) => item === chartItem)) {
        copyItems.push(chartItem)
      } else {
        return
      }
    })
  }

  return copyItems
}

export function getInitialHiddenAndShownReturnIds(filter_items: FilterItems[]): { shown: string[]; hidden: string[] } {
  let returnIdsWithHiddenDefault: string[] = []
  const returnIdsWithShownDefault: string[] = []

  filter_items?.forEach((filterItem) => {
    if (filterItem.default_hidden) {
      filterItem.return_ids.forEach((returnId) => {
        if (!returnIdsWithHiddenDefault.includes(returnId)) {
          returnIdsWithHiddenDefault.push(returnId)
        }
      })
    } else {
      filterItem.return_ids.forEach((returnId) => {
        if (!returnIdsWithShownDefault.includes(returnId)) {
          returnIdsWithShownDefault.push(returnId)
        }
      })
    }
  })

  filter_items?.forEach((filterItem) => {
    if (filterItem.is_mutually_exclusive && filterItem.default_hidden) {
      filterItem.return_ids.forEach((returnId) => {
        returnIdsWithHiddenDefault = returnIdsWithHiddenDefault.filter((id) => id !== returnId)
      })
    }
  })

  return { shown: returnIdsWithShownDefault, hidden: returnIdsWithHiddenDefault }
}
