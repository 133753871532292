import { useState } from 'react'

import { Button } from 'ui/atoms'
import { DatePickerPeriod } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'

import moment, { Moment } from 'moment'
import { Form } from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import styles from './FormDatePickerPeriod.module.less'

type BackwardsCompatibleDatePickerPeriodProps = React.ComponentPropsWithoutRef<typeof DatePickerPeriod>

interface Props {
  onSubmit: (period: Period) => void
  formClassName?: string
  submitLabel?: string
  defaultPeriod: Period
  granularity?: BackwardsCompatibleDatePickerPeriodProps['granularity']
}

export function FormDatePickerPeriod(props: Props): React.ReactElement {
  const { t } = useTranslation()
  const [datePickerHasError, setDatePickerHasError] = useState(false)

  const [period, setPeriod] = useState<{
    startTime: Moment
    endTime: Moment
  }>(props.defaultPeriod)

  return (
    <Form
      className={props.formClassName ?? styles.Form}
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit({ startTime: period.startTime, endTime: period.endTime })
      }}
    >
      <DatePickerPeriod
        startDate={period.startTime}
        endDate={period.endTime}
        granularity={props.granularity || 'minute'}
        onSelect={(startTime, endTime) => {
          setPeriod({
            startTime: moment(startTime),
            endTime: moment(endTime),
          })
        }}
        onError={(error) => {
          setDatePickerHasError(error)
        }}
      />
      <Button disabled={datePickerHasError} style={{ marginLeft: 10 }} primary icon="fal fa-redo">
        {props.submitLabel ?? t('Load period')}
      </Button>
    </Form>
  )
}
