import React, { useState, ReactElement } from 'react'

import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'
import PrintButton from 'ui/molecules/PrintButton/PrintButton'
import UiConfig from 'ui/uiConfig/UiConfig'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

const availabilityWidgetContainerContentClass = `availabilityWidgetContainerContentClass`
export const availabilityHeaderContentClass = `availabilityHeaderContentClass`

const availabilityWidget: Array<Step> = [
  {
    target: availabilityWidgetContainerContentClass,
    content: `Here you can see one production unit per widget. Each production unit allows you to choose which period you wish to follow up and will then show unavailabilities, graphs and KPI:s for the chosen period. \n\nIn the chart you can follow up the graph for the production unit reference value compared against the actual outcome for the production unit. The availability is calculated from these values. \n\n If there are any planned unavailabilities registered the reference value is adjusted to the actual outcome for the period of the unavailability so that the total KPI of availability is not affected. \n\nIn each widget, you can register any planned unavailabilities that should apply for the specific production unit. Be aware of that these unavailabilities only affect the calculations made in this module and are not connected to the ordinary settings of Aurora. But you can see what ordinary settings have been active during the chosen period to get a better understanding of why the production unit outcome looks the way it does, but these settings are not affecting the availability calculations in this module.`,
    title: `Availability per production unit`,
  },
]
const steps: Array<Step> = [
  {
    target: availabilityHeaderContentClass,
    content: `In the module Availability, you are able to follow up KPI:s for the availability of different production units. Here in the header you find a filter function to filter what productions units you want to see on the page. And you can also export the page content to an image, PDF or Excel.`,
    title: `KPI:s for Availability`,
  },
  ...availabilityWidget,
]

export default function AvailabilityView(): ReactElement {
  const [runTour, setRunTour] = useState<null | number>(null)
  const { t } = useTranslation()

  return (
    <View
      header={
        <Grid className={availabilityHeaderContentClass}>
          <ModuleHeader
            infoHeader={t(`Availability`)}
            onInfoButtonClick={() => {
              setRunTour(0)
            }}
          >
            <Grid item container justifyContent="flex-end" alignItems="flex-end" spacing={1}>
              <Grid item>
                <PrintButton fileName={`abs-availability.png`} title={t('Print')} />
              </Grid>
            </Grid>
          </ModuleHeader>
        </Grid>
      }
    >
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? availabilityWidget : steps}
      />

      <UiConfig type={'availability'} />
    </View>
  )
}
