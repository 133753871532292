import React, { ReactElement, ReactNode, useState } from 'react'

import { dynamicClassName } from 'styles/helper'

import { Collapse, Icon, IconButton } from '@mui/material'

import { getRoute } from 'helpers/route.helper/route.helper'

import styles from './Card.module.less'

type CardProps = {
  children: ReactNode | ReactElement | ReactNode[] | ReactElement[] | null | string
  collapsible?: boolean
  defaultCollapsed?: boolean
  rememberCollapsed?: boolean
  style?: 'table' | 'default' | 'sub' | 'none'
  margin?: boolean
  title?: ReactNode | ReactElement | null | string
  width?: string
  tourId?: string
}

function extractTitleText(title: ReactNode | ReactElement | null | string): string | null {
  if (title === null) {
    return null
  } else if (typeof title === 'string') {
    return title
  } else if (React.isValidElement(title) && typeof title.props.title === 'string') {
    return title.props.title
  }
  return null
}

export default function Card({
  children,
  collapsible,
  defaultCollapsed,
  rememberCollapsed,
  margin,
  title,
  width,
  tourId,
  style,
}: CardProps): ReactElement {
  const route = getRoute()
  const cardStyle: Record<string, string> = {}
  const key = `${route}_${extractTitleText(title)}`
  const localStorageItem = key ? localStorage.getItem(key) : null
  const isLocalStorageCollapsed = localStorageItem ? localStorageItem === 'open' ? false : localStorageItem === 'close' ? true : null : null
  const [collapsed, setCollapsed] = useState<boolean>((rememberCollapsed && isLocalStorageCollapsed !== null) ? isLocalStorageCollapsed : (defaultCollapsed ?? false))

  if (width) {
    cardStyle.width = width
  }

  const optionalClassNames = {
    [styles.Card]: style === 'default' || !style,
    [styles.Card__margin]: !!margin,
    [styles.CardStyle_table]: style === 'table',
    [styles.CardStyle__sub]: style === 'sub',
  }
  if (tourId) {
    optionalClassNames[tourId] = true
  }

  return (
    <div className={dynamicClassName(optionalClassNames)} style={cardStyle}>
      {/* Title */}
      {collapsible && title ? (
        <div
          className={dynamicClassName({
            [styles.CollapseTitleContainer]: true,
            [styles.CollapseTitleContainer__grey]: style === 'table',
          })}
          onClick={() => {
            if (rememberCollapsed && key) {
              localStorage.setItem(key, collapsed ? 'open' : 'close')
            }
          }}
        >
          {title}
          <div
            className={dynamicClassName({
              [styles.CollapseIconContainer]: true,
              [styles.CollapseIconContainer__sub]: style === 'sub',
            })}
          >
            <IconButton onClick={() => {
              setCollapsed(!collapsed)
              if (rememberCollapsed && key) {
                localStorage.setItem(key, collapsed ? 'open' : 'close')
              }
            }} size="large">
              <Icon fontSize="small" className={collapsed ? 'fal fa-chevron-down' : 'fal fa-chevron-up'} />
            </IconButton>
          </div>
        </div>
      ) : (
        title
      )}

      {/* Body/children */}
      {collapsible ? <Collapse in={!collapsed}>{!collapsed && children}</Collapse> : children}
    </div>
  )
}
