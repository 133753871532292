import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { RoutesArray } from 'ui/components/Navigation/types'

import { useTranslation } from 'react-i18next'

import AbSLabsView from 'views/AbSLabsView/AbSLabsView'
import AdminSettingsView from 'views/AdminSettingsView/AdminSettingsView'
import AvailabilityView from 'views/AvailabilityView/AvailabilityView'
import DevView from 'views/DevView/DevView'
import DigitalTwinSamEnergiView from 'views/DigitalTwinSamEnergiView/DigitalTwinSamEnergiView'
import DigitalTwinBaseSettingsView from 'views/DigitalTwinSettingsView/DigitalTwinBaseSettingsView'
import DigitalTwinDeviationSettingsView from 'views/DigitalTwinSettingsView/DigitalTwinDeviationSettingsView'
import ElectricityPlanningLightView from 'views/ElectricityPlanningLightView/ElectricityPlanningLightView'
import ElectricityPlanningView from 'views/ElectricityPlanningView/ElectricityPlanningView'
import EventsView from 'views/EventsView/EventsView'
import FollowupProductionView from 'views/FollowupProductionView/FollowupProductionView'
import FuelPlanView from 'views/FuelPlanView/FuelPlanView'
import LogBookView from 'views/LogBookView/LogBookView'
import MigrateToDbSView from 'views/MigrateToDbSView/MigrateToDbSView'
import OptimizationAnalysisView from 'views/OptimizationAnalysisView/OptimizationAnalysisView'
import OptimizeModelSettingsView from 'views/OptimizeModelSettingsView/OptimizeModelSettingsView'
import OptimizeSettingsView from 'views/OptimizeSettingsView/OptimizeSettingsView'
import OptimizeView from 'views/OptimizeView/OptimizeView'
import OpusUploadView from 'views/OpusUploadView/OpusUploadView'
import ReferenceGuidesView from 'views/ReferenceGuidesView/ReferenceGuidesView'
import ReviewForecastsView from 'views/ReviewForecastsView/ReviewForecastsView'
import SamEnergiView from 'views/SamEnergiView/SamEnergiView'
import SandboxProjectView from 'views/SandboxProjectView/SandboxProjectView'
import SandboxView from 'views/SandboxView/SandboxView'
import SettlementsView from 'views/SettlementsView/SettlementsView'
import ShiftHandoverView from 'views/ShiftHandoverView/ShiftHandoverView'
import ShiftScheduleView from 'views/ShiftScheduleView/ShiftScheduleView'
import StartPageView from 'views/StartpageView/StartpageView'
import UiConfigView from 'views/UiConfigView/UiConfigView'
import UnitAvailabilityView from 'views/UnitAvailabilityView/UnitAvailabilityView'
import UserProfileView from 'views/UserProfileView/UserProfileView'
import DigitalTwinVersionControlView from 'views/VersionControlView/DigitalTwinVersionControlView'

export function useMainRoutes(): RoutesArray {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()
  const { user } = useAuth()

  return [
    {
      path: `/startpage`,
      sidebarName: t(`Startpage`),
      navbarName: t(`Startpage`),
      module: `startpage`,
      icon: `fal fa-home`,
      component: StartPageView,
    },
    {
      path: `/optimizationanalysis`,
      notInSidemenu: true,
      module: `optimizationanalysis`,
      component: OptimizationAnalysisView,
    },
    {
      path: `/optimize`,
      sidebarName: t(`Production plan`),
      navbarName: t(`Production plan`),
      module: `optimize`,
      icon: `fal fa-chart-area`,
      component: OptimizeView,
    },
    {
      sidebarName: t(`Settings`),
      icon: `fal fa-cogs`,
      module: `settings`,
      children: [
        {
          path: activeSystem?.primary_digital_twin ? `/digitalTwinDeviationSettings` : `/optimizesettings`,
          sidebarName: t(`Deviation settings`),
          navbarName: t(`Deviation settings`),
          module: `optimizesettings`,
          icon: `fal fa-wrench`,
          component: activeSystem?.primary_digital_twin ? DigitalTwinDeviationSettingsView : OptimizeSettingsView,
        },
        {
          path: activeSystem?.primary_digital_twin ? `/digitalTwinBaseSettings` : `/optimizemodelsettings`,
          sidebarName: t(`Opt model settings`),
          navbarName: t(`Opt model settings`),
          module: `optimizemodelsettings`,
          icon: `fal fa-cog`,
          component: activeSystem?.primary_digital_twin ? DigitalTwinBaseSettingsView : OptimizeModelSettingsView,
        },
      ],
    },
    {
      sidebarName: t(`Follow-up`),
      icon: `fal fa-history`,
      module: `followup`,
      children: [
        {
          path: `/followupproduction`,
          sidebarName: t(`Follow-up: Production`),
          sidebarNameAsChild: t(`Production`),
          navbarName: t(`Follow-up: Production`),
          module: `followupproduction`,
          icon: `fal fa-chart-bar`,
          component: FollowupProductionView,
        },
        {
          sidebarName: t(`Review forecasts`),
          sidebarNameAsChild: t(`Review forecasts`),
          navbarName: t(`Review forecasts`),
          path: `/reviewforecasts`,
          module: `reviewforecasts`,
          icon: `fal fa-chart-line`,
          component: ReviewForecastsView,
        },
      ],
    },
    {
      path: `/events/:eventId?`,
      sidebarName: t('Events'),
      navbarName: t('Events'),
      module: `events`,
      icon: `fal fa-calendar-week`,
      component: EventsView,
    },
    {
      path: `/logbook`,
      sidebarName: t(`Logbook`),
      navbarName: t(`Logbook`),
      module: `logbook`,
      icon: `fal fa-sticky-note`,
      component: LogBookView,
    },
    {
      sidebarName: t(`Shift`),
      icon: `fal fa-calendar-alt`,
      module: `shifthandover`,
      children: [
        {
          path: `/shifthandover`,
          sidebarName: t(`Shift handover`),
          navbarName: t(`Shift handover`),
          module: `shifthandover`,
          icon: `fal fa-clipboard-list-check`,
          component: ShiftHandoverView,
        },
      ],
    },
    {
      path: `/shiftschedule`,
      sidebarName: t(`Shift Schedule`),
      navbarName: t(`Shift Schedule`),
      module: `shiftschedule`,
      icon: `fal fa-user-clock`,
      component: ShiftScheduleView,
    },
    {
      path: `/dev`,
      module: `devView`,
      component: DevView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/labs`,
      module: `abSLabsView`,
      component: AbSLabsView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/samenergi`,
      sidebarName: t(`SamEnergi`),
      navbarName: t(`SamEnergi`),
      module: `samenergi`,
      icon: `fal fa-handshake`,
      component: activeSystem?.primary_digital_twin ? DigitalTwinSamEnergiView : SamEnergiView,
    },
    {
      path: `/availability`,
      sidebarName: t(`Availability`),
      navbarName: t(`Availability`),
      module: `availability`,
      icon: `fal fa-percentage`,
      component: activeSystem?.primary_opt_model ? AvailabilityView : UnitAvailabilityView,
    },
    {
      path: `/sandbox/:id/`,
      navbarName: t(`Sandbox`),
      module: `sandbox`,
      notInSidemenu: true,
      alwaysAvailable: true,
      component: SandboxProjectView,
    },
    {
      path: `/sandbox`,
      sidebarName: t(`Sandbox`),
      navbarName: t(`Sandbox`),
      module: `sandbox`,
      icon: `fal fa-flask`,
      component: SandboxView,
    },
    {
      path: `/settlements`,
      sidebarName: t(`Settlements`),
      navbarName: t(`Settlements`),
      module: `settlements`,
      icon: `far fa-calculator-alt`,
      component: SettlementsView,
    },
    {
      path: `/referenceguides`,
      sidebarName: t(`Reference guides`),
      navbarName: t(`Reference guides`),
      module: `referenceguides`,
      icon: `fal fa-book`,
      component: ReferenceGuidesView,
    },
    {
      component: DigitalTwinVersionControlView,
      icon: `fal fa-user-friends`,
      module: `digitaltwinversioncontrol`,
      navbarName: t(`Version Control Digital Twin`),
      path: `/digitalTwinVersionControl`,
      sidebarName: t(`Version Control Digital Twin`),
    },
    {
      path: `/electricityplanning`,
      sidebarName: t(`Electricity Planning`),
      navbarName: t(`Electricity Planning`),
      module: `elplan`,
      icon: `far fa-bolt`,
      component: ElectricityPlanningView,
    },
    {
      path: `/electricityplanninglight`,
      sidebarName: user?.is_superuser ? t(`Electricity Planning Light`) : t(`Electricity Planning`),
      navbarName: user?.is_superuser ? t(`Electricity Planning Light`) : t(`Electricity Planning`),
      module: `elplanlight`,
      icon: `far fa-bolt`,
      component: ElectricityPlanningLightView,
    },
    {
      path: `/fuelplan`,
      module: `fuelplan`,
      sidebarName: t('Fuel plan'),
      navbarName: t('Fuel plan'),
      component: FuelPlanView,
      icon: `fal fa-mountains`,
    },
    {
      path: `/opusupload`,
      module: `opusupload`,
      sidebarName: t('OPUS Upload'),
      navbarName: t('OPUS Upload'),
      component: OpusUploadView,
      icon: `fal fa-upload`,
    },
    {
      path: `/userprofile`,
      module: `userpage`,
      component: UserProfileView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/adminsettings`,
      module: `useradmin`,
      component: AdminSettingsView,
      globalModule: true,
      notInSidemenu: true,
    },
    {
      path: `/uiconfig`,
      module: `uiconfig`,
      component: UiConfigView,
      notInSidemenu: true,
    },
    {
      path: `/migratetodbs`,
      module: `uiconfig`,
      component: MigrateToDbSView,
      notInSidemenu: true,
    },
    {
      module: `startpage`,
      redirect: true,
      path: `/`,
      to: `/startpage`,
    },
  ]
}
