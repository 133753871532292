import React from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import {
  DateInput as ReactAriaDateInput,
  DatePickerStateContext,
  DateSegment,
  Group,
  Label,
  TimeFieldStateContext,
} from 'react-aria-components'
import { useTranslation } from 'react-i18next'

import { CalendarToggleButton } from '../Calendar/CalendarToggleButton'

import styles from './DateField.module.less'

interface DateFieldGroupProps {
  isInvalid?: boolean
  label?: string
  children: React.ReactNode
}
function DateFieldGroup(props: DateFieldGroupProps): JSX.Element {
  return (
    <Group className={styles.DateField}>
      <Label className={`${styles.DateField_Label} ${props.isInvalid ? styles.DateField_Label_error : ''}`}>
        {props.label}
      </Label>
      {props.children}
    </Group>
  )
}

const DateInput = (): JSX.Element => {
  const isLocaleH12 = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).resolvedOptions().hour12

  return (
    <ReactAriaDateInput className={styles.DateField_DateInput} >
      {(segment) => {
        if(!isLocaleH12 && segment.type === 'hour' && (segment.value === 0 || (segment.value && segment.value < 10))) {
          segment.text = segment.text.padStart(2, '0')
        }
        return (
          <DateSegment data-testid={`${segment.type}-input`} segment={segment} className={styles.DateField_DateSegment} />
        )}}
    </ReactAriaDateInput>
  )
}

interface Props {
  label?: string
  allowEmpty?: boolean
  disabled?: boolean
}

export function CalendarDateField(props: Props): JSX.Element {
  const datePickerState = React.useContext(DatePickerStateContext)
  const showUntilFurtherNotice = props.allowEmpty && !datePickerState.value
  const { t } = useTranslation()

  return (
    <Tooltip
      title={props.disabled ? '' : t('Select date with picker here')}
      placement="top-start"
      arrow
      display='block'
      PopperProps={{ className: styles.customTooltip }}
    >
      <span>
        <DateFieldGroup label={props.label} isInvalid={datePickerState.isInvalid}>
          <CalendarToggleButton disabled={props.disabled} />
          {showUntilFurtherNotice ? <UntilFurtherNoticePlaceholer /> : <DateInput />}
        </DateFieldGroup>
      </span>
    </Tooltip>
  )
}

function UntilFurtherNoticePlaceholer(): JSX.Element {
  const { t } = useTranslation()
  return <span className={styles.DateField_indefinite}>{t('Until further notice')}</span>
}

interface TimeFieldProps {
  label?: string
}

export function TimeField(props: TimeFieldProps): JSX.Element {
  const timeFieldContext = React.useContext(TimeFieldStateContext)
  const isInvalid = timeFieldContext.isInvalid

  return (
    <DateFieldGroup label={props.label} isInvalid={isInvalid}>
      <DateInput />
    </DateFieldGroup>
  )
}
