import React, { useEffect, ReactElement, useMemo, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import { ELPLAN_BID_REFRESH_EVENT_QUERY_KEY, ELPLAN_REFRESH_EVENT_QUERY_KEY, PlanValues, useCreateElplanMutation, useElplan, useElplanBids, useElplanInfo, useElplanSyncResultsMutation, useToggleLockMutation, useUpdateElplanMutation } from 'api/elplan/elplan.api'
import { useUiConfigAnchorComponent } from 'api/uiConfig/uiConfig.api'
import elplanStore from 'store/elplan/elplan'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import { DatePickerInForm } from 'ui/molecules/pickers/DatePicker/DatePicker'
import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { convertNumberDecimalSign } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'

import DAOverviewModal from './DAOverviewModal/DAOverviewModal'
import styles from './DayAheadOverviewView.module.less'

function getElectricityPlan(dataset: Dataset): PlanValues[]
{
  const array: PlanValues[] = []

  dataset.times.forEach((time, index) => {
    array.push({
      volume: dataset.values[index] ?? 0,
      start_time: time as ISODateTime,
      end_time: dataset.times.length -1 === index ? Datetime.getEndOfDay(time as ISODateTime) : dataset.times[index + 1] as ISODateTime,
      time: time as ISODateTime,
    })

  })

  return array

}

export default function ElectricityPlanView(): ReactElement {
  const { t } = useTranslation()
  const auth = useAuth()
  const elplanInfo = useElplanInfo(auth.systemId)

  const [currentView, setCurrentView] = useState<'today' | 'tomorrow' | ''>('tomorrow')
  const elplanSnap = useSnapshot(elplanStore)
  const [date, setDate] = useState<{
    startTime: ISODateTime | undefined, 
    endTime: ISODateTime | undefined,
  }>({startTime: elplanSnap.date.start_time, endTime: elplanSnap.date.end_time})

  const [modalIsOpen, setModalIsOpen] = useState<boolean>()
  const [isEditingPlan, setIsEditingPlan] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  useElplan(
    elplanSnap.elplan_info_id, 
    date.startTime 
      ? Datetime.toLocalTime(date.startTime, 'onlyDate') as ISODateTime 
      : undefined
  )
  const amountOfBlockBids = elplanSnap.blockBidInfo.amount || 0
  const onlyReturnIds = (new Array(amountOfBlockBids))
    .fill('')
    .map((_, i) => `blockbid.${i + 1}.volume`)

  const { data: elplanBidsExistForCurrentDate } = useElplanBids(elplanSnap.elplan_info_id, date.startTime, date.endTime)
  const { data: elplanBidsUiConfig } = useUiConfigAnchorComponent('elplan_bids')
  const { data: elplanBidsDatasets = [] } = useDatasets(
    elplanBidsUiConfig?.id ?? -1,
    elplanBidsUiConfig?.version ?? -1,
    elplanBidsUiConfig?.dataset_instructions || [],
    date.startTime,
    date.endTime,
    {
      onlyReturnIds,
      fillMissingHourTimestamps: false,
      datasetRefreshToken: ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
      overrideAlias: {
        elplan_info_id: elplanSnap.elplan_info_id,
      },
    }
  )

  const electricityPlanFromSpotBids = useMemo(() => {
    if (!elplanBidsDatasets?.length) {
      return []
    }

    const onlySpotBids = elplanBidsDatasets.find((dataset) => dataset.return_id === 'spotbid_without_price.volume')

    if (!onlySpotBids) {
      return []
    }

    return getElectricityPlan(onlySpotBids)
  }, [elplanBidsDatasets])

  const elplanExistsForCurrentDate = elplanSnap.elplan.has_plan
  const isLocked = elplanSnap.elplan.is_locked

  const { mutate: createElplanValues} = useCreateElplanMutation()
  const { mutate: updateElplanValues } = useUpdateElplanMutation()
  const { mutate: toggleLockVerification } = useToggleLockMutation()
  const { mutate: syncElplanResults } = useElplanSyncResultsMutation()

  const graphDates = useMemo(() => {

    if (currentView === 'tomorrow') {
      setDate({startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime})
      return {startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime}
    }

    if (currentView === 'today') {
      setDate({startTime: Datetime.getTodayDate().startTime, endTime: Datetime.getTodayDate().endTime})
      return {startTime: Datetime.getTodayDate().startTime, endTime: Datetime.getTodayDate().endTime}
    }

  }, [currentView])

  useEffect(() => {
    queryClient.invalidateQueries(ELPLAN_REFRESH_EVENT_QUERY_KEY)
  }, [date])

  function updatingPlan(elplan: PlanValues[]): void {

    // Cannot sort the snap directly, so we need to create a new array
    const existingValues = [...(elplanSnap.elplan.values)]

    // Sort both existing and new values by start_time so that index are the same
    const sortedExistingValues = existingValues.sort((a, b) => a.start_time.localeCompare(b.start_time))
    const sortedNewValues = elplan.sort((a, b) => a.start_time.localeCompare(b.start_time))

    const values = sortedNewValues.map((value, index) => {
      return {
        id: sortedExistingValues[index].id as number,
        volume: value.volume,
      }
    })

    updateElplanValues({ id: elplanSnap.elplan.elplan_id, values })
  }

  function onCreatingPlan(): void {
    setModalIsOpen(true)
    setIsEditingPlan(!isEditingPlan)
  }

  function onEditingPlan(): void {
    setModalIsOpen(true)
    setIsEditingPlan(!isEditingPlan)
  }

  function verifyPlan(): void {
    toggleLockVerification({id: elplanSnap.elplan.elplan_id})
  }

  function createPlan(electricityPlan: PlanValues[]): void {
    if (!date.startTime) {
      return
    }

    const values = electricityPlan.map((bid) => {
      return {
        time: bid.time,
        volume: convertNumberDecimalSign(bid.volume),
      }
    })
    setModalIsOpen(false)
    createElplanValues({
      elplan_info: elplanSnap.elplan_info_id, 
      date: Datetime.toLocalTime(date.startTime, 'onlyDate'), 
      values,
    })
  }

  function onDateTimePickerChange(value: ISODateTime): void{
    setDate({ startTime: Datetime.getStartOfDay(value), endTime: Datetime.getEndOfDay(value) })
    setCurrentView('')
  }

  const integrationExists = elplanInfo?.data?.integration ?? false

  return (
    <div>
      <CardWidget
        title={t(`DA - Overview`)} id={''}        >
        <div className={styles.ElectricityPlanView_TopLevel}>
          <div className={styles.ElectricityPlanView_Period}>
            <DatePickerInForm
              value={date.endTime}
              onSubmit={(value) => { onDateTimePickerChange(value) }}
              showWeekNumbers
              label={t('Chosen date')}
              granularity={'day'}
            />
          </div>
          <div className={styles.ElectricityPlanView_ButtonsWrapper}>
            <div className={styles.ElectricityPlanView_ButtonsWrapper__Buttons}>
              {(integrationExists && !isLocked) && (
                <Button
                  secondary
                  marginBottom
                  icon={'fal fa-sync'}
                  onClick={() => {
                    const system = auth.activeSystem?.name
                    if (!system || !date.startTime) {
                      return
                    }

                    syncElplanResults({
                      date: Datetime.toLocalTime(date.startTime, 'onlyDate'),
                      system,
                    })
                  }}
                >
                  {t('Fetch results')}
                </Button>
              )}
              {!elplanExistsForCurrentDate ?
                <Tooltip title={!(elplanBidsExistForCurrentDate && elplanBidsExistForCurrentDate.length > 0) ? t('Place bids in DA - Bid management or fetch results with the button to the left') : ''} arrow placement='top'>
                  <div>
                    <Button
                      primary
                      icon={'fal fa-plus'}
                      marginLeft
                      marginBottom
                      disabled={!(elplanBidsExistForCurrentDate && elplanBidsExistForCurrentDate.length > 0)}

                      onClick={() => onCreatingPlan()}>
                      {t('Create electricity plan')}
                    </Button>
                  </div>
                </Tooltip>
                :
                elplanExistsForCurrentDate && !elplanSnap.elplan.is_locked ?
                  <Button
                    icon={'fal fa-pen'}
                    primary
                    marginBottom
                    marginLeft
                    disabled={elplanSnap.elplan.is_locked}
                    onClick={() => onEditingPlan()}>{t('Edit electricity plan')}
                  </Button> : null}
              {elplanExistsForCurrentDate ? <Button
                icon={elplanSnap.elplan.is_locked ? 'fal fa-unlock' : 'fal fa-lock'}
                primary={!elplanSnap.elplan.is_locked}
                warning={elplanSnap.elplan.is_locked}
                disabled={!elplanExistsForCurrentDate}
                marginLeft
                marginBottom
                tooltip={elplanSnap.elplan.is_locked ? t('Electricitiy plan will no longer be locked in production plan') : ''}
                onClick={elplanSnap.elplan.is_locked ? () => verifyPlan() : () => setConfirmDialog(true)}
              >
                {elplanSnap.elplan.is_locked ? t('Unlock to edit') : t('Lock in production plan')}
              </Button> : null}
            </div>
          </div>
        </div>
        <div className={styles.ElectricityPlanView_InfoBanner}>
          {elplanExistsForCurrentDate &&
              <InfoBanner
                text={elplanSnap.elplan.is_locked ? t('The electricity plan is locked in production plan') : t('As of now, this electricity plan does not affect the production plan. Lock the electricity plan with the button above')}
                style={!elplanSnap.elplan.is_locked ? 'warningWithoutBackground' : 'info'}
              />
          }
        </div>

        <div className={styles.ElectricityPlanView_Tables}>

          <UiConfig type="day_ahead_overview"
            datasetStartTime={date ? date?.startTime : graphDates?.startTime}
            datasetEndTime={date ? date?.endTime : graphDates?.endTime}
            datasetRefreshToken={ELPLAN_REFRESH_EVENT_QUERY_KEY}/>
        </div>

        {modalIsOpen &&
        <DAOverviewModal
          onClose={() => setModalIsOpen(false)}
          onSubmit={(electricityPlan) =>
            elplanExistsForCurrentDate ? updatingPlan(electricityPlan) :
              createPlan(electricityPlan)}
          elplanExistsWarningText={t('You are currently editing an already created electricity plan. Any changes you make to this plan may differ from the plan you have sent into trading.')}
          elplanFromSpotBid={electricityPlanFromSpotBids}
        />}
        {confirmDialog &&
          <ConfirmDialog
            open={confirmDialog}
            onConfirm={() => {
              setConfirmDialog(false)
              verifyPlan()
            }}
            onClose={() => setConfirmDialog(false)}
            textObject={{
              title: t(`Are you sure you want to lock a manual electric plan?`),
              text: t(`Note that if you lock a manual electricity plan, the automatic electricity plan will not automatically enter. Do you want to continue to lock the electricity plan?`),
            }}
          />}
      </CardWidget>
    </div>
  )
}