import React, { ReactElement } from 'react'

import { postStat } from 'api/stats/stats.api'
import HeaderPeriodPicker from 'ui/components/HeaderPeriodPicker/HeaderPeriodPicker'
import DeviationSettings from 'ui/uiConfig/anchorComponents/FollowupProduction/DeviationSettings/DeviationSettings'
import Datetime from 'utils/datetime/datetime'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { handlePeriodChange } from 'views/FollowupProductionView/FollowupProductionView'


import styles from './FollowupHeader.module.less'

type FollowupProductionHeaderProps = {
  period: Period
  handlePeriodChange: handlePeriodChange
  loading?: boolean
  isFollowupProduction?: boolean
}
export const followupHeaderContentClass = `followupHeaderContent`

export default function FollowupProductionHeader({
  loading,
  period,
  isFollowupProduction,
  handlePeriodChange,
}: FollowupProductionHeaderProps): ReactElement {
  const { t } = useTranslation()

  const totalDaysInPeriod = Math.ceil(Datetime.getNrOfHoursBetween(period.startTime, period.endTime) / 24)
  const maxDaysToShowDeviationSettings = 14
  return (
    <Grid container direction="row" justifyContent="flex-end" className={followupHeaderContentClass}>
      {(period.startTime && period.endTime && totalDaysInPeriod <= maxDaysToShowDeviationSettings) ?
        <Grid item xs={2} md={3} lg={4} className={styles.FollowupHeader_DeviationSettingsBanner}>
          <DeviationSettings startTime={Datetime.toISOString(period.startTime)} endTime={Datetime.toISOString(period.endTime)} followUp={true} />
        </Grid>
        : <Grid item xs={2} md={3} lg={4} className={styles.FollowupHeader_DeviationSettingsBanner}>
          <i>{t('Choose a period of maximum {{maxDaysToShowDeviationSettings}} days to view active deviation settings', { maxDaysToShowDeviationSettings })}</i>
        </Grid>}
      <Grid item xs={10} md={9} lg={8}>
        <HeaderPeriodPicker
          loading={loading}
          period={period}
          handlePeriodChange={(ev) => {
            handlePeriodChange(ev)
            postStat(`followup-header`, `change-period`)
          }}
          view="hour"
          isFollowup={isFollowupProduction}
        />
      </Grid>
    </Grid>
  )
}
