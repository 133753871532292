import React, { ReactElement } from 'react'

import { Button as AtomButton } from 'ui/atoms'
import { ButtonProps } from 'ui/atoms/Button/Button'

interface Props {
  isCollapsed: boolean
  children: ButtonProps['children']
  disabled?: boolean
  onClick: () => void
}

export function ToggleButton({ isCollapsed, onClick, children, disabled }: Props): ReactElement {
  return (
    <AtomButton
      secondary
      icon={isCollapsed ? 'fal fa-chevron-down' : 'fal fa-chevron-up'}
      disabled={disabled}
      onClick={onClick}
      style={{
        whiteSpace: 'nowrap', // Prevent line break between icon and text inside button
      }}
    >
      {children}
    </AtomButton>
  )
}
