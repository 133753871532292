/* eslint-disable */

import React, { ReactElement, useCallback } from 'react'

import { styled } from '@mui/material/styles';

import { Theme } from '@mui/material'
import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting, useObjectPropertySettings } from 'api/settings/settings.api'
import classNames from 'classnames'
import { TableOptions } from 'react-table'

import CustomTable from 'ui/components/CustomTable/CustomTable'
import TimeField from 'ui/components/CustomTable/components/TimeField/TimeField'
import { ActiveRow } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/ObjectPropertySettingForm'
import { getTextFromValue, getSettingValue, useHasPermission } from 'helpers/global.helper/global.helper'
import { isHistorySetting, isActiveSetting } from 'helpers/optimize.helper/optimize.helper'
import { formatClassificationString } from 'helpers/settingsModal.helper/settingsModal.helper'

import styles from './PreviousSettingsTable.module.less'

import CommentField from './components/CommentField/CommentField'
import ManageField from './components/ManageField/ManageField'
import { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import { useTranslation } from 'react-i18next'

const PREFIX = 'PreviousSettingsTable';

const classes = {
  editing: `${PREFIX}-editing`,
  deleted: `${PREFIX}-deleted`,
  added: `${PREFIX}-added`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.editing}`]: {
    backgroundColor: theme.palette.table.rowHighlight.editing,
  },

  [`& .${classes.deleted}`]: {
    backgroundColor: theme.palette.table.rowHighlight.deleted,
  },

  [`& .${classes.added}`]: {
    backgroundColor: theme.palette.table.rowHighlight.added,
  }
}));

const tableOptions: Partial<TableOptions<{}>> = {
  initialState: {
    sortBy: [{ id: 'updatedAt', desc: true }],
    hiddenColumns: ['id'],
    pageSize: 5,
  },
}

type PreviousSettingsTableProps = {
  activeRow?: ActiveRow
  objectProperty: ObjectProperty
  isBinaryProperty: boolean
  unit: string
  isBaseValue?: boolean
  permissions: SettingsPermissions
  onEditClick: (
    comment: string | null,
    end_time: string | null,
    start_time: string,
    id: number,
    value: string,
    classification?: string
  ) => void
  onDeleteClick: (id: number) => void
}

export default function PreviousSettingsTable({
  activeRow,
  unit,
  objectProperty,
  isBinaryProperty,
  isBaseValue,
  onEditClick,
  onDeleteClick,
  permissions,
}: PreviousSettingsTableProps): ReactElement {
  const { t } = useTranslation()


  const { data: allSettings } = useObjectPropertySettings(objectProperty.id, { is_base_value: isBaseValue })

  const activeSettings = allSettings?.filter((setting) => isActiveSetting(setting)) ?? []

  const historySettings = allSettings?.filter((setting) => isHistorySetting(setting)) ?? []

  const generateColumns = useCallback(
    (edit?: boolean) => [
      { Header: 'Id', accessor: 'id' },
      {
        Header: t('Start time'),
        accessor: 'startTime',
        Cell: TimeField,
      },
      {
        Header: t('End time'),
        accessor: 'endTime',
        Cell: TimeField,
      },
      { Header: t('Value '), accessor: 'value' },
      { Header: t('Unit '), accessor: 'unit' },

      { Header: t('Cause '), accessor: 'comment', Cell: CommentField },
      {
        Header: t('Last updated'),
        accessor: 'updatedAt',
        Cell: TimeField,
      },
      ...(edit ? [{ Header: t('Manage'), accessor: 'manage', disableSortBy: true }] : []),
    ],
    []
  )

  const activeSettingsColumns = React.useMemo(() => generateColumns(true), [generateColumns])
  const historySettingsColumns = React.useMemo(() => generateColumns(true), [generateColumns])

  const generateRows = useCallback(
    (settings: Setting[], canEdit?: boolean): Record<string, string | number | ReactElement | null>[] => {
      return settings.map((setting) => {
        const mappedValue = getTextFromValue(setting.value, objectProperty.value_mappings)
        const settingValue = mappedValue
          ? mappedValue
          : isBinaryProperty
            ? getSettingValue(setting.value)
            : setting.value

        const classificationTranslation = formatClassificationString(setting.classification) ?? ' - '

        return {
          id: setting.id,
          startTime: setting.start_time,
          endTime: setting.end_time,
          value: settingValue,
          unit: unit,
          comment: setting.comment,
          classification: classificationTranslation,
          updatedAt: setting.updated_at,
          ...(canEdit
            ? {
                manage: (
                  <ManageField
                    key={setting.id}
                    onEditClick={() => {
                      onEditClick(
                        setting.comment,
                        setting.end_time,
                        setting.start_time,
                        setting.id,
                        String(setting.value),
                        setting.classification
                      )
                    }}
                    onDeleteClick={() => {
                      onDeleteClick(setting.id)
                    }}
                    isDisabled={!canEdit}
                    isEditing={
                      activeRow && activeRow.status === 'editing' && activeRow.id === setting.id ? true : false
                    }
                  />
                ),
              }
            : null),
        }
      })
    },
    [activeRow, isBinaryProperty, objectProperty.value_mappings, onDeleteClick, onEditClick]
  )

  const activeSettingsData = React.useMemo(
    () => generateRows(activeSettings, permissions.canEdit),
    [activeSettings, generateRows]
  )
  const historySettingsData = React.useMemo(
    () => generateRows(historySettings, permissions.canEditHistoric),
    [historySettings, generateRows]
  )

  return (
    <Root className={styles.PreviousSettingsTable_Container}>
      <CustomTable
        columns={activeSettingsColumns}
        data={activeSettingsData}
        title={t('Active settings')}
        tableOptions={tableOptions}
        emptyTableText={t('No active settings found')}
        rowClass={(row) => {
          return classNames(
            styles.PreviousSettingsTable_Background,
            activeRow && {
              [classes[activeRow.status]]: (row.original as { id: number }).id === activeRow?.id,
            }
          )
        }}
      />
      <CustomTable
        columns={historySettingsColumns}
        data={historySettingsData}
        title={t('Previous settings')}
        tableOptions={tableOptions}
        emptyTableText={t('No historic settings found')}
        rowClass={styles.PreviousSettingsTable_Background}
      />
    </Root>
  );
}
