import React, { ReactElement, useMemo, useRef, useState } from 'react'

import { PlanValues } from 'api/elplan/elplan.api'
import elplanStore from 'store/elplan/elplan'
import { Dialog } from 'ui/components/Dialog/Dialog'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import Datetime from 'utils/datetime/datetime'

import { DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { clone } from 'helpers/global.helper/global.helper'

import styles from './ElectricityPlanModal.module.less'

type ElectricityPlanModalProps = {
  onClose: () => void
  onSubmit: (planValues: PlanValues[]) => void
  planValues?: PlanValues[]
  elplanExistsWarningText?: string
  title?: string
}

export default function ElectricityPlanModal({
  planValues,
  onClose,
  onSubmit,
  title,
  elplanExistsWarningText,
}: ElectricityPlanModalProps): ReactElement {
  const ref = useRef(null)
  const elplanSnap = useSnapshot(elplanStore)
  const [currentPlanValues, setCurrentPlanValues] = useState<PlanValues[]>(
    clone(planValues) ?? (clone(elplanSnap?.elplan.values) as unknown as PlanValues[])
  )

  const { t } = useTranslation()
  const timeAndValues = useMemo(() => {
    function onCellChange(e: any, index: number): void {
      const copy = currentPlanValues
      copy[index].volume = e.target?.innerText
      setCurrentPlanValues(copy)
    }

    return currentPlanValues
      ?.sort((a, b) => a.start_time.localeCompare(b.start_time))
      .map((obj, index) => {
        return (
          <tr key={obj.start_time}>
            <td
              className={styles.ElectricityPlanModal_Table_Cell}
            >{`${Datetime.toLocalTime(obj.start_time, 'hour')}-${Datetime.toLocalTime(obj.end_time, 'hour')}`}</td>
            <td
              className={styles.ElectricityPlanModal_EditableContent}
              contentEditable="true"
              suppressContentEditableWarning
              onBlur={(e) => onCellChange(e, index)}
            >
              {`${obj.volume}`}
            </td>
          </tr>
        )
      })
  }, [currentPlanValues])

  const halfOfHourValues = Math.ceil(timeAndValues.length / 2)

  return (
    <div className={styles.ElectricityPlanModal}>
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth container={ref.current}>
        <CustomDialogTitle title={title ?? t('Edit electricity plan')} handleClose={() => onClose()} />
        <DialogContent>
          <div className={styles.ElectricityPlanModal_Table}>
            <table>
              <tbody className={styles.ElectricityPlanModal_Body}>
                <tr className={styles.ElectricityPlanModal_Table_Cell}>
                  <th className={styles.ElectricityPlanModal_Table_Cell__header}>
                    {' '}
                    <span className={'fal fa-clock'} />
                    {t('Time')}
                  </th>
                  <th className={styles.ElectricityPlanModal_Table_Cell__header}>
                    {' '}
                    <span className={'fal fa-bolt'} />
                    {`${t('Volume')} (MW)`}
                  </th>
                </tr>
                <div className={styles.ElectricityPlanModal_Table_Cell}>{timeAndValues.slice(0, halfOfHourValues)}</div>
              </tbody>
              <tbody className={styles.ElectricityPlanModal_Body}>
                <tr className={styles.ElectricityPlanModal_Table_Cell}>
                  <th className={styles.ElectricityPlanModal_Table_Cell__header}>
                    {' '}
                    <span className={'fal fa-clock'} />
                    {t('Time')}
                  </th>
                  <th className={styles.ElectricityPlanModal_Table_Cell__header}>
                    {' '}
                    <span className={'fal fa-bolt'} />
                    {`${t('Volume')} (MW)`}
                  </th>
                </tr>
                <div className={styles.ElectricityPlanModal_Table_Cell}>{timeAndValues.slice(halfOfHourValues)}</div>
              </tbody>
            </table>
          </div>
          <ModalButtons
            onSubmit={() => {
              onSubmit(currentPlanValues)
              onClose()
            }}
            onCancel={() => onClose()}
            infoText={elplanSnap.elplan.has_plan ? elplanExistsWarningText : undefined}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
