import { ObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Setting } from 'api/settings/settings.api'
import { Unit } from 'api/units/units.api'
import { Category, Event } from 'ui/components/BarCalendar/BarCalendar.types'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'

import { getDisplayName } from 'helpers/global.helper/global.helper'
import { formatSettingsCalendarTitle} from 'helpers/optimizeSettings.helper/optimizeSettings.helper'

import { CELL_HEIGHT } from './SettingsCalendar/SettingsCalendar'


export type SettingsCalendarItemSetting = Setting & {
  title: string
  value: number | string
  backgroundColor: string
  endUntilFurtherNotice: boolean
  isEditable: boolean
  parentId: number
  object_property?: number
}

export type SettingsCalendarItem = Unit & {
  settings: SettingsCalendarItemSetting[]
}

export type RowUnitIndexAndSettings = {
  unitIndex: number
  numberOfSettings: number
}

export function getSumOfPreviousRowsSettings(rowUnitIndexAndSettings:RowUnitIndexAndSettings[], index: number): number {
  const copy = [...rowUnitIndexAndSettings]
  const rowsUpToIndex = copy.splice(0,index)
  return rowsUpToIndex.reduce(function (acc, obj) {
    const numberOfSettingsPerRow = obj.numberOfSettings
    if (numberOfSettingsPerRow > 1) {
      return acc + obj.numberOfSettings - 1
    }
    return acc + 0
  }, 0)
}

export function getNumberOfSettingsPerRow(items: SettingsCalendarItem[]): RowUnitIndexAndSettings[] {

  const indexAndHeight: RowUnitIndexAndSettings[] = []


  items.forEach((item, i) => {
    indexAndHeight.push({ unitIndex: i, numberOfSettings: item.settings.length})
  })

  return indexAndHeight
}

export function getCellHeight(rowCellHeights: RowUnitIndexAndSettings[], index: number): number {
  const find = rowCellHeights.find((row) => row.unitIndex === index)
  if (find) {
    if (find.numberOfSettings > 1) {
      return find?.numberOfSettings * CELL_HEIGHT
    }
  }
  return CELL_HEIGHT
}

export function unitsToCalendarItems(objectProperties: ObjectProperty[], units: Unit[]): SettingsCalendarItem[] {
  const items: SettingsCalendarItem[] = []
  if (units) {
    units.forEach((unit) => {
      items.push({ ...unit, settings: [] })
    })

    objectProperties.forEach((prop) => {
      const parentObject = prop.parent
      if (parentObject?.type === `unit`) {
        const unit = items.find((item) => item.id === parentObject.id)
        if (unit) {
          prop.active_settings?.forEach((setting) => {
            const obj = {
              attribute: prop.name,
              title: formatSettingsCalendarTitle(prop, setting),
              tooltip: prop.category,
              backgroundColor: unit.color,
              value: setting.value,
              isEditable: isSettingEditable(Datetime.toISOString(setting.end_time ?? Datetime.getISONow(168))),
              parentId: parentObject.id,
              endUntilFurtherNotice: setting.end_time === null,
            }
            unit.settings.push({...setting, ...obj})
          })
        }
      }
    })
  }
  return items
}

export function isSettingEditable(endTime: ISODateTime): boolean {
  if (Datetime.isAfter(endTime, Datetime.getISONow(0))) {
    return true
  }
  return false
}

export function viewableSettingPeriod(
  calendarEndTime: ISODateTime, 
  calendarStartTime: ISODateTime, 
  settingStartTime: ISODateTime, 
  settingEndTime: ISODateTime
): { startTime: ISODateTime | null, endTime: ISODateTime | null } {
  let viewableStartTime = settingStartTime
  let viewableEndTime = settingEndTime

  if (settingEndTime === null) {
    viewableEndTime = calendarEndTime

    if (Datetime.isBeforeOrEqual(settingStartTime, calendarStartTime)) {
      viewableStartTime = calendarStartTime
    }

    return {startTime: viewableStartTime, endTime: viewableEndTime}
  }

  if (Datetime.isBefore(settingStartTime, calendarStartTime) 
    && Datetime.isBefore(settingEndTime, calendarStartTime)
    || Datetime.isAfter(settingStartTime, calendarEndTime) 
    && Datetime.isAfter(settingEndTime, calendarEndTime)) {
    return { startTime: null, endTime: null }
  }



  if (Datetime.isBefore(settingStartTime, calendarStartTime)) {
    viewableStartTime = calendarStartTime
  } else if (Datetime.isAfterOrEqual(settingStartTime, calendarStartTime)) {
    viewableStartTime = settingStartTime
  }

  if (Datetime.isAfterOrEqual(settingEndTime, calendarEndTime)) {
    viewableEndTime = calendarEndTime
  }

  if (Datetime.isSameDate(viewableStartTime, viewableEndTime)) {
    viewableStartTime = Datetime.getStartOfDay(viewableStartTime)
    viewableEndTime = Datetime.getEndOfDay(viewableEndTime)
  }

  return {startTime: viewableStartTime, endTime: viewableEndTime}

}

export function generateCategoriesAndEvents(items: SettingsCalendarItem[]): { categories: Category[], events: Event[] } {
  const categories: Category[] = []
  const events: Event[] = []

  // categories, id = items.name, name = items.display_name
  items.forEach((item) => {
    categories.push({ id: item.name, name: getDisplayName(item) })
    item.settings.forEach((setting) => {
      const event: Event = {
        attribute: setting.attribute as string,
        name: setting.title,
        id: setting.id.toString(),
        type: setting.title.split(':')[0],
        startTime: setting.start_time ?? Datetime.getISONow(0),
        endTime: setting.end_time,
        categoryId: item.name,
        meta: {
          tooltipElement: setting.comment !== '' ? `${i18n.t('Cause')}: ${setting.comment}` : '',
          clickable: setting.isEditable,
          object_property: setting.object_property,
          comment: setting.comment,
          value: setting.value,
        },
      }
      events.push(event)
    })
  })

  return { categories, events }
}
