import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Grid, Typography, Icon } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './HourlyMetricField.module.less'

type HourFieldProps = {
  title: string
  value: number | string
  icon?: string
  warning?: boolean
  tooltip: string
}
function HourField({ title, value, icon, tooltip, warning }: HourFieldProps): ReactElement {
  return (
    <Grid item xs={5}>
      <Typography className={styles.HourlyMetricField_SmallText}>{title}</Typography>
      {icon && <Icon className={icon} />}
      <Typography className={warning ? styles.HourlyMetricField_LargeText_Warning : styles.HourlyMetricField_LargeText}>
        {value}
      </Typography>
      <Typography className={warning ? styles.HourlyMetricField_UnitText_Warning : styles.HourlyMetricField_UnitText}>
        {`h`}{' '}
      </Typography>
      <Tooltip arrow placement="top" title={tooltip}>
        {warning ? (
          <Icon className={classNames(`fas fa-info-circle`, styles.HourlyMetricField_WarningIcon)} fontSize="inherit" />
        ) : (
          <Icon className={classNames(`fas fa-info-circle`, styles.HourlyMetricField_InfoIcon)} fontSize="inherit" />
        )}
      </Tooltip>
    </Grid>
  )
}

type HourlyMetricFieldProps = {
  remainingHours: {
    unplannedSummerRemaining: number
    plannedRemaining: number
    unplannedWinterRemaining: number
  }
}
export default function HourlyMetricField({
  remainingHours: { unplannedSummerRemaining, unplannedWinterRemaining, plannedRemaining },
}: HourlyMetricFieldProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      className={styles.HourlyMetricField_Container}
    >
      <HourField
        title={t(`remaining unplanned`)}
        value={unplannedWinterRemaining}
        warning={unplannedWinterRemaining < 0}
        icon={`fal fa-snowflake`}
        tooltip={t(
          `Unplanned hours refer to interruptions that the supplier is informed of less than 14 days before it occurs. Winter refers to the period January 1 - April 30 and October 1 - December 31 the same year.`
        )}
      />
      <HourField
        title={t(`remaining unplanned`)}
        value={unplannedSummerRemaining}
        warning={unplannedSummerRemaining < 0}
        icon={`fal fa-sun`}
        tooltip={t(
          `Unplanned hours refer to interruptions that the supplier is informed of less than 14 days before it occurs. Summer refers to the period May 1 - September 30.`
        )}
      />
      <HourField
        title={t(`remaning, planned`)}
        value={plannedRemaining}
        warning={plannedRemaining < 0}
        tooltip={
          plannedRemaining < 0
            ? t(
              'More hours than allowed and planned have been consumed this period. Please check the number for `Planned hours, this year` in `Adjust hours to decline`.'
            )
            : t(
              `Planned hours refer to interruptions that the supplier is informed of at least 14 days before it occurs.`
            )
        }
      />
    </Grid>
  )
}
