import { ReactElement } from 'react'

import { resetZoom } from 'store/zoom/zoom'
import Checkbox from 'ui/atoms/Checkbox/Checkbox'

import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import optimizeViewStore from 'views/OptimizeView/store/optimizeViewStore'

export function OutcomeCheckbox(): ReactElement {
  const { t } = useTranslation()
  const optimizeViewSnap = useSnapshot(optimizeViewStore)

  return (
    <Checkbox
      label={t(`Show outcome`)}
      background
      isChecked={optimizeViewSnap.showOutcomeCheckbox}
      onClick={() => {
        optimizeViewStore.showOutcomeCheckbox = !optimizeViewSnap.showOutcomeCheckbox
        resetZoom()
      }}
    />
  )
}