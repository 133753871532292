import { ReactElement, useState } from 'react'

import { Button } from 'ui/atoms'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import CreateLogBookPostModal from './LogBookPostModal/LogBookPostModal'
import LogBookFlow from './components/LogBookFlow/LogBookFlow'
import PinnedMessages from './components/PinnedMessages/PinnedMessages'

const widgetConstants = {
  LOGBOOK_WIDGET_1: `item-1`,
  LOGBOOK_WIDGET_2: `item-2`,
  LOGBOOK_WIDGET_3: `item-3`,
}

export default function LogBookView(): ReactElement {
  const { t } = useTranslation()

  const [runTour, setRunTour] = useState<null | number>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const logBookSteps: Array<Step> = [
    {
      target: widgetConstants.LOGBOOK_WIDGET_1,
      content: `Here you create a new log book post.`,
      title: `Logbook`,
    },
    {
      target: widgetConstants.LOGBOOK_WIDGET_2,
      content: `Here you can see all pinned messages. To unpin a message, click on the thumbtack icon. To edit a message, click on the pen icon. You can also see if a message is reported and who created and edited the message.`,
      title: `Pinned Messages`,
    },
    {
      target: widgetConstants.LOGBOOK_WIDGET_3,
      content:
        'Here you can see the logbook flow in a table view. You find all posts created here. You can search for logbook posts, filter on any column you want and edit and delete posts. You can also resize any column to your choosing by dragging on the pin next to the column title. If you have any questions, feel free to contact us.',
      title: `Logbook flow`,
    },
  ]

  return (
    <div>
      <View
        header={
          <ModuleHeader
            infoHeader={t(`Logbook`)}
            onInfoButtonClick={() => {
              setRunTour(0)
            }}
          >
            <Grid item container xs={12} justifyContent="flex-end">
              <div className={widgetConstants.LOGBOOK_WIDGET_1}>
                <Button primary marginRight icon="fal fa-plus" onClick={() => setModalOpen(true)}>
                  {t(`Create logbook entry`)}
                </Button>
              </div>
            </Grid>
          </ModuleHeader>
        }
      >
        <Grid item xs={12}>
          <CardWidget id={widgetConstants.LOGBOOK_WIDGET_2} title={t('Pinned Messages')}>
            <PinnedMessages />
          </CardWidget>
        </Grid>
        <Grid item xs={12}>
          <CardWidget id={widgetConstants.LOGBOOK_WIDGET_3} title={t('Logbook flow')}>
            <LogBookFlow />
          </CardWidget>
        </Grid>
        <Tour
          isSingle={!!runTour}
          run={runTour !== null}
          setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
          steps={logBookSteps}
        />

        <CreateLogBookPostModal open={modalOpen} handleClose={() => setModalOpen(false)} edit={false} />
      </View>
    </div>
  )
}
