import React, { ReactElement, useState } from 'react'

import UiConfig from 'ui/uiConfig/UiConfig'
import Datetime from 'utils/datetime/datetime'

import styles from './Overview.module.less'
import { OverViewPeriodPickerAndSwitch } from './components/OverviewPeriodPickerAndSwitch/OverviewPeriodPickerAndSwitch'

export default function Overview(): ReactElement {
  const [period, setPeriod] = useState({
    startTime: Datetime.getStartOfDay(Datetime.getISONow(-48)),
    endTime: Datetime.getEndOfDay(Datetime.getISONow(48)),
  })

  let sent_marginal_cost = { startTime: period.startTime, endTime: period.endTime }

  const currentDate = Datetime.getISONow(0)
  const year = new Date(currentDate).getFullYear()
  const month = new Date(currentDate).getMonth()
  const day = new Date(currentDate).getDate()
  const limit = Datetime.toISOString(new Date(year, month, day, 13, 20))

  if (Datetime.isBefore(currentDate, limit) || Datetime.isBefore(period.endTime, limit)) {
    if (Datetime.isAfter(period.endTime, limit)) {
      sent_marginal_cost = { startTime: period.startTime, endTime: Datetime.getEndOfDay(currentDate) }
    } else {
      sent_marginal_cost = { startTime: period.startTime, endTime: period.endTime }
    }
  } else if (Datetime.isAfter(currentDate, limit)) {
    sent_marginal_cost = { startTime: period.startTime, endTime: Datetime.getTomorrowDate().endTime }
  }

  return (
    <>
      <div className={styles.Overview}>
        <div className={styles.Overview_KpiValues}>
          <UiConfig
            type="marginal_cost_kpi"
            ignoreZoom={true}
            datasetStartTime={period.startTime}
            datasetEndTime={period.endTime}
          />
        </div>
        <div className={styles.Overview_PeriodPicker}>
          <OverViewPeriodPickerAndSwitch period={period} handlePeriodChange={setPeriod} />
        </div>
      </div>
      <UiConfig
        type="marginal_cost_chart"
        ignoreZoom={false}
        overrideAlias={{
          sent_marginal_cost_start: period.startTime,
          sent_marginal_cost_end: sent_marginal_cost.endTime,
          prel_marginal_cost_start: sent_marginal_cost.endTime,
          prel_marginal_cost_end: period.endTime,
        }}
      />
    </>
  )
}
