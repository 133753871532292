import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConfigurableProperty,
  DigitalTwinSetting,
  DeviationSettingLevel,
  useDigitalTwin,
  useDigitalTwinSettings,
  DigitalTwinSettingTarget,
} from 'api/digitalTwin/digitalTwin.api'
import {
  BASE_SETTING_PRIO,
  BASE_SETTING_PRIORITIES,
  DEVIATION_SETTING_PRIORITIES,
} from 'api/digitalTwin/digitalTwinConstants'
import { OptProject } from 'api/optProjects/optProjects.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import DigitalTwinEditSettingsModal from 'ui/components/DigitalTwinEditSettingsModal/DigitalTwinEditSettingsModal'
import PropertiesTable from 'ui/components/PropertiesTable/PropertiesTable'
import SettingsTable, { SettingsPermissions } from 'ui/components/SettingsTable/SettingsTable'
import Datetime from 'utils/datetime/datetime'
import {
  getFilteredPropertiesByPriority,
  getPropertiesFromDigitalTwinModel,
  getPropertyTableItems,
} from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import {
  getActiveSettingByProperty,
  getHistorySettingByProperty,
  getUpcomingSettingByProperty,
} from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './ChangeLogSettings.module.less'

type PropertyOpenedByProps = DigitalTwinSettingTarget & { isBaseSetting: boolean }

type ChangeLogSettingsProps = {
  project: OptProject
  propertyToEditInitially?: PropertyOpenedByProps // When component mounts, open edit modal for this property
}

export default function ChangeLogSettings({
  project,
  propertyToEditInitially,
}: ChangeLogSettingsProps): ReactElement {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()

  const [propertyOpenedByProps, setSettingOpenedByProps] = useState<PropertyOpenedByProps>()
  const [deviationSettings, setDeviationSettings] = useState<DigitalTwinSetting[]>([])
  const [baseSettings, setBaseSettings] = useState<DigitalTwinSetting[]>([])
  const [editingSetting, setEditingSetting] = useState<{
    setting: { level: DeviationSettingLevel; name: string; attribute?: string }
    isBaseSetting: boolean
  }>()

  const permissions: SettingsPermissions = {
    canEdit: true,
    canEditHistoric: true,
  }

  const alertTexts = {
    change: {
      success: t(`Setting saved.`),
    },
  }

  const period = useMemo(
    () => ({ startTime: moment(project.start_time), endTime: moment(project.end_time) }),
    [project]
  )
  const startEndTimeRange = {
    startTime: {
      min: Datetime.toISOString(period.startTime), 
      max: Datetime.toISOString(period.endTime),
    },
    endTime: {
      min: Datetime.toISOString(period.startTime), 
      max: Datetime.toISOString(period.endTime),
    },
  }

  const { data: digitalTwin, isLoading: loadingDigitalTwin } = useDigitalTwin(project.digital_twin, true)

  const allConfigurableProperties: ConfigurableProperty[] = useMemo(
    () => (digitalTwin?.model ? getPropertiesFromDigitalTwinModel(digitalTwin.name, digitalTwin.model) : []),
    [digitalTwin?.model, digitalTwin?.name]
  )

  // Check if there is a property specified by props. If there is, make sure it exists in the available properties
  useEffect(() => {
    if (propertyOpenedByProps === propertyToEditInitially) {
      return // Props have not changed, do nothing
    }

    setSettingOpenedByProps(propertyToEditInitially)

    if (propertyToEditInitially === undefined) {
      return // No setting to open, do nothing
    }

    const isBaseSetting = propertyToEditInitially.isBaseSetting

    for (const property of allConfigurableProperties) {
      // Don't open modal if the target property doesn't have the given priority in its available priorities
      const targetPropertyIsBase =
        isBaseSetting && property.available_priorities?.includes(BASE_SETTING_PRIO.priority_level)
      const targetPropertyIsDeviation =
        !isBaseSetting &&
        DEVIATION_SETTING_PRIORITIES.some((prio) => property.available_priorities?.includes(prio.priority_level))

      if (
        propertyToEditInitially.level === property.level &&
        propertyToEditInitially.name === property.name &&
        propertyToEditInitially.attribute === property.attribute &&
        (targetPropertyIsBase || targetPropertyIsDeviation)
      ) {
        setEditingSetting({ setting: propertyToEditInitially, isBaseSetting })
        break
      }
    }
  }, [allConfigurableProperties, propertyToEditInitially, propertyOpenedByProps])

  const [filteredProperties, filteredBaseProperties] = useMemo<ConfigurableProperty[][]>(() => {
    const deviationPriorities = [...DEVIATION_SETTING_PRIORITIES, ...(activeSystem?.setting_priority_levels ?? [])]
    return getFilteredPropertiesByPriority(allConfigurableProperties, deviationPriorities, BASE_SETTING_PRIORITIES)
  }, [activeSystem?.setting_priority_levels, allConfigurableProperties])

  const { mutateAsync: fetchSettings, isLoading: loadingSettings } = useDigitalTwinSettings()
  const now = useMemo(() => Datetime.getISONow(), [])

  const refetchSettings = useCallback(() => {
    fetchSettings({
      digitalTwinUid: digitalTwin?.uid,
      properties: allConfigurableProperties,
      priorities: [...BASE_SETTING_PRIORITIES, ...DEVIATION_SETTING_PRIORITIES],
      activeFrom: Datetime.toISOString(period.startTime),
      activeTo: Datetime.toISOString(period.endTime),
    }).then((settings: DigitalTwinSetting[]) => {
      const newDeviationSettings: DigitalTwinSetting[] = []
      const newBaseSettings: DigitalTwinSetting[] = []
      settings.forEach((setting) => {
        if (setting.priority === BASE_SETTING_PRIO.priority_level) {
          newBaseSettings.push(setting)
        } else {
          newDeviationSettings.push(setting)
        }
      })
      setDeviationSettings(newDeviationSettings)
      setBaseSettings(newBaseSettings)
    })
  }, [allConfigurableProperties, digitalTwin?.uid, fetchSettings, period.startTime, period.endTime])
  useEffect(refetchSettings, [refetchSettings])

  const activeSettingByProperty = useMemo(() => getActiveSettingByProperty(deviationSettings), [deviationSettings])
  const activeBaseSettingByProperty = useMemo(() => getActiveSettingByProperty(baseSettings), [baseSettings])
  const upcomingSettingByProperty = useMemo(
    () => getUpcomingSettingByProperty(now, deviationSettings),
    [deviationSettings, now]
  )
  const historySettingByProperty = useMemo(
    () => getHistorySettingByProperty(now, deviationSettings),
    [deviationSettings, now]
  )

  const baseSettingTableData = useMemo(
    () =>
      getPropertyTableItems({
        activeBaseSettingByProperty,
        activeSettingByProperty,
        digitalTwin,
        filteredProperties: filteredBaseProperties,
        historySettingByProperty,
        isBaseSettings: true,
        periodStartTime: Datetime.toISOString(period.startTime),
        periodEndTime: Datetime.toISOString(period.endTime),
        canEdit: permissions.canEdit,
        t,
        upcomingSettingByProperty,
        setActiveSetting: (setting) => {
          if (setting === undefined) {
            setEditingSetting(undefined)
          } else {
            setEditingSetting({ setting, isBaseSetting: true })
          }
        },
        loading: loadingDigitalTwin || loadingSettings,
      }),
    [
      activeBaseSettingByProperty,
      activeSettingByProperty,
      digitalTwin,
      filteredBaseProperties,
      historySettingByProperty,
      loadingDigitalTwin,
      loadingSettings,
      period.endTime,
      period.startTime,
      permissions.canEdit,
      t,
      upcomingSettingByProperty,
    ]
  )

  const deviationSettingTableData = useMemo(
    () =>
      getPropertyTableItems({
        activeBaseSettingByProperty,
        activeSettingByProperty,
        digitalTwin,
        filteredProperties,
        historySettingByProperty,
        isBaseSettings: false,
        periodStartTime: Datetime.toISOString(period.startTime),
        periodEndTime: Datetime.toISOString(period.endTime),
        canEdit: permissions.canEdit,
        t,
        upcomingSettingByProperty,
        setActiveSetting: (setting) => {
          if (setting === undefined) {
            setEditingSetting(undefined)
          } else {
            setEditingSetting({ setting, isBaseSetting: false })
          }
        },
        loading: loadingDigitalTwin || loadingSettings,
      }),
    [
      activeBaseSettingByProperty,
      activeSettingByProperty,
      digitalTwin,
      filteredProperties,
      historySettingByProperty,
      loadingDigitalTwin,
      loadingSettings,
      period.endTime,
      period.startTime,
      permissions.canEdit,
      t,
      upcomingSettingByProperty,
    ]
  )

  return (
    <>
      <Grid container direction="column" className={styles.ChangeLogSettings_Container}>
        <Grid item xs={12} className={styles.ChangeLogSettings_InnerContainer}>
          <Typography variant="h2" className={styles.ChangeLogSettings_Title}>
            {t(`Deviation settings`)}
          </Typography>
          {digitalTwin ? (
            <PropertiesTable
              dataIn={deviationSettingTableData}
              translations={digitalTwin?.translations}
              priorities={DEVIATION_SETTING_PRIORITIES}
              permissions={permissions}
              isBaseSettings={false}
            />
          ) : (
            <SettingsTable
              permissions={permissions}
              classification={`deviation`}
              alertTexts={alertTexts}
              startEndTimeRange={startEndTimeRange}
              period={period}
              optModel={project.opt_model}
              tableOptions={{ initialState: { pageSize: 5 } }}
            />
          )}
        </Grid>
        <Grid item xs={12} className={styles.ChangeLogSettings_InnerContainer}>
          <Typography variant="h2" className={styles.ChangeLogSettings_Title}>
            {t(`Opt model settings`)}
          </Typography>
          {digitalTwin ? (
            <PropertiesTable
              dataIn={baseSettingTableData}
              translations={digitalTwin?.translations}
              priorities={BASE_SETTING_PRIORITIES}
              permissions={permissions}
              isBaseSettings={true}
            />
          ) : (
            <SettingsTable
              permissions={permissions}
              classification={`base`}
              alertTexts={alertTexts}
              startEndTimeRange={startEndTimeRange}
              period={period}
              optModel={project.opt_model}
              tableOptions={{
                initialState: { sortBy: [{ id: `updatedAt`, desc: true }], hiddenColumns: [`status`], pageSize: 5 },
              }}
            />
          )}
        </Grid>
        {!!project.opt_model && (
          <Grid item xs={12} className={styles.ChangeLogSettings_InnerContainer}>
            <Typography variant="h2" className={styles.ChangeLogSettings_Title}>
              {t(`Prices`)}
            </Typography>
            <SettingsTable
              permissions={permissions}
              classification={`price`}
              alertTexts={alertTexts}
              startEndTimeRange={startEndTimeRange}
              period={period}
              optModel={project.opt_model}
              tableOptions={{
                initialState: {
                  sortBy: [{ id: `updatedAt`, desc: true }],
                  hiddenColumns: [`category`, `status`],
                  pageSize: 5,
                },
              }}
            />
          </Grid>
        )}
      </Grid>
      {digitalTwin && editingSetting && editingSetting.setting.attribute && (
        <DigitalTwinEditSettingsModal
          configurableProperties={allConfigurableProperties}
          level={editingSetting.setting.level}
          attribute={editingSetting.setting.attribute}
          name={editingSetting.setting.name}
          period={period}
          onClose={() => setEditingSetting(undefined)}
          digitalTwin={digitalTwin}
          permissions={permissions}
          priorities={editingSetting.isBaseSetting ? BASE_SETTING_PRIORITIES : DEVIATION_SETTING_PRIORITIES}
          baseSettingPriorities={BASE_SETTING_PRIORITIES}
          onSettingModified={refetchSettings}
          isBaseSettings={editingSetting.isBaseSetting}
        />
      )}
    </>
  )
}
