import React, { ReactElement } from 'react'

import { useAllSystemUiConfigs } from 'api/uiConfig/uiConfig.api'
import { ResizeableColumn } from 'ui/atoms'

import UiConfigPanel from 'views/UiConfigView/components/UiConfigPanel/UiConfigPanel'

import styles from './UiConfigView.module.less'
import UiConfigEditor from './components/UiConfigEditor/UiConfigEditor'
import UiConfigPreview from './components/UiConfigPreview/UiConfigPreview'
import 'ui/uiConfig/components/index'

export default function UiConfigView(): ReactElement {
  useAllSystemUiConfigs({ mode: `dev` })

  return (
    <div className={styles.UiConfigView}>
      <ResizeableColumn width={`200px`}>
        <UiConfigPanel />
      </ResizeableColumn>
      <ResizeableColumn width={`50%`}>
        <UiConfigPreview />
      </ResizeableColumn>
      <UiConfigEditor />
    </div>
  )
}
