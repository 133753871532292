import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import {
  ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
  useCopyBidsFromDate,
  useElplanBidCreateMutation,
  useElplanSendingBidMutation,
  useElplanInfo,
  useSentBidsToBroker,
  BidTypeName,
  ElectricityPriceScenario,
} from 'api/elplan/elplan.api'
import { postStat } from 'api/stats/stats.api'
import { useUiConfigAnchorComponent } from 'api/uiConfig/uiConfig.api'
import elplanStore, { setCurrentDate } from 'store/elplan/elplan'
import { Button, Switch, Tabs } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import { DatePickerInForm } from 'ui/molecules/pickers/DatePicker/DatePicker'
import Datetime from 'utils/datetime/datetime'
import { translateKeyWithSpecificTranslations } from 'utils/stringUtils'

import { Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { calcDataset, exportPlannedElectricityProductionToExcel } from 'helpers/dataset.helper/dataset.helper'
import { convertNumberDecimalSign, isArray } from 'helpers/global.helper/global.helper'

import BidModal from './BidModal/BidModal'
import CopyBidsModal from './CopyBidsModal/CopyBidsModal'
import {
  FormattedBid,
  FormattedBidWithIdAndTitle,
  getDatasetInstructionsForSumVolumeCalc,
  getItemsForExcel,
} from './DayAheadBidManagement.helper'
import styles from './DayAheadBidManagementView.module.less'
import ElectricityPriceScenarioVisualization from './ElectricityPriceScenarioVisualization/ElectricityPriceScenarioVisualization'

const showSentBidsAtBanner = (sentBidsToBrokerAtDate: undefined | { latest_date: ISODateTime }): ReactElement => {
  if (sentBidsToBrokerAtDate?.latest_date) {
    const formattedDate = Datetime.toLocalTime(sentBidsToBrokerAtDate.latest_date, 'hour')
    return (
      <InfoBanner
        style={'info'}
        text={t(`Bids sent at {{sentDate}}.`, {
          sentDate: formattedDate,
        })}
      />
    )
  }

  return <InfoBanner style={'warningWithoutBackground'} text={t('No bids sent')} />
}

export default function BidManagementView(): ReactElement {
  const elplanSnap = useSnapshot(elplanStore)
  const { t } = useTranslation()
  const auth = useAuth()

  const [currentView, setCurrentView] = useState<'tomorrow' | 'week' | ''>('tomorrow')
  const [chosenDate, setChosenDate] = useState<{ startTime: ISODateTime; endTime: ISODateTime }>({
    startTime: Datetime.getTomorrowDate().startTime,
    endTime: Datetime.getTomorrowDate().endTime,
  })
  const [typeOfBidToCopy, setTypeOfBidToCopy] = useState<BidTypeName>()
  const [currentBlockBid, setCurrentBlockBid] = useState<FormattedBidWithIdAndTitle>()

  const [currentProfileBid, setCurrentProfileBid] = useState<FormattedBidWithIdAndTitle>()

  const systemHasBlockBids = elplanSnap.bid_types.some((bid) => bid.type === 'blockbid')
  const systemHasProfileBids = elplanSnap.bid_types.some((bid) => bid.type === 'profilebid')
  const amountOfBlockBids = elplanSnap.blockBidInfo.amount || 0
  const amountOfProfileBids = elplanSnap.blockBidInfo.amount || 0

  // Datasets for excel export of bids
  const onlyReturnIds = useMemo(() => {
    const onlyReturnIds = ['spotbid_without_price.volume']

    if (systemHasBlockBids) {
      for (let i = 0; i < amountOfBlockBids; i++) {
        onlyReturnIds.push(`blockbid.${i + 1}.volume`)
        onlyReturnIds.push(`blockbid.${i + 1}.price`)
      }
    }

    if (systemHasProfileBids) {
      for (let i = 0; i < amountOfProfileBids; i++) {
        onlyReturnIds.push(`profilebid.${i + 1}.volume`)
        onlyReturnIds.push(`profilebid.${i + 1}.price`)
      }
    }

    return onlyReturnIds
  }, [amountOfBlockBids, amountOfProfileBids, systemHasBlockBids, systemHasProfileBids])

  
  const updateCurrentView = useCallback((newCurrentView: 'tomorrow' | 'week' | '') => {
    if (newCurrentView === 'tomorrow') {
      setChosenDate({ startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getTomorrowDate().endTime })
    }

    if (newCurrentView === 'week') {
      setChosenDate({ startTime: Datetime.getTomorrowDate().startTime, endTime: Datetime.getDateOneWeekAhead() })
    }

    setCurrentView(newCurrentView)
  }, [])

  const { data: elplanBidsUiConfig } = useUiConfigAnchorComponent('elplan_bids')
  const { data: elplanBidsDatasets = [] } = useDatasets(
    elplanBidsUiConfig?.id ?? -1,
    elplanBidsUiConfig?.version ?? -1,
    elplanBidsUiConfig?.dataset_instructions || [],
    chosenDate.startTime,
    chosenDate.endTime,
    {
      onlyReturnIds,
      fillMissingHourTimestamps: false,
      datasetRefreshToken: ELPLAN_BID_REFRESH_EVENT_QUERY_KEY,
      overrideAlias: {
        elplan_info_id: elplanSnap.elplan_info_id,
      },
    }
  )

  const itemsForExcel = getItemsForExcel(amountOfBlockBids)
  const itemsDataId: Set<string> = new Set(itemsForExcel.map((item) => item.data_id))
  const visibleDatasets = elplanBidsDatasets.filter(({ return_id }) => itemsDataId.has(return_id))
  const datasetNames = (itemsForExcel || []).reduce((acc, item) => ({ ...acc, [item.data_id]: item.title }), {})

  const primaryDigitalTwin = auth.activeSystem?.primary_digital_twin
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwin?.uid, true, true)

  const { mutate: createBid } = useElplanBidCreateMutation()
  const { mutate: copyBidsFromDate } = useCopyBidsFromDate()
  const { mutate: sendBidToElplan } = useElplanSendingBidMutation()

  function addNewBlockOrProfileBid(bid: FormattedBid): void {
    createBid({
      elplan_info: elplanSnap.elplan_info_id,
      start_time: Datetime.toISOString(bid.startTime),
      end_time: Datetime.toISOString(bid.endTime),
      type: bid.type,
      volume: isArray(bid.volume)
        ? bid.volume.map((vol) => convertNumberDecimalSign(vol) as number)
        : convertNumberDecimalSign(bid.volume),
      price: convertNumberDecimalSign(bid.price),
      name: bid.name || '-',
      electricity_price_scenario: bid.scenarioId,
      generate_block_order: true,
    })
  }

  function onDateTimePickerChange(value: ISODateTime): void {
    setChosenDate({ startTime: Datetime.getStartOfDay(value), endTime: Datetime.getEndOfDay(value) })
    setCurrentDate(Datetime.getStartOfDay(value), Datetime.getEndOfDay(value))
    updateCurrentView('')
  }

  function copySpotBidsFromDate(date: ISODateTime, type: string): void {
    postStat('copy-spotbids-from-date', `click`)

    setTypeOfBidToCopy(undefined)
    copyBidsFromDate({
      elplan_info: elplanSnap.elplan_info_id,
      from_date: Datetime.toLocalTime(date, 'onlyDate') as ISODateTime,
      to_date: Datetime.toLocalTime(chosenDate.startTime, 'onlyDate') as ISODateTime,
      type: type,
    })
  }

  const sentBidsToBrokerAtDate = useSentBidsToBroker(
    elplanSnap.elplan_info_id,
    chosenDate.startTime,
    chosenDate.endTime,
    undefined
  )
  const { data: elplanInfo } = useElplanInfo(auth.systemId)
  const integrationExists = elplanInfo?.integration ?? false

  const calcDatasets = calcDataset(elplanBidsDatasets, getDatasetInstructionsForSumVolumeCalc(amountOfBlockBids))
  const maxBidVolume = elplanSnap?.maxBidVolume
  const bidsIsOvermaxBidVolume =
    typeof maxBidVolume === 'number' ? calcDatasets.values.some((num) => num && num > maxBidVolume) : false

  const scenarios: ElectricityPriceScenario[] = useMemo(() => {
    if (!elplanInfo) {
      return []
    }

    if (elplanInfo.scenarios.length === 0) {
      return [
        {
          bid_types: (elplanInfo?.bid_types ?? []).map((bid) => bid.type),
          elplan_info_id: elplanInfo?.id,
          id: 1,
          scenario_name: '',
          scenario_number: 0,
          all_bids: true,
          optjob_subtypes: [],
          ui_config_id: null,
          hide_optimize_button: true,
          uiconfig_override_alias: {},
        },
      ]
    }
  
    return elplanInfo.scenarios
  }, [elplanInfo])

  function closeBlockBidModal(): void {
    setCurrentBlockBid(undefined)
  }

  function closeProfileBidModal(): void {
    setCurrentProfileBid(undefined)
  }

  return (
    <div>
      <CardWidget title={t(`DA - Bid management`)} id={''}>
        <div className={styles.DayAheadBidManagementView_Period}>
          <Switch
            value={currentView}
            items={[
              { label: t('Tomorrow'), value: 'tomorrow' },
              { label: t('Week'), value: 'week' },
            ]}
            onClick={updateCurrentView}
            setNoTabActive={currentView === ''}
          />
          <DatePickerInForm
            value={chosenDate.startTime}
            onSubmit={(date) => {
              onDateTimePickerChange(date)
            }}
            label={t('Chosen date')}
            showWeekNumbers
            granularity={'day'}
            isRequired
          />
          <div className={styles.DayAheadBidManagementView_Period__right}>
            {integrationExists && (
              <Tooltip
                title={
                  bidsIsOvermaxBidVolume
                    ? t(`Some bids are over the maximum bid volume. Max volume for bids is {{maxBidVolume}} MW`, {
                      maxBidVolume: elplanSnap.maxBidVolume,
                    })
                    : ''
                }
                arrow
              >
                <div className={styles.DayAheadBidManagementView_SendBidsButtonContainer}>
                  <Button
                    primary
                    marginLeft
                    disabled={bidsIsOvermaxBidVolume || currentView === 'week'}
                    onClick={() => {
                      if (!auth.activeSystem) {
                        return
                      }
                      sendBidToElplan({
                        date: Datetime.toLocalTime(chosenDate.startTime, 'onlyDate'),
                        system: auth.activeSystem?.name,
                      })
                    }}
                  >
                    {t('Send bids')}
                  </Button>
                  {currentView !== 'week' && showSentBidsAtBanner(sentBidsToBrokerAtDate.data ?? undefined)}
                </div>
              </Tooltip>
            )}
            <TooltipIconButton
              icon="fas fa-cloud-download-alt"
              iconColor={'#016b80'}
              iconSize={'medium'}
              onClick={() => exportPlannedElectricityProductionToExcel(visibleDatasets, datasetNames, chosenDate)}
              tooltip={t('Export to Excel')}
            />
          </div>
        </div>
        {scenarios.length === 1 && (
          <ElectricityPriceScenarioVisualization
            scenario={scenarios[0]}
            datasetStartTime={chosenDate.startTime}
            datasetEndTime={chosenDate.endTime}
            setCurrentBlockBid={setCurrentBlockBid}
            setCurrentProfileBid={setCurrentProfileBid}
            setTypeOfBidToCopy={setTypeOfBidToCopy}
            currentView={currentView}
          />
        )}
        {(elplanInfo?.scenarios?.length ?? 0) > 1 && (
          <Tabs
            items={(elplanInfo?.scenarios ?? [])
              .sort((a, b) => a.scenario_number - b.scenario_number)
              .map((scenario) => ({
                title: translateKeyWithSpecificTranslations(scenario.scenario_name, digitalTwin?.translations),
                children: (
                  <ElectricityPriceScenarioVisualization
                    scenario={scenario}
                    datasetStartTime={chosenDate.startTime}
                    datasetEndTime={chosenDate.endTime}
                    setCurrentBlockBid={setCurrentBlockBid}
                    setCurrentProfileBid={setCurrentProfileBid}
                    setTypeOfBidToCopy={setTypeOfBidToCopy}
                    currentView={currentView}
                  />
                ),
              }))}
            uniqueStorageKey={'elprice_scenario_tabs'}
          />
        )}
      </CardWidget>
      {typeOfBidToCopy !== undefined && (
        <CopyBidsModal
          onSubmit={(chosenDate) => copySpotBidsFromDate(chosenDate, typeOfBidToCopy ?? '')}
          closeModal={() => setTypeOfBidToCopy(undefined)}
        />
      )}
      {currentBlockBid !== undefined && (
        <BidModal
          closeModal={closeBlockBidModal}
          bid={currentBlockBid}
          createBid={addNewBlockOrProfileBid}
          editBid={addNewBlockOrProfileBid}
          type={'block'}
          isEditing={currentBlockBid.fake}
        />
      )}
      {currentProfileBid !== undefined && (
        <BidModal
          closeModal={closeProfileBidModal}
          bid={currentProfileBid}
          createBid={addNewBlockOrProfileBid}
          editBid={addNewBlockOrProfileBid}
          type={'profile'}
          isEditing={currentProfileBid.fake}
        />
      )}
    </div>
  )
}
