import React, {ReactElement, useMemo} from 'react'

import { colors } from 'styles/variables'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'

import { CalendarEvent } from 'views/EventsView/components/EventsCalendar/EventsCalendar'

import styles from './BarCalendar.module.less'
import { Category, Event } from './BarCalendar.types'
import Column from './components/BCColumn/BCColumn'
import EventBar from './components/BCEventBar/BCEventBar'
import Row from './components/BCRow/BCRow'

export const FILLER_EVENT_ID = 'filler'


type BarCalenderProps = {
  startTime: ISODateTime
  endTime: ISODateTime
  events: Event[] | CalendarEvent[]
  categories: Category[]
  onClick?: (setting: Event) => void
  header?: string
  isOperationalEventCalendar?: boolean
  disableDefaultSorting?: boolean
}


function getVisibleEvents(events: Event[], visibleStartTime: ISODateTime, visibleEndTime: ISODateTime): Event[] {
  const filteredEvents = events.filter(event => {
    if (!event.startTime || !Datetime.isBeforeOrEqual(event.startTime, visibleEndTime)) {
      return false
    }

    if (!!event.endTime && !Datetime.isAfterOrEqual(event.endTime, visibleStartTime)) {
      return false
    }

    return true
  })

  const result: Event[] = []

  let previousEndTime = visibleStartTime
  for (const event of filteredEvents) {
    if (!event.startTime) {
      continue
    }

    let eventStartTime = event.startTime
    let eventEndTime = event.endTime

    if (Datetime.isBefore(previousEndTime, eventStartTime)) {
      result.push({
        id: FILLER_EVENT_ID,
        type: event.type,
        startTime: previousEndTime,
        endTime: eventStartTime,
        categoryId: event.categoryId,
        color: '#ff000000',
        name: 'FILLER',
        // getNrOfHoursBetween(00:00, 00:00) = 1, so we need to subtract 1.
        hours: Datetime.getNrOfHoursBetween(eventStartTime, previousEndTime) - 1,
      })
    }

    if (eventEndTime == null || Datetime.isAfter(eventEndTime, visibleEndTime)) {
      eventEndTime = visibleEndTime
      event.isTurnicatedEnd = true
    }
    if (Datetime.isAfter(visibleStartTime, eventStartTime)) {
      eventStartTime = visibleStartTime
      event.isTurnicatedStart = true
    }

    event.hours = Datetime.getNrOfHoursBetween(eventEndTime, eventStartTime)
    result.push(event)
    previousEndTime = Datetime.addHours(eventEndTime, 1)
  }

  return result
}

function formatEvents(events: (Event | CalendarEvent)[], startTime: string, endTime: string):
{
  timeRows: { [key: string]: { [key: string]: (Event | CalendarEvent)[][] }},
  totalRowsByCategory: { [key: string]: number }
} {
  events = events.filter((event) => {
    const eventStartTime = event.startTime ? new Date(event.startTime).getTime() : null
    const eventEndTime = event.endTime ? new Date(event.endTime).getTime() : null
    const visibleStartTime = new Date(startTime).getTime()
    const visibleEndTime = new Date(endTime).getTime()

    return (eventEndTime == null || eventEndTime >= visibleStartTime) 
      && eventStartTime
      && eventStartTime <= visibleEndTime
  })

  const eventsByCategory: { [key: string]: (Event | CalendarEvent)[] } = {}
  events.forEach((event) => {
    if (!eventsByCategory[event.categoryId]) {
      eventsByCategory[event.categoryId] = []
    }
    eventsByCategory[event.categoryId].push(event)
  })

  const timeRows: { [key: string]: { [key: string]: (Event | CalendarEvent)[][] } } = {}
  const totalRowsByCategory: { [key: string]: number } = {}
  for (const categoryId in eventsByCategory) {
    const eventsInCategory = eventsByCategory[categoryId]
    timeRows[categoryId] = {}

    eventsInCategory.forEach((event) => {
      const typeId = event.type
      if (!timeRows[categoryId][typeId]) {
        timeRows[categoryId][typeId] = []
      }

      const newRow = [event]
      let addedToRow = false

      for (const row of timeRows[categoryId][typeId]) {
        if (!addedToRow && !row.some((rowEvent) => checkOverlap(rowEvent, event))) {
          row.push(event)
          addedToRow = true
        }
      }

      if (!addedToRow) {
        timeRows[categoryId][typeId].push(newRow)
      }
    })

    totalRowsByCategory[categoryId] = Object.values(timeRows[categoryId]).reduce(
      (total, rowsOfType) => total + rowsOfType.length,
      0
    )
  }

  for (const categoryId in timeRows) {
    const typesInCategory = timeRows[categoryId]
    for (const typeId in typesInCategory) {
      const rowsOfType = typesInCategory[typeId]
      const staggeredRows: (Event | CalendarEvent)[][] = []

      rowsOfType.forEach((row) => {
        let addedToRow = false
        for (const staggeredRow of staggeredRows) {
          if (!addedToRow && !staggeredRow.some((staggeredEvent) => row.some((rowEvent) => checkOverlap(rowEvent, staggeredEvent)))) {
            staggeredRow.push(...row)
            addedToRow = true
          }
        }
        if (!addedToRow) {
          staggeredRows.push([...row.map((event) => ({ ...event }))]) // Create a new array for the non-overlapping set
        }
      })

      timeRows[categoryId][typeId] = staggeredRows
    }
  }

  function checkOverlap(eventA: (Event | CalendarEvent), eventB: (Event | CalendarEvent)): boolean {
    if (!eventA.startTime || !eventB.startTime) {
      return false
    }

    const startTimeA = new Date(eventA.startTime).getTime()
    const endTimeA = eventA.endTime ? new Date(eventA.endTime).getTime() : null
    const startTimeB = new Date(eventB.startTime).getTime()
    const endTimeB = eventB.endTime ? new Date(eventB.endTime).getTime() : null

    return (
      (endTimeB == null || startTimeA <= endTimeB)
        && (endTimeA == null || startTimeB <= endTimeA)
    )
  }

  return { timeRows, totalRowsByCategory }
}


function isCalendarEvent(obj: unknown): obj is CalendarEvent {
  return !!obj && typeof obj === 'object' && 'attribute' in obj
}

export default function BarCalendar({
  startTime,
  endTime,
  events,
  categories = [],
  onClick,
  disableDefaultSorting,
  header = i18n.t('Unit'),
  isOperationalEventCalendar = false,
}: BarCalenderProps): ReactElement {
  startTime = Datetime.toISOString(new Date(new Date(startTime).setHours(0, 0, 0, 0)))
  endTime = Datetime.toISOString(new Date(new Date(endTime).setHours(23, 59, 59, 999)))

  const currentDate: ISODateTime = Datetime.getISONow(0)

  const sortedCategories = useMemo(() => { return [...categories].sort((a, b) => a.name.localeCompare(b.name))}, [categories])

  const dates = useMemo(() => {
    return Datetime.getDatesBetween(startTime, endTime)
  }, [startTime, endTime])

  const sortedEvents = useMemo(() => {
    if (disableDefaultSorting) {
      return events
    }

    return [...events].sort((a, b) => {
      const aStartTimeMs = a.startTime ? new Date(a.startTime).getTime() : 0
      const bStartTimeMs = b.startTime ? new Date(b.startTime).getTime() : 0
      return aStartTimeMs - bStartTimeMs
    })
  }, [events, disableDefaultSorting])

  const eventColors = [
    colors.primary3,
  ]
  const typeColors: { [key: string]: string } = {}
  sortedEvents.forEach((event) => {
    if (!typeColors[event.type]) {
      typeColors[event.type] = eventColors[0]
      if (isCalendarEvent(event) && event.attribute) {
        if (event.attribute.includes('max') || event.attribute.includes('min')) { // yellow
          typeColors[event.type] = '#f1c202'
        } else if (event.attribute.includes('availability') || event.attribute.includes('forced')) { // red
          typeColors[event.type] = '#f35e5e'
        }
      }
    }
  })
  sortedEvents.forEach((event) => {
    if (isOperationalEventCalendar) {
      event.color
    } else {
      event.color = typeColors[event.type]
    }
  })

  // Format events into rows
  const { timeRows, totalRowsByCategory } = useMemo(() => {
    return formatEvents(sortedEvents, startTime, endTime)
  }, [sortedEvents, startTime, endTime])

  const defaultRowHeight = 35
  const defaultDayWidth = useMemo(() => {
    return `(85vw - 240px)/${Math.min(dates.length, 8)}`
  }, [dates])

  // Calculate the number of hours between the min and max date
  const visibleHours = useMemo(() => {
    const minTimestamp = new Date(startTime).getTime()
    const maxTimestamp = new Date(endTime).getTime()
    const hours = Math.round((maxTimestamp - minTimestamp) / 3600e3)
    return hours
  }, [startTime, endTime])

  return (
    <div className={styles.SettingsCalendar}>
      <div className={styles.CategoryColumn}>
        <Row>
          <Column header={{textContent: header}}/>
        </Row>
        {
          sortedCategories.map((category: Category) => {
            const nRows = totalRowsByCategory[category.id] || 0
            return nRows > 0 ? (
              <div key={category.id}><Row height={defaultRowHeight*nRows} centerContent={true}>{ category.name }</Row></div>
            ): null
          })
        }
      </div>
      <div className={styles.CalenderSection}>
        <div className={styles.ColumnWrapper}>
          <Row height={defaultRowHeight}>
            {
              dates.map((date: ISODateTime, index: number) => {
                const isToday: boolean = Datetime.toLocalTime(date, 'onlyDate') === Datetime.toLocalTime(currentDate, 'onlyDate')
                const isWeekend: boolean = Datetime.isWeekend(date)
                const textContent = Datetime.toLocalTime(date, 'shortDayTextWithMonth')
                return (
                  <Column
                    header={{textContent, isToday, isWeekend}}
                    width={defaultDayWidth}
                    key={`${index}${textContent}`}
                  />
                )
              })
            }
          </Row>
          {
            sortedCategories.map((category: Category) => {
              if (!totalRowsByCategory[category.id]) return
              if (!timeRows[category.id]) {
                return (
                  <Row key={category.id} height={defaultRowHeight} width={`calc(${defaultDayWidth}*${dates.length})`}/>
                )
              }

              const categoryTimeRows = timeRows[category.id]
              const typeKeys = Object.keys(categoryTimeRows)

              return (
                <div key={category.id} style={{
                  width: `calc(${defaultDayWidth}*${dates.length})`,
                }}>
                  {typeKeys.map((typeKey) => {
                    const typeTimeRows = categoryTimeRows[typeKey]
                    return (
                      <React.Fragment key={typeKey}>
                        {typeTimeRows.map((row: (Event | CalendarEvent)[], rowIndex: number) => {
                          const visibleEvents = getVisibleEvents(row, startTime, endTime)
                          return (
                            <Row 
                              key={`${typeKey}-${rowIndex}`} 
                              height={defaultRowHeight} 
                              width={`calc(${defaultDayWidth}*${dates.length})`}
                              padded={true}
                            >
                              {
                                visibleEvents.map((event: Event, eventIndex: number) => {
                                  const widthRatio = event.hours ? event.hours / visibleHours : 1
                                  return (
                                    <EventBar
                                      event={{
                                        ...event,
                                        categoryName: category.name,
                                      }}
                                      onClick={onClick}
                                      width={`calc(${defaultDayWidth}*${dates.length*widthRatio})`}
                                      key={`${typeKey}-${rowIndex}-${eventIndex}`}
                                    />
                                  )
                                })
                              }
                            </Row>
                          )
                        }
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}