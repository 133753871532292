import { OptProject } from 'api/optProjects/optProjects.api'

import i18n from 'i18next'

export function getSandboxOptName(obj: OptProject[]): string {
  let num = 0
  obj.forEach((opt) => {
    if (opt.display_name.includes(i18n.t(`Sandbox `))) {
      num++
    }
    return opt
  })
  return i18n.t(`Sandbox `) + (num + 1)
}

export function getSandboxOptStatus(status?: string): string | undefined {
  return status
    ? {
      Created: i18n.t(`Not started`),
      Queued: i18n.t(`Optimizing`),
      Running: i18n.t(`Optimizing`),
      Failed: i18n.t(`Optimization failed`),
      Unsolvable: i18n.t(`Optimization failed`),
      Finished: i18n.t(`Optimization finished`),
    }[status] ?? ``
    : ``
}

export function getSandboxOptType(type: string): string | undefined {
  return (
    {
      Single: i18n.t(`Single optimization`),
    }[type] ?? ``
  )
}
