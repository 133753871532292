import React, { ReactElement, ReactNode, useMemo } from 'react'

import TimeSeriesDataTable from 'ui/components/TimeSeriesDataTable/TimeSeriesDataTable'
import { TimeSeriesDataTableColumnDef, TimeSeriesDataTableData } from 'ui/components/TimeSeriesDataTable/types'
import {
  registerUiConfigComponent,
  registerAppendPropsWithDatasets,
  registerDefaultProps,
  registerComponentHeight,
} from 'ui/uiConfig/factory'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useSnapshot } from 'valtio'

import { sortChartItems } from '../Chart/Tooltip/tooltip.helper'
import { ChartItem } from '../Chart/chartTypes'
import { getDatasetsInItems, getReturnIdsFromDatasetInstruction, getAggregatedDatasets, serializeTableAccessor, normalizeDatasetId } from 'helpers/dataset.helper/dataset.helper'
import { clone, getTypeObject, isNumber, round, sortArrayOfDicts } from 'helpers/global.helper/global.helper'
import optimizeViewStore from 'views/OptimizeView/store/optimizeViewStore'

import styles from './DatasetTable.module.less'
import DatasetTableHeader from './DatasetTableHeader'

export type DatasetTableItem = UiConfigPropsItem & {
  title: string
  sub_title?: string
  data_id: string
  unit: string
  decimals: number
  data_type?: string
  table_order: number
  show_only_in_export?: boolean
  border_right?: boolean
}

export type DatasetTableProps = {
  items: DatasetTableItem[]
  customColumns?: TimeSeriesDataTableColumnDef[]
  datasets: Dataset[]
  children: ReactNode
  title: string
  do_not_show_zero_or_null_values_in_table: boolean //excludes datasets with only 0 or null values
  do_not_show_download_button: boolean //hides download button
  do_not_show_sum_or_average_row: boolean //hides sum and average row as rows
  do_not_show_min_max_row: boolean //hides min and max row as rows
  show_only_value_in_future: boolean
  show_initial_page_with_current_optimization_hour?: boolean
  show_initial_page_with_current_day?: boolean
  initial_page_size?: number
  do_not_show_top_toolbar: boolean
  columnOverrides?: { [data_id: string]: TimeSeriesDataTableColumnDef }
  enableEditing?: boolean
  enableColumnActions?: boolean
  tableKey?: string
  disableCustomSorting?: boolean
  tableContainerRef?: React.RefObject<HTMLDivElement>
  use_column_color_from_items?: boolean //use color from item instead of every other grey column
  sorting_function?: (string & 'table_order') | 'order' | 'chart_items' | 'alphabetical' | 'chart_table_order'
  footer_calculates_per_page?: boolean //if true, the footer will calculate the sum, average, min and max per page and not for the whole dataset
  onMouseMove?: (event: React.MouseEvent<HTMLTableCellElement>) => void
  max_height?: string | number | undefined
  sticky_table_header?: boolean
  hideSingleValueDatasets?: boolean,
  onPaginationChange: (newPagination: { pageIndex: number, pageSize: number }) => void
}

const defaultProps: Omit<DatasetTableProps, `datasets` | `children`> = {
  items: [
    {
      title: ``,
      sub_title: '',
      data_id: ``,
      unit: ``,
      table_order: 0,
      decimals: 1,
      data_type: ``,
      border_right: false,
    },
  ],
  title: ``,
}

function appendPropsWithDataset(
  datasetInstruction: DatasetInstruction,
  previousProps: DatasetTableProps
): DatasetTableProps {
  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)
  const newItems = returnIds.map((returnId) => ({
    title: returnId,
    sub_title: '',
    data_id: returnId,
    unit: ``,
    decimals: 1,
    data_type: ``,
    table_order: 0,
    border_right: false,
  }))

  return {
    ...previousProps,
    do_not_show_zero_or_null_values_in_table: false,
    do_not_show_download_button: false,
    do_not_show_sum_or_average_row: false,
    do_not_show_min_max_row: false,
    show_only_value_in_future: false,
    show_initial_page_with_current_optimization_hour: false,
    show_initial_page_with_current_day: false,
    initial_page_size: 10,
    do_not_show_top_toolbar: false,
    use_column_color_from_items: false,
    footer_calculates_per_page: false,
    sorting_function: 'table_order',
    title: ``,
    items: [...(previousProps?.items || []), ...newItems],
  }
}

registerUiConfigComponent('dataset_table', DatasetTable)
registerAppendPropsWithDatasets('dataset_table', appendPropsWithDataset)
registerDefaultProps('dataset_table', defaultProps)
registerComponentHeight('dataset_table', 300)

function isNotZeroOrNull(currentValue: number | null): boolean {
  return currentValue === 0 || currentValue === null
}

function cleanDatasetsForTable(datasets: Dataset[], do_not_show_zero_or_null_values_in_table: boolean, hideSingleValueDatasets: boolean): Dataset[] {
  let filteredDatasets = clone(datasets)

  if (hideSingleValueDatasets) {
    filteredDatasets = filteredDatasets.filter((dataset) => !(dataset.times.length <= 1)) //datasets with only one value does not show in dataset table
  }

  if (do_not_show_zero_or_null_values_in_table) {
    filteredDatasets = filteredDatasets.filter((dataset) => !dataset.values.every(isNotZeroOrNull)) //filter datasets on values if all of them are 0 or null
  }

  return filteredDatasets
}

function sortDatasetItems(items: DatasetTableItem[], sorting_function?: string): DatasetTableItem[] {
  if (sorting_function === 'table_order') {
    return sortArrayOfDicts(items, 'table_order')
  } else if (sorting_function === 'order') {
    return sortArrayOfDicts(items, 'order')
  } else if (sorting_function === 'chart_items') {
    //same function that sorts chart items in charts and tooltip
    return sortChartItems(items)
  } else if (sorting_function === 'alphabetical') {
    return items.sort((a, b) => a.title.localeCompare(b.title))
  } else if (sorting_function === 'chart_table_order') {
    //sorts first by chart items, then in table order including undefined values
    const chartOrder = sortChartItems(items) as DatasetTableItem[]
    return sortTableOrderWithUndefined(chartOrder)
  }
  return items
}

// sort that allows table_order undefined, items without table_order will be put first, then items with table_order are sorted numerically and ascending
function sortTableOrderWithUndefined(items: DatasetTableItem[]): DatasetTableItem[] {
  return items.sort((a, b) => {
    if(a.table_order === b.table_order) { 
      return 0
    } else if (!a.table_order) {
      return -1
    } else if  (!b.table_order) {
      return 1
    }
    return a.table_order > b.table_order ? 1 : -1
  })
}

function getColumnTitleFromItem(item: DatasetTableItem): string {

  if(item.title && item.sub_title) {
    return `${item.title} ${item.sub_title}`
  }

  return item.title
}

export default function DatasetTable({
  items,
  customColumns,
  datasets,
  title,
  do_not_show_zero_or_null_values_in_table,
  do_not_show_download_button,
  do_not_show_sum_or_average_row,
  do_not_show_min_max_row,
  show_only_value_in_future,
  show_initial_page_with_current_optimization_hour,
  show_initial_page_with_current_day,
  initial_page_size,
  do_not_show_top_toolbar,
  columnOverrides,
  enableEditing,
  enableColumnActions,
  tableKey,
  disableCustomSorting,
  tableContainerRef,
  use_column_color_from_items,
  footer_calculates_per_page,
  sorting_function,
  onMouseMove,
  max_height,
  sticky_table_header,
  onPaginationChange,
  hideSingleValueDatasets=true,
}: DatasetTableProps): ReactElement {
  const optimizeViewSnap = useSnapshot(optimizeViewStore)

  datasets = datasets || []
  items = items || []

  const itemsInOrder = sortDatasetItems(clone(items), sorting_function)

  const datasetNames = (itemsInOrder || []).reduce((acc, item) => ({ ...acc, [item.data_id]: getColumnTitleFromItem(item) }), {})
  const datasetsInExport = getDatasetsInItems(itemsInOrder as ChartItem[], clone(datasets))

  const datasetsInTable =
    !do_not_show_sum_or_average_row || !do_not_show_min_max_row
      ? getDatasetsInItems(
          itemsInOrder as ChartItem[],
          cleanDatasetsForTable(datasets, do_not_show_zero_or_null_values_in_table, hideSingleValueDatasets)
      )
      : cleanDatasetsForTable(datasets, do_not_show_zero_or_null_values_in_table, hideSingleValueDatasets)

  const itemsToShowInTable = itemsInOrder.filter(
    (item) => !item.show_only_in_export && datasetsInTable.find((dataset) => dataset.return_id === item.data_id)
  )

  const returnIdToDecimals = itemsInOrder.reduce((acc, item) => ({ ...acc, [normalizeDatasetId(item.data_id)]: item.decimals }), {})

  const sumAndAverageRows =  useMemo(() => { return !do_not_show_sum_or_average_row ?
    [...getAggregatedDatasets(datasetsInTable, 'Sum'),
      ...getAggregatedDatasets(datasetsInTable, 'Average')] : []},
  [do_not_show_sum_or_average_row, datasetsInTable])

  const minAndMaxRows = useMemo(() => { return !do_not_show_min_max_row ?
    [...getAggregatedDatasets(datasetsInTable, 'Max'),
      ...getAggregatedDatasets(datasetsInTable, 'Min')] : []},
  [do_not_show_min_max_row, datasetsInTable])

  const columnsInTable = useMemo(() => {
    const columns: TimeSeriesDataTableColumnDef[] = (itemsToShowInTable || [])
      .map((item) => {
        const unit = getTypeObject(0, item.data_type, item.unit, item.decimals).unit
        const column: TimeSeriesDataTableColumnDef = {
          enableColumnActions: enableColumnActions,
          accessorKey: serializeTableAccessor(item.data_id),
          header: ``,
          Header: () => (<DatasetTableHeader title={item.title} sub_title={item.sub_title} unit={unit}/>),
          Footer: () => {
            return (
              <>
                {!do_not_show_sum_or_average_row &&
                  <>
                    <span>{sumAndAverageRows[0][serializeTableAccessor(item.data_id)]}</span>
                    <br />
                    <span>{sumAndAverageRows[1][serializeTableAccessor(item.data_id)]}</span>
                  </>
                }
                {!do_not_show_min_max_row &&
                  <>
                    {!do_not_show_sum_or_average_row && <br />}
                    <span>{minAndMaxRows[0][serializeTableAccessor(item.data_id)]}</span>
                    <br />
                    <span>{minAndMaxRows[1][serializeTableAccessor(item.data_id)]}</span>
                  </>
                }
              </>
            )
          },
          muiTableHeadCellProps: {
            align: 'right',
          },
          minSize: 110,
          size: 110,
          color: item.color ?? null,
          borderRight: item.border_right ?? false,
          align: 'right',
        }

        // Override column properties if they are defined in the columnOverrides prop
        if (columnOverrides && item.data_id in columnOverrides) {
          const relevantOverrides = columnOverrides[item.data_id]
          Object.assign(column, relevantOverrides)
        }

        return column
      })

    const timeColumn: TimeSeriesDataTableColumnDef = {
      enableColumnActions: enableColumnActions,
      accessorKey: `time`,
      header: 'time',
      Header: () => <span style={{ whiteSpace: 'pre-wrap'}}>{i18n.t(`Time`)}</span>,
      Cell: ({ renderedCellValue }) => <span style={{ whiteSpace: `nowrap`}} className={styles.DatasetTable_TimeColumn}>{renderedCellValue}</span>,
      Footer: () => {
        return (
          <div className={styles.DatasetTable_TimeColumn}>
            {!do_not_show_sum_or_average_row && (
              <>
                <span>{sumAndAverageRows[0][`time`]} {footer_calculates_per_page ? `(${i18n.t(`current page`)})` : ''}</span>
                <br />
                <span>{sumAndAverageRows[1][`time`]} {footer_calculates_per_page ? `(${i18n.t(`current page`)})` : ''}</span>
              </>
            )}
            {!do_not_show_min_max_row && (
              <>
                {!do_not_show_sum_or_average_row && <br />}
                <span>{minAndMaxRows[0][`time`]} {footer_calculates_per_page ? `(${i18n.t(`current page`)})` : ''}</span>
                <br />
                <span>{minAndMaxRows[1][`time`]} {footer_calculates_per_page ? `(${i18n.t(`current page`)})` : ''}</span>
              </>
            )}
          </div>
        )
      },
      minSize: 75,
      size: footer_calculates_per_page ? 195 : 160,
      align: 'left',
      enableEditing: false,
    }

    // Override column properties if they are defined in the columnOverrides prop
    if (columnOverrides && 'time' in columnOverrides) {
      const relevantOverrides = columnOverrides['time']
      Object.assign(timeColumn, relevantOverrides)
    }

    return [timeColumn, ...customColumns || [], ...columns]
  }, [
    columnOverrides, 
    do_not_show_min_max_row, 
    do_not_show_sum_or_average_row, 
    enableColumnActions, 
    footer_calculates_per_page, 
    itemsToShowInTable, 
    minAndMaxRows, 
    sumAndAverageRows,
    customColumns,
  ])

  const dataInTable = useMemo(() => {
    const data: TimeSeriesDataTableData[] = []
    datasetsInTable.forEach((dataset) => {
      const serializedTableAccessor = serializeTableAccessor(dataset.return_id)
      const item = itemsToShowInTable.find((item) => item.data_id === dataset.return_id)
      const indexToDisappear: number[] = []
      dataset.times.forEach((time, index) => {
        if (!data[index]) {
          data[index] = {}
        }
        if (!data[index][`time`]) {
          data[index][`time`] = Datetime.toLocalTime(time, 'longDayTextWithoutYear')
        }
      })

      dataset.values?.forEach((value, index) => {
        if (!indexToDisappear.includes(index)) {
          data[index][serializedTableAccessor] = isNumber(value)
            ? round(value, item?.decimals ?? 1)
            : null
        }
      })
    })

    if (show_only_value_in_future) {
      const now = optimizeViewSnap.currentOptimizationHour
      const nowInLocalTime = Datetime.toLocalTime(now, 'longDayTextWithoutYear')
      const nowIndex = data.findIndex((item) => item[`time`] === nowInLocalTime)
      return data.splice(nowIndex, data.length)
    }

    return data
  }, [datasetsInTable, itemsToShowInTable, show_only_value_in_future, optimizeViewSnap.currentOptimizationHour])

  return (
    <div className={styles.DatasetTable}
      style={{ maxHeight: max_height, overflow: 'hidden' }}
    >
      {title && <div className={styles.DatasetTable_Title}>{title}</div>}
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, overflowX: `auto`, overflowY: 'hidden'}}>
            <TimeSeriesDataTable
              columns={columnsInTable}
              data={dataInTable}
              useColumnColorFromItem={use_column_color_from_items ?? false}
              initialPageSize={initial_page_size}
              hideDownloadButton={do_not_show_download_button}
              datasetsInExport={datasetsInExport}
              showInitialPageWithCurrentOptimizationHour={show_initial_page_with_current_optimization_hour ?? false}
              showInitialPageWithCurrentDay={show_initial_page_with_current_day ?? false}
              datasetNames={datasetNames}
              hideTopToolbar={do_not_show_top_toolbar}
              enableEditing={enableEditing}
              editingMode={enableEditing ? 'table' : undefined}
              tableKey={tableKey}
              tableContainerRef={tableContainerRef}
              disableCustomSorting={disableCustomSorting}
              footerCalculatesPerPage={footer_calculates_per_page ?? false}
              returnIdToDecimals={returnIdToDecimals}
              hideSumAndAverageRow={do_not_show_sum_or_average_row}
              hideMinMaxRow={do_not_show_min_max_row}
              onMouseMove={onMouseMove}
              max_height={max_height}
              sticky_table_header={sticky_table_header}
              onPaginationChange={onPaginationChange}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  )
}
