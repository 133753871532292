import React, { ReactElement } from 'react'

import uiConfigStore from 'store/uiConfig/uiConfig'
import ErrorFallback from 'ui/components/ErrorFallback/ErrorFallback'
import FilterComponent from 'ui/components/Filter/FilterComponent'
import UiConfigComponent from 'ui/uiConfig/UiConfigComponent'
import sentry from 'utils/sentry/sentry'

import { ErrorBoundary } from 'react-error-boundary'
import { useSnapshot } from 'valtio'

import styles from './UiConfigPreview.module.less'

export default function UiConfigPreview(): ReactElement {
  const snap = useSnapshot(uiConfigStore)
  const uid = snap.editingUid

  if (!uid) {
    return <div className={styles.UiConfigPreview} />
  }

  return (
    <div className={styles.UiConfigPreview}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error) => sentry.captureException(error)}>
        <FilterComponent datasetRefreshToken={''} />
        <UiConfigComponent uid={uid} />
      </ErrorBoundary>
    </div>
  )
}
