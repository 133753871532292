import React, { useState, ReactElement, useEffect } from 'react'

import { postStat } from 'api/stats/stats.api'
import Button from 'ui/atoms/Button/Button'
import FollowupButton from 'ui/components/Followup/FollowupHeader/FollowupButton'
import { DatePickerPeriod } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'
import type { Props as DatePickerPeriodProps } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'

import { Grid, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import { handlePeriodChange } from 'views/FollowupProductionView/FollowupProductionView'

import styles from './HeaderPeriodPicker.module.less'

type HeaderPeriodPickerProps = {
  period: Period
  handlePeriodChange: handlePeriodChange
  loading?: boolean
  isFollowup?: boolean
  showTitle?: boolean
  onExport?: () => void
  exportButtonTitle?: string
  maxDate?: DatePickerPeriodProps['maxDate']
  minDate?: DatePickerPeriodProps['minDate']
  view?: string
}

export default function HeaderPeriodPicker({
  handlePeriodChange,
  loading,
  period,
  isFollowup,
  showTitle = true,
  onExport,
  exportButtonTitle,
  maxDate,
  minDate,
}: HeaderPeriodPickerProps): ReactElement {
  const { t } = useTranslation()

  useEffect(() => {
    setPeriodData({ period: { ...period } })
  }, [period])

  const [periodData, setPeriodData] = useState<{
    period: {
      startTime: Moment
      endTime: Moment
    }
  }>({
    period: {
      startTime: period.startTime,
      endTime: period.endTime,
    },
  })
  const [isError, setIsError] = useState(false)

  const handleDatePickerError = (error: boolean): void => {
    setIsError(error)
  }
  const hasFollowupBtnAccess = useHasPermission(`run_followup_opt`)

  return (
    <Grid container direction="column">
      {showTitle && (
        <Grid item xs={12} lg={8}>
          <Typography variant="h3" className={styles.HeaderPeriodPicker_Title}>
            {t(`Choose period`)}
          </Typography>
        </Grid>
      )}
      <Grid item container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} lg={8}>
          <DatePickerPeriod
            minDate={minDate}
            maxDate={maxDate ?? moment().endOf('day')}
            startDate={periodData.period.startTime}
            endDate={periodData.period.endTime}
            onSelect={(startTime, endTime) => {
              setPeriodData({
                period: {
                  startTime: moment(startTime),
                  endTime: moment(endTime),
                },
              })
            }}
            onError={handleDatePickerError}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <div style={{ display: `flex`, justifyContent: 'center' }}>
            <Button
              primary
              noRadiusLeft={isFollowup && hasFollowupBtnAccess}
              disabled={isError || loading}
              icon="fal fa-redo"
              onClick={() => {
                handlePeriodChange(periodData.period)
                postStat(`period-picker`, `apply-period-change`)
              }}
            >
              {t(`Load period`)}
            </Button>
            {isFollowup && hasFollowupBtnAccess ? <FollowupButton period={periodData.period} /> : null}
            {onExport && (
              <Button marginLeft secondary icon="fas fa-cloud-download-alt" onClick={onExport}>
                {exportButtonTitle}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
