import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery, UseQueryOptions } from 'react-query'

export type ShiftHandoverCommentGroup = DisplayNameObject & {
  id: number
  system: number
  units: number[]
}

export const SHIFT_HANDOVER_COMMENT_GROUPS_KEY = `shiftHandoverCommentGroups`
export function useShiftHandoverCommentGroups(
  queryConfig?: UseQueryOptions<
    ShiftHandoverCommentGroup[],
    unknown, 
    ShiftHandoverCommentGroup[], 
    (string | { system: number | undefined })[]
  >
) {
  const { systemId } = useAuth()
  
  return useQuery(
    [SHIFT_HANDOVER_COMMENT_GROUPS_KEY, { system: systemId }],
    () =>
      apiClient<ShiftHandoverCommentGroup[]>(`shift_handover_comment_groups`, {
        params: {
          system: systemId,
        },
      }),
    {
      ...queryConfig,
      enabled: !!systemId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
    }
  )
}
