import React, { useState, ReactElement } from 'react'

import {
  useWeatherForecast,
  useWeatherLocations,
  WeatherForecast,
  WeatherLocation,
} from 'api/weatherLocations/weatherLocations.api'
import { useTimeoutInterval } from 'hooks/utilsHooks/utilsHooks'

import {  Grid, styled, Typography } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'

import styles from './StartPageHeaderContent.module.less'
import WeatherInfo from './components/WeatherInfo'

export const startpageHeaderContentClass = `startpageHeaderContent`

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(`md`)]: {
    display: `none`,
  },
}))


function Clock(): ReactElement {
  const [time, setTime] = useState(moment())

  useTimeoutInterval({ interval: 1000, callback: () => setTime(moment()) })

  return (
    <StyledGrid
      item
      justifyContent="flex-start"
      alignItems="flex-end"
      container
      direction="column"
      xl={3}
      lg={4}
      md={8}
    >
      <Typography variant="body1" className={styles.StartPageHeaderContent_BoldText}>
        {time.format(`dddd, D MMMM`)}
      </Typography>
      <h3 className={styles.StartPageHeaderContent_Clock}>{time.format(`HH:mm:ss`)}</h3>
    </StyledGrid>
  )
}

// TODO: Weather info is only avaible currently for opt model. Should support digital twin too.
export default function StartpageHeaderContent(): ReactElement {
  const weatherLocationsQueriesReponse = useWeatherLocations()
  const weatherLocations = weatherLocationsQueriesReponse.reduce<WeatherLocation[]>((locations, response) => {
    return response.data ? [...locations, response.data] : locations
  }, [])

  const weatherForecastQueriesResponse = useWeatherForecast({ start_time: moment().add(1, `hour`).set(`minutes`, 0) })
  const weatherForecast: Record<string, WeatherForecast[]> = weatherForecastQueriesResponse.reduce(
    (forecasts, response) => {
      return response.data ? { ...forecasts, ...response.data } : forecasts
    },
    {}
  )

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      className={classNames(startpageHeaderContentClass, styles.StartPageHeaderContent_HeaderContent)}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        xl={8}
        lg={7}
        md={4}
        sm={4}
        xs={12}
        className={styles.StartPageHeaderContent_WeatherGrid}
      >
        {weatherLocations.length
          ? weatherLocations.map((location) => {
            return (
              <Grid item key={location.id}>
                <WeatherInfo forecast={weatherForecast[location.id]} location={location} />
              </Grid>
            )
          })
          : null}
      </Grid>
      <Clock />
    </Grid>
  )
}
