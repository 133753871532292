import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './Inline.module.less'

type InlineProps = {
  children?: (false | ReactElement | ReactElement)[] | ReactElement
  spaceBetween?: boolean
  flexEnd?: boolean
  alignTop?: boolean
  fullWidth?: boolean
  marginTop?: boolean
  marginBottom?: boolean
}

export default function Inline({
  children,
  spaceBetween,
  marginTop,
  marginBottom,
  flexEnd,
  alignTop,
  fullWidth = true,
}: InlineProps): ReactElement {
  return (
    <div
      className={dynamicClassName({
        [styles.Inline]: true,
        [styles.Inline__spaceBetween]: !!spaceBetween,
        [styles.Inline__flexEnd]: !!flexEnd,
        [styles.Inline__fullWidth]: !!fullWidth,
        [styles.Inline__marginTop]: !!marginTop,
        [styles.Inline__marginBottom]: !!marginBottom,
        [styles.Inline__alignTop]: !!alignTop,
      })}
    >
      {children}
    </div>
  )
}
