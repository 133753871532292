import React, { ReactElement, useState } from 'react'

import { editingUiConfigStore } from 'store/uiConfig/uiConfig'
import { Button, TextField, Select, Switch, Inline, Toggle, RecordInput } from 'ui/atoms'
import { fetchDatasetsFromDbS } from 'utils/dbs/dbs'

import { TextField as MuiTextField, Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { getReturnIdsFromDatasetInstruction, serializeDatasetInstruction } from 'helpers/dataset.helper/dataset.helper'
import { clone } from 'helpers/global.helper/global.helper'

import styles from './AddDatasetForm.module.less'

type AddDatasetFormProps = {
  onSubmit: (datasetInstruction: DatasetInstruction) => void
}

type SupportedDatasetTypes = 'dbs' | 'calc'

export default function AddDatasetForm(props: AddDatasetFormProps): ReactElement {
  const [loadingState, setLoadingState] = useState<'' | 'loading' | 'error' | 'success'>('')
  const { t } = useTranslation()

  const editingUiConfigSnap = useSnapshot(editingUiConfigStore)

  const [datasetInstruction, setDatasetInstruction] = useState<DatasetInstruction>({
    type: (editingUiConfigSnap.uiConfigEditorDefaultValues.type as SupportedDatasetTypes) || `dbs`,
    return_id: ``,
    contract: {
      metadata_filter: editingUiConfigSnap.uiConfigEditorDefaultValues?.metadata_filter ? JSON.parse(editingUiConfigSnap.uiConfigEditorDefaultValues.metadata_filter) : {
        include: {
          organization: 'sigholm',
          system: 'demostaden',
          internal_id: 'temperature',
          abs_service: 'abs_weather',
        },
        exclude: {},
      },
      id_renaming: {},
      global_read_api: false,
      tag: ``,
      variables: [],
      operator: `+`,
      element_wise: true,
      sources: [],
      system_id: '$system_id',
      opt_model_id: `$opt_model_id`,
      subtype: ``,
    },
    filter: {
      start_time: `$start_time`,
      end_time: `$end_time`,
      offset_start_time: `$offset_start_time`,
      offset_end_time: `$offset_end_time`,
      aggregate: ``,
    },
  })
  const contractCalc = datasetInstruction.contract as DatasetContractCalc

  function onSubmit(): void {
    const serializedDatasetInstruction = serializeDatasetInstruction(datasetInstruction)
    props.onSubmit(serializedDatasetInstruction)

    // Update default values
    editingUiConfigStore.uiConfigEditorDefaultValues.type = datasetInstruction.type
    editingUiConfigStore.uiConfigEditorDefaultValues.metadata_filter = JSON.stringify(datasetInstruction.contract.metadata_filter || {})
  }

  return (
    <div className={styles.AddDatasetForm}>
      <Inline spaceBetween>
        <h2>{t(`Add dataset`)}</h2>
        <Switch
          value={datasetInstruction.type}
          onClick={(type) =>
            setDatasetInstruction({
              ...datasetInstruction,
              type: type as SupportedDatasetTypes,
            })
          }
          items={[
            {
              label: `dbs`,
              value: `dbs`,
            },
            {
              label: `calc`,
              value: `calc`,
            },
          ]}
        />
      </Inline>

      <h3>{t(`Contract`)}</h3>
      {/* DbS */}
      {datasetInstruction.type === 'dbs' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <pre>metadata_filter.include</pre>
            <RecordInput
              value={datasetInstruction.contract.metadata_filter?.include || {}}
              onChange={(record) => {
                const updatedInstruction = clone(datasetInstruction)

                if (!updatedInstruction.contract.metadata_filter) {
                  updatedInstruction.contract.metadata_filter = {
                    include: {},
                    exclude: {},
                  }
                }
                updatedInstruction.contract.metadata_filter.include = record
                setDatasetInstruction(updatedInstruction)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <pre>metadata_filter.exclude</pre>
            <RecordInput
              value={datasetInstruction.contract.metadata_filter?.exclude || {}}
              onChange={(record) => {
                const updatedInstruction = clone(datasetInstruction)

                if (!updatedInstruction.contract.metadata_filter) {
                  updatedInstruction.contract.metadata_filter = {
                    include: {},
                    exclude: {},
                  }
                }
                updatedInstruction.contract.metadata_filter.exclude = record
                setDatasetInstruction(updatedInstruction)
              }}
            />
          </Grid>

          {/* On non-produciton environment, this setting allow reading data from production database. */}
          {process.env.REACT_APP_STAGE !== `production` && (
            <Grid item xs={12}>
              <Toggle
                label={t(`Global read API`)}
                value={datasetInstruction.contract.global_read_api || false}
                onClick={(global_read_api) =>
                  setDatasetInstruction({
                    ...datasetInstruction,
                    contract: { ...datasetInstruction.contract, global_read_api },
                  })
                }
              />


              <p>
                {t(
                  `If enabled, the DbS API token used will be the global read API token with data from AbS Prod. This is useful for fetching datasets like measurements, forecast, electricity prices and weather.`
                )}
              </p>
            </Grid>
          )}

          <Grid item xs={12}>
            <pre>id_renaming</pre>
            <RecordInput
              value={datasetInstruction.contract.id_renaming || {}}
              onChange={(record) => {
                const updatedInstruction = clone(datasetInstruction)
                updatedInstruction.contract.id_renaming = record
                setDatasetInstruction(updatedInstruction)
              }}
            />
            <Button
              secondary
              disabled={Object.keys(datasetInstruction.contract?.metadata_filter?.include ?? {}).length === 0 || loadingState === 'loading'}
              onClick={async () => {
                setLoadingState('loading')

                try {
                  const queryInstruction = clone(datasetInstruction)
                  queryInstruction.filter.aggregate = 'latest'
                  const datasets = await fetchDatasetsFromDbS([queryInstruction])

                  const updatedInstruction = clone(datasetInstruction)
                  updatedInstruction.contract.id_renaming = datasets.reduce((acc, dataset) => {
                    return {
                      ...acc,
                      [dataset.return_id]: dataset.return_id,
                    }
                  }, {})
                  setDatasetInstruction(updatedInstruction)

                  setLoadingState('success')
                } catch (err: any) {
                  setLoadingState('error')
                }
              }}
            >{t('Fetch datasets & populate id_renaming')}</Button>
          </Grid>
        </Grid>
      )}

      {/* Calc */}
      {datasetInstruction.type === `calc` && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              freeSolo
              autoSelect
              options={editingUiConfigSnap.editingUiConfig.dataset_instructions
                .map((datasetInstruction) => {
                  const returnIds = getReturnIdsFromDatasetInstruction(datasetInstruction)

                  return returnIds.map((returnId) => ({
                    value: returnId,
                    index: returnId,
                  }))
                })
                .flat()}
              getOptionLabel={(option) => option.value ?? option}
              onChange={(e, newValue) => {
                const variables = newValue.map((value) => {
                  if (Number(value)) {
                    return Number(value)
                  }
                  return value.value
                })
                setDatasetInstruction({
                  ...datasetInstruction,
                  contract: { ...contractCalc, variables },
                })
              }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  error={(contractCalc.variables?.length ?? 0) < 2}
                  fullWidth
                  aria-label={`Variabler`}
                  label={t(`Variables`)}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Toggle
              label={t(`Element wise`)}
              value={contractCalc.element_wise === true}
              onClick={(element_wise) =>
                setDatasetInstruction({
                  ...datasetInstruction,
                  contract: { ...contractCalc, element_wise },
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              label={t(`Operator`)}
              value={contractCalc.operator ?? `+`}
              onChange={(operator) =>
                setDatasetInstruction({
                  ...datasetInstruction,
                  contract: { ...contractCalc, operator: operator as `+` | `-` | `/` | `*` | `min` | `max` | `if` },
                })
              }
              items={[
                { value: `+`, label: `+` },
                { value: `-`, label: `-` },
                { value: `/`, label: `/` },
                { value: `*`, label: `*` },
                { value: `min`, label: `min` },
                { value: `max`, label: `max` },
                { value: `if`, label: `if` },
                { value: `ifnot`, label: `ifnot` },
              ]}
            />
          </Grid>
        </Grid>
      )}

      <h3>{t(`Filter`)}</h3>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            label={t(`Aggregate method`)}
            value={datasetInstruction.filter.aggregate}
            onChange={(aggregate) =>
              setDatasetInstruction({
                ...datasetInstruction,
                filter: { ...datasetInstruction.filter, aggregate: aggregate as DatasetInstructionAggregate },
              })
            }
            items={[
              { value: ``, label: t(`None`) },
              { value: `sum`, label: t(`Sum`) },
              { value: `mean`, label: t(`Mean`) },
              { value: `min`, label: t(`Min`) },
              { value: `max`, label: t(`Max`) },
              { value: `latest`, label: t(`Latest`) },
            ]}
          />
        </Grid>
      </Grid>

      <br />

      <Inline spaceBetween>
        <Button primary onClick={onSubmit}>
          {t(`Add dataset`)}
        </Button>

        {new Set(['calc']).has(datasetInstruction.type) && (
          <Grid item xs={4}>
            <TextField
              variant="standard"
              error={!datasetInstruction.return_id}
              label={t(`Return ID`)}
              value={datasetInstruction.return_id}
              onChange={(return_id) => setDatasetInstruction({ ...datasetInstruction, return_id })}
            />
          </Grid>
        )}
      </Inline>

      <br />
      <br />
    </div>
  )
}
