import React, { ReactElement } from 'react'

import Icon from 'ui/atoms/Icon/Icon'
import Datetime from 'utils/datetime/datetime'

import i18n from 'i18next'

import styles from './NotificationCenter.module.less'

export type SubtypeObject = {
  name: string
  display_name: string
}

export type NotificationType = {
  system_id: number
  id: number
  type: 'optimization' | 'setting' | 'admin'
  priority: number
  creator_name: string
  route?: string
  created_at: string
  updated_by: string
  description: string
  updated_at: string
  meta?: {
    opt_job_type?:
      | 'regular'
      | 'sandbox'
      | 'measvalues_calculations'
      | 'followup_without_deviations'
      | 'followup_with_deviations'
    setting_attribute?: 'availability' | 'forced'
    setting_type?: 'deviation' | 'base'
    comment?: string
    start_time?: string
    end_time?: string
    status?: 'finished' | 'failed' | 'running' | 'queued' | 'unsolvable' | 'created'
    subtypes?: SubtypeObject[]
  }
}

export function getTime(time: ISODateTime): ReactElement {
  let newTime: ISODateTime = time
  const today = Datetime.getTodayDate()
  if (Datetime.isSameDate(time as ISODateTime, today.startTime)) {
    newTime = Datetime.toLocalTime(time as ISODateTime, 'hour')
  } else if (Datetime.isAfter(time as ISODateTime, Datetime.getYearToDate().startTime)) {
    newTime = Datetime.toLocalTime(time as ISODateTime, 'longDayTextWithoutYear')
  } else {
    newTime = Datetime.toLocalTime(time as ISODateTime, 'longDayText')
  }

  return <div className={styles.NotificationCenter_NotificationItemContainer_Time}>{newTime}</div>

}

export function getNotificationItem(
  item: NotificationType,
  onLinkClicked: (route: string) => void
): ReactElement | undefined {

  const createdBy = `${i18n.t('Created by')} ${item.creator_name}`

  if (item.type === 'admin') {
    return (
      <div className={styles.NotificationCenter_NotificationItemContainer}>
        <div
          className={styles.NotificationCenter_NotificationItemContainer_Description__italic}
        >{`"${item.description}"`}</div>
        <div className={styles.NotificationCenter_NotificationItemContainer_Description}>{createdBy}</div>
      </div>
    )
  } else if (item.type === 'optimization') {
    let statusIcon = <Icon icon="far fa-spinner-third fa-spin" color="gray" large />
    let optimizationDescription = ''
    const status = item.meta?.status

    const hasSubtype = item.meta?.subtypes
    const subtype = hasSubtype ? hasSubtype.map((subtype: SubtypeObject) => subtype.display_name).join(', ') : ''

    if (item.meta?.opt_job_type === 'regular') {
      const typeOfRegular = item.creator_name ? i18n.t('manual') : i18n.t('automatic')
      if (status === 'running') {
        if (hasSubtype) {
          optimizationDescription = item.creator_name
            ? i18n.t(
              'A {{typeOfRegular}} optimization for {{subtype}} created by {{userName}} has been created and is now running.',
              { typeOfRegular: typeOfRegular, subtype: subtype, userName: item.creator_name }
            )
            : i18n.t('A {{typeOfRegular}} optimization for {{subtype}} has been created and is now running.', {
              typeOfRegular: typeOfRegular,
              subtype: subtype,
            })
        } else {
          optimizationDescription = item.creator_name
            ? i18n.t('A {{typeOfRegular}} optimization created by {{userName}} has been created and is now running.', {
              typeOfRegular: typeOfRegular,
              userName: item.creator_name,
            })
            : i18n.t('A {{typeOfRegular}} optimization has been created and is now running.', {
              typeOfRegular: typeOfRegular,
            })
        }
      } else if (status === 'queued') {
        if (hasSubtype) {
          optimizationDescription = item.creator_name
            ? i18n.t('A {{typeOfRegular}} optimization for {{subtype}} created by {{userName}} has been queued.', {
              typeOfRegular: typeOfRegular,
              subtype: subtype,
              userName: item.creator_name,
            })
            : i18n.t('A {{typeOfRegular}} optimization for {{subtype}} has been queued.', {
              typeOfRegular: typeOfRegular,
              subtype: subtype,
            })
        } else {
          optimizationDescription = item.creator_name
            ? i18n.t('A {{typeOfRegular}} optimization created by {{userName}} has been queued.', {
              typeOfRegular: typeOfRegular,
              userName: item.creator_name,
            })
            : i18n.t('A {{typeOfRegular}} optimization has been queued.', { typeOfRegular: typeOfRegular })
        }
      } else if (status === 'created') {
        if (hasSubtype) {
          optimizationDescription = item.creator_name
            ? i18n.t('A {{typeOfRegular}} optimization for {{subtype}} created by {{userName}} has been created.', {
              typeOfRegular: typeOfRegular,
              subtype: subtype,
              userName: item.creator_name,
            })
            : i18n.t('A {{typeOfRegular}} optimization for {{subtype}} has been created.', {
              typeOfRegular: typeOfRegular,
              subtype: subtype,
            })
        } else {
          optimizationDescription = item.creator_name
            ? i18n.t('A {{typeOfRegular}} optimization created by {{userName}} has been created.', {
              typeOfRegular: typeOfRegular,
              userName: item.creator_name,
            })
            : i18n.t('A {{typeOfRegular}} optimization has been created.', { typeOfRegular: typeOfRegular })
        }
      } else if (status === 'unsolvable') {
        if (hasSubtype) {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization for {{subtype}} is unsolvable.', {
            typeOfRegular: typeOfRegular,
            subtype: subtype,
          })
        } else {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization is unsolvable.', {
            typeOfRegular: typeOfRegular,
          })
          statusIcon = <Icon icon="far fa-times-circle" color="orange" large />
        }
      } else if (status === 'failed') {
        statusIcon = <Icon icon="fad fa-times-circle" color="red" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization for {{subtype}} has failed.', {
            typeOfRegular: typeOfRegular,
            subtype: subtype,
          })
        } else {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization has failed.', {
            typeOfRegular: typeOfRegular,
          })
        }
      } else if (status === 'finished') {
        statusIcon = <Icon icon="fad fa-check-circle" color="green" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization for {{subtype}} has been completed.', {
            typeOfRegular: typeOfRegular,
            subtype: subtype,
          })
        } else {
          optimizationDescription = i18n.t('A {{typeOfRegular}} optimization has been completed.', {
            typeOfRegular: typeOfRegular,
          })
        }
      }

      return (
        <div className={styles.NotificationCenter_NotificationItemContainer}>
          <div className={styles.NotificationCenter_NotificationItemContainer_Icon}>{statusIcon}</div>
          <div className={styles.NotificationCenter_NotificationItemContainer_Description}>
            {optimizationDescription}
          </div>
          {item.creator_name && status != 'running' && (
            <div className={styles.NotificationCenter_NotificationItemContainer_Description__italic}>{createdBy}</div>
          )}
          {status === 'failed' || status === 'finished' ? (
            <a
              className={styles.NotificationCenter_NotificationItemContainer_Link}
              onClick={() => onLinkClicked('/optimize')}
            >
              {i18n.t('Go to the production plan')}
            </a>
          ) : (
            <></>
          )}
        </div>
      )
    }

    if (
      item.meta?.opt_job_type === 'followup_with_deviations' ||
      item.meta?.opt_job_type === 'followup_without_deviations'
    ) {
      const period =
        item.meta?.start_time && item.meta?.end_time
          ? `${Datetime.toLocalTime(item.meta?.start_time as ISODateTime)} till ${Datetime.toLocalTime(item.meta?.end_time as ISODateTime)}`
          : ''
      const followUpType =
        item.meta?.opt_job_type === 'followup_with_deviations'
          ? i18n.t('(incl. production deviations)')
          : i18n.t('(optimal production)')
      if (status === 'running') {
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} created by {{userName}} has been created and is now running.',
            { followUpType: followUpType, followUpPeriod: period, subtype: subtype, userName: item.creator_name }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} created by {{userName}} has been created and is now running.',
            { followUpType: followUpType, followUpPeriod: period, userName: item.creator_name }
          )
        }
      } else if (status === 'queued') {
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} created by {{userName}} has been queued.',
            { followUpType: followUpType, subtype: subtype, followUpPeriod: period, userName: item.creator_name }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} created by {{userName}} has been queued.',
            { followUpType: followUpType, followUpPeriod: period, userName: item.creator_name }
          )
        }
      } else if (status === 'created') {
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} created by {{userName}} has been created.',
            { followUpType: followUpType, subtype: subtype, followUpPeriod: period, userName: item.creator_name }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} created by {{userName}} has been created.',
            { followUpType: followUpType, followUpPeriod: period, userName: item.creator_name }
          )
        }
      } else if (status === 'unsolvable') {
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} is unsolvable.',
            { followUpType: followUpType, subtype: subtype, followUpPeriod: period }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} is unsolvable.',
            { followUpType: followUpType, followUpPeriod: period }
          )
        }
        statusIcon = <Icon icon="far fa-times-circle" color="orange" large />
      } else if (status === 'failed') {
        statusIcon = <Icon icon="fad fa-times-circle" color="red" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} has failed.',
            { followUpType: followUpType, followUpPeriod: period, subtype: subtype }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} has failed.',
            { followUpType: followUpType, followUpPeriod: period }
          )
        }
      } else if (status === 'finished') {
        statusIcon = <Icon icon="fad fa-check-circle" color="green" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} for {{subtype}} with the period {{followUpPeriod}} has been completed.',
            { followUpType: followUpType, followUpPeriod: period, subtype: subtype }
          )
        } else {
          optimizationDescription = i18n.t(
            'A follow-up optimization {{followUpType}} with the period {{followUpPeriod}} has been completed.',
            { followUpType: followUpType, followUpPeriod: period }
          )
        }
      }

      let link = ''
      let startTimeDate = ''
      let endTimeDate = ''
      if (item.meta.start_time && item.meta.end_time) {
        startTimeDate = item.meta.start_time.split('T')[0]
        endTimeDate = item.meta.end_time.split('T')[0]
        link = `/followupproduction?startTime=${startTimeDate}&endTime=${endTimeDate}`
      }

      return (
        <div className={styles.NotificationCenter_NotificationItemContainer}>
          <div className={styles.NotificationCenter_NotificationItemContainer_Icon}>{statusIcon}</div>
          <div className={styles.NotificationCenter_NotificationItemContainer_Description}>
            {optimizationDescription}
          </div>
          {item.creator_name && status != 'running' && (
            <div className={styles.NotificationCenter_NotificationItemContainer_Description__italic}>{createdBy}</div>
          )}
          {status === 'failed' || status === 'finished' ? (

            <a
              className={styles.NotificationCenter_NotificationItemContainer_Link}
              onClick={() => onLinkClicked(link)}
            >
              {i18n.t('Go to the follow-up module')}
            </a>

          ) : (
            <></>
          )}
        </div>
      )
    }

    if (item.meta?.opt_job_type === 'sandbox') {
      if (status === 'created' || status === 'running' || status === 'queued') {
        optimizationDescription = i18n.t('A sandbox optimization has been created.')
      } else if (status === 'failed' || status === 'unsolvable') {
        statusIcon = <Icon icon="fad fa-times-circle" color="red" large />
        optimizationDescription = i18n.t('A sandbox optimization has failed.')
      } else if (status === 'finished') {
        statusIcon = <Icon icon="fad fa-check-circle" color="green" large />
        optimizationDescription = i18n.t('A sandbox optimiziation has finished')
      }

      return (
        <div className={styles.NotificationCenter_NotificationItemContainer}>
          <div className={styles.NotificationCenter_NotificationItemContainer_Icon}>{statusIcon}</div>
          <div className={styles.NotificationCenter_NotificationItemContainer_Description}>
            {optimizationDescription}
          </div>
          {item.creator_name && status != 'running' && (
            <div className={styles.NotificationCenter_NotificationItemContainer_Description__italic}>{createdBy}</div>
          )}
          {
            <a
              className={styles.NotificationCenter_NotificationItemContainer_Link}
              onClick={() => onLinkClicked('/sandbox')}
            >
              {i18n.t('Go to the sandbox module')}
            </a>
          }
        </div>
      )
    }

    if (item.meta?.opt_job_type === 'measvalues_calculations') {
      const period =
        item.meta?.start_time && item.meta?.end_time
          ? `${Datetime.toLocalTime(item.meta?.start_time as ISODateTime)} till ${Datetime.toLocalTime(item.meta?.end_time as ISODateTime)}`
          : ''
      if (status === 'created' || status === 'running' || status === 'queued') {
        optimizationDescription = i18n.t('A meas values optimization has been created.')
      } else if (status === 'failed' || status === 'unsolvable') {
        statusIcon = <Icon icon="fad fa-times-circle" color="red" large />
        optimizationDescription = i18n.t('A meas values optimization with the period {{measPeriod}} has failed.', {
          measPeriod: period,
        })
      } else if (status === 'finished') {
        statusIcon = <Icon icon="fad fa-check-circle" color="green" large />
        optimizationDescription = i18n.t(
          'A meas values optimization with the period {{measPeriod}} has been completed.',
          { measPeriod: period }
        )
      }

      let link = ''
      if (item.meta.start_time && item.meta.end_time) {
        const startTimeDate = item.meta.start_time.split('T')[0]
        const endTimeDate = item.meta.end_time.split('T')[0]
        link = `/followupproduction?startTime=${startTimeDate}&endTime=${endTimeDate}`
      }

      return (
        <div className={styles.NotificationCenter_NotificationItemContainer}>
          <div className={styles.NotificationCenter_NotificationItemContainer_Icon}>{statusIcon}</div>
          <div className={styles.NotificationCenter_NotificationItemContainer_Description}>
            {optimizationDescription}
          </div>
          {item.creator_name && status != 'running' && (
            <div className={styles.NotificationCenter_NotificationItemContainer_Description__italic}>{createdBy}</div>
          )}
          {
            <a className={styles.NotificationCenter_NotificationItemContainer_Link} onClick={() => onLinkClicked(link)}>
              {i18n.t('Go to the follow-up module')}
            </a>
          }
        </div>
      )
    }

    if (item.meta?.opt_job_type === 'fuel_plan') {
      const period =
        item.meta?.start_time && item.meta?.end_time
          ? `${Datetime.toLocalTime(item.meta?.start_time as ISODateTime)} till ${Datetime.toLocalTime(item.meta?.end_time as ISODateTime)}`
          : ''
      if (status === 'created' || status === 'running' || status === 'queued') {
        if (hasSubtype) {
          optimizationDescription = i18n.t('A fuel plan optimization for {{subtype}} has been created.', {
            subtype: subtype,
          })
        } else {
          optimizationDescription = i18n.t('A fuel plan optimization has been created.')
        }
      } else if (status === 'failed' || status === 'unsolvable') {
        statusIcon = <Icon icon="fad fa-times-circle" color="red" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A fuel plan optimization for {{subtype}} with the period {{fuelPeriod}} has failed.',
            { subtype: subtype, fuelPeriod: period }
          )
        } else {
          optimizationDescription = i18n.t('A fuel plan optimization with the period {{fuelPeriod}} has failed.', {
            fuelPeriod: period,
          })
        }
      } else if (status === 'finished') {
        statusIcon = <Icon icon="fad fa-check-circle" color="green" large />
        if (hasSubtype) {
          optimizationDescription = i18n.t(
            'A fuel plan optimization for {{subtype}} with the period {{fuelPeriod}} has been completed.',
            { subtype: subtype, fuelPeriod: period }
          )
        } else {
          optimizationDescription = i18n.t(
            'A fuel plan optimization with the period {{fuelPeriod}} has been completed.',
            { fuelPeriod: period }
          )
        }
      }

      return (
        <div className={styles.NotificationCenter_NotificationItemContainer}>
          <div className={styles.NotificationCenter_NotificationItemContainer_Icon}>{statusIcon}</div>
          <div className={styles.NotificationCenter_NotificationItemContainer_Description}>
            {optimizationDescription}
          </div>
          {item.creator_name && status != 'running' && (
            <div className={styles.NotificationCenter_NotificationItemContainer_Description__italic}>{createdBy}</div>
          )}
          {status === 'failed' || status === 'finished' ? (
            <a
              className={styles.NotificationCenter_NotificationItemContainer_Link}
              onClick={() => onLinkClicked('/fuelplan')}
            >
              {i18n.t('Go to fuel plan')}
            </a>
          ) : (
            <></>
          )}
        </div>
      )
    }
  }
}
