import React, { ReactElement } from 'react'

import { DigitalTwinTranslations } from 'api/digitalTwin/digitalTwin.api'
import { Button } from 'ui/atoms'
import Icon from 'ui/atoms/Icon/Icon'

import { useTranslation } from 'react-i18next'

import Tooltip from '../Tooltip/Tooltip'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './DigitalTwinAccumulatorLevels.module.less'

export type AccumulatorLevelAttribute = 'start_level' | 'end_level'

type SingleDigitalTwinAccumulatorLevelProps = {
  value: number
  accumulatorName: string
  hasEditPermission: boolean
  attribute: AccumulatorLevelAttribute
  onEditClicked?: (unitName: string, attribute: AccumulatorLevelAttribute) => void
  digitalTwinTranslations?: DigitalTwinTranslations
  warnings: string[]
}

export function SingleDigitalTwinAccumulatorLevel({
  accumulatorName,
  value,
  attribute,
  hasEditPermission,
  onEditClicked,
  digitalTwinTranslations,
  warnings,
}: SingleDigitalTwinAccumulatorLevelProps): ReactElement {
  const { t } = useTranslation()

  const propertyName = attribute === 'start_level' ? t('Start level') : t('End level')
  const unitName = getPrettyName(accumulatorName, digitalTwinTranslations)

  return (
    <div>
      {/* The root div is necessary for receiving styling from parent element. If margin is added to the tooltip, nothing happens. */}
      <Tooltip
        title={
          // `warnings.length > 0` check makes sure that an empty tooltip is not shown when there are no warnings.
          warnings.length > 0 && warnings.map((warning) => <div key={warning}>{warning}</div>)
        }
        arrow
      >
        <div className={styles.DigitalTwinAccumulatorLevels_SingleAccumulatorContainer}>
          {warnings.length > 0 && (
            <div className={styles.DigitalTwinAccumulatorLevels_WarningIcon}>
              <Icon icon="fas fa-exclamation-circle" size="medium" color="yellow" />
            </div>
          )}
          <div>
            <div className={styles.DigitalTwinAccumulatorLevels_PropertyName}>{`${propertyName} ${unitName}`}</div>
            <div className={styles.DigitalTwinAccumulatorLevels_Value}>{value.toFixed(2)}</div>
          </div>
          {onEditClicked !== undefined && (
            <div className={styles.DigitalTwinAccumulatorLevels_EditButton}>
              <Button
                onClick={() => {
                  onEditClicked(accumulatorName, attribute)
                }}
                marginLeft
                link
                noPadding
                icon="fas fa-edit"
                disabled={!hasEditPermission}
                disabledTooltip={t('Unfortunately, you do not have permission to make changes here')}
              />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  )
}
