import React, { ReactElement, useState } from 'react'

import { useUnitAvailabilityPlannedStop } from 'api/unitAvailabilityPlannedStop/unitAvailabilityPlannedStop.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'

import { Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PlannedStopModal from '../PlannedStopModal'
import PlannedStopSettingItem from '../PlannedStopSettingItem/PlannedStopSettingItem'
import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'
import { useHasPermission } from 'helpers/global.helper/global.helper'

import styles from './PlannedStopSettings.module.less'

type PlannedStopSettingsProps = {
  period: Period
  unitName: string
  onRefetch?: () => void
  groupId?: number
}

export default function PlannedStopSettings({ period, unitName, onRefetch }: PlannedStopSettingsProps): ReactElement {
  const [open, setOpen] = useState(false)
  const { activeSystem } = useAuth()
  const { data: plannedStopData } = useUnitAvailabilityPlannedStop(activeSystem?.id, unitName, period)
  const { t } = useTranslation()

  const hasPlannedStopChangeAccess = useHasPermission('create_planned_stop')

  return (
    <Grid item container direction="column" style={{ width: '95%' }}>
      <Grid item container direction="row" justifyContent="flex-start">
        <Grid item xs={6}>
          <Typography variant="h1" display="inline" style={{ fontWeight: 'bold' }}>
            {t('Events')}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ maxHeight: 300 }}>
          <Typography variant="h1" display="inline" style={{ fontWeight: 'bold' }}>
            {t('Period')}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid item>
        {plannedStopData && plannedStopData.length > 0 ? (
          plannedStopData.map((item) => {
            return (
              <PlannedStopSettingItem
                hasChangePermission={hasPlannedStopChangeAccess}
                event={item}
                key={item.id}
                unitName={unitName}
                onRefetch={onRefetch}
              />
            )
          })
        ) : (
          <div className={styles.PlannedStopSettings_NoSettingDiv}>
            {t('There are no planned unavailabilities for the period: ') +
              formatDateTimeToLocalizedString(period.startTime.format(), true) +
              ' - ' +
              formatDateTimeToLocalizedString(period.endTime.format(), true)}
          </div>
        )}
      </Grid>
      <Divider />
      {hasPlannedStopChangeAccess ? (
        <ContainedIconButton
          color="primary"
          icon={'fal fa-plus'}
          label={t('Add')}
          style={{ marginTop: 20, maxWidth: 130 }}
          onClick={() => setOpen(true)}
        />
      ) : null}
      <PlannedStopModal
        hasChangePermission={hasPlannedStopChangeAccess}
        open={open}
        onClose={setOpen}
        unitName={unitName}
        period={period}
        onRefetch={onRefetch}
      />
    </Grid>
  )
}
