import React, { ReactElement } from 'react'

import globalStore from 'store/global/global'
import queueStore from 'store/queue/queue'
import { dynamicClassName } from 'styles/helper'
import { ProgressBar } from 'ui/atoms'
import { ProgressBarItem } from 'ui/atoms/ProgressBar/ProgressBar'

import { useSnapshot } from 'valtio'

import styles from './QueueProgressBar.module.less'

export default function QueueProgressBar(): ReactElement {
  const queueSnap = useSnapshot(queueStore)
  const globalSnap = useSnapshot(globalStore)
  const show = queueSnap.showQueue
  const navIsOpen = globalSnap.navigationIsOpen

  if (!show) {
    return <></>
  }

  const items: ProgressBarItem[] = [
    {
      title: `Done`,
      value: queueSnap.successfulMessages,
      backgroundColor: `#43a047`,
      color: `#17501b`,
    },
    {
      title: `Error`,
      value: queueSnap.errorMessages,
      backgroundColor: `#f35e5e`,
      color: `#7c0f14`,
    },
  ]

  return (
    <div
      className={dynamicClassName({
        animation: true,
        [styles.QueueProgressBar]: true,
        [styles.QueueProgressBar__closedNavbar]: !navIsOpen,
      })}
    >
      <ProgressBar items={items} total={queueSnap.originalQueueLength} showText={false} />
    </div>
  )
}
