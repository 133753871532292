import React, { ReactElement } from 'react'

import { Card, HorizontalBar, WarningTriangle } from 'ui/atoms'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { useTranslation } from 'react-i18next'

import { getTypeObject } from 'helpers/global.helper/global.helper'

import styles from './RealTimeCard.module.less'

export type RealTimeCardData = {
  title: string
  id: string
  optimalId: string
  outcomeId: string
  optimalIndex: number
  outcomeIndex: number
  optimalValue: number
  outcomeValue: number
  optimalAge: number
  outcomeAge: number
  isPeakUnit: boolean
  highlightValue: boolean
  age: number
  unit?: string
  dataType?: string
  color: string
}

type RealTimeCardProps = {
  item: RealTimeCardData
  key: string
  cardWidth: string
  maxValue: number
  showWarningAtAgeInMinute: number
}

export default function RealTimeCard({ item, key, cardWidth, maxValue, showWarningAtAgeInMinute }: RealTimeCardProps): ReactElement {
  const { t } = useTranslation()

  return (<Card key={key} width={cardWidth}>
    <div
      className={styles.RealTimeCard_BarWrapper}
    >
      <div className={styles.RealTimeCard_BarWrapper_Title}>{t('Optimal')}</div>

      <HorizontalBar
        value={getTypeObject(item.optimalValue, item.dataType, item.unit, 2).value}
        width={`${100 * item.optimalValue / maxValue}%`}
        color={item.color}
        height={30}
      />

      <div className={styles.RealTimeCard_BarWrapper_Title}>{t('Outcome')}</div>
      <HorizontalBar
        value={getTypeObject(item.outcomeValue, item.dataType, item.unit, 2).value}
        width={`${100 * item.outcomeValue / maxValue}%`}
        color={item.color} transparent={true}
        height={30}
        danger={item.highlightValue}
      />
    </div>
    <div className={styles.RealTimeCard_TextWrapper}>
      <div className={styles.RealTimeCard_TextWrapper_UnitAndTriangleWrapper}>
        <div className={styles.RealTimeCard_TextWrapper_UnitAndTriangleWrapper_Unit}>
          {getTypeObject(item.optimalValue, item.dataType, item.unit, 2).unit}
        </div>
        <div className={styles.RealTimeCard_TextWrapper_UnitAndTriangleWrapper_WarningTriangle}>
          <WarningTriangle visible={item.age > showWarningAtAgeInMinute} tooltip={`${t('Time since last update')}: ${item.age} ${t('min')}`} />
        </div>
      </div>
      <Tooltip title={item.title} arrow display='block'>
        <div className={styles.RealTimeCard_TextWrapper_Title}>
          {item.title}
        </div>
      </Tooltip>
    </div>
  </Card>)
}
