import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'
import { DatePickerPeriod } from 'ui/molecules/pickers/DatePicker/DatePickerPeriod'
import { toDateValue } from 'ui/molecules/pickers/DatePicker/utils/dateAdapter'
import { useFormatErrorMessage } from 'ui/molecules/pickers/internal/DatePicker/utils/useFormatErrorMessage'
import { validateRange } from 'ui/molecules/pickers/internal/DatePicker/utils/validation'

import { FormControl } from '@mui/material'
import moment, { Moment } from 'moment'
import { useController, useFormContext } from 'react-hook-form'

import styles from './SettingDateTimePicker.module.less'

export type DateTimePickerRange = {
  start?: ISODateTime | Moment | null
  end?: ISODateTime | Moment | null
}
type SettingDateTimePickerProps = {
  startDateControllerName?: string
  endDateControllerName?: string
  allowEmpty?: boolean
  'data-testid'?: string
} & Omit<NonFormSettingDateTimePickerProps, 'value' | 'setDates'>

export function SettingDateTimePicker({
  startDateControllerName = 'start_time',
  endDateControllerName = 'end_time',
  allowEmpty,
  ...props
}: SettingDateTimePickerProps): ReactElement {
  const { control } = useFormContext()
  const { format } = useFormatErrorMessage()

  const handleValidation = (_: unknown, formVal: {
    [controllerNanme: string]: ISODateTime | Moment | null
  }): boolean | string => {
    const rangeValidation = validateRange({
      startDate: toDateValue(formVal[startDateControllerName]),
      endDate: toDateValue(formVal[endDateControllerName]),
      minValue: toDateValue(props.minValue),
      maxValue: toDateValue(props.maxValue),
      allowEmptyEndDate: allowEmpty,
    })

    if (!rangeValidation.isValid) {
      return format(rangeValidation.error)
    }

    return true
  }

  const { field: startField } = useController({ name: startDateControllerName, control, rules: { required: true, validate: handleValidation } })
  const { field: endField } = useController({ name: endDateControllerName, control, rules: { required: !allowEmpty, validate: handleValidation } })

  return (
    <FormControl style={{ width: `100%` }}>
      <NonFormSettingDateTimePicker
        value={{
          start: startField.value,
          end: endField.value,
        }}
        setDates={(startTime, endTime) => {
          startField.onChange(startTime)
          endField.onChange(endTime)
        }}
        allowEmptyEndDate={allowEmpty}
        {...props}
      />
    </FormControl>
  )
}

type BackwardsCompatibleDatePickerPeriodProps = React.ComponentPropsWithoutRef<typeof DatePickerPeriod>

type NonFormSettingDateTimePickerProps = {
  value?: DateTimePickerRange
  setDates?: (startTime: ISODateTime | null | undefined, endTime: ISODateTime | null | undefined) => void
  minValue?: ISODateTime | Moment | null
  maxValue?: ISODateTime | Moment | null
  isDisabled?: boolean
} & Omit<BackwardsCompatibleDatePickerPeriodProps, 'startDate' | 'endDate' | 'minDate' | 'maxDate' | 'onSelect'>

// For use in (value, onChange)-style components, that don't have a form context.
export function NonFormSettingDateTimePicker({
  value = {},
  setDates,
  minValue,
  maxValue,
  isDisabled = false,
  ...restProps
}: NonFormSettingDateTimePickerProps): ReactElement {
  return (
    <div className={dynamicClassName({
      [styles.NonFormSettingDateTimePicker_ContainerDisabled]: isDisabled,
    })}>
      <DatePickerPeriod
        granularity="minute"
        minDate={minValue}
        maxDate={maxValue}
        isDisabled={isDisabled}
        startDate={value.start !== undefined ? moment(value.start) : moment()}
        endDate={value.end !== undefined ? moment(value.end) : moment().hour(23).minutes(59)}
        onSelect={(startTime, endTime) => setDates && setDates(startTime, endTime)}
        {...restProps}
      />
    </div>
  )
}
