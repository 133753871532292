import React, { ReactElement } from 'react'

import { LoadingPlaceholderContainer } from 'ui/atoms'

import { Icon, Typography, Grid } from '@mui/material'
import type { NonUndefined } from 'react-hook-form/dist/types/utils'
import { useTranslation } from 'react-i18next'
import { QueryStatus } from 'react-query'

type DataType = undefined | unknown
type DataTypeObject<T extends Record<string, DataType>> = {
  [K in keyof T]: T[K]
}

type NonUndefinedDataTypeObject<T extends Record<string, DataType>> = {
  [K in keyof T]: NonUndefined<T[K]>
}

interface StatusComponentProps<
  Rest extends Record<string, unknown>,
  Data extends Record<string, unknown>,
  Comp extends React.ComponentType<Rest & NonUndefinedDataTypeObject<Data> & { isFetching?: boolean }>,
> {
  data: DataTypeObject<Data>
  status: QueryStatus | QueryStatus[]
  LoadingFallback?: JSX.Element
  ErrorFallback?: JSX.Element
  Component: Comp
  ComponentProps?: Rest
  isFetching?: boolean
}

export default function StatusComponent<
  Rest extends Record<string, unknown>,
  Data extends Record<string, unknown>,
  Comp extends React.ComponentType<Rest & NonUndefinedDataTypeObject<Data>>,
>({
  status,
  data,
  LoadingFallback,
  ErrorFallback,
  Component,
  ComponentProps,
  isFetching,
}: StatusComponentProps<Rest, Data, Comp>): ReactElement {
  const { t } = useTranslation()

  if (Object.keys(data).every((key) => data[key] !== undefined)) {
    return (
      <Component
        {...(data as NonUndefinedDataTypeObject<Data>)}
        {...(ComponentProps as never)}
        isFetching={isFetching}
      />
    )
  } else if (
    (Array.isArray(status) && status.some((status: QueryStatus) => status === `error`)) ||
    status === `error`
  ) {
    return (
      ErrorFallback ?? (
        <Grid container alignItems="center" justifyContent="center">
          <Icon className={`fal fa-exclamation-triangle`} style={{ color: `red` }} />
          <Typography variant="h4">{t(`Content failed to load`)}</Typography>
        </Grid>
      )
    )
  } else if (
    (Array.isArray(status) &&
      status.some((status: QueryStatus) => status === `loading` || status === `idle` || status === `success`)) ||
    status === `loading` ||
    status === `idle` ||
    status === `success`
  ) {
    // status can be success even when data is undefined in a few cases where it should still be loading
    return LoadingFallback ?? <LoadingPlaceholderContainer />
  }
  throw Error(`Status component got unrecognized status`)
}
