import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDigitalTwin } from 'api/digitalTwin/digitalTwin.api'
import {
  LogBookPost,
  useDeleteLogBookPostMutation,
  useGetLogBookCategories,
  useLogBookPostCreate,
  useUpdateLogBookPostMutation,
} from 'api/logBook/logBook.api'
import alertStore from 'store/alert/alert'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import { Dialog } from 'ui/components/Dialog/Dialog'
import Dropdown from 'ui/components/Dropdown/Dropdown'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import Datetime from 'utils/datetime/datetime'

import { Grid, Typography, Icon, DialogContent, Checkbox, TextField, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './LogBookPostModal.module.less'

type CreateModalProps = {
  open: boolean
  handleClose: () => void
  edit: boolean
  log_post?: LogBookPost | null
}

function CreateLogBookPostModal({ open, handleClose, edit, log_post }: CreateModalProps): ReactElement {
  const { systemId, user, activeSystem } = useAuth()
  const ref = useRef(null)
  const { mutateAsync: createLogBookPost } = useLogBookPostCreate()
  const { mutateAsync: updateLogBookPost } = useUpdateLogBookPostMutation()
  const { mutateAsync: deleteLogBookPost } = useDeleteLogBookPostMutation()
  const primaryDigitalTwinUid = activeSystem?.primary_digital_twin?.uid
  const { data: digitalTwin } = useDigitalTwin(primaryDigitalTwinUid, true)
  const { t } = useTranslation()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDeletePost, setConfirmDeletePost] = useState(false)
  const [pinned, setPinned] = useState(false)
  const [reported, setReported] = useState(false)

  const initialLogBookPostState = useMemo(
    (): LogBookPost => ({
      id: '',
      created_by: '',
      created_at: '',
      updated_at: '',
      updated_by: '',
      is_pinned: false,
      is_reported: false,
      category: undefined,
      content: '',
      written_by: '',
      system_id: systemId,
    }),
    [systemId]
  )
  const [logBookPost, setLogBookPost] = useState<LogBookPost>(initialLogBookPostState)

  function handleDeletePost(): void {
    setConfirmDeletePost(true)
  }

  const resetLogBookPost = useCallback((): void => {
    setLogBookPost(initialLogBookPostState)
    setPinned(false)
    setReported(false)
  }, [initialLogBookPostState])

  useEffect(() => {
    if (edit && log_post) {
      setLogBookPost(log_post)
      setPinned(log_post.is_pinned)
      setReported(log_post.is_reported)
    } else {
      resetLogBookPost()
    }
  }, [edit, log_post, resetLogBookPost])

  const onPinClick = (): void => {
    setPinned((prevPinned) => {
      const newPinned = !prevPinned
      setLogBookPost({
        ...logBookPost,
        is_pinned: newPinned,
      })
      return newPinned
    })
  }

  const onReportedClick = (): void => {
    setReported((prevReported) => {
      const newReported = !prevReported
      setLogBookPost({
        ...logBookPost,
        is_reported: newReported,
      })
      return newReported
    })
  }

  const editPost = useCallback(
    (data: LogBookPost) => {
      try {
        updateLogBookPost(data)
        handleClose()
        alertStore.success(t(`Logbook entry saved`))
      } catch (e) {
        alertStore.error(t(`Failed to save logbook entry`))
      }
    },
    [handleClose, t, updateLogBookPost]
  )

  const createPost = useCallback(
    async (data: LogBookPost) => {
      try {
        await createLogBookPost({
          ...data,
          created_by: user?.first_name + ' ' + user?.last_name,
        })
        handleClose()
        alertStore.success(t(`Logbook entry submitted`))
        resetLogBookPost()
      } catch (e) {
        alertStore.error(t(`Failed to submit logbook entry`))
      }
    },
    [createLogBookPost, handleClose, resetLogBookPost, t, user?.first_name, user?.last_name]
  )

  const deletePost = useCallback(
    async (id: string) => {
      try {
        await deleteLogBookPost(id)
        alertStore.success(t(`Logbook entry deleted`))
        handleClose()
      } catch (e) {
        alertStore.error(t(`Failed to delete logbook entry`))
      }
    },
    [deleteLogBookPost, handleClose, t]
  )

  let writtenByInfoText = null
  if (edit) {
    writtenByInfoText = (
      <span className={styles.LogBookPostModal_WrittenByInfoText}>
        { t('Created by')} {logBookPost?.written_by} {logBookPost?.created_at && Datetime.toLocalTime(logBookPost?.created_at as ISODateTime)}
        { logBookPost?.updated_written_by && (
          <>
            , {t('Last edited by')} {logBookPost?.updated_written_by} {logBookPost?.updated_at && Datetime.toLocalTime(logBookPost?.updated_at as ISODateTime)}
          </>
        )}
      </span>
    )
  }

  const systemCategories = useGetLogBookCategories(systemId).data
  const categoryOptions = systemCategories
    ? systemCategories
      .map((category: string): string => {
        return getPrettyName(category, digitalTwin?.translations)
      })
      .map((category: string): { value: string; label: string } => {
        return {
          value: category,
          label: category,
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
    : [{ value: t('Other'), label: t('Other') }]

  const disablePublishButton =
    logBookPost.category === '' ||
    logBookPost.content === '' ||
    logBookPost.written_by === '' ||
    logBookPost.updated_written_by === null

  return (
    <>
      <div ref={ref}>
        <Dialog container={ref.current} open={open} fullWidth maxWidth="md">
          <CustomDialogTitle
            title={edit ? t('Edit logbook entry') : t('Create logbook entry')}
            info_text={writtenByInfoText}
            handleClose={() => setConfirmDialogOpen(true)}
          />
          <DialogContent style={{ padding: '15px' }}>
            <Grid container direction="column" spacing={2}>
              <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={1}>
                  <Icon className="fal fa-info-circle" />
                </Grid>
                <Grid item xs={10}>
                  <Dropdown
                    fullWidth
                    label={t(`Category`) + ' *'}
                    items={categoryOptions}
                    value={logBookPost?.category ?? ''}
                    handleChange={(e) => {
                      setLogBookPost({
                        ...logBookPost,
                        category: e.target.value as string,
                      })
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={1}>
                  <Icon className="fal fa-comment-alt-lines" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    required
                    fullWidth
                    name="content"
                    label={t(`Logbook comment`)}
                    type="text"
                    value={logBookPost?.content}
                    onChange={(e) => {
                      setLogBookPost({
                        ...logBookPost,
                        content: e.target.value as string,
                      })
                    }}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={1}>
                  <Icon className="fal fa-pen" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    required
                    fullWidth
                    name="written_by"
                    label={t(`Signed by`)}
                    type="text"
                    value={!edit ? logBookPost?.written_by : logBookPost?.updated_written_by}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      !edit
                        ? setLogBookPost({
                          ...logBookPost,
                          written_by: e.target.value as string,
                        })
                        : setLogBookPost({
                          ...logBookPost,
                          updated_written_by: e.target.value as string,
                        })
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <div className={styles.LogBookPostModal_ActionRow}>

              <div className={styles.LogBookPostModal_ActionRow_Checkbox}>
                <Checkbox
                  checked={reported}
                  onChange={() => onReportedClick()}
                  inputProps={{ 'aria-label': 'decorative checkbox' }}
                />
                <Typography>{t('Reported')}</Typography>
              </div>
              <div className={styles.LogBookPostModal_ActionRow_Checkbox}>
                <TooltipIconButton
                  icon={pinned ? 'fas fa-thumbtack' : 'fal fa-thumbtack'}
                  iconColor="#FFAE00"
                  iconSize="small"
                  onClick={onPinClick}
                  tooltip={'Pin'}
                />
                <Typography>{pinned ? t('Unpin as important message') : t('Pin as important message')}</Typography>
              </div>
              <div className={styles.LogBookPostModal_ActionRow_PublishButton}>
                <div>
                  {edit && (
                    <Button danger secondary marginRight icon={`fal fa-trash-alt`} onClick={() => handleDeletePost()}>
                      {t('Remove')}
                    </Button>
                  )}
                </div>
                <Tooltip
                  title={disablePublishButton ? t('Please fill out all required * fields to continue') : ''}
                  arrow
                >
                  <span>
                    <ContainedIconButton
                      disabled={disablePublishButton}
                      color="primary"
                      icon={edit ? `fal fa-save` : `fal fa-plus`}
                      label={edit ? t(`Save`) : t(`Post`)}
                      type="submit"
                      style={{ marginTop: 20, marginBottom: 20 }}
                      onClick={() => (edit ? editPost(logBookPost) : createPost(logBookPost))}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      <ConfirmDialog
        open={confirmDialogOpen}
        textObject={{
          title: t(`Are you sure you want to close your logbook entry?`),
          text: edit
            ? t(
              `Any possible progress will be lost if you close this window. Are you sure you want to close this window?`
            )
            : t(
              `All your progress will be lost and you have to redo your logbook entry again if you close this window.`
            ),
        }}
        onClose={() => {
          setConfirmDialogOpen(false)
        }}
        onConfirm={() => {
          handleClose()
          setConfirmDialogOpen(false)
          resetLogBookPost()
        }}
      />
      <ConfirmDialog
        open={confirmDeletePost}
        textObject={{
          title: t(`Are you sure you want to delete this logbook post?`),
          text: t(`If you confirm this logbook post will be deleted and you will not be able to recover it.`),
        }}
        onClose={() => {
          setConfirmDeletePost(false)
        }}
        onConfirm={() => {
          setConfirmDeletePost(false)
          deletePost(logBookPost.id)
        }}
      />
    </>
  )
}

export default CreateLogBookPostModal
