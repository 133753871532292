import React, { ReactElement } from 'react'

import { dynamicClassName } from 'styles/helper'

import styles from './InfoBanner.module.less'

type InfoBannerProps = {
  text: string
  style?: 'info' | 'warning' | 'warningWithoutBackground' | 'danger'
  inlineBlock?: boolean
}

export default function InfoBanner({ text, style, inlineBlock }: InfoBannerProps): ReactElement {

  return (
    <div style={inlineBlock ? {display: 'inline-block'} : {}} className={dynamicClassName({
      [styles.InfoBanner]: true,
      [styles.InfoBanner_Background__warning]: !!(style === 'warning'),
    } )}>
      <span className={dynamicClassName({
        [styles.InfoBanner_Icon]: true,
        [`fas fa-info-circle ${styles.InfoBanner_Icon__info}`]: !!(style === 'info'),
        [`fas fa-info-circle ${styles.InfoBanner_Icon__warning}`]: !!(style === 'warning'),
        [`fas fa-info-circle ${styles.InfoBanner_Icon__warningWithoutBackground}`]: !!(style === 'warningWithoutBackground'),
        [`fas fa-exclamation-circle ${styles.InfoBanner_Icon__danger}`]: !!(style === 'danger'),
      } )}/>
      {text}
    </div>)
}

