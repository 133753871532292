import React, { useState, ReactElement } from 'react'

import { Event, EventCategory, useSystemEvents } from 'api/systemEvents/systemEvents.api'
import { useCardWidget } from 'ui/components/CardWidget/CardWidget'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'
import { ArrowDatePicker } from 'ui/molecules/pickers/ArrowDatePicker/ArrowDatePicker'

import {
  Grid,
  Typography,
  MenuItem,
  Divider,
  Checkbox,
  Popper,
  MenuList,
  ClickAwayListener,
  CircularProgress,
} from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { startTimeLimit } from 'helpers/global.helper/global.helper'

import styles from './EventLogger.module.less'
import EventLoggerItem from './components/EventLoggerItem'
import FilterButton from './components/FilterButton/FilterButton'

type EventLoggerContentProps = {
  events: Event[]
  filterBox: Record<EventCategory, boolean>
  hasNextPage: boolean
  fetchNextPage: () => void
  isFetchingNextPage: boolean
}

function EventLoggerContent({
  events,
  filterBox,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: EventLoggerContentProps): ReactElement {
  const filteredEvents = events.filter((event) => filterBox[event.event_category])
  const { t } = useTranslation()

  return (
    <>
      {filteredEvents.length ? (
        filteredEvents.map((event) => <EventLoggerItem event={event} key={`system-event-item-${event.id}`} />)
      ) : (
        <Typography variant="body1" style={{ textAlign: `center` }}>
          <br />
          {t(`There are no events to show.`)}
          <br />
          <br />
        </Typography>
      )}
      {hasNextPage && (
        <Grid container justifyContent="center">
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <ContainedIconButton
              onClick={fetchNextPage}
              color="primary"
              icon="fal fa-arrow-alt-to-bottom"
              label={t(`Fetch earlier events`)}
            />
          )}
        </Grid>
      )}
    </>
  )
}

function EventLogger(): ReactElement {
  const { t } = useTranslation()

  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null)
  const [currentDate, setCurrentDate] = useState(moment())
  const [filterBox, setFilterBox] = useState<Record<EventCategory, boolean>>({
    optimize_setting: true,
    optimization_regular: true,
    optimization_sandbox: true,
    optimization_followup_with_deviations: true,
    optimization_followup_without_deviations: true,
    optimization_measvalues_calculations: true,
    forecast_load: true,
    forecast_elprice: true,
    optimization_automatic: true,
  })
  const {
    data: eventsResponse,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    status,
  } = useSystemEvents({
    startDate: moment(currentDate).startOf(`day`).format(`YYYY-MM-DD HH:mm`),
    endDate: moment(currentDate).endOf(`day`).format(`YYYY-MM-DD HH:mm`),
  })
  const events = eventsResponse?.pages?.reduce<Event[]>((prev, next) => [...prev, ...next.results], [])

  const toggleFilterBox = (event?: React.MouseEvent<HTMLElement>): void => {
    setAnchorFilter(anchorFilter || !event?.currentTarget ? null : event?.currentTarget)
  }

  function handleChange(name: EventCategory): void {
    setFilterBox({ ...filterBox, [name]: !filterBox[name] })
  }
  const translationArray = Object.keys(filterBox).map((category) => {
    return {
      translation: {
        optimization_automatic: t(`Automatic optimizations`),
        optimization_regular: t(`Optimizations`),
        optimization_followup_with_deviations: t(`Follow-ups (include deviations)`),
        optimization_followup_without_deviations: t(`Follow-ups (optimal production)`),
        optimization_measvalues_calculations: t(`Outcome values`),
        optimization_sandbox: t(`Sandbox optimizations`),
        optimize_setting: t(`Settings`),
        forecast_load: t(`Demand forecasts`),
        forecast_elprice: t(`Electricity price forecasts`),
      }[category],
      key: category as EventCategory,
    }
  })

  translationArray.sort((a, b) => a.translation.localeCompare(b.translation))


  const { node } = useCardWidget()

  return (
    <>
      <Grid container direction="row" justifyContent="center" className={styles.EventLogger_EventLogMenuBar}>
        <Grid item container xl={8} lg={12} md={8} sm={8} xs={8} justifyContent="center">
          <ArrowDatePicker
            prevButtonTooltip={t(`Previous day`)}
            nextButtonTooltip={t(`Next day`)}
            format="ddd D MMM, YYYY"
            value={currentDate}
            onChange={(date) => setCurrentDate(date)}
            minValue={startTimeLimit}
            step={{ unit: `day`, amount: 1 }}
          />
        </Grid>
        <Grid item container xl={4} lg={12} md={4} sm={4} xs={4} >
          <FilterButton onClick={(e) => toggleFilterBox(e)} text={t(`Filter`)} style={{width: '100%'}}/>
          <Popper
            open={Boolean(anchorFilter)}
            anchorEl={anchorFilter}
            className={styles.EventLogger_FilterDropdownMenu}
            placement="bottom-start"
            container={node?.current}
          >
            <ClickAwayListener onClickAway={() => toggleFilterBox()}>
              <MenuList>
                {translationArray.map((translation) => {
                  return (
                    <MenuItem key={translation.key} onClick={() => handleChange(translation.key)}>
                      <Checkbox checked={filterBox[translation.key]} onChange={() => handleChange(translation.key)} />
                      {translation.translation}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </ClickAwayListener>
          </Popper>
        </Grid>
      </Grid>
      <Divider />
      <StatusComponent
        status={[status]}
        data={{ events }}
        Component={EventLoggerContent}
        ComponentProps={{ hasNextPage: hasNextPage ?? false, isFetchingNextPage, fetchNextPage, filterBox }}
      />
    </>
  )
}

export default EventLogger
