import React, { ReactElement } from 'react'

import { BASE_SETTING_PRIORITIES } from 'api/digitalTwin/digitalTwinConstants'

import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'

import DigitalTwinSettingsView from './DigitalTwinSettingsView'

export default function DigitalTwinDeviationSettingsView(): ReactElement | null {
  const { t } = useTranslation()
  
  const permissions = {
    canEdit: useHasPermission(`change_model_settings`),
    canEditHistoric: useHasPermission(`change_historic_model_settings`),
  }

  return (
    <DigitalTwinSettingsView
      priorities={BASE_SETTING_PRIORITIES}
      showCalendar={false}
      title={t(`Base settings`)}
      calendarTitle={t(`Base settings - calendar`)}
      tableTitle={t(`Base settings - table`)}
      isBaseSettings={true}
      permissions={permissions}
    />
  )
}
