import React, { ReactElement } from 'react'

import { DeclinedHours, HeatSupplier, useDeclinedHours } from 'api/heatSuppliers/heatSuppliers.api'
import { Setting, useObjectPropertySettings } from 'api/settings/settings.api'
import { samenergiStore } from 'store/samenergi/samenergi'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { CircularProgress, Grid } from '@mui/material'
import moment, { Moment } from 'moment'

import { mapAvailableHoursSettings } from 'helpers/samEnergi.helper/samEnergi.helper'

import HourlyMetricField from './components/HourlyMetricField/HourlyMetricField'
import ManageButtons from './components/ManageButtons/ManageButtons'

function SupplierDetailsContent({
  settings,
  declinedHours,
}: {
  declinedHours: DeclinedHours
  settings: Setting[]
}): ReactElement {
  const availableHours = mapAvailableHoursSettings(settings)
  const currentDeclinedHours = declinedHours ? declinedHours : { planned: 0, unplanned_winter: 0, unplanned_summer: 0 }

  const plannedRemaining = (availableHours.plannedStop?.value ?? 0) - currentDeclinedHours.planned
  const unplannedWinterRemaining =
    (availableHours.unplannedStopWinter?.value ?? 0) - currentDeclinedHours.unplanned_winter
  const unplannedSummerRemaining =
    (availableHours.unplannedStopSummer?.value ?? 0) - currentDeclinedHours.unplanned_summer

  samenergiStore.plannedRemaining = plannedRemaining
  samenergiStore.unplannedSummerRemaining = unplannedSummerRemaining
  samenergiStore.unplannedWinterRemaining = unplannedWinterRemaining

  return (
    <>
      <HourlyMetricField
        remainingHours={{
          plannedRemaining: plannedRemaining,
          unplannedWinterRemaining: unplannedWinterRemaining,
          unplannedSummerRemaining: unplannedSummerRemaining,
        }}
      />
    </>
  )
}

type SupplierDetailsProps = {
  supplier: HeatSupplier
  currentDate: Moment
}

export default function SupplierDetails({ supplier, currentDate }: SupplierDetailsProps): ReactElement {
  const { data: settings, status } = useObjectPropertySettings(
    supplier.props.reduce<number[]>(
      (current, next) => (next.name === `available_hours` ? [...current, next.id] : current),
      []
    ),
    {
      active_from: moment(currentDate).startOf(`year`).format(),
      active_to: moment(currentDate).endOf(`year`).format(),
      is_base_value: true,
    }
  )
  const { data: declinedHours, status: declinedHoursStatus } = useDeclinedHours(moment(currentDate).year(), supplier.id)

  return (
    <Grid container direction="row" spacing={3} style={{ padding: 20 }} justifyContent="center">
      <ManageButtons currentDate={currentDate} supplier={supplier} />
      <StatusComponent
        data={{ settings, declinedHours }}
        status={[status, declinedHoursStatus]}
        Component={SupplierDetailsContent}
        ComponentProps={{ supplier, currentDate }}
        LoadingFallback={
          <Grid item>
            <CircularProgress />
          </Grid>
        }
      />
    </Grid>
  )
}
