import React, { ReactElement, useState } from 'react'

import { LogBookPost, useUpdateLogBookPostMutation } from 'api/logBook/logBook.api'
import alertStore from 'store/alert/alert'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import Datetime from 'utils/datetime/datetime'

import { Card, CardContent, Grid, Icon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './PinnedItem.module.less'

type PinnedItemProps = {
  data: LogBookPost
  onEditClick?: () => void
}

export default function PinnedItem({ data, onEditClick }: PinnedItemProps): ReactElement {
  const { t } = useTranslation()
  const { mutateAsync: updateLogBookPost } = useUpdateLogBookPostMutation()

  const [confirmUnpinMessage, setConfirmUnpinMessage] = useState(false)

  function handleUnpinClick(): void {
    setConfirmUnpinMessage(true)
  }

  const unPinItem = async (): Promise<void> => {
    try {
      await updateLogBookPost({
        ...data,
        is_pinned: false,
      })
      alertStore.success(t(`Unpinned message`))
    } catch (err) {
      alertStore.error(t(`Something went wrong, try again or contact us.`))
    }
  }

  return (
    <>
      <Card className={styles.PinnedItem_Card}>
        <CardContent className={styles.PinnedItem_CardContent}>
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            className={styles.PinnedItem_Card_TopRow}
          >
            <Grid item container direction="row" alignItems="center" xs>
              <TooltipIconButton
                icon={data.is_pinned ? 'fas fa-thumbtack' : 'fal fa-thumbtack'}
                tooltip={t('Unpin message')}
                iconColor="#FFAE00"
                iconSize="small"
                onClick={handleUnpinClick}
              />
              {data.category && (
                <Typography variant="h6" sx={{ fontWeight: '600' }}>
                  {t(data.category)}
                </Typography>
              )}
            </Grid>
            <TooltipIconButton icon={'fas fa-pen'} tooltip={t('Edit')} iconSize="small" onClick={onEditClick} />
          </Grid>
          <Typography variant="body1">{data.content}</Typography>
          {data.is_reported && (
            <Grid container direction="row" alignItems="center" className={styles.PinnedItem_Card_isReported}>
              <Icon className="fas fa-check-square" fontSize="small" style={{ color: '#80704d' }} />
              <Typography fontSize={14} className={styles.PinnedItem_Card_isReported_Font}>
                {t(`Reported`)}
              </Typography>
            </Grid>
          )}
          <Grid className={styles.PinnedItem_Card_CreatedByInfo}>
            <Typography fontSize={13}>
              {`${t('Created by')} ${data.written_by} ${Datetime.toLocalTime(data.created_at as ISODateTime)}`}
            </Typography>
            {data.updated_written_by && (
              <Typography fontSize={13}>
                {`${t('Edited by')} ${data.updated_written_by} ${Datetime.toLocalTime(data.updated_at as ISODateTime)}`}
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>

      <ConfirmDialog
        open={confirmUnpinMessage}
        textObject={{
          title: t(`Are you sure you want to unpin this log?`),
          text: t(
            `You can still find the log in the table in Logbook Flow, but the log will disapear from Pinned messages.`
          ),
        }}
        onClose={() => setConfirmUnpinMessage(false)}
        onConfirm={() => {
          unPinItem()
          setConfirmUnpinMessage(false)
        }}
      />
    </>
  )
}
