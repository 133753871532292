import { apiClient } from 'api/apiClient/apiClient'

import { useQuery, UseQueryResult } from 'react-query'

export type Module = { name: string; submodules?: string[] }

export const MODULES_QUERY_KEY = `modules`

export function useModules(systemId: number | undefined): UseQueryResult<Module[]> {
  return useQuery(
    [MODULES_QUERY_KEY, { system: systemId }],
    () =>
      apiClient<Module[]>(`modules`, {
        params: {
          system: systemId,
        },
      }),
    {
      enabled: !!systemId,
    }
  )
}
