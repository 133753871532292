import React, { ReactElement, useMemo, useRef, useState } from 'react'

import { useDatasets } from 'api/dataset/dataset.api'
import {
  ConfigurableProperty,
  DigitalTwinSettingCreationRequest,
  DeviationSettingLevel,
  DigitalTwin,
  DigitalTwinPropertyObject,
  TimeSeriesSettingCreationRequest,
  TimeSeriesSettingValue,
  useCreateTimeSeriesSetting,
  DigitalTwinSettingMetadata,
} from 'api/digitalTwin/digitalTwin.api'
import { useUiConfig } from 'api/uiConfig/uiConfig.api'
import authStore from 'store/auth/auth'
import uiConfigStore from 'store/uiConfig/uiConfig'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Dialog } from 'ui/components/Dialog/Dialog'
import TimeSeriesSettingTable, { getTableKey } from 'ui/components/DigitalTwinSettingForm/TimeSeriesSettingTable'
import { TIME_SERIES_VISUALIZATION } from 'ui/components/DigitalTwinSettingForm/constants'
import CustomDialogTitle from 'ui/components/SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import ModalButtons from 'ui/molecules/ModalButtons/ModalButtons'
import DeviationSettings from 'ui/uiConfig/anchorComponents/FollowupProduction/DeviationSettings/DeviationSettings'
import Chart from 'ui/uiConfig/components/Chart/Chart'
import { DEFAULT_TIME_SERIES_CONFIG } from 'ui/uiConfig/components/Chart/chart.constants'
import { ChartConfigWithZoom, ChartItem } from 'ui/uiConfig/components/Chart/chartTypes'
import Datetime from 'utils/datetime/datetime'

import { DialogActions, DialogContent } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { fillMissingHours } from 'helpers/dataset.helper/dataset.helper'
import { getPrettyName, getPropertyPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'

import styles from './DigitalSettingsOverviewModal.module.less'


type DigitalTwinSettingsOverviewModalProps = {
  initData: {name: string, level: string}
  propertyItems: DigitalTwinPropertyObject[]
  digitalTwin: DigitalTwin
  onClose: () => void
  onSettingModified: () => void
  priorities: SystemSettingPriorityLevel[],
  activeSettingMetadataDict: {[key: string]: DigitalTwinSettingMetadata}
  activeBaseSettingMetadataDict: {[key: string]: DigitalTwinSettingMetadata}
}

function generateDataSet(returnId: string, settingDataValue?: number | TimeSeriesSettingValue | null | undefined): Dataset | undefined{
  const chartSettingItem: Dataset = {
    return_id: returnId,
    times: [],
    values: [],
  }

  if (settingDataValue && typeof settingDataValue === 'object') {
    Object.entries(settingDataValue).forEach(([time, { value }]) => {
      (chartSettingItem.times as ISODateTime[]).push(time as ISODateTime)
      chartSettingItem.values.push(value)
    })
    return chartSettingItem
  }
  return undefined
}

function generateChartItem(
  title: string,
  data_id: string,
  color: string,
  configurableProperties?: ConfigurableProperty[]
): ChartItem {
  const unit = configurableProperties?.find((configurableProperty) => (
    configurableProperty.attribute === data_id)
  )?.measurement_unit

  // Chart item
  const chartItem: ChartItem = {
    title: title,
    fill: false,
    unit: unit,
    color: color,
    order: -1,
    dashed: false,
    data_id: data_id,
    decimals: 1,
    data_type: undefined,
    y_axis_id: 'y',
    tooltip_do_not_show_zero_values: false,
  }
  return chartItem
}

function generateSettingDataValue(
  activeSetting: DigitalTwinSettingMetadata,
  startTime: ISODateTime,
  endTime: ISODateTime
): TimeSeriesSettingValue {
  return Datetime.getHoursBetween(startTime, endTime)
    .map((time, index) => ({time, index}))
    .reduce((accumulated: TimeSeriesSettingValue, newEntry: {time: ISODateTime, index: number}) => {
      let value: {id?: number, value: number | null} = {value: null}
      if (activeSetting?.value && Array.isArray(activeSetting.value)) {
        value = activeSetting.value[newEntry.index]
      } else if (typeof activeSetting?.value === 'number') {
        value = {value: activeSetting.value}
      } else if (typeof activeSetting?.value === 'string' && !isNaN(Number(activeSetting.value))) {
        value = { value: Number(activeSetting.value) }
      }

      accumulated[newEntry.time] = value
      return accumulated
    }, {})
}

export default function DigitalTwinSettingsOverviewModal({
  initData,
  propertyItems,
  digitalTwin,
  onClose,
  onSettingModified,
  priorities,
  activeSettingMetadataDict,
  activeBaseSettingMetadataDict,
}: DigitalTwinSettingsOverviewModalProps): ReactElement {
  const [settingsData, setSettingsData] = useState<{ [attribute: string]: DigitalTwinSettingCreationRequest }>({})
  const [updatedSettingsData, setUpdatedSettingsData] = useState<{ [attribute: string]: DigitalTwinSettingCreationRequest }>({})
  const { mutateAsync: createTimeSeriesSetting, isLoading: isCreatingTimeSeriesSettingLoading } = useCreateTimeSeriesSetting()

  const { t } = useTranslation()
  const ref = useRef(null)

  const { activeSystem, systemId: globalSystemId } = useAuth()
  const systemId = activeSystem?.id ?? globalSystemId

  const startTime = Datetime.toISOString(moment().startOf('day'))
  const endTime = Datetime.toISOString(moment().startOf('day').hours(23))
  const times = useMemo(() => {
    return Datetime.getHoursBetween(startTime, endTime)
  }, [startTime, endTime])

  const activeProperties: DigitalTwinPropertyObject | undefined = useMemo(() => (
    propertyItems.find((item) => item.name === initData.name && item.level === initData.level)
  ), [initData, propertyItems])

  // List of active deviation settings
  const activeSettings = useMemo(() => {
    const settings: DigitalTwinSettingMetadata[] = []
    activeProperties?.configurable_properties?.forEach((configurableProperty) => {
      const activeSetting = activeSettingMetadataDict[
        JSON.stringify({
          level: activeProperties.level,
          name: activeProperties.name,
          attribute: configurableProperty.attribute,
        })
      ]
      if (activeSetting) {
        settings.push(activeSetting)
      }
    })
    return settings
  },[activeProperties, activeSettingMetadataDict])

  // List of active base settings
  const activeBaseSettings = useMemo(() => {
    const settings: DigitalTwinSettingMetadata[] = []
    activeProperties?.configurable_properties?.forEach((configurableProperty) => {
      const activeBaseSetting = activeBaseSettingMetadataDict[
        JSON.stringify({
          level: activeProperties.level,
          name: activeProperties.name,
          attribute: configurableProperty.attribute,
        })
      ]
      if (activeBaseSetting) {
        settings.push(activeBaseSetting)
      }
    })
    return settings
  }, [activeProperties, activeBaseSettingMetadataDict])

  // Active settings
  const activeChartSettings: {
    datasets: Dataset[]
    chartItems: ChartItem[]
  } = useMemo(() => {
    const datasets: Dataset[] = []
    const chartItems: ChartItem[] = []
    activeSettings.forEach((activeSetting, index) => {
      if (activeSetting.start_time) {
        const prettyName = getPropertyPrettyName(activeSetting.name, activeSetting.attribute, digitalTwin.translations)
        const title = `${prettyName}: ${t('Active')} ${t('Deviation settings')}`
        const returnId = activeSetting.attribute + ' (Active deviation)' + index

        // Chart item
        const chartItem = generateChartItem(title, returnId, 'red', activeProperties?.configurable_properties)
        chartItems.push(chartItem)

        // Dataset
        const values = generateSettingDataValue(activeSetting, activeSetting.start_time, activeSetting.end_time ?? endTime)
        const dataset = generateDataSet(returnId, values)
        if (dataset) {
          datasets.push(dataset)
        }
      }
    })

    activeBaseSettings.forEach((activeBaseSetting, index) => {
      if (activeBaseSetting.start_time) {
        const prettyName = getPropertyPrettyName(
          activeBaseSetting.name,
          activeBaseSetting.attribute,
          digitalTwin.translations
        )
        const title = `${prettyName}: ${t('Active')} ${t('Base setting')}`
        const returnId = activeBaseSetting.attribute + ' (Active base)' + index

        // Chart item
        const chartItem = generateChartItem(title, returnId, 'green', activeProperties?.configurable_properties)
        chartItems.push(chartItem)

        // Dataset
        const values = generateSettingDataValue(activeBaseSetting, activeBaseSetting.start_time, activeBaseSetting.end_time ?? endTime)
        const dataset = generateDataSet(returnId, values)
        if (dataset) {
          datasets.push(dataset)
        }
      }
    })

    return {
      datasets: fillMissingHours(datasets, startTime, endTime),
      chartItems: chartItems,
    }
  }, [
    activeBaseSettings,
    activeProperties?.configurable_properties,
    activeSettings,
    digitalTwin.translations,
    endTime,
    startTime,
    t,
  ])

  useMemo(() => {
    const value = times
      .map((time, index) => ({time, index}))
      .reduce((accumulated: {[time: ISODateTime]: {id?: number, value: number | null}}, newEntry: {time: ISODateTime, index: number}) => {
        accumulated[newEntry.time] = {value: null}
        return accumulated
      }, {})
    const settings: {[attribute: string]: DigitalTwinSettingCreationRequest} = {}
    activeProperties?.configurable_properties?.forEach((configurableProperty) => {
      settings[configurableProperty.attribute] = {
        name: activeProperties.name,
        level: activeProperties.level as DeviationSettingLevel,
        attribute: configurableProperty.attribute,
        comment: '-',
        priority: priorities[0].priority_level,
        start_time: startTime,
        end_time: endTime,
        isTimeSeries: true,
        value: {...value}, // Spread operation to clone
        unit: '',
        isBaseSetting: false,
      }
    })
    setSettingsData(settings)
  }, [activeProperties, priorities, endTime, startTime, times])

  // Convert the setting value to a dataset for the chart
  const chartSettings: {
    datasets: Dataset[]
    chartItems: ChartItem[]
  } = useMemo(() => {
    const dataSets: Dataset[] = []
    const chartItems: ChartItem[] = []
    Object.values(settingsData).forEach((settingData) => {
      const dataset = generateDataSet(settingData.attribute, settingData.value)
      if (dataset) {
        dataSets.push(dataset)
      }

      const prettyName = getPropertyPrettyName(settingData.name, settingData.attribute, digitalTwin.translations)
      const chartItem = generateChartItem(
        prettyName,
        settingData.attribute,
        'black',
        activeProperties?.configurable_properties
      )
      chartItems.push(chartItem)
    })
    return {
      datasets: dataSets,
      chartItems: chartItems,
    }
  }, [activeProperties?.configurable_properties, digitalTwin.translations, settingsData])

  const values = times.map(() => { return {value: 0} })
  const [tableKey, setTableKey] = useState<string>(getTableKey(values))

  // Use the dataset instructions of the `TIME_SERIES_VISUALIZATION` anchor component to fetch data.
  const authSnap = useSnapshot(authStore)
  const uiConfigRes = useUiConfig(systemId, TIME_SERIES_VISUALIZATION, { mode: authSnap.isBeta ? `beta` : null })
  const tmpUiConfig = uiConfigRes?.data?.find((u) => u.component === TIME_SERIES_VISUALIZATION)
  const uiConfigSnap = useSnapshot(uiConfigStore)
  const uiConfig = uiConfigSnap.getParsedUiConfig(tmpUiConfig?.uid ?? 0, {}, {})

  const datasetRes = useDatasets(
    uiConfig?.id ?? -1,
    uiConfig?.version ?? -1,
    uiConfig?.dataset_instructions ?? [],
    startTime,
    endTime,
    {
      uid: uiConfig?.uid ?? 0,
      returnIdRegexFilter: `^(.*\\.)?${initData.name}(\\..*)?$`, // Only fetch data from sources whose return_id matches the setting name
    }
  )

  const chartConfig = DEFAULT_TIME_SERIES_CONFIG
  if (chartConfig.options) {
    chartConfig.options.ignoreUpdateDataResolutionOnZoom = true
  }

  return (<div ref={ref}>
    <Dialog
      fullWidth
      open={true}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      container={ref.current}
      onClose={onClose}
    >
      <div className={styles.DialogHeaderContainer}>
        <div className={styles.Beta}>{'Beta'}</div>
        <CustomDialogTitle
          title={initData.name ? getPrettyName(initData.name, digitalTwin.translations) : ''}
          handleClose={onClose}
        />
        <div className={styles.DialogHeaderContainer__itemAlignRight}>
          <DeviationSettings
            startTime={startTime}
            endTime={endTime}
            forceListView
          />
        </div>
        <Chart
          title={t('Meas values')}
          items={[
            ...(uiConfig?.props.items ?? []) as ChartItem[],
            ...activeChartSettings.chartItems,
            ...chartSettings.chartItems,
          ]}
          config={chartConfig as ChartConfigWithZoom}
          datasets={[
            ...(datasetRes.data ?? []),
            ...activeChartSettings.datasets,
            ...chartSettings.datasets,
          ]}
          hide_legend={false}
          maxWidth='100%'
        />
      </div>
      <DialogContent>
        <div style={{ marginTop: 16, marginBottom: 8, width: '100%' }}>
          <TimeSeriesSettingTable
            chartItems={[
              ...(uiConfig?.props.items ?? []) as ChartItem[],
              ...chartSettings.chartItems,
            ]}
            datasets={[
              ...(datasetRes.data ?? []),
              ...chartSettings.datasets,
            ]}
            // invalidValuesIndex={invalidValuesIndex} TODO: Implement invalidValuesIndex
            setSettingData={(res) => {
              setSettingsData(prevState => ({...prevState, [res.attribute]: res}))
              setUpdatedSettingsData(prevState => ({...prevState, [res.attribute]: res}))
            }}
            settingsData={Object.values(settingsData)}
            times={times}
            tableKey={tableKey}
            setTableKey={setTableKey}
            digitalTwinTranslations={digitalTwin.translations}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ModalButtons
          disableSubmit={Object.keys(updatedSettingsData).length === 0}
          isSubmitting={isCreatingTimeSeriesSettingLoading}
          onSubmit={() => {
            const promises = Object.values(updatedSettingsData).map((setting) =>
              createTimeSeriesSetting({
                overwrite: true,
                digitalTwinUid: digitalTwin.uid,
                data: setting as TimeSeriesSettingCreationRequest,
                splitOnNullValues: true,
              })
            )

            Promise.allSettled(promises).then(data => {
              data.forEach((results) => {
                if (results.status === 'fulfilled') {
                  results.value.forEach((res) => {
                    const settings = res.time_series?.settings ?? []
                    const attribute = settings.length > 0 ? settings[0].attribute : undefined
                    if (attribute != undefined) {
                      setUpdatedSettingsData(prevState => {
                        const newState = {...prevState}
                        delete newState[attribute]
                        return newState
                      })
                    }
                  })
                }
              })

              // If some stasus are fullfilled, update the settings
              if (data.some((res) => res.status === 'fulfilled')) {
                onSettingModified()
              }

              // If all statuses are fulfilled, close the modal
              if (data.every((res) => res.status === 'fulfilled')) {
                setUpdatedSettingsData({})
                onClose()
              }
            })
          }}
          onCancel={() => {
            setUpdatedSettingsData({})
            onClose()
          }}
        />
      </DialogActions>
    </Dialog>
  </div>)
}