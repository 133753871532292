import React, { ReactElement } from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { Icon } from '@mui/material'

import { StyledTab, StyledTabs } from './StyledTabs'

type RegularTabsProps = {
  value: number
  handleChange: (event: React.ChangeEvent<unknown>, newValue: number) => void
  tabObjects: TabObject[]
}
export type TabObject = {
  tooltip?: string
  label: string
  id: number
  disabled?: boolean
}
export default function RegularTabs({ handleChange, value, tabObjects }: RegularTabsProps): ReactElement {
  return (
    <StyledTabs value={value} onChange={handleChange} aria-label="Navigationbar">
      {tabObjects.map((tab) => {
        return (
          <StyledTab
            key={tab.id}
            value={tab.id}
            aria-label={tab.label}
            disabled={tab.disabled}
            label={
              <div>
                {tab.tooltip && (
                  <Tooltip title={tab.tooltip} placement="top" arrow>
                    <Icon className="fas fa-info-circle" fontSize="inherit" />
                  </Tooltip>
                )}
                &nbsp; {tab.label}
              </div>
            }
          />
        )
      })}
    </StyledTabs>
  )
}
