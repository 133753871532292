import React, { ReactElement } from 'react'

import { Card } from 'ui/atoms'
import { registerUiConfigComponent, registerDefaultProps } from 'ui/uiConfig/factory'

type ElplanBidsProps = {
  items: Array<{ title: string, data_id: string }>
  datasets: Dataset[]
}

const defaultProps = {
  use_default: true,
}
registerUiConfigComponent(`elplan_bids`, ElplanBids, {
  isSingletonComponent: true,
})
registerDefaultProps(`elplan_bids`, defaultProps)

export default function ElplanBids({ items, datasets }: ElplanBidsProps): ReactElement {
  return (
    <>
      <Card title="Elplan bids">
        <p>{items.length} items. {datasets.length} datasets.</p>

        {datasets.map((dataset, index) => (
          <div key={index}>
            {dataset.return_id}
          </div>
        ))}
      </Card>
    </>
  )
}
