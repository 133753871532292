export const DEFAULT_MIN_DATE_FOR_DATE_PICKER = moment().year(2015).startOf(`year`)
export const DEFAULT_MAX_DATE_FOR_DATE_PICKER = moment().add(5, 'years').endOf(`year`) //Make sure you cannot choose a date too far in the future


export type ValidationError = 'REVERSED_RANGE' | 'MISSING_END_DATE' | 'MISSING_START_DATE' | 'RANGE_OVERFLOW' | 'RANGE_UNDERFLOW'

export type ValidationResult =
  | {
      isValid: true
    }
  | {
      isValid: false
      error: ValidationError
    }

import moment from 'moment'
import { DateValue } from 'react-aria-components'

interface RangeValidationArgs {
  startDate: DateValue | null | undefined
  endDate: DateValue | null | undefined
  allowEmptyEndDate?: boolean
  minValue?: DateValue | null | undefined
  maxValue?: DateValue | null | undefined
}

interface DateValArg {
  value: DateValue
  minValue?: DateValue | null | undefined
  maxValue?: DateValue | null | undefined
}

function validateDate({ value, minValue, maxValue }: DateValArg): ValidationResult {
  const rangeOverflow = value != null && maxValue != null && value.compare(maxValue) > 0
  const rangeUnderflow = value != null && minValue != null && value.compare(minValue) < 0

  if (rangeOverflow) {
    return {
      isValid: false,
      error: 'RANGE_OVERFLOW',
    }
  }

  if (rangeUnderflow) {
    return {
      isValid: false,
      error: 'RANGE_UNDERFLOW',
    }
  }

  return {
    isValid: true,
  }
}

export function validateRange(args: RangeValidationArgs): ValidationResult {
  const { startDate, endDate, maxValue, minValue, allowEmptyEndDate } = args

  // Missing start date
  if (!startDate) {
    return {
      isValid: false,
      error: 'MISSING_START_DATE',
    }
  }

  // Check if start date is within max/min range
  const startDateValidation = validateDate({
    value: startDate,
    minValue,
    maxValue,
  })
  if (!startDateValidation.isValid) {
    return startDateValidation
  }

  // Check if end date is missing
  if (!endDate && !allowEmptyEndDate) {
    return {
      isValid: false,
      error: 'MISSING_END_DATE',
    }
  }

  // Check of end date is within max/min range
  if (endDate) {
    const endDateValidation = validateDate({
      value: endDate,
      minValue,
      maxValue,
    })
    if (!endDateValidation.isValid) {
      return endDateValidation
    }
  }


  // Check if startDate is after endDate
  if (startDate && endDate && endDate.compare(startDate) < 0) {
    return {
      isValid: false,
      error: 'REVERSED_RANGE',
    }
  }

  // If none of the above, then the range is valid
  return {
    isValid: true,
  }
}

