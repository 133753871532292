import React, { useState, ReactElement } from 'react'

import { BULLETIN_BOARD_THREADS_QUERY_KEY } from 'api/bulletinBoard/bulletinBoard.api'
import Icon from 'ui/atoms/Icon/Icon'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import BulletinBoard from 'ui/components/BulletinBoard/BulletinBoardView'
import CardWidget from 'ui/components/CardWidget/CardWidget'
import EventLogger from 'ui/components/EventLogger/EventLogger'
import ModuleHeader from 'ui/components/ModuleHeader/ModuleHeader'
import SystemMessageBanner from 'ui/components/SystemMessageBanner/SystemMessageBanner'
import Tour from 'ui/components/Tour/Tour'
import View from 'ui/components/View/View'

import { useChannel, useEvent } from '@harelpls/use-pusher'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from 'react-joyride'

import { getDisplayName } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'

import styles from './StartpageView.module.less'
import StartpageHeaderContent from './components/StartpageHeaderContent/StartpageHeaderContent'

const widgetConstants = {
  STARTPAGE_CALENDAR: `startpage-calendar`,
  STARTPAGE_OVERVIEW_CHART: `startpage-overview-chart`,
  STARTPAGE_EVENTLOG: `startpage-eventlog`,
  STARTPAGE_BULLETINBOARD: `startpage-bulletin-board`,
  STARTPAGE_CALENDAR_CONTENT: `startpage-calendar-content`,
  STARTPAGE_EVENTLOG_CONTENT: `startpage-eventlog-content`,
  STARTPAGE_BULLETINBOARD_CONTENT: `startpage-bulletin-board-content`,
}

function handleBulletinboardPusherEvent(
  _event:
    | {
        data?:
          | {
              type: `comment` | `thread`
              thread_id: number
              comment_id: number
              event: `changed` | `deleted` | `liked`
            }
          | undefined
      }
    | undefined
): void {
  queryClient.invalidateQueries([BULLETIN_BOARD_THREADS_QUERY_KEY])
}

const bullentinInfo: Array<Step> = [
  {
    target: widgetConstants.STARTPAGE_BULLETINBOARD_CONTENT,
    content: `Did anything new happen at the plant or is there anything you think your colleagues need to know? On the bulletin board you can post messages (and comment on others') to all permitted users at the plant. The bulletin board also shows created and edited deviation settings to increase transparency and ease of communication between the users.`,
    title: `Bulletin board for current plant`,
  },
]

const eventlogInfo: Array<Step> = [
  {
    target: widgetConstants.STARTPAGE_EVENTLOG_CONTENT,
    content: `The system events widget shows all events that occur in the system for the current plant. This log contains events split into different types of optimizations, settings as well as outcome values and forecast updates. You can also filter events based on what you would like to see.`,
    title: `Event log`,
  },
]

const steps: Array<Step> = [...bullentinInfo, ...eventlogInfo]

export default function StartpageView(): ReactElement {
  const { t } = useTranslation()

  const [runTour, setRunTour] = useState<null | number>(null)
  const { systemId, activeSystem } = useAuth()

  const channel = useChannel(systemId ? `aurora-django-bulletinboard-${systemId}` : undefined)

  useEvent(channel, `bulletinboard-event`, handleBulletinboardPusherEvent)

  const isDigitalTwinSystem = !!activeSystem?.primary_digital_twin

  return (
    <View
      header={
        <ModuleHeader
          infoHeader={t(`Startpage`)}
          onInfoButtonClick={() => {
            setRunTour(0)
          }}
        >
          <StartpageHeaderContent />
        </ModuleHeader>
      }
    >
      <SystemMessageBanner/>
      <Tour
        isSingle={!!runTour}
        run={runTour !== null}
        setRunState={(state: boolean) => setRunTour(state ? 0 : null)}
        steps={runTour === 1 ? bullentinInfo : runTour === 2 ? eventlogInfo : steps}
      />
      <Grid item xs={12} md={12} lg={8} xl={9}>
        <CardWidget
          id={widgetConstants.STARTPAGE_BULLETINBOARD_CONTENT}
          title={
            activeSystem
              ? t('Bulletin board for {{currentSystem}}', {
                currentSystem: activeSystem ? getDisplayName(activeSystem) : null,
              })
              : ''
          }
          handleInfoIconClick={() => {
            setRunTour(1)
          }}
        >
          <BulletinBoard />
        </CardWidget>
      </Grid>
      {isDigitalTwinSystem ? (
        // NOTE: This is a temporary notification for VF users to be able to find system events after migration to Digital Twin.
        // A while after all VF systems have been migrated, this can be removed. Remember to remove css classes and translation sources as well.
        <Grid item xs={12} md={12} lg={4} xl={3}>
          <CardWidget id={widgetConstants.STARTPAGE_EVENTLOG_CONTENT} title={t('System events')}>
            <div className={styles.StartpageView_SystemEventInfoHeader}>
              <i>{t('The system events have moved!')}</i>
            </div>
            <i>{t('System events are now located at the top right corner of the page')}</i>
            {' ('}
            <Icon color="black" size="large" icon="fad fa-tasks-alt" />
            {'). '}
            <i>{t('You can view them regardless of the module you are currently working in.')}</i>
          </CardWidget>
        </Grid>
      ) : (
        <Grid item xs={12} md={12} lg={4} xl={3}>
          <CardWidget
            id={widgetConstants.STARTPAGE_EVENTLOG_CONTENT}
            title={t('System events')}
            handleInfoIconClick={() => {
              setRunTour(2)
            }}
          >
            <EventLogger />
          </CardWidget>
        </Grid>
      )}
    </View>
  )
}
