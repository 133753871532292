import React, { ReactElement } from 'react'

import { Button } from 'ui/atoms'
import InfoBanner from 'ui/components/InfoBanner/InfoBanner'
import Tooltip from 'ui/components/Tooltip/Tooltip'

import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './ModalButtons.module.less'

type ModalButtonsProps = {
  cancelLabel?: string
  submitLabel?: string
  infoText?: string
  icon?: string
  disableSubmit?: boolean
  onSubmit?: () => void
  onCancel?: () => void
  onDelete?: () => void
  isSubmitting?: boolean
}

export default function ModalButtons({
  onSubmit,
  onCancel,
  onDelete,
  cancelLabel,
  submitLabel,
  infoText,
  disableSubmit,
  icon,
  isSubmitting,
}: ModalButtonsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles.ModalButtons}>
      <div className={styles.ModalButtons_Container}>
        <div className={styles.ModalButtons_InfoBanner}>
          {onDelete && (
            <div className={styles.ModalButtons_Buttons_LeftButtons}>
              <div className={styles.ModalButtons_Buttons_LeftButtons__delete}>
                <Button
                  danger
                  secondary
                  marginRight
                  icon={`fal fa-trash-alt`}
                  onClick={() => {
                    if (onDelete) {
                      onDelete()
                    }
                  }}
                >
                  {submitLabel ?? t('Remove')}
                </Button>
              </div>
            </div>
          )}

          {infoText && <InfoBanner style="warning" text={infoText} />}
        </div>
        <div>
          <div className={styles.ModalButtons_Buttons}>
            <div className={styles.ModalButtons_Buttons_RightButtons__cancel}>
              <Button
                marginLeft
                marginRight
                secondary
                onClick={() => {
                  if (onCancel) {
                    onCancel()
                  }
                }}
              >
                {cancelLabel ?? t('Cancel')}
              </Button>
            </div>
            <div className={styles.ModalButtons_Buttons_RightButtons__submit}>
              <Tooltip title={disableSubmit ? t('Fill in all mandatory fields (marked with *) in order to save') : ''} placement='top-end' display='block' arrow>
                <span>
                  <Button
                    primary
                    icon={icon}
                    disabled={disableSubmit ?? false}
                    onClick={() => {
                      if (onSubmit && !isSubmitting) {
                        onSubmit()
                      } }}>
                    {isSubmitting
                      ? <CircularProgress className={styles.ModalButtons_Buttons__spinner} size={16} color="inherit" />
                      : submitLabel ?? t('Save')}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
