import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { DigitalTwinHeatSupplier, useDigitalTwinHeatSuppliers } from 'api/heatSuppliers/heatSuppliers.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import Datetime from 'utils/datetime/datetime'

import { Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


import useDigitalTwinHeatSupplierSettings from '../hooks/useDigitalTwinHeatSupplierSettings'

import DigitalTwinHeatSupplierTabs from './DigitalTwinHeatSupplierTabs'
import DigitalTwinHeatSuppliersHeader from './DigitalTwinHeatSuppliersHeader'

export default function DigitalTwinHeatSuppliers(): ReactElement {
  const { t } = useTranslation()

  const [selectedHeatSupplier, setSelectedHeatSupplier] = useState<DigitalTwinHeatSupplier>()
  const [selectedDate, setSelectedDate] = useState(moment().add(1, `d`))

  const { data: heatSuppliers, status: heatSupplierStatus } = useDigitalTwinHeatSuppliers()
  const orderedSuppliers = useMemo(
    () => heatSuppliers?.sort((a, b) => a.display_name.localeCompare(b.display_name)),
    [heatSuppliers]
  )

  const selectedDateHeatSupplierSettingParams = useMemo(() => {
    const selectedIsoDate = Datetime.toISOString(selectedDate)
    return {
      activeFrom: Datetime.getStartOfDay(selectedIsoDate),
      activeTo: Datetime.getEndOfDay(selectedIsoDate),
      attributes: ['availability'],
      heatSupplier: selectedHeatSupplier,
    }
  }, [selectedDate, selectedHeatSupplier])

  const {
    fetchSettingsStatus,
    settings: selectedDateSettings,
    fetchSettings: refetchSelectedDateSettings,
  } = useDigitalTwinHeatSupplierSettings(selectedDateHeatSupplierSettingParams)

  useEffect(() => setSelectedHeatSupplier(orderedSuppliers?.at(0)), [orderedSuppliers])

  if (heatSupplierStatus === 'loading' || orderedSuppliers === undefined || selectedHeatSupplier === undefined) {
    return <LoadingPlaceholderContainer />
  }

  if (orderedSuppliers.length === 0) {
    return <Typography variant="h2">{t(`There are no suppliers registered on this system`)}</Typography>
  }

  return (
    <>
      <DigitalTwinHeatSuppliersHeader
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        heatSupplier={selectedHeatSupplier}
        fetchSettingsStatus={fetchSettingsStatus}
        settings={selectedDateSettings}
      />
      <DigitalTwinHeatSupplierTabs
        selectedDate={selectedDate}
        heatSuppliers={orderedSuppliers}
        heatSupplier={selectedHeatSupplier}
        setSelectedHeatSupplier={setSelectedHeatSupplier}
        refetchSelectedDateSettings={refetchSelectedDateSettings}
      />
    </>
  )
}
