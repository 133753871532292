import React, { ReactElement, useState } from 'react'

import { useObjectProperties, useObjectProperty } from 'api/objectProperties/objectProperties.api'
import { Unit } from 'api/units/units.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import ObjectPropertyFormSelector from 'ui/components/ObjectPropertyFormSelector/ObjectPropertyFormSelector'
import { DefaultValues } from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/ObjectPropertySettingForm'
import datetime from 'utils/datetime/datetime'

import { useTranslation } from 'react-i18next'

import NoPermissionErrorMessage from '../ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import { SettingsPermissions } from '../SettingsTable/SettingsTable'
import { getDisplayName } from 'helpers/global.helper/global.helper'
import { getOptimizationPeriod } from 'helpers/optimize.helper/optimize.helper'
import { DateTimePickerRange } from 'helpers/settingsModal.helper/settingsModal.helper'

import PropertyMenu, { PropertyChoice } from './components/PropertyMenu/PropertyMenu'

export type UnitSettingFormData = DefaultValues & {
  objectProperty: number
}

type UnitSettingFormProps = {
  permissions: SettingsPermissions
  isEditing?: boolean
  unit: Unit
  defaultValues?: Partial<UnitSettingFormData>
  startEndTimeRange?: DateTimePickerRange
  setIsDirty?: (arg: boolean) => void
  onFormFinished: () => void
  period?: {startTime: ISODateTime, endTime: ISODateTime},
}

function UnitSettingForm({
  permissions,
  startEndTimeRange,
  isEditing,
  unit,
  defaultValues,
  setIsDirty,
  onFormFinished,
  period,
}: UnitSettingFormProps): ReactElement {
  const { t } = useTranslation()

  const [prop, setProp] = useState<number | undefined>(defaultValues?.objectProperty ?? undefined)
  const { data: objectProperties = [], isLoading } = useObjectProperties({ classification: `deviation` })
  const { startTime, endTime } = period ?? getOptimizationPeriod()
  const { data: objectProperty } = useObjectProperty({
    id: prop,
    start_time: period 
      ? startTime as ISODateTime
      : datetime.toISOString(startTime),
    end_time: period 
      ? endTime as ISODateTime
      : datetime.toISOString(startTime),
  })

  const choices: PropertyChoice[] = objectProperties
    .reduce<PropertyChoice[]>(
      (acc, item) =>
        unit.props.includes(item.id) ? [...acc, { name: getDisplayName(item), value: item.id }] : acc,
      []
    )
    .sort((a, b) => getDisplayName(a).localeCompare(getDisplayName(b)))

  return (
    isLoading ? (<LoadingPlaceholderContainer />)
      : (<>
        { !permissions.canEdit ? <NoPermissionErrorMessage title={t(`Unfortunately, you do not have permission to make changes here`)} message={t('Unfortunately, you do not have permission to change settings on this user/facility')} /> : <></>}
        <PropertyMenu
          disabled={isEditing ? true : false}
          value={prop}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setProp(Number(event.target.value))}
          choices={choices}
        />
        <ObjectPropertyFormSelector
          objectProperty={objectProperty}
          ObjectPropertySettingFormProps={{
            setIsDirty: setIsDirty,
            onFormFinished: onFormFinished,
            defaultValues: defaultValues,
            startEndTimeRange: startEndTimeRange,
            permissions: permissions,
          }}
        />
      </>)
  )
}

export default UnitSettingForm
