import React, { ReactElement, useEffect, useMemo, useRef, useState, useCallback } from 'react'

import { LogBookPost, useGetLogBookPosts } from 'api/logBook/logBook.api'
import theme from 'styles/theme/theme'
import { Button, TextField } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'
import { FormDatePickerPeriod } from 'ui/molecules/pickers/DatePicker/FormDatePickerPeriod'
import Datetime from 'utils/datetime/datetime'

import { Box, createTheme, Dialog, DialogContent, StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material'
import MaterialReactTable, { MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'

import CreateLogBookPostModal from 'views/LogBookView/LogBookPostModal/LogBookPostModal'

import styles from './LogBookFlow.module.less'

export default function LogBookFlow(): ReactElement {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState<Date>(moment().startOf('month').subtract(6, 'months').toDate())
  const [endDate, setEndDate] = useState<Date>(moment().endOf('month').toDate())
  const { systemId } = useAuth()
  const [queryString, setQueryString] = useState<string>('')
  const [queryStringFieldValue, setQueryStringFieldValue] = useState<string>('')

  const logBookPostData =
    useGetLogBookPosts({ systemId, createdFrom: startDate, createdTo: endDate, queryString: queryString }).data || []

  const [selectedLogBookPost, setSelectedLogBookPost] = useState<LogBookPost | undefined>(undefined)

  const handleRowClick = (original: LogBookPost): void => {
    setSelectedLogBookPost(undefined)
    setTimeout(() => {
      setSelectedLogBookPost(original)
      setModalOpen(true)
    }, 0)
  }

  const CommentCell = ({ cell }: { cell: MRT_Cell<Record<string, unknown>> }): JSX.Element => {
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [openCommentModal, setOpenCommentModal] = useState(false)
    const cellRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const checkOverflow = (): void => {
        if (cellRef.current) {
          setIsOverflowing(cellRef.current.scrollHeight > cellRef.current.clientHeight)
        }
      }
      checkOverflow()
    }, [cell])

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            ref={cellRef}
            sx={{
              maxHeight: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {cell.getValue<string>()}
          </Box>
          {isOverflowing && (
            <>
              <TooltipIconButton
                icon={'fas fa-comment-dots'}
                tooltip={t('Show full comment')}
                iconColor="#016b80"
                iconSize="small"
                onClick={() => setOpenCommentModal(true)}
              />
              <Dialog open={openCommentModal} onClose={() => setOpenCommentModal(false)} title={t('Comment')}>
                <DialogContent>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {cell.getValue<string>()}
                    <TooltipIconButton
                      icon={'fad fa-times-circle'}
                      tooltip={t('Close')}
                      iconColor="red"
                      iconSize="small"
                      onClick={() => setOpenCommentModal(false)}
                    />
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      </>
    )
  }

  const [columns] = useState<MRT_ColumnDef<Record<string, unknown>>[]>([
    {
      accessorKey: 'category',
      header: t('Category'),
      Cell: ({ cell }) => {
        const categoryValue = cell.getValue<string>()
        return <Box sx={{ display: 'inline' }}>{t(categoryValue)}</Box>
      },
      size: 30,
    },
    {
      accessorKey: 'created_at',
      header: t('Created') + '/' + t('Edited'),
      Cell: ({ row }) => {
        const createdAt = row.original.created_at
        const updatedAt = row.original.updated_at
        const displayDate = updatedAt ? updatedAt : createdAt
        return <Box>{Datetime.toLocalTime(displayDate as ISODateTime)}</Box>
      },
      size: 60,
    },
    {
      accessorKey: 'content',
      header: t('Comments'),
      Cell: CommentCell,
      minSize: 70,
    },
    {
      accessorKey: 'is_reported',
      header: t('Reported'),
      Cell: ({ cell }) => <Box>{cell.getValue<boolean>() ? t('Yes') : t('No')}</Box>,
      size: 50,
    },
    {
      accessorKey: 'written_by',
      header: t('Signed by'),
      Cell: ({ cell }) => <Box>{cell.getValue<string>()}</Box>,
      size: 50,
    },
    {
      accessorKey: 'updated_written_by',
      header: t('Last edited by'),
      Cell: ({ cell }) => <Box>{cell.getValue<string>()}</Box>,
      size: 70,
    },
    {
      accessorKey: 'edit',
      header: t('Manage'),
      Cell: ({ cell }) => (
        <Box>
          <Button onClick={() => handleRowClick(cell.row.original as LogBookPost)} primary>
            {t('Edit')}
          </Button>
        </Box>
      ),
      size: 40,
    },
  ])

  const globalTheme = useTheme()
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: theme.palette.primary,
          secondary: theme.palette.secondary,
          info: theme.palette.info,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalTheme]
  )

  const updateLogBookFilters = useCallback(
    (period: Period): void => {
      setStartDate(period.startTime)
      setEndDate(period.endTime)
      setQueryString(queryStringFieldValue)
    },
    [queryStringFieldValue]
  )

  return (
    <>
      <div className={styles.LogBookFlow}>
        <TextField
          label={t('Search')}
          variant="outlined"
          value={queryStringFieldValue}
          onChange={(s) => setQueryStringFieldValue(`${s}`)}
          onEnter={() => updateLogBookFilters({ startTime: startDate, endTime: endDate })}
        />
        <FormDatePickerPeriod
          granularity="day"
          defaultPeriod={{
            startTime: startDate,
            endTime: endDate,
          }}
          onSubmit={updateLogBookFilters}
          submitLabel={t('Filter log posts')}
        />
      </div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <div style={{ width, overflowX: `scroll` }}>
            <div>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={logBookPostData}
                    enableColumnActions={false}
                    enableStickyHeader
                    enableColumnResizing
                    enableGlobalFilter={false}
                    enableColumnDragging={false}
                    paginateExpandedRows={false}
                    initialState={{
                      columnOrder: [
                        'category',
                        'created_at',
                        'content',
                        'is_reported',
                        'written_by',
                        'updated_written_by',
                        'edit',
                      ],
                      sorting: [{ id: 'created_at', desc: true }],
                    }}
                  />
                </ThemeProvider>
              </StyledEngineProvider>
              <CreateLogBookPostModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                edit={true}
                log_post={selectedLogBookPost}
              />
            </div>
          </div>
        )}
      </AutoSizer>
    </>
  )
}
