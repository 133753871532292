import React, { ReactElement, useState } from 'react'

import { dynamicClassName } from 'styles/helper'
import Button from 'ui/atoms/Button/Button'
import Icon from 'ui/atoms/Icon/Icon'

import styles from './RedirectSnackbar.module.less'

type RedirectSnackbarProps = {
  text?: string
  redirectButtonText?: string
  stayButtonText?: string
  onStayButtonClick?: () => void
  onRedirectButtonClick?: () => void
  children?: ReactElement
  style?: 'warning' | 'info' | 'meetup'
  systemId?: number
  name?: string
}

export default function RedirectSnackbar({
  text,
  onStayButtonClick,
  onRedirectButtonClick,
  redirectButtonText,
  stayButtonText,
  children,
  style,
  systemId,
  name,
}: RedirectSnackbarProps): ReactElement {
  const snackbarHasBeenClosed = JSON.parse(window.localStorage.getItem(`snackbar-closed-system-${systemId}`) || '[]').includes(name)
  const [isVisible, setVisibility] = useState<boolean>(snackbarHasBeenClosed ? false : true)

  if (!isVisible) {
    return <></>
  }

  return (
    <div
      className={dynamicClassName({
        [styles.RedirectSnackbar]: true,
        [styles.RedirectSnackbar__info]: style === 'info' || !style,
        [styles.RedirectSnackbar__meetup]: style === 'meetup',
        [styles.RedirectSnackbar__warning]: style === 'warning',
      })}
    >
      {text && <div className={styles.RedirectSnackbar_Message}>{text}</div>}
      {children}
      {(stayButtonText || redirectButtonText) && (
        <div className={styles.RedirectSnackbar_ButtonContainer}>
          {stayButtonText && (
            <Button
              link
              onClick={() => {
                setVisibility(false)
                if (onStayButtonClick) {
                  onStayButtonClick()
                }
              }}
            >
              {stayButtonText}
            </Button>
          )}
          {redirectButtonText && (
            <Button link danger onClick={onRedirectButtonClick}>
              {redirectButtonText}
            </Button>
          )}
        </div>
      )}
      <div className={styles.RedirectSnackbar_Close}>
        <Icon
          icon={`fal fa-times`}
          color={style === 'meetup' ? `white` : `black`}
          onClick={() => {
            setVisibility(false)
            const items = JSON.stringify([...JSON.parse(window.localStorage.getItem(`snackbar-closed-system-${systemId}`) || '[]'), name])
            window.localStorage.setItem(`snackbar-closed-system-${systemId}`, items)
          }}
        />
      </div>
    </div>
  )
}
