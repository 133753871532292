import React, { useState, useEffect, ReactElement, useMemo } from 'react'

import { getOptjobAsObject, OptJobType, useLatestOptJobStatus } from 'api/optJobs/optJobs.api'
import { postStat } from 'api/stats/stats.api'
import { useSystem, useSystemAutomaticOptimizationToggleMutation } from 'api/systems/systems.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { SubtypeObject } from 'ui/components/NotificationCenter/NotificationCenter.helper'
import OptimizationFailedGuide from 'ui/components/NotificationCenter/OptimizationFailedGuide'
import NoPermissionErrorMessage from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import { OptimizeButton } from 'ui/components/OptimizeFormProvider/components/OptimizeButton/OptimizeButton'
import OptimizeWithSubtypes from 'ui/components/OptimizeWithSubTypes/OptimizeWithSubTypes'
import Tooltip from 'ui/components/Tooltip/Tooltip'
import Datetime from 'utils/datetime/datetime'

import { Typography, FormControlLabel, Checkbox } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getLanguage } from 'localization/localization.helper'
import SubtypeOptJobInfoBox from 'views/OptimizeView/components/SubtypeOptJobInfoBox/SubtypeOptJobInfoBox'

import styles from './OptimizeUpdateContent.module.less'

type OptimizeUpdateContentProps = {
  creatingOptJob?: boolean
  enableAutomaticOptimization: boolean
  isValid?: boolean
  onOptimize?: () => void
  permission: UserPermission
  type: OptJobType
}

export default function OptimizeUpdateContent({
  creatingOptJob,
  enableAutomaticOptimization,
  isValid,
  onOptimize,
  permission,
  type,
}: OptimizeUpdateContentProps): ReactElement {
  const { t } = useTranslation()
  const { systemId, hasAccess } = useAuth()
  const { data: activeSystem } = useSystem({ id: systemId })

  const automaticOptStatus = activeSystem?.automatic_optimization ?? false

  const [updatedAtFromNow, setUpdatedAtFromNow] = useState(``)

  const hasRunRegularOptAccess = useHasPermission(permission)

  const { data: latestFinishedOptJob } = useLatestOptJobStatus({ type: type, status: `Finished` })
  const firstLatestFinishedOptJob = getOptjobAsObject(latestFinishedOptJob)
  const { data: latestFinishedRegularOptJobData } = useLatestOptJobStatus({ type: 'regular', status: `Finished` })

  //To fetch whether or not the latest optimization has failed or not
  const { data: latestOptJob } = useLatestOptJobStatus({ type })
  const firstLatestOptJob = getOptjobAsObject(latestOptJob)

  const language = getLanguage()
  useEffect(() => {
    if (firstLatestFinishedOptJob && firstLatestFinishedOptJob.updated_at) {
      const time = moment(firstLatestFinishedOptJob.updated_at).locale(moment.locale(language)).fromNow()
      setUpdatedAtFromNow(time)
      const timeInterval = setInterval(
        () =>
          setUpdatedAtFromNow(moment(firstLatestFinishedOptJob.updated_at).locale(moment.locale(language)).fromNow()),
        60000
      )
      return () => clearInterval(timeInterval)
    } else {
      setUpdatedAtFromNow(``)
    }
  }, [language, firstLatestFinishedOptJob])

  const { mutate: automaticOptimizationToggle } = useSystemAutomaticOptimizationToggleMutation()

  const firstLatestFinishedRegularOptJob = Array.isArray(latestFinishedRegularOptJobData)
    ? latestFinishedRegularOptJobData[0]
    : undefined
  const latestFinishedRegularOptJob = firstLatestFinishedRegularOptJob?.updated_at
  const hoursSinceLatestOptJob =
    Datetime.getNrOfHoursBetween(latestFinishedRegularOptJob as ISODateTime, Datetime.getISONow()) - 1

  const showInfobox =
    activeSystem?.primary_opt_model?.has_unused_settings ||
    activeSystem?.primary_digital_twin?.has_unused_settings ||
    hoursSinceLatestOptJob >= 1

  const showSystemSubtypes = useMemo(() => {
    if (!hasAccess({ submodule: 'optimize_subnet', module: 'optimize' })) {
      return false
    }

    return activeSystem?.subtypes?.[type]?.length && activeSystem?.subtypes?.[type]?.length > 0
  }, [activeSystem?.subtypes, hasAccess, type])

  const latestOptimizedSingleSubtype = firstLatestFinishedRegularOptJob?.subtypes?.length === 1
  const subtypes = firstLatestFinishedRegularOptJob?.subtypes
    ?.map((subtype: SubtypeObject) => subtype.display_name ?? subtype.name)

  let latestOptimizedSubtypeName = ''

  if (subtypes) {
    if (subtypes.length === 1) {
      latestOptimizedSubtypeName = subtypes[0]
    } else if (subtypes.length === 2) {
      latestOptimizedSubtypeName = subtypes.join(' & ')
    } else if (subtypes.length > 2) {
      latestOptimizedSubtypeName = `${subtypes.slice(0, -1).join(', ')} & ${subtypes[subtypes.length - 1]}`
    }
  }

  let optimizeButtonTooltip = ''

  if (updatedAtFromNow !== ``) {
    if (showSystemSubtypes) {
      optimizeButtonTooltip = t('Last updated for {{subtype}} {{updatedAtFromNow}}', { subtype: latestOptimizedSubtypeName, updatedAtFromNow: updatedAtFromNow })
    } else {
      optimizeButtonTooltip = t('Last updated {{updatedAtFromNow}}', { updatedAtFromNow: updatedAtFromNow })
    }
  }

  return (
    <div className={styles.OptimizeUpdateContent_UpdateContainer}>
      {enableAutomaticOptimization && (
        <div className={styles.OptimizeUpdateContent_UpdateAutomaticallyGrid}>
          <div className={styles.OptimizeUpdateContent_UpdateAutomaticallyContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={automaticOptStatus}
                  onChange={() => {
                    if (activeSystem) {
                      automaticOptimizationToggle({ id: activeSystem.id })
                      postStat(`automatic-optimization-toggle`, `click`)
                    }
                  }}
                  value="updateAutomatically"
                />
              }
              className={styles.OptimizeUpdateContent_UpdateAutomaticallyLabel}
              labelPlacement="end"
              label={
                <Typography variant="body1" className={styles.OptimizeUpdateContent_UpdateAutomaticallyLabel}>
                  {t(`Update automatically`)}
                </Typography>
              }
            />
          </div>
        </div>
      )}
      {firstLatestOptJob?.status === 'Failed' && 
        <OptimizationFailedGuide startTime={Datetime.getISONow()} endTime={Datetime.getDateOneWeekAhead()} popup />
      }
      <div className={styles.OptimizeUpdateContent_OptimizeButtonContainer}>
        <Tooltip
          title={optimizeButtonTooltip}
          arrow
          placement='left'
          display="block"
        >
          <div>
            {showSystemSubtypes ? (
              <OptimizeWithSubtypes
                isValid={isValid}
                permission={permission}
                type={type || 'regular'}
                optimizeAllSubtypes={onOptimize}
                creatingOptJob={creatingOptJob}
              />
            ) : (
              <OptimizeButton
                type={type}
                disabled={!hasRunRegularOptAccess}
                onClick={onOptimize}
                isValid={isValid}
                style={{ minWidth: '130px' }}
                creatingOptJob={creatingOptJob}
              />
            )}
          </div>
        </Tooltip>
        {!hasRunRegularOptAccess && (
          <NoPermissionErrorMessage
            title={t(`You do not have permission to optimize`)}
            message={t('Unfortunately, you do not have permission to start an optimization on this user/facility')}
          />
        )}
        {showSystemSubtypes && latestOptimizedSingleSubtype && (
          <SubtypeOptJobInfoBox subtypeName={latestOptimizedSubtypeName} />
        )}
        {showInfobox ? (
          <div
            className={
              hoursSinceLatestOptJob >= 12
                ? styles.OptimizeUpdateContent_NeedsUpdateFieldRed
                : styles.OptimizeUpdateContent_NeedsUpdateFieldYellow
            }
          >
            <Tooltip
              title={t(`Start a new optimization to use the latest settings`)}
              placement="left"
              arrow
              display="block"
            >
              <Typography variant="subtitle2" className={styles.OptimizeUpdateContent_NeedsUpdateText}>
                {t(`Optimization is outdated`)}
              </Typography>
            </Tooltip>

          </div>
        ) : null}
      </div>
    </div>
  )
}
