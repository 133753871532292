import React, { ReactElement } from 'react'

import { postStat } from 'api/stats/stats.api'
import TooltipIconButton from 'ui/components/TooltipIconButton/TooltipIconButton'

import { Menu, Icon, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'


import { exportToExcel } from 'helpers/dataset.helper/dataset.helper'

import styles from './ExportDatasetsMenu.module.less'

type ExportDatasetsMenuProps = {
  datasets: Dataset[]
  datasetNames: Record<string, string>
  onImageExport?: () => void
  onPrint?: () => void
}

export default function ExportDatasetsMenu({
  datasets,
  datasetNames,
  onImageExport,
  onPrint,
}: ExportDatasetsMenuProps): ReactElement {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  function handleClick(event: React.MouseEvent): void {
    setAnchorEl(event.currentTarget as HTMLButtonElement)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  return (
    <>
      <TooltipIconButton
        tooltip={t(`Export`)}
        icon={
          <Icon
            color="primary"
            className={styles.ExportDatasetsMenu_icon + ` fas fa-cloud-download-alt`}
            fontSize="small"
          />
        }
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.ExportDatasetsMenu}
      >
        <MenuItem
          onClick={() => {
            exportToExcel(datasets, datasetNames)
            postStat(`chart`, `export-to-excel`)
            handleClose()
          }}
          className={styles.ExportDatasetsMenuItem}
        >
          <Icon color="primary" className={styles.ExportDatasetsMenuItem_icon + ` far fa-file-excel`} />
          <Typography className={styles.ExportDatasetsMenuItem_text} variant="body1">
            {t(`Excel`)}
          </Typography>
        </MenuItem>

        {onImageExport && (
          <MenuItem
            onClick={() => {
              onImageExport()
              postStat(`chart`, `export-to-png`)
              handleClose()
            }}
            className={styles.ExportDatasetsMenuItem}
          >
            <Icon color="primary" className={styles.ExportDatasetsMenuItem_icon + ` far fa-file-image`} />
            <Typography className={styles.ExportDatasetsMenuItem_text} variant="body1">
              {t(`Image (PNG)`)}
            </Typography>
          </MenuItem>
        )}

        {onPrint && (
          <MenuItem
            onClick={() => {
              onPrint()
              postStat(`chart`, `export-to-print`)
              handleClose()
            }}
            className={styles.ExportDatasetsMenuItem}
          >
            <Icon color="primary" className={styles.ExportDatasetsMenuItem_icon + ` far fa-print`} />
            <Typography className={styles.ExportDatasetsMenuItem_text} variant="body1">
              {t(`Print`)}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
