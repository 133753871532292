import React, { ReactElement } from 'react'

import { Grid, Typography, Icon } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { formatDateTimeToLocalizedString } from 'helpers/dateTime.helper/dateTime.helper'

import styles from './SupplierSettingsItem.module.less'

type SupplierSettingsItemProps = {
  setting: {
    start_time: string | null
    end_time: string | null
    updated_at: string | null
    value: number
    creator_name?: string
  }
  supplierName?: string
}

export default function SupplierSettingsItem({ setting, supplierName }: SupplierSettingsItemProps): ReactElement {
  const { t } = useTranslation()

  const displayDate = setting.updated_at === null ? null : formatDateTimeToLocalizedString(setting.updated_at)

  let timeAndCreatorLabel = ''
  if (displayDate && setting.creator_name) {
    timeAndCreatorLabel = `${displayDate} ${t(`by`)} ${setting.creator_name}`
  } else if (displayDate) {
    timeAndCreatorLabel = `${displayDate}:`
  } else if (setting.creator_name) {
    timeAndCreatorLabel = `${setting.creator_name}:`
  }

  const value = setting.value === 0 ? t(`No`) : t(`Yes`)
  return (
    <Grid item container alignItems="center" className={styles.SupplierSettingsItem_Container}>
      <Grid item>
        <Icon
          className={classNames(`fas fa-exclamation-circle`, styles.SupplierSettingsItem_Icon)}
          fontSize="inherit"
        />
      </Grid>
      <Grid item>
        <Typography style={{ fontStyle: 'bold' }}>{timeAndCreatorLabel}</Typography>
        <Typography>
          <b>{value}</b>
          {` `}
          {supplierName && t(` for delivery from {{supplier}} during following period: `, { supplier: supplierName })}
          <b>
            {moment(setting.start_time ?? moment()).format(`ddd D MMMM HH:mm`) + ` - `}
            {setting.end_time === null
              ? t('Until further notice')
              : moment(setting.end_time).format(`ddd D MMMM HH:mm`)}
          </b>
        </Typography>
      </Grid>
    </Grid>
  )
}
