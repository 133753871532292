import React, { ReactElement } from 'react'

import { useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { useSettings } from 'api/settings/settings.api'
import { SettingsListItem } from 'ui/components/SettingListItem/SettingListItem'
import SettingsDropdown from 'ui/components/SettingsDropdown/SettingsDropdown'

import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getDisplayName, prettyPrintAnySettingValue } from 'helpers/global.helper/global.helper'

type DeviationSettingsDropdownProps = {
  period: Period
  unit: number
}

function DeviationSettingsDropdown({ period, unit }: DeviationSettingsDropdownProps): ReactElement {
  const { t } = useTranslation()

  const { data: objectProperties } = useObjectProperties()
  const { data: settings } = useSettings({
    params: {
      active_from: period.startTime.format(),
      active_to: period.endTime.format(),
    },
  })

  const filteredProperties =
    objectProperties?.filter((prop) => prop?.parent?.type === `unit` && prop?.parent?.id === unit) ?? []

  const filteredSettings = settings?.filter((setting) => {
    const property = filteredProperties && filteredProperties.find((prop) => setting.object_property === prop.id)
    if (property && !setting.is_base_value) {
      return property.classifications.includes(`deviation`)
    }
    return false
  })

  const settingsListLength = filteredSettings?.length ?? 0

  return (
    <Grid item container alignItems="center" justifyContent="center" xs={12}>
      {settingsListLength === 0 || filteredSettings === undefined ? (
        <Typography variant="body1">{t(`No active deviation settings found for this period.`)}</Typography>
      ) : (
        <>
          {settingsListLength <= 1 ? (
            filteredSettings.map((setting) => {
              const prop = filteredProperties.find((prop) => setting.object_property === prop.id)
              if (prop) {
                const propertyName = getDisplayName(prop)

                return (
                  <Grid item key={setting.id}>
                    <SettingsListItem
                      key={setting.id}
                      name={[propertyName, (prop.parent ? getDisplayName(prop.parent) : '-')]}
                      startTime={setting.start_time}
                      endTime={setting.end_time}
                      value={prettyPrintAnySettingValue(prop, setting).toString()}
                      measurementUnit={prop.measurement_unit}
                      comment={setting.comment}
                    />
                  </Grid>
                )
              }
              return null
            })
          ) : (
            <Grid item>
              <SettingsDropdown
                customButtonTitle={t(`{{number}} deviation settings this period`, { number: settingsListLength })}
              >
                {filteredSettings.map((setting) => {
                  const prop = filteredProperties.find((prop) => setting.object_property === prop.id)
                  if (prop) {
                    const propertyName = getDisplayName(prop)

                    return (
                      <Grid key={setting.id} item>
                        <SettingsListItem
                          name={[propertyName, (prop.parent ? getDisplayName(prop.parent) : '-')]}
                          startTime={setting.start_time}
                          endTime={setting.end_time}
                          value={prettyPrintAnySettingValue(prop, setting).toString()}
                          measurementUnit={prop.measurement_unit}
                          comment={setting.comment}
                        />
                      </Grid>
                    )
                  }
                  return null
                })}
              </SettingsDropdown>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default DeviationSettingsDropdown
