import React, {
  ReactElement,
  useMemo,
  useRef,
} from 'react'

import {
  DigitalTwinSettingCreationRequest,
  DeviationSettingLevel,
  DigitalTwinTranslations,
  DigitalTwinPropertyObject,
  DigitalTwin,
  ConfigurableProperty,
} from 'api/digitalTwin/digitalTwin.api'
import { UnitSettingFormData } from 'ui/components/UnitSettingForm/UnitSettingForm'
import Datetime from 'utils/datetime/datetime'

import { DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DigitalTwinSettingForm from '../DigitalTwinSettingForm/DigitalTwinSettingForm'
import { DigitalTwinSettingsAutocompleteRow } from '../DigitalTwinSettingsAutocompleteRow/DigitalTwinSettingsAutocompleteRow'
import { useHasPermission } from 'helpers/global.helper/global.helper'
import { getDateTimePickerRangesFromPermissions } from 'helpers/settingsModal.helper/settingsModal.helper'
import { getPrettyName } from 'views/DigitalTwinSettingsView/DigitalTwinSettingsView.helper'
import { EventPost } from 'views/EventsView/components/events.helper'

export type CreateSettingData = {
  isTimeSeries: boolean
  start_time?: ISODateTime | null
  end_time?: ISODateTime | null
  level?: DeviationSettingLevel
  name?: string
  priority?: number
  comment?: string
  attribute?: string
  value?: number | null | { id?: number; value: number | null }[]
  id?: number
  operational_event?: number | null
}

type DigitalTwinSettingDialogContentProps = {
  defaultValues?: Partial<UnitSettingFormData>
  propertyItems: DigitalTwinPropertyObject[]
  digitalTwin: DigitalTwin
  allowEmpty: boolean
  priorities: SystemSettingPriorityLevel[]
  baseSettingPriorities?: SystemSettingPriorityLevel[]
  hasUnitAndProperty: boolean
  isBaseSettings?: boolean,
  operationalEvent?: EventPost | null
  operationalEventId?: number | null
  disableAll?: boolean
  propertySelected: boolean
  newSetting: DigitalTwinSettingCreationRequest
  invalidValuesIndex: number[]
  configurableProperties: DigitalTwinPropertyObject[]
  activeConfigurableProperty?: ConfigurableProperty
  valueHelperText?: string
  initData?: CreateSettingData | null
  updateNewSetting: (data: DigitalTwinSettingCreationRequest) => void
}

export type Choices = {
  name: string
  value: string
}

export function getAutocompleteValueForProperty(
  attribute: string,
  translations?: DigitalTwinTranslations
): { name: string; value: string } {
  return {
    name: getPrettyName(attribute, translations),
    value: attribute,
  }
}

export function getAutocompleteValueForModelEntityName(
  name: string,
  translations?: DigitalTwinTranslations
): { name: string; value: string } {
  return {
    name: getPrettyName(name, translations),
    value: name,
  }
}



// If the time range is changed, we need to update the values so they contain values
// for the parts of the range that were not previously included.
export function getDefaultValuesWhenTimeChanged(
  input: DigitalTwinSettingCreationRequest,
  startTime: ISODateTime,
  endTime: ISODateTime
): number | null | { [time: ISODateTime]: { id?: number; value: number | null } } {
  return Datetime.getHoursBetween(startTime, endTime)
    .map((time, index) => ({ time, index }))
    .reduce(
      (
        accumulated: { [time: ISODateTime]: { id?: number; value: number | null } },
        newEntry: { time: ISODateTime; index: number }
      ) => {
        accumulated[newEntry.time] =
          input.value && typeof input.value === 'object' && newEntry.time in input.value
            ? (input.value[newEntry.time] ?? { value: null })
            : { value: null }
        return accumulated
      },
      {}
    )
}
export function getNullValuesForSettingData(
  startTime: ISODateTime,
  endTime: ISODateTime
): { [time: ISODateTime]: { value: null } } {
  return Datetime.getHoursBetween(startTime, endTime)
    .reduce(
      (
        accumulated: { [time: ISODateTime]: { value: null } },
        time: ISODateTime
      ) => {
        accumulated[time] = { value: null }
        return accumulated
      },
      {}
    )
}


function updateNewSettingWithData({ isTimeSeries, newSettingData, overrideAttributes }: { isTimeSeries: boolean; newSettingData: DigitalTwinSettingCreationRequest; overrideAttributes: Partial<DigitalTwinSettingCreationRequest> }): DigitalTwinSettingCreationRequest {
  const updatedSetting = {
    ...newSettingData,
    ...overrideAttributes,
  }

  if (isTimeSeries) {
    updatedSetting.value = newSettingData.start_time && newSettingData.end_time ? getNullValuesForSettingData(newSettingData.start_time, newSettingData.end_time) : {} // reset values for category and asset if it has changed
  }

  return updatedSetting
}

export default function DigitalTwinSettingDialogContent({
  propertyItems,
  digitalTwin,
  hasUnitAndProperty,
  priorities,
  baseSettingPriorities,
  newSetting,
  configurableProperties,
  invalidValuesIndex,
  updateNewSetting,
  activeConfigurableProperty,
  valueHelperText,
  initData,
  operationalEvent = null,
  disableAll = false,
}: DigitalTwinSettingDialogContentProps): ReactElement {
  const { t } = useTranslation()

  const propertySelected = !!newSetting.attribute
  const nameSelected = !!newSetting.name

  const units = useMemo(
    () =>
      configurableProperties.reduce<{ attribute: string; unit: string | undefined }[]>((acc, p) => {
        if (!acc.some((item) => item.attribute === p.attribute)) {
          acc.push({ attribute: p.attribute, unit: p.measurement_unit })
        }
        return acc
      }, []),
    [configurableProperties]
  )

  const dialogContentRef = useRef<HTMLDivElement>(null)
  const canEditHistoric = useHasPermission(`change_historic_plan_settings`)

  const startEndTimeRange = useMemo(() => {
    const range = getDateTimePickerRangesFromPermissions(canEditHistoric)

    if (newSetting.start_time) {
      range.endTime.min = newSetting.start_time
    }

    return range
  }, [canEditHistoric, newSetting.start_time])

  const { typeChoices, unitChoices } = useMemo(() => {
    const typeChoices: Choices[] = []
    const unitChoices: Choices[] = []

    if (propertyItems) {
      for (const unit of propertyItems) {
        if (unit.level && !typeChoices.some((c) => c.value === unit.level)) {
          typeChoices.push({
            name: getPrettyName(unit.level, digitalTwin?.translations, true),
            value: unit.level,
          })
        }

        const unitProperties = configurableProperties.filter((p) => p.name === unit.name)
        if (
          unit.level === newSetting.level &&
          !unit.tags?.includes('exclude') &&
          unitProperties.length > 0 // User is not able to create settings for a unit that has no available properties
        ) {
          unitChoices.push(getAutocompleteValueForModelEntityName(unit.name, digitalTwin?.translations))
        }
      }
    }

    typeChoices.sort((a, b) => a.name.localeCompare(b.name))
    unitChoices.sort((a, b) => a.name.localeCompare(b.name))

    return { typeChoices, unitChoices }
  }, [configurableProperties, digitalTwin?.translations, newSetting.level, propertyItems])

  const propertyChoices = useMemo(
    () =>
      (configurableProperties ?? [])
        .filter((p) => p.name === newSetting.name && !p.tags?.includes('exclude'))
        .map((p) => getAutocompleteValueForProperty(p.attribute, digitalTwin?.translations))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [configurableProperties, digitalTwin?.translations, newSetting.name]
  )

  if (!digitalTwin || !initData) {
    return <></>
  }

  const isEditingExistingSetting = initData.id !== undefined
  
  return (
    <>
      <DialogContent ref={dialogContentRef}>
        {!hasUnitAndProperty && (
          <div>
            <DigitalTwinSettingsAutocompleteRow
              disabled={disableAll || isEditingExistingSetting}
              required
              icon="fal fa-pen"
              label={t(`Category`)}
              id="category"
              value={{
                name: getPrettyName(newSetting?.level, digitalTwin?.translations, true),
                value: newSetting.level,
              }}
              onChange={(value) => {
                const updatedSetting = updateNewSettingWithData({ isTimeSeries: newSetting.isTimeSeries, newSettingData: newSetting, overrideAttributes: { name: '', level: value as DeviationSettingLevel } })
                updateNewSetting(updatedSetting)
              }}
              choices={typeChoices}
            />
            <DigitalTwinSettingsAutocompleteRow
              disabled={disableAll || isEditingExistingSetting}
              required
              label={t(`Asset`)}
              id="item"
              value={
                newSetting.name === ''
                  ? null
                  : getAutocompleteValueForModelEntityName(newSetting.name, digitalTwin?.translations)
              }
              onChange={(value) => {
                const updatedSetting = updateNewSettingWithData({ isTimeSeries: newSetting.isTimeSeries, newSettingData: newSetting, overrideAttributes: { name: value ?? '', attribute: '' } })
                updateNewSetting(updatedSetting)
              }}
              choices={unitChoices}
            />
          </div>
        )}
        {nameSelected && !hasUnitAndProperty && (
          <DigitalTwinSettingsAutocompleteRow
            required
            disabled={disableAll || isEditingExistingSetting}
            icon="fal fa-cogs"
            label={t(`Property`)}
            id="objectProperty"
            value={
              newSetting.attribute === ''
                ? null
                : getAutocompleteValueForProperty(newSetting.attribute, digitalTwin?.translations)
            }
            onChange={(value) => {
              const attribute = value ?? ''
              const updatedSetting = {
                ...newSetting,
                attribute,
                unit: units.find((unit) => unit.attribute === attribute)?.unit ?? '',
              }
              if (attribute === 'forced' && !newSetting.isTimeSeries) {
                updatedSetting.value = 1
              }
              if (attribute === 'availability' && !newSetting.isTimeSeries) {
                updatedSetting.value = 0
              }

              // If the new property does not have the same priorities as the old one, set the priority to the first available one.
              // Use the priority order from the priorities prop, not from the available_priorities.
              const newActiveProperty = configurableProperties.find(
                (p) => p.name === newSetting.name && p.attribute === attribute
              )
              updatedSetting['priority'] = (
                priorities.find((p) => newActiveProperty?.available_priorities?.includes(p.priority_level)) ??
                priorities[0]
              ).priority_level

              updateNewSetting(updatedSetting)
            }}
            choices={propertyChoices}
          />
        )}
        {nameSelected && propertySelected && (
          <DigitalTwinSettingForm
            settingData={newSetting}
            setSettingData={updateNewSetting}
            priorities={priorities.filter((p) =>
              activeConfigurableProperty?.available_priorities?.includes(p.priority_level)
            )}
            baseSettingPriorities={baseSettingPriorities}
            startEndTimeRange={startEndTimeRange}
            isUpdate={initData.id !== undefined}
            activeSettingId={initData.id}
            errorHelperText={valueHelperText}
            activeConfigurableProperty={activeConfigurableProperty}
            dialogContentRef={dialogContentRef}
            invalidValuesIndex={invalidValuesIndex}
            digitalTwin={digitalTwin}
            operationalEvent={operationalEvent}
            disableAll={disableAll}
          />
        )}
      </DialogContent>
    </>
  )
}
