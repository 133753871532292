import React, { ReactElement, useState } from 'react'

import FilterButton from 'ui/components/EventLogger/components/FilterButton/FilterButton'

import { Popper, ClickAwayListener, MenuList, MenuItem, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'

import styles from './StartPageFilter.module.less'

type StartPageFilterProps = {
  onFilterClick?: () => void
  filterOnUserPosts: boolean
}

export default function StartPageFilter({ onFilterClick, filterOnUserPosts }: StartPageFilterProps): ReactElement {
  const { t } = useTranslation()

  const [anchorFilter, setAnchorFilter] = useState<(EventTarget & Element) | null | boolean>(null)
  const [open, setOpen] = useState(false)

  function handleToggle(): void {
    setOpen(!open)
  }

  const toggleFiltersBox = (event: React.MouseEvent): void => {
    setAnchorFilter((prev) => (prev ? null : event.currentTarget))
  }

  function filterWrapper(e: React.MouseEvent): void {
    toggleFiltersBox(e)
    handleToggle()
  }

  function handleChange(): void {
    filterOnUserPosts
    if (onFilterClick) {
      onFilterClick()
    }
  }
  return (
    <>
      <FilterButton onClick={(e) => filterWrapper(e)} text={t(`Filter`)} />
      <Popper
        placement="bottom-start"
        open={Boolean(anchorFilter)}
        anchorEl={anchorFilter}
        className={styles.StartPageFilter_SystemMenu}
        // container={node?.current} children={undefined}
      >
        <ClickAwayListener onClickAway={(e) => toggleFiltersBox(e)}>
          <MenuList>
            <MenuItem onClick={() => handleChange()}>
              <Checkbox checked={filterOnUserPosts} />
              {t(`Show only threads created by user`)}
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
