import React, { ReactElement, useEffect } from 'react'

import { ApiRequestStatus } from 'api/api.utils'
import {
  DeviationSettingLevel,
  DigitalTwinSetting,
  useCreateDigitalTwinSetting,
  useUpdateDigitalTwinSetting,
} from 'api/digitalTwin/digitalTwin.api'
import { DeclinedHours } from 'api/heatSuppliers/heatSuppliers.api'
import { LoadingPlaceholderContainer } from 'ui/atoms'
import ContainedIconButton from 'ui/components/ContainedIconButton/ContainedIconButton'
import NoPermissionErrorMessage from 'ui/components/ObjectPropertyFormSelector/components/ObjectPropertySettingForm/components/AddDialogActions/NoPermissionErrorMessage/NoPermissionErrorMessage'
import Datetime from 'utils/datetime/datetime'
import { getSettingValue } from 'utils/digitalTwinSettings/digitalTwinSettingUtils'

import { Grid, Icon, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getAvailableHoursFromSettings } from 'helpers/samEnergi.helper/samEnergi.helper'

import styles from './DigitalTwinAvailableHoursForm.module.less'
import SeasonTextField from './SeasonTextField'

export const AVAILABLE_HOURS_SETTINGS_PRIO = 0

type AvailableHoursFormData = {
  year: number
  unplannedStopSummer: number
  plannedStop: number
  unplannedStopWinter: number
}

type Period = `unplannedStopSummer` | `plannedStop` | `unplannedStopWinter`
const PERIODS: Period[] = [`unplannedStopSummer`, `plannedStop`, `unplannedStopWinter`]

const PERIOD_ATTRIBUTE_MAP = {
  unplannedStopSummer: 'available_hours_unplanned_summer',
  plannedStop: 'available_hours',
  unplannedStopWinter: 'available_hours_unplanned_winter',
}

export type ActiveSettingsRange = {
  activeFrom: ISODateTime | undefined
  activeTo: ISODateTime | undefined
}

export function getDigitalTwinAvailableHoursPeriod(year: number): ActiveSettingsRange {
  return {
    activeFrom: Datetime.toISOString(moment(year, 'YYYY').startOf('year').startOf('hour')),
    activeTo: Datetime.toISOString(moment(year, 'YYYY').endOf('year').startOf('hour')),
  }
}

export type DigitalTwinAvailableHoursFormProps = {
  declinedHours: DeclinedHours
  hasEditPermission: boolean
  onSettingsModified: () => void
  setIsDirty?: (arg: boolean) => void
  settings: DigitalTwinSetting[]
  digitalTwinUid: number
  heatSupplierUnitName: string
  year: number
  setYear: (year: number) => void
  fetchSettingsStatus: ApiRequestStatus
}

export default function DigitalTwinAvailableHoursForm({
  declinedHours,
  hasEditPermission,
  onSettingsModified,
  setIsDirty,
  settings,
  digitalTwinUid,
  heatSupplierUnitName,
  year,
  setYear,
  fetchSettingsStatus,
}: DigitalTwinAvailableHoursFormProps): ReactElement {
  const { t } = useTranslation()

  const { mutateAsync: createSetting } = useCreateDigitalTwinSetting()
  const { mutateAsync: updateDeviationSetting } = useUpdateDigitalTwinSetting()

  const currentDeclinedHours = declinedHours ? declinedHours : { planned: 0, unplanned_winter: 0, unplanned_summer: 0 }

  const declined =
    currentDeclinedHours.planned + currentDeclinedHours.unplanned_summer + currentDeclinedHours.unplanned_winter

  const methods = useForm<AvailableHoursFormData>({
    mode: `onChange`,
    shouldUnregister: false,
  })
  const { handleSubmit, formState, reset, watch } = methods
  const [unplannedStopSummer, unplannedStopWinter, plannedStop] = watch([
    `unplannedStopSummer`,
    `unplannedStopWinter`,
    'plannedStop',
  ])
  const total = Number(unplannedStopSummer) + Number(plannedStop) + Number(unplannedStopWinter)

  const { isValid, isSubmitting, isDirty } = formState

  useEffect(() => {
    setIsDirty?.(isDirty)
  }, [isDirty, setIsDirty])

  useEffect(() => {
    const hourSettings = getAvailableHoursFromSettings(settings)

    const now = Datetime.getISONow()
    reset({
      unplannedStopSummer: getSettingValue(now, hourSettings?.unplannedStopSummer) ?? 0,
      plannedStop: getSettingValue(now, hourSettings.plannedStop) ?? 0,
      unplannedStopWinter: getSettingValue(now, hourSettings.unplannedStopWinter) ?? 0,
    })
  }, [settings, reset])

  async function onSubmit(data: AvailableHoursFormData): Promise<void> {
    const hourSettings = getAvailableHoursFromSettings(settings)

    for (const period of PERIODS) {
      const newValue = Number(data[period])
      const settingTimeRange = getDigitalTwinAvailableHoursPeriod(year)
      const settingsData = {
        overwrite: true,
        data: {
          level: 'unit' as DeviationSettingLevel,
          name: heatSupplierUnitName,
          attribute: PERIOD_ATTRIBUTE_MAP[period],
          comment: "Created by 'available hours' modal.",
          priority: AVAILABLE_HOURS_SETTINGS_PRIO,
          start_time: settingTimeRange.activeFrom ?? null,
          end_time: settingTimeRange.activeTo ?? null,
          value: newValue,
          isTimeSeries: false,
          unit: 'hours',
          isBaseSetting: true,
        },
      }

      if (hourSettings[period] === undefined) {
        createSetting({ ...settingsData, digitalTwinUid }).then(() => onSettingsModified())
      } else {
        const setting = hourSettings[period]
        const oldValue = Number(setting?.value)

        if (setting && newValue !== oldValue) {
          updateDeviationSetting({ ...settingsData, settingId: setting.id }).then(() => onSettingsModified())
        }
      }
    }
  }

  return (
    <FormProvider {...methods}>
      {!hasEditPermission ? (
        <NoPermissionErrorMessage
          title={t(`Unfortunately, you do not have permission to make changes here`)}
          message={t('Unfortunately, you do not have permission to change settings on this user/facility')}
        />
      ) : null}
      <form aria-label="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          spacing={2}
          container
          direction="column"
          justifyContent="space-evenly"
          className={styles.AvailableHoursForm_Form}
          alignItems="flex-start"
        >
          <Grid item container direction="row" alignItems="center" justifyContent="flex-start">
            <Grid container direction="row" item xs={5} spacing={3}>
              <Grid item className={styles.AvailableHoursForm_Icon} style={{ alignSelf: `center` }}>
                <Icon className="fal fa-alarm-clock" />
              </Grid>

              <Grid item xs={9}>
                {fetchSettingsStatus === 'loading' ? (
                  <LoadingPlaceholderContainer height={64} width={190} />
                ) : (
                  <TextField
                    disabled={!hasEditPermission}
                    fullWidth
                    error={year < 2020}
                    name="year"
                    id="year"
                    value={year}
                    inputProps={{
                      'data-testid': `year`,
                      min: 2020,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setYear(Number(event.target.value))
                    }}
                    variant="outlined"
                    className={styles.AvailableHoursForm_TextField}
                    type="number"
                    label={t(`Year`)}
                    aria-label={t(`Year`)}
                    helperText={year < 2020 ? t(`Year not available`) : undefined}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {fetchSettingsStatus === 'loading' ? (
                <LoadingPlaceholderContainer height={64} />
              ) : (
                <>
                  <Typography variant="body1" whiteSpace="nowrap">
                    <span className={styles.AvailableHoursForm_BoldText}>{t(`Total of allowed hours: `)}</span>
                    {total} h
                  </Typography>
                  <Typography variant="body1" title="declined" whiteSpace="nowrap">
                    <span className={styles.AvailableHoursForm_BoldText}>{t(`Consumed hours: `)}</span>
                    {declined} h
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={1}
            wrap="nowrap"
          >
            <Grid item xs={12} sm={4}>
              {fetchSettingsStatus === 'loading' ? (
                <LoadingPlaceholderContainer height={64} width={220} />
              ) : (
                <SeasonTextField
                  disabled={!hasEditPermission}
                  name="plannedStop"
                  id="plannedStop"
                  error={formState?.errors?.plannedStop ? true : false}
                  declinedHours={currentDeclinedHours.planned}
                  label={t(`Planned hours, this year`)}
                  inputIcon="fal fa-redo"
                />
              )}
            </Grid>
            <Grid item container direction="row" xs={12} sm={4} alignItems="center" justifyContent="flex-start">
              {fetchSettingsStatus === 'loading' ? (
                <LoadingPlaceholderContainer height={64} width={220} />
              ) : (
                <SeasonTextField
                  disabled={!hasEditPermission}
                  name="unplannedStopWinter"
                  id="unplannedStopWinter"
                  error={formState?.errors?.unplannedStopWinter ? true : false}
                  declinedHours={currentDeclinedHours.unplanned_winter}
                  label={t(`Unplanned hours, winter`)}
                  inputIcon="fal fa-snowflake"
                />
              )}
            </Grid>
            <Grid item container direction="row" xs={12} sm={4} alignItems="center" justifyContent="flex-start">
              {fetchSettingsStatus === 'loading' ? (
                <LoadingPlaceholderContainer height={64} width={220} />
              ) : (
                <SeasonTextField
                  disabled={!hasEditPermission}
                  name="unplannedStopSummer"
                  id="unplannedStopSummer"
                  error={formState?.errors?.unplannedStopSummer ? true : false}
                  declinedHours={currentDeclinedHours.unplanned_summer}
                  label={t(`Unplanned hours, summer`)}
                  inputIcon="fal fa-sun"
                />
              )}
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <ContainedIconButton
              color="primary"
              type="submit"
              icon="fal fa-save"
              label={t(`Save`)}
              disabled={!hasEditPermission || isSubmitting || !isValid}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
