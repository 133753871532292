import React, { ReactElement, useRef, useState } from 'react'

import { Classification, useObjectProperties } from 'api/objectProperties/objectProperties.api'
import { useUnits } from 'api/units/units.api'
import ConfirmDialog from 'ui/components/ConfirmDialog/ConfirmDialog'
import { Dialog } from 'ui/components/Dialog/Dialog'
import { SettingsPermissions, baseSettingTypes } from 'ui/components/SettingsTable/SettingsTable'

import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import CustomDialogTitle from '../SettingsModal/components/CustomDialogTitle/CustomDialogTitle'
import { DateTimePickerRange } from 'helpers/settingsModal.helper/settingsModal.helper'

import { CreateNewDeviationDialog } from './DeviationSettingsModal/DeviationSettingsModal'

export type SettingsModalProps = {
  open: boolean
  closeModal: () => void,
  title?: string | ReactElement,
  children?: ReactElement | null,
  newDeviationForm?: boolean
  permissions: SettingsPermissions,
  startEndTimeRange?: DateTimePickerRange,
  classification: Classification,
}

export default function SettingsModal({
  open,
  closeModal,
  title = i18n.t('Settings'),
  newDeviationForm = false,
  permissions,
  startEndTimeRange,
  children,
  classification,

}: SettingsModalProps): ReactElement {
  const { t } = useTranslation()
  const dirtyForm = false // Used to allow double confirmation when closing modal
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false)
  const handleClose = (): void => (!dirtyForm ? closeModal() : setCloseConfirmOpen(true))

  const { data: objectProperties = [] } = useObjectProperties({ classification })
  const { data: units } = useUnits()

  const ref = useRef(null)

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{padding: 8}}
        container={ref.current}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <CustomDialogTitle title={title} handleClose={handleClose} />
        {newDeviationForm && <CreateNewDeviationDialog
          units={units ?? []}
          objectProperties={objectProperties ?? []}
          handleClose={handleClose}
          permissions={permissions}
          startTimeAndEndTimeRange={startEndTimeRange}
          isBaseValueSetting={classification ? baseSettingTypes.includes(classification) : false}
        />}
        {children}
      </Dialog>
      <ConfirmDialog
        open={closeConfirmOpen}
        onClose={() => {
          setCloseConfirmOpen(false)
        }}
        onConfirm={() => {
          setCloseConfirmOpen(false)
          closeModal()
        }}
        textObject={{
          title: t(`Do you really want to close without saving?`),
          text: t(
            `Currently you have an unsaved setting, are you completely sure you would like to exit without saving?`
          ),
        }}
      />
    </div>
  )
}