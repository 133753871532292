import React, { ReactElement, useMemo } from 'react'

import { HeatSupplierData, useHeatSupplierData } from 'api/heatSuppliers/heatSuppliers.api'
import DefaultTable from 'ui/components/CustomTable/CustomTable'
import StatusComponent from 'ui/components/StatusComponent/StatusComponent'

import { Grid } from '@mui/material'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'


import { useCurrency } from 'helpers/global.helper/global.helper'

function TableWrapper({ heatSupplierData }: { heatSupplierData: HeatSupplierData[] }): ReactElement {
  const currency = useCurrency()
  const { t } = useTranslation()

  const tableColumns: Column<Record<string, unknown>>[] = [
    {
      accessor: `time`,
      Header: t(`Time`),
    },
    {
      accessor: `price`,
      Header: t(`Spot-price`),
    },
    {
      accessor: `tempDemand`,
      Header: t(`Temperature demand`),
    },
    {
      accessor: `delivery`,
      Header: t(`Should deliver?`),
      Cell: (tableInfo: { value: any }) => <b>{tableInfo.value}</b>,
    },
  ]

  const tableData = useMemo(() => {
    return heatSupplierData.map((timeData) => ({
      time: moment(timeData.time).format(`D MMMM HH:mm`),
      price:
        timeData.marginal_cost !== null && timeData.marginal_cost !== undefined
          ? `${timeData.marginal_cost.toFixed(1)} ${currency}/MWh`
          : `-`,
      tempDemand: `${timeData.temperature_demand}°C`,
      delivery: timeData.availability === 0 ? t(`No`) : t(`Yes`),
    }))
  }, [currency, heatSupplierData, t])

  return <DefaultTable data={tableData} columns={tableColumns} tableOptions={{ initialState: { pageSize: 24 } }} />
}

type SupplierTableProps = {
  currentDate: Moment
  supplierId: number
  isDigitalTwin?: boolean
}

export default function SupplierTable({ currentDate, supplierId, isDigitalTwin }: SupplierTableProps): ReactElement {
  const { data: heatSupplierData, status } = useHeatSupplierData(
    moment(currentDate).startOf(`day`),
    moment(currentDate).endOf(`day`).minutes(0),
    true,
    supplierId,
    isDigitalTwin
  )

  return (
    <Grid item>
      <StatusComponent data={{ heatSupplierData }} status={status} Component={TableWrapper} />
    </Grid>
  )
}
