import React, { ReactElement } from 'react'

import { DigitalTwinHeatSupplier } from 'api/heatSuppliers/heatSuppliers.api'
import { TabObject } from 'ui/components/Tabs/RegularTabs'
import Tabs from 'ui/components/Tabs/Tabs'

import { Grid } from '@mui/material'
import { Moment } from 'moment'

import SupplierTable from 'views/SamEnergiView/components/SupplierView/components/SupplierViewTabs/components/SupplierTable/SupplierTable'

import DigitalTwinHeatSupplierDetails from './DigitalTwinHeatSupplierDetails'

type DigitalTwinHeatSupplierTabsProps = {
  heatSuppliers: DigitalTwinHeatSupplier[]
  selectedDate: Moment
  heatSupplier?: DigitalTwinHeatSupplier
  setSelectedHeatSupplier: (heatSupplier: DigitalTwinHeatSupplier | undefined) => void
  refetchSelectedDateSettings: () => void
}

export default function DigitalTwinHeatSupplierTabs({
  heatSuppliers,
  selectedDate,
  heatSupplier,
  setSelectedHeatSupplier,
  refetchSelectedDateSettings,
}: DigitalTwinHeatSupplierTabsProps): ReactElement {
  const onTabChanged = (_event: React.ChangeEvent<unknown>, selectedTabValue: number): void => {
    const newSelectedHeatSupplier = heatSuppliers.find((supplier) => supplier.id === selectedTabValue)
    setSelectedHeatSupplier(newSelectedHeatSupplier)
  }

  const tabs: TabObject[] = heatSuppliers
    .map((supplier) => {
      return { label: supplier.display_name, id: supplier.id }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Grid container direction="row" spacing={1} style={{ marginTop: 20 }}>
      {heatSupplier && (
        <Tabs handleChange={onTabChanged} value={heatSupplier?.id} tabObjects={tabs}>
          <Grid item container direction="row" justifyContent="space-between" spacing={2} xs={12}>
            <Grid item xs={12} md={7}>
              <SupplierTable currentDate={selectedDate} supplierId={heatSupplier.id} isDigitalTwin />
            </Grid>
            <Grid item xs={12} md={5}>
              <DigitalTwinHeatSupplierDetails
                selectedDate={selectedDate}
                heatSupplier={heatSupplier}
                refetchSelectedDateSettings={refetchSelectedDateSettings}
              />
            </Grid>
          </Grid>
        </Tabs>
      )}
    </Grid>
  )
}
