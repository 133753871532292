import React, { ReactElement } from 'react'

import { Dialog } from 'ui/components/Dialog/Dialog'

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ConfirmTextObject } from 'helpers/settingsModal.helper/settingsModal.helper'

type ConfirmDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  textObject: ConfirmTextObject
  subTextList?: string[]
  confirmButtonStyle?: React.CSSProperties
  alternativeButton?: {
    text: string
    onClick: () => void
  }
}

export default function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  textObject,
  subTextList,
  confirmButtonStyle,
  alternativeButton,
}: ConfirmDialogProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>{textObject.title}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{textObject.text}</DialogContentText>
        {subTextList
          ? subTextList.map((i, index) => (
            <DialogContentText id="alert-dialog-description" key={index}>
              {i}
            </DialogContentText>
          ))
          : null}
      </DialogContent>
      <DialogActions>
        {alternativeButton && (
          <Button onClick={alternativeButton.onClick} color="secondary" variant="contained">
            {t(alternativeButton.text)}
          </Button>
        )}
        <Button onClick={onClose} color="secondary" variant="contained">
          {t(`Cancel`)}
        </Button>
        <Button
          style={confirmButtonStyle}
          onClick={() => {
            onConfirm()
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          {textObject?.confirm ?? t(`OK`)}
        </Button>

      </DialogActions>
    </Dialog>
  )
}
